import React from "react";
import NavBar from './MartNavBar';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { AsyncTypeahead,Highlighter } from 'react-bootstrap-typeahead';
import * as Common from '../common/ImportUserEssentials';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../styles/mart-orders-add.css';
import {Html5Qrcode} from "html5-qrcode";
import beepSound from '../sounds/scanner-beep.mp3';
import Offcanvas from 'react-bootstrap/Offcanvas';
import GLightbox from 'glightbox';

export default function MartDashboard(props){
	const [cartAnimation,setCartAnimation] = React.useState('');
	const [cutomerExist,setCustomerExist] = React.useState(false);
	const [isCustLoading, setIsCustLoading] = React.useState(false);
	const [custSel,setCustSel] = React.useState([]);
	const [customers,setCustomers] = React.useState([]);
	const [cust_phone_disabled, setCustPhoneDisabled] = React.useState(false);
	const [cust_details, setCustDetails] = React.useState({'id':0,'name':'','gstin':'','phone':''});

	//const [martMode,setMartMode] = React.useState('normal');
	const [categories,setCategories] = React.useState([]);
	const [dummy_categories,setDummyCategories] = React.useState([]);
	const [items,setItems] = React.useState([]);
	const [customer_modal,setCustomerModal] = React.useState(false);
	const [showCart, setShowCart] = React.useState(false);
	const [showCat, setShowCat] = React.useState(false);
	const [cartItems,setCartItems] = React.useState([]);
	const [reCalcCart,setReCalcCart] = React.useState(true);
	const [cartSubTotal, setCartSubTotal] = React.useState(0);
	//const [cartDiscount, setCartDiscount] = React.useState(0);
	const [cartGst, setCartGst] = React.useState(0);
	const [cartTotal, setCartTotal] = React.useState(0);
	const [defaultPayee,setDefaultPayee] = React.useState(0);
	const [cartOtherCharge,setCartOtherCharge] = React.useState('');
	const [cartRoundoff,setCartRoundoff] = React.useState('');
	const [cartAddDiscount,setCartAddDiscount] = React.useState('');
	const [searchText,setSearchText] = React.useState('');

	const [isItemLoading, setIsItemLoading] = React.useState(false);
	const [itemsCart,setItemsCart] = React.useState([]);
	//Cart
	const [martCartMode,setMartCartMode] = React.useState('normal');
	
	// Bypass client-side filtering by returning `true`. Results are already
	const filterBy = () => true;
	const navigate = Common.useNavigate();
	React.useEffect(() => {
		GLightbox({
			selector: '[data-glightbox]',
			touchNavigation: false,
			loop: false,
			autoplayVideos: true
		});
	},[items]);
    React.useEffect(() => {
        document.title = 'Add Order | '+props.site_name;
		loadItemCategories();
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/defaultPayee/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setDefaultPayee(response.data.data.value);
			}
		}).catch(error => console.log(error));
	}, [props]);
	const reloadItems = () => {
		if( categories.length > 0 ){
			let temp = [];
			categories.forEach((cat) => {
				temp.push(cat.id);
			});
			loadItems(temp);
		}
	}
	React.useEffect(() => {
		if( searchText === '' || searchText.length >= 3 ){
			reloadItems();
		}
	},[searchText]);
	React.useEffect(() => {
		reloadItems();
		/*let lst = localStorage.getItem('mo_cust');
		if( lst !== null ){
			let temp = JSON.parse(lst);

			setCustSel([{'id':temp['id'],'party_name':temp['name'],'mobile':temp['phone']}]);
			if( temp['id'] > 0 ){
				setCustPhoneDisabled(true);
			}
			setCustDetails(temp);
			setCustomerExist(true);
		}*/
	}, [categories]);
	//Barcode scanner
	let audio = new Audio(beepSound);
	const playBeep = () => {
		if(audio.paused === true){
		  audio.play();
		}else{
		  audio.pause();
		  audio.currentTime = 0;
		  audio.play();
		}
	};
	/*React.useEffect(() => {
		const html5QrCode = new Html5Qrcode("barReader");
		const startScanner = async () => {
			await html5QrCode.start(
				{ facingMode: 'environment' },
				{
					fps: 10,
					qrbox: { width: 300, height: 200 }
					//qrbox: qrboxFunction
				},
				(decodedText, decodedResult) => {
					if(decodedText !== ''){
						playBeep();
						stopScanner();
						addItemBarcode(decodedText);
					}
				},
				(errorMessage) => {
					// parse error, ignore it.
				}
			).catch((err) => {
				Common.toast.error(err);
				setMartMode('normal');
			});
		};
		const addItemBarcode = (barcode) => {
			Common.showLoader(true);
			Common.axios({
				method: 'post',
				url:props.url+'martorders/itemFromBarcode',
				data: {martid:Common.secureLocalStorage.getItem('fo_martid'),barcode:barcode}
			}).then(function (response) {
				Common.showLoader(false);
				if(response.data.success){
					const index = cartItems.findIndex(item => item.iid === response.data.data.iid);
					if( index >= 0 ){
						let newQty = parseFloat(cartItems[index].qty) + 1;
						updateCart(index,newQty,1);
						Common.toast.success(response.data.message);
						return false;
					}
					addToCart(response.data.data,1);
					Common.toast.success(response.data.message);
					return false;
				}
				startScanner();
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
		const stopScanner = async () => {
			if (html5QrCode.isScanning) {
				await html5QrCode.stop();
				html5QrCode.clear();
			}
		};
		if( martMode === 'barcode' ){
			startScanner();
		}
		return () => {
			stopScanner();
		};
	}, [martMode,refBar]);*/
	React.useEffect(() => {
		if( document.getElementById('barCartReader') !== null ){
			const html5QrCode = new Html5Qrcode("barCartReader");
			const startScanner = async () => {
				await html5QrCode.start(
					{ facingMode: 'environment' },
					{
						fps: 10,
						qrbox: { width: 250, height: 180 }
						//qrbox: qrboxFunction
					},
					(decodedText, decodedResult) => {
						if(decodedText !== ''){
							playBeep();
							//stopScanner();
							setMartCartMode('normal');
							addItemBarcode(decodedText);
						}
					},
					(errorMessage) => {
						// parse error, ignore it.
					}
				).catch((err) => {
					Common.toast.error(err);
					setMartCartMode('normal');
				});
			};
			const addItemBarcode = (barcode) => {
				Common.showLoader(true);
				Common.axios({
					method: 'post',
					url:props.url+'martorders/itemFromBarcode',
					data: {martid:Common.secureLocalStorage.getItem('fo_martid'),barcode:barcode}
				}).then(function (response) {
					Common.showLoader(false);
					//startScanner();
					setMartCartMode('barcode');
					if(response.data.success){
						addToCart(response.data.data);
						Common.toast.success(response.data.message);
						return false;
					}
					Common.toast.error(response.data.message);
				}).catch(error => console.log(error));
			}
			const stopScanner = async () => {
				if (html5QrCode.isScanning) {
					await html5QrCode.stop();
					html5QrCode.clear();
				}
			};
			if( martCartMode === 'barcode' ){
				startScanner();
			}
			return () => {
				stopScanner();
			};
		}
	}, [martCartMode]);
	React.useEffect(() => {
		if( cutomerExist){
			setCustomerModal(false);
		} else {
			setCustomerModal(true);
		}
	}, [cutomerExist]);
	React.useEffect(() => {
		let cart_sub = 0, cart_gst = 0;
		if( cartItems.length > 0 ){
			cartItems.forEach(item => {
				cart_sub += Number(item.qty) * Number(item.actual_sale_price);
				cart_gst += Number(item.qty) * Number(item.gst_amt);
			});
		}
		setCartSubTotal(cart_sub);
		setCartGst(cart_gst);
		let total_amount = (Number(cart_gst) + Number(cart_sub) + Number(cartOtherCharge) - Number(cartAddDiscount)) + (Number(cartRoundoff));
		setCartTotal(total_amount.toFixed(2));
	}, [cartItems,reCalcCart]);
	const setCustPhone = (val) => {
		setCustDetails(cust_details => ({...cust_details, phone: val}))
	}
	const checkCustomer = () => {
		if(cust_details.name !== ''){
			setCustomerExist(true);
			setCustomerModal(false);
			//localStorage.setItem('mo_cust',JSON.stringify(cust_details));
			return false;
		}
		Common.toast.error('Please enter customer.');
	}
	const loadItemCategories = () => {
		Common.axios({
            method: 'post',
            url:props.url+'martorders/getCategories',
			data: {martid:Common.secureLocalStorage.getItem('fo_martid')}
        }).then(function (response) {
            if(response.data.success){
				setCategories(response.data.data);
				setDummyCategories(response.data.data);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const loadItems = (catids) => {
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'martorders/itemsByCategory',
			data: {martid:Common.secureLocalStorage.getItem('fo_martid'),'catids':catids,search:searchText}
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
				setItems(response.data.data);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const setCustomerDetails = (e) => {
		if(e[0] === undefined) {
			setCustSel([]);
			setCustDetails({'id':0,'name':'','gstin':'','phone':''});
			setCustPhoneDisabled(false);
			setCustomerExist(false);
			//localStorage.removeItem('mo_cust');
			return;
		}
		setCustSel(e);
		let temp = {};
		temp['id'] = e[0].id.startsWith('new') ? 0:e[0].id;
		temp['name'] = (e[0]) ? e[0].party_name : '';
		temp['phone'] = (e[0].mobile) ? e[0].mobile : '';
		temp['gstin'] = (e[0].gstin) ? e[0].gstin : '';
		setCustDetails(temp);
		if( temp['id'] > 0 ){
			setCustPhoneDisabled(true);
		}
	}
	const customerSearch = (query: string) => {
		setIsCustLoading(true);

		fetch(props.url+`customer/search/`+localStorage.getItem('fo_profileid')+`/${query}`)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setCustomers(items);
			setIsCustLoading(false);
		});
	};
	const itemsSearch = (query: string) => {
		setIsItemLoading(true);

		fetch(props.url+`martorders/itemSearch/`+Common.secureLocalStorage.getItem('fo_martid')+'/'+query)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setItemsCart(items);
			setIsItemLoading(false);
		});
	};
	const addToCart = (item) => {
		if( presentInCart(item.iid) ){
			incrementCart(item);
			return false;
		}
		//item['qty'] = item['service'] === '0' ? item['min_qty'] : Common.displayUnit(1);
		item['qty'] = 1;
		item['actual_sale_price'] = item['sale_price'] - item['discount'];
		setCartItems([...cartItems,item]);
		cartAnimate();
	}
	const cartAnimate = () => {
		setCartAnimation('bx-tada');
		setTimeout(() => {
			setCartAnimation('');
		}, 1000);
	}
	const presentInCart = (iid) => {
		return cartItems.some(item => iid === item.iid);
	}
	const removeFromCart = (cart) => {
		const index = cartItems.findIndex(item => item.iid === cart.iid);
		if (index !== -1) {
			const newArray = [...cartItems.slice(0, index), ...cartItems.slice(index + 1)];
			setCartItems(newArray);
			setReCalcCart(!reCalcCart);
			cartAnimate();
			if( martCartMode === 'barcode' ){
				setMartCartMode('normal');
				setTimeout(() => {
					setMartCartMode('barcode');
				}, 1000);
			}
		}
	}
	const cntItemsInCart = (iid) => {
		const index = cartItems.findIndex(item => item.iid === iid);
		return cartItems[index]['qty'];
	}
	const decrementCart = (item) => {
		const index = cartItems.findIndex(cart => cart.iid === item.iid);
		let newQty = parseFloat(cartItems[index]['qty']) - 1;
		if( newQty < 1 ){
			removeFromCart(item);
			return false;
		}
		updateCart(index,newQty);
	}
	const incrementCart = (item) => {
		const index = cartItems.findIndex(cart => cart.iid === item.iid);
		let newQty = parseFloat(cartItems[index]['qty']) + 1;
		updateCart(index,newQty);
	}
	const updateItemPrice = (index,newPrice) => {
		newPrice = parseFloat(newPrice) > 0 ? newPrice.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : newPrice;
		let temp_cart = cartItems;
		temp_cart[index]['actual_sale_price'] = newPrice;
		temp_cart[index]['gst_amt'] = Number(temp_cart[index]['qty'])*Number(temp_cart[index]['gst_rt'])*Number(temp_cart[index]['actual_sale_price'])/100;
		setCartItems(temp_cart);
		setReCalcCart(!reCalcCart);
	}
	const updateCart = (index,newQty) => {
		newQty = parseFloat(newQty) > 0 ? newQty.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : newQty;
		let temp_cart = cartItems;
		temp_cart[index]['qty'] = newQty;
		setCartItems(temp_cart);
		setReCalcCart(!reCalcCart);
		cartAnimate();
	}
	const placeOrder = (callFrom) => {
		let data = {};
		data['pid'] = localStorage.getItem('fo_profileid');
		data['martid'] = Common.secureLocalStorage.getItem('fo_martid');
		data['cid'] = cust_details.id;
		data['cust_name'] = cust_details.name;
		data['cust_gstin'] = cust_details.gstin;
		data['cust_phone'] = cust_details.phone;
		data['cust_legal_name'] = '';
		data['cust_trade_name'] = '';
		data['cust_addr'] = '';
		data['cust_pincode'] = '';
		data['cust_district'] = '';
		data['cust_state'] = '';
		data['cust_email'] = '';
		data['update_cust'] = 0;
		data['pos'] = Common.secureLocalStorage.getItem('fo_martpos');
		data['doc_date'] = new Date();
		data['total_taxable_amt'] = cartSubTotal;
		data['total_doc_discount'] = cartAddDiscount;
		data['round_off'] = cartRoundoff;
		data['other_charges'] = cartOtherCharge;
		data['total_amt'] = cartTotal;
		data['doc_type'] = 'INV';
		data['supply_type'] = (cust_details.gstin !== '' && cust_details.gstin.length === 15) ? 'B2B':'B2C';
		data['etin'] = '';
		data['rev_chrg'] = 'N';
		data['igst_intra'] = 'N';
		data['income_type'] = '1';
		data['income_subtype'] = '';
		data['payee'] = defaultPayee;
		data['items'] = [];
		let errors = [];
		cartItems.forEach(item => {
			let temp = {};
			if( item['service'] === '0' && Number(item['qty']) < Number(item['min_qty']) ){
				errors.push(item['name']+' : Minimum order qty. is '+Common.displayUnit(item['min_qty']));
				return false;
			}
			temp['id'] = item['id'];
			temp['name'] = item['name'];
			temp['item_qty'] = item['qty'];
			temp['unit'] = item['uqcid'];
			temp['hsn'] = item['hsn'];
			temp['price'] = Number(item['actual_sale_price'])+Number(item['discount']);
			temp['item_discount'] = item['qty']*item['discount'];
			temp['gst_rt'] = item['gst_rt'];
			temp['gst_rate_id'] = item['gst_rate_id'];
			temp['igst_amt'] = 0;
			temp['cgst_amt'] = temp['sgst_amt'] = (Number(item['qty']*(parseFloat(item['gst_rt'])/100)*item['actual_sale_price'])/2).toFixed(2);
			temp['item_tot_price'] = parseFloat(item['qty'])*parseFloat(item['actual_sale_price']) + parseFloat(temp['cgst_amt']) + parseFloat(temp['sgst_amt']);
			data['items'].push(temp);
		});
		data['items'] = JSON.stringify(data['items']);
		data['allocation_centre'] = '';
		data['poid'] = 0;
		data['qid'] = 0;

		if( errors.length > 0 ){
			Common.showLoader(false);
			let err_list = '';
			errors.forEach((val, index) => {
				err_list += '<li>'+val+'</li>';
			})
			const err_html = () => (
				<ul><strong>List of Errors:</strong>{Common.parse(err_list)}</ul>
			);
			Common.toast.error(err_html);
			return false;
		}

		Common.axios({
			method: 'post',
			url: props.url+'sale_orders/add',
			data: data
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				if(callFrom === 1){
					navigate('/mart-payment',
					{
						state: {
							sid: response.data.so_id
						}
					});
					return false;
				}
				navigate("/mart-orders");
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const finalDocTotalCal = (otherCharge,roundoff,discount) => {
		if(otherCharge === '.' || roundoff === '.' || discount === '.'){
			return false;
		}
		if(otherCharge === '' && roundoff === '' && discount === ''){
			return false;
		}
		let total_amount = (Number(cartGst) + Number(cartSubTotal) + Number(otherCharge) - Number(discount)) + (Number(roundoff));
		setCartTotal(total_amount.toFixed(2));
	}
	const handleDocRoundoff = (roundoff) => {
		if((Number(0.99) < Number(roundoff)) || (Number(-0.99) > Number(roundoff))){
			Common.toast.error('Round off amount should be between -0.99 to 0.99');
			return false;
		}
		setCartRoundoff(roundoff);
		finalDocTotalCal(cartOtherCharge,roundoff,cartAddDiscount);
	}
    return (
		<div id="mm_orderadd">
		<Offcanvas className="w-25" placement={'start'} show={showCat} onHide={()=>setShowCat(false)}>
			<Offcanvas.Body className="text-center text-break">
				<div key={"catMLinkall"} className="catDivBtn py-2">
					<button onClick={()=>{setShowCat(false);loadItemCategories(categories)}} className="btn btn-lg rounded-pill btn-link p-0">
						<img className="img-thumbnail rounded-pill" src={window.location.origin+"/assets/img/allcat.png"} alt={"Items All"}/>
					</button>
					<small>All</small>
				</div>
				{categories.map((cat, i) => {
					return (
					<div key={"catMLink"+i} className="catDivBtn py-2">
						<button onClick={()=>{setShowCat(false);loadItems([cat.id]);setDummyCategories([cat])}} className="btn btn-lg rounded-pill btn-link p-0">
							{(cat.image.name !== '') ?
							<img className="img-thumbnail rounded-pill border-1" src={cat.image.name} alt={"Items "+i}/>
							:
							<img className="img-thumbnail rounded-pill border-1" src={window.location.origin+"/assets/img/no-preview.png"} alt={"Items "+i}/>
							}
						</button>
						<small>{cat.name}</small>
					</div>
					);
				})}
			</Offcanvas.Body>
		</Offcanvas>
		<Offcanvas id="vendor_cart" placement={'end'} show={showCart} onHide={()=>{setShowCart(false);setMartCartMode('normal')}}>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title className="row row-cols-auto w-100 align-items-center">
					<div className="col-4 col-lg-3">My Cart</div>
					<div className="col-5 col-lg-7 p-0">
						<AsyncTypeahead selected={[]} filterBy={filterBy} id="item-cart" isLoading={isItemLoading} labelKey="name" minLength={3} onSearch={itemsSearch} options={itemsCart} placeholder="Search (min 3 chars)" onChange={(e) => addToCart(e[0])}
							renderMenuItemChildren={(option: Item,props) => (
								<div className="smaller_font">
									<Highlighter search={props.text}>
										{option.name}
									</Highlighter>
									<div>
										<small className="text-success text-nowrap">Price: {Common.displayPrice(option.sale_price)}</small>
										{option.service === '0' && option.stock_movement === '1' && <>
										, <small>Available: {option.current_stock === '0.00' ? 'Out of Stock' : Number(option.current_stock)}</small>
										</>}
									</div>
								</div>
							)}
						/>
					</div>
					<div className="col-2 col-lg-2">
						<Common.OverlayTrigger placement={'bottom'} overlay={Common.tooltipContent('Scan Barcodes')}>
							<Button onClick={()=>setMartCartMode( (martCartMode==='barcode') ? 'normal':'barcode')} variant="outer-primary" className={"p-0 "+(martCartMode==='barcode' ? "text-success" : "") }><i className={"bx bx-lg bx-barcode-reader"+(martCartMode==='barcode' ? " bx-burst":"")}></i></Button>
						</Common.OverlayTrigger>
					</div>
				</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className={"row pb-2 text-center "+ (martCartMode === 'normal' ? "d-none":"")}>
					<div className={"mx-auto "+(martCartMode === 'normal' ? "d-none":"")} style={{'width':'320px','height':'auto'}}>
						<h5>Scan Barcodes</h5>
						<div id="barCartReader" className="d-block"></div>
					</div>
				</div>
				{cartItems.length === 0 ? <><div className="text-center"><small>You have not added anything to cart.</small></div></>
				:
				<div className="table-responsive">
					<table id="cartTable" className="small_font table table-sm table-hover table-borderless align-middle">
						<thead>
							<tr>
								<th style={{'width':'10%'}}></th>
								<th style={{'width':'40%'}}>Item</th>
								<th style={{'width':'15%'}} className="text-end">Qty</th>
								<th style={{'width':'30%'}} className="text-end">Price/Unit</th>
								<th style={{'width':'5%'}} className="text-end"></th>
							</tr>
						</thead>
						<tbody>
						{cartItems.map((item,index) => {
							return (
								<tr key={"iCart"+index}>
									<td>
									{item.media.length > 0 ? <>									
										{ (item.media[0].type === 'image/png' || item.media[0].type === 'image/jpeg') ?
											<img className="border rounded" src={item.media[0].name} width="50" height="auto" alt={"Items Cart"+index}/>
										:
											<video className="w-100" controls>
												<source src={item.media[0].name} type={item.media[0].type}/>
												Your browser does not support the video tag.
											</video>
										}
										</>
										: <img className="border rounded" src={window.location.origin+"/assets/img/no-media.png"} height="auto" width="50" alt={"Items Cart"+index}/>
									}
									</td>
									<td>
										{item.name}<br/>
										<span className="smaller_font text-success text-nowrap">{Common.displayPrice(item.sale_price-item.discount)}</span>
										{ item.discount > 0 && <><br/><span className="smaller_font text-decoration-line-through text-muted">{Common.displayPrice(item.sale_price)}</span></>}
									</td>
									<td valign="top">
										<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Change Quantity')}>
										<input onChange={(e)=>updateCart(index,Common.acceptOnlyDecimal(e.target.value))} type="text" step=".001" min="0" className="text-right form-control form-control-sm" name="qty" value={item.qty} autoComplete="off"/>
										</Common.OverlayTrigger>
									</td>
									<td valign="top" align="right">
										<input type="text" name="price" className="text-right form-control form-control-sm" value={item.actual_sale_price} onChange={(e)=>updateItemPrice(index,e.target.value)}/>
										<span className="smaller_font text-success text-nowrap">Tot: {Common.displayPrice(item.qty*(item.actual_sale_price))}</span>
									</td>
									<td valign="top">
										<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Remove from Cart')}>
											<Button onClick={()=>removeFromCart(item)} size="sm" variant="link"><i className='bx bx-trash'></i></Button>
										</Common.OverlayTrigger>
									</td>
								</tr>
							)
						})}
						</tbody>
					</table>
				</div>
				}
				<hr/>
				<div className="list-group small_font">
					<div className="list-group-item list-group-item-action text-nowrap">Subtotal: <span className="float-end">{Common.displayPrice(cartSubTotal)}</span></div>
					<div className="list-group-item list-group-item-action text-nowrap">GST: <span className="float-end">{Common.displayPrice(cartGst)}</span></div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Additional discount:</span>
							</div>
							<div className="col-4">
								<input type="text" autoComplete="off" className="form-control form-control-sm text-end" id="frmSaleDocDiscount" value={cartAddDiscount} placeholder="0.00" onChange={(e) => { setCartAddDiscount(Common.acceptOnlyDecimal(e.target.value));finalDocTotalCal(cartOtherCharge,cartRoundoff,Common.acceptOnlyDecimal(e.target.value)); }} />
							</div>
						</div>
					</div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Other charges:</span>
							</div>
							<div className="col-4">
								<input type="text" autoComplete="off" className="form-control form-control-sm text-end" id="frmSaleDocOtherCharge" value={cartOtherCharge} placeholder="0.00" onChange={(e) => { setCartOtherCharge(Common.acceptOnlyDecimal(e.target.value));finalDocTotalCal(Common.acceptOnlyDecimal(e.target.value),cartRoundoff,cartAddDiscount); }} />
							</div>
						</div>
					</div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Round off:</span>
							</div>
							<div className="col-4">
								<input type="number" autoComplete="off" className="form-control form-control-sm text-end" id="frmSaleDocRoundoff" value={cartRoundoff} placeholder="0.00" onChange={(e) => { handleDocRoundoff(e.target.value); }} />
							</div>
						</div> 
					</div>
					<div className="list-group-item list-group-item-action text-nowrap active">Total: <span className="float-end">{Common.displayPrice(cartTotal)}</span></div>
				</div>
				{cartTotal > 0 &&
				<div className="d-grid gap-2 pt-4">
					<Button onClick={()=>placeOrder(1)} variant="success" type="button">Proceed to Pay <i className='bx bx-right-arrow-alt bx-fade-right'></i></Button>
					<Button onClick={()=>placeOrder(0)} variant="warning" type="button">Pay Later</Button>
				</div>
				}
			</Offcanvas.Body>
		</Offcanvas>
		<Modal show={customer_modal} onHide={()=>setCustomerModal(false)} backdrop="static" keyboard={false}>
			<Modal.Header closeButton={cust_details.name === '' ? false : true}>
				<Modal.Title>Customer Details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="form-floating">
					<AsyncTypeahead selected={custSel} clearButton allowNew newSelectionPrefix="Add: " filterBy={filterBy} id="sale-customer" className="floatingTypeahead" isLoading={isCustLoading} labelKey="party_name" minLength={3} onSearch={customerSearch} options={customers} placeholder="Search (min 3 chars)" onChange={(e) => setCustomerDetails(e)}
						renderMenuItemChildren={(option: Item,props) => (
							<div>
								<Highlighter search={props.text}>
									{option.party_name}
								</Highlighter>
								<div>
									<small>Mobile: {option.mobile}</small>
								</div>
							</div>
						)}
					/>
					<label htmlFor="frmSaleCName">Customer Name <span className="text-danger">*</span></label>
				</div>
				<div className="form-floating mt-2">
					<input name="cust_phone" type="text" maxLength="10" className="form-control" id="frmSaleCPhone" placeholder="Enter Phone" value={cust_details.phone} onChange={(e) => setCustPhone(Common.acceptOnlyNumbers(e.target.value))} disabled={cust_phone_disabled} autoComplete="off"/>
					<label htmlFor="frmSaleCPhone">Phone</label>
				</div>
				<div className="mt-2 text-center">
					<Button variant="primary" size="sm" onClick={checkCustomer}><i className='bx bx-save'></i>&nbsp;Submit</Button>
				</div>
			</Modal.Body>
		</Modal>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container martBg">
                <div className="layout-page">
                    <NavBar {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
							<div className="row">
								<h4 className="fw-bold pt-3 martTxtColor">
									<a className="btn btn-sm btn-primary me-1" href="/mart-orders"><i className='bx bxs-chevron-left'></i></a>
									<span className="hidden-phone align-middle">
										<span className="text-muted martTxtColor fw-light">Orders /</span>
										Add Order
									</span>
									<span className="dropdown">
										<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
										<i className='text-danger bx bxl-youtube bx-md'></i>
										</button>
										<div className="dropdown-menu" >
											<a href="https://youtu.be/42-7mAk7srg" className="dropdown-item" target="_blank" rel="noreferrer"><span>Create an order</span></a>
										</div>
									</span>
									<span className="d-inline-block fs-5 float-end">
										<span className="d-inline-block text-truncate" style={{maxWidth:"125px","verticalAlign":"middle"}}>{cust_details.name}</span>&nbsp;<Button onClick={()=>setCustomerModal(true)} variant="primary" size="sm" className="btn-icon rounded-pill"><i className='tf-icons bx bx-edit-alt'></i></Button>
										<div className="x_small_font">{cust_details.phone !== '' ? 'Phone: '+cust_details.phone : ''}</div>
									</span>
								</h4>
							</div>
							<div className="row align-items-center">
								<div className="offset-md-4 col-md-4">
									<div className="input-group input-group-merge">
										<span className="input-group-text"><i className="bi bi-search"></i></span>
										<input value={searchText} type="text" className="form-control" aria-label="Search Item" placeholder="Search (min 3 chars)" onChange={(e)=>setSearchText(e.target.value)}/>
										<span className="input-group-text cursor_pointer" onClick={()=>setSearchText('')}><i className="bi bi-x-lg"></i></span>
									</div>
								</div>
								{/*<div className="col-md-3">
									<div className="text-center">
										<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Scan Barcodes')}>
											<Button onClick={()=>setMartMode( (martMode==='barcode') ? 'normal':'barcode')} variant="outer-primary" className={(martMode==='barcode') ? "text-success" : "text-white" }><i className={"bx bx-lg bx-barcode-reader"+(martMode==='barcode' ? " bx-burst":"")}></i></Button>
										</Common.OverlayTrigger>
									</div>
								</div>*/}
								<div className="col-md-4">
									<div className="float-end hidden-phone">
										<Button onClick={()=>setShowCart(true)} variant="success" className="py-0 d-flex align-items-center"><i className={"bi bx-md align-text-bottom "+(cartItems.length === 0 ? "bi-cart ":"bi-cart-fill ") + cartAnimation}></i>
											<div className="ms-1 text-end align-text-bottom">
												{cartItems.length === 0 && 'My Cart'}
												{cartItems.length > 0 && <>{cartItems.length} items<br/> {Common.displayPrice(cartTotal)}</>}
											</div>
										</Button>
									</div>
								</div>
							</div>
							<div className="row pb-5">
								{/*<div className={"py-5 col-12 text-center "+ (martMode === 'normal' ? "d-none":"")}>
									<div className={"mx-auto "+(martMode === 'normal' ? "d-none":"")} style={{'width':'320px','height':'auto'}}>
										<h5 className="martTxtColor">Scan Barcodes</h5>
										<div id="barReader" className="d-block"></div>
									</div>
								</div>*/}
								<div className={"card mt-5 col-md-1 text-center hidden-phone " + {/*(martMode === 'barcode' ? "d-none":"")*/}}>
									<div key={"catLinkall"} className="catDivBtn py-2">
										<button onClick={()=>loadItemCategories(categories)} className="btn btn-lg rounded-pill btn-link">
											<img className="img-thumbnail rounded-pill" src={window.location.origin+"/assets/img/allcat.png"} alt={"Items All"}/>
										</button>
										<small>All</small>
									</div>
									{categories.map((cat, i) => {
										if( cat.id === "0" && items[cat.id] !== undefined && items[cat.id].length === 0 ) { return ''; }
										return (
										<div key={"catLink"+i} className="catDivBtn py-2">
											<button onClick={()=>{loadItems([cat.id]);setDummyCategories([cat])}} className="btn btn-lg rounded-pill btn-link">
												{(cat.image.name !== '') ?
												<img className="img-thumbnail rounded-pill border-1" src={cat.image.name} alt={"Items "+i}/>
												:
												<img className="img-thumbnail rounded-pill border-1" src={window.location.origin+"/assets/img/no-preview.png"} alt={"Items "+i}/>
												}
											</button>
											<small>{cat.name}</small>
										</div>
										);
									})}
								</div>
								<div className={"col-md-11 "+{/*(martMode === 'barcode' ? "d-none":"")*/}}>
									{dummy_categories.map((cat, cati) => {
										if( (items[cat.id] !== undefined && items[cat.id].length === 0) && dummy_categories.length > 1 ){ return ''; }
										return (
											<div className="row" key={"cat_"+cati}>
												<div className="col-12">
													<div className="divider text-start mb-1"><div className="divider-text fs-5 text-white text-capitalize fw-bold">{cat.name} ({items[cat.id] !== undefined && items[cat.id].length})</div></div>
												</div>
												{items[cat.id] === undefined && <><div className="mb-3"><ShimmerSimpleGallery card={true} row={1} col={4} imageHeight={200} caption={true} /></div></>}
												{items[cat.id] !== undefined && items[cat.id].length === 0 && <><div className="mb-3 text-white text-center">There are no items in this category</div></>}
												{items[cat.id] !== undefined && items[cat.id].map( (item,index) =>{
													return (
													<div key={"item_"+index} className="col-6 col-md-4 col-xl-3 card-group">
														<div className="card mb-3">
															<div className="itemMedia p-0 card-body">
																{item.discount > 0 && <>
																	<Button variant="primary" size="sm" className="dispDiscount" disabled>{item.discount/item.sale_price*100}% OFF</Button>
																</>
																}
																{item.media.length > 0 ?
																<Carousel variant="dark" controls={(item.media.length > 1) ? true : false} indicators={(item.media.length > 1) ? true : false}>
																{item.media.map((media,medi) => {
																	return(
																		<Carousel.Item key={"media_"+medi}>
																			{ (media.type === 'image/png' || media.type === 'image/jpeg') ?
																				<a href={media.name} data-type="image" data-width="100%" data-glightbox={"gallery"+cati+index} data-gallery={"gallery"+cati+index}>
																					<img data-length={item.media.length} className="card-img-top" src={media.name} height="200" alt={"Items "+index+medi}/>
																				</a>
																			:
																				<a href={media.name} data-type="video" data-width="100%" data-glightbox={"gallery"+cati+index} data-gallery={"gallery"+cati+index}>
																				<video className="w-100" controls>
																					<source src={media.name} type={media.type}/>
																					Your browser does not support the video tag.
																				</video>
																				</a>
																			}
																		</Carousel.Item>
																	)
																})}
																</Carousel>
																: <img className="card-img-top" src={window.location.origin+"/assets/img/no-media.png"} height="200" alt={"Items "+index}/>
																}
															</div>
															<div className="card-footer px-3 py-2">
																<div className="card-title">{item.name}</div>
																<div className="card-text mb-1">
																	<div className="row d-flex align-items-center">
																		<div className="col-sm-5 col-md-12 col-lg-5">
																			<div className="small_font text-center text-sm-start text-md-center text-lg-start">
																				<div>{Common.displayPrice(item.sale_total_price)}</div>
																				{item.discount>0 && <div><small className="text-decoration-line-through text-muted">{Common.displayPrice((Number(item.sale_price) + (Number(item.gst_amt)/(Number(item.sale_price)-Number(item.discount))).toFixed(2)*Number(item.sale_price)))}</small></div>}
																			</div>
																		</div>
																		<div className="col-sm-7 col-md-12 col-lg-7 mt-2 mt-sm-0 text-center text-sm-end text-md-center text-lg-end">
																			{item.service === '0' &&
																				<div className={"x_small_font text-primary "+(presentInCart(item.iid) ? "text-end" : "text-lg-end text-md-center text-sm-end text-center")}>Min: {Common.displayPercent(item.min_qty)} {item.item_unit}</div>
																			}
																			{presentInCart(item.iid) ? <div className="row">
																			<ButtonGroup size="sm" aria-label="Cart Button">
																			  <Button onClick={()=>decrementCart(item)} variant="success"><i className='bx bx-minus'></i></Button>
																			  <Button variant="success" className="fw-bold btnIncVal disabled">{cntItemsInCart(item.iid)}</Button>
																			  <Button onClick={()=>incrementCart(item)} variant="success"><i className='bx bx-plus'></i></Button>
																			</ButtonGroup>
																			</div> : <Button onClick={()=>addToCart(item)} size="sm" variant="outline-success">Add</Button>
																			}
																		</div>
																	</div>
																</div>
																{/*<p className="card-text">
																	<small className="text-muted">Last purchased {Math.floor(Math.random() * (10 - 1 + 1)) + 1} mins ago</small>
																</p>*/}
															</div>
														</div>
													</div>
													)
												})}
											</div>
										)
									})}
								</div>
							</div>
                        </div>
                    </div>
					<div className="fixed-bottom d-block d-md-none">
						<div className="d-grid mx-auto">
							<ButtonGroup aria-label="Cart Button">
								<Button onClick={()=>setShowCat(true)} variant="primary" className=""><i className='bx bx-md bx-list-ul'></i>Categories</Button>
								<Button onClick={()=>setShowCart(true)} variant="success" className="py-0 d-flex align-items-center justify-content-center"><i className={"bi bx-md align-text-bottom "+(cartItems.length === 0 ? "bi-cart ":"bi-cart-fill ") + cartAnimation}></i>
									<div className="ms-1 text-end align-text-bottom">
										{cartItems.length === 0 && 'My Cart'}
										{cartItems.length > 0 && <>{cartItems.length} items<br/> {Common.displayPrice(cartTotal)}</>}
									</div>
								</Button>
							</ButtonGroup>
						</div>
					</div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		</div>
    );
}