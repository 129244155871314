import React,{useState,useEffect}  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default function PoContractListing(props){
	const checkboxes = ['primary','item','import','sellerbank','custom'];
	const [checkAll,setCheckAll] = React.useState(false);
	const [totalDataCnt,setTotalDataCount] = React.useState({'Active':'','Cancelled':'','Expired':''});
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
	const [DTpending, setDTPending] = React.useState(true);
    const [purchase, setPurchaseList]    = useState([]);
    const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [exportBtnClass,setExportBtnClass]    = React.useState('d-none');
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
    const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
	const [expensePay_r,setexpensePay_r] = React.useState(true);
	const navigate = Common.useNavigate();
	const [uploadPaidModal, setUploadPaidModal] = useState(false);
	const [itemDocument,setItemDocument]        = useState('');
	const invoiceTabTypes = ['Active', 'Cancelled', 'Expired'];
	const [activeTab, setActCan] = React.useState(invoiceTabTypes[0]);
	const [showActiveDiv,setShowActiveDiv]     = React.useState(true);
    const [showCancelledDiv,setShowCancelledDiv] = React.useState(false);
    const [showDraftDiv,setShowDraftDiv] = React.useState(false);
	const [totSeller,setTotSeller] = React.useState({'Active':'-','Cancelled':'-','Expired':'-'});
	const [totInvAmt,setTotInvAmt] = React.useState({'Active':'-','Cancelled':'-','Expired':'-'});
	const [einvCancelShow,setEinvCancelShow] = React.useState(false);
	const [purchaseId,setPurchaseId] = React.useState('');
	const [invNo,setInvNo] = React.useState('');
	const [einvCancelRemarks,setEinvCancelRemarks] = React.useState('');
	const [can_type,setCanType] = React.useState('0');
	/* const [cancelCheckShow, setCancelCheckShow] = React.useState(false);
	const [cancelCheckMessage, setCancelCheckMessage] = React.useState(''); */
	const [exportModal,setExportModal] = React.useState(false);
	const [dlParams,setDlParams] = React.useState([]);
	const [expensePay_w,setexpensePay_w] = React.useState(true);
	const [sortOrder,setSortOrder] = React.useState('');
	const Tab = styled.button`
		font-size: 16px;
		padding: 10px 60px;
		cursor: pointer;
		opacity: 0.6;
		background: white;
		border: 0;
		outline: 0;
		background-color: #ecf0f1f2;
		${({ active }) =>
			active &&
			`
			border-bottom: 2px solid black;
			opacity: 1;
		`}
	`;
	const ButtonGroup = styled.div`
		display: flex;
	`;
    useEffect(() => {
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Read permission
			setexpensePay_r((userPerm[3]['view'] !== undefined && userPerm[3]['view'] === '0') ? false : true);
		}
        document.title = 'Expense Listing| '+props.site_name;
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Write permission
			setexpensePay_w((userPerm[3]['write'] !== undefined && userPerm[3]['write'] === '0') ? false : true);
		}
		loadDataTable(1,perPage,filterText,dates);
	},[props,dt_load,filterText]);
	if( !expensePay_r || !expensePay_w){
		navigate("/not-authorized");
	}
	const setDatePicker = (start, end) => {
		setDates({ start, end });
		setDtLoad(!dt_load);
    }
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
    const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,dates,sortOrder);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText,dates,sortOrder);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,dates,btoa(column.sortField+'####'+sortDirection));
	};
    const loadDataTable = (page,perPage,filterText,dates,sort='',active_tab=activeTab) => {
        Common.axios({
            method: 'post',
            url:props.url+'po_purchase/listing/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'dates':dates,'sort':sort,'search':filterText,'active_tab':active_tab}
        }).then(function (response) {
			setExportBtnClass('d-none');
            if(response.data.success){
                setPurchaseList(response.data.data);
				setTotalRows(response.data.total);
				// setTotalDataCount(response.data.total);
				setTotalDataCount(prevState => ({
				...prevState,
					[active_tab]: response.data.total
				}));
				setDTPending(false);
				if(response.data.total > 0){
					setExportBtnClass('');
				}
				setTotInvAmt(prevState => ({
				...prevState,
					[active_tab]: response.data.tabInfo.amount
				}));
				setTotSeller(prevState => ({
				...prevState,
					[active_tab]: response.data.tabInfo.vendor
				}));
            }
			if( response.data.action !== undefined && response.data.action === 'session'){
				Common.destroySession();
				navigate("/login");
				return false;
			}
        }).catch(error => console.log(error));
    }
	const checkAllItems = event => {
		if(event.target.checked){
			setDlParams(checkboxes);
			setCheckAll(true);
		} else {
			setDlParams([]);
			setCheckAll(false);
		}
	}
	const setDownloadParams = event => {
        if(event.target.checked){
            setDlParams([...dlParams, event.currentTarget.value]);
        } else {
            setDlParams(dlParams.filter(item=>item !== event.currentTarget.value));
        }
    }
	const handleDownloadPdf =(val,status,invNum) => {
		Common.showLoader(true);
		let url = props.url+'po_purchase/getData/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status));
        Common.axios({
            method: 'get',
            url:url,
            // responseType: 'blob',
        }).then(function (response) {
			Common.showLoader(false);
			// console.log(response.data);
            if(response.data.success){
				window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
				let currentdate = new Date();
				let timesec = currentdate.getTime().toString().substring(2, 6);
				let fileName = currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() + "_" + timesec;
                link.setAttribute('download', invNum+'_'+fileName+'.pdf');
                document.body.appendChild(link);
                link.click(); */
            }
            else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => {console.log(error);Common.showLoader(false);});
	}
	const handleExportCSV = (e)=>{
		e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'po_purchase/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'dates':dates,'search':filterText,'active_tab':activeTab,'dlParams':dlParams},
            // responseType: 'blob',
        }).then(function (response) {
			if(response.data.success){
				window.location.href = response.data.message
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Expense-${Date.now()}.csv`);
                document.body.appendChild(link); */
				setExportModal(false);
				setCheckAll(false);
				setDlParams([]);
                // link.click();
			} else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
	}
    const columns = [
    	{
    		name: 'Action',
			button: true,
			cell: (row) => (expensePay_r === true ? <><div className="dropdown">
				<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
				  <i className="bx bx-dots-vertical-rounded"></i>
				</button>
				<div className="dropdown-menu" >
					{/* <button className="dropdown-item" onClick={(e) => handleShareContent(row.id,2)}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button> */}
					{/* <button className="dropdown-item" onClick={(e) => handleShareContent(row.id,3)}><i className="bx bx-envelope"></i> Share via Email </button> */}
					{/* <button className="dropdown-item" onClick={(e) => handleCopyInvoice(row.id)}><i className='bx bxs-copy'></i> Copy Invoice</button> */}
					<button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.id,1,row.doc_no)}><i className="bx bxs-download"></i> Download</button>
				  	<button className="dropdown-item" onClick={(e) => handleCancelEinvoice(row)}><i className='bx bx-message-square-x'></i> Cancel PO</button>
				  	<button className="dropdown-item" onClick={(e) => handleExpireEinvoice(row)}><i className='bx bx-trash'></i> Mark As Expired</button>
				</div>
				<button type="button" className="btn p-1 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bx bx-share-alt"></i>
                </button>
				<div className="dropdown-menu">
					<button className="dropdown-item" onClick={(e) => handleShareContent(row.id,2)}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                  	<button className="dropdown-item" onClick={(e) => handleShareContent(row.id,3)}><i className="bx bx-envelope"></i> Share via Email </button>
				</div>
			  </div></>:<></>
			)
    	},
        {
            name: 'Invoice No',
            selector: row => row.doc_no,
            sortable: true,
			sortField: 'p.doc_no'
        },
        {
            name: 'Invoice Date',
            selector: row => row.doc_date,
            sortable: true,
			sortField: 'p.doc_date'
        },
        {
            name: 'Seller',
            selector: row => row.party_name,
            sortable: false,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt (₹)',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'p.total_amt'
        },
		{
            name: 'Download Attachment',
            selector: row => row.filepath,
            sortable: false,
			sortField: 'file_path',
			cell: (row) => (
				row.filepath !== null ? <><button type="button" onClick={(e) => handleDocDownload(row.filepath,row.id)} className="btn btn-sm btn-primary" style={{'width': '16.8rem'}}>Download</button> <i className='bx bx-trash' onClick={(e) => handleDocDelete(row.filepath,row.id)}></i></> : <button type="button" onClick={(e) => showUploadDocModal(true, row.id)} className="btn btn-sm btn-info" >Upload</button>
			)
        },
		{
			name: 'Created On',
			selector: row => row.created_on,
			sortable: true,
			sortField: 'p.created_on'
		},
		{
			name: 'Created By',
			selector: row => row.created_by,
			sortable: false,
			sortField: 'created_by'
		}
    ];
	const handleDocDownload = (filePath, purid) => {
        Common.showLoader(true);
        Common.axios({
            method: 'get',
            url:props.url+'po_purchase/doc_download/'+encodeURIComponent(btoa(filePath)) + '/' + localStorage.getItem('fo_profileid') + '/' + purid,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				Common.toast.dismiss();
				/* loadDataTable(1,perPage,filterText,dates,'',activeTab);
				window.location.href = props.url+response.data.url; */
                const url = props.url+response.data.url;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.data.file_name);
                document.body.appendChild(link);
                link.click();
                return false;
			} 
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    };
	/* const handleCopyInvoice =(val,action) => {
        let urlPath = (action === 'copy')?'add-expense':'expense-draft';
        navigate('/'+ urlPath,
    const handleCopyInvoice =(val) => {
        navigate('/add-expense',
        {
            state: {
                eid: val,
                action: action
            }
        });
    } */
	/*columns.push({
            name: 'Action',
            button: true,
            cell: (row) => (
                <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu" >                  
                  <button className="dropdown-item" onClick={(e) => handleShareContent(row.id,'wa')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                </div>
              </div>
            )
        }
    );*/
	const handleShareContent =(val,status) => {
		Common.axios({
			method: 'get',
			url: props.url+'po_purchase/getData/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status)),
		}).then(function (response) {
			if(response.data.success){
				console.log(response.data);
				if(parseInt(status) === 2){
					window.open("https://api.whatsapp.com/send?text="+response.data.data.body,"_blank");
				}else{
					window.open("mailto:?subject="+response.data.data.subject+"&body="+response.data.data.body,"_blank");
				}
			}
		}).catch(error => console.log(error));
	}
	function TabGroup() {
		const handleTab = penColChecked => {
			setActCan(penColChecked);
			loadDataTable(1,perPage,filterText,dates,sortOrder,penColChecked);
			if(penColChecked === "Cancelled"){
				setShowActiveDiv(false);
				setShowCancelledDiv(true);
				setShowDraftDiv(false);
			}
			else if(penColChecked === "Expired"){
				setShowActiveDiv(false);
				setShowCancelledDiv(false);
				setShowDraftDiv(true);
			}
			else{
				setShowActiveDiv(true);
				setShowCancelledDiv(false);
				setShowDraftDiv(false);
			}
		};
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{invoiceTabTypes.map(type => (
				<Tab
					key={type}
					active={activeTab === type}
					onClick={() => handleTab(type)}
				>
					{(totalDataCnt[type] !== '') ? type + ' ('+totalDataCnt[type]+')' : type}
					<div className="tabAdditionalInfo">
						Seller.# {totSeller[type]} | Tot. Amt.(₹) {Common.numToWords(totInvAmt[type])}
					</div>
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
	
	const cancelColumns = [
		/* {
			name: 'Action',
			button: true,
			cell: (row) => (
				<div className="dropdown">
				<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
				  <i className="bx bx-dots-vertical-rounded"></i>
				</button>
				<div className="dropdown-menu" >
					<button className="dropdown-item" onClick={(e) => handleCopyInvoice(row.id,'copy')}><i className='bx bxs-copy'></i> Copy Invoice</button>
				</div>
			  </div>
			)
		}, */
        {
            name: 'Invoice No.',
            selector: row => row.doc_no,
            sortable: true,
			sortField: 'inv_no'
        },
        {
            name: 'Invoice Date',
            selector: row => row.doc_date,
            sortable: true,
			sortField: 'doc_date',
			/* cell: (row) => (
				Common.displayDate(row.doc_date)
			) */
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: false,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        },
        {
            name: 'Cancel Reason',
            selector: row => row.cancel_reason,
            sortable: false,
        },
		{
			name: 'Cancel Date',
			selector: row => row.cancel_date,
			sortable: true,
			sortField: 'cancel_date'
		},
		{
			name: 'Created By',
			selector: row => row.created_by,
			sortable: false,
			sortField: 'created_by'
		},
		{
			name: 'Created On',
			selector: row => row.created_on,
			sortable: true,
			sortField: 'created_on'
		},
		{
			name: 'Updated By',
			selector: row => row.updated_by,
			sortable: false,
			sortField: 'updated_by'
		},
		{
			name: 'Updated On',
			selector: row => row.updated_on,
			sortable: true,
			sortField: 'updated_on'
		}
		
	];
	const handleDocument = (e) => {
        const fileTypes = ['application/pdf', 'image/jpeg', 'image/jpg','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/png', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
		if (e.target.files[0] !== undefined) {
			if (!fileTypes.includes(e.target.files[0].type)) {
				Common.toast.error('File format not supported');
				e.target.value = null;
				return false;
			} else {
				if (e.target.files[0].size > (5 * 1048576)) {
					Common.toast.error('File size should be less than 5MB');
					e.target.value = null;
					return false;
				} else {
					setItemDocument(e.target.files[0]);
				}
			}
		}
        Common.toast.dismiss();
    };
	const draftColumns = [
		/* {
			name: 'Action',
			button: true,
			cell: (row) => (
				<div className="dropdown">
				<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
				<i className="bx bx-dots-vertical-rounded"></i>
				</button>
				<div className="dropdown-menu" >
					<button className="dropdown-item" onClick={(e) => handleCopyInvoice(row.id,'edit')}><i className='bx bxs-edit'></i> Edit Expired</button>
				</div>
			</div>
			)
		}, */
		{
			name: 'Invoice No.',
			selector: row => row.doc_no,
			sortable: true,
			sortField: 'doc_no'
		},
		{
			name: 'Invoice Date',
			selector: row => row.inv_date,
			sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
		},
		{
			name: 'Seller',
			selector: row => row.party_name,
			sortable: false,
			sortField: 'party_name'
		},
		{
			name: 'Total Amt',
			selector: row => row.total_amt,
			sortable: true,
			sortField: 'total_amt'
		},
		{
            name: 'Download Attachment',
            selector: row => row.filepath,
            sortable: false,
			sortField: 'file_path',
			cell: (row) => (
				row.filepath !== '' ? <><button type="button" onClick={(e) => handleDocDownload(row.filepath,row.id)} className="btn btn-sm btn-primary" style={{'width': '10rem'}}>Download</button> {/* <i className='bx bx-trash' onClick={(e) => handleDocDelete(row.filepath,row.id)}></i> */}</> : <></>
			)
        },
		{
			name: 'Expired Date',
			selector: row => row.expiry_date,
			sortable: true,
			sortField: 'expiry_date'
		},
		{
			name: 'Created By',
			selector: row => row.created_by,
			sortable: false,
			sortField: 'created_by'
		},
		{
			name: 'Created On',
			selector: row => row.created_on,
			sortable: true,
			sortField: 'created_on'
		},
		{
			name: 'Updated By',
			selector: row => row.updated_by,
			sortable: false,
			sortField: 'updated_by'
		},
		{
			name: 'Updated On',
			selector: row => row.updated_on,
			sortable: true,
			sortField: 'updated_on'
		}
	];
	const handleCancelEinvoice = (details)=>{
		setInvNo(details.doc_no)
		setPurchaseId(details.id);
		setEinvCancelShow(true);
    }
	const handleExpireEinvoice = (details)=>{
		confirmAlert({
            title: 'Mark As Expired',
            message: 'Are you sure you want to mark '+ details.doc_no +' as Expired?',
            buttons: [{
                    label: 'Yes',
                    onClick: () => {
                        Common.showLoader(true);
                        Common.axios({
                            method: 'post',
                            url:props.url+'po_purchase/expireInvoice/',
                            data: {
                                filepath:details.filepath,
                                id:details.id
                            }
                        }).then(function (response) {
                            Common.showLoader(false);
                            if(response.data.success){
								loadDataTable(1,perPage,filterText,dates,sortOrder);
                                Common.toast.success(response.data.message);
                            } 
                            Common.toast.dismiss();
                        }).catch(error => console.log(error));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log('no')
                    }
                }
            ]
        });
    }
	const handleDocDelete = (filepath, id) => {
        confirmAlert({
            title: 'Delete Document',
            message: 'Are you sure you want to delete?',
            buttons: [{
                    label: 'Yes',
                    onClick: () => {
                        Common.showLoader(true);
                        Common.axios({
                            method: 'post',
                            url:props.url+'po_purchase/del_document/',
                            data: {
                                filepath:filepath,
                                id:id
                            }
                        }).then(function (response) {
                            Common.showLoader(false);
                            if(response.data.success){
								loadDataTable(1,perPage,filterText,dates,sortOrder);
                                Common.toast.success(response.data.message);
                            } 
                            Common.toast.dismiss();
                        }).catch(error => console.log(error));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log('no')
                    }
                }
            ]
        });
    }
	const showUploadDocModal = (flag, id) => {
        setPurchaseId(id)
        if (flag === false)
            setUploadPaidModal(false);
        else
            setUploadPaidModal(true);
    }
	const uploadDocument = (e) => {
        let fileData = new FormData();
        fileData.append('files', itemDocument);
        fileData.append('pid', localStorage.getItem('fo_profileid'));
        fileData.append('po_purchase_id', purchaseId);
		fileData.append('from_datatable', '1');
        Common.axios.post(props.url+'po_purchase/upload_file', fileData, {
            mode: 'cors',
            headers: { 'Content-Type':  'multipart/form-data' }
        }).then(function (response) {
            setUploadPaidModal(false);
			loadDataTable(1,perPage,filterText,dates,sortOrder);
        }).catch(error => console.log(error));
    }
	// check url for this cancel env
	const handleCancelEINV = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['id']      = purchaseId;
		data['pid']         = localStorage.getItem('fo_profileid');
		data['can_type']    = can_type;
		Common.axios({
            method: 'post',
            url:props.url+'po_purchase/cancelInvoice',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setEinvCancelShow(false);
				Common.toast.dismiss();
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
					window.location.reload(true);
					return false;
                }else{
                    Common.toast.error(response.data.message);
                }
                return false;
			} 
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="po-listing" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Expenses & Payments /</span> PO Contracts</h4>
							<div className="row">
								<div className="pb-3">
									<div className="col-md-12">
										<TabGroup/>
									</div>
								</div>
								<div id="sdt_cont" className="col-sm-12">
									{showActiveDiv && (<>
										<div className="row">
											<div className="col-md-3 col-12 listing-tabs mb-1">
												<button className={"btn btn-sm btn-info "+exportBtnClass} onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
												{expensePay_w && (<>
													<Common.Link to="/add-po" className="btn btn-sm btn-primary" style={{'marginLeft':'.5rem'}}> <i className="bx bx-plus"></i>Add PO</Common.Link>
												</>)}
											</div><div className="col-md-5 col-12 text-center mb-1">
												<DateRangePicker
													initialSettings={DTE.DatePickerPredefined(moment,start,end)}
													onCallback={setDatePicker}>
													<div className="input-group input-group-sm">
														<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
														<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
															<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
														</div>
													</div>
												</DateRangePicker>
												<small>Date range based on Invoice Date</small>
											</div>
											<div className="col-md-4 col-12 text-center text-md-end mb-1">
												<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Invoice Number, Customer')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
												<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
											</div>
											<div className="col-md-12">
												<DTE.DataTable title={"Bills"} className="rdt_AdjDrp" columns={columns} data={purchase} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange}   onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
											</div>
										</div>
									</>)}
									{showCancelledDiv && (<>
										<div className="row">
											<div className="col-md-3 col-12 mb-1">
												<button className={"btn btn-sm btn-info mobile-margin-left "+exportBtnClass} onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
											</div>
											<div className="col-md-5 col-12 text-center mb-1">
												<DateRangePicker
													initialSettings={DTE.DatePickerPredefined(moment,start,end)}
													onCallback={setDatePicker}>
													<div className="input-group input-group-sm">
														<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
														<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
															<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
														</div>
													</div>
												</DateRangePicker>
												<small>Date range based on Invoice Date</small>
											</div>
											<div className="col-md-4 col-12 text-center text-md-end mb-1">
												<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Invoice Number, Customer')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
												<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
											</div>
											<div className="col-md-12">
												<DTE.DataTable title={"Bills"} className="rdt_AdjDrp" columns={cancelColumns} data={purchase} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows}   onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
											</div>
										</div>
									</>)}
									{showDraftDiv && (<>
										<div className="row">
											<div className="col-md-3 col-12 mb-1">
												<button className={"btn btn-sm btn-info mobile-margin-left "+exportBtnClass} onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
											</div>
											<div className="col-md-5 col-12 text-center mb-1">
												<DateRangePicker
													initialSettings={DTE.DatePickerPredefined(moment,start,end)}
													onCallback={setDatePicker}>
													<div className="input-group input-group-sm">
														<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
														<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
															<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
														</div>
													</div>
												</DateRangePicker>
												<small>Date range based on Invoice Date</small>
											</div>
											<div className="col-md-4 col-12 text-center text-md-end mb-1">
												<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Invoice Number, Customer')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
												<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
											</div>
										</div>
										<DTE.DataTable title="Bills" className="dataTables_wrapper rdt_AdjDrp" columns={draftColumns} data={purchase} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</>)}
                                </div>
								
							</div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal size='md' show={einvCancelShow} onHide={()=>setEinvCancelShow(false)} aria-labelledby="Invoice-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Invoice-Cancel-modal">Cancel PO Contract ({invNo})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addEwbCancelFrm" onSubmit={handleCancelEINV} autoComplete="off">
					<div className="row">
						<div className="col-sm-12">
                            <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                            <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} required/>
						</div>
                        <div className="col-sm-12">
                            <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                            <div className="input-group input-group-merge">
                                <textarea rows="2" id="cancel-remarks" name="cancel_remarks" className="form-control" placeholder="Cancel Remarks" aria-label="CancelRemarks" aria-describedby="Remarks" value={einvCancelRemarks} onChange={(e) => setEinvCancelRemarks(e.target.value)} required></textarea>
                            </div>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-3">
							{/* <button type='submit' className="btn btn-sm btn-primary" disabled={(irnNo === null && irnCancelDate === null) || (irnNo !== null && irnCancelDate !== null)?true:false} onClick = {function(e){ setCanType(0);}}><i className='bx bx-message-square-x' ></i>&nbsp;Cancel IRN</button>&nbsp;&nbsp; */}
							<button type='submit' className="btn btn-sm btn-danger" /* disabled={(irnNo !== null && irnCancelDate === null)?true:false} */ onClick = {function(e){ setCanType(1);}}><i className='bx bx-message-square-x' ></i>&nbsp;Cancel Invoice</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
		<Modal show={exportModal} onHide={()=>setExportModal(false)} aria-labelledby="export-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="export-modal">Basic Export</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="my-1">
					Select options below to add more details to your report
					<div className="my-3">
						<div className="row justify-content-md-center">
							<div className="col-sm-4">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" id="itemAll" onChange={checkAllItems}/>
									<label className="form-check-label" htmlFor="itemAll"> Select All </label>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="primary" id="itemPrimary" checked={dlParams.includes('primary')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemPrimary"> All primary invoice details </label>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="item" id="itemdataCheck" checked={dlParams.includes('item')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemdataCheck"> Item Data </label>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="import" id="itemdataImport" checked={dlParams.includes('import')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemdataImport"> Import Data </label>
								</div>
							</div>
							<div className="col-md">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="sellerbank" id="itemdataSellerbank" checked={dlParams.includes('sellerbank')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemdataSellerbank"> Seller Bank Details </label>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="custom" id="itemdataCustom" checked={dlParams.includes('custom')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemdataCustom"> Purchase Custom Fields </label>
								</div>
							</div>
						</div>
					</div>
					<div className="alert alert-info" role="alert">
						<i className='bx bxs-info-square'></i> Segments having multiple entries for a single transaction record will get displayed in multiple rows
					</div>
					<div className="text-center">
						<button onClick={handleExportCSV} className="btn btn-sm btn-primary">Submit</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
		<Modal show={uploadPaidModal} size='sm' onHide={()=>showUploadDocModal(false, purchaseId)} aria-labelledby="Eway-Bill-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Select File to upload</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Default file input example</label>
                            <input className="form-control" type="file" name="formFile" id="formFile" onChange={handleDocument} />
                        </div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={(e) => uploadDocument(e)}>
				Upload
			</Button>
			<Button variant="danger" onClick={()=>showUploadDocModal(false,purchaseId)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
       </>
    );
}