import axios from "axios";
import toast from 'react-hot-toast';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link,useNavigate,useLocation } from 'react-router-dom';
import { AES } from 'crypto-js';
import parse from 'html-react-parser';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FYNTicket from './FYNTicket';
import Calculator from './Calculator';
import PartnerNeedHelp from './PartnerNeedHelp';
import moment from 'moment';
import { saveAs } from 'file-saver';
import  secureLocalStorage  from  "react-secure-storage";
export {secureLocalStorage};
export { axios };
export { toast };
export { Link };
export { useNavigate };
export { useLocation };
export { AES };
export {OverlayTrigger};
export { Container };
export { Form };
export { Row };
export { Col };
export { parse };
export { Calculator };
//export { FontAwesomeIcon };
export { FYNTicket };
export { PartnerNeedHelp };
export { saveAs };
export const date_format = 'DD-MM-YYYY';
export const acceptOnlyChars = (string) => {
	return string.replace(/[^a-z ]/gi, '');
};
export const acceptOnlyNumbers = (string) => {
	return string.replace(/[^0-9]/gi, '');
};
export const optRegOffices = [
	{ value: "0", label: "Registered Office" },
	{ value: "1", label: "Head Office" },
	{ value: "2", label: "Branch" },
	{ value: "3", label: "Warehouse" },
	{ value: "4", label: "Others" }
];
export const tooltipContent = (body) => (
	<Tooltip>
		{body}
    </Tooltip>
);
export const popoverContent = (header,body) => (
	<Popover id="popover-fo">
		<Popover.Header as="h6">{header}</Popover.Header>
		<Popover.Body>
		{body}
		</Popover.Body>
	</Popover>
);
export const displayPrice = (price) => {
	return parse('<i className="bx bx-rupee"></i>'+parseFloat(price).toFixed(2));
}
export const displayUnit = (value) => {
	return parse(parseFloat(value).toFixed(3).replace(/[.,]000$/, ""));
}
export const displayPercent = (value) => {
	return parse(parseFloat(value).toFixed(2).replace(/[.,]00$/, ""));
}
export const showLoader = (loading) => {
	const loader = document.querySelector('.lmask');
	loading ? loader.classList.remove('d-none') : loader.classList.add('d-none')
}
export const acceptOnlyCharsNumbers = (string) => {
	return string.replace(/[^a-z 0-9]/gi, '');
};
export const acceptOnlyCharsNumbersNoSpace = (string) => {
	return string.replace(/[^a-z0-9]/gi, '');
};
export const acceptUPI = (string) => {
	return string.replace(/[^a-z0-9-.]/gi, '');
};
export const acceptOnlyDecimal = (string) => {
	return string.replace(/[^0-9.]/gi, '');
};
export const stateIndex = (states,stateid) => {
	let val = '';

	if( typeof(states) === 'object' ){
		states.forEach((data,index) => {
			if( data.value === stateid ){
				val = index;
			}
			if( val !== '' ){
				return;
			}
		});
	}
	return val;
}
export const validGstin = (gstin) => {
	const gstinRegex = new RegExp(/[0-9]{2}[0-9a-zA-Z]{5}[0-9]{4}[0-9a-zA-Z]{2}[ZNRDCS0-9]{1}[0-9a-zA-Z]{1}/gi);
	return gstinRegex.test(gstin);
}
export const displayDate = (data) => {
	const date = moment(data);

	return date.format('DD-MM-YYYY');
}
export const displayDateTime = data => {
	const date = moment(data);

	return date.format('DD-MM-YYYY HH:mm:ss');
}
export const ewayBillCancelReason = [
	{ value: "1", label: "Duplicate" },
	{ value: "2", label: "Order Cancelled" },
	{ value: "3", label: "Data Entry mistake" },
	{ value: "4", label: "Others" }
];
export const numToWords = (val) => {
    if(val >= 10000000) val = (val/10000000).toFixed(2) + ' Cr';
    else if(val >= 100000) val = (val/100000).toFixed(2) + ' L';
    else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
    return val;
}
export const destroySession = () => {
	/*localStorage.removeItem('fo_loggedin');
	localStorage.removeItem('fo_profileid');
	localStorage.removeItem('fo_userperm');
	localStorage.removeItem('fo_subplan');
	localStorage.removeItem('fo_subvalidity');
	localStorage.removeItem('fo_plantype');
	localStorage.removeItem('fo_collaboration');
	localStorage.removeItem('mo_cust');
	localStorage.removeItem('fo_subModal');
	localStorage.removeItem('fo_subFinalModal');
	localStorage.removeItem('fo_userrole');
	localStorage.removeItem('mo_cust');*/
	localStorage.clear();
	secureLocalStorage.clear();
}
export const userInitial = (string) => {
	if( string === undefined ) return '';
	var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    } else {
		initials += names[0].substring(1, 2).toUpperCase();
	}
    return initials;
}