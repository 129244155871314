import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import * as Common from '../common/ImportUserEssentials';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Button from 'react-bootstrap/Button';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';

export default function AddBankTransfer(props){
	// User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	
	const [transfer_amount, setTransferAmount]   = useState('');
    const [commission_per, setCommissionPer]     = useState('');
    const [commission_amt, setCommissionAmt]     = useState('');
    const [transfer_date, setTransferDate]    	 = useState(new Date());
    const [trans_ref, setTransRef]    		  	 = useState('');
    const [transfer_remarks, setTransferRemarks] = useState('');
    const [transfer_total, setTransferTotal]     = useState('');
	const [disPerDisabled,setDisPerDisabled]  	 = useState(true);
	const [transferBankList,setTransferBankList] = useState([]);
    const [to_bank_account, setToBankAccount]    = useState('');
	const [bankList, setBankList]                = useState([]);
    const [bank_account, setBankAccount]         = useState('');
	// const [defaultPayee, setDefaultPayee]        = useState([]);
    const [bank_closing_balance, setBankClosingBalance] = useState('0.00');
    	
	const [accountMgmt_w,setaccountMgmt_w]       = useState(true);
	const navigate = Common.useNavigate();

	useEffect(() => {
		document.title = 'Add Bank Transfer | '+props.site_name;
		if( userPerm && Object.keys(userPerm).length > 0 ){
			setaccountMgmt_w((userPerm[1]['write'] !== undefined && userPerm[1]['write'] === '0') ? false : true);
		}
        Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/bank-list/'+localStorage.getItem('fo_profileid')+'/0/1',
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
			}
		}).catch(error => console.log(error));
	}, []);
	if( !accountMgmt_w ){
		navigate("/not-authorized");
	}
    const handleTransferBankList = (bankId)=>{
		if(bankId){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bank-list/'+localStorage.getItem('fo_profileid')+'/'+bankId+'/1',
			}).then(function (response) {
				if(response.data.success){
					setTransferBankList(response.data.items);
                    setBankClosingBalance(Number(response.data.closing_balance).toFixed(2));
				}
			}).catch(error => console.log(error));
		}
    }
    const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		///let label = (e[0]) ? e[0].label : '';
		if(value){
			setBankAccount(value);
			// setDefaultPayee([{'value':value,'label':label,'digits':''}]);
			handleTransferBankList(value);
		}else{
			setBankAccount('');
			// setDefaultPayee([]);
			handleTransferBankList('');
		}
	}
	const setToBankSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setToBankAccount(value);
	}
	const calcCommission = (param,val) => {
		let percent = 0,amount = 0.00;
		let tot_cal_amt = (transfer_amount > 0)?transfer_amount:0;
		if( param === 'per' ){
			percent = val;
			amount = (tot_cal_amt * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/tot_cal_amt * 100).toFixed(2);
		}
		setCommissionPer(percent);
		setCommissionAmt(amount);
		setTransferTotal(transfer_amount - amount);
	}
	const addBankTransfer = (e)=>{
        e.preventDefault();
        if (bank_account === '') {
            Common.toast.error('Choose From Bank');
            return false;
        }
        if (to_bank_account === '') {
            Common.toast.error('Choose To Bank');
            return false;
        }
		if(transfer_amount === '' || Number(transfer_amount) === 0){
			Common.toast.error('Amount should be greater than 0 and lesser than the closing balance');
			return false;
		}
        if(Number(bank_closing_balance) < Number(transfer_amount)){
			Common.toast.error('Amount should be greater than 0 and lesser than the closing balance');
			return false;
		}

        Common.axios({
            method: 'post',
            url:props.url+'bank-accounts/add_bank_passbook',
            data: {
                pid:localStorage.getItem('fo_profileid'),from_bank_account:bank_account,to_bank_account:to_bank_account,transfer_amount:transfer_amount,commission_amt:commission_amt,transfer_date:transfer_date,trans_ref:trans_ref,transfer_remarks:transfer_remarks
            },
        }).then(function (response) {
			if(response.data.success){
                navigate("/bank-accounts",
				{
					state: {
						tab_type: 2,
					}
				});
				return false;
			}
			Common.toast.dismiss();
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }

    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="add-bank-account" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
						    <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Account Management / <a href="/bank-accounts" className="text-muted">Bank Accounts</a> /</span> Add Bank Transfer</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            <form id="formBankAccount" autoComplete="off" >
                                                <div className="row">
                                                    <div className='col-md-6'>
                                                        <div className='row'>
                                                            <div className="mb-3 col">
                                                                <label className="col-4 col-form-label" htmlFor="frmFromBank">From Bank</label>
                                                                <Typeahead clearButton id="frmFromBank" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose From Bank"
                                                                    options={bankList}
                                                                    renderMenuItemChildren={(option: Item,props) => (
                                                                        <Highlighter search={props.text}>
                                                                            {option.label}
                                                                        </Highlighter>
                                                                    )}
                                                                />
                                                                {bank_account !== '' && (<>
                                                                    <small className='text-success'>Balance Amount: {Number(bank_closing_balance).toFixed(2)}</small>
                                                                </>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='row'>
                                                            <div className='mb-3 col'>
                                                                <label className="col-4 col-form-label" htmlFor="frmToBank">To Bank</label>
                                                                <Typeahead clearButton id="frmToBank" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setToBankSelect(e)} placeholder="Choose To Bank"
                                                                    options={transferBankList}
                                                                    renderMenuItemChildren={(option: Item,props) => (
                                                                        <Highlighter search={props.text}>
                                                                            {option.label}
                                                                        </Highlighter>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='row'>
                                                            <div className='mb-3 col'>
                                                                <label className="col-4 col-form-label" htmlFor="frmTransferTotal">Transfer Amount <span className="text-danger">*</span></label>
                                                                <div className="input-group input-group-merge">
                                                                    <span className="input-group-text"><i className='bx bx-rupee'></i></span>
                                                                    <input type="text" className="form-control" id="frmTransferTotal" placeholder="0.00" value={transfer_amount}
                                                                        onChange={(e) => {
                                                                            setTransferAmount(Common.acceptOnlyDecimal(e.target.value));
                                                                            setCommissionPer('');
                                                                            setCommissionAmt('');
                                                                            setTransferTotal(Common.acceptOnlyDecimal(e.target.value));
                                                                            (e.target.value > 0 ? setDisPerDisabled(false):setDisPerDisabled(true))
                                                                        }}
                                                                    autoComplete="off"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='row'>
                                                            <label className="col-form-label" htmlFor="frmTransferComPer">Commission <span className="text-danger">*</span></label>
                                                            <div className="col-sm-6">
                                                                <div className="input-group input-group-merge">
                                                                    <span className={"input-group-text "+(disPerDisabled ? "disabled-bg" : "")}><i className='bx bxs-offer'></i></span>
                                                                    <input type="text" className="form-control" id="frmTransferComPer" placeholder="0.00" value={commission_per} onChange={(e) => calcCommission('per',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="input-group input-group-merge">
                                                                    <span className={"input-group-text "+(disPerDisabled ? "disabled-bg" : "")}><i className='bx bx-rupee'></i></span>
                                                                    <input type="text" className="form-control" id="frmTransferComAmt" placeholder="0.00" value={commission_amt} onChange={(e) => calcCommission('amt',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='row'>
                                                            <div className='mb-3 col'>
                                                                <label className="col-4 col-form-label" htmlFor="frmTransferTotal">Net Transfer <span className="text-danger">*</span></label>
                                                                <div className="input-group input-group-merge">
                                                                    <span className="input-group-text disabled-bg"><i className='bx bx-rupee'></i></span>
                                                                    <input readOnly type="text" className="form-control" id="frmTransferTotal" placeholder="0.00" value={Number(transfer_total).toFixed(2)}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='row'>
                                                            <div className='mb-3 col'>
                                                                <label className="col-4 col-form-label" htmlFor="frmBankPayDate">Trans date</label>
                                                                <DatePicker data-testid="frmBankPayDate" dayPlaceholder="dd" monthPlaceholder="mm" id='bank-transfer-datepicker' yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setTransferDate} value={transfer_date} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='row'>
                                                            <div className='mb-3 col'>
                                                                <label className="col-4 col-form-label" htmlFor="frmBankTransRef">Transaction Ref. No.</label>
                                                                <input type="text" maxLength="100" className="form-control" id="frmBankTransRef" placeholder="Transaction Ref." value={trans_ref} onChange={(e) => setTransRef(e.target.value)} autoComplete="off"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='row'>
                                                            <div className='mb-3 col'>
                                                                <label className="col-4 col-form-label" htmlFor="frmBankPayRemarks">Transfer Remarks</label>
                                                                <input type="text" className="form-control" id="frmBankPayRemarks" placeholder="Transfer Remarks" value={transfer_remarks} onChange={(e) => setTransferRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-7">
                                                        <a href="/bank-accounts" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                        <Button variant="primary" size="sm" className="ms-1" onClick={addBankTransfer}><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}