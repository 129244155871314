import React  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import Modal from 'react-bootstrap/Modal';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;

export default function BankAccounts(props){
	// const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [filterText, setFilterText] = React.useState('');
	const [DTpending, setDTPending] = React.useState(true);
    const [bank_accounts, setBankAccounts]    = React.useState([]);
	const [dt_load,setDtLoad] = React.useState(true);
	const [viewModal, setViewModal] = React.useState(false);
	const [bank,setBank] = React.useState({payee_name:'',account_no:'',ifsc_code:'',bank_name:'',payment_terms:'',payment_instructions:'',nickname:'',bank_branch_name:'',status:''});
	const handleViewClose = () => setViewModal(false);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [totalDataCnt,setTotalDataCount] = React.useState({'Bank Accounts':'','Bank Passbook':'','Bank Transfer':''});
	const bankTabTypes = ['Bank Accounts', 'Bank Passbook','Bank Transfer'];
	const [activeTab, setActiveTab] = React.useState(bankTabTypes[0]);
	const [bankList, setBankList] = React.useState([]);
	const [defaultPayee, setDefaultPayee] = React.useState([{'value': 'All', 'label': 'All','digits':''}]);
	// const [defaultPayee, setDefaultPayee] = React.useState([]);
    const [bank_account, setBankAccount]    = React.useState('All');
    const [bank_opening_balance, setBankOpeningBalance]    		  = React.useState('0.00');
    const [bank_closing_balance, setBankClosingBalance]    		  = React.useState('0.00');
	const [bankPassbookModel, setBankPassbookModel]				  = React.useState(false);
	const [openingBalanceModel, setOpeningBalanceModel]		      = React.useState(false);
    const [opening_balance,setOpeningBalance]           		  = React.useState('');
	const [opening_balance_date, setOpeningBalanceDate] 		  = React.useState(new Date());
    const [exportBtnClass,setExportBtnClass]    				  = React.useState('d-none');
    const [bankNickName,setBankNickName]    				  	  = React.useState('');
    const [modalNewOpeningBalance,setModalNewOpeningBalance]      = React.useState('0.00');
    const [modalOpeningBalanceRemarks,setModalOpeningBalanceRemarks]  = React.useState('');
    const [modalOpeningBalance,setModalOpeningBalance]   		  = React.useState('');
    const [modalOpeningBalanceDate,setModalOpeningBalanceDate]    = React.useState(new Date());
    const [modalbid,setModalBid]                                  = React.useState('');
    const [modalNickName,setModalNickName]                                  = React.useState('');
    const [trials,setTrials]                                       = React.useState([]);
	const [bankAccountTypes, setBankAccountTypes]                  = React.useState('All');
	const [bankAccountTypeSelected, setBankAccountTypeSelected]    = React.useState([{'value': 'All', 'label': 'All','digits':''}]);
	const [allBankAccountTypes, setAllBankAccountTypes]            = React.useState([{value: 'All', label: 'All'},{value:'B',label:'Bank Account'}, {value:'C',label:'Credit Card'}, {value:'P',label:'Prepaid Instruments'}, {value:'R',label:'Cash'}, {value:'F',label:'Deposits'}])
	const [sortOrder, setSortOrder]                                = React.useState('');
	const navigate 												  = Common.useNavigate();

	// User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	const [accountMgmt_w,setaccountMgmt_w] = React.useState(true);
	
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
    const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
	let state = Common.useLocation();

    React.useEffect(() => {
        document.title = 'Bank Accounts | '+props.site_name;
		let passedOnTab = bankTabTypes[0]
		if (state.state !== null) {
			setActiveTab(bankTabTypes[state.state.tab_type])
			if (state.state.tab_type === 2) {
				passedOnTab = bankTabTypes[state.state.tab_type]
				setShowBankAccountDiv(false);
				setShowBankPassbookDiv(false);
				setShowBankTransferDiv(true);
			}
		}
		if( userPerm && Object.keys(userPerm).length > 0 ){
			setaccountMgmt_w((userPerm[1]['write'] !== undefined && userPerm[1]['write'] === '0') ? false : true);
		}
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/bank-list/'+localStorage.getItem('fo_profileid')+'/0/0',
		}).then(function (response) {
			if(response.data.success){
				setBankAccount('All');
				setBankList([{value:"All", label:"All"},...response.data.items]);
			}
		}).catch(error => console.log(error));
		loadDataTable(1,perPage,filterText,dates,sortOrder,passedOnTab,bank_account);
	}, [props,dt_load,filterText]);
	const loadDataTable = (page,perPage,filterText,dates,sort='',active_tab=activeTab,bank_account='All',display_bank_acc='All') => {
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'bank-accounts/listing/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'dates':dates,'search':filterText,'active_tab':active_tab,bank_account:bank_account,'display_bank_acc':display_bank_acc}
        }).then(function (response) {
			setExportBtnClass('d-none');
			Common.showLoader(false);
            if(response.data.success){
				setBankAccounts(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(prevState => ({
				...prevState,
					[active_tab]: response.data.total
				}));
				if(response.data.data.length > 0){
					setExportBtnClass('d-block');
				}
				setDTPending(false);
				setBankOpeningBalance(Number(response.data.opening_balance).toFixed(2));
				setBankClosingBalance(Number(response.data.closing_balance).toFixed(2));
				if(active_tab === "Bank Passbook" && response.data.opening_balance_status === 0 && bank_account !=='' && bank_account !== "All"){
					handleOpeningBalanceModel('show');
				}
            }
        }).catch(error => console.log(error));
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,dates,sortOrder,activeTab,bank_account,bankAccountTypes);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText,dates,sortOrder,activeTab,bank_account,bankAccountTypes);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection));
		loadDataTable(1,perPage,filterText,dates,btoa(column.sortField+'####'+sortDirection),activeTab,bank_account,bankAccountTypes);
	};
	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setDtLoad(!dt_load);
				setFilterText('');
			}
		};
		return (
			<React.Fragment>
				<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Account Holder Name, Bank Name, Nickname')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
				<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
			</React.Fragment>
		);
	}, [filterText, dt_load]);
	const bankAccountStatus = (checked,id, nickName,status) => {
		if (status === '1') {
			confirmAlert({
				title: 'Disable Bank Account',
				message: 'Are you sure you want to Disable ' + nickName + ' account? This Bank Account will not be available for selection on any Collection, Payment or Bank transfer activities',
				buttons: [{
						label: 'Yes',
						onClick: () => {
							Common.showLoader(true);
							Common.axios({
								method: 'post',
								url: props.url+'bank-accounts/update',
								data: {status:checked,id:id,pid:localStorage.getItem('fo_profileid')}
							}).then(function (response) {
								Common.showLoader(false);
								if(response.data.success){
									setBankAccountTypeSelected([{'value': 'All', 'label': 'All','digits':''}])
									loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,bank_account,bankAccountTypes);
									Common.toast.success(response.data.message);
									return false;
								}
								Common.toast.error(response.data.message);
							}).catch(error => console.log(error));
						}
					},
					{
						label: 'No',
						onClick: () => {
							window.location.reload();
						}
					}
				]
			});
		} else {
			Common.showLoader(true);
			Common.axios({
				method: 'post',
				url: props.url+'bank-accounts/update',
				data: {status:checked,id:id,pid:localStorage.getItem('fo_profileid')}
			}).then(function (response) {
				Common.showLoader(false);
				if(response.data.success){
					setBankAccountTypeSelected([{'value': 'All', 'label': 'All','digits':''}])
					loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,bank_account,bankAccountTypes);
					Common.toast.success(response.data.message);
					return false;
				}
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
		
	}
	const viewDetails = (val) => {
		Common.axios({
            method: 'get',
            url:props.url+'bank-accounts/details/'+localStorage.getItem('fo_profileid')+'/'+val,
        }).then(function (response) {
            if(response.data.success){
                setBank(response.data.data);
				setViewModal(true);
				return false;
            }
        }).catch(error => console.log(error));
	}
	const makeDefault =(val,type) => {
		if(type !== 'B'){
			Common.toast.error('Not Available');
			return false;
		}
        Common.axios({
            method: 'post',
            url: props.url+'bank-accounts/update',
            data: {'default':1,id:val,pid:localStorage.getItem('fo_profileid')},
        }).then(function (response) {
            if(response.data.success){
				setTimeout(()=>{setDtLoad( (dt_load) ? false : true)},500);
            }
        }).catch(error => console.log(error));
    }
    const columns = [
    	{
            name: 'Action',
            button: true,
			cell: (row) => ( accountMgmt_w === true ? <><div className="dropdown">
				<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="true">
				  <i className="bx bx-dots-vertical-rounded"></i>
				</button>
				<div className="dropdown-menu datatable_menu " data-popper-placement="bottom-end" >
					<button onClick={(e) => viewDetails(row.id)}  className="dropdown-item"><i className="bx bx-book-reader me-1"></i>View</button>
					{(row.default === '0' && row.status === '1') && <>
					<button onClick={(e) => makeDefault(row.id,row.type)}  className="dropdown-item"><i className="bx bx-lock-alt me-1"></i> Mark Default</button>
					</>}
					<button onClick={(e) => handleBankPassBook(row.id,row.nickname)}  className="dropdown-item"><i className='bx bxs-bank'></i> Bank Passbook</button>
					<button className="dropdown-item" onClick={(e) => handleEditBankAccount(row.id)}><i className='bx bxs-edit'></i> Edit Bank Account</button>
					<button className="dropdown-item" onClick={(e) => handleEditOpeningBalance(row.id)}><i className='bx bxs-edit'></i> Edit Opening Balance</button>
					<button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.id)}><i className="bx bxs-download"></i> Download O/b Trail</button>
				</div>
			  </div></> :<></>
			)
        },
		{
            name: 'Status',
            selector: row => row.status,
            sortable: false,
			sortField: 'status',
			cell: (row) => (
				row.default === '1' ? <Common.OverlayTrigger overlay={Common.popoverContent('Why status cannot be changed ?','You cannot change the status of your default bank account. Make another bank account as default first and then to do this activity.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger> :
				<BootstrapSwitchButton
					checked={(row.status === '1' )}
					onlabel='Active'
					offlabel='Inactive'
					onstyle="success"
					offstyle="danger"
					size="sm"
					width={250}
					onChange={(checked: boolean) => {
						bankAccountStatus(checked,row.id, row.nickname,row.status);
					}}
				/>
			)
        },
        {
            name: 'Account Holder Name',
            sortable: true,
			sortField: 'payee_name',
			cell: (row) => (
				row.payee_name + (row.default === '1' ? ' (Default)':'')
			)
        },
        {
            name: 'Bank Name',
            selector: row => row.type,
            sortable: true,
			sortField: 'bank_name',
			cell: (row) => (
				row.bank_name
			)
        },
		{
            name: 'Nick Name',
            selector: row => row.type,
            sortable: true,
			sortField: 'nickname',
			cell: (row) => (
				row.nickname
			)
        },
		{
            name: 'Type',
            selector: row => row.bank_type,
            sortable: true,
			sortField: 'bank_type',
			cell: (row) => (
				row.bank_type
			)
        },
		{
            name: 'Current balance (₹)',
            selector: row => row.closing_balance,
            sortable: false,
			sortField: 'closing_balance',
			cell: (row) => (
				Number(row.closing_balance).toFixed(2)
			)
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
			sortField: 'created_by',
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'b.createdon',
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
			sortField: 'updated_by',
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'b.updated_on',
        },

    ];
	const passbookColumns = [
        {
            name: 'Transaction Date',
            sortable: true,
			sortField: 'trans_date',
			cell: (row) => (
				row.trans_date
			)
        },
        {
            name: 'Particulars',
            selector: row => row.particulars,
            sortable: false,
			cell: (row) => (
				row.particulars
			)
        },
		{
			name: 'Debit',
            selector: row => row.debit,
            sortable: false,
			cell: (row) => (
				row.debit
			)
        },
		{
			name: 'Credit',
			selector: row => row.credit,
			sortable: false,
			cell: (row) => (
				row.credit
			)
		}
    ];
	if( bank_account === 'All' ) {
		passbookColumns.push({
            name: 'Bank Name',
            selector: row => row.bank_name,
            sortable: false,
			cell: (row) => (
				row.bank_name
			)
        });
	}
	const bankTransferColumns = [
        {
            name: 'From Bank',
            sortable: false,
			sortField: 'from_bank',
			cell: (row) => (
				row.from_bank
			)
        },
        {
            name: 'To Bank',
            selector: row => row.to_bank,
            sortable: false,
			cell: (row) => (
				row.to_bank
			)
        },
		{
            name: 'Transfer Amount',
            selector: row => row.collected_amt,
            sortable: false,
			cell: (row) => (
				Number(row.collected_amt).toFixed(2)
			)
        },
		{
            name: 'Commission',
            selector: row => row.commission_amt,
            sortable: false,
			cell: (row) => (
				Number(row.commission_amt).toFixed(2)
			)
        },
		{
            name: 'Net Transfer',
            selector: row => row.total_amt,
            sortable: false,
			cell: (row) => (
				Number(row.total_amt).toFixed(2)
			)
        },
		{
            name: 'Trans date',
            selector: row => row.trans_date,
            sortable: false,
			cell: (row) => (
				row.trans_date
			)
        },
		{
            name: 'Transaction Ref. No',
            selector: row => row.trans_ref_no,
            sortable: false,
			cell: (row) => (
				row.trans_ref_no
			)
        },
		{
            name: 'Transfer Remarks',
            selector: row => row.trans_remarks,
            sortable: false,
			cell: (row) => (
				row.trans_remarks
			)
        }
    ];
	const handleEditBankAccount =(val) => {
		let urlPath = 'edit-bank-account';
        navigate('/'+ urlPath,
        {
            state: {
                bid: val
            }
        });
    }
	const handleEditOpeningBalance = (val) => {
		Common.axios({
            method: 'post',
            url:props.url+'bank-accounts/get-opening-balance',
            data: {
                pid:localStorage.getItem('fo_profileid'),id:val
            },
        }).then(function (response) {
			if(response.data.success){
				setModalBid(response.data.data.id);
				console.info(response.data.trials);
				setModalNickName(response.data.data.nickname);
				setTrials(response.data.trials.length > 0 ? response.data.trials : [])
				setModalOpeningBalance(response.data.data.opening_balance !== null ? response.data.data.opening_balance : '');
				if (response.data.data.opening_balance_date !== null) {
					setModalOpeningBalanceDate(response.data.data.opening_balance_date);
				} else {
					setModalOpeningBalanceDate(new Date());
				}
				setOpeningBalanceModel(true)
			}
        }).catch(error => console.log(error));
	}
	const handleDownloadPdf = (bankId) => {
		let pid = localStorage.getItem('fo_profileid')
		Common.axios({
			method: 'get',
			url: props.url+'bank-accounts/download_pdf/'+encodeURIComponent(btoa(bankId))+'/'+encodeURIComponent(btoa(pid)),
		}).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
			}
		}).catch(error => console.log(error));
	}
	const setDatePicker = (start, end) => {
		setDates({ start, end });
		let dates_range = {
			start: start,
			end: end,
		}
		loadDataTable(1,perPage,filterText,dates_range,sortOrder,activeTab,bank_account,bankAccountTypes);
	}
	const handleBankPassBook = (bId,nickName) => {
		setBankAccount(bId);		
		setDefaultPayee([{'value':bId,'label':nickName,'digits':''}]);
		setActiveTab('Bank Passbook');
		loadDataTable(1,perPage,filterText,dates,sortOrder,'Bank Passbook',bId,bankAccountTypes);
		setShowBankAccountDiv(false);
		setShowBankPassbookDiv(true);
	}
	const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		if(value){
			setBankAccount(value);
			setBankNickName(label);
			setDefaultPayee([{'value':value,'label':label,'digits':''}]);
			loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,value);
		}else{
			setBankAccount('');
			setBankNickName('');
			setDefaultPayee([]);
			loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,'');
		}
	}
	const setBankAccountSelectTabOne = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		console.log(value);
		if(value){
			setBankAccountTypes(value);
			setBankAccountTypeSelected([{'value':value,'label':label,'digits':''}]);
			loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,'All', value);
		}else{
			setBankAccountTypes('');
			setBankNickName('');
			setBankAccountTypeSelected([]);
			loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,'All', '');
		}
	}
	const handleOpeningBalanceModel = (param) => {
		if( param === 'show' ){
			setBankPassbookModel(true);
		}
		if( param === 'hide' ){
			setBankPassbookModel(false);
			setOpeningBalance();
			setOpeningBalanceDate(new Date());
		}
	}
	const addOpeningBalance = ()=>{
        Common.axios({
            method: 'post',
            url:props.url+'bank-accounts/update',
            data: {
                pid:localStorage.getItem('fo_profileid'),opening_balance:opening_balance,opening_balance_date:opening_balance_date,id:bank_account
            },
        }).then(function (response) {
			if(response.data.success){
                setBankPassbookModel(false);
				setOpeningBalance();
				setOpeningBalanceDate(new Date());
				Common.toast.success(response.data.message);			
            	loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,bank_account,bankAccountTypes);
				return false;
			}
			Common.toast.dismiss();
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
	const updateOpeningBalance = () => {
		Common.axios({
            method: 'post',
            url:props.url+'bank-accounts/update-opening-balance',
            data: {
                pid:localStorage.getItem('fo_profileid'),
				old_opening_balance:modalOpeningBalance,
				new_opening_balance:modalNewOpeningBalance,
				opening_bal_date:modalOpeningBalanceDate,
				remarks: modalOpeningBalanceRemarks,
				id:modalbid
            },
        }).then(function (response) {
			if(response.data.success){
                setOpeningBalanceModel(false);
				setModalNewOpeningBalance('0.00');
				setModalOpeningBalanceRemarks('')
				setBankAccountTypeSelected([{'value': 'All', 'label': 'All','digits':''}])
				Common.toast.success(response.data.message);			
            	loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,bank_account,bankAccountTypes);
				return false;
			}
			Common.toast.dismiss();
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const handleExportCSV = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'bank-accounts/download_csv/',
			data: {'pid':localStorage.getItem('fo_profileid'),'dates':dates,'search':filterText,'active_tab':activeTab,bank_account:bank_account},
        }).then(function (response) {
			if(response.data.success){
				window.location.href = response.data.message;
			}
        }).catch(error => console.log(error));
    }
	const [showBankAccountDiv,setShowBankAccountDiv]   = React.useState(true);
    const [showBankPassbookDiv,setShowBankPassbookDiv] = React.useState(false);
    const [showBankTransferDiv,setShowBankTransferDiv] = React.useState(false);
	function TabGroup() {
		const handleTab = bankChecked => {
			setExportBtnClass('d-none');
			setActiveTab(bankChecked);
			// setBankAccount('');
			// setDefaultPayee([]);
			// setBankAccountTypes(['B', 'C', 'P', 'R', 'F'])
			setBankAccountTypeSelected([{value:'All',label:'All'}])
			setAllBankAccountTypes([{value:'All',label:'All'},{value:'B',label:'Bank Account'}, {value:'C',label:'Credit Card'}, {value:'P',label:'Prepaid Instruments'}, {value:'R',label:'Cash'}, {value:'F',label:'Deposits'}])
			setSortOrder('')
			loadDataTable(1,perPage,filterText,dates,'',bankChecked,bank_account,bankAccountTypes);
			state.state = null
			if(bankChecked === "Bank Passbook"){
				setShowBankAccountDiv(false);
				setShowBankPassbookDiv(true);
				setShowBankTransferDiv(false);
			}
			else if(bankChecked === "Bank Transfer"){
				setShowBankAccountDiv(false);
				setShowBankPassbookDiv(false);
				setShowBankTransferDiv(true);
			}
			else{
				setShowBankAccountDiv(true);
				setShowBankPassbookDiv(false);
				setShowBankTransferDiv(false);
			}
		};
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{bankTabTypes.map(type => (
				<Tab
					key={type}
					active={activeTab === type}
					onClick={() => handleTab(type)}
				>
					{type === 'Bank Accounts'?(totalDataCnt[type] !== '') ? type + ' ('+totalDataCnt[type]+')' : type:type}
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
    return (
		<>
		<Modal show={viewModal} onHide={handleViewClose} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>Details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Common.Row>
					<Common.Col><strong>Payee Name : </strong> {bank.payee_name}</Common.Col>
					<Common.Col><strong>Account Number : </strong> {bank.account_no}</Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col><strong>IFSC Code : </strong> {bank.ifsc_code}</Common.Col>
					<Common.Col><strong>Bank Name: </strong> {bank.bank_name}</Common.Col>
				</Common.Row>
				<Common.Row>
					<Common.Col><strong>Payment Terms : </strong> {bank.payment_terms}</Common.Col>
					<Common.Col><strong>Payment Instructions: </strong> {bank.payment_instructions}</Common.Col>					
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col><strong>Nick Name : </strong> {bank.nickname}</Common.Col>
					<Common.Col><strong>Status : </strong> {bank.status === '1' ? 'Active' : 'Inactive'}</Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col><strong>Bank Branch Name : </strong> {bank.bank_branch_name}</Common.Col>
				</Common.Row>
			</Modal.Body>
		</Modal>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="bank-accounts" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Account Management /</span> Banking</h4>
							<div className="row ">
								<div className="col-sm-12">
									<div className="row pb-3">
										<div className="col-md-8">
											<TabGroup/>
										</div>
									</div>
								</div>
								<div id="sdt_cont" className="col-sm-12">
									{showBankAccountDiv && (<>
										<div className="row">
											<div className="col-md-4 col-sm-4 col-4 mb-1 align-middle">
												<b>Total Balance: {bank_closing_balance}</b>
											</div>
											<div className="col-md-4 text-sm col-sm-4 col-4 text-center mb-1">
											<Typeahead selected={bankAccountTypeSelected} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelectTabOne(e)} placeholder="Choose Bank Account"
												options={allBankAccountTypes}
												renderMenuItemChildren={(option: Item,props) => (
													<Highlighter search={props.text}>
														{option.label}
													</Highlighter>
												)}
											/>
											</div>
											<div className="col-md-4 col-sm-4 col-4 mb-1">
												{ accountMgmt_w && (<>
												<div className="my-2 d-flex justify-content-end">
													<a href="/add-bank-account" className="btn btn-sm btn-primary">
														<i className='bx bx-plus'></i> Bank Account
													</a>
												</div>
												</>)}
											</div>
										</div>
										<DTE.DataTable className="rdt_AdjDrp" columns={columns} data={bank_accounts} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} subHeader subHeaderComponent={subHeaderComponentMemo} persistTableHead/>
									</>)}
									{showBankPassbookDiv && (<>
										<div className="row">
											<div className="col-md-2 col-4 mb-1">
												<div className="btn-group btn-group-sm" role="group">
													<button className={"btn btn-sm btn-info "+exportBtnClass} onClick={handleExportCSV}>
														<span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
														<i className="bx bx-download d-block d-sm-none"></i>
													</button>
												</div>
											</div>
											<div className="col-md-3 col-8 mb-1">
											<Typeahead selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
												options={bankList}
												renderMenuItemChildren={(option: Item,props) => (
													<Highlighter search={props.text}>
														{option.label}
													</Highlighter>
												)}
											/>
											</div>
											<div className="col-md-4 col-12 text-center mb-1">
												<DateRangePicker
													initialSettings={DTE.DatePickerPredefined(moment,start,end)}
													onCallback={setDatePicker}>
													<div className="input-group input-group-sm">
														<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
														<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
															<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
														</div>
													</div>
												</DateRangePicker>
												<small>Date range based on Payment Date</small>
											</div>
											<div className="col-md-3 col-12 text-center mb-1">
												<b>Opening Balance: {bank_opening_balance}</b>
											</div>
										</div>
										<DTE.DataTable className="rdt_AdjDrp" columns={passbookColumns} data={bank_accounts} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}  persistTableHead/>
										<div className="row">
											<div className="col-md-12 col-12 mt-2 p-0">
												<b>Closing Balance: {bank_closing_balance}</b>
											</div>
										</div>
									</>)}
									{showBankTransferDiv && (<>
										<div className="row">
											<div className="col-md-4 col-sm-4 col-12 mb-1">
												<div className="btn-group btn-group-sm" role="group">
													<button className={"btn btn-sm btn-info "+exportBtnClass} onClick={handleExportCSV}>
														<span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
														<i className="bx bx-download d-block d-sm-none"></i>
													</button>
												</div>
											</div>
											<div className="col-md-4 col-sm-4 col-12 text-center mb-1">
												<DateRangePicker
													initialSettings={DTE.DatePickerPredefined(moment,start,end)}
													onCallback={setDatePicker}>
													<div className="input-group input-group-sm">
														<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
														<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
															<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
														</div>
													</div>
												</DateRangePicker>
												<small>Date range based on Transaction Date</small>
											</div>
											<div className="col-md-4 col-sm-4 col-12 text-center mb-1">
												<div className="btn-group btn-group-sm" role="group">
													<a href="/add-bank-transfer" className="btn btn-sm btn-primary">
														<span className="d-none d-sm-block"><i className='bx bxs-bank'></i>Transfer to Bank Account</span>
														<i className="bx bx-plus d-block d-sm-none"></i>
													</a>
												</div>
											</div>
										</div>
										<DTE.DataTable className="rdt_AdjDrp" columns={bankTransferColumns} data={bank_accounts} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}  persistTableHead/>
									</>)}
								</div>								
							</div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal show={bankPassbookModel} size='sm' onHide={()=>setBankPassbookModel(false)} aria-labelledby="Bank-Passbook-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Bank Passbook</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="mb-3 col-md-12">
						<label htmlFor="frmOpeningBalance" className="form-label">Opening Balance<span className="text-danger">*</span></label>
						<input name="opening_balance" type="number" className="form-control" id="frmOpeningBalance" placeholder="Enter Opening Balance" value={opening_balance} onChange={(e) => setOpeningBalance((e.target.value))} required/>
					</div>
					<div className="mb-3 col-md-12">
						<label htmlFor="frmOpeningBalanceDate" className="form-label">Opening Balance Date<span className="text-danger">*</span></label>
						<DatePicker name="opening_balance_date" calendarIcon={null} clearIcon={null} disableCalendar={true} data-testid="frmOpeningBalanceDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningBalanceDate} value={opening_balance_date} disabled />
					</div>
					<div className="mb-3 col-md-12">
						<small>Note: All collections & payments done before entering the opening balance date will not be considered in the passbook</small>
					</div>
					<div className="text-center mt-3 col-md-12">
						<Button variant="primary" size="sm" className="ms-1" onClick={addOpeningBalance}><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
		<Modal show={openingBalanceModel} size='md' onHide={()=>setOpeningBalanceModel(false)} aria-labelledby="Opening-Balance-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Edit Opening Balance - {modalNickName}</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{height: '70vh',overflowY: 'auto',overflowX: 'scroll'}}>
				<div className="row mb-2">
					<div className="mb-3 col-md-12">
						<label htmlFor="frmOpeningBalance" className="form-label">Opening Balance</label>
						<input name="modal_opening_balance" type="number" className="form-control" id="frmOpeningBalance" placeholder="Enter Opening Balance" value={modalOpeningBalance} readOnly/>
					</div>
					<div className="mb-3 col-md-12">
						<label htmlFor="frmOpeningBalance" className="form-label">Remarks</label>
						<input name="remarks" type="text" className="form-control" id="frmRemarks" placeholder="Remarks" value={modalOpeningBalanceRemarks} onChange={(e) => setModalOpeningBalanceRemarks(e.target.value)}/>
					</div>
					<div className="mb-3 col-md-12">
						<label htmlFor="frmOpeningBalance" className="form-label">New Opening Balance</label>
						<input name="modal_opening_balance_new" type="number" className="form-control" id="frmOpeningBalance" placeholder="Enter Opening Balance" value={modalNewOpeningBalance} onChange={(e) => setModalNewOpeningBalance(e.target.value)}/>
					</div>
					<div className="mb-3 col-md-12">
						<label htmlFor="frmOpeningBalanceDate" className="form-label">Opening Balance Date</label>
						<DatePicker name="modal_opening_balance_date" calendarIcon={null} clearIcon={null} disableCalendar={true} data-testid="frmOpeningBalanceDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" value={modalOpeningBalanceDate} disabled />
					</div>
					{/* <div className="mb-3 col-md-12">
						<small>Note: All collections & payments done before entering the opening balance date will not be considered in the passbook</small>
					</div> */}
					<div className="text-center mt-3 col-md-12">
						<Button variant="primary" size="sm" className="ms-1" onClick={updateOpeningBalance}><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="table-responsive mb-3  text-nowrap">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th>#</th>
										<th>Old Opening Balance(₹)</th>
										<th>New Opening Balance(₹)</th>
										<th>Remarks</th>
										<th>Created By</th>
										<th>Created On</th>
									</tr>
								</thead>
								<tbody>
									{trials.length > 0 ? <>
										{trials.map((data, index) => {
											return (
												<tr key={'key-'+index}>
													<td>{data.id}</td>
													<td>{Number(data.old_opening_balance).toFixed(2)}</td>
													<td>{Number(data.new_opening_balance).toFixed(2)}</td>
													<td>{data.remarks}</td>
													<td>{data.name}</td>
													<td>{data.created_on}</td>
												</tr>
											)
										})}
									
									</> : <>
										<tr><td colSpan={6} style={{'textAlign': 'center'}}>No Records</td></tr>
									</>}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
		</>
    );
}