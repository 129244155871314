import React,{useState,useEffect} from 'react';
import { Link,useNavigate,useParams  } from 'react-router-dom';
import Layout from './Layout';
import NavBar from './NavBar';
import axios from "axios";
import ChildInput from './ChildInput';

export default function EditSale(props){
    
  const [inputList, setInputList]    = useState([]);
  const [options, setOptions]        = useState([]);
  const [gstin, setGstin]            = useState('');
	const [party_name, setPartyName]   = useState('');
	const [pincode, setPinCode]        = useState('');
	const [state,setState]             = useState('');
  const [district,setDistrict]       = useState('');
  const [addr,setAddr]               = useState('');
  const [phoneNumber,setPhoneNumber] = useState('');
  const [showDiv,setShowDiv]         = useState(false);
  const [units,setUnits]             = useState([]);
  const [itemName,setItemName]       = useState('');
  const [qty,setQty]                 = useState('');
  const [unit,setUnit]               = useState('');
  const [baseprice,setBasePrice]     = useState('');
  const [tax,setTax]                 = useState('');
  const [discount,setDiscount]       = useState('');
  const [discount_flat,setFlatDiscount]= useState('');
  const [totalval,setTotalVal]       = useState('');
  const [taxes,setTaxes]             = useState([]);
  const [received,setReceived]       = useState();
  const [rate,setRate]               = useState('');
  const [readOnly,setReadOnly]       = useState(false);
  const [due,setDue]                 = useState('');

  const navigate = useNavigate();
  const params = useParams(); 

  useEffect(() => {
    document.title = 'Edit Sale | '+props.site_name;
    //get data by id

    axios({
        method: 'get',
        url:props.url+'sales/getDataById/'+params.id,
    }).then(function (response) {
        if(response.data.success){
            setPartyName(response.data.data[0].cust_name);
         // setUnits(response.data.data);
        }
    }).catch(error => console.log(error));

//get unit
let url = props.url+'sales/getUnits';
axios({
    method: 'get',
    url:url,
}).then(function (response) {
    if(response.data.success){
      setUnits(response.data.data);
    }
}).catch(error => console.log(error));

//get tax
axios({
    method: 'get',
    url:props.url+'ajax/getTaxes',
}).then(function (response) {
    if(response.data.success){
      setTaxes(response.data.data);
    }
}).catch(error => console.log(error));

}, []);

const onAddBtnClick = (event) => {
  if(itemName===""){
    alert("Please specify Item Name");
    return;
  }

  if(qty===""){
    alert("Please specify Item Qty");
    return;
  }

  if( localStorage.getItem('fo_totalval') ){
    let total =  parseFloat(localStorage.getItem('fo_totalval')) + parseFloat(totalval);
    localStorage.setItem('fo_totalval', total);
  }else{
    localStorage.setItem('fo_totalval', totalval);
  }
setInputList(inputList.concat(<ChildInput key={inputList.length} len={inputList.length} itemName={itemName} qty={qty} rate={rate} unit={unit} url={props.url} tax={tax} discount={discount} totalval={totalval} discount_flat={discount_flat}/>));
setItemName(''); setQty(''); setUnit('');  setRate('');  setTax(''); setDiscount('');setFlatDiscount('');
setBasePrice(localStorage.getItem('fo_totalval'));
setShowDiv(true);
}

  const handleCustomer=(e)=>{
    e.preventDefault();
    let searchText = e.target.value;
    let url = props.url+'customer/search';
    axios({
        method: 'post',
        url:url,
        data: {
          searchText:searchText
        },
    }).then(function (response) {
        if(response.data.success){
          setOptions(response.data.data);
        }
      }).catch(error => console.log(error));
  }

  const onItemSelect =(e)=>{
    if (e.key === 'Enter') {
      e.preventDefault();
      let customer = e.target.value;
      let url = props.url+'customer/customerData';
      axios({
          method: 'post',
          url:url,
          data: {
            customer:customer
          },
      }).then(function (response) {
          if(response.data.success){
            setPartyName(response.data.data.party_name); setPinCode(response.data.data.pincode);
            setState(response.data.data.state);  setDistrict(response.data.data.district);
            setAddr(response.data.data.addr);  setPhoneNumber(response.data.data.mobile);
            setGstin(response.data.data.gstin);
          }
        }).catch(error => console.log(error));
    }
  }
  const handleAddiFields=(val)=>{
    console.log(val);
    setBasePrice(val);
    setShowDiv(true);
    setDue(val);
  }
  const handleSubmit =(e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    let pid = localStorage.getItem('fo_loggedin');
    formData.append('pid', pid);
    let url = props.url+'sales/add';
    let data = {};
   
    // convert the key/value pairs
      for (var [key, value] of formData.entries()) { 
       data[key] = value;
      }
      
    axios({
        method: 'post',
        url:url,
        data: {
          formData:data
        },
    }).then(function (response) {
      localStorage.removeItem('fo_totalval');
        if(response.data.success){
          navigate("/sales-listing");
        }
      }).catch(error => console.log(error));
   
  }

  const setRateCalculation=(val)=>{
    setRate(val);
    setTotalVal(parseFloat(val*qty).toFixed(2));
    setReadOnly(true);
  }
  const setTaxCalculation=(val,text)=>{
    setTax(val);
   let str =  text.substring(text.indexOf("@")+1);
  let strtext =  str.substring(0, str.indexOf('%'));
    let taxval = (parseFloat(rate*qty).toFixed(2)*strtext)/100;
    let totaval = parseInt (parseFloat(rate*qty).toFixed(2)) + parseInt(taxval);
    console.log(totaval);
    setTotalVal(parseFloat(totaval).toFixed(2));
    setReadOnly(true);
  }
  const setDiscountCalculation=(val)=>{
    let subtotal = rate*qty;
    let discount = (subtotal * val)/100;
    setDiscount(val);
    setTotalVal(parseFloat(subtotal-discount).toFixed(2));
    setReadOnly(true);
  }
  const setFlatDiscountCalculation=(val)=>{
    let subtotal = rate*qty;
    setFlatDiscount(val);
    setTotalVal(parseFloat(subtotal-val).toFixed(2));
    setReadOnly(true);
  }
  const setReceivedCalc =(val)=>{
      setReceived(val);
      let dueCalc = baseprice- val;
    setDue(dueCalc);
  }

      return(
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="add-sale" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Sales Management /</span> Edit Sale</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                          
                                          <form id="formAccountSettings" onSubmit={handleSubmit}>
                                            <div className="row">
                                            <div className="mb-3 col-md-6">
                                              <label htmlFor="gstin" className="form-label">Customer <span className="text-danger">*</span></label>
                                                <input className="form-control" list="datalistOptions" id="exampleDataList"
                                                  placeholder="Type to search..."  onChange={handleCustomer} onKeyDown={onItemSelect} required
                                                  />
                                                <datalist id="datalistOptions">
                                                  {options.map((val,key) => (
                                                    <option key={val.id} >{val.party_name}</option>
                                                  ))}
                                                  
                                                </datalist>
                                                <div className="form-text">Press Enter To Auto fetch Customer Data</div>
                                              </div>
                                              <div className="mb-3 col-md-6">
                                                <Link to="/new-customer">Add New Customer</Link>
                                              </div>

                                              <div className="mb-3 col-md-6">
                                                <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingThree">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse"
                                                      data-bs-target="#accordionThree" aria-expanded="false" aria-controls="accordionThree">Gstin And Address
                                                    </button>
                                                  </h2>
                                                  <div id="accordionThree" className="accordion-collapse collapse" aria-labelledby="headingThree" >
                                                      <div className="accordion-body">
                                                          <label htmlFor="gstin" className="form-label">Gstin</label>
                                                          <input type="text" className="form-control" id="gstin" name="gstin"
                                                          placeholder=""  maxLength="15" value={gstin} onChange={(e) => setGstin(e.target.value)}
                                                          />
                                                          <label htmlFor="name" className="form-label">Name</label>
                                                          <input type="text" className="form-control" id="name" name="name" placeholder=""
                                                          value={party_name} onChange={(e) => setPartyName(e.target.value)}
                                                          />
                                                          <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                                          <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" placeholder="" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                                                          />
                                                          <label htmlFor="pinCode" className="form-label">Pin Code</label>
                                                          <input type="text" className="form-control" id="pinCode" name="pinCode" placeholder="" value={pincode} onChange={(e) => setPinCode(e.target.value)}
                                                          maxLength="6"
                                                          />
                                                          <label htmlFor="state" className="form-label">State</label>
                                                          <input type="text" className="form-control" id="state" name="state"
                                                          placeholder="" value={state} onChange={(e) => setState(e.target.value)}
                                                          />
                                                          <label htmlFor="district" className="form-label">District</label>
                                                          <input type="text" className="form-control" id="district" name="district"
                                                          placeholder="" value={district} onChange={(e) => setDistrict(e.target.value)}
                                                          />
                                                          <label htmlFor="address" className="form-label">Address</label>
                                                          <textarea id="basic-icon-default-message" name="address" className="form-control"
                                                          placeholder="" aria-label="Abc 123123" aria-describedby="basic-icon-default-message2" value={addr} onChange={(e) => setAddr(e.target.value)} ></textarea>
                                                      </div>
                                                    </div>
                                                </div>
                                              </div>


                                              <div className="mb-3 col-md-6"> 
                                              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#itemModalCenter">Add Item (Optional)</button>
                                              </div>
                                              <div className="row justify-content-end align_button">
                                                <div className="col-sm-10">
                                               
                                                <div className="modal fade" id="itemModalCenter" tabIndex="-1" aria-hidden="true">
                                                  <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                      <div className="modal-header">
                                                        <h5 className="modal-title" id="modalCenterTitle">Add Items To Sale</h5>
                                                        <button
                                                          type="button"
                                                          className="btn-close"
                                                          data-bs-dismiss="modal"
                                                          aria-label="Close"
                                                        ></button>
                                                      </div>
                                                      <div className="modal-body">
                                                       <div className="row g-2">
                                                          <div className="col mb-0">
                                                            <label htmlFor="emailWithTitle" className="form-label">Item Name</label>
                                                            
                                                              <input type="text" className="form-control" id="pitemName" name="itemName" placeholder="cake" value={itemName} onChange={(e) => setItemName(e.target.value)}/>
                                                          </div>
                                                          <div className="col mb-0">
                                                            <label htmlFor="emailWithTitle" className="form-label">Quantity</label>
                                                              <input type="text" className="form-control" id="pqty" name="qty" placeholder="1" value={qty} onChange={(e) => setQty(e.target.value)} />
                                                          </div>
                                                          <div className="col mb-0">
                                                            <label htmlFor="dobWithTitle" className="form-label">Unit</label>
                                                            <select id="defaultSelect" className="form-select" name='unit' value={unit} onChange={(e) => setUnit(e.target.value)}>
                                                            {units.map((val,key) => (
                                                             <option key={val.id} value={val.id} >{val.description} ( {val.code} )</option>
                                                            ))}
                                                            </select>
                                                          </div>
                                                          <div className="col mb-0">
                                                            <label htmlFor="emailWithTitle" className="form-label">Price / Unit</label>
                                                              <input type="text" className="form-control" id="rate" name="rate" placeholder="1" value={rate} onChange={(e) => setRateCalculation(e.target.value)} />
                                                          </div>
                                                        </div>
                                                        <div className="row g-2">
                                                        <div className="col mb-0">
                                                            <label htmlFor="emailWithTitle" className="form-label">Discount %</label>
                                                              <input type="text" className="form-control" id="discount" name="discount" placeholder="" value={discount} onChange={(e) => setDiscountCalculation(e.target.value)} />
                                                          </div>
                                                          <div className="col mb-0">
                                                            <label htmlFor="emailWithTitle" className="form-label">Discount (Flat)</label>
                                                              <input type="text" className="form-control" id="discount_flat" name="discount_flat" placeholder="" value={discount_flat} onChange={(e) => setFlatDiscountCalculation(e.target.value)} />
                                                          </div>
                                                          <div className="col mb-0">
                                                            <label htmlFor="emailWithTitle" className="form-label">Tax %</label>
                                                            <select id="defaultSelect" className="form-select" name='tax' value={tax} onChange={(e) => setTaxCalculation(e.target.value,e.target[e.target.value-1].text)}>
                                                                {taxes.map((val,key) => (
                                                                <option key={val.id} value={val.id} >{val.rate}</option>
                                                                ))}
                                                            </select>
                                                          </div>
                                                         
                                                          <div className="col mb-0">
                                                            <label htmlFor="emailWithTitle" className="form-label">Total Amount</label>
                                                            <input type="text" className="form-control" id="totalval" name="totalval" placeholder="" value={totalval}  onChange={(e) => setTotalVal(e.target.value)} readOnly/>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="modal-footer">
                                                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                                                          Close
                                                        </button>
                                                        <button type="button" className="btn btn-primary" onClick={onAddBtnClick}><span className="hidden-phone">Save</span></button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                             </div>

                                              {inputList.length > 0 && (<div className="mb-3 col-md-12">
                                              <h2 className="accordion-header" id="headingTwo">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionTwo"
                                              aria-expanded="false" aria-controls="accordionTwo">Billed Items</button>
                                              </h2>
                                              <div id="accordionTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" >
                                                <div className="accordion-body" id="accordion-body">
                                                  <ul className="p-0 m-0">{inputList}</ul>
                                                </div>
                                              </div>
                                              </div>)}
                                              <div className="row">
                                              <div className="mb-3 col-md-6">
                                                <div className="row g-2">
                                                    <div className="col mb-0">
                                                    </div>
                                                    <div className="col mb-0">
                                                    </div>
                                                    <div className="row mb-3">
                                                      <label className="col-sm-4 col-form-label" htmlFor="basic-default-name">Total<span className="text-danger">*</span></label>
                                                      <div className="col-sm-8">
                                                        <input type="text" className="form-control"  id="baseprice" name="baseprice" placeholder="" value={baseprice} onChange={(e) => handleAddiFields(e.target.value)} readOnly={readOnly} />
                                                      </div>
                                                  </div>
                                                  
                          
                                                </div>
                                              </div>  
                                              </div>
                                              {showDiv && (<>
                                              <div className="row">
                                                <div className="mb-3 col-md-6">
                                                  <div className="row g-2">
                                                    <div className="col mb-0">
                                                    </div>
                                                    <div className="col mb-0">
                                                    </div>
                                                    <div className="row mb-3">
                                                      <label className="col-sm-4 col-form-label" htmlFor="basic-default-name">Received</label>
                                                      <div className="col-sm-8">
                                                        <input type="text" className="form-control"  id="received" name="received" placeholder="" value={received} onChange={(e) => setReceivedCalc(e.target.value)}  />
                                                      </div>
                                                  </div>
                                                  </div>
                                                  <div className="row g-2">
                                                    <div className="col mb-0">
                                                    </div>
                                                    <div className="col mb-0">
                                                    </div>
                                                    <div className="row mb-3">
                                                      <label className="col-sm-4 col-form-label" htmlFor="basic-default-name">Due</label>
                                                      <div className="col-sm-8">
                                                        <input type="text" className="form-control-plaintext"  id="due" name="due" placeholder="" value={due} onChange={(e) => setDue(e.target.value)}  />
                                                      </div>
                                                  </div>
                                                  </div>
                                                </div></div>
                                              </>)}
                                            </div>
                                              <div className="row justify-content-end align_button">
                                                <div className="mt-3">
                                                  <button type="submit" className="btn btn-primary me-2"><span className="hidden-phone">Save</span></button>
                                                </div>
                                              </div>
                                          </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div> 
        </>   
      );  
}