import React,{useState} from 'react';
import '../styles/fyn-ticket.css';
export default function PartnerNeedHelp(props){
	

    return(
		<>
		<div className="buy-now">
			<a data-bs-toggle="offcanvas" href="#fyn-ticket-div" role="button" className="btn btn-sm btn-danger btn-fyn-ticket">Need Help ?</a>
		</div>
		<div className="offcanvas offcanvas-end" tabIndex="-1" id="fyn-ticket-div" aria-labelledby="fyn-ticket-divLabel" style={{"visibility": "hidden"}} aria-hidden="true">
		<div id="slide-div-down">
			<div className="offcanvas-header">
				<h3 id="fyn-ticket-divLabel" className="offcanvas-title margin-l text-center" style={{marginLeft: '110px'}}>For Support</h3>
				<button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div className="offcanvas-body flex-grow-0" style={{marginTop: '30px'}}>
				<p className="text-center">
                    Contact Name : Shashidhar B R <br></br>
                    Constant Mobile : 9663355662 <br></br>
					Constant Email : shashidhar.br@fynone.com
				</p>
			</div>
		</div>
		</div>
		</>
    );
}