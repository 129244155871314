import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import "../styles/calculator.css";
import * as math from 'mathjs';


export default function Calculator() {
    
	const [modalDisplay, setModalDisplay] = useState(false)
	const [result, setResult] = useState('');
	const [selectedOperator, setSelectedOperator] = useState('');

	const handleClick = (e) => {
    	const buttonName = e.target.name;
  
    	if (isNaN(buttonName)) {
      		// If an operator button is clicked
      		if (selectedOperator && selectedOperator !== buttonName) {
        		// If a different operator is already selected, update the selected operator
        		setSelectedOperator(buttonName);
        		setResult(result.slice(0, -1).concat(buttonName));
      		} else if (!selectedOperator) {
        		// Otherwise, select the operator and append it to the result
        		setSelectedOperator(buttonName);
        		setResult(result.concat(buttonName));
      		}
    	} else {
      		// If a number button is clicked
      		if (selectedOperator) {
        		// If an operator is already selected, append the number to the result after the operator
        		if (result.slice(-1) === selectedOperator) {
						setResult(result.concat(buttonName));
					} else {
						setResult(result + selectedOperator + buttonName);
					}	
				setSelectedOperator('');
			} else {
				// Otherwise, simply append the number to the result
				setResult(result.concat(buttonName));
			}
		}
	};

  	const clear = () => {
		setResult('');
		setSelectedOperator('');
  	};

  	const backspace = () => {
		setResult(result.slice(0, -1));
		setSelectedOperator('');
  	};

	const calculate = () => {
    	try {
      		setResult(math.evaluate(result).toString());
      		setSelectedOperator('');
    	} catch (err) {
      		setResult('Error');
    	}
  	};
    
    const displayModal = (param) => {
        if (param === 'hide') {
            setModalDisplay(false);
        } else {
            setModalDisplay(true);
        }
    }

	return (
		<>
			<div>
				<span><i className='bx bxs-calculator' onClick={() => displayModal('show')}></i></span>
			</div>
		<Modal size='sm' show={modalDisplay} onHide={() => displayModal('hide')} aria-labelledby="advance-modal" backdrop="static" keyboard={false} centered>
				<Modal.Header closeButton>
					<div className="col-4 modal-mobile-view">
						<Modal.Title id="calculator-modal">Calculator</Modal.Title>
					</div>
				</Modal.Header>
				<Modal.Body>
					<form >
						<input type="text" className="form-control" id="inputid" value={result} readOnly />
					</form>
					<div className="keypad">
						<button className="highlight cal-btn" onClick={clear} id="clear" >Clear</button>
						<button className="highlight cal-btn" onClick={backspace} id="backspace" >C</button>
						<button className="highlight cal-btn" name="/" onClick={handleClick} style={{'color': 'black'}} >&divide;</button>
						<button className="cal-btn" name="7" onClick={handleClick} >7</button>
						<button className="cal-btn" name="8" onClick={handleClick} >8</button>
						<button className="cal-btn" name="9" onClick={handleClick} >9</button>
						<button className="highlight cal-btn" name="*" onClick={handleClick} style={{'color': 'black'}}>&times;</button>
						<button className="cal-btn" name="4" onClick={handleClick} >4</button>
						<button className="cal-btn" name="5" onClick={handleClick} >5</button>
						<button className="cal-btn" name="6" onClick={handleClick} >6</button>
						<button className="highlight cal-btn" name="-" onClick={handleClick} style={{'color': 'black'}}>&ndash;</button>
						<button className="cal-btn" name="1" onClick={handleClick} >1</button>
						<button className="cal-btn" name="2" onClick={handleClick} >2</button>
						<button className="cal-btn" name="3" onClick={handleClick} >3</button>
						<button className="highlight cal-btn" name="+" onClick={handleClick} style={{'color': 'black'}}>+</button>
						<button className="cal-btn" name="0" onClick={handleClick} >0</button>
						<button className="highlight cal-btn" name="." onClick={handleClick} style={{'color': 'black'}}>.</button>
						<button className="highlight cal-btn" name="=" onClick={calculate} id="result" >=</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}