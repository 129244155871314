import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import MartLayout from './MartLayout';
import NavBar from './NavBar';
import MartNavBar from './MartNavBar';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import ItemCustomFields from './ItemCustomFields';
import {Html5Qrcode} from "html5-qrcode";
import beepSound from '../sounds/scanner-beep.mp3';
export default function AddItem(props){
	// User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	const [dupTimeout, setDupTimeout] = useState(null);
	const [itemAdditional,setItemAdditional] = useState(true);
	const [item_name, setItemName]         = useState('');
    const [item_unit, setItemUnit]         = useState('28');
    const [item_price, setItemPrice]       = useState('');
    const [gross_amt, setGrossAmt]       = useState('');
    const [item_total, setItemTotal]       = useState('');
    const [item_hsn, setItemHSN]       = useState('');
    const [gst_rate, setGstRate]       = useState('');
    const [gst_rate_id, setGstRateId]       = useState('');
    const [gst_amt, setGstAmt]       = useState('');
    const [opening_stock, setOpeningStock] = useState('0');
    const [min_qty, setMinQty]         = useState('1');
	const [opening_stock_date, setOpeningStockDt] = useState(new Date());
    const [units,setUnits]                 = useState([]);
    const [taxes,setTaxes]                 = useState([]);
	const [discount_amt,setDiscountAmt]  = useState('');
	const [discount_per,setDiscountPer]  = useState('');
	const [disPerDisabled,setDisPerDisabled]  = useState(true);
	const [disAmtDisabled,setDisAmtDisabled]  = useState(true);
	const [inventoryMgmt_w,setinventoryMgmt_w] = React.useState(true);
	const [serviceType, setServiceType] = useState('');
	
    const [purchase_item_price, setPurchaseItemPrice] = useState('');
    const [purchase_gross_amt, setPurchaseGrossAmt] = useState('');
    const [purchase_item_total, setPurchaseItemTotal] = useState('');
	const [purchase_discount_amt,setPurchaseDiscountAmt]  = useState('');
	const [purchase_discount_per,setPurchaseDiscountPer]  = useState('');
	const [disPurchasePerDisabled,setDisPurchasePerDisabled]  = useState(true);
	const [disPurchaseAmtDisabled,setDisPurchaseAmtDisabled]  = useState(true);
    const [purchase_gst_amt, setPurchaseGstAmt]       = useState('');
    const [itemStockMovement, setItemStockMovement]       = useState('0');
	const [itemStockChecked,setItemStockChecked]  = useState(false);
	const [dividerDisplay, setDividerDisplay] = useState(true);
	const [additionalItemDiv, setAdditionalItemDiv] = useState(true);
	const [salePurchasedividerDisplay, setSalePurchaseDividerDisplay] = useState(true);
	const [salePurchaseAdditionalItemDiv, setSalePurchaseAdditionalItemDiv] = useState(true);
	const [skuCode, setSkuCode] = useState('');
	const [barcode, setBarcode] = useState('');
	const [safetyStock, setSafetyStock] = useState('');
	const [reorderPoint, setReorderPoint] = useState('');
	const [reorderQty, setReorderQty] = useState('');
	const [maximumStockLevel, setMaximumStockLevel] = useState('');
	const [customInvFields,setCustomInvFields]	  = useState(false);
	const [customInputList, setCustomInputList]	  = useState([]);
	const [isNegativeBal, setIsNegativeBal] = useState(false);
	const [allowNegStock,setAllowNegStock] = useState(0);
	const [barcodeScanModal,setBarcodeScanModal] = React.useState(false);

	const navigate = useNavigate();
	useEffect(() => {
		if( props.referrer === "fynone" ){
			document.title = 'Add Item | '+props.site_name;
		}
		if( props.referrer === "fynmart" ){
			document.title = 'Add Inventory | '+props.site_name;
		}
		if( Object.keys(userPerm).length > 0 ){
			// Write permission
			setinventoryMgmt_w((userPerm[4]['write'] !== undefined && userPerm[4]['write'] === '0') ? false : true);
		}
				
        //get unit
        let url = props.url+'ajax/getUnits';
        Common.axios({
            method: 'get',
            url:url,
        }).then(function (response) {
            if(response.data.success){
				setUnits(response.data.data);
            }
        }).catch(error => console.log(error));

		// get tax rates
		Common.axios({
			method: 'get',
			url:props.url+'ajax/getTaxes',
		}).then(function (response) {
			if(response.data.success){
				setTaxes(response.data.data);
			}
		}).catch(error => console.log(error));

		// // Calculate gross amout
		// let grsamt = Number(item_price) - Number(discount_amt);
		// setGrossAmt(grsamt.toFixed(2));
		
		// // Calculate gst amount
		// setGstAmt(((grsamt * gst_rate)/100).toFixed(2));

		// // Calculate item total
		// setItemTotal(((Number(item_price) - Number(discount_amt)) + Number(gst_amt)).toFixed(2));
		// if( Number(item_price) > 0 ){
		// 	setDisPerDisabled(false);
		// 	setDisAmtDisabled(false);
		// }
		// if( Number(item_price) === 0 ){
		// 	setDisPerDisabled(true);
		// 	setDisAmtDisabled(true);
		// }
	}, []);
	// }, [item_price,discount_amt,gst_rate,gst_amt]);
	//Barcode scanner
	let audio = new Audio(beepSound);
	const playBeep = () => {
		if(audio.paused === true){
		  audio.play();
		}else{
		  audio.pause();
		  audio.currentTime = 0;
		  audio.play();
		}
	};
	const getBarCode = () => {
		setBarcodeScanModal(true);
		setTimeout(() => { 
			const html5QrCode = new Html5Qrcode("barReader");
			const startScanner = async () => {
				await html5QrCode.start(
					{ facingMode: 'environment' },
					{
						fps: 10,
						qrbox: { width: 300, height: 200 }
						//qrbox: qrboxFunction
					},
					(decodedText, decodedResult) => {
						if(decodedText !== ''){
							playBeep();
							stopScanner();
							setBarcode(decodedText);
							setBarcodeScanModal(false);
							// stopScanner();
						}
					},
					(errorMessage) => {
						// parse error, ignore it.
					}
				).catch((err) => {
					Common.toast.error(err);
				});
			};
			const stopScanner = async () => {
				if (html5QrCode.isScanning) {
					await html5QrCode.stop();
					html5QrCode.clear();
				}
			};
			startScanner();
			return () => {
				stopScanner();
			};
		});
	}
	const saleItemCalculation = (param,val) => {
		if( param === 'item_price' ){
			setItemPrice(val);
			setGrossAmt((Number(val) - Number(discount_amt)).toFixed(2));
			let gstAmt = (((Number(val) - Number(discount_amt)) * gst_rate)/100).toFixed(2);
			setGstAmt(gstAmt);
			setItemTotal(((Number(val) - Number(discount_amt)) + Number(gstAmt)).toFixed(2));
			if( Number(val) > 0 ){
				setDisPerDisabled(false);
				setDisAmtDisabled(false);
			}
			if( Number(val) === 0 ){
				setDisPerDisabled(true);
				setDisAmtDisabled(true);
			}
			// || Number(purchase_item_price) === 0
			if( Number(val) === 0){
				setGrossAmt('');
				setItemTotal('');
				setGstAmt('');
			}
		}
		if( param === 'discount_amt' ){
			setGrossAmt((Number(item_price) - Number(val)).toFixed(2));			
			let gstAmt = (((Number(item_price) - Number(val)) * gst_rate)/100).toFixed(2);
			setGstAmt(gstAmt);
			setItemTotal(((Number(item_price) - Number(val)) + Number(gstAmt)).toFixed(2));
		}
		if( param === 'gst_rate' ){
			setGrossAmt((Number(item_price) - Number(discount_amt)).toFixed(2));
			let gstAmt = ((gross_amt * val)/100).toFixed(2);
			setGstAmt(gstAmt);
			setItemTotal(((Number(item_price) - Number(discount_amt)) + Number(gstAmt)).toFixed(2));
		}
		if( param === 'item_total' ){
			let final_amount = Number(discount_amt) + (Number(val) / ((Number(gst_rate)/100)+1));
			let gross_amount = (Number(final_amount) - Number(discount_amt)).toFixed(2);
			setGrossAmt(gross_amount);
			let gstAmt = (Number(gross_amount * gst_rate)/100).toFixed(2);
			setGstAmt(gstAmt);
			setItemPrice(final_amount.toFixed(2));
			setItemTotal(val);
			setDiscountPer(Number((discount_amt/final_amount) * 100).toFixed(2));
			if( Number(final_amount) > 0 ){
				setDisPerDisabled(false);
				setDisAmtDisabled(false);
			}
			if( Number(final_amount) === 0 ){
				setDisPerDisabled(true);
				setDisAmtDisabled(true);
			}
		}
	}
    const handleItem = (e)=>{
        e.preventDefault();
		Common.showLoader(true);
        let formData = new FormData(e.target);
        if(item_name===""){
			Common.toast.dismiss();
            Common.toast.error("Item Name is Required.");
            return false;
        }
		if(item_name.length < 3){
			Common.toast.dismiss();
            Common.toast.error("Item Name should have minimum 3 characters.");
            return false;
        }
		if (serviceType !== '1') {
			if (isNegativeBal === false && opening_stock < 0) {
				Common.toast.dismiss();
				Common.toast.error("Opening Stock cannot be Negative.");
				return false;
			}
		}
        
		let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
		data['item_unit'] = (serviceType === '1')?29:item_unit;
		data['discount'] = discount_amt;
		data['allow_neg_stock'] = allowNegStock;
		data['purchase_discount'] = purchase_discount_amt;
		data['gst_rt'] = gst_rate;
		data['gst_rate_id'] = gst_rate_id;
		data['item_service'] = serviceType;
		data['opening_stock'] = (serviceType === '1')?0:opening_stock;
		data['min_qty'] = (serviceType === '1')?0:min_qty;
		if (serviceType === '1') {
			if (item_hsn !== '') {
				data['item_hsn'] = '99'+item_hsn;
			} else {
				data['item_hsn'] = '';
			}
		} else {
			data['item_hsn'] = item_hsn;
		}
		data['pid'] = localStorage.getItem('fo_profileid');

		// additional details
		data['sku_code'] = skuCode;
		data['barcode'] = barcode;
		data['safety_stock'] = safetyStock;
		data['reorder_point'] = reorderPoint;
		data['reorder_qty'] = reorderQty;
		data['max_stock_level'] = maximumStockLevel;
		if( props.referrer === "fynmart" ){
			data['submit_from'] = 'mart';
		}

		Common.axios({
            method: 'post',
            url:props.url+'items/addRecord',
            data: data,
        }).then(function (response) {
			Common.toast.dismiss();
			Common.showLoader(false);
			if(response.data.success){
				Common.toast.success(response.data.message);
				if( props.referrer === "fynmart" ){
					navigate("/mart-inventory");
				}else{
					navigate("/item-listing");
				}
				return false;
				// setItemName(''); setItemUnit('28'); setItemPrice('');
			}else{
				Common.toast.error(response.data.message);
			}
		});
	};
    const handleAddiFields=(val)=>{
		// if (val === '') {
		// 	setDividerDisplay(true)
		// 	setSalePurchaseDividerDisplay(true)
		// }
		setItemName(val);
		if( dupTimeout ){
			clearTimeout(dupTimeout);
			setDupTimeout(null);
		}
		if( val !== '' && val.length >= 3 ){			
			setItemAdditional(true);
			setDupTimeout(setTimeout(() => {
				Common.axios({
					method: 'post',
					url:props.url+'items/duplicate',
					data: {'name':val,'pid':localStorage.getItem('fo_profileid')}
				}).then(function (response) {
					if(!response.data.success){
						resetForm();
						Common.toast.dismiss();
						Common.toast.error(response.data.message);
					}
				}).catch(error => console.log(error));
			}, 2000));
			return false;
		}
		setItemAdditional(true);
		setServiceType('');
		return false;
    }
	const resetForm = () => {
		handleAddiFields('');
		setItemUnit('28');
		setItemPrice('');
		setOpeningStock('');
		setMinQty('1');
		setOpeningStockDt(new Date());
		
		setItemAdditional(true);
		// setItemService('');
		setItemHSN('');
		setPurchaseItemPrice('');
		setPurchaseGrossAmt('');
		setPurchaseItemTotal('');
		setPurchaseDiscountAmt('');
		setPurchaseDiscountPer('');
		setDisPurchasePerDisabled(true);
		setDisPurchaseAmtDisabled(true);
		setPurchaseGstAmt('');
		setItemStockMovement(0);
		setSkuCode('');
		setBarcode('');
		setReorderQty('');
		setReorderPoint('');
		setSafetyStock('');
		setMaximumStockLevel('');
		// setDividerDisplay(false);
		setAdditionalItemDiv(true);
		setSalePurchaseAdditionalItemDiv(true);
		// setSalePurchaseDividerDisplay(false);
	}
	const calcDiscount = (param,val) => {
		let percent = 0,amount = 0, price = item_price;
		if( param === 'per' ){
			percent = val;
			amount = (price * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/price * 100).toFixed(2);
		}
		setDiscountPer(percent);
		setDiscountAmt(amount);
		saleItemCalculation('discount_amt',amount);
	}
	const calcPurchaseDiscount = (param,val) => {
		let percent = 0,amount = 0, price = purchase_item_price;
		if( param === 'per' ){
			percent = val;
			amount = (price * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/price * 100).toFixed(2);
		}
		setPurchaseDiscountPer(percent);
		setPurchaseDiscountAmt(amount);
		purchaseItemCalculation('discount_amt',amount);
	}
	const purchaseItemCalculation = (param,val) => {
		if( param === 'item_price' ){
			setPurchaseItemPrice(val);
			setPurchaseGrossAmt((Number(val) - Number(purchase_discount_amt)).toFixed(2));
			let gstAmt = (((Number(val) - Number(purchase_discount_amt)) * gst_rate)/100).toFixed(2);
			setPurchaseGstAmt(gstAmt);
			setPurchaseItemTotal(((Number(val) - Number(purchase_discount_amt)) + Number(gstAmt)).toFixed(2));
			if( Number(val) > 0 ){
				setDisPurchasePerDisabled(false);
				setDisPurchaseAmtDisabled(false);
			}
			if( Number(val) === 0 ){
				setDisPurchasePerDisabled(true);
				setDisPurchaseAmtDisabled(true);
			}
			// || Number(purchase_item_price) === 0
			if( Number(val) === 0){
				setPurchaseGrossAmt('');
				setPurchaseItemTotal('');
				setPurchaseGstAmt('');
			}
		}
		if( param === 'discount_amt' ){
			setPurchaseGrossAmt((Number(purchase_item_price) - Number(val)).toFixed(2));			
			let gstAmt = (((Number(purchase_item_price) - Number(val)) * gst_rate)/100).toFixed(2);
			setPurchaseGstAmt(gstAmt);
			setPurchaseItemTotal(((Number(purchase_item_price) - Number(val)) + Number(gstAmt)).toFixed(2));
		}
		if( param === 'gst_rate' ){
			setPurchaseGrossAmt((Number(purchase_item_price) - Number(purchase_discount_amt)).toFixed(2));
			let gstAmt = ((purchase_gross_amt * val)/100).toFixed(2);
			setPurchaseGstAmt(gstAmt);
			setPurchaseItemTotal(((Number(purchase_item_price) - Number(purchase_discount_amt)) + Number(gstAmt)).toFixed(2));
		}
		if( param === 'item_total' ){
			let final_amount = Number(purchase_discount_amt) + (Number(val) / ((Number(gst_rate)/100)+1));
			let gross_amount = (Number(final_amount) - Number(purchase_discount_amt)).toFixed(2);
			setPurchaseGrossAmt(gross_amount);
			let gstAmt = (Number(gross_amount * gst_rate)/100).toFixed(2);
			setPurchaseGstAmt(gstAmt);
			setPurchaseItemPrice(final_amount.toFixed(2));
			setPurchaseItemTotal(val);
			setPurchaseDiscountPer(Number((purchase_discount_amt/final_amount) * 100).toFixed(2));
			if( Number(final_amount) > 0 ){
				setDisPurchasePerDisabled(false);
				setDisPurchaseAmtDisabled(false);
			}
			if( Number(final_amount) === 0 ){
				setDisPurchaseAmtDisabled(true);
				setDisPurchaseAmtDisabled(true);
			}
		}
	}
	const getItemStockMovement = () => {
		setItemStockMovement((itemStockMovement === '1') ? '0' : '1');
		setItemStockChecked((itemStockMovement === '1') ? false : true);
		if(itemStockMovement === '1'){
			setOpeningStock('');
		}
	}
	const getServiceType = (actype) => {
		if(item_name === ''){
			Common.toast.dismiss();
			Common.toast.error('Item Name is Required.');
			return false;
		}
		if(item_name.length < 3){
			Common.toast.dismiss();
            Common.toast.error("Item Name should have minimum 3 characters.");
            return false;
        }
		setServiceType(actype);
		setDividerDisplay(true);
		setSalePurchaseDividerDisplay(true);
	}
	const showAdditionalItemFields = () => {
		setAdditionalItemDiv( (additionalItemDiv) ? false : true );
	}
	const showSalePurchaseAdditionalItemFields = () => {
		setSalePurchaseAdditionalItemDiv( (salePurchaseAdditionalItemDiv) ? false : true );
	}
	const handleCustomInvFields=()=>{
		const allWithClass = Array.from(
			document.getElementsByClassName('custom_fields')
		);
		console.log(allWithClass.length);
		if(allWithClass.length < 20){
        	setCustomInputList(customInputList.concat(<ItemCustomFields key={customInputList.length} len={customInputList.length}/>));
		}
		else{
			Common.toast.error('Max Custom Fields Reached');
		}
    }
	const toggeCustomInvFields = () => {
		setCustomInvFields((customInvFields) ? false : true);
		if(!customInvFields){
			if(customInputList.length < 1){
				handleCustomInvFields();
			}
		}
		// else{
		// 	setCustomInputList([]);
		// }
	}
	const setItemQty = (val,cnt) => {
		let newArr = '';

		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;

		setSafetyStock(newArr);
	}
	const setReorderPointValue = (val,cnt) => {
		let newArr = '';
		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
		setReorderPoint(newArr);
	}
	const setReorderQtyValue = (val,cnt) => {
		let newArr = '';
		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
		setReorderQty(newArr);
	}
	const setMaxStockLevelValue = (val,cnt) => {
		let newArr = '';
		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
		setMaximumStockLevel(newArr);
	}
	if( !inventoryMgmt_w ){
		navigate("/not-authorized");
	}
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
				{props.referrer === 'fynone' ? 
                <Layout label="add-item" site_logo={props.site_logo} site={props.site_name}/>
				:
				<MartLayout label="mart-inventory-add" {...props}/>
				}
                <div className="layout-page">
					{props.referrer === 'fynone' ? 
                    <NavBar site={props.site_name} url={props.url}/>
					:
					<MartNavBar {...props}/>
					}
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Inventory {props.referrer==="fynone" ? 'Management':''} /</span> Add Item
							<span className="dropdown">
                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className='text-danger bx bxl-youtube bx-md'></i>
                                </button>
                                <div className="dropdown-menu" >
									<a href="https://youtu.be/o2zZagDTzIs" className="dropdown-item" target="_blank" rel="noreferrer"><span>Add inventory</span></a>
                                </div>
                            </span>
						</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
											<form id="addItmFrm" onSubmit={handleItem} autoComplete="off">
												<div className="row">
													<div className="col-sm-6">
														<div className="row">
															<div className="mb-3 col">
																<label htmlFor="item_name" className="form-label">Item Name <span className="text-danger">*</span></label>
																<input autoFocus type="text" className="form-control" id="item_name" name="item_name" placeholder="E.g., Mobile" value={item_name} onChange={(e) => handleAddiFields(e.target.value)} required/>
																<div className="form-text">
																	Required: Minimum 3 characters
																</div>
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="row">
															<div className="mb-3 col">
																<label className="form-label">Please Select One <span className="text-danger">*</span></label>
																<div className="my-2">
																	<div className="form-check form-check-inline">
																		<input id="product_type" name="service_type" className="form-check-input" type="radio" value="0"  required checked={serviceType === '0'} onChange={(e) => getServiceType(e.target.value)} />
																		<label className="form-check-label" htmlFor="product_type"> Product </label>
																	</div>
																	<div className="form-check form-check-inline">
																		<input id="service_type" name="service_type" className="form-check-input" type="radio" value="1" required  checked={serviceType === '1'} onChange={(e) => getServiceType(e.target.value)} />
																		<label className="form-check-label" htmlFor="service_type"> Service </label>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														{itemAdditional && serviceType !== "" && <>
														<div className="row">
															<div className="mb-3 col">
															{serviceType === "0" && <>
																<label htmlFor="item_unit" className="form-label">Item Unit <span className="text-danger">*</span></label>
																<Typeahead clearButton id="item_unit" key="id" className="floatingTypeahead" onChange={(e) => setItemUnit(e[0] ? e[0].id : '')} placeholder="Select Unit"
																	options={units}
																	labelKey={option => `${option.description} ( ${option.code} )`}
																	renderMenuItemChildren={(option: Item,props) => (
																		<Highlighter search={props.text}>
																			{option.description +' ( '+ option.code+' )'}
																		</Highlighter>
																	)}
																	defaultSelected={[units.find(obj=>{return obj.code === 'NOS'})]}
																/>
															</>}
															{serviceType === "1" && <>
																<label htmlFor="item_unit" className="form-label">Item Unit <span className="text-danger">*</span></label>
																<Typeahead disabled id="item_unit" key="id" className="floatingTypeahead"
																	options={units}
																	labelKey={option => `${option.description} ( ${option.code} )`}
																	renderMenuItemChildren={(option: Item,props) => (
																		<Highlighter search={props.text}>
																			{option.description +' ( '+ option.code+' )'}
																		</Highlighter>
																	)}
																	defaultSelected={[units.find(obj=>{return obj.code === 'OTH'})]}
																/>
															</>}
															</div>
															<div className="mb-3 col">
																<label htmlFor="item_hsn" className="form-label">HSN Code</label>
																<div className="input-group input-group-merge">
																	{serviceType === "1" && <>
																		<div className="input-group-prepend">
																			<div className="input-group-text border-start rounded-start">99</div>
																		</div>
																	</>}
																	<input type="text" className="form-control" id="item_hsn" name="item_hsn" placeholder={(serviceType === '1') ? "E.g., 99094112" : "E.g., 99882112"} value={item_hsn} maxLength={(serviceType === '1') ? '6' : '8'} onChange={(e) => setItemHSN(e.target.value)} />
																</div>
															</div>
														</div>
														{serviceType !== "1" && <>
															<div className='row'>
																<div className="mb-3 col">
																	<label className="pt-4">
																		<input className="form-check-input me-1" type="checkbox" value={itemStockMovement} onChange={getItemStockMovement} name='item_stock_movement' checked={itemStockChecked}/> Track Item stock movement
																	</label>
																</div>
																{itemStockChecked === true && <>
																	<div className="mb-3 col">
																		<label className="pt-4">Allow negative balance</label>
																		<label className="">
																			<input name="default-radio-1" className="form-check-input" type="radio" value={isNegativeBal} id="defaultRadio2" onClick={(e) => {setIsNegativeBal(true); setAllowNegStock(1);} } /> Yes 
																			<input name="default-radio-1" className="form-check-input" type="radio" value={isNegativeBal} id="defaultRadio2" style={{'marginLeft': '1rem'}} onClick={(e) => {setIsNegativeBal(false); setAllowNegStock(0); }} defaultChecked /> No
																		</label>
																	</div>
																</>}
															</div>
														</>}
														</>}
													</div>

													<div className="col-sm-6">
														{itemAdditional && serviceType !== "" && <>
														<div className="row">
															<div className="mb-3 col-md-6">
																<label className="form-label" htmlFor="gst_rate">GST</label>
																<div className="input-group input-group-merge">
																	<span className={"input-group-text"}><i className='bx bxs-offer'></i></span>
																	<Typeahead clearButton id="gst_rate" key="id" className="floatingTypeahead border-end rounded-end" onChange={(e) => {setGstRate(e[0] ? e[0].val : '');setGstRateId(e[0] ? e[0].id : '');purchaseItemCalculation('gst_rate',(e[0] ? e[0].val : ''));saleItemCalculation('gst_rate',(e[0] ? e[0].val : ''));}} placeholder="Select GST"
																		options={taxes}
																		labelKey="rate"
																		renderMenuItemChildren={(option: Item,props) => (
																			<Highlighter search={props.text}>
																				{option.rate}
																			</Highlighter>
																		)}
																		defaultSelected={taxes.slice(0,1)}
																	/>
																</div>
															</div>
															{serviceType !== "1" && <>
																<div className="mb-3 col-md-6">
																	<label htmlFor="min_qty" className="form-label">Min Order Qty <span className="text-danger">*</span></label>&nbsp;&nbsp;
																	<Common.OverlayTrigger overlay={Common.popoverContent('','The minimum quantity to be ordered')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
																	<input type="text" className="form-control" id="min_qty" name="min_qty" placeholder="" value={min_qty} onChange={(e) => setMinQty(Common.acceptOnlyDecimal(e.target.value))} required/>
																</div>
															</>}
														</div>
														{itemStockChecked === true && serviceType !== "1" ? <>
																<div className="row">
																	<div className="mb-3 col">
																		<label htmlFor="opening_stock" className="form-label">Opening Stock Qty</label>
																		<div className="input-group input-group-merge">
																			<input type="text" className="form-control" id="opening_stock" name="opening_stock" placeholder="" value={opening_stock} onChange={(e) => setOpeningStock(Common.acceptOnlyNumbers(e.target.value))} disabled = {(itemStockMovement === '1') ? false : true} />
																		</div>
																	</div>
																	<div className="mb-3 col">
																		<label htmlFor="opening_stock_date" className="form-label">Opening Stock Qty as on Date</label>
																		<DatePicker name="opening_stock_date" data-testid="opening_stock_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningStockDt} value={opening_stock_date} disabled = {(itemStockMovement === '1') ? false : true} />
																	</div>
																</div>
															</>: <></> }
														{/* {serviceType !== "1" && <>
														<div className="row">
															<div className="mb-3 col">
																<label htmlFor="min_qty" className="form-label">Min Order Qty <span className="text-danger">*</span></label>&nbsp;&nbsp;
																<Common.OverlayTrigger overlay={Common.popoverContent('','The minimum quantity to be ordered')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
																<input type="text" className="form-control" id="min_qty" name="min_qty" placeholder="" value={min_qty} onChange={(e) => setMinQty(Common.acceptOnlyDecimal(e.target.value))} required/>
															</div>
															<div className="mb-3 col">
																<label className="pt-4">
																	<input className="form-check-input me-1" type="checkbox" value={itemStockMovement} onChange={getItemStockMovement} name='item_stock_movement' checked={itemStockChecked}/> Track Item stock movement
																</label>
															</div>
														</div>
														</>} */}
														</>}
													</div>
													<div className={"row " + (salePurchasedividerDisplay ? 'd-block':'d-none')}>
														<div className="col">
															<div className="divider">
																<div className="divider-text"><i className={'bx bx-chevrons-'+ (salePurchaseAdditionalItemDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showSalePurchaseAdditionalItemFields}>{salePurchaseAdditionalItemDiv ? 'Hide':'Show'} Sale & Purchase Price fields</Common.Link><i className={'bx bx-chevrons-'+ (salePurchaseAdditionalItemDiv ? "up":"down")+' bx-fade-up'}></i></div>
															</div>
														</div>
													</div>
													{salePurchaseAdditionalItemDiv && 
												itemAdditional && <>
													<div className="col-sm-6 border-end">
														<h6 className='text-center mt-3'>Sale</h6>
														<div className="mb-3 col">
															<label htmlFor="item_price" className="form-label">Item Price / Unit </label>
															<div className="input-group input-group-merge">
																<span className="input-group-text"><i className="bx bx-rupee"></i></span>
																<input type="text" className="form-control" id="item_price" name="item_price" placeholder="0.00"  min="0" value={item_price} onChange={(e) => {saleItemCalculation('item_price',Common.acceptOnlyDecimal(e.target.value));setDiscountAmt('');setDiscountPer('');}}  />
															</div>
														</div>
														<div className="mb-3 col">
															<label className="form-label" htmlFor="discount_per">Discount</label>
															<div className="input-group input-group-merge">
																<span className={"input-group-text "+(disPerDisabled ? "disabled-bg" : "")}><i className='bx bxs-offer'></i></span>
																<input type="text" className="form-control" id="discount_per" placeholder="0.00" value={discount_per} onChange={(e) => calcDiscount('per',Common.acceptOnlyDecimal(e.target.value))} disabled={disPerDisabled} />
																<span className={"input-group-text "+(disAmtDisabled ? "disabled-bg" : "")}><i className='bx bx-rupee'></i></span>
																<input type="text" className="form-control" id="discount_amt" placeholder="0.00" value={discount_amt} onChange={(e) => calcDiscount('amt',Common.acceptOnlyDecimal(e.target.value))} disabled={disAmtDisabled} />
															</div>
														</div>
														<div className="mb-3 col">
															<label htmlFor="gross_amt" className="form-label">Gross Amount</label>
															<div className="input-group input-group-merge">
																<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
																<input type="number" className="form-control" id="gross_amt" placeholder="0.00"  min="0" value={gross_amt} readOnly />
															</div>
															<div className="form-text">
																Calculation: Price/Unit - Discount
															</div>
														</div>
														<div className="mb-3 col">
															<label htmlFor="gst_amt" className="form-label">GST Amount</label>
															<div className="input-group input-group-merge">
																<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
																<input type="text" className="form-control" id="gst_amt" name="gst_amt" placeholder="0.00"  min="0" value={gst_amt} readOnly />
															</div>
														</div>
														<div className="mb-3 col">
															<label htmlFor="item_total" className="form-label">Item Total</label>
															<div className="input-group input-group-merge">
																<span className="input-group-text"><i className="bx bx-rupee"></i></span>
																<input type="text" className="form-control" id="item_total" name="total_price" placeholder="0.00"  min="0" value={item_total} onChange={(e) => {saleItemCalculation('item_total',Common.acceptOnlyDecimal(e.target.value));}}/>
															</div>
															<div className="form-text">
																Calculation: Gross Amount + GST Amount
															</div>
														</div>
													</div>
													</>}
													{salePurchaseAdditionalItemDiv && 
													itemAdditional && <>
													<div className="col-sm-6">
														<h6 className='text-center mt-3'>Purchase</h6>
														<div className="mb-3 col">
															<label htmlFor="purchase_item_price" className="form-label">Item Price / Unit </label>
															<div className="input-group input-group-merge">
																<span className="input-group-text"><i className="bx bx-rupee"></i></span>
																<input type="text" className="form-control" id="purchase_item_price" name="purchase_item_price" placeholder="0.00"  min="0" value={purchase_item_price} onChange={(e) => {purchaseItemCalculation('item_price',Common.acceptOnlyDecimal(e.target.value));setPurchaseDiscountAmt('');setPurchaseDiscountPer('');}}  />
															</div>
														</div>
														<div className="mb-3 col">
															<label className="form-label" htmlFor="purchase_discount_per">Discount</label>
															<div className="input-group input-group-merge">
																<span className={"input-group-text "+(disPurchasePerDisabled ? "disabled-bg" : "")}><i className='bx bxs-offer'></i></span>
																<input type="text" className="form-control" id="purchase_discount_per" placeholder="0.00" value={purchase_discount_per} onChange={(e) => calcPurchaseDiscount('per',Common.acceptOnlyDecimal(e.target.value))} disabled={disPurchasePerDisabled} />
																<span className={"input-group-text "+(disPurchaseAmtDisabled ? "disabled-bg" : "")}><i className='bx bx-rupee'></i></span>
																<input type="text" className="form-control" id="purchase_discount_amt" placeholder="0.00" value={purchase_discount_amt} onChange={(e) => calcPurchaseDiscount('amt',Common.acceptOnlyDecimal(e.target.value))} disabled={disPurchaseAmtDisabled} />
															</div>
														</div>
														<div className="mb-3 col">
															<label htmlFor="purchase_gross_amt" className="form-label">Gross Amount</label>
															<div className="input-group input-group-merge">
																<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
																<input type="number" className="form-control" id="purchase_gross_amt" placeholder="0.00"  min="0" value={purchase_gross_amt} readOnly />
															</div>
															<div className="form-text">
																Calculation: Price/Unit - Discount
															</div>
														</div>
														<div className="mb-3 col">
															<label htmlFor="purchase_gst_amt" className="form-label">GST Amount</label>
															<div className="input-group input-group-merge">
																<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
																<input type="text" className="form-control" id="purchase_gst_amt" name="purchase_gst_amt" placeholder="0.00"  min="0" value={purchase_gst_amt} readOnly />
															</div>
														</div>
														<div className="mb-3 col">
															<label htmlFor="purchase_item_total" className="form-label">Item Total</label>
															<div className="input-group input-group-merge">
																<span className="input-group-text"><i className="bx bx-rupee"></i></span>
																<input type="text" className="form-control" id="purchase_item_total" name="purchase_item_total" placeholder="0.00"  min="0" value={purchase_item_total} onChange={(e) => {purchaseItemCalculation('item_total',Common.acceptOnlyDecimal(e.target.value));}}/>
															</div>
															<div className="form-text">
																Calculation: Gross Amount + GST Amount
															</div>
														</div>
													</div>
													</>}
												</div>
												<div className={"row " + (dividerDisplay ? 'd-block':'d-none')}>
													<div className="col">
														<div className="divider">
															<div className="divider-text"><i className={'bx bx-chevrons-'+ (additionalItemDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showAdditionalItemFields}>{additionalItemDiv ? 'Hide':'Show'} additional fields</Common.Link><i className={'bx bx-chevrons-'+ (additionalItemDiv ? "up":"down")+' bx-fade-up'}></i></div>
														</div>
													</div>
												</div>
												{additionalItemDiv && 
												<>
													<div className='row'>
														<div className="col-md-12" id="collection_tab">
															<div className="nav-align-top mb-4">
																<ul className="nav nav-tabs nav-fill" role="tablist">
																	<li className="nav-item">
																		<button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="true">
																			Item Details
																		</button>
																	</li>
																	<li className="nav-item">
																		<button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-profile" aria-controls="navs-justified-profile" aria-selected="false" onClick={toggeCustomInvFields}>
																			Custom Fields
																		</button>
																	</li>
																</ul>
																<div className="tab-content">
																	<div className="tab-pane fade show active" id="navs-justified-home" role="tabpanel">
																		<div className='row'>
																			<div className="col-sm-4">
																				<div className="mb-3 col">
																					<label htmlFor="sku_code" className="form-label">SKU Code </label>
																					<div className="input-group input-group-merge">
																						<input type="text" className="form-control" id="sku_code" name="sku_code" value={skuCode} onChange={(e)=> {setSkuCode(e.target.value);}} />
																					</div>
																				</div>
																			</div>
																			<div className="col-sm-4">
																				<div className="mb-3 col">
																					<label htmlFor="barcode" className="form-label">Barcode </label>
																					<div className="input-group input-group-merge">
																						<input type="text" className="form-control" id="barcode" name="barcode" value={barcode} onChange={(e)=> {setBarcode(e.target.value);}} />
																						<span className="input-group-text py-0 pe-1">
																							<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Scan Barcodes')}>
																								<i onClick={()=>getBarCode()} className={"bx bx-md bx-barcode-reader"}></i>
																							</Common.OverlayTrigger>
																						</span>
																					</div>
																				</div>
																			</div>
																			<div className="col-sm-4">
																				<div className="mb-3 col">
																					<label htmlFor="safety_stock" className="form-label">Safety Stock </label>
																					<div className="input-group input-group-merge">
																						<input type="text" className="form-control" id="safety_stock" name="safety_stock" placeholder="0.000" step=".001" min="0" value={safetyStock} onChange={(e)=> {setItemQty(Common.acceptOnlyDecimal(e.target.value));}} />
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className='row'>
																			<div className="col-sm-4">
																				<div className="mb-3 col">
																					<label htmlFor="reorder_point" className="form-label">Reorder Point </label>
																					<div className="input-group input-group-merge">
																						<input type="text" className="form-control" id="reorder_point" name="reorder_point" placeholder="0.000" step=".001" min="0" value={reorderPoint} onChange={(e)=> {setReorderPointValue(Common.acceptOnlyDecimal(e.target.value));}} />
																					</div>
																				</div>
																			</div>
																			<div className="col-sm-4">
																				<div className="mb-3 col">
																					<label htmlFor="reorder_qty" className="form-label">Reorder Quantity </label>
																					<div className="input-group input-group-merge">
																						<input type="text" className="form-control"  id="reorder_qty" name="reorder_qty" placeholder="0.000" step=".001" min="0" value={reorderQty} onChange={(e)=> {setReorderQtyValue(Common.acceptOnlyDecimal(e.target.value));}} />
																					</div>
																				</div>
																			</div>
																			<div className="col-sm-4">
																				<div className="mb-3 col">
																					<label htmlFor="max_stock_level" className="form-label">Maximum Stock Level </label>
																					<div className="input-group input-group-merge">
																						<input type="text" className="form-control" id="max_stock_level" name="max_stock_level" placeholder="0.000" step=".001" min="0" value={maximumStockLevel} onChange={(e)=> {setMaxStockLevelValue(Common.acceptOnlyDecimal(e.target.value));}} />
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="tab-pane fade" id="navs-justified-profile" role="tabpanel">
																		<div className='row'>
																			<div className='col-md-1'></div>
																			<div className='col-md-10'>
																				{/* <div className="card accordion-item"> */}
																					<div id="customInvFields" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#salesAccordion">
																						{customInputList}
																						<div className="mb-3 text-center">
																							<button type="button" className="btn btn-sm btn-primary me-2" onClick={handleCustomInvFields}><i className='bx bx-plus'></i> Add Fields</button>
																						</div>
																					</div>
																				{/* </div> */}
																			</div>
																			<div className='col-md-1'></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													
												</>}
                                                <div className="row ">
													<div className="col-sm-10">
														<a href={props.referrer === "fynone" ? "/item-listing" : "/mart-inventory"} role="button" className="me-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
														<button type='submit' className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
														&nbsp;&nbsp;
														<button type='button' onClick={resetForm} className="btn btn-sm btn-secondary" ><span className="tf-icons bx bx-reset"></span>&nbsp;Reset</button>
													</div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal size='md' show={barcodeScanModal} onHide={()=>setBarcodeScanModal(false)} aria-labelledby="Barcode-scan-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Barcode-scan-modal">Scan Barcode</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<div className="row">
				<div className={"py-2 col-12 text-center"}>
					<div className={"mx-auto"} style={{'width':'320px','height':'auto'}}>
						<div id="barReader" className="d-block"></div>
					</div>
				</div>
			</div>
			</Modal.Body>
		</Modal>
        </>
    );
}