import React from 'react';
import AccounHeader from './AccountHeader';
import '../styles/page-misc.css';

export default function Tnc(props) {
	React.useEffect(() => {
		document.title = 'FYN ONE - Billing & Accounting app for Bharat | Cancellation & Refund Policy';
	}, []);
	return (
		<div className="container-xxl container-p-y">
			<AccounHeader site_logo={props.site_logo} site_name={props.site_name} />
			<div className="text-center mx-5">
				<div><h2 className="mt-5 mx-2">Cancellation & Refund Policy</h2></div>
				<div>No cancellations & Refunds are applicable. Pl use the free trial period to explore all offerings before enrolling for the paid version</div>
				<div className="text-center mt-5">
					<a href="/" className="btn btn-info">Back to Home</a>
				</div>
			</div>
		</div>
	)
}