import React, {useState, useEffect} from 'react';

const NoInternetConnection = (props) => {
	const convertToBase64 = (src) => {
		fetch(src)
        .then((res) => res.blob())
        .then((blob) => {
            // Read the Blob as DataURL using the FileReader API
            const reader = new FileReader();
            reader.onloadend = () => {
				localStorage.setItem('noInternetImg',reader.result);
            };
            reader.readAsDataURL(blob);
        });
	}
	useEffect(() => {
		convertToBase64(window.location.origin+"/assets/img/no-internet.png");
	}, []);
	
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });
	
    // if user is online, return the child component else return a custom component
    if(isOnline){
		return(
			props.children
		)
    } else {
        return(
			<div className="container-xxl container-p-y">
				<div className="misc-wrapper">
					<h2 className="mb-2 mx-2">No internet :(</h2>
					<p className="mb-4 mx-2">Please check your internet connection.</p>
					<div className="mt-3">
						<img
							src={localStorage.getItem('noInternetImg')}
							alt="page-misc-error-light"
							width="500"
							className="img-fluid"
							data-app-dark-img="page-misc-error-dark.png"
							data-app-light-img="page-misc-error-light.png"
						/>
					</div>
				</div>
			</div>
		)
    }
}

export default NoInternetConnection;