import React from 'react'
import * as Common from '../common/ImportUserEssentials';

export default function PostLogin(props){
	const navigate = Common.useNavigate();
	React.useEffect(() => {
		if( Common.secureLocalStorage.getItem('fm_redirect') !== null ){
			navigate(Common.secureLocalStorage.getItem('fm_redirect'));
		} else {
			navigate('/home');
		}
	},[props]);
}