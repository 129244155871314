import React from 'react';

export default function AccounHeader(props){
    return (
        <div className="app-brand justify-content-center">
			<a href="/" className="app-brand-link gap-2">
				<span className="app-brand-logo demo">
					<img alt={props.site_name} height="65" width="auto" src={window.location.origin+'/'+props.site_logo}></img>
				</span>
				{/* <span className="app-brand-text demo text-body fw-bold">{props.site_name}</span> */}
			</a>
		  </div>
        );
}