import React,{useState} from 'react';
import * as Common from '../common/ImportUserEssentials';
import '../styles/fyn-ticket.css';
export default function FYNTicket(props){
	const [slideDown, setSlideDown] = useState('');
	const [slideUp, setSlideUp] = useState('');
	const handleTicket = () => {
        Common.axios({
            method: 'get',
            url: props.url+'user/ticket',
        }).then(function (response) {
            if(response.data.success){                
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
            }
            else{
				Common.toast.dismiss();
                Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
      }

    const slide =(param) => {
    	if (param === 'down') {
	      	setSlideDown('d-none');
	      	setSlideUp('d-block');
    	} else {
	      	setSlideDown('d-block');
	      	setSlideUp('d-none');
    	}
    }
    return(
		<>
		<div className="buy-now">
			<a data-bs-toggle="offcanvas" href="#fyn-ticket-div" role="button" className="btn btn-sm btn-danger btn-fyn-ticket">Need Help ?</a>
		</div>
		{ ! localStorage.getItem('fo_implemented_by_name') && (<>
		<div className="offcanvas offcanvas-end" tabIndex="-1" id="fyn-ticket-div" aria-labelledby="fyn-ticket-divLabel" style={{"visibility": "hidden"}} aria-hidden="true">
			<div id="slide-div" className={slideDown.toString()}>
				<div className="offcanvas-header">
					<h3 id="fyn-ticket-divLabel" className="offcanvas-title">We're here to help</h3>
					<button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
				</div>
				<div className="offcanvas-body my-auto mx-0 flex-grow-0">
					<p className="text-center">
						If you have any questions, concerns or issues, we're here to help. Please create a new Ticket to help us serve you better.
					</p>
					{/* <div className="text-center d-grid d-md-block"> */}
					<div className="row btn-row text-nowrap">
						<div className='col-sm-4'>
							<button type="button" id='create-ticket' onClick={handleTicket} className="btn btn-sm btn-primary"><i className='bx bx-message-square-dots'></i> Create Ticket</button>
						</div>
						<div className='col-md-4'>
							<a target="_blank" rel="noreferrer" id='help-tutorial' href="https://www.youtube.com/watch?v=uFOhnS8QuEA&list=PLuiAXtE-r0eJ_7itrAZWR1rg7sOCEQZC2" className="btn btn-sm btn-outline-secondary"><i className='bx bx-play-circle'></i> Help Tutorial</a>
						</div>
						<div className='col-sm-4'>
							<a target="_blank" rel="noreferrer" id='whatsapp' href="https://whatsapp.com/channel/0029VaKkjmX7YSd0uX1XqW2z" className="btn btn-sm btn-outline-secondary"><i className='bx bxl-whatsapp'></i> Channel</a>
						</div>
					</div>
					<div className='row btn-row'>
						<div className='col-md-4'></div>
						<div className='col-md-4'>
							<button type="button" id='support-btn' className="btn btn-sm btn-outline-secondary mt-2" onClick={() => slide('down')}><i className='bx bx-mail-send' ></i> Support</button>
						</div>
					</div>

				</div>
			</div>
			<div id="slide-div-down" className={slideUp.toString()}>
				<div className="offcanvas-header">
					<h3 id="fyn-ticket-divLabel" className="offcanvas-title margin-l text-center" style={{marginLeft: '110px'}}>For Support</h3>
					<button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
				</div>
				<div className="offcanvas-body flex-grow-0" style={{marginTop: '45px'}}>
					<p className="text-center">
						Email us to : support@fynone.com
					</p>
				<div className="text-center d-grid gap-2 d-md-block" style={{marginTop: '50px'}}>
					<button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => slide('up')}><i className='bx bx-arrow-back' ></i> Back</button>
				</div>
				</div>
			</div>
		</div>
		</>)}
		{localStorage.getItem('fo_implemented_by_name') && (<>
		<div className="offcanvas offcanvas-end" tabIndex="-1" id="fyn-ticket-div" aria-labelledby="fyn-ticket-divLabel" style={{"visibility": "hidden"}} aria-hidden="true">
			<div id="slide-div-down">
				<div className="offcanvas-header">
					<h3 id="fyn-ticket-divLabel" className="offcanvas-title margin-l text-center" style={{marginLeft: '110px'}}>For Support</h3>
					<button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
				</div>
				<div className="offcanvas-body flex-grow-0 text-center" style={{marginTop: '15px'}}>
					<h5>Contact Your Partner</h5>
					{ localStorage.getItem('fo_implemented_by_logo') !== "null" && (<>
						<img src = {props.url+"public/assets/img/profile_logo/"+localStorage.getItem('fo_implemented_by_logo')} alt="user-avatar"  className="rounded" height="100" width="100" />&nbsp;&nbsp;
					</>)}
					<span>
						<b>{ localStorage.getItem('fo_implemented_by_name') }</b>
					</span>
				</div>
			</div>
		</div>
		</>)}
		</>
    );
}