import React,{useState}  from "react";
import Layout from './Layout';
import Button from 'react-bootstrap/Button';
import NavBar from './NavBar';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Select from "react-select";
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import moment from 'moment';
import styled from 'styled-components';

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;

export default function DispatchStock(props){
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
    const [DTpending, setDTPending]                    = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
    const [sales, setSalesList]                        = useState([]);
	const [genEwayShow,setGenEwayShow]                 = useState(false);
	const [genGatepassShow,setGenGatepassShow]         = useState(false);
	const [transporterName,setTransporterName]         = useState('');
	const [transporterID,setTransporterId]             = useState('');
	const [approxDistance,setApproxDistance]           = useState(0);
    
	const [transporterDocNo,setTransporterDocNo]       = useState('');
	const [transporterDocDt,setTransporterDocDt]       = useState();
	const [vehicleNo,setVehicleNo]                     = useState('');
	const [vehicle_type,getVehicleType]                = useState('R');
	const [trans_mode,setTransMode]                    = useState('1');
	const [dispatchDate,setDispatchDate]               = useState(new Date());
	const [modeTypeLabel,setModeTypeLabel]             = useState('Transporter Doc.');
	const [saleId,setSaleId]                           = useState('');
	const [ewbNo,setEwbNo]                             = useState('');
	const [ewbErrorDetail,setEwbErrorDetail]           = useState('');
	const [ewbCancelReason,setEwbCancelReason]         = useState('');
	const [ewbCancelRemarks,setEwbCancelRemarks]       = useState('');
	const [saleEwabId,setSaleEwbId]                    = useState('');
	const [ewbCancelError,setEwbCancelError]           = useState('');
	const [cancelFieldDiv,setCancelFieldDiv]           = useState(false);
	const [gatepassNo,setGatepassNo]                   = useState('');
	const [gatepassCancelFieldDiv,setGatepassCancelDiv]= useState(false);
	const [gatepassCanReason,setGatepassCanReason]     = useState('');
	const [gatepassCanRemarks,setGatepassCanRemarks]   = useState('');
	const [gatepassId,setGatepassId]                   = useState('');
	const [exportBtnClass,setExportBtnClass]    = React.useState('d-none');
	const [ewbStatus,setEwbStatus]    = React.useState('0');
    const [sortOrder,setSortOrder]    = React.useState('');
    const invoiceTabTypes             = ['Active', 'Cancelled'];
    const [activeTab, setActCan]      = React.useState(invoiceTabTypes[0]);
    const mainTabTypes                = ['Sale', 'Delivery Challan'];
	const [mainTab, setMainTab]       = React.useState(mainTabTypes[0]);
    const [dcData, setDCData]         = React.useState([]);
    const [totalDcRows, setTotalDcRows] = React.useState(0);
    const [totalDcDataCnt, setTotalDcDataCnt] = React.useState(0);
    const navigate = Common.useNavigate();
    const [dCCancelShow,setDCCancelShow] = React.useState(false);
    const [genDCEwayShow,setGenDCEwayShow] = React.useState(false);
    const [genDCGatepassShow,setGenDCGatepassShow] = React.useState(false);
    const [invNo,setInvNo] = React.useState('');
    const [dcCancelRemarks,setDcCancelRemarks] = React.useState('');
    const [deliveryChallanId,setDeliveryChallanId] = React.useState('');
    const [dcEwbId,setDCEwbId] = React.useState('');
    const [dCAlert,setDCAlert] = React.useState('');
    const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
	const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
    let state = Common.useLocation();
	
    React.useEffect(() => {
        document.title = 'Dispatch Stock | '+props.site_name;
        let tab = mainTabTypes[0];
        if (state.state !== null) {
            setMainTab(mainTabTypes[1])
            tab = mainTabTypes[1];
        }
        if (mainTab === 'Delivery Challan' || tab === 'Delivery Challan') {
            loadDataDCTable(1,perPage,filterText,dates,'');
        } else {
            loadDataTable(1,perPage,filterText);
        }
    },[props,filterText,dt_load,dates,mainTab]);
    const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
    const setDatePicker = (start, end) => {
		setDates({ start, end });
		//setDtLoad(!dt_load);
    }
	const handlePageChange = page => {
        if (mainTab === 'Delivery Challan') {
            loadDataDCTable(page,perPage,filterText,dates,sortOrder);
        } else {
            loadDataTable(page,perPage,filterText,sortOrder);
        }
	};
	const handlePerRowsChange = async (newPerPage, page) => {
        if (mainTab === 'Delivery Challan') {
            loadDataDCTable(page,newPerPage,filterText,dates,sortOrder);
        } else {
            loadDataTable(page,newPerPage,filterText,sortOrder);
        }
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
        setSortOrder(btoa(column.sortField+'####'+sortDirection))
        if (mainTab === 'Delivery Challan') {
            loadDataDCTable(1,perPage,filterText,dates,btoa(column.sortField+'####'+sortDirection));
        } else {
            loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection));
        }
		// loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection));
	};
    const loadDataTable = (page,perPage,filterText,sort='') => {
        Common.axios({
            method: 'post',
            url:props.url+'sales/dispatchStockListing',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText}
        }).then(function (response) {
			setExportBtnClass('d-none');
            if(response.data.success){
                setSalesList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
                setEwbStatus((response.data.data[0])?response.data.data[0].ewb_status:0);
				setDTPending(false);
				if(response.data.total > 0){
					setExportBtnClass('');
				}
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    
    const columns = [
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu" >
                  <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.id,1,row.inv_no)}><i className="bx bxs-download"></i> Download Invoice</button>
                  <button className="dropdown-item" onClick={(e) => handleDownloadEWayBill(row.id,row.ewbno,row.gatepass_no,1)}><i className="bx bxs-download"></i> Download EWay Bill</button>
                  <button className="dropdown-item" onClick={(e) => handleDownloadEWayBill(row.id,row.ewbno,row.gatepass_no,2)}><i className="bx bxs-download"></i> Download Gatepass</button>
                </div>
              </div>
            )
        },
        {
            name: 'Invoice No',
            selector: row => row.inv_no,
            sortable: true,
			sortField: 'inv_no'
        },
        {
            name: 'Invoice Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date'
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: false,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        }       
	];
    if (ewbStatus === '1') {
        columns.push({
            name: 'Generate EWB',
            sortable: false,
			center: true,
            cell: (row) => (
				( row.ewbno === null && row.ewb_error_detail !== null) ? <Button variant="danger" size="sm" onClick={(e)=>genEwayBill(row.id,1)}>View Error</Button> : ( row.ewbno === null) ? <Button variant="primary" size="sm" onClick={(e)=>genEwayBill(row.id,0)}>E-Way Bill</Button> : <a className='text-primary cursor-pointer' onClick={(e)=>genEwayBill(row.id,2)}>{ row.ewbno }</a>
			)
        })
    }
    columns.push({
        name: 'Generate Gatepass',
        sortable: false,
        center: true,
        cell: (row) => (
            ( (row.ewbno !== null && row.gatepass_no === null) ) ? <Button variant="warning" size="sm" onClick={(e)=>genGatepass(row.id,1,row.gatepass_no)}>Gatepass</Button> : ( row.gatepass_no === null) ? <Button variant="warning" size="sm" onClick={(e)=>genGatepass(row.id,0,row.gatepass_no)}>Gatepass</Button> : <a className='text-primary cursor-pointer' onClick={(e)=>genGatepass(row.id,1,row.gatepass_no)}>{ row.gatepass_no }</a>
        )
    },
    {
        name: 'Created By',
        selector: row => row.created_by,
        sortable: false,
        sortField: 'created_by'
    },
    {
        name: 'Created On',
        selector: row => row.created_on,
        sortable: true,
        sortField: 'created_on'
    },
    {
        name: 'Updated By',
        selector: row => row.updated_by,
        sortable: false,
        sortField: 'updated_by'
    },
    {
        name: 'Updated On',
        selector: row => row.updated_on,
        sortable: true,
        sortField: 'updated_on'
    });
    const handleEditDC =(dc_id) => {
        let urlPath = 'edit-dc';
        navigate('/'+ urlPath,
        {
            state: {
                dc_id: dc_id,
                action: 'edit',
            }
        });
    }
    const dcColumns = [
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu" >
                    {( row.ewbno === null)?<button className="dropdown-item" onClick={(e) => handleEditDC(row.id)}><i className='bx bxs-edit'></i> Edit Delivery Challan</button>:''}
                    <button className="dropdown-item" onClick={(e) => handleDCDownloadPdf(row.id,1,row.inv_no)}><i className="bx bxs-download"></i> Download Delivery Challan</button>
                    <button className="dropdown-item" onClick={(e) => handleCancelDC(row)}><i className="bx bx-message-square-x"></i> Cancel Delivery Challan</button>
                    {/* <button className="dropdown-item" onClick={(e) => handleDownloadEWayBill(row.id,row.ewbno,row.gatepass_no,1)}><i className="bx bxs-download"></i> Download EWay Bill</button> */}
                    {/* <button className="dropdown-item" onClick={(e) => handleDownloadEWayBill(row.id,row.ewbno,row.gatepass_no,2)}><i className="bx bxs-download"></i> Download Gatepass</button> */}
                </div>
              </div>
            )
        },
        {
            name: 'Document No',
            selector: row => row.inv_no,
            sortable: true,
			sortField: 'inv_no'
        },
        {
            name: 'Document Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date'
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: false,
			sortField: 'party_name'
        },
        {
            name: 'Total Amount',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        },
        {
            name: 'Generate EWB',
            sortable: false,
			center: true,
            cell: (row) => (
				( row.ewbno === null && row.ewb_error_detail !== null) ? <Button variant="danger" size="sm" onClick={(e)=>genDCEwayBill(row.id,1)}>View Error</Button> : ( row.ewbno === null) ? <Button variant="primary" size="sm" onClick={(e)=>genDCEwayBill(row.id,0)}>E-Way Bill</Button> : <a className='text-primary cursor-pointer' onClick={(e)=>genDCEwayBill(row.id,2)}>{ row.ewbno }</a>
			)
        }
    ];
    /* if (ewbStatus === '1') {
        dcColumns.push({
            name: 'Generate EWB',
            sortable: false,
			center: true,
            cell: (row) => (
				( row.ewbno === null && row.ewb_error_detail !== null) ? <Button variant="danger" size="sm" onClick={(e)=>genDCEwayBill(row.id,1)}>View Error</Button> : ( row.ewbno === null) ? <Button variant="primary" size="sm" onClick={(e)=>genDCEwayBill(row.id,0)}>E-Way Bill</Button> : <a className='text-primary cursor-pointer' onClick={(e)=>genDCEwayBill(row.id,2)}>{ row.ewbno }</a>
			)
        })
    } */

    dcColumns.push({
        name: 'Generate Gatepass',
        sortable: false,
        center: true,
        cell: (row) => (
            ( (row.ewbno !== null && row.gatepass_no === null) ) ? <Button variant="warning" size="sm" onClick={(e)=>genDCGatepass(row.id,1,row.gatepass_no)}>Gatepass</Button> : ( row.gatepass_no === null) ? <Button variant="warning" size="sm" onClick={(e)=>genDCGatepass(row.id,0,row.gatepass_no)}>Gatepass</Button> : <a className='text-primary cursor-pointer' onClick={(e)=>genDCGatepass(row.id,1,row.gatepass_no)}>{ row.gatepass_no }</a>
        )
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: true,
            sortField: 'created_by'
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
            sortField: 'created_on'
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: true,
            sortField: 'updated_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
            sortField: 'updated_on'
        }
    )
    const handleCancelDC = (details) => {
        console.info(details);
        setInvNo(details.inv_no);
        setDeliveryChallanId(details.id);
        if ( details.ewbno !== null) {
            setDCAlert(true)
            return false;
        }
        setDCCancelShow(true)
    }
    const handleCancelDeliveryChallan = (e) => {
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['dcid'] = deliveryChallanId;
		data['pid']         = localStorage.getItem('fo_profileid');
		Common.axios({
            method: 'post',
            url:props.url+'delivery-challan/cancelDeliveryChallan',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setDCCancelShow(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
					loadDataDCTable(1,perPage,filterText,dates,'');
					// window.location.reload(true);
					return false;
                }else{
                    Common.toast.error(response.data.message);
                }
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    const genEwayBill = async (id,genStatus) => {
		Common.showLoader(true);
        let check_status = 0; 
        if(genStatus === 0 || genStatus === 1){
            await Common.axios({
                method: 'get',
                url:props.url+'sales/getSaleDetails/'+id,
            }).then(function (response) {
                Common.showLoader(false);
                if(response.data.success){
                    if(response.data.data.dispatch_stock_flag === '0'){
                        check_status = 1;
                        Common.toast.error('Invoice already cancelled');
                        return false;
                    }
                    if(genStatus === 0){
                        setGenEwayShow(true);
                    }
                }
            }).catch(error => console.log(error));
        }
        if(check_status === 1){
            loadDataTable(1,perPage,filterText,sortOrder);
            return false;
        }
        reset();
        setSaleId(id);
        if(genStatus === 1 || genStatus === 2){
            Common.axios({
                method: 'get',
                url:props.url+'sales/dispatchStockdetails/'+id,
            }).then(function (response) {
                if(response.data.success){
                    setTransporterName(response.data.data.transporter_name);
                    setTransporterId(response.data.data.transporter_id);
                    setApproxDistance(response.data.data.approx_distance);
                    setTransporterDocNo(response.data.data.transpoter_doc_no);
                    setTransporterDocDt(response.data.data.transporter_date);
                    setVehicleNo(response.data.data.vehicle_no);
                    getVehicleType(response.data.data.vehicle_type);
                    setTransMode(response.data.data.mode_of_transport);
                    setDispatchDate(response.data.data.dispatch_date);
                    setEwbNo(response.data.data.ewbno);
                    setEwbErrorDetail(JSON.parse(response.data.data.ewb_error_detail));
                    setSaleEwbId(response.data.data.sale_ewb_id);
                    setEwbCancelError('');
                    if(response.data.data.cancel_error_message){
                        setEwbCancelError(JSON.parse(response.data.data.cancel_error_message));
                    }
                    switch(response.data.data.mode_of_transport) {
                        case '2':
                            setModeTypeLabel('RR');
                            break;
                        case '3':
                            setModeTypeLabel('Airway Bill');
                          break;
                        case '4':
                            setModeTypeLabel('Bill of lading');
                            break;
                        default:
                            setModeTypeLabel('Transporter Doc.');
                    }
                    setGenEwayShow(true);
                }
            }).catch(error => console.log(error));
        }
        Common.showLoader(false);
    }
    const genDCEwayBill = async (id, genStatus) => {
        Common.showLoader(true);
        setDeliveryChallanId(id);
        reset();
        if (genStatus === 0) {
            setGenDCEwayShow(true);
        } else if(genStatus === 1 || genStatus === 2){
            Common.axios({
                method: 'get',
                url:props.url+'delivery-challan/dispatchStockdetails/'+id,
            }).then(function (response) {
                if(response.data.success){
                    console.info(response.data.data);
                    setTransporterName(response.data.data.transporter_name);
                    setTransporterId(response.data.data.transporter_id);
                    setApproxDistance(response.data.data.approx_distance);
                    setTransporterDocNo(response.data.data.transpoter_doc_no);
                    setTransporterDocDt(response.data.data.transporter_date);
                    setVehicleNo(response.data.data.vehicle_no);
                    getVehicleType(response.data.data.vehicle_type);
                    setTransMode(response.data.data.mode_of_transport);
                    setDispatchDate(response.data.data.dispatch_date);
                    setEwbNo(response.data.data.ewbno);
                    setEwbErrorDetail(JSON.parse(response.data.data.ewb_error_detail));
                    setDCEwbId(response.data.data.dc_ewb_id);
                    setEwbCancelError('');
                    if(response.data.data.cancel_error_message){
                        setEwbCancelError(JSON.parse(response.data.data.cancel_error_message));
                    }
                    switch(response.data.data.mode_of_transport) {
                        case '2':
                            setModeTypeLabel('RR');
                            break;
                        case '3':
                            setModeTypeLabel('Airway Bill');
                          break;
                        case '4':
                            setModeTypeLabel('Bill of lading');
                            break;
                        default:
                            setModeTypeLabel('Transporter Doc.');
                    }
                    setGenDCEwayShow(true);
                }
            }).catch(error => console.log(error));
        }
        Common.showLoader(false);
    }
    const genDCGatepass = async (id,genStatus,gp_no) => {
        Common.showLoader(true);
        setDeliveryChallanId(id);
        reset();
        if (genStatus === 0) {
            setGatepassNo('');
            setVehicleNo('');
            setGenDCGatepassShow(true)
        } else if(genStatus === 1){
            Common.axios({
                method: 'get',
                url:props.url+'delivery-challan/dispatchStockdetails/'+id,
            }).then(function (response) {
                if(response.data.success){
                    setTransporterName(response.data.data.transporter_name);
                    setTransporterId(response.data.data.transporter_id);
                    setApproxDistance(response.data.data.approx_distance);
                    setTransporterDocNo(response.data.data.transpoter_doc_no);
                    setTransporterDocDt(response.data.data.transporter_date);
                    setVehicleNo(response.data.data.vehicle_no);
                    getVehicleType(response.data.data.vehicle_type);
                    setTransMode(response.data.data.mode_of_transport);
                    setDispatchDate(response.data.data.dispatch_date);
                    setEwbNo(response.data.data.ewbno);
                    setEwbErrorDetail(JSON.parse(response.data.data.ewb_error_detail));
                    setSaleEwbId(response.data.data.dc_ewb_id);
                    setGatepassNo(response.data.data.gatepass_no)
                    setEwbCancelError('');
                    if(response.data.data.cancel_error_message){
                        setEwbCancelError(JSON.parse(response.data.data.cancel_error_message));
                    }
                    switch(response.data.data.mode_of_transport) {
                        case '2':
                            setModeTypeLabel('RR');
                            break;
                        case '3':
                            setModeTypeLabel('Airway Bill');
                          break;
                        case '4':
                            setModeTypeLabel('Bill of lading');
                            break;
                        default:
                            setModeTypeLabel('Transporter Doc.');
                    }
                    setGenDCGatepassShow(true);
                }
            }).catch(error => console.log(error));
        }
        Common.showLoader(false);
        
    }
	const handleDownloadPdf =(val,status,invNum) => {
		Common.showLoader(true);
		let url = props.url+'sales/getData/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status));
        Common.axios({
            method: 'get',
            url:url,
            // responseType: 'blob',
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let currentdate = new Date();
				let timesec = currentdate.getTime().toString().substring(2, 6);
				let fileName = currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() + "_" + timesec;
                link.setAttribute('download', invNum+'_'+fileName+'.pdf');
                document.body.appendChild(link);
                link.click(); */
            }
            else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => {console.log(error);Common.showLoader(false);});
    }
	const handleDCDownloadPdf =(val,status,invNum) => {
		Common.showLoader(true);
		let url = props.url+'delivery-challan/getData/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status));
        Common.axios({
            method: 'get',
            url:url,
            // responseType: 'blob',
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let currentdate = new Date();
				let timesec = currentdate.getTime().toString().substring(2, 6);
				let fileName = currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() + "_" + timesec;
                link.setAttribute('download', invNum+'_'+fileName+'.pdf');
                document.body.appendChild(link);
                link.click(); */
            }
            else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => {console.log(error);Common.showLoader(false);});
    }
    const handleDownloadEWayBill =(val,ewbno,gatepass_no,status) => {
        if(!ewbno && status === 1){
            Common.toast.error('EWay Bill not generated.');
            return false;
        }
        if(!gatepass_no && status === 2){
            Common.toast.error('Gatepass not generated.');
            return false;
        }
		Common.showLoader(true);
		let url = props.url+'sales/ewbDownload/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status));
        Common.axios({
            method: 'get',
            url:url,
            // responseType: 'blob',
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${Date.now()}.pdf`);
                document.body.appendChild(link);
                link.click(); */
            }
            else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => {console.log(error);Common.showLoader(false);});
    }
    const handleDownloadDCEWayBill =(val,ewbno,gatepass_no,status) => {
        if(!ewbno && status === 1){
            Common.toast.error('EWay Bill not generated.');
            return false;
        }
        if(!gatepass_no && status === 2){
            Common.toast.error('Gatepass not generated.');
            return false;
        }
		Common.showLoader(true);
		let url = props.url+'delivery-challan/ewbDownload/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status));

        Common.axios({
            method: 'get',
            url:url,
            // responseType: 'blob',
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${Date.now()}.pdf`);
                document.body.appendChild(link);
                link.click(); */
            }
            else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => {console.log(error);Common.showLoader(false);});
    }
    const handleTransporterId = (newValue) => {
        setTransporterId(newValue);
    };
    const handleEwayBill = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['sale_id']      = saleId;
		data['pid']          = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'ajax/genEWayBill',
            data: data,
        }).then(function (response) {
			if(response.data.success){
                reset();
				setGenEwayShow(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
                }else{
                    Common.toast.error(response.data.message);
                }
                Common.showLoader(false);
                loadDataTable(1,perPage,filterText,sortOrder);
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    const handleDCEwayBill = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['dc_id']      = deliveryChallanId;
		data['pid']          = localStorage.getItem('fo_profileid');
		Common.axios({
            method: 'post',
            url:props.url+'delivery-challan/genEWayBill',
            data: data,
        }).then(function (response) {
			if(response.data.success){
                reset();
				setGenDCEwayShow(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
                }else{
                    Common.toast.error(response.data.message);
                }
                Common.showLoader(false);
                // loadDataTable(1,perPage,filterText,sortOrder);
                loadDataDCTable(1,perPage,filterText,dates);
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    const handleDCGatepass = (e) => {
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        if(dispatchDate == null){
            Common.toast.error("Dispatch Date is Required for Generate Gatepass.");
            Common.showLoader(false);
            return false;
        }
        let data = {};
        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['dc_id']      = deliveryChallanId;
		data['pid']          = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'delivery-challan/genGatepass',
            data: data,
        }).then(function (response) {
			if(response.data.success){
                reset();
				setGenDCGatepassShow(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
                }else{
                    Common.toast.error(response.data.message);
                }
                Common.showLoader(false);
                // loadDataTable(1,perPage,filterText,sortOrder);
                loadDataDCTable(1,perPage,filterText,dates,'')
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    const handleEwayBillCancel = ()=>{
        Common.showLoader(true);
        let errors = [];
        if( ewbCancelReason === '' ){
            errors.push('Cancel Reason cannot be blank.');
        }
        if( ewbCancelRemarks === '' ){
            errors.push('Cancel Remarks cannot be blank.');
        }
        if( errors.length > 0 ){
			Common.showLoader(false);
			let err_list = '';
			errors.forEach((val, index) => {
				err_list += '<li>'+val+'</li>';
			})
			const err_html = () => (
				<ul><strong>List of Errors:</strong>{Common.parse(err_list)}</ul>
			);
			Common.toast.error(err_html);
			return false;
		}
		Common.axios({
            method: 'post',
            url:props.url+'ajax/cancelEwayBill',
            data: {'sale_ewb_id':saleEwabId,'saleid':saleId,'pid':localStorage.getItem('fo_profileid'),'cancel_reason':ewbCancelReason,'cancel_remarks':ewbCancelRemarks},
        }).then(function (response) {
			if(response.data.success){
                setGenEwayShow(false);
                setCancelFieldDiv(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
                }else{
                    Common.toast.error(response.data.message);
                }
                Common.showLoader(false);
                loadDataTable(1,perPage,filterText,sortOrder);
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    const handleDCEwayBillCancel = ()=>{
        Common.showLoader(true);
        let errors = [];
        if( ewbCancelReason === '' ){
            errors.push('Cancel Reason cannot be blank.');
        }
        if( ewbCancelRemarks === '' ){
            errors.push('Cancel Remarks cannot be blank.');
        }
        if( errors.length > 0 ){
			Common.showLoader(false);
			let err_list = '';
			errors.forEach((val, index) => {
				err_list += '<li>'+val+'</li>';
			})
			const err_html = () => (
				<ul><strong>List of Errors:</strong>{Common.parse(err_list)}</ul>
			);
			Common.toast.error(err_html);
			return false;
		}
		Common.axios({
            method: 'post',
            url:props.url+'delivery-challan/cancelEwayBill',
            data: {'dc_ewb_id':dcEwbId,'dcid':deliveryChallanId,'pid':localStorage.getItem('fo_profileid'),'cancel_reason':ewbCancelReason,'cancel_remarks':ewbCancelRemarks},
        }).then(function (response) {
			if(response.data.success){
                setGenDCEwayShow(false);
                setCancelFieldDiv(false);
                setGatepassCanReason('')
                setGatepassCanRemarks('')
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
                }else{
                    Common.toast.error(response.data.message);
                }
                Common.showLoader(false);
                // loadDataTable(1,perPage,filterText,sortOrder);
                loadDataDCTable(1,perPage,filterText,dates,'');
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    const handleCancelField = () => {
        setCancelFieldDiv((cancelFieldDiv) ? false : true);
        if( !cancelFieldDiv ){
			setEwbCancelRemarks('');
		}
    };    
    const handleGatepassCancelField = () => {
        setGatepassCancelDiv((gatepassCancelFieldDiv) ? false : true);
        if( !gatepassCancelFieldDiv ){
			setGatepassCanRemarks('');
		}
    };

    const reset = ()=>{
        setTransporterName('');setTransporterId('');setApproxDistance(0);setTransporterDocNo('');
        setTransporterDocDt();setVehicleNo('');getVehicleType('R');setTransMode('1');setDispatchDate(new Date());setEwbNo('');setEwbErrorDetail('');setEwbCancelRemarks('');setCancelFieldDiv(false);setEwbCancelError('');setGatepassNo();
    }
    const genGatepass = async (id,genStatus,gp_no) => {
		Common.showLoader(true);
        let check_status = 0; 
        if(genStatus === 0 || genStatus === 1){
            await Common.axios({
                method: 'get',
                url:props.url+'sales/getSaleDetails/'+id,
            }).then(function (response) {
                Common.showLoader(false);
                if(response.data.success){
                    if(response.data.data.dispatch_stock_flag === '0'){
                        check_status = 1;
                        Common.toast.error('Invoice already cancelled');
                        return false;
                    }
                    if(genStatus === 0){
                        setGenGatepassShow(true);
                    }
                }
            }).catch(error => console.log(error));
        }
        if(check_status === 1){
            loadDataTable(1,perPage,filterText,sortOrder);
            return false;
        }
        reset();
        setSaleId(id);
        if(genStatus === 1){
            let post_url = props.url+'sales/dispatchStockdetails/'+id;
            if(gp_no){
                post_url = props.url+'sales/getGatepassDetails/'+id;
            }
            Common.axios({
                method: 'get',
                url:post_url,
            }).then(function (response) {
                if(response.data.success){
                    setTransporterName(response.data.data.transporter_name);
                    setTransporterId(response.data.data.transporter_id);
                    setApproxDistance(response.data.data.approx_distance);
                    setTransporterDocNo(response.data.data.transpoter_doc_no);
                    setTransporterDocDt(response.data.data.transporter_date);
                    setVehicleNo(response.data.data.vehicle_no);
                    getVehicleType(response.data.data.vehicle_type);
                    setTransMode(response.data.data.mode_of_transport);
                    setDispatchDate(response.data.data.dispatch_date);
                    setGatepassNo(response.data.data.gatepass_no);
                    setGatepassId(response.data.data.gatepass_id);
                    switch(response.data.data.mode_of_transport) {
                        case '2':
                            setModeTypeLabel('RR');
                            break;
                        case '3':
                            setModeTypeLabel('Airway Bill');
                          break;
                        case '4':
                            setModeTypeLabel('Bill of lading');
                            break;
                        default:
                            setModeTypeLabel('Transporter Doc.');
                    }
                    setGenGatepassShow(true);
                }
            }).catch(error => console.log(error));
        }
        Common.showLoader(false);
    }
    const handleGatepass = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        if(dispatchDate == null){
            Common.toast.error("Dispatch Date is Required for Generate Gatepass.");
            Common.showLoader(false);
            return false;
        }
        let data = {};
        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['sale_id']      = saleId;
		data['pid']          = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'sales/genGatepass',
            data: data,
        }).then(function (response) {
			if(response.data.success){
                reset();
				setGenGatepassShow(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
                }else{
                    Common.toast.error(response.data.message);
                }
                Common.showLoader(false);
                loadDataTable(1,perPage,filterText,sortOrder);
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    const handleGatepassCancel = ()=>{
        Common.showLoader(true);
        let errors = [];
        if( gatepassCanReason === '' ){
            errors.push('Cancel Reason cannot be blank.');
        }
        if( gatepassCanRemarks === '' ){
            errors.push('Cancel Remarks cannot be blank.');
        }
        if( errors.length > 0 ){
			Common.showLoader(false);
			let err_list = '';
			errors.forEach((val, index) => {
				err_list += '<li>'+val+'</li>';
			})
			const err_html = () => (
				<ul><strong>List of Errors:</strong>{Common.parse(err_list)}</ul>
			);
			Common.toast.error(err_html);
			return false;
		}
		Common.axios({
            method: 'post',
            url:props.url+'sales/cancelGatepass',
            data: {'gatepass_id':gatepassId,'pid':localStorage.getItem('fo_profileid'),'cancel_reason':gatepassCanReason,'cancel_remarks':gatepassCanRemarks},
        }).then(function (response) {
			if(response.data.success){
                setGenGatepassShow(false);
                setGatepassCancelDiv(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
                }else{
                    Common.toast.error(response.data.message);
                }
                Common.showLoader(false);
                loadDataTable(1,perPage,filterText,sortOrder);
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
	const handleExportCSV = (e)=>{
		e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'sale/dispatch_stock_download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText},
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
			} else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
	}
	const handleExportDcCSV = (e)=>{
		e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'delivery-challan/delivery_challan_download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText, dates:dates,active_tab:activeTab},
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
			} else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
	}
    function TabGroup() {
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{mainTabTypes.map(type => (
				<Tab
					key={type}
					active={mainTab === type}
					onClick={() => handleMainTab(type)}
				>
					{type}
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
	const handleMainTab = penColChecked => {
		setMainTab(penColChecked);
		setSortOrder('')
		if(penColChecked === 'Sale'){
			loadDataTable(1,perPage,filterText,'');
		} else {
			loadDataDCTable(1,perPage,filterText,dates,'',penColChecked);

        }
	};
	const handleTab = penColChecked => {
		setActCan(penColChecked);
        state.state = null
        // console.info(penColChecked);return false;
        if (mainTab === 'Delivery Challan') {
            loadDataDCTable(1,perPage,filterText,dates,'',penColChecked)
        } else {
            loadDataTable(1,perPage,filterText,dates,sortOrder,penColChecked);
        }
	};
    // delivery challan part
    const loadDataDCTable = (page,perPage,filterText,dates,sort='',active_tab=activeTab) => {
        let pid = 0
		/* 
		if (state !== null) {
			pid = state.pid
		} else {
			pid = localStorage.getItem('fo_profileid');
		} */
        pid = localStorage.getItem('fo_profileid');
		if( pid !== null ){
			Common.axios({
				method: 'post',
				url:props.url+'delivery-challan/listing/',
				data: {'pid':pid,'page':page,'perPage':perPage,'sort':sort,'search':filterText,'dates':dates,'active_tab':active_tab}
			}).then(function (response) {
				if(response.data.success){
					// setExportBtnClass('d-none');
                    setEwbStatus(response.data.data.ewb_status)
					setDCData(response.data.data);
					setTotalDcRows(response.data.total);
					setTotalDcDataCnt(prevState => ({
					...prevState,
						[active_tab]: response.data.total
					}));
					// setEinvoiceStatus(response.data.einvoice_status);
					setDTPending(false);
					if(response.data.total > 0){
                        setExportBtnClass('');
                    }
					/* setTotCustomer(prevState => ({
					...prevState,
						[active_tab]: response.data.tabInfo.customer
					})); */
					/* setTotInvAmt(prevState => ({
					...prevState,
						[active_tab]: response.data.tabInfo.amount
					})); */
				}
				if( response.data.action !== undefined && response.data.action === 'session'){
					Common.destroySession();
					navigate("/login");
					return false;
				}
			}).catch(error => console.log(error));
		}
    }

    return (
    <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="dispatch-stock" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Inventory Management /</span> Dispatch Stock</h4>
                            <div className="row">
                                <div className="pb-3">
                                    <div className="col-md-12">
                                        <TabGroup/>
                                    </div>
                                </div>
                                {mainTab === 'Sale' && (<>
                                    
                                    <div className="col-md-6 col-12 mb-1 text-center text-md-start">
                                        <button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span> </button>
                                    </div>
                                    <div className="col-md-6 col-12 text-center text-md-end mb-1">
                                        <Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Party Name, Mobile, Gstin, Pincode, State, District')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
                                        <DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                                    </div>
                                    <div className="col-md-12">
                                        <DTE.DataTable title={"Sale ("+totalDataCnt+")"} className="dataTables_wrapper rdt_AdjDrp" columns={columns} data={sales} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
                                    </div>
                                </>)}
                                {mainTab === 'Delivery Challan' && (<>
                                        <div className="col-md-4 col-lg-5 col-12 mb-1 text-center text-md-start">
                                            <button className={"btn btn-sm btn-info me-1 "+exportBtnClass} onClick={handleExportDcCSV}><i className="bx bx-download"></i><span className="hidden-phone">Export CSV</span></button>
                                            <Common.Link to="/add-dc" className="btn btn-primary btn-sm"> <i className='bx bx-plus'></i><span className="">Add Delivery Challan</span></Common.Link>
                                        </div>
                                        
                                        <div className="col-md-4 col-lg-3 col-12 text-center mb-1">
                                            <DateRangePicker
                                                initialSettings={DTE.DatePickerPredefined(moment,start,end)}
                                                onCallback={setDatePicker}>
                                                <div className="input-group input-group-sm">
                                                    <span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
                                                    <div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
                                                        <i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
                                                    </div>
                                                </div>
                                            </DateRangePicker>
                                            <small>Date range based on Invoice Date</small>
                                        </div>
                                        <div className="col-md-4 col-12 text-center text-md-end mb-1">
                                            <Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Invoice Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
                                            <DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                                        </div>
                                        <div className="col-md-12 col-12 text-center mb-1">
                                            <div className="form-check form-check-inline">
                                                <input id="active_type" className="form-check-input" type="radio" value="Active"  required checked={activeTab === 'Active'} onChange={(e) => handleTab(e.target.value)} />
                                                <label className="form-check-label" htmlFor="active_type"> Active {(activeTab === 'Active' && (totalDcDataCnt[activeTab] !== '' || totalDcDataCnt[activeTab] !== undefined || totalDcDataCnt[activeTab] !== null)) ? ' ('+totalDcDataCnt[activeTab]+')' : ''}</label>
                                                {/* {activeTab === 'Cancelled' && (<>
                                                    <div className="tabAdditionalInfo">
                                                        Cust.# - | Tot. Amt.(₹) -
                                                    </div>
                                                </>)} */}
                                            </div>
                                            
                                            <div className="form-check form-check-inline">
                                                <input id="cancelled_type" className="form-check-input" type="radio" value="Cancelled" required  checked={activeTab === 'Cancelled'} onChange={(e) => handleTab(e.target.value)} />
                                                <label className="form-check-label" htmlFor="cancelled_type"> Cancelled {(activeTab === 'Cancelled' && (totalDcDataCnt[activeTab] !== '' || totalDcDataCnt[activeTab] !== undefined || totalDcDataCnt[activeTab] !== null)) ? ' ('+totalDcDataCnt[activeTab]+')' : ''}</label>													
                                                {/* {activeTab === 'Cancelled' && (<>
                                                    <div className="tabAdditionalInfo">
                                                        Cust.# hwew
                                                        {totCustomer[activeTab]}
                                                        | Tot. Amt.(₹) {Common.numToWords(totInvAmt[activeTab])}
                                                    </div>
                                                </>)} */}
                                            </div>
                                        </div>
                                    <div className="col-md-12">
                                        <DTE.DataTable title={"Delivery Challan ("+totalDcRows+")"} className="dataTables_wrapper rdt_AdjDrp" columns={dcColumns} data={dcData} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalDcRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
                                    </div>
                                </>)}
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        <Modal size='lg' show={genEwayShow} onHide={()=>setGenEwayShow(false)} aria-labelledby="Eway-Gatepass-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Eway-Gatepass-modal">Generate EWay Bill</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addItmFrm" onSubmit={handleEwayBill} autoComplete="off">
                {/* <form id="addItmFrm" autoComplete="off"> */}
					<div className="row">
                        {ewbErrorDetail && <>
                            <div className="col-sm-12">
                                <p><b>Errors reported by NIC. For More details <a href='https://docs.ewaybillgst.gov.in/apidocs/api-error-codes-list.html' target="_blank" rel="noreferrer">Click Here</a></b></p>
                                <table className="table table-sm table-bordered dispatch_eway_error">
                                    <thead>
                                        <tr>
                                            <th width="15%">Error Code</th>
                                            <th>Error Details</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                    {ewbErrorDetail.map((val,index) => (
                                        <tr>
                                            <td>{val.ErrorCode}</td>
                                            <td>{val.ErrorMessage}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </>}
                        {ewbCancelError && <>
                            <div className="col-sm-12">
                                <p><b>Errors reported by NIC. For More details <a href='https://docs.ewaybillgst.gov.in/apidocs/api-error-codes-list.html' target="_blank" rel="noreferrer">Click Here</a></b></p>
                                <table className="table table-sm table-bordered dispatch_eway_error w-50">
                                    <thead>
                                        <tr>
                                            <th>Error Code</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                    {ewbCancelError.map((val1,index1) => (
                                        <tr key={'error'+index1}>
                                            <td>{val1.ErrorCode}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </>}
                        <div className="col-sm-12 text-center">
                            <h5>Transportation Details</h5>
                        </div>
						<div className="col-sm-4">
                            <label htmlFor="transporter_name" className="form-label">Transporter Name</label>
                            <div className="input-group input-group-merge">
                                <input type="text" className="form-control" id="transporter_name" name="transporter_name" placeholder="Transporter Name" value={transporterName} onChange={(e) => setTransporterName(e.target.value)}  disabled={ewbNo && transporterName?true:false} />
                            </div>
						</div>
                        <div className="col-sm-4">
                            <label htmlFor="opening_stock" className="form-label">Transporter ID</label>
                            <div className="input-group input-group-merge">
                                <input type="text" id="transporter_id" name="transporter_id" className="form-control" placeholder="Transporter ID" maxLength="15" value={transporterID} onChange={(e) => handleTransporterId(e.target.value)} disabled={ewbNo && transporterID?true:false}></input>
                            </div>
						</div>
                        <div className="col-sm-4">
                            <label htmlFor="approx_distance" className="form-label">Approximate Distance(in KM)</label>
                            <div className="input-group input-group-merge">
                                <input type="text" id="approx_distance" name="approx_distance" className="form-control" placeholder="Approximate Distance" value={approxDistance} onChange={(e) => setApproxDistance(Common.acceptOnlyDecimal(e.target.value))} disabled={ewbNo && approxDistance?true:false}></input>
                            </div>
						</div>
					</div>
                    <div className="row">
                        <div className="col-sm-12 mt-3 text-center">
                            <h5 className="input_form_subheader">Part B Details</h5>
                        </div>
                        <div className="col-md-2">&nbsp;</div>
						<div className="col-sm-8 mb-3">
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="transporter_name" className="form-label">Mode:&nbsp;&nbsp;</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="form-check form-check-inline">
                                        <input id="rotrans_mode" name="trans_mode" className="form-check-input" type="radio" value="1"  required checked={trans_mode === '1'} onChange={(e) => {setTransMode(e.target.value); setModeTypeLabel('Transporter Doc.')}} disabled={ewbNo?true:false}/>
                                        <label className="form-check-label" htmlFor="rotrans_mode"> Road </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="rtrans_mode" name="trans_mode" className="form-check-input" type="radio" value="2" required checked={trans_mode === '2'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('RR')}} disabled={ewbNo?true:false} />
                                        <label className="form-check-label" htmlFor="rtrans_mode"> Rail </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="atrans_mode" name="trans_mode" className="form-check-input" type="radio" value="3" required checked={trans_mode === '3'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('Airway Bill')}} disabled={ewbNo?true:false} />
                                        <label className="form-check-label" htmlFor="atrans_mode"> Air </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="strans_mode" name="trans_mode" className="form-check-input" type="radio" value="4" required checked={trans_mode === '4'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('Bill of lading')}} disabled={ewbNo?true:false}/>
                                        <label className="form-check-label" htmlFor="strans_mode"> Ship </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="transporter_name" className="form-label">Vehicle Type:&nbsp;&nbsp;</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="form-check form-check-inline">
                                        <input id="regular" name="vehicle_type" className="form-check-input" type="radio" value="R"  required checked={vehicle_type === 'R'} onChange={(e) => getVehicleType(e.target.value)} disabled={ewbNo?true:false} />
                                        <label className="form-check-label" htmlFor="regular"> Regular </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="ODC" name="vehicle_type" className="form-check-input" type="radio" value="O" required checked={vehicle_type === 'O'} onChange={(e) => getVehicleType(e.target.value)} disabled={ewbNo?true:false} />
                                        <label className="form-check-label" htmlFor="ODC"> Over Dimensional Cargo </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="vehicle_no" className="form-label">Vehicle No.</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" id="vehicle_no" name="vehicle_no" placeholder="Vehicle No." value={vehicleNo} onChange={(e) => setVehicleNo(e.target.value)}  disabled={ewbNo && vehicleNo?true:false}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="tr_doc_no" className="form-label">{modeTypeLabel} No.</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" id="tr_doc_no" name="tr_doc_no" placeholder="Transporter Doc. No." value={transporterDocNo} onChange={(e) => setTransporterDocNo(e.target.value)} disabled={ewbNo && transporterDocNo?true:false} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="tr_doc_no" className="form-label">{modeTypeLabel} Date</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <DatePicker name="tr_doc_date" data-testid="tr_doc_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setTransporterDocDt} value={transporterDocDt} disabled={ewbNo && transporterDocDt?true:false} />
                                    </div>
                                </div>
                            </div>
                            {cancelFieldDiv && <>
                                <div className="row mb-3 border">
                                    <div className="col-sm-6 pt-2">
                                        <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                                        <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} onChange={(e) => setEwbCancelReason(e.value)}/>
                                    </div>
                                    <div className="col-sm-6 pt-2">
                                        <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                                        <div className="input-group input-group-merge">
                                            <input type="text" className="form-control" id="cancel-remarks" name="cancel_remarks" placeholder="CancelRemarks" value={ewbCancelRemarks} onChange={(e) => setEwbCancelRemarks(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 text-center py-2">
                                        <button type='button' className="btn btn-sm btn-success" onClick={(e) => handleEwayBillCancel()}><i className='bx bx-paper-plane'></i>&nbsp;Submit</button>
                                    </div>
                                </div>
                            </>}
						</div>
                        <div className="col-md-2">&nbsp;</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-3">
                            {(ewbNo) ? <div><button type='button' onClick={(e) => handleDownloadEWayBill(saleId,ewbNo,gatepassNo,1)} className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Download EWay Bill</button>&nbsp;&nbsp;<button type='button' className="btn btn-sm btn-danger" onClick={(e) => handleCancelField()}><i className='bx bx-message-square-x'></i>&nbsp;Cancel EWay Bill</button></div> : <button type='submit' className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Generate EWay Bill</button>}
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
        <Modal size='lg' show={genGatepassShow} onHide={()=>setGenGatepassShow(false)} aria-labelledby="Eway-Gatepass-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Eway-Gatepass-modal">Generate Gatepass</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addItmFrm" onSubmit={handleGatepass} autoComplete="off">
					<div className="row">
                        <div className="col-sm-12 text-center">
                            <h5>Transportation Details</h5>
                        </div>
						<div className="col-sm-4">
                            <label htmlFor="transporter_name" className="form-label">Transporter Name</label>
                            <div className="input-group input-group-merge">
                                <input type="text" className="form-control" id="transporter_name" name="transporter_name" placeholder="Transporter Name" value={transporterName} onChange={(e) => setTransporterName(e.target.value)} />
                            </div>
						</div>
                        <div className="col-sm-4">
                            <label htmlFor="opening_stock" className="form-label">Transporter ID</label>
                            <div className="input-group input-group-merge">
                                <input type="text" id="transporter_id" name="transporter_id" className="form-control" placeholder="Transporter ID" maxLength="15" value={transporterID} onChange={(e) => handleTransporterId(e.target.value)} ></input>
                            </div>
						</div>
                        <div className="col-sm-4">
                            <label htmlFor="approx_distance" className="form-label">Approximate Distance(in KM)</label>
                            <div className="input-group input-group-merge">
                                <input type="number" id="approx_distance" name="approx_distance" className="form-control" placeholder="Approximate Distance" value={approxDistance} onChange={(e) => setApproxDistance(e.target.value)} ></input>
                            </div>
						</div>
					</div>
                    <div className="row">
                        <div className="col-sm-12 mt-3 text-center">
                            <h5 className="input_form_subheader">Part B Details</h5>
                        </div>
                        <div className="col-md-2">&nbsp;</div>
						<div className="col-sm-8 mb-3">
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="transporter_name" className="form-label">Mode:&nbsp;&nbsp;</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="form-check form-check-inline">
                                        <input id="rotrans_mode" name="trans_mode" className="form-check-input" type="radio" value="1"  required checked={trans_mode === '1'} onChange={(e) => {setTransMode(e.target.value); setModeTypeLabel('Transporter Doc.')}} />
                                        <label className="form-check-label" htmlFor="rotrans_mode"> Road </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="rtrans_mode" name="trans_mode" className="form-check-input" type="radio" value="2" required checked={trans_mode === '2'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('RR')}} />
                                        <label className="form-check-label" htmlFor="rtrans_mode"> Rail </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="atrans_mode" name="trans_mode" className="form-check-input" type="radio" value="3" required checked={trans_mode === '3'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('Airway Bill')}} />
                                        <label className="form-check-label" htmlFor="atrans_mode"> Air </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="strans_mode" name="trans_mode" className="form-check-input" type="radio" value="4" required checked={trans_mode === '4'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('Bill of lading')}} />
                                        <label className="form-check-label" htmlFor="strans_mode"> Ship </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="transporter_name" className="form-label">Vehicle Type:&nbsp;&nbsp;</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="form-check form-check-inline">
                                        <input id="regular" name="vehicle_type" className="form-check-input" type="radio" value="R"  required checked={vehicle_type === 'R'} onChange={(e) => getVehicleType(e.target.value)} />
                                        <label className="form-check-label" htmlFor="regular"> Regular </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="ODC" name="vehicle_type" className="form-check-input" type="radio" value="O" required checked={vehicle_type === 'O'} onChange={(e) => getVehicleType(e.target.value)} />
                                        <label className="form-check-label" htmlFor="ODC"> Over Dimensional Cargo </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="vehicle_no" className="form-label">Vehicle No.</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" id="vehicle_no" name="vehicle_no" placeholder="Vehicle No." value={vehicleNo} onChange={(e) => setVehicleNo(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="tr_doc_no" className="form-label">{modeTypeLabel} No.</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" id="tr_doc_no" name="tr_doc_no" placeholder="Transporter Doc. No." value={transporterDocNo} onChange={(e) => setTransporterDocNo(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="tr_doc_no" className="form-label">{modeTypeLabel} Date</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <DatePicker name="tr_doc_date" data-testid="tr_doc_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setTransporterDocDt} value={transporterDocDt} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="dispatch_date" className="form-label">Dispatch Date</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <DatePicker name="dispatch_date" data-testid="dispatch_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setDispatchDate} value={dispatchDate} />
                                    </div>
                                </div>
                            </div>
                            {gatepassCancelFieldDiv && <>
                                <div className="row mb-3 border">
                                    <div className="col-sm-6 pt-2">
                                        <label htmlFor="gtpcancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                                        <Select id="gtpcancel-reason" placeholder="Select Cancel Reason" name='gtpcancel_reason' options={Common.ewayBillCancelReason} onChange={(e) => setGatepassCanReason(e.value)}/>
                                    </div>
                                    <div className="col-sm-6 pt-2">
                                        <label htmlFor="gtpcancel_remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                                        <div className="input-group input-group-merge">
                                            <input type="text" className="form-control" id="gtpcancel_remarks" name="gtpcancel_remarks" placeholder="CancelRemarks" value={gatepassCanRemarks} onChange={(e) => setGatepassCanRemarks(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 text-center py-2">
                                        <button type='button' className="btn btn-sm btn-success" onClick={(e) => handleGatepassCancel()}><i className='bx bx-paper-plane'></i>&nbsp;Submit</button>
                                    </div>
                                </div>
                            </>}
						</div>
                        <div className="col-md-2">&nbsp;</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-3">
                            {(gatepassNo) ? <div><button type='button' onClick={(e) => handleDownloadEWayBill(saleId,ewbNo,gatepassNo,2)} className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Download Gatepass</button>&nbsp;&nbsp;<button type='button' className="btn btn-sm btn-danger" onClick={(e) => handleGatepassCancelField()}><i className='bx bx-message-square-x'></i>&nbsp;Cancel Gatepass</button></div> : <button type='submit' className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Generate Gatepass</button>}
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
        {/* Delivery challan modals  */}
        <Modal size='lg' show={genDCEwayShow} onHide={()=>setGenDCEwayShow(false)} aria-labelledby="Eway-Gatepass-modal" backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title id="Eway-DC-Gatepass-modal">Generate EWay Bill</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="addItmFrm" onSubmit={handleDCEwayBill} autoComplete="off">
                {/* <form id="addItmFrm" autoComplete="off"> */}
                    <div className="row">
                        {ewbErrorDetail && <>
                            <div className="col-sm-12">
                                <p><b>Errors reported by NIC. For More details <a href='https://docs.ewaybillgst.gov.in/apidocs/api-error-codes-list.html' target="_blank" rel="noreferrer">Click Here</a></b></p>
                                <table className="table table-sm table-bordered dispatch_eway_error">
                                    <thead>
                                        <tr>
                                            <th width="15%">Error Code</th>
                                            <th>Error Details</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                    {ewbErrorDetail.map((val,index) => (
                                        <tr>
                                            <td>{val.ErrorCode}</td>
                                            <td>{val.ErrorMessage}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </>}
                        {ewbCancelError && <>
                            <div className="col-sm-12">
                                <p><b>Errors reported by NIC. For More details <a href='https://docs.ewaybillgst.gov.in/apidocs/api-error-codes-list.html' target="_blank" rel="noreferrer">Click Here</a></b></p>
                                <table className="table table-sm table-bordered dispatch_eway_error w-50">
                                    <thead>
                                        <tr>
                                            <th>Error Code</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                    {ewbCancelError.map((val1,index1) => (
                                        <tr key={'error'+index1}>
                                            <td>{val1.ErrorCode}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </>}
                        <div className="col-sm-12 text-center">
                            <h5>Transportation Details</h5>
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="transporter_name" className="form-label">Transporter Name</label>
                            <div className="input-group input-group-merge">
                                <input type="text" className="form-control" id="transporter_name" name="transporter_name" placeholder="Transporter Name" value={transporterName} onChange={(e) => setTransporterName(e.target.value)}  disabled={ewbNo && transporterName?true:false} />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="opening_stock" className="form-label">Transporter ID</label>
                            <div className="input-group input-group-merge">
                                <input type="text" id="transporter_id" name="transporter_id" className="form-control" placeholder="Transporter ID" maxLength="15" value={transporterID} onChange={(e) => handleTransporterId(e.target.value)} disabled={ewbNo && transporterID?true:false}></input>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="approx_distance" className="form-label">Approximate Distance(in KM)</label>
                            <div className="input-group input-group-merge">
                                <input type="text" id="approx_distance" name="approx_distance" className="form-control" placeholder="Approximate Distance" value={approxDistance} onChange={(e) => setApproxDistance(Common.acceptOnlyDecimal(e.target.value))} disabled={ewbNo && approxDistance?true:false}></input>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 mt-3 text-center">
                            <h5 className="input_form_subheader">Part B Details</h5>
                        </div>
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-sm-8 mb-3">
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="transporter_name" className="form-label">Mode:&nbsp;&nbsp;</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="form-check form-check-inline">
                                        <input id="rotrans_mode" name="trans_mode" className="form-check-input" type="radio" value="1"  required checked={trans_mode === '1'} onChange={(e) => {setTransMode(e.target.value); setModeTypeLabel('Transporter Doc.')}} disabled={ewbNo?true:false}/>
                                        <label className="form-check-label" htmlFor="rotrans_mode"> Road </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="rtrans_mode" name="trans_mode" className="form-check-input" type="radio" value="2" required checked={trans_mode === '2'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('RR')}} disabled={ewbNo?true:false} />
                                        <label className="form-check-label" htmlFor="rtrans_mode"> Rail </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="atrans_mode" name="trans_mode" className="form-check-input" type="radio" value="3" required checked={trans_mode === '3'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('Airway Bill')}} disabled={ewbNo?true:false} />
                                        <label className="form-check-label" htmlFor="atrans_mode"> Air </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="strans_mode" name="trans_mode" className="form-check-input" type="radio" value="4" required checked={trans_mode === '4'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('Bill of lading')}} disabled={ewbNo?true:false}/>
                                        <label className="form-check-label" htmlFor="strans_mode"> Ship </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="transporter_name" className="form-label">Vehicle Type:&nbsp;&nbsp;</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="form-check form-check-inline">
                                        <input id="regular" name="vehicle_type" className="form-check-input" type="radio" value="R"  required checked={vehicle_type === 'R'} onChange={(e) => getVehicleType(e.target.value)} disabled={ewbNo?true:false} />
                                        <label className="form-check-label" htmlFor="regular"> Regular </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="ODC" name="vehicle_type" className="form-check-input" type="radio" value="O" required checked={vehicle_type === 'O'} onChange={(e) => getVehicleType(e.target.value)} disabled={ewbNo?true:false} />
                                        <label className="form-check-label" htmlFor="ODC"> Over Dimensional Cargo </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="vehicle_no" className="form-label">Vehicle No.</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" id="vehicle_no" name="vehicle_no" placeholder="Vehicle No." value={vehicleNo} onChange={(e) => setVehicleNo(e.target.value)}  disabled={ewbNo && vehicleNo?true:false}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="tr_doc_no" className="form-label">{modeTypeLabel} No.</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" id="tr_doc_no" name="tr_doc_no" placeholder="Transporter Doc. No." value={transporterDocNo} onChange={(e) => setTransporterDocNo(e.target.value)} disabled={ewbNo && transporterDocNo?true:false} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="tr_doc_no" className="form-label">{modeTypeLabel} Date</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <DatePicker name="tr_doc_date" data-testid="tr_doc_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setTransporterDocDt} value={transporterDocDt} disabled={ewbNo && transporterDocDt?true:false} />
                                    </div>
                                </div>
                            </div>
                            {cancelFieldDiv && <>
                                <div className="row mb-3 border">
                                    <div className="col-sm-6 pt-2">
                                        <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                                        <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} onChange={(e) => setEwbCancelReason(e.value)}/>
                                    </div>
                                    <div className="col-sm-6 pt-2">
                                        <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                                        <div className="input-group input-group-merge">
                                            <input type="text" className="form-control" id="cancel-remarks" name="cancel_remarks" placeholder="CancelRemarks" value={ewbCancelRemarks} onChange={(e) => setEwbCancelRemarks(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 text-center py-2">
                                        <button type='button' className="btn btn-sm btn-success" onClick={(e) => handleDCEwayBillCancel()}><i className='bx bx-paper-plane'></i>&nbsp;Submit</button>
                                    </div>
                                </div>
                            </>}
                        </div>
                        <div className="col-md-2">&nbsp;</div>
                    </div>
                    <div className="row ">
                        <div className="col-sm-12 text-center mt-3">
                            {(ewbNo) ? <div><button type='button' onClick={(e) => handleDownloadDCEWayBill(deliveryChallanId,ewbNo,gatepassNo,1)} className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Download EWay Bill</button>&nbsp;&nbsp;<button type='button' className="btn btn-sm btn-danger" onClick={(e) => handleCancelField()}><i className='bx bx-message-square-x'></i>&nbsp;Cancel EWay Bill</button></div> : <button type='submit' className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Generate EWay Bill</button>}
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
        <Modal size='lg' show={genDCGatepassShow} onHide={()=>setGenDCGatepassShow(false)} aria-labelledby="Eway-Gatepass-modal" backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title id="Eway-DC-Gatepass-modal">Generate Gatepass</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="addItmFrm" onSubmit={handleDCGatepass} autoComplete="off">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5>Transportation Details</h5>
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="transporter_name" className="form-label">Transporter Name</label>
                            <div className="input-group input-group-merge">
                                <input type="text" className="form-control" id="transporter_name" name="transporter_name" placeholder="Transporter Name" value={transporterName} onChange={(e) => setTransporterName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="opening_stock" className="form-label">Transporter ID</label>
                            <div className="input-group input-group-merge">
                                <input type="text" id="transporter_id" name="transporter_id" className="form-control" placeholder="Transporter ID" maxLength="15" value={transporterID} onChange={(e) => handleTransporterId(e.target.value)} ></input>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="approx_distance" className="form-label">Approximate Distance(in KM)</label>
                            <div className="input-group input-group-merge">
                                <input type="number" id="approx_distance" name="approx_distance" className="form-control" placeholder="Approximate Distance" value={approxDistance} onChange={(e) => setApproxDistance(e.target.value)} ></input>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 mt-3 text-center">
                            <h5 className="input_form_subheader">Part B Details</h5>
                        </div>
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-sm-8 mb-3">
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="transporter_name" className="form-label">Mode:&nbsp;&nbsp;</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="form-check form-check-inline">
                                        <input id="rotrans_mode" name="trans_mode" className="form-check-input" type="radio" value="1"  required checked={trans_mode === '1'} onChange={(e) => {setTransMode(e.target.value); setModeTypeLabel('Transporter Doc.')}} />
                                        <label className="form-check-label" htmlFor="rotrans_mode"> Road </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="rtrans_mode" name="trans_mode" className="form-check-input" type="radio" value="2" required checked={trans_mode === '2'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('RR')}} />
                                        <label className="form-check-label" htmlFor="rtrans_mode"> Rail </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="atrans_mode" name="trans_mode" className="form-check-input" type="radio" value="3" required checked={trans_mode === '3'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('Airway Bill')}} />
                                        <label className="form-check-label" htmlFor="atrans_mode"> Air </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="strans_mode" name="trans_mode" className="form-check-input" type="radio" value="4" required checked={trans_mode === '4'} onChange={(e) => {setTransMode(e.target.value);setModeTypeLabel('Bill of lading')}} />
                                        <label className="form-check-label" htmlFor="strans_mode"> Ship </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="transporter_name" className="form-label">Vehicle Type:&nbsp;&nbsp;</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="form-check form-check-inline">
                                        <input id="regular" name="vehicle_type" className="form-check-input" type="radio" value="R"  required checked={vehicle_type === 'R'} onChange={(e) => getVehicleType(e.target.value)} />
                                        <label className="form-check-label" htmlFor="regular"> Regular </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="ODC" name="vehicle_type" className="form-check-input" type="radio" value="O" required checked={vehicle_type === 'O'} onChange={(e) => getVehicleType(e.target.value)} />
                                        <label className="form-check-label" htmlFor="ODC"> Over Dimensional Cargo </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="vehicle_no" className="form-label">Vehicle No.</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" id="vehicle_no" name="vehicle_no" placeholder="Vehicle No." value={vehicleNo} onChange={(e) => setVehicleNo(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="tr_doc_no" className="form-label">{modeTypeLabel} No.</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" id="tr_doc_no" name="tr_doc_no" placeholder="Transporter Doc. No." value={transporterDocNo} onChange={(e) => setTransporterDocNo(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="tr_doc_no" className="form-label">{modeTypeLabel} Date</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <DatePicker name="tr_doc_date" data-testid="tr_doc_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setTransporterDocDt} value={transporterDocDt} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <label htmlFor="dispatch_date" className="form-label">Dispatch Date</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="input-group input-group-merge">
                                        <DatePicker name="dispatch_date" data-testid="dispatch_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setDispatchDate} value={dispatchDate} />
                                    </div>
                                </div>
                            </div>
                            {gatepassCancelFieldDiv && <>
                                <div className="row mb-3 border">
                                    <div className="col-sm-6 pt-2">
                                        <label htmlFor="gtpcancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                                        <Select id="gtpcancel-reason" placeholder="Select Cancel Reason" name='gtpcancel_reason' options={Common.ewayBillCancelReason} onChange={(e) => setGatepassCanReason(e.value)}/>
                                    </div>
                                    <div className="col-sm-6 pt-2">
                                        <label htmlFor="gtpcancel_remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                                        <div className="input-group input-group-merge">
                                            <input type="text" className="form-control" id="gtpcancel_remarks" name="gtpcancel_remarks" placeholder="CancelRemarks" value={gatepassCanRemarks} onChange={(e) => setGatepassCanRemarks(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 text-center py-2">
                                        <button type='button' className="btn btn-sm btn-success" onClick={(e) => handleGatepassCancel()}><i className='bx bx-paper-plane'></i>&nbsp;Submit</button>
                                    </div>
                                </div>
                            </>}
                        </div>
                        <div className="col-md-2">&nbsp;</div>
                    </div>
                    <div className="row ">
                        <div className="col-sm-12 text-center mt-3">
                            {(gatepassNo) ? <div><button type='button' onClick={(e) => handleDownloadDCEWayBill(deliveryChallanId,ewbNo,gatepassNo,2)} className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Download Gatepass</button>&nbsp;&nbsp;<button type='button' className="btn btn-sm btn-danger" onClick={(e) => handleGatepassCancelField()}><i className='bx bx-message-square-x'></i>&nbsp;Cancel Gatepass</button></div> : <button type='submit' className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Generate Gatepass</button>}
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal size='md' show={dCCancelShow} onHide={()=>setDCCancelShow(false)} aria-labelledby="Quotation-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Quotation-Cancel-modal">Cancel Delivery Challan ({invNo})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addEwbCancelFrm" onSubmit={handleCancelDeliveryChallan} autoComplete="off">
					<div className="row">
						<div className="col-sm-12">
                            <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                            <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} required/>
						</div>
                        <div className="col-sm-12">
                            <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                            <div className="input-group input-group-merge">
                                <textarea rows="2" id="cancel-remarks" name="cancel_remarks" className="form-control" placeholder="Cancel Remarks" aria-label="CancelRemarks" aria-describedby="Remarks" value={dcCancelRemarks} onChange={(e) => setDcCancelRemarks(e.target.value)} required></textarea>
                            </div>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-3">
							<button type='submit' className="btn btn-sm btn-danger"><i className='bx bx-message-square-x' ></i>&nbsp;Cancel</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
        
        <Modal size='md' show={dCAlert} onHide={()=>setDCAlert(false)} aria-labelledby="Quotation-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Quotation-Cancel-modal"><b>Cancel Delivery Challan</b> </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<b>This Delivery Challan has an Active EWay bill. Pl cancel the EWB before proceeding to cancel the Delivery Challan</b>
			</Modal.Body>
		</Modal>
    </>
    );
}