import React  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import TagManager from 'react-gtm-module';

export default function ProfileList(props){
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [filterText, setFilterText] = React.useState('');
	const [DTpending, setDTPending] = React.useState(true);
    const [profiles, setProfileList]    = React.useState([]);
	const [dt_load,setDtLoad] = React.useState(true);
	const navigate = Common.useNavigate();
	const [sortOrder, setSortOrder] = React.useState('');

	const [accountMgmt_w,setaccountMgmt_w] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [exportBtnClass,setExportBtnClass]    = React.useState('d-none');
	
    React.useEffect(() => {
        document.title = 'Profiles | '+props.site_name;
		const hostname = window.location.hostname;
		if( hostname === 'mart.fynone.com' ){
			const tagManagerArgs = {
				gtmId: 'G-RF6E5H5MTZ'
			}

			TagManager.initialize(tagManagerArgs);
		}
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( userPerm && Object.keys(userPerm).length > 0 ){
			setaccountMgmt_w((userPerm[1]['write'] !== undefined && userPerm[1]['write'] === '0') ? false : true);
		}
		loadDataTable(1,perPage,filterText,sortOrder);
	}, [props,dt_load,filterText,perPage]);
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const loadDataTable = (page,perPage,filterText,sort='') => {
		Common.axios({
            method: 'post',
            url:props.url+'user/profiles',
			data: {'page':page,'perPage':perPage,'sort':sort,'search':filterText}
        }).then(function (response) {
			setExportBtnClass('d-none');
            if(response.data.success){
				setProfileList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				if(response.data.total > 0){
					setExportBtnClass('');
				}
            }
        }).catch(error => console.log(error));
	}
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,sortOrder);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection));
	};
    const handleDefaultClick =(val) => {
        Common.axios({
            method: 'post',
            url: props.url+'profile/markDefault',
            data: {profileId:val},
        }).then(function (response) {
            if(response.data.success){
				setTimeout(()=>{setDtLoad( (dt_load) ? false : true)},500);
            }
        }).catch(error => console.log(error));
    }
	const userStatus = (checked,id) => {
		Common.axios({
			method: 'post',
			url: props.url+'user/profile_update',
			data: {status:checked,id:id}
		}).then(function (response) {
            if(response.data.success){
				Common.toast.success(response.data.message);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
    const editProfile =(val, role) => {
		if (role === 'Sub-User') {
			Common.toast.error('You do not have persmission to edit profile');
		} else {
			navigate('/edit-profile',
			{
				state: {
					pid: val
				}
			});
		}
    }
    const columns = [
        {
            name: 'Action',
            button: true,
			cell: (row) => ( accountMgmt_w === true ? <><div className="dropdown">
				<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="true">
					<i className="bx bx-dots-vertical-rounded"></i>
				</button>
				<div className="dropdown-menu datatable_menu " data-popper-placement="bottom-end" >
					{row.current_profile === '' && <>
					<button onClick={(e) => handleDefaultClick(row.id)}  className="dropdown-item"><i className="bx bx-lock-alt me-1"></i> Mark Default</button>
					</>}
					<button onClick={(e) => editProfile(row.id, row.role)}  className="dropdown-item"><i className="bx bx-edit me-1"></i> Edit</button>
				</div>
			  </div></> :<></>
			)
        },
		{
            name: 'Status',
            selector: row => row.status,
            sortable: false,
			sortField: 'status',
			cell: (row) => (
				localStorage.getItem('fo_profileid') !== row.id && row.current_profile === '' && row.role !== 'Sub-User' ?
				<BootstrapSwitchButton
					checked={(row.status === '1' )}
					onlabel='Active'
					offlabel='Inactive'
					onstyle="success"
					offstyle="danger"
					size="sm"
					width={100}
					onChange={(checked: boolean) => {
						userStatus(checked,row.id);
					}}
				/> : row.role !== 'Sub-User' ? <Common.OverlayTrigger overlay={Common.popoverContent('Why status cannot be changed ?','You cannot change the status of your default or current profile. Switch to another profile to do this activity.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>: <Common.OverlayTrigger overlay={Common.popoverContent('Why status cannot be changed ?','You do not have permission to change status.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
			)
        },
        {
            name: 'Name',
            sortable: true,
			sortField: 'name',
			cell: (row) => (
				(localStorage.getItem('fo_profileid') === row.id) ?
				<div className="currentProfile">{row.name + row.current_profile}</div>
				:
				row.name + row.current_profile
			)
        },
		{
            name: 'Address Type',
			selector: row => row.address_type,
            sortable: false,
			sortField: 'address_type'
        },
		{
            name: 'Master Profile',
			selector: row => row.master_profile
        },
        {
            name: 'Referred By',
			selector: row => row.referred_by,
            sortable: false,
			sortField: 'referred_by'
        },
        {
            name: 'Implemented By',
			selector: row => row.implemented_by,
            sortable: false,
			sortField: 'implemented_by'
        },
        {
            name: 'Managed By',
			selector: row => row.managed_by,
            sortable: false,
			sortField: 'managed_by'
        },
        {
            name: 'Created By',
			selector: row => row.created_by,
            sortable: false,
			sortField: 'created_by'
        },
        {
            name: 'Created On',
			selector: row => row.created_on,
            sortable: true,
			sortField: 'p.created_on'
        },
        {
            name: 'Updated By',
			selector: row => row.updated_by,
            sortable: false,
			sortField: 'updated_by'
        },
        {
            name: 'Updated on',
			selector: row => row.updated_on,
            sortable: true,
			sortField: 'p.updated_on'
        }
    ];
	const handleExportCSV = (e)=>{
		e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'profile/download',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText},
        }).then(function (response) {
			if(response.data.success){
				window.location.href = response.data.message;
				//window.open(response.data.message, '_blank')
				// New Code - start
				//const file = new File([response.data],fileName,{type: response.data.type});
				//Common.saveAs(file);
				// New Code - end
	
	
	
                // const url = window.URL.createObjectURL(new Blob([response.data],{type: response.data.type}));
				
				// const contentDisposition = response.headers['content-disposition'];
				// let fileName = 'unknown';
				// if (contentDisposition) {
					// const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
					// if (fileNameMatch.length === 2)
						// fileName = fileNameMatch[1];
				// }
				//Common.saveAs(url, fileName);
				
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', fileName);
                // document.body.appendChild(link);
                // link.click();
				// link.remove();
				// window.URL.revokeObjectURL(url);
			} else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
	}
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="profiles" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3">All Profiles
								<span className="dropdown">
                                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='text-danger bx bxl-youtube bx-md'></i>
                                    </button>
                                    <div className="dropdown-menu" >
                                        <a href="https://youtu.be/O6n46yrLwO4" className="dropdown-item" target="_blank" rel="noreferrer"><span>Create & Manage profile</span></a>
                                    </div>
                                </span>
							</h4>
							<div className="row">
								<div className="col-md-6 col-12 mb-1 text-center text-md-start">
									<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
									{/*{ accountMgmt_w && (<>*/}
										<a href="/add-profile" className="btn btn-sm btn-primary">
											<i className='bx bx-plus'></i> Profile
										</a>
									{/*</>)}*/}
								</div>
								<div className="col-md-6 col-12 text-center text-md-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Party Name, Mobile, Gstin, Pincode, State, District')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
                            <div className="row">
                                <div className="col-md-12">
                                    <DTE.DataTable className="rdt_AdjDrp" title={"Profiles ("+totalDataCnt+")"} columns={columns} data={profiles} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
    );
}