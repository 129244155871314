import React,{useState}  from "react";
import Layout from './Layout';
import PartnerLayout from './PartnerLayout';
import NavBar from './NavBar';
import PartnerNavBar from './PartnerNavBar';
import 'react-date-picker/dist/DatePicker.css';
import '../styles/add-document.css';
import * as Common from '../common/ImportUserEssentials';
//import Modal from 'react-bootstrap/Modal';

export default function WhatsNew(props) {
    const [whatsNew, setWhatsNew] = useState([]);
	//const [whatsNewModal,setWhatsNewModal] = React.useState(false);
    // const handleWhatsNewViewClose = () => setWhatsNewModal(false);
    React.useEffect(() => {
        document.title = 'Whats New | '+props.site_name;
        Common.axios({
            method: 'post',
            url:props.url+'user/whatsNew/',
            data: {
                uid: localStorage.getItem('fo_loggedin'),
                after_login: '0'
            }
        }).then(function (response) {
            if( response.data.success === 1){
                if (response.data.whatsnew.length > 0) {
					setWhatsNew(response.data.whatsnew);
					//setWhatsNewModal(true);
				} else {
					//setWhatsNewModal(false);
				}
                return false;
            }
            Common.toast.dismiss();
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }, [props]);

    return(
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    {props.referrer === 'fynone' ? 
                        <Layout site_logo={props.site_logo} label="whats-new" site={props.site_name}/>
                        :
                        <PartnerLayout label="whats-new" {...props}/>
                    }
                    <div className="layout-page">
                        {props.referrer === 'fynone' ? 
                            <NavBar site={props.site_name} url={props.url}/>
                            :
                            <PartnerNavBar {...props}/>
                        }
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"> Whats New</h4>
                                {whatsNew.map((data, index) => {
                                    return (
                                        <div className="row mb-4" key={'whatsnew-'+data.id} id={'whatsnew-'+data.id}>
                                            <div className="col-md-3">
                                                <div className="date-arrow">
                                                    <div className="date-block"><br/>
                                                        &nbsp;<div><b>{data.created_on.split(" ")[0].split("-")[2]}</b></div>
                                                        <div><b><small>
                                                            {data.created_on.split(" ")[0].split("-")[1] === '01' ? "Jan" : ""}
                                                            {data.created_on.split(" ")[0].split("-")[1] === '02' ? "Feb" : ""}
                                                            {data.created_on.split(" ")[0].split("-")[1] === '03' ? "March" : ""}
                                                            {data.created_on.split(" ")[0].split("-")[1] === '04' ? "April" : ""}
                                                            {data.created_on.split(" ")[0].split("-")[1] === '05' ? "May" : ""}
                                                            {data.created_on.split(" ")[0].split("-")[1] === '06' ? "June" : ""}
                                                            {data.created_on.split(" ")[0].split("-")[1] === '07' ? "July" : ""}
                                                            {data.created_on.split(" ")[0].split("-")[1] === '08' ? "Aug" : ""}
                                                            {data.created_on.split(" ")[0].split("-")[1] === '09' ? "Sept" : ""}
                                                            {data.created_on.split(" ")[0].split("-")[1] === '10' ? "Oct" : ""}
                                                            {data.created_on.split(" ")[0].split("-")[1] === '11' ? "Nov" : ""}
                                                            {data.created_on.split(" ")[0].split("-")[1] === '12' ? "Dec" : ""}
                                                        </small></b></div>
                                                        <div><b><small>{data.created_on.split(" ")[0].split("-")[0]}</small></b></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="card plate-card">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center"  style={{'flexDirection':'column'}}>
                                                            <p dangerouslySetInnerHTML={{ __html: (data.title) }} />
                                                            <p dangerouslySetInnerHTML={{ __html: (data.description) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>      
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}