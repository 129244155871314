import React  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import paymentModes from '../masters/PaymentModes.js';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;

export default function LoanListing(props){
	
	const [filterText, setFilterText] = React.useState('');
	const [DTpending, setDTPending] = React.useState(true);
	const [dt_load,setDtLoad] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [totalDataCnt,setTotalDataCount] = React.useState({'Loans Taken':'','Loans Given':''});
	const tabTypes = ['Loans Taken', 'Loans Given'];
	const [activeTab, setActiveTab] = React.useState(tabTypes[0]);
	const [loanList, setLoanList] = React.useState([]);
    const [exportBtnClass,setExportBtnClass] = React.useState('d-none');
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	const [loan_taken_date,setLoanTakenDate] 		  = React.useState('');
	const [loanPaymentDateShow, setLoanPaymentDateShow] = React.useState(false);
	const [loanSecheduleShow, setLoanSecheduleShow] = React.useState(false);
	const [loanSechedulData, setLoanSecheduleData] = React.useState([]);
	const [loanSummeryData, setLoanSummeryData] = React.useState([]);
	const [loan_taken_id,setLoanTakenID] 		  = React.useState('');
	const [loanInvestment_r,setLoanInvestment_r] = React.useState(true);
	const [loanInvestment_w,setLoanInvestment_w] = React.useState(true);
	const [bankList, setBankList] 				 = React.useState([]);
	const [bankAccount,setBankAccount] 			 = React.useState(0);
	const [defaultPayee, setDefaultPayee] 		 = React.useState([]);
	const [showLoansTakenDiv,setShowLoansTakenDiv] = React.useState(true);
    const [showLoansGivenDiv,setShowLoansGivenDiv] = React.useState(false);
	
	// Loan Given
	const [loan_given_id,setLoanGivenID] 			= React.useState('');
	const [loan_given_date,setLoanGivenDate] 		= React.useState('');
	const [loanGivenDateShow, setLoanGivenDateShow] = React.useState(false);
	const [payment_mode,setPaymentMode]             = React.useState('UPI/QR');
	// const [bank_cash_account,setBankCashAccount]    = React.useState(1);

	const [sortOrder,setSortOrder]                  = React.useState('');

	const navigate = Common.useNavigate();
	
	
	let state  = Common.useLocation();

    React.useEffect(() => {
        document.title = 'Loan Listing | '+props.site_name;
		if (localStorage.getItem('fo_subplan') === 'Basic') {
			navigate("/pricing-plan");
		}
		let passedTab = ''
		if (state.state !== null) {
			setActiveTab(tabTypes[state.state.tab_type])
			if (state.state.tab_type === 1) {
				passedTab = tabTypes[1]
				setShowLoansGivenDiv(true);
				setShowLoansTakenDiv(false);
			} else {
				passedTab = tabTypes[0]
				setShowLoansGivenDiv(false);
				setShowLoansTakenDiv(true);
			}
		}
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Read permission
			setLoanInvestment_r((userPerm[8]['view'] !== undefined && userPerm[8]['view'] === '0') ? false : true);
		}
		if( Object.keys(userPerm).length > 0 ){
			setLoanInvestment_w((userPerm[8]['write'] !== undefined && userPerm[8]['write'] === '0') ? false : true);
		}
		if (state.state !== null) {
			loadDataTable(1,perPage,filterText,'',passedTab);
		} else {
			loadDataTable(1,perPage,filterText,'',activeTab);
		}

		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				setDefaultPayee(response.data.defaultPayee);
				if (response.data.defaultPayee.length > 0) {
					setBankAccount(response.data.defaultPayee[0].value);
				}
			}
		}).catch(error => console.log(error));

	}, [props,dt_load,filterText,state]);
	if( !loanInvestment_r ){
		navigate("/not-authorized");
	}
	const loadDataTable = (page,perPage,filterText,sort='',active_tab=activeTab) => {
		// Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'loans/listing/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText,'active_tab':active_tab}
        }).then(function (response) {
			setExportBtnClass('d-none');
			// Common.showLoader(false);
            if(response.data.success){
				setLoanList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(prevState => ({
				...prevState,
					[active_tab]: response.data.total
				}));
				if(response.data.data.length > 0){
					setExportBtnClass('d-inline-block');
				}
				setDTPending(false);
            }
        }).catch(error => console.log(error));
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,sortOrder,activeTab);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText,sortOrder,activeTab);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection),activeTab);
	};
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
    const columns = [
    	{
            name: 'Action',
            button: true,
			cell: (row) => ( loanInvestment_w === true ? <>
				<div className="dropdown">
					<button type="button" className="btn me-2 p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
					<i className="bx bx-dots-vertical-rounded"></i>
					</button>
					<div className="dropdown-menu" >
						<button className="dropdown-item" onClick={(e) => handleDownloadView(row.id,2,0)}><i className='bx bxs-copy'></i> View Schedule</button>
						<button className="dropdown-item" onClick={(e) => handleDownloadView(row.id,1,0)}><i className="bx bxs-download"></i> Download Schedule</button>
						<button className="dropdown-item" onClick={(e) => handleCloseLoan(e,row.id,0,row.due)}><i className='bx bx-message-square-x'></i> Close Loan</button>
					</div>
					<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
					<i className="bx bx-share-alt"></i>
					</button>
					<div className="dropdown-menu" >
						<button className="dropdown-item" onClick={(e) => handleShareContent(row.id,1,0)}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
						<button className="dropdown-item" onClick={(e) => handleShareContent(row.id,2,0)}><i className="bx bx-envelope"></i> Share via Email </button>
					</div>
              	</div>
			  </> :<></>
			)
        },
		{
            name: 'Status',
            selector: row => row.status,
            sortable: false,
			sortField: 'status',
			cell: (row) => (
				( row.status === 'Active' ) ? <Button variant="success" size="sm" >{row.status}</Button> : ( row.status === 'In Progress' ) ? <Button variant="warning" size="sm" >{row.status}</Button>:<Button variant="danger" size="sm" >{row.status}</Button>
			)
        },
        {
            name: 'Provider Name',
            sortable: true,
			sortField: 'loan_provider',
			cell: (row) => (
				row.loan_provider
			)
        },
        {
            name: 'Loan amount',
            selector: row => row.loan_amt,
            sortable: true,
			sortField: 'loan_amt',
			cell: (row) => (
				row.loan_amt
			)
        },
		{
            name: 'Paid Amount',
            selector: row => row.payment,
            sortable: true,
			sortField: 'payment',
			cell: (row) => (
				row.payment
			)
        },
		{
            name: 'Due Amount',
            selector: row => row.due,
            sortable: true,
			sortField: 'due',
			cell: (row) => (
				row.due
			)
        },
		{
            name: 'Total Amount',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt',
			cell: (row) => (
				row.total_amt
			)
        },
		{
            name: 'Date of Loan',
            selector: row => row.loan_taken_date,
            sortable: true,
			sortField: 'loan_taken_date',
			cell: (row) => (
				( row.loan_taken_date !== '00-00-0000' ) ? row.loan_taken_date : <button style={{"display":"contents"}} className="btn btn-link" onClick={(e)=>getLoanTakenDate(row.id)}>{row.loan_taken_date} <i className='bx bx-pencil'></i></button>
			)
        },
		{
            name: 'Loan Period',
            selector: row => row.loan_period,
            sortable: true,
			sortField: 'loan_period',
			cell: (row) => (
				row.loan_period
			)
        },
		{
            name: 'Moratorium period',
            selector: row => row.moratorium_period,
            sortable: true,
			sortField: 'moratorium_period',
			cell: (row) => (
				row.moratorium_period
			)
        },
		{
            name: 'Total Loan Period',
            selector: row => row.total_loan_period,
            sortable: true,
			sortField: 'total_loan_period',
			cell: (row) => (
				row.total_loan_period
			)
        },
		{
            name: 'Repayment frequency',
            selector: row => row.repayment_frequency,
            sortable: true,
			sortField: 'repayment_frequency',
			cell: (row) => (
				row.repayment_frequency
			)
        },
		{
            name: 'Due date',
            selector: row => row.repayment_due_date,
            sortable: true,
			sortField: 'repayment_due_date',
			cell: (row) => (
				row.repayment_due_date
			)
        },
		{
            name: 'Rate of interest',
            selector: row => row.rate_of_interest,
            sortable: true,
			sortField: 'rate_of_interest',
			cell: (row) => (
				row.rate_of_interest
			)
        },
		
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
			sortField: 'created_by',
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on',
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
			sortField: 'updated_by',
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on',
        },

    ];
	const loanGiven = [
    	{
            name: 'Action',
            button: true,
			cell: (row) => ( loanInvestment_w === true ? <>
				<div className="dropdown">
					<button type="button" className="btn me-2 p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
					<i className="bx bx-dots-vertical-rounded"></i>
					</button>
					<div className="dropdown-menu" >
						<button className="dropdown-item" onClick={(e) => handleDownloadView(row.id,2,1)}><i className='bx bxs-copy'></i> View Schedule</button>
						<button className="dropdown-item" onClick={(e) => handleDownloadView(row.id,1,1)}><i className="bx bxs-download"></i> Download Schedule</button>
						<button className="dropdown-item" onClick={(e) => handleCloseLoan(e,row.id,1,row.due)}><i className='bx bx-message-square-x'></i> Close Loan</button>
					</div>
					<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
					<i className="bx bx-share-alt"></i>
					</button>
					<div className="dropdown-menu" >
						<button className="dropdown-item" onClick={(e) => handleShareContent(row.id,1,1)}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
						<button className="dropdown-item" onClick={(e) => handleShareContent(row.id,2,1)}><i className="bx bx-envelope"></i> Share via Email </button>
					</div>
              	</div>
			  </> :<></>
			)
        },
		{
            name: 'Status',
            selector: row => row.status,
            sortable: false,
			sortField: 'status',
			cell: (row) => (
				( row.status === 'Active' ) ? <Button variant="success" size="sm" >{row.status}</Button> : ( row.status === 'In Progress' ) ? <Button variant="warning" size="sm" >{row.status}</Button>:<Button variant="danger" size="sm" >{row.status}</Button>
			)
        },
        {
            name: 'Recipient Name',
            sortable: true,
			sortField: 'loan_recipient',
			cell: (row) => (
				row.loan_recipient
			)
        },
        {
            name: 'Loan amount',
            selector: row => row.loan_amt,
            sortable: true,
			sortField: 'loan_amt',
			cell: (row) => (
				row.loan_amt
			)
        },
		{
            name: 'Paid Amount',
            selector: row => row.payment,
            sortable: true,
			sortField: 'payment',
			cell: (row) => (
				row.payment
			)
        },
		{
            name: 'Due Amount',
            selector: row => row.due,
            sortable: true,
			sortField: 'due',
			cell: (row) => (
				row.due
			)
        },
		{
            name: 'Total Amount',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt',
			cell: (row) => (
				row.total_amt
			)
        },
		{
            name: 'Date of Loan',
            selector: row => row.loan_given_date,
            sortable: true,
			sortField: 'loan_given_date',
			cell: (row) => (
				( row.loan_given_date !== '00-00-0000' ) ? row.loan_given_date : <button style={{"display":"contents"}} className="btn btn-link" onClick={(e)=>getLoanGivenDate(row.id)}>{row.loan_given_date} <i className='bx bx-pencil'></i></button>
			)
        },
		{
            name: 'Loan Period',
            selector: row => row.loan_period,
            sortable: true,
			sortField: 'loan_period',
			cell: (row) => (
				row.loan_period
			)
        },
		{
            name: 'Moratorium period',
            selector: row => row.moratorium_period,
            sortable: true,
			sortField: 'moratorium_period',
			cell: (row) => (
				row.moratorium_period
			)
        },
		{
            name: 'Total Loan Period',
            selector: row => row.total_loan_period,
            sortable: true,
			sortField: 'total_loan_period',
			cell: (row) => (
				row.total_loan_period
			)
        },
		{
            name: 'Repayment frequency',
            selector: row => row.repayment_frequency,
            sortable: true,
			sortField: 'repayment_frequency',
			cell: (row) => (
				row.repayment_frequency
			)
        },
		{
            name: 'Due date',
            selector: row => row.repayment_due_date,
            sortable: true,
			sortField: 'repayment_due_date',
			cell: (row) => (
				row.repayment_due_date
			)
        },
		{
            name: 'Rate of interest',
            selector: row => row.rate_of_interest,
            sortable: true,
			sortField: 'rate_of_interest',
			cell: (row) => (
				row.rate_of_interest
			)
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
			sortField: 'created_by',
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on',
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
			sortField: 'updated_by',
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on',
        },

    ];
	
	const getLoanTakenDate = (val) => {
		setLoanTakenID(val);
		setLoanPaymentDateShow(true);
	}
	const handleLoanTakenDateShow =() => {
		if(loan_taken_date === ''){
			Common.toast.error('Loan Taken Date is required.');
			return false;
		}
		if( loan_taken_date !== '' && payment_mode === '' ){
			Common.showLoader(false);
			Common.toast.error('Payment Mode is required');
			return false;
		}
		if(loan_taken_date !== '' && bankAccount === 0){
			Common.toast.error('Bank Account is required.');
			return false;
		}
        setLoanPaymentDateShow(false);
		Common.axios({
			method: 'post',
			url: props.url+'loans/update',
			data: {loan_taken_date:loan_taken_date,loan_id:loan_taken_id,bankAccount:bankAccount,payment_mode:payment_mode}
		}).then(function (response) {
			if(response.data.success){
				setLoanPaymentDateShow(false);
				setLoanTakenDate('');
				for (var i = 0; i < bankList.length; i++) {
					if (bankList[i].default === '1') {
						setDefaultPayee([bankList[i]]);
						break;
					}
				}
				setDefaultPayee([])
				setListOfBanks();
				loadDataTable(1,perPage,filterText,sortOrder,activeTab);
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const setPaymentModeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		console.log(value);
		setPaymentMode(value);
		if(value === 'Cash'){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/'+value,
			}).then(function (response) {
				if(response.data.success){
					if(response.data.data !== ''){
						// setBankCashAccount(1);
						setDefaultPayee(response.data.data);
						setBankAccount(response.data.data[0].value);
						setBankList(response.data.data);
					}else{
						// setBankCashAccount(0);
						setDefaultPayee([]);
						setBankAccount(0);
						setBankList([]);
					}
				}
			}).catch(error => console.log(error));
		}else{
			// setBankCashAccount(1);
			setDefaultPayee([]);
			setBankAccount(0);
			setListOfBanks();
		}
	}
	const setListOfBanks = () => {
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				// setDefaultPayee(response.data.defaultPayee);
				// if (response.data.defaultPayee.length > 0) {
				// 	setBankAccount(response.data.defaultPayee[0].value);
				// }
			}
		}).catch(error => console.log(error));
	}
	const getLoanGivenDate = (val) => {
		setLoanGivenID(val);
		setLoanGivenDateShow(true);
	}
	const handleLoanGivenDateShow =() => {
		if(loan_given_date === ''){
			Common.toast.error('Loan Given Date is required.');
			return false;
		}
		if(loan_given_date !== '' && payment_mode === ''){
			Common.toast.error('Payment Mode is required.');
			return false;
		}
		if(loan_given_date !== '' && bankAccount === 0){
			Common.toast.error('Bank Account is required.');
			return false;
		}
		Common.axios({
			method: 'post',
			url: props.url+'loans/update-loan-given',
			data: {loan_given_date:loan_given_date,loan_id:loan_given_id,bankAccount:bankAccount,payment_mode:payment_mode}
		}).then(function (response) {
			if(response.data.success){
				setLoanGivenDate('')
				for (var i = 0; i < bankList.length; i++) {
					if (bankList[i].default === '1') {
						setDefaultPayee([bankList[i]]);
						  break;
					}
				}
				setDefaultPayee([])
				setListOfBanks();
				setLoanGivenDateShow(false);
				loadDataTable(1,perPage,filterText,sortOrder,activeTab);
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const handleCloseLoan = (e, val, type, dueAmt) => {
        e.preventDefault();
		if(dueAmt === '0.00'){
			Common.toast.error('Loan cannot be closed if any EMIs are pending.');
			return false;
		}
        confirmAlert({
            title: 'Close Loan',
            message: 'Are you sure you want to Close this Loan?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    Common.showLoader(true);
					let url = props.url+'loans/update';
					if(type === 1){
						url = props.url+'loans/update-loan-given';
					}
                    Common.axios({
                        method: 'post',
						url: url,
						data: {status:'3',loan_id:val}
                    }).then(function (response) {
                        Common.showLoader(false);
                        if(response.data.success){
                            Common.toast.dismiss();
                            if(response.data.success === 1){
                                loadDataTable(1,perPage,filterText,sortOrder,activeTab);
                                Common.toast.success(response.data.message);
                                return false;
                            }else{
                                Common.toast.error(response.data.message);
                            }
                            return false;
                        }
                        const err_html = () => (
                            Common.parse(response.data.message)
                        );
                        Common.toast.error(err_html);
                        Common.showLoader(false);
                    }).catch(error => console.log(error));
                }
              },
              {
                label: 'No',
                onClick: () => {console.log('no')}
              }
            ]
          });
    }
	const handleShareContent =(val,status,type) => {
		let url = props.url+'loans/getShareContent/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status))+'/'+encodeURIComponent(btoa(type));
		Common.axios({
			method: 'get',
			url:url,
		}).then(function (response) {
			if(response.data.success){
				if(parseInt(status) === 1){
					window.open("https://api.whatsapp.com/send?text="+response.data.data.body,"_blank");
				}else{
					window.open("mailto:?subject="+response.data.data.subject+"&body="+response.data.data.body,"_blank");
				}
			}
		}).catch(error => console.log(error));
	}
	const handleExportCSV = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'loans/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText,'active_tab':activeTab},
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
			}
        }).catch(error => console.log(error));
    }
	const handleDownloadView =(val,status,type) => {
		let url = props.url+'loans/getLoanScheduleData/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status))+'/'+encodeURIComponent(btoa(type));
		Common.axios({
			method: 'get',
			url:url,
		}).then(function (response) {
			if(response.data.success){
				if(parseInt(status) === 1){
					window.location.href = response.data.message;
				}else{
					setLoanSecheduleData(response.data.data);
					setLoanSummeryData(response.data.loanSummery);
					setLoanSecheduleShow(true);
				}
			}
		}).catch(error => console.log(error));
	}
	// const [showLoansTakenDiv,setShowLoansTakenDiv] = React.useState(true);
    // const [showLoansGivenDiv,setShowLoansGivenDiv] = React.useState(false);
	function TabGroup() {
		const handleTab = bankChecked => {
			setExportBtnClass('d-none');
			setActiveTab(bankChecked);
			state.state = null
			setSortOrder('')
			loadDataTable(1,perPage,filterText,'',bankChecked,'');
			if(bankChecked === "Loans Taken"){
				setShowLoansTakenDiv(true);
				setShowLoansGivenDiv(false);
			}
			else{
				setShowLoansGivenDiv(true);
				setShowLoansTakenDiv(false);
			}
		};
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{tabTypes.map(type => (
				<Tab
					key={type}
					active={activeTab === type}
					onClick={() => handleTab(type)}
				>
					{(totalDataCnt[type] !== '') ? type + ' ('+totalDataCnt[type]+')' : type}
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
	const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : 0;
		setBankAccount(value);
		// setDefaultPayee('');
		if (value === 0) {
			setDefaultPayee([]);
		} else {
			bankList.forEach((bank,i) => {
				if (bank.value === value) {
					setDefaultPayee([bank]);
				}
			});
		}
	}
    return (
		<>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="loan-listing" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Loans & Investments /</span> Loans</h4>
							<div className="row ">
								<div className="col-sm-12">
									<div className="row pb-3">
										<div className="col-md-8">
											<TabGroup/>
										</div>
									</div>
								</div>
								<div id="sdt_cont" className="col-sm-12">
									{showLoansTakenDiv && (<>
										{ loanInvestment_w && (<>
											<div className="row">
												<div className="col-4 listing-tabs mb-1">
													<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
														<i className="bx bx-download"></i><span className="hidden-phone">Export CSV</span>
													</button>
													<Common.Link to="/add-loan" className="btn btn-primary btn-sm">
														<i className="bx bx-plus"></i><span className="hidden-phone">Add Loan</span>
													</Common.Link>
												</div>
												<div className="col-8 text-end mb-1">
													<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Provider Name')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
													<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
												</div>
											</div>
										
										</>)}
										<DTE.DataTable className="dataTables_wrapper rdt_AdjDrp" columns={columns} data={loanList} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</>)}
									{showLoansGivenDiv && (<>
										{ loanInvestment_w && (<>
											<div className="row">
												<div className="col-md-3 col-12 listing-tabs mb-1">
													<div className="btn-group btn-group-sm" role="group">
														<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
															<span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
															<i className="bx bx-download d-block d-sm-none"></i>
														</button>
														<Common.Link to="/add-loan-given" className="btn btn-primary btn-sm">
															<i className="bx bx-plus"></i>Add Loan
														</Common.Link>
													</div>
												</div>
												<div className="col-md-5 mb-1"></div>
												<div className="col-md-4 col-12 text-center text-md-end mb-1">
													<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Receipent Name')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
													<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
												</div>
											</div>
										
										</>)}
										<DTE.DataTable className="dataTables_wrapper rdt_AdjDrp" columns={loanGiven} data={loanList} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}  persistTableHead/>
									</>)}
								</div>
							</div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal show={loanPaymentDateShow} size='md' onHide={()=>setLoanPaymentDateShow(false)} aria-labelledby="Loan-Payment-Date-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Loan Taken Date</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row mb-3">
					<div className="col-sm-4">
						<label htmlFor="frmLoanTakenDate" className="form-label">Date of Loan Taken:&nbsp;&nbsp;</label>
					</div>
					<div className="col-sm-8">
						<DatePicker name="loan_taken_date" data-testid="frmLoanTakenDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setLoanTakenDate} value={loan_taken_date} />
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-sm-4">
						<label htmlFor="frmBankAccount" className="form-label">Payment Mode:&nbsp;&nbsp;</label>
					</div>
					<div className="col-sm-8">
						<Typeahead clearButton defaultSelected={paymentModes.slice(1, 2)} id="frmSalePayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
							options={paymentModes}
							renderMenuItemChildren={(option: Item) => (
								<div>
									{option.label}
								</div>
							)}
						/>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-sm-4">
						<label htmlFor="frmBankAccount" className="form-label">Bank Account:&nbsp;&nbsp;</label>
					</div>
					<div className="col-sm-8">
						<Typeahead selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
							options={bankList}
							renderMenuItemChildren={(option: Item,props) => (
								<Highlighter search={props.text}>
									{option.label}
								</Highlighter>
							)}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={()=>handleLoanTakenDateShow()}>
				Submit
			</Button>
			</Modal.Footer>
		</Modal>
		<Modal show={loanGivenDateShow} size='md' onHide={()=>setLoanGivenDateShow(false)} aria-labelledby="Loan-Payment-Date-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Loan Given Date</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row mb-3">
					<div className="col-sm-4">
						<label htmlFor="frmLoanGivenDate" className="form-label">Date of Loan Given:&nbsp;&nbsp;</label>
					</div>
					<div className="col-sm-8">
						<DatePicker name="loan_given_date" data-testid="frmLoanGivenDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setLoanGivenDate} value={loan_given_date} />
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-sm-4">
						<label htmlFor="frmBankAccount" className="form-label">Payment Mode:&nbsp;&nbsp;</label>
					</div>
					<div className="col-sm-8">
						<Typeahead clearButton defaultSelected={paymentModes.slice(1, 2)} id="frmSalePayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
							options={paymentModes}
							renderMenuItemChildren={(option: Item) => (
								<div>
									{option.label}
								</div>
							)}
						/>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-sm-4">
						<label htmlFor="frmBankAccount" className="form-label">Bank Account:&nbsp;&nbsp;</label>
					</div>
					<div className="col-sm-8">
						<Typeahead selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
							options={bankList}
							renderMenuItemChildren={(option: Item,props) => (
								<Highlighter search={props.text}>
									{option.label}
								</Highlighter>
							)}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={()=>handleLoanGivenDateShow()}>
				Submit
			</Button>
			</Modal.Footer>
		</Modal>
		<Modal show={loanSecheduleShow} size='lg' onHide={()=>setLoanSecheduleShow(false)} aria-labelledby="Loan-Sechedule-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Loan Sechedule</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row mb-3">
					<div className="col-sm-12">
						<div className="table-responsive text-nowrap">
							<table className="table table-sm table-bordered">
								<thead className="table-light">
									<tr>
										<th>Sl.</th>
										<th align="right">Principal O/s</th>
										<th align="right">Towards Interest</th>
										<th align="right">Towards Principal</th>
										<th align="right">EMI</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody className="table-border-bottom-0">
									{loanSechedulData.map((val,index) => (
										<tr key={'pay'+index}>
											<td>{index+1}</td>
											<td align="right">{Common.displayPrice(val.principal_amt)}</td>
											<td align="right">{Common.displayPrice(val.towards_intrest)}</td>
											<td align="right">{Common.displayPrice(val.towards_principal)}</td>
											<td align="right">{Common.displayPrice(val.emi_amt)}</td>
											<td>{(val.status === '1'?'Paid':'Not Paid')}</td>
										</tr>
									))}
									<tr key='pay_total'>
										<td></td>
										<td></td>
										<td align="right">{Common.displayPrice(loanSummeryData.towards_intrest)}</td>
										<td align="right">{Common.displayPrice(loanSummeryData.towards_principal)}</td>
										<td align="right">{Common.displayPrice(loanSummeryData.emi_amt)}</td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="danger" onClick={()=>setLoanSecheduleShow(false)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
		</>
    );
}