import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import Select from "react-select";
import * as Common from '../common/ImportUserEssentials';
import ImageUploading from 'react-images-uploading';

export default function Profile(props){
    const [account_name, setAccountName] = useState('');
    const [legal_name, setLegalName] 	 = useState('');
    const [trade_name, setTradeName] 	 = useState('');
    const [stateid, setState]          	 = useState('');
    const [district, setDistrict]        = useState('');
    const [pin, setPin]                  = useState('');
    const [address, setAddress]          = useState('');
    const [gstin, setGstin]              = useState('');
    const [address_type, setAddressType] = useState('');
    const [type, setType]                = useState('');
    const [state_master, setStateMaster] = useState([]);
    const [logo_name, setLogo] 			 = useState('');
	const [images, setImages] 			 = useState('');
	const [editedAccName, setEditedAccName] = useState('');
	const navigate = Common.useNavigate();
	const { state } = Common.useLocation();
	useEffect(() => {
		document.title = 'Edit Profile | '+props.site_name;
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_state_master',
		}).then(function (response) {
			if( response.data.success ){
				setStateMaster(response.data.data);
			}
		}).catch(error => console.log(error));
        Common.axios({
            method: 'post',
            url: props.url+'user/profileDetails',
			data: {pid:Common.AES.encrypt(state.pid, props.site_key).toString()}
        }).then(function (response) {
            if(response.data.success){
                setAccountName(response.data.data.name);
                setEditedAccName(response.data.data.name);
                setLegalName(response.data.data.legal_name);
                setTradeName(response.data.data.trade_name);
                setState(response.data.data.state); setDistrict(response.data.data.district);
                setPin(response.data.data.pincode); setAddress(response.data.data.addr);
				// setUpi(response.data.data.upi);
                setGstin(response.data.data.gstin); setAddressType(response.data.data.address_type);
                setType(response.data.data.type);
                setLogo(response.data.data.logo);
				return false;
            }
			Common.toast.error(response.data.message);
			setTimeout(() => {
				navigate("/profiles");
			}, 2000);
        }).catch(error => console.log(error));
	}, [props,state.pid]);

	const onChange = (imageList) => {
		setImages(imageList);
	};
	const handleGstinData =(e) => {
		let value = e.target.value
		if (value.length === 15) {
			Common.showLoader(true);
			let url = props.url+'ajax/fetch_gstin_details';
			Common.axios({
				method: 'post',
				url:url,
				data: {gstin:value},
			}).then(function (response) {
				Common.showLoader(false);
				if(response.data.status){
					if(response.data.data.status_cd === "0"){
						Common.toast.error(response.data.data.error.message);
						return false;
					}
					
					setLegalName(response.data.data.lgnm);
					if( response.data.data.tradeNam !== undefined ){
						setTradeName(response.data.data.tradeNam);
					}
					if( response.data.data.pradr !== undefined ){
						if( response.data.data.pradr.addr.pncd !== undefined ){
							setPin(response.data.data.pradr.addr.pncd);
							populatePinData(response.data.data.pradr.addr.pncd);
						}
						if( response.data.data.pradr.addr.loc !== undefined ){
							setAddress(response.data.data.pradr.addr.loc);
						}
					}
					return false;
				}
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
	}
    const populatePinData =(pincode) => {
		setPin(pincode);
		if( pincode.length === 6 ){
			Common.axios({
				method: 'post',
				url: props.url+'ajax/getDataByPin',
				data: {pincode:pincode},
			}).then(function (response) {
				if(response.data.success){
					setState(response.data.stateid); 
					setDistrict(response.data.district);
					return false;
				}
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
    }
    const profileUpdate =(e) => {
        e.preventDefault();
        let uid = localStorage.getItem('fo_loggedin');
        Common.axios({
			method: 'post',
			url:props.url+'user/profile_update',
			data: {account_name:account_name,legal_name:legal_name,pin:pin,state:stateid,district:district,address:address,type:type,gstin:gstin,address_type:address_type,id:state.pid,logo:images,logo_name:logo_name,uid:uid,edited_acc_name:editedAccName},
        }).then(function (response) {
			if(response.data.success){
				Common.toast.success(response.data.message);
				setTimeout(() => {
					navigate("/profiles");
				}, 2000);
			} else{
				Common.toast.error(response.data.message);
			}
		}).catch(error => console.log(error));
    }
	let imageUrl = "../assets/img/default-user-img.jpg";
	if(logo_name){
		imageUrl = props.url+"public/assets/img/profile_logo/"+logo_name;
	}
	const removeLogo = () => {
		setImages('');
		setLogo('');
	};
    return (
        <div className="layout-wrapper layout-menu-fixed layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="profiles" site={props.site_name}/>
                    <div className="layout-page">
						<NavBar site={props.site_name} url={props.url}/>
                            <div className="content-wrapper">
                                <div className="container-xxl flex-grow-1 container-p-y">
                                    <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Account Management / <Common.Link className="text-secondary" to="/profiles">Profiles</Common.Link> /</span> Edit Profile</h4>
                                        <div className="row">
                                            <div className="col-md-12">
                                            <div className="card mb-4">
                                                <h5 className="card-header">Profile Details</h5>
                                                <div className="card-body mt-3">
                                                <form id="editProfileFrm" autoComplete="off" onSubmit={profileUpdate}>
                                                    <div className="row">
														<div className="col-md-12 mb-3">
															<div className="button-wrapper">
																<div className="App">
																	<ImageUploading										
																		value={images}
																		onChange={onChange}
																		dataURLKey="data_url"
																		acceptType={["jpg","png"]}
																		maxFileSize="51200"
																	>
																		{({
																		imageList,
																		onImageUpload,
																		onImageRemove,
																		errors,
																		}) => (
																		// write your building UI
																		<div className="upload__image-wrapper">
																			<div className="row">
																				<div className="col-sm-3">
																					<div key={0} className="image-item">
																						<img
																							//src={image['data_url']}
																							src = {imageList.length > 0 ? imageList[0]['data_url'] : imageUrl}
																							alt="user-avatar"
																							className="d-block rounded"
																							height="100"
																							width="100"
																							id="uploadedAvatar"
																						/>
																					</div>
																				</div>
																				<div className="col-sm-6">
																					<div className="button-wrapper mt-4">
																						<label htmlFor="upload" className="btn btn-sm btn-primary me-2 mb-2" tabIndex="0" onClick={onImageUpload}>
																							<span className="d-none d-sm-block">Upload Logo</span>
																							<i className="bx bx-upload d-block d-sm-none"></i>
																						</label>
																						{logo_name ? (
																						<button type="button" className="btn btn-sm btn-outline-secondary account-image-reset mb-2" onClick={() => removeLogo()}>
																							<i className="bx bx-reset d-block d-sm-none"></i>
																							<span className="d-none d-sm-block">Reset</span>
																						</button>
																						) : (
																							<button type="button" className="btn btn-sm btn-outline-secondary account-image-reset mb-2" onClick={() => onImageRemove(0)}>
																							<i className="bx bx-reset d-block d-sm-none"></i>
																							<span className="d-none d-sm-block">Reset</span>
																						</button>
																						)}
																						<p className="text-muted mb-0 smaller_font">Allowed JPG or PNG. Max size of 50KB</p>
																					</div>
																				</div>
																				{errors && (
																					<div className="col-sm-12">
																						{errors.acceptType && (
																						<span className="text-danger smaller_font">* Your selected file type is not allow</span>
																						)}
																						{errors.maxFileSize && (
																						<span className="text-danger smaller_font">* Selected file size exceed maxFileSize</span>
																						)}
																					</div>
																				)}
																			</div>
																		</div>
																		)}
																	</ImageUploading>
																</div>
															</div>
															<hr className="my-0"></hr>
														</div>
														<div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="edaccName">Account Name<span className="text-danger">*</span></label>
															<input type="text" className="form-control" id="edaccName" name="account_name" aria-label="Account Name"
															aria-describedby="basic-icon-default-fullname2" required autoFocus value={editedAccName} onChange={(e) => setEditedAccName(e.target.value)}/>
														</div>
														{type === 'business' && <>
														<div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="edaccGstin">GSTIN</label>
															<input type="text" className="form-control" id="edaccGstin"
																name="gstin"
																placeholder="GSTIN"
																aria-label=""
																value={gstin} onChange={(e) => {handleGstinData(e);setGstin(e.target.value)}} maxLength={15}/>
																<div className="form-text">Press Enter To Auto fetch GSTIN Data</div>
														</div>
														<div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="edLegalName">Legal Name</label>
															<input type="text" className="form-control" id="edLegalName" name="legal_name" aria-label="Legal Name"
															aria-describedby="Legal Name" value={legal_name} onChange={(e) => setLegalName(e.target.value)}/>
														</div>
														<div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="edTradeName">Trade Name</label>
															<input type="text" className="form-control" id="edTradeName" name="trade_name" aria-label="Trade Name"
															aria-describedby="Trade Name" value={trade_name} onChange={(e) => setTradeName(e.target.value)}/>
														</div>
														</>}
														<div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="profilePincode">Pin Code</label>
															<input type="text" id="profilePincode" name="pin" className="form-control" placeholder="Pin Code" aria-label="Pin Code" value={pin} onChange={(e) => populatePinData(Common.acceptOnlyNumbers(e.target.value))} maxLength="6" inputMode="numeric"/>
														</div>
														<div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="edaccState">State</label>
															<Select id="edaccState" placeholder="Select State" name='state' value={state_master[Common.stateIndex(state_master,stateid)]} options={state_master} onChange={(e) => setState(e.value)} required/>
														</div>
														<div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="basic-icon-default-phone">District</label>
															<input
															type="text"
															name="district"
															className="form-control"
															placeholder="District"
															aria-label="District"
															value={district}
															onChange={(e) => setDistrict(e.target.value)}
															/>
														</div>
														{type === 'business' &&
														<>
														 <div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="business-address-type">Address Type</label>
															<Select id="business-address-type" placeholder="Select Address Type" defaultValue={Common.optRegOffices[address_type]} name='address_type' options={Common.optRegOffices} onChange={(e) => setAddressType(e.value)} required/>
														</div>
														</>}
														<div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="basic-icon-default-message">Address</label>
															{/* <textarea
															name="address"
															className="form-control"
															placeholder="Address"
															aria-label="Address"
															aria-describedby="basic-icon-default-message2" value={address} onChange={(e) => setAddress(e.target.value)}></textarea> */}
															<input 
															type="text"
															name="address"
															maxLength="100"
															className="form-control"
															placeholder="Address"
															aria-label="Address"
															aria-describedby="basic-icon-default-message2" value={address} onChange={(e) => setAddress(e.target.value)} />
														</div>
                                                    </div>
													<div className="row">
														<div className="d-flex gap-2">
															<a href="/profiles" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> <span className="hidden-phone">Back</span></a>
															<button onClick={profileUpdate} type='button' className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;<span className="hidden-phone">Save</span></button>
														</div>
													</div>
												</form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
					</div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
    );
}