import React  from "react";
import Layout from './PartnerLayout';
import NavBar from './PartnerNavBar';
import 'react-date-picker/dist/DatePicker.css';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

export default function FynoneMarketing(props) {
    const [documents, setDocuments]             = React.useState([]);
    const [DTpending, setDTPending]             = React.useState(true);
    const [filterText, setFilterText]           = React.useState('');
    const [totalRows, setTotalRows]             = React.useState(0);
	const [perPage, setPerPage]                 = React.useState(10);
    const [dt_load,setDtLoad]                   = React.useState(true);
    const [switchChecked, setChecked]           = React.useState(false);
    const [activeType, setActiveType]           = React.useState('1');
    const [sortOrder, setSortOrder]             = React.useState('');
    const navigate                              = Common.useNavigate();
    React.useEffect(() => {
        document.title = 'Fynone Marketing | '+props.site_name;
        loadDataTable(1,perPage,filterText, '', '0');
	}, [props,dt_load,filterText]);
    const loadDataTable = (page,perPage,filterText,sort='', isDocDeleted) => {
		Common.axios({
            method: 'post',
            url:props.url+'partner-marketing/document-manager/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText, 'status': isDocDeleted,'account_type':localStorage.getItem('fo_partnerType')}
        }).then(function (response) {
            if(response.data.success){
				setDocuments(response.data.data);
				setTotalRows(response.data.total);
				setDTPending(false);
            }
        }).catch(error => console.log(error));
	};
    const handlePerRowsChange = async (newPerPage, page) => {
		// loadDataTable(page,newPerPage,filterText, '', '1');
		setPerPage(newPerPage);
	};
    const handlePageChange = page => {
		loadDataTable(page,perPage,filterText, sortOrder, '0');
	};
    const handleSort = (column, sortDirection) => {
        setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection), '0');
	};
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
    
    const editDocument = (val) => {
        navigate('/add-fynone-marketing',{state: {
            rowId: val.id,
            type:'edit'
        }});
    }
    const deleteDocument = (e, val) => {
        e.preventDefault();
        confirmAlert({
            title: 'Delete Document',
            message: 'Are you sure you want to delete?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    Common.showLoader(true);

                    let data = {};
                    
                    data['id'] = val;
                    data['pid'] = localStorage.getItem('fo_profileid');
            
                    Common.axios({
                        method: 'post',
                        url:props.url+'partner-marketing/delete-document',
                        data: data,
                    }).then(function (response) {
                        Common.showLoader(false);
                        if(response.data.success){
                            Common.toast.dismiss();
                            if(response.data.success === 1){
                                loadDataTable(1,perPage,filterText, sortOrder, '0');
                                Common.toast.success(response.data.message);
                                return false;
                            }else{
                                Common.toast.error(response.data.message);
                            }
                            return false;
                        } 
                        const err_html = () => (
                            Common.parse(response.data.message)
                        );
                        Common.toast.error(err_html);
                        Common.showLoader(false);
                    }).catch(error => console.log(error));
                }
              },
              {
                label: 'No',
                onClick: () => {console.log('no')}
              }
            ]
          });
    }
    const columns = [
        {
            name: 'Document Title',
            sortable: true,
			sortField: 'title',
			cell: (row) => (
				row.title
			)
        },
        {
            name: 'Document Description',
            selector: row => row.description,
            sortable: false,
			sortField: 'description',
			cell: (row) => (
				row.description
			)
        },
		{
            name: 'Tags',
            selector: row => row.file_path,
            sortable: false,
			sortField: 'path',
			cell: (row) => (
				row.tags
			)
        },
		{
            name: 'Partner',
            selector: row => row.partner_name,
            sortable: false,
			sortField: 'partner_name',
			cell: (row) => (
				(row.partner_name)?row.partner_name:'All'
			)
        },
		{
            name: 'Download Attachment',
            selector: row => row.file_path,
            sortable: false,
			sortField: 'file_path',
			cell: (row) => (
				row.file_path == '' ? ''  : <button type="button" onClick={(e) => handleDocDownload(row.file_path)} className="btn btn-sm btn-primary" >Download</button>
			)
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
            sortField: 'created_by',
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
            sortField: 'created_on',
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
            sortField: 'updated_by',
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
            sortField: 'updated_on',
        }
    ];
    if(localStorage.getItem('fo_partnerType') === 'admin'){
        columns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => (row.is_deleted === '0' ? <>
                    <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="true">
                        <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu datatable_menu " data-popper-placement="bottom-end" >
                            <button onClick={(e) => editDocument(row)}  className="dropdown-item"><i className="bx bx-book-reader me-1"></i>Edit</button>
                            <button onClick={(e) => deleteDocument(e, row.id)}  className="dropdown-item"><i className="bx bx-lock-alt me-1"></i> Delete</button>
                        </div>
                    </div>
                    </> : <>{'Deleted'}</>
                )
            },  
        );
    }
    const handleDocDownload = (filePath) => {
        Common.showLoader(true);
        Common.axios({
            method: 'get',
            url:props.url+'partner-marketing/download/'+encodeURIComponent(btoa(filePath)),
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				Common.toast.dismiss();
                const url = props.url+response.data.url;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.data.file_name);
                document.body.appendChild(link);
                link.click();
                return false;
			} 
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    };
    const handleChange = (e) => {
        switchChecked === true ? setChecked(false) : setChecked(true);
        activeType === '0' ? setActiveType('1') : setActiveType('0');
        loadDataTable(1,perPage,filterText, sortOrder, activeType);
    }
    return(
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Layout site_logo={props.site_logo} label="partner-marketing" site={props.site_name}/>
                    <div className="layout-page">
                        <NavBar site={props.site_name} url={props.url}/>
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h4 className="fw-bold py-3 mb-3">FynOne Marketing</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="my-2">
                                            { (localStorage.getItem('fo_partnerType') === 'admin') && <>
                                                <label style={{'marginRight':'0.5rem'}}>
                                                    <BootstrapSwitchButton
                                                        onChange={handleChange}
                                                        checked={switchChecked}
                                                        onlabel='Deleted'
                                                        onstyle='danger'
                                                        offlabel='Active'
                                                        offstyle='success'
                                                        width='100'
                                                        size="sm"
                                                    />
                                                </label>
                                                <a href="/add-fynone-marketing" className="btn btn-sm btn-primary">
                                                    <i className='bx bx-plus'></i> <span className="hidden-phone">Document</span>
                                                </a>
                                            </>}
											<span className="float-end">
												<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Document Title, Tags')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
												<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
											</span>
                                        </div>
                                        
                                        <DTE.DataTable className="dataTables_wrapper rdt_AdjDrp" columns={columns}  data={documents} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} persistTableHead/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Common.PartnerNeedHelp url={props.url}/>
                    </div>
                </div>
            </div>
        </>
    );
}