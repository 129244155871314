import React,{useState,useEffect}  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import { confirmAlert } from 'react-confirm-alert'; // Import
import * as DTE from '../common/ImportDataTableEssentials';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';

export default function ItemListing(props){
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [stateClear, setStateClear] = React.useState({ toggledClearRows: false });
	const [checkAll,setCheckAll] = React.useState(false);
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
	const [DTpending, setDTPending] = React.useState(true);
    const [items, setItemsList]   = useState([]);
	const [item_name, setItemName] = useState('');
	const [item_unit, setItemUnit] = useState('');
	const [item_service, setItemService] = useState('');
	const [item_hsn, setItemHsn] = useState('');
	const [total_price, setTotalPrice] = useState('');
	const [price, setPrice] = useState('');
	const [discount, setDiscount] = useState('');
	const [gst_amt, setGstAmt] = useState('');
	const [purchase_total_price, setPurchaseTotalPrice] = useState('');
	const [purchase_price, setPurchasePrice] = useState('');
	const [purchase_discount, setPurchaseDiscount] = useState('');
	const [purchase_gst_amt, setPurchaseGstAmt] = useState('');
	const [min_qty, setMinQty] = useState('');
	const [current_stock, setCurrentStock] = useState('');
	const [viewModal, setViewModal] = useState(false);
	const handleViewClose = () => setViewModal(false);
	const [inventoryMgmt_w,setinventoryMgmt_w] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [exportBtnClass,setExportBtnClass]    = React.useState('d-none');
	const [gst_rate, setGstRate] = useState('');
	const [stock_movement, setStockMovement] = useState('');
	const [stockOpening, setStockOpening] = useState('');
	const [stockOpeningDate, setStockOpeningDate] = useState('');
	// additional fields
	const [skuCode, setSkuCode] = useState('');
	const [barcode, setBarcode] = useState('');
	const [safetyStock, setSafetyStock] = useState('');
	const [reorderPoint, setReorderPoint] = useState('');
	const [reorderQty, setReorderQty] = useState('');
	const [maxStockLevel, setMaxStockLevel] = useState('');
	// item custom Fields
	const [itemCustomFields, setItemCustomFields] = useState([]);
	// User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	const [exportModal,setExportModal] = React.useState(false);
	const [dlParams,setDlParams] = React.useState([]);
	const [switchChecked, setChecked]           = useState(false);
    const [activeType, setActiveType]           = useState('0');
	const [downloadStatus, setDownloadStatus]   = useState('1');
	const [trackMovementModal, setTrackMovementModal]   = useState(false);
	const [opening_stock, setOpeningStock] = useState('0');
	const [opening_stock_date, setOpeningStockDt] = useState(new Date());
	const [itemId, setItemId] = useState('');
	const [sortOrder,setSortOrder] = useState('');
	const navigate = Common.useNavigate();

    useEffect(() => {
        document.title = 'Item Listing | '+props.site_name;
		if( Object.keys(userPerm).length > 0 ){
			//Write
			setinventoryMgmt_w((userPerm[4]['write'] !== undefined && userPerm[4]['write'] === '0') ? false : true);
		}
		loadDataTable(1,perPage,filterText,'','1');
    },[props,filterText,dt_load]);
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,sortOrder,'1');
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText,sortOrder,'1');
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection),'1');
	};
    const loadDataTable = (page,perPage,filterText,sort='',status) => {
		Common.axios({
            method: 'post',
            url:props.url+'items/listing',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText,'status':status}
        }).then(function (response) {
			setExportBtnClass('d-none');
            if(response.data.success){
                setItemsList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				if(response.data.total > 0){
					setExportBtnClass('');
				}
            }
        }).catch(error => console.log(error));
	}
	const handleExportCSV = (e)=>{
		e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'items/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText,'dlParams':dlParams,'status': downloadStatus},
            // responseType: 'blob',
        }).then(function (response) {
			if(response.data.success){
				window.location.href = response.data.message
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Item-${Date.now()}.csv`);
                document.body.appendChild(link); */
				setExportModal(false);
				setCheckAll(false);
				setDlParams([]);
                // link.click();
			} else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
	}
	const handleItemCheckbox = (state) => {
		let datas = state.selectedRows;
		let res = [];
		datas.map((val,index) => (
			res[index] = val.id
		))
		setSelectedRows(res);
	};
	const handleMoveMart = () => {
		Common.showLoader(true);
		let url = props.url+'items/moveToMart';
		Common.axios({
			method: 'post',
			url:url,
			data:{'pid':localStorage.getItem('fo_profileid'),id:selectedRows},
		}).then(function (response) {
			Common.showLoader(false);
			Common.toast.dismiss();
			handleClearRows();
			if(response.data.success){
				Common.toast.success(response.data.message, {duration: 6000});
				loadDataTable(1,perPage,filterText,sortOrder,'1');
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => {console.log(error);Common.showLoader(false);});
	}
	const handleClearRows = () => {
		setStateClear({ toggledClearRows: !stateClear.toggledClearRows})
	}
	const contextActions = React.useMemo(() => {
		return (
			<div>
				<Button variant="info" size="sm" key="Move to Store" onClick={handleMoveMart}>
					Move to Mart
				</Button>
			</div>
		);
	}, [selectedRows]);
	const viewItem = (val) => {
		Common.axios({
            method: 'get',
            url:props.url+'items/details/'+localStorage.getItem('fo_profileid')+'/'+val,
        }).then(function (response) {
            if(response.data.success){
                setItemName(response.data.data.name);
                setItemUnit(response.data.data.uqc_unit);
                setItemService(response.data.data.service);
                setItemHsn(response.data.data.hsn);
                setPrice(response.data.data.sale_price);
                setDiscount(response.data.data.sale_discount);
                setGstAmt(response.data.data.sale_gst_amt);
                setMinQty(response.data.data.min_qty);
                setTotalPrice(response.data.data.sale_total_price);
                setCurrentStock(response.data.data.current_stock);
				setPurchasePrice(response.data.data.purchase_price);
                setPurchaseDiscount(response.data.data.purchase_discount);
                setPurchaseGstAmt(response.data.data.purchase_gst_amt);
                setPurchaseTotalPrice(response.data.data.purchase_total_price);
                setGstRate(response.data.data.gst_rate);
				setStockMovement(response.data.data.stock_movement);
				let stockDate = response.data.data.opening_stock_date.split('-')[1];
				let month = '';
				if (stockDate === '01') {
					month = 'Jan';
				} else if (stockDate === '02') {
					month = 'Feb';
				} else if (stockDate === '03') {
					month = 'March';
				} else if (stockDate === '04') {
					month = 'April';
				} else if (stockDate === '05') {
					month = 'May';
				} else if (stockDate === '06') {
					month = 'June';
				} else if (stockDate === '07') {
					month = 'July';
				} else if (stockDate === '08') {
					month = 'Aug';
				} else if (stockDate === '09') {
					month = 'Sept';
				} else if (stockDate === '10') {
					month = 'Oct';
				} else if (stockDate === '11') {
					month = 'Nov';
				} else if (stockDate === '12') {
					month = 'Dec';
				}
				// additional fields
				setSkuCode(response.data.data.sku_code);
				setBarcode(response.data.data.barcode);
				setSafetyStock(response.data.data.safety_stock);
				setReorderPoint(response.data.data.reorder_point);
				setReorderQty(response.data.data.reorder_qty);
				setMaxStockLevel(response.data.data.max_stock_level);
				setStockOpening(response.data.data.opening_stock);
				setStockOpeningDate(response.data.data.opening_stock_date.split('-')[2]+" "+month+" "+response.data.data.opening_stock_date.split('-')[0]);
				// item custom fields
				setItemCustomFields(response.data.item_custom_fields);
				setViewModal(true);
            }
        }).catch(error => console.log(error));
	}
	const editItem = (val) => {
        navigate('/edit-item',
        {
            state: {
                item_id: val
            }
        });
	}
	const setDownloadParams = event => {
        if(event.target.checked){
            setDlParams([...dlParams, event.currentTarget.value]);
        } else {
            setDlParams(dlParams.filter(item=>item !== event.currentTarget.value));
        }
    }

	const deleteItem = (e,val) => {
		e.preventDefault();
		confirmAlert({
            title: 'Delete Item',
            message: 'Are you sure you want to delete?',
            buttons: [{
                label: 'Yes',
                onClick: () => {
                    Common.showLoader(true);
			        Common.axios({
			            method: 'get',
			            url:props.url+'items/deleteItem/'+localStorage.getItem('fo_profileid')+'/'+val,
			        }).then(function (response) {
			            if(response.data.success){
							loadDataTable(1,perPage,filterText,sortOrder,'1');
							Common.toast.success(response.data.message);
			            }else {
							Common.toast.error(response.data.message);
						}
						Common.showLoader(false);
			        }).catch(error => console.log(error));
                }
            },
            {
                label: 'No',
                onClick: () => {console.log('no')}
            }]
        });
	}

    const columns = [
    	{
            name: 'Action',
            button: true,
            cell: (row) => ( row.status !== '0' ? <><div className="dropdown">
					<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
						<i className="bx bx-dots-vertical-rounded"></i>
					</button>
					<div className="dropdown-menu" >
						<button onClick={(e) => viewItem(row.id)} className="dropdown-item" ><i className='bx bx-book-reader'></i> View </button>
						{inventoryMgmt_w && (<>
						<button onClick={(e) => editItem(row.id)} className="dropdown-item" ><i className="bx bx-edit"></i> Edit </button>
						<button onClick={(e) => deleteItem(e, row.id)} className="dropdown-item" ><i className="bx bx-trash"></i> Delete </button>
						</>)}
					</div>
              </div></> : <>{'Deleted'}</>
                
            ),
        },
        {
            name: 'Item Name',
            selector: row => row.name,
            sortable: true,
			sortField: 'name'
        },
        {
            name: 'Current Stock',
            selector: row => row.stock_movement === "0" && row.service === "0" ? <button type="button" className='btn btn-sm btn-outline-secondary' onClick={() => {openStockMovementModal(row)}}>Add Stock</button> : row.current_stock,
            sortable: false,
			sortField: 'current_stock'
        },
        {
            name: 'Move to Mart',
            selector: row => row.mart_item,
            sortable: false,
			center: true,
			cell: (row) => (
				( row.mart_item !== null ) ? <Common.OverlayTrigger overlay={Common.popoverContent('','Item Moved to Mart')}><i className='bx bx-store'></i></Common.OverlayTrigger> : ''
			)
        },
        {
            name: 'Item Unit',
            selector: row => row.item_unit ,
            sortable: false,
			sortField: 'item_unit'
        },
        {
            name: 'Sale Item Price / Unit (₹)',
            selector: row => row.sale_price,
            sortable: true,
			sortField: 'sale_price',
			right: true,
			style: {
				'text-align': 'right',
				'display': 'grid'
			}
        },
		{
            name: 'Sale Total Price (₹)',
            selector: row => row.sale_total_price,
            sortable: true,
			sortField: 'sale_total_price',
			right: true,
			style: {
				'text-align': 'right',
				'display': 'grid'
			}
        },
        {
            name: 'Purchase Item Price / Unit (₹)',
            selector: row => row.purchase_price,
            sortable: true,
			sortField: 'purchase_price',
			right: true,
			style: {
				'text-align': 'right',
				'display': 'grid'
			}
        },
		{
            name: 'Purchase Total Price (₹)',
            selector: row => row.purchase_total_price ,
            sortable: true,
			sortField: 'purchase_total_price',
			right: true,
			style: {
				'text-align': 'right',
				'display': 'grid'
			}
        },
        {
            name: 'Min Order Qty',
            selector: row => row.min_qty,
            sortable: true,
			sortField: 'min_qty'
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
            sortField: 'created_by',
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
            sortField: 'item.created_on',
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
            sortField: 'updated_by',
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
            sortField: 'item.updated_on',
        }		
    ];
	const handleChange = (e) => {
        switchChecked === true ? setChecked(false) : setChecked(true);
        activeType === '1' ? setActiveType('0') : setActiveType('1');
		switchChecked === true ? setDownloadStatus('1') : setDownloadStatus('0');
        loadDataTable(1,perPage,filterText, sortOrder, activeType);
    }
	const handleStockMovement = (e) => {
		Common.axios({
            method: 'post',
            url:props.url+'items/updateStock/',
			data: {
				item_id:itemId,
				opening_stock: opening_stock
			}
        }).then(function (response) {
            if(response.data.success){
                Common.toast.success(response.data.message);
				setTrackMovementModal(false);
				setOpeningStock('0')
				loadDataTable(1,perPage,filterText,sortOrder,'1');
            }
        }).catch(error => console.log(error));
	}
	const openStockMovementModal = (item) => {
		setTrackMovementModal(true)
		setItemId(item.id)
	}
	
	const closeStockMovementModal = () => {
		setTrackMovementModal(false)
		setOpeningStock('0');
	}

  return (
    <>
	<Modal size={"lg"} show={viewModal} onHide={handleViewClose} backdrop="static" keyboard={false}>
		<Modal.Header closeButton>
			<Modal.Title>Details</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Common.Row>
				<Common.Col><strong>Description : </strong> {item_name}</Common.Col>
				<Common.Col><strong>Unit : </strong> {item_unit}</Common.Col>
			</Common.Row>
			<Common.Row className="mt-1">
				<Common.Col>
					<strong>Is it a Service? : </strong> {item_service == '0' ? <>
						<label><input className="mx-1" type="radio" name="service" checked disabled/>Product</label>
						<label><input className="mx-1" type="radio" name="service"  disabled/>Service</label>
					</> : <>
						<label><input className="mx-1" type="radio" name="service"  disabled/>Product</label>
						<label><input className="mx-1" type="radio" name="service" checked  disabled/>Service</label>
					</>}
				</Common.Col>
				<Common.Col><strong>HSN Code : </strong> {item_hsn}</Common.Col>
			</Common.Row>
			<Common.Row className="mt-1">
				<Common.Col><strong>Min Order Qty : </strong> {min_qty}</Common.Col>
				<Common.Col><strong>GST Rate : </strong> {gst_rate}</Common.Col>
			</Common.Row>

			{item_service === '0' && stock_movement == '1' ? <>
				<Common.Row>
					<Common.Col><strong>Current Stock : </strong> {current_stock}</Common.Col>
					<Common.Col><strong>Track Item stock movement : </strong>{stock_movement == '1' ? 'Yes' : 'No'}</Common.Col>
				</Common.Row>
							
				<Common.Row>
					<Common.Col><strong>Opening Stock : </strong> {stockOpening}</Common.Col>
					<Common.Col><strong>Opening Stock Date : </strong> {stockOpeningDate}</Common.Col>
				</Common.Row>
			</>: item_service === '0' && stock_movement == '0' ? <>
				<Common.Row>
					<Common.Col><strong>Track Item stock movement : </strong>{stock_movement == '1' ? 'Yes' : 'No'}</Common.Col>
				</Common.Row>
			</>:<></>}
			
			<Common.Row className="mt-1">
				<Common.Col><h6 className='bg-secondary text-white text-center mt-3'><strong>Sale</strong></h6></Common.Col>
				<Common.Col><h6 className='bg-secondary text-white text-center mt-3'><strong>Purchase</strong></h6></Common.Col>
			</Common.Row>
			<Common.Row className="mt-1">
				<Common.Col>
					<strong>Price/Unit : </strong> {Common.displayPrice(price)}<br></br>
					<strong>Discount (-): </strong> {Common.displayPrice(discount)}<br></br>
					<strong>Gross Amount : </strong> {Common.displayPrice(price-discount)}<br></br>
					<strong>GST : </strong> {Common.displayPrice(gst_amt)}<br></br>
					<strong>Total Price : </strong> {Common.displayPrice(total_price)}
				</Common.Col>
				<Common.Col>
					<strong>Price/Unit : </strong> {Common.displayPrice(purchase_price)}<br></br>
					<strong>Discount (-): </strong> {Common.displayPrice(purchase_discount)}<br></br>
					<strong>Gross Amount : </strong> {Common.displayPrice(purchase_price-purchase_discount)}<br></br>
					<strong>GST : </strong> {Common.displayPrice(purchase_gst_amt)}<br></br>
					<strong>Total Price : </strong> {Common.displayPrice(purchase_total_price)}
				</Common.Col>
			</Common.Row>
			<Common.Row className="mt-1">
				<Common.Col><h6 className='bg-secondary text-white text-center mt-3'><strong>Item Details</strong></h6></Common.Col>
			</Common.Row>
			<Common.Row className="mt-1">
				<Common.Col>
					<strong>SKU Code : </strong> {skuCode}<br></br>
					<strong>Barcode : </strong> {barcode}<br></br>
					<strong>Safety Stock : </strong> {safetyStock}
				</Common.Col>
				<Common.Col>
					<strong>Reorder Point : </strong> {reorderPoint} <br></br>
					<strong>Reorder Quantity : </strong> {reorderQty} <br></br>
					<strong>Maximum Stock Level : </strong> {maxStockLevel}
				</Common.Col>
			</Common.Row>
			{itemCustomFields.length > 0 ? <>
				<Common.Row className="mt-1">
					<Common.Col><h6 className='text-center mt-3'><strong>Custom Fields</strong></h6></Common.Col>
				</Common.Row>
				{itemCustomFields.map((field, i) =>{
					return (
						<Common.Row className="mt-1">
							<Common.Col>
								<strong>Field Name : </strong> {field.item_field_name}<br></br>
							</Common.Col>
							{/* <Common.Col>
								<strong>Field Value : </strong> {field.item_field_value}<br></br>
							</Common.Col> */}
						</Common.Row>
					)
				})}
					
			</>:<></>}
			
		</Modal.Body>
	</Modal>
    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
            <Layout label="item-listing" site_logo={props.site_logo} site={props.site_name}/>
            <div className="layout-page">
                <NavBar site={props.site_name} url={props.url}/>
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Inventory Management /</span> Item Listing
							<span className="dropdown">
								<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
								<i className='text-danger bx bxl-youtube bx-md'></i>
								</button>
								<div className="dropdown-menu" >
									<a href="https://youtu.be/o2zZagDTzIs" className="dropdown-item" target="_blank" rel="noreferrer"><span>Add inventory</span></a>
									<a href="https://youtu.be/qLMjD2JCAi4" className="dropdown-item" target="_blank" rel="noreferrer"><span>Bulk upload inventory</span></a>
								</div>
							</span>
						</h4>
						<div className="row">
							<div className="col-md-6 col-12 mb-1 text-center text-md-start">
								<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
								{inventoryMgmt_w && (<>
									<div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
										<button type="button" className="btn btn-primary" disabled><i className="bx bx-plus"></i></button>
										<Common.Link to="/add-item" className="btn btn-primary"> <i className='bx bxs-keyboard'></i>Form</Common.Link>
										<Common.Link to="/add-item-file" className="btn btn-primary"> <i className='bx bxs-file'></i>Bulk Upload</Common.Link>
									</div>
								</>)}
								<label style={{'marginLeft': '0.5rem', 'marginTop': '0.2rem'}}>
									<BootstrapSwitchButton
										onChange={handleChange}
										checked={switchChecked}
										onlabel='Deleted'
										onstyle='danger'
										offlabel='Active'
										offstyle='success'
										width='100'
										size="sm"
									/>
								</label>
							</div>
							<div className="col-md-6 col-12 text-center text-md-end mb-1">
								<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Item Name, Item Unit')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
								<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
							</div>
						</div>
                        <div className="row">
                            <div className="col-md-12">								
								<DTE.DataTable title={"Items ("+totalDataCnt+")"} selectableRows selectableRowsHighlight onSelectedRowsChange={handleItemCheckbox} className="rdt_AdjDrp" columns={columns} data={items} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} contextActions={contextActions} clearSelectedRows={stateClear.toggledClearRows}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Common.FYNTicket url={props.url}/>
            </div>
        </div>
    </div>
	<Modal show={exportModal} onHide={()=>setExportModal(false)} aria-labelledby="export-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="export-modal">Basic Export</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="my-1">
					Select options below to add more details to your report
					<div className="my-3">
						<div className="row">
							<div className="col-md">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="primary" id="itemPrimary" checked={dlParams.includes('primary')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemPrimary"> All item details </label>
								</div>
							</div>
						</div>
					</div>
					<div className="text-center">
						<button onClick={handleExportCSV} className="btn btn-sm btn-primary">Submit</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
		<Modal show={trackMovementModal} onHide={()=>closeStockMovementModal()} aria-labelledby="track-movement-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="track-movement-modal">Track Stock Movement</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="my-1">
					<div className="row">
						<div className="mb-3 col">
							<label htmlFor="opening_stock" className="form-label">Opening Stock Qty</label>
							<div className="input-group input-group-merge">
								<input type="number" className="form-control" id="opening_stock" name="opening_stock" placeholder="" value={opening_stock} onChange={(e) => setOpeningStock(e.target.value)} />
							</div>
						</div>
						<div className="mb-3 col">
							<label htmlFor="opening_stock_date" className="form-label">Opening Stock Qty as on Date</label>
							<DatePicker calendarIcon={null} clearIcon={null} disableCalendar={true} name="opening_stock_date" data-testid="opening_stock_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningStockDt} value={opening_stock_date} disabled = {true} />
						</div>
					</div>
					<div className="text-center">
						<button onClick={handleStockMovement} className="btn btn-sm btn-primary">Update Stock </button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
   </>
);

}