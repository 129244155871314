import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import * as Common from '../common/ImportUserEssentials';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import paymentModes from '../masters/PaymentModes.js';
// import deductionList from '../masters/Deductions.js';

export default function OtherIncome(props){
	const [received,setReceived]                           = useState();
	const [payment_date,setPaymentDate]                    = useState(new Date());
	const [payment_mode,setPaymentMode]                    = useState('UPI/QR');
	// const [payment_remarks,setPaymentRemarks]              = useState('');
	const [trans_ref,setTransRef]                          = useState('');
	const [bankList, setBankList] 						   = useState([]);
	const [bankAccount,setBankAccount] 					   = useState(0);
    const [bank_cash_account,setBankCashAccount] 		   = useState(1);
	const [defaultPayee, setDefaultPayee] 				   = useState([]);
	const [income_type_master, setIncomeTypeMaster]        = useState([]);
	const [income_type,setIncomeType]                      = useState('');
	const [income_sub_type_master, setIncomeSubTypeMaster] = useState([]);
	const [income_sub_type,setIncomeSubType]               = useState('');
	const [incomeSubTypeDisable, setIncomeSubTypeDisable]  = useState(true);
	const [voucher_no,setVoucherNo]                        = useState('');
	const [voucher_date, setVoucherDate]                   = useState(new Date());
	const [voucher_remarks,setVoucherRemarks]              = useState('');
	const [gst_amt,setGstAmt]                              = useState('');
	const [taxable_amt,setTaxableAmt]                      = useState('');
	const [total_amt,setTotalAmount]                       = useState('');
	const [payment_div,setPaymentDiv]                      = useState(false);
    const [taxes,setTaxes]                 				   = useState([]);
    const [gst_rate, setGstRate]       					   = useState('');
    const [gst_rate_id, setGstRateId]       			   = useState('');
    const [loanList, setLoanList]       			   	   = useState([]);
    const [loanId, setLoanId]       			   	   	   = useState('');
	const [loan_div,setLoanDiv]                      	   = useState(false);
	const [loan_select_div,setLoanSelectDiv]               = useState(false);
	const [allocation_centre_master,setAllocationCentreMaster] = useState([]);
	const [allocation_centre,setAllocationCentre] 			= useState('');
	const [deduction_model,setDeductionModel]           	= useState(false);
	const [deduction_amt,setDeductionAmt]           		= useState('');
	const [deduction_per,setDeductionPer]           		= useState('');
	const frmSaleDedType                            		= React.createRef();
	const frmSaleDedSubtype                                 = React.createRef();
	const [deductions,setDeductions]                = useState([]);
	const [total_deduction,setTotalDeduction]       = useState('0.00');
	const [final_total,setFinalTotal]       = useState('0.00');
	const [defaultTaxes,setDefaultTaxes] = useState([]);

	const [deductionMasterList, setDeductionMasterList]       = useState([]);
	const [deductionMaster, setDeductionMaster]               = useState('');
	const [deductionMasterLabel, setDeductionMasterLabel]     = useState('')

	const [deductionSubtypeMasterList, setDeductionSubtypeMasterList] = useState([])
	const [deductionSubtypeMaster, setDeductionSubtypeMaster] = useState('')
	const [deductionSubtypeMasterLabel, setDeductionSubtypeMasterLabel] = useState('');
	const [isDeductionMasterSelected, setIsDeductionMasterSelected] = useState(false);
	const gstType = [{'id': '1','val':'IGST'},{'id': '2','val':'SGST + CGST'}];
	const [gstTypeId, setGstTypeId] = useState('')
	const [defaultGstType, setDefaultGstType] = useState([])
	const [isGstRateSelected, setIsGstRateSelected] = useState(false);

	//common Model	
	const [commonModel, setCommonModel]    	  		 = useState(false);
	const [commonModelHeading,setCommonModelHeading] = useState('');
	const [commonModelBody,setCommonModelBody] 		 = useState('');
	const [commonModelFooter,setCommonModelFooter] 	 = useState('');
    
	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Add Other Income | '+props.site_name;

		// get tax rates
		Common.axios({
			method: 'get',
			url:props.url+'ajax/getTaxes',
		}).then(function (response) {
			if(response.data.success){
				setTaxes(response.data.data);
				// setDefaultTaxes(response.data.data.slice(0,1))
			}
		}).catch(error => console.log(error));

		// deduction master
		Common.axios({
			method: 'get',
			url:props.url+'ajax/getDeductionMaster',
		}).then(function (response) {
			if(response.data.success){
				setDeductionMasterList(response.data.data);
			}
		}).catch(error => console.log(error));

		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				setDefaultPayee(response.data.defaultPayee);
				if (response.data.defaultPayee.length > 0) {
					setBankAccount(response.data.defaultPayee[0].value);
				}
			}
		}).catch(error => console.log(error));
        // Income Types Master
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_other_income_types',
		}).then(function (response) {
			if( response.data.success ){
				setIncomeTypeMaster(response.data.data);
			}
		}).catch(error => console.log(error));

		//get allocation centre
		Common.axios({
			method: 'get',
			url:props.url+'sales/getAllocationCentre/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setAllocationCentreMaster(response.data.data);
			}
		}).catch(error => console.log(error));

		// Fetch voucher No
        const getVoucherNo = () => {
            Common.axios({
                method: 'post',
                url:props.url+'collection/getVoucherNo',
                data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
            }).then(function (response) {
                if(response.data.success){
                    setVoucherNo(response.data.data);
                    return false;
                }
                Common.toast.error(response.data.message);
            }).catch(error => console.log(error))
		}
        getVoucherNo();
        const voucher_interval = setInterval(() => {getVoucherNo()},10000);
        

        return () => clearInterval(voucher_interval);
	}, [props]);

	const setPaymentModeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setPaymentMode(value);
		if(value === 'Cash'){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/'+value,
			}).then(function (response) {
				if(response.data.success){
					if(response.data.data !== ''){
						setBankCashAccount(1);
						setDefaultPayee(response.data.data);
						setBankAccount(response.data.data[0].value);
						setBankList(response.data.data);
					}else{
						setBankCashAccount(0);
						setDefaultPayee([]);
						setBankAccount(0);
					}
				}
			}).catch(error => console.log(error));
		}else{
			setBankCashAccount(1);
			setDefaultPayee([]);
			setListOfBanks();
		}
	}
	const getTaxes = () => {
		Common.axios({
			method: 'get',
			url:props.url+'ajax/getTaxes',
		}).then(function (response) {
			if(response.data.success){
				// setTaxes(response.data.data);
				setGstRate(response.data.data[0].val)
				setGstRateId(response.data.data[0].id)
				summaryTotalCal((response.data.data[0] ? response.data.data[0].val : ''),'gst');
				setDefaultTaxes([response.data.data[0]])
			}
		}).catch(error => console.log(error));
	}
	const setListOfBanks = () => {
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				// setDefaultPayee(response.data.defaultPayee);
				// if (response.data.defaultPayee.length > 0) {
				// 	setBankAccount(response.data.defaultPayee[0].value);
				// }
			}
		}).catch(error => console.log(error));
	}
	const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setBankAccount(value);
		// setDefaultPayee('');
		if (value === '') {
			setDefaultPayee([]);
		} else {
			bankList.forEach((bank,i) => {
				if (bank.value === value) {
					setDefaultPayee([bank]);
				}
			});
		}
	}
    const setIncomeTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		setIncomeType(value);
        setIncomeSubTypeDisable((value)?false:true);
		setLoanDiv(false);
		setLoanSelectDiv(false);
		if (value === '') {
			setIsGstRateSelected(false);
		} else {
			setIsGstRateSelected(true);
		}
		if(label === 'Financial income'){
			getLoanList();
		}
        Common.axios({
			method: 'post',
				url: props.url+'ajax/fetch_income_subtypes',
				data: {
					'pid': localStorage.getItem('fo_profileid'),
					'income_type_master_id':value
				}
		}).then(function (response) {
			if( response.data.success ){
				setIncomeSubTypeMaster(response.data.data);
				getTaxes()
			}
		}).catch(error => console.log(error));
	}
    const setIncomeSubTypeSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setIncomeSubType(value);
	}
	const setLoanSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setLoanId(value);
		setLoanSelectDiv(false);
		if(value){
			setLoanSelectDiv(true);
			Common.axios({
				method: 'get',
					url: props.url+'loans/loan-given-emi/'+value,
			}).then(function (response) {
				if( response.data.success ){
					setTaxableAmt(Number(response.data.data.emi_amt).toFixed(2));
					setTotalAmount(Number(response.data.data.emi_amt).toFixed(2));
					setFinalTotal(Number(response.data.data.emi_amt).toFixed(2));
					setReceived(Number(response.data.data.emi_amt).toFixed(2));
					setGstAmt(0);
					setGstRateId(1);
				}else{
					setCommonModel(true);
                    setCommonModelHeading('Loans');
                    setCommonModelBody(<div className="row"><div className="col-sm-12"><p>All scheduled EMI payments for this loan are completed. To close this loan, pl navigate to <a href="/loan-listing"> Loans</a> section and mark the loan as Closed.</p></div></div>);
                    setCommonModelFooter('');
				}
			}).catch(error => console.log(error));
		}else{
			setTaxableAmt('');
			setTotalAmount('');
			setFinalTotal('');
			setReceived('');
			setGstAmt('');
			setGstRateId('');
		}
	}
	const getLoanList = () => {
        Common.axios({
			method: 'post',
				url: props.url+'loans/loan-given-list',
				data: {
					'pid': localStorage.getItem('fo_profileid')
				}
		}).then(function (response) {
			if( response.data.success ){
				if(response.data.data.length > 0){
					setLoanDiv(true);
					setLoanList(response.data.data);
				}
			}
		}).catch(error => console.log(error));
	}
    const summaryTotalCal = (amount,type) => {
        let totalAmt = 0;let gstAmt = 0;
		if(type === 'taxable'){
			gstAmt  = Number((amount * gst_rate)/100).toFixed(2);
            totalAmt = (Number(amount) + Number(gstAmt)).toFixed(2);
        }else{
			gstAmt  = Number((taxable_amt * amount)/100).toFixed(2);
            totalAmt = (Number(taxable_amt) + Number(gstAmt)).toFixed(2);
        }
		let finalAmt = (Number(totalAmt) - Number(total_deduction)).toFixed(2);
		setGstAmt(gstAmt);
        setTotalAmount(totalAmt);
		setFinalTotal(finalAmt);
        setReceived(totalAmt);
	}
	const setAllocationCentreSelect = (e) => {
		let value = (e[0]) ? e[0].name : '';
		setAllocationCentre(value);
	}
    const handleOtherIncome =(e) => {
		e.preventDefault();		
		Common.showLoader(true);
		if( payment_div && payment_mode === '' ){
			Common.showLoader(false);
			Common.toast.error('Payment Mode is required');
			return false;
		}
		// payment_mode !== 'Cash' &&
		if( payment_div && (bankAccount === undefined || bankAccount === 0 || bankAccount === '') ){
			Common.showLoader(false);
			Common.toast.error('Bank Account is required.');
			return false;
		}
		// if( income_sub_type === '' ){
		// 	Common.toast.error('Income Sub Type is required');
		// 	return false;
		// }
		if( gst_rate_id === '' ){
			Common.showLoader(false);
			Common.toast.error('GST Rate is required');
			return false;
		}
		
		if (isGstRateSelected === true && parseInt(gst_rate_id) >= 5 && gstTypeId === '') {
			Common.showLoader(false);
			Common.toast.error('GST Type is required');
			return false;
		}
        // setSaveBtnDisabled(true);
		let formData = new FormData(e.target);
		let pid = localStorage.getItem('fo_profileid');
		formData.append('pid', pid);
        formData.append('payment_mode', payment_mode);
        formData.append('bankAccount', bankAccount);
        formData.append('income_type', income_type);
        formData.append('income_sub_type', income_sub_type);
        formData.append('payment_status', (payment_div)?1:0);
		formData.append('gst_type', gstTypeId);
        formData.append('gst_rate_id', gst_rate_id);
        formData.append('loan_id', loanId);
		formData.append('allocation_centre', allocation_centre);
		formData.append('deductions', JSON.stringify(deductions));
		let data = {};
		// convert the key/value pairs
		for (var [key, value] of formData.entries()) {
		   data[key] = value;
		}

		Common.axios({
			method: 'post',
			url: props.url+'collection/addOtherIncome',
			data: data
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				navigate("/collection-listing",
				{
					state: {
						tab_type: 3,
					}
				});
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const calcDeduction = (param,val) => {
		let percent = 0,amount = 0;
		if( param === 'per' ){
			percent = val;
			amount = (taxable_amt * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/taxable_amt * 100).toFixed(2);
		}
		setDeductionPer(percent);
		setDeductionAmt(amount);
	}
	const deductionModal = () => {
		setDeductionAmt('');setDeductionPer('');
		setDeductionModel(true);
		setIsDeductionMasterSelected(false)
	}
	const setDeductionTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		setDeductionMaster(value);
		setDeductionMasterLabel(label)
		if (value === '') {
			setIsDeductionMasterSelected(false);
			setDeductionSubtypeMasterList([])
		} else {
			setIsDeductionMasterSelected(true);
		}
		if (value !== '') {
			Common.axios({
				method: 'post',
				url: props.url+'ajax/getDeductionSubtypeMaster',
				data: {
					'pid': localStorage.getItem('fo_profileid'),
					'deduction_master_id':value
				}
			}).then(function (response) {
				if( response.data.success ){
					let dedSubtypeList = response.data.data;
					if (dedSubtypeList.length > 0) {
						setDeductionSubtypeMasterList(response.data.data);
					}
				}
			}).catch(error => console.log(error));
		} else {
			setDeductionSubtypeMaster(value);
			frmSaleDedSubtype.current.clear();
			setDeductionSubtypeMasterList([])
		}
	}
	const setDeductionSubTypeSelect = (e) => {
		if (e.length > 0) {
			setDeductionSubtypeMaster(e[0].label);
			setDeductionSubtypeMasterLabel(e[0].label)
		}
	}
	const addDeduction = () => {
		if( deduction_per === undefined || deduction_per === '' || deduction_per > 100 ){
			Common.toast.error('Deduction percent is invalid.');
			return false;
		}
		if( deduction_amt === undefined || deduction_amt === '' || Number(deduction_amt) === 0 ){
			Common.toast.error('Deduction amount should be greater than zero.');
			return false;
		}

		if( deductionMasterLabel === undefined || deductionMasterLabel === '' ){
			Common.toast.error('Deduction type is required.');
			return false;
		}
		
		if( Number(total_deduction) + Number(deduction_amt) > Number(taxable_amt) ){
			Common.toast.error('Total deduction amount cannot be greater than total taxable value.');
			return false;
		}
		let ded = {};
		ded['deduction_per'] = deduction_per;
		setDeductionPer('');
		ded['deduction_amt'] = deduction_amt;
		setDeductionAmt('');
		ded['deduction_type'] = deductionMasterLabel;
		frmSaleDedType.current.clear();
		ded['deduction_subtype'] = deductionSubtypeMasterLabel;
		frmSaleDedSubtype.current.clear();
		ded['deduction_master_id'] = deductionMaster
		ded['deduction_subtype_label'] = deductionSubtypeMaster;
		// ded['deduction_remarks'] = deduction_remarks;
		// setDeductionRemarks('');

		setDeductions([...deductions, ded]);
		let tot_deduction = (Number(total_deduction) + Number(deduction_amt)).toFixed(2);
		setTotalDeduction(tot_deduction);
		let final_tot = (Number(final_total) - Number(deduction_amt)).toFixed(2);
		setFinalTotal(final_tot);
		setDeductionSubtypeMasterList([])
		setDeductionSubtypeMasterLabel('');
		setIsDeductionMasterSelected(false)
		setDeductionModel(false);
	}
	const removeDeduction = (index) => {
		let price = deductions[index].deduction_amt;
		setDeductions(deductions.filter((item,key) => key !== index));
		let tot_deduction = (Number(total_deduction) - Number(price)).toFixed(2);
		setTotalDeduction(tot_deduction);
		let final_tot = (Number(final_total) + Number(price)).toFixed(2);
		setFinalTotal(final_tot);
	}
	const handleGst = (e) => {
		setGstRate(e[0] ? e[0].val : '');
		setGstRateId(e[0] ? e[0].id : '');
		summaryTotalCal((e[0] ? e[0].val : ''),'gst');
		if (e[0] !== undefined) {
			setDefaultTaxes(e);
			setIsGstRateSelected(true);
			// setDefaultGstType([{'id': '1','val':'IGST'}]);
		} else {
			setIsGstRateSelected(false);
			setDefaultTaxes([]);
			// setDefaultGstType([])
		}
	}
	
	const handleGstType = (e) => {
		if (e.length > 0) {
			setGstTypeId(e[0].id);
			setDefaultGstType(e)
		} else {
			setGstTypeId('');
			setDefaultGstType([])
		}
	}

	return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="other-income" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Income & Collections /</span> Add Other Income
							<span className="dropdown">
                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className='text-danger bx bxl-youtube bx-md'></i>
                                </button>
                                <div className="dropdown-menu" >
									<a href="https://www.youtube.com/watch?v=wg1syPJGFec" className="dropdown-item" target="_blank" rel="noreferrer"><span>Other income</span></a>
                                </div>
                            </span>
						</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            <form id="formAddCollection" onSubmit={handleOtherIncome} autoComplete="off" className='formAddCollection'>
                                            	<div className="row" id="collectionAddDiv">
													<div className="col-12">
														<div className="accordion" id="salesAccordion">
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingTwo">
																	<button type="button" className="accordion-button" data-bs-target="#accordionVoucher" aria-expanded="false" aria-controls="accordionVoucher" disabled>
																		Voucher Details <Common.OverlayTrigger overlay={Common.popoverContent('','This transaction will not be displayed in accounting module till payment details are updated')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
																	</button>
																</h2>
																<div id="accordionVoucher" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <Typeahead clearButton id="frmIncomeType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setIncomeTypeSelect(e)} options={income_type_master} placeholder="Choose account head"
                                                                                            renderMenuItemChildren={(option: Item,props) => (
                                                                                                <>
                                                                                                <Highlighter search={props.text}>
                                                                                                    {option.label}
                                                                                                </Highlighter>
                                                                                                <div>
                                                                                                    <small>{option.description}</small>
                                                                                                </div>
                                                                                                </>
                                                                                            )}
                                                                                        />
																					<label htmlFor="frmIncomeType">Other Income Type <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <Typeahead disabled={incomeSubTypeDisable} clearButton allowNew newSelectionPrefix="Add: "  id="frmIncomeSubType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setIncomeSubTypeSelect(e)} options={income_sub_type_master} placeholder="Choose account head"
                                                                                        renderMenuItemChildren={(option: Item,props) => (
                                                                                            <>
                                                                                            <Highlighter search={props.text}>
                                                                                                {option.label}
                                                                                            </Highlighter>
                                                                                            <div>
                                                                                                <small>{option.description}</small>
                                                                                            </div>
                                                                                            </>
                                                                                        )}
                                                                                    />
                                                                                    <label htmlFor="frmIncomeSubType">Other Income Sub Type</label>
																				</div>
																			</div>
																		</div>
																		{loan_div && (<>
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <Typeahead clearButton id="frmLoanList" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setLoanSelect(e)} options={loanList} placeholder="Choose account head"
                                                                                            renderMenuItemChildren={(option: Item,props) => (
                                                                                                <>
                                                                                                <Highlighter search={props.text}>
                                                                                                    {option.label}
                                                                                                </Highlighter>
                                                                                                <div>
                                                                                                    <small>{option.description}</small>
                                                                                                </div>
                                                                                                </>
                                                                                            )}
                                                                                        />
																					<label htmlFor="frmLoanList">Loans</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				
																			</div>
																		</div>
																		</>)}
                                                                        <div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input name="voucher_no" type="text" maxLength="10" className="form-control" id="frmVoucherNo" placeholder="Enter Voucher No" value={voucher_no} readOnly/>
																					<label htmlFor="frmVoucherNo">Voucher number</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <DatePicker name="voucher_date" data-testid="frmVoucherDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setVoucherDate} value={voucher_date} />
																					<label htmlFor="frmVoucherDate">Voucher date<span className="text-danger">*</span></label>
																				</div>
																			</div>
																		</div>
                                                                        <div className="row">
																			<div className="col-md-3 col-sm-3 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" name="taxable_amt" className="form-control" id="frmVoucherTaxableAmt" placeholder="0.00" value={taxable_amt} onChange={(e) => { setTaxableAmt(Common.acceptOnlyDecimal(e.target.value));summaryTotalCal(e.target.value,'taxable'); }} required readOnly={loan_select_div}/>
																					<label htmlFor="frmVoucherTaxableAmt">Taxable Amount <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-3 col-sm-3 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton disabled={loan_select_div} id="gst_rate" key="id" className="floatingTypeahead border-end rounded-end" onChange={(e) => {handleGst(e)}} placeholder="Select GST"
																						options={taxes}
																						labelKey="rate"
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.rate}
																							</Highlighter>
																						)}
																						// defaultSelected={taxes.slice(0,1)}
																						selected={defaultTaxes}
																					/>
																					<label htmlFor="frmVoucherGstAmt">GST Rate <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-3 col-sm-3 mb-3">
																				<div className="form-floating">
																					<Typeahead disabled={isGstRateSelected === true && parseInt(gst_rate_id) >= 5 ? false :true} clearButton id="gst_type" key="id" className="floatingTypeahead border-end rounded-end" onChange={(e) => handleGstType(e)} placeholder="Select GST Type"
																						options={gstType}
																						labelKey="val"
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.val}
																							</Highlighter>
																						)}
																						selected={defaultGstType}
																					/>
																					<label htmlFor="frmVoucherGstAmt">GST Type <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-3 col-sm-3 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" name="gst_amt" className="form-control" id="frmVoucherGstAmt" placeholder="0.00" value={gst_amt} required readOnly/>
																					<label htmlFor="frmVoucherGstAmt">GST Amount <span className="text-danger">*</span></label>
																				</div>
																			</div>
																		</div>
                                                                        <div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" name="total_amt" className="form-control" id="frmVoucherTotal" placeholder="0.00" value={total_amt} readOnly required/>
																					<label htmlFor="frmVoucherTotal">Total Amount</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton allowNew newSelectionPrefix="Add: " id="frmSaleAllocCentre" labelKey="name" key="id" className="floatingTypeahead" placeholder="Select Allocation Centre"
																						options={allocation_centre_master}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.name}
																							</Highlighter>
																						)}
																						onChange={(e)=>{setAllocationCentreSelect(e)}}
																					/>
																					<label htmlFor="frmSaleAllocCentre">Allocation Centre</label>
																					<div className="form-text" style={{'fontSize':'60%'}}>Use this field to maintain custom cost/profit centers</div>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <textarea rows="5" name="voucher_remarks" className="form-control" placeholder="Voucher Remarks" aria-label="frmVoucherRemarks" aria-describedby="frmVoucherRemarks" value={voucher_remarks} onChange={(e) => setVoucherRemarks(e.target.value)}></textarea>
                                                                                    <label htmlFor="frmVoucherRemarks">Voucher Remarks</label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
                                                    {payment_div && (<>
                                                    <div className="col-12">
														<div className="accordion" id="salesAccordion">
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingTwo">
																	<button type="button" className="accordion-button" data-bs-target="#accordionVoucher" aria-expanded="false" aria-controls="accordionVoucher" disabled>
																		Payment Details
																	</button>
																</h2>
																<div id="accordionVoucher" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
                                                                            <div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" className="form-control" id="frmReceivedTotal" name="received_amount" placeholder="0.00" value={received} readOnly required/>
																					<label htmlFor="frmReceivedTotal">Amount</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" className="form-control" id="frmDeductionTotal" name="deduction_amount" placeholder="0.00" value={total_deduction} readOnly required/>
																					<label htmlFor="frmDeductionTotal">Total Deduction Amount</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" className="form-control" id="frmFinalTotal" name="final_total" placeholder="0.00" value={final_total} readOnly required/>
																					<label htmlFor="frmFinalTotal">Total Amount</label>
																				</div>
																			</div>
																			
                                                                        </div>
                                                                        <div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <DatePicker data-testid="frmSalePayDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setPaymentDate} name="payment_date" value={payment_date} />
                                                                                    <label htmlFor="frmVoucherRemarks">Payment Date <span className="text-danger">*</span></label>
																				</div>
																			</div>
                                                                            <div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <Typeahead clearButton defaultSelected={paymentModes.slice(1, 2)} id="frmSalePayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
                                                                                    options={paymentModes}
                                                                                    renderMenuItemChildren={(option: Item) => (
                                                                                        <div>
                                                                                            {option.label}
                                                                                        </div>
                                                                                    )}
                                                                                    />
																					<label htmlFor="frmVoucherTotal">Payment Mode <span className="text-danger">*</span></label>
																				</div>
																			</div>
                                                                        </div>
                                                                        <div className="row">
																		<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <Typeahead selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
                                                                                        options={bankList}
                                                                                        renderMenuItemChildren={(option: Item,props) => (
                                                                                            <Highlighter search={props.text}>
                                                                                                {option.label}
                                                                                            </Highlighter>
                                                                                        )}
                                                                                    />
                                                                                    <label htmlFor="frmVoucherRemarks">Bank Account <span className="text-danger">*</span></label>
																				</div>
																				<small>To add additional bank account, Go to Account management - <a href='/bank-accounts'>Bank accounts</a></small>
																			</div>
                                                                            <div className="col-md-6 col-sm-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" maxLength="100" className="form-control" id="frmSaleTransRef" placeholder="Transaction Ref." name="trans_ref" value={trans_ref} onChange={(e) => setTransRef(e.target.value)} autoComplete="off"/>
                                                                                    <label htmlFor="frmVoucherTotal">Transaction Ref. No.</label>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <textarea rows="5" name="payment_remarks" className="form-control" placeholder="Payment Remarks" aria-label="frmPaymentRemarks" aria-describedby="frmPaymentRemarks" value={payment_remarks} onChange={(e) => setPaymentRemarks(e.target.value)}></textarea>
                                                                                    <label htmlFor="frmPaymentRemarks">Payment Remarks</label>
																				</div>
																			</div> */}
                                                                        </div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="card">
														<h6 className="card-header">Deductions</h6>
														{deductions.length === 0 && <div className="text-center py-1">No deduction</div>}
														{deductions.length > 0 && <>
														<div className="table-responsive text-nowrap">
															<table className="table table-sm table-bordered">
																<thead className="table-light">
																	<tr>
																		<th align="center">Action</th>
																		<th>Sl.</th>
																		<th align="right">Deduction (%)</th>
																		<th align="right">Deduction (<i className='bx bx-rupee' ></i>)</th>
																		<th>Deduction Type</th>
																		<th>Deduction Subtype</th>
																		{/* <th>Remarks</th> */}
																	</tr>
																</thead>
																<tbody className="table-border-bottom-0">
																	{deductions.map((val,index) => (
																		<tr key={'ded'+index}>
																			<td align="center">
																				<button type="button" className="btn btn-sm" onClick={() => removeDeduction(index)}><i className='bx bx-trash'></i></button>
																			</td>
																			<td>{index+1}</td>
																			<td align="right">{Common.displayPercent(val.deduction_per)}%</td>
																			<td align="right">{Common.displayPrice(val.deduction_amt)}</td>
																			<td>{val.deduction_type}</td>
																			<td>{val.deduction_subtype}</td>
																			{/* <td>{val.deduction_remarks}</td> */}
																		</tr>
																	))}
																</tbody>
															</table>
														</div>
														</>}
													</div>
                                                    </>)}
												</div>
                                                <br></br>
                                                <div className="row">
                                                    <div className="col-sm-7 text-right">
                                                        <a href="/collection-listing" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                        &nbsp;&nbsp;
                                                            <button type='button' onClick={()=>{setPaymentDiv(!payment_div);}} className={"btn btn-sm " + (payment_div ? 'btn-danger':'btn-success')} > {payment_div ? <><i className='bx bx-minus'></i></> : <><i className='bx bx-plus'></i></>} Payment</button>&nbsp;&nbsp;
															{payment_div && (<>
																<button type="button" onClick={()=>{deductionModal();}} className={"btn btn-sm btn-success"}><i className='bx bx-plus'></i> Deduction</button>
															</>)}
                                                        &nbsp;&nbsp;
														{bank_cash_account === 1 && (<>
															<button type='submit' className="btn btn-sm btn-primary"><i className='bx bx-save' ></i> Save</button>
														</>)}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal show={commonModel} size='sm' aria-labelledby="Common-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header>
			<Modal.Title>{commonModelHeading}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{commonModelBody}
			</Modal.Body>
			<Modal.Footer>
				{commonModelFooter}
			</Modal.Footer>
		</Modal>
		<Modal size='md' show={deduction_model} onHide={()=>setDeductionModel(false)} aria-labelledby="deduction-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<div className="col-4 modal-mobile-view">
					<Modal.Title id="deduction-modal">Deduction</Modal.Title>
				</div>
				<div className="col text-right">
					<span id="payModalInfo" className="badge bg-warning">
						Total Taxable Value: {Common.displayPrice(taxable_amt)}
					</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className='col-sm-12'>
						<div className="alert alert-secondary mt-3">
							<div className="row">
								<h4>Add Deduction</h4>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="row">
										<label className="col-sm-5 col-form-label" htmlFor="frmSaleDedPer">Percent <span className="text-danger">*</span></label>
										<div className="col-sm-7">
											<div className="input-group input-group-merge">
												<span className="input-group-text"><i className='bx bxs-offer'></i></span>
												<input type="text" className="form-control" id="frmSaleDedPer" placeholder="0.00" value={deduction_per} onChange={(e) => calcDeduction('per',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
											</div>
										</div>
									</div>
									<div className="row">
										<small>(Deduction at source and on base value)</small>
									</div>
								</div>
								<div className="col-6">
									<div className="row">
										<label className="col-sm-4 col-form-label" htmlFor="frmSaleDedAmt">Amount <span className="text-danger">*</span></label>
										<div className="col-sm-8">
											<div className="input-group input-group-merge">
												<span className="input-group-text"><i className='bx bx-rupee'></i></span>
												<input type="text" className="form-control" id="frmSaleDedAmt" placeholder="0.00" value={deduction_amt} onChange={(e) => calcDeduction('amt',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-4 col-form-label" htmlFor="frmSaleDedType">Deduction Type <span className="text-danger">*</span></label>
								<div className="col-8">
									<Typeahead clearButton ref={frmSaleDedType} id="frmSaleDedType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDeductionTypeSelect(e)} placeholder="Choose Deduction Type"
										options={deductionMasterList}
										renderMenuItemChildren={(option: Item,props) => (
											<Highlighter search={props.text}>
												{option.label}
											</Highlighter>
										)}
									/>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-4 col-form-label" htmlFor="frmSaleDedRemarks">Deduction Subtype</label>
								<div className="col-8">
									{/* <input type="text" className="form-control" id="frmSaleDedRemarks" placeholder="Deduction Remarks" value={deduction_remarks} onChange={(e) => setDeductionRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/> */}
									<Typeahead clearButton id="frmSaleDedSubtype" allowNew newSelectionPrefix="Add: " ref={frmSaleDedSubtype} disabled={isDeductionMasterSelected === false ? true : false} labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDeductionSubTypeSelect(e)} placeholder="Choose Deduction Sub Type"
										options={deductionSubtypeMasterList}
										renderMenuItemChildren={(option: Item,props) => (
											<Highlighter search={props.text}>
												{option.label}
											</Highlighter>
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="text-center mt-3">
					<button type="button" className="btn btn-sm btn-primary ms-1" onClick={addDeduction}><span className="tf-icons bx bx-save"></span>&nbsp;Add</button>
				</div>
			</Modal.Body>
		</Modal>
        </>
    );
}