const deductions = [
  {
    label: 'Tax Deducted at Source (TDS)',
    value: 'Tax Deducted at Source (TDS)'
  },
  {
    label: 'Professional Tax',
    value: 'Professional Tax'
  },
  {
    label: 'Withholding Tax on Royalties & Technical fees',
    value: 'Withholding Tax on Royalties & Technical fees'
  },
  /*{
	label: 'Bank/Payment Gateway charges',
	value: 'Bank/Payment Gateway charges'
  },
  {
	label: 'Delivery charges',
	value: 'Delivery charges'
  },*/
  {
    label: 'Others',
    value: 'Others'
  }
];
export default deductions;