import React,{useState,useCallback}  from "react";
import Layout from './Layout';
import MartLayout from './MartLayout';
import NavBar from './NavBar';
import MartNavBar from './MartNavBar';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import styled from 'styled-components';
import * as Common from '../common/ImportUserEssentials';
import Modal from 'react-bootstrap/Modal';
import * as DTE from '../common/ImportDataTableEssentials';
import Select from "react-select";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import paymentModes from '../masters/PaymentModes.js';
// import deductionList from '../masters/Deductions.js';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Button from 'react-bootstrap/Button';

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;
		
export default function CollectionListing(props){
    const { state } = Common.useLocation();
	const [totalDataCnt,setTotalDataCount] = useState({'Pending':'','Collected':'','Advance':'','Other Income':''});
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
	const [DTpending, setDTPending] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
    const [successMsg,setSucces]                = useState('');
    const [errorMessage,setError]               = useState('');
    const [showInvoiceDiv,setshowInvoiceDiv]    = useState(true);
    const [showIrnDiv,setshowIrnDiv]            = useState(false);
    const [switchChecked, setChecked]           = useState(false);
    const [activeCancel, setActiveCancel]       = useState(false);
    const [dueDate, setDueDate]                 = useState('');
    const [dueDateModal, setDueDateModal]       = useState(false);
    const [selectedSaleId, setSelectedSaleId]   = useState('');
    let collection_type                       = '';
    if (props.referrer === 'fynmart') {
        collection_type                   = ['Pending', 'Collected', 'Advance'];
    }else{
        collection_type                   = ['Pending', 'Collected', 'Advance', 'Other Income'];
    }
    const CollectionTypes = collection_type;
    let tabType = ''
    if (state !== null) {
        tabType = CollectionTypes[state.tab_type];
    } else {
        tabType = CollectionTypes[0]
    }
    // const [activeTab, setPendingCollection]     = useState(CollectionTypes[0]);
    const [activeTab, setPendingCollection]     = useState(tabType);
    const [data, setData]                       = useState([]);
    const [exportBtnClass,setExportBtnClass]    = useState('d-none');
	const [dedModalShow,setDedModalShow]        = useState(false);
	const [temp_deduction,setTempDeduction]     = useState([]);
    const navigate = Common.useNavigate();
	const [totCustomer,setTotCustomer] = React.useState({'Pending':'-','Collected':'-','Advance':'-','Other Income':'-'});
	const [totInvAmt,setTotInvAmt] = React.useState({'Pending':'-','Collected':'-','Advance':'-','Other Income':'-'});
    const [collectionCancelShow,setCollectionCancelShow] = React.useState(false);
	const [collectionCancelRemarks,setCollectionCancelRemarks] = React.useState('');
	const [collectionId,setCollectionId] = React.useState('');
	const [receiptNo,setReceiptNo] = React.useState('');
	const [cancelCheckShow, setCancelCheckShow] = useState(false);
	const [cancelCheckMessage, setCancelCheckMessage] = useState('');
    const [otherIncomeCancelShow,setOtherIncomeCancelShow] = React.useState(false);
	const [paymentModalShow,setPaymentModalShow] = useState(false);
	const [paymentDetails,setPaymentDetails] 	 = useState([]);
	const [paymentType,setPaymentType] 	 = useState('');
	const [oid,setOid] = useState('');
	const [payment_date,setPaymentDate]             = useState(new Date());
	const [trans_ref,setTransRef]                   = useState('');
	const [payment_remarks,setPaymentRemarks]       = useState('');
	const [bankList, setBankList] 					= useState([]);
	const [bankAccount,setBankAccount] 				= useState(0);
	const [defaultPayee, setDefaultPayee] 			= useState([]);
    const [bank_cash_account,setBankCashAccount] 	= useState(1);
    const [payment_mode, setPaymentMode]            = useState('UPI/QR');
    const [showCollectionDiv,setShowCollectionDiv]      = useState(false);
    const [ShowPendingDiv,setShowPendingDiv]            = useState(true);
    const [showAdvancedDiv,setShowAdvancedDiv]          = useState(false);
    const [showOtherIncomeDiv,setOtherIncomeDiv]        = useState(false);
    
	const [deduction_div,setDeductionDiv]           = useState(false);
	const [deduction_amt,setDeductionAmt]           = useState('');
	const [deduction_per,setDeductionPer]           = useState('');
	// const [deduction_type,setDeductionType]         = useState('');
	const [deductions,setDeductions]                = useState([]);
	// const [deduction_remarks,setDeductionRemarks]   = useState('');
	const [total_deduction,setTotalDeduction]       = useState('0.00');
	// const [final_total,setFinalTotal]               = useState('0.00');
	const frmSaleDedType                            = React.createRef();
    const frmSaleDedSubtype                         = React.createRef();
	const [taxable_amt,setTaxableAmt]               = useState('');

    //common Model	
	const [commonModel, setCommonModel]    	  		 = useState(false);
	const [commonModelHeading,setCommonModelHeading] = useState('');
	const [commonModelBody,setCommonModelBody] 		 = useState('');
	const [commonModelFooter,setCommonModelFooter] 	 = useState('');
	const [defaultDuePriority,setDefaultDuePriority] 	 = useState([{'value': 'All', 'label': 'Dues Priority (All)','digits':''}]);
    const [priorityType, setPriorityType]                = useState('All');
    const [deductionMasterList, setDeductionMasterList]       = useState([]);
	// const [defaultDeductionMasterSelected, setDefaultDeductionMasterSelected] = useState([]);
	const [deductionMaster, setDeductionMaster]               = useState('');
	const [deductionMasterLabel, setDeductionMasterLabel]     = useState('')

	const [deductionSubtypeMasterList, setDeductionSubtypeMasterList] = useState([])
	// const [defaultDeductionSubtypeMasterSelected, setDefaultDeductionSubtypeMasterSelected] = useState([])
	const [deductionSubtypeMaster, setDeductionSubtypeMaster] = useState('')
	const [deductionSubtypeMasterLabel, setDeductionSubtypeMasterLabel] = useState('');
	const [isDeductionMasterSelected, setIsDeductionMasterSelected] = useState(false);
    const [sortOrder,setSortOrder]                                  = useState('');
	
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
    const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');

    const [PAdates, setPADates] = React.useState({
		PAstart: moment().subtract(1, 'years'),
		PAend: moment(),
    });
    const { PAstart, PAend } = PAdates;
	const PAlabel = PAstart.format('DD-MM-YYYY') + ' ~ ' + PAend.format('DD-MM-YYYY');
	
    React.useEffect(() => {
        if( props.referrer === "fynone" ){
            document.title = 'Collection Listing | '+props.site_name;
		}
		if( props.referrer === "fynmart" ){
			document.title = 'Mart Collection Listing | '+props.site_name;
		}

        if( !localStorage.getItem('fo_loggedin') ){
            navigate("/");
        }
        if (state !== null) {
            // setPendingCollection(CollectionTypes[state.tab_type])
            if (state.tab_type === 2) {
                setShowPendingDiv(false);
                setShowCollectionDiv(false);
                setShowAdvancedDiv(true);
                setOtherIncomeDiv(false);
            } else if (state.tab_type === 3) {
                setShowPendingDiv(false);
                setShowCollectionDiv(false);
                setShowAdvancedDiv(false);
                setOtherIncomeDiv(true);
            }
        }
        Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
                fetchCollectionData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType);
                setBankList(response.data.items);
                setDefaultPayee(response.data.defaultPayee);
                if (response.data.defaultPayee.length > 0) {
                    setBankAccount(response.data.defaultPayee[0].value);
                }
                
			}
		}).catch(error => console.log(error));

        // deduction master
		Common.axios({
			method: 'get',
			url:props.url+'ajax/getDeductionMaster',
		}).then(function (response) {
			if(response.data.success){
				setDeductionMasterList(response.data.data);
				// setDefaultDeductionMasterSelected([response.data.data[0]]);
			}
		}).catch(error => console.log(error));
    },[props,dt_load,filterText,dates,PAdates]);
	const setDatePicker = (start, end) => {
        setDates({ start, end });
        setDtLoad(!dt_load);
    }
    const PAsetDatePicker = (PAstart, PAend) => {
        setPADates({ PAstart, PAend });
    }
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		fetchCollectionData(switchChecked,activeTab,page,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType)
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		fetchCollectionData(switchChecked,activeTab,page,newPerPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType)
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
        setSortOrder(btoa(column.sortField+'####'+sortDirection))
		fetchCollectionData(switchChecked,activeTab,1,perPage,dates,filterText,btoa(column.sortField+'####'+sortDirection),activeCancel,PAdates,priorityType);
	};
    const fetchCollectionData = useCallback(async (switchChecked, activeTab,page,perPage,dates,filterText='',sort='',activeCancel,PAdates,type='All') => {
        let switch_level = (switchChecked)?'document':'invoice';
        let active_cancel = (activeCancel)?'C':'A';
        Common.axios({
            method: 'POST',
            url:props.url+'collection/listing',
            data:{switch_level:switch_level,active_tab:activeTab,pid:localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText,'dates':dates,'PAdates':PAdates,'active_cancel':active_cancel,'p_type':type}
        }).then(function (response) {
            setExportBtnClass('d-none');
            if(response.data.success){
                setData(response.data.data);
                setTotalRows(response.data.total);
                setTotalDataCount(prevState => ({
                    ...prevState,
                    [activeTab]: response.data.total
                }));
                setDTPending(false);
                if(response.data.data.length > 0){
                    setExportBtnClass('d-block');
                }
                setTotCustomer(prevState => ({
                ...prevState,
                    [activeTab]: response.data.tabInfo.customer
                }));
                setTotInvAmt(prevState => ({
                ...prevState,
                    [activeTab]: response.data.tabInfo.amount
                }));
            }
        }).catch(error => console.log(error));
        
     }, []);
    const columns = [
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                 <div>
                    <button className="btn p-1 btn-sm btn-light" onClick={(e) => handleEdit(row.collection_id)}><i className="bx bx-edit"></i> </button>
                    <button type="button" className="btn p-1 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-share-alt"></i>
                    </button>
                    <div className="dropdown-menu" >
                        <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.collection_id,'PI',row.inv_num)}><i className="bx bxs-download"></i> Download</button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.collection_id,1,'PI')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.collection_id,2,'PI')}><i className="bx bx-envelope"></i> Share via Email </button>
                    </div>
                </div>
            ),
        },
        {
            name: 'Priority',
            selector: row =>  parseInt(row.days) < 0 ? <Common.OverlayTrigger overlay={Common.popoverContent('','Over Due')}><i style={{'color' : 'red','fontSize':'1.5rem'}} className='bx bxs-alarm'></i></Common.OverlayTrigger> : parseInt(row.days) >= 0 && parseInt(row.days) <= 7 ? <Common.OverlayTrigger overlay={Common.popoverContent('','Immediate due')}><i style={{'color' : 'orange','fontSize': '1.5rem'}} className='bx bxs-alarm'></i></Common.OverlayTrigger> : parseInt(row.days) > 7 ? <Common.OverlayTrigger overlay={Common.popoverContent('','Not Due Yet')}><i style={{'color' : '#cfcf7e','fontSize':'1.5rem'}} className='bx bxs-alarm'></i></Common.OverlayTrigger> :<></>,
            sortable: false,
			sortField: 'days'
        },
        {
            name: 'Customer Name',
            selector: row => row.client_name,
            sortable: false,
			sortField: 'client_name'
        },
        {
            name: 'Phone Number',
            selector: row => row.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Invoice Amount',
            selector: row => row.amount,
            sortable: true,
			sortField: 'amount'
        },
        {
            name: 'Invoice Number',
            selector: row => row.inv_num ,
            sortable: true,
			sortField: 'sl.inv_no'
        },
        {
            name: 'Invoice Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'sl.inv_date'
        },
        {
            name: 'Due Amount(₹)',
            selector: row => row.due_amount,
            sortable: true,
			sortField: 'sl.due_amt'
        },
        {
            name: 'Due Date',
            selector: row => row.days === null ? <button style={{"display":"contents"}} className="btn btn-link" onClick={(e)=>handleDueDate(row.sale_id)}><i className='bx bx-pencil'></i></button> : row.due_date,
            sortable: true,
			sortField: 'sl.due_date'
        },
        {
            name: 'Paid Amount(₹)',
            selector: row => row.paid_amount,
            sortable: false,
			sortField: 'cl.credit_amount'
        },
    ];
    const docColumns = [
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                <div>
                    <button className="btn btn-sm btn-light" onClick={(e) => handleCustomerEdit(row.customer_id)}><i className="bx bx-edit"></i> </button>
                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false"><i className="bx bx-share-alt"></i></button>
                    <div className="dropdown-menu" >
                        <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.customer_id,'PC',row.client_name)}><i className="bx bxs-download"></i> Download</button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.customer_id,1,'PC')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.customer_id,2,'PC')}><i className="bx bx-envelope"></i> Share via Email </button>
                    </div>
                </div>
            ),
        },
        {
            name: 'Customer Name',
            selector: rows => rows.client_name,
            sortable: true,
			sortField: 'cu.party_name'
        },
        {
            name: 'Phone Number',
            selector: rows => rows.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Due Amount',
            selector: rows => rows.due_amount,
            sortable: true,
			sortField: 'sl.due_amt'
        },
        {
            name: 'Invoice Count',
            selector: rows => rows.inv_count,
            sortable: true,
			sortField: 'inv_count'
        }
    ];
    const handleEdit =(val) => {
        let edit_url = (props.referrer === 'fynone')?'edit-collection':'mart-collection-edit';
        navigate('/'+edit_url,
        {
            state: {
                cl_id: val
            }
        });
    }
    const handleCustomerEdit =(val) => {
        let edit_url = (props.referrer === 'fynone')?'edit-customer-collection':'mart-collection-customer-edit';
        navigate('/'+edit_url,
        {
            state: {
                cu_id: val
            }
        });
    }
    const handleDueDate = (sale_id) => {
        setDueDateModal(true);
        setSelectedSaleId(sale_id);
    }
    const handleShareContent =(val,status,type) => {
        let url = props.url+'collection/shareContent';
        let pid = localStorage.getItem('fo_profileid');
        Common.axios({
            method: 'POST',
            url:url,
            data:{cid:val,status:status,type:type,pid:pid},
        }).then(function (response) {
            if(response.data.success){
                if(status === 1){
                    window.open("https://api.whatsapp.com/send?text="+response.data.data.body,"_blank");
                }else{
                    window.open("mailto:?subject="+response.data.data.subject+"&body="+response.data.data.body,"_blank");
                }
            }
        }).catch(error => console.log(error));
    }
    const handleShareOtherIncomeContent =(val,status) => {
        let url = props.url+'collection/otherIncomeShareContent';
        let pid = localStorage.getItem('fo_profileid');
        Common.axios({
            method: 'POST',
            url:url,
            data:{oid:val,status:status,pid:pid},
        }).then(function (response) {
            if(response.data.success){
                if(status === 1){
                    window.open("https://api.whatsapp.com/send?text="+response.data.data.body,"_blank");
                }else{
                    window.open("mailto:?subject="+response.data.data.subject+"&body="+response.data.data.body,"_blank");
                }
            }
        }).catch(error => console.log(error));
    }
    
    const handleDownloadPdf =(val,type,invNum) => { 
		Common.axios({
			method: 'POST',
			url: props.url+'collection/collectionDownloadPDF',
			data:{cid:val,type:type,pid:localStorage.getItem('fo_profileid')},
			// responseType: 'blob',
		}).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
				/* const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
                let tabType = '';
                switch(type){
                    case 'PC':
                        tabType = 'Pending_';
                        break;
                    case 'CC':
                        tabType = 'Collected_';
                        break;
                    case 'AC':
                        tabType = 'Advance_';
                        break;
                    default:
                        tabType = '';

                }
                let currentdate = new Date();
				let timesec = currentdate.getTime().toString().substring(2, 6);
				let fileName = currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() + "_" + timesec;
                link.setAttribute('download', invNum+'_'+tabType+fileName+'.pdf');
				document.body.appendChild(link);
				link.click(); */
			}
			else{
				setError(response.data.message);
			}
		}).catch(error => console.log(error));
    }
    const handleDownloadOtherIncomePdf =(val) => { 
		Common.axios({
			method: 'POST',
			url: props.url+'collection/otherIncomePDF',
			data:{oid:val,pid:localStorage.getItem('fo_profileid')},
		}).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
			}
			else{
				setError(response.data.message);
			}
		}).catch(error => console.log(error));
    }

    const collectionColumns = [
        {
            name: 'Customer Name',
            selector: row => row.client_name,
            sortable: false,
			sortField: 'client_name'
        },
        {
            name: 'Phone Number',
            selector: row => row.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Invoice Number',
            selector: row => row.inv_num ,
            sortable: true,
			sortField: 'sl.inv_no'
        },
        {
            name: 'Invoice Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'sl.inv_date'
        },
        {
            name: 'Paid Amount',
            selector: row => row.paid_amount,
            sortable: true,
			sortField: 'cl.credit_amount'
        },
        {
            name: 'Payment Receipt No',
            selector: row => row.receipt_no,
            sortable: true,
			sortField: 'cl.receipt_no'
        },
        {
            name: 'Payment Mode',
            selector: row => row.payment_mode,
            sortable: false,
			sortField: 'payment_mode'
        },
        {
            name: 'Total Deductions',
            cell: (row) => (
                <button className="btn btn-link text-decoration-underline" onClick={() => deductionModal(row.collection_id)}>
                  {row.deduction_amount}
                </button>
              ),
        },
        {
            name: 'Received On',
            selector: row => row.payment_received_on,
            sortable: true,
			sortField: 'cl.payment_date'
        },
        {
            name: 'Transaction Ref.',
            selector: row => row.trans_ref,
            sortable: false,
			sortField: 'trans_ref'
        },
        {
            name: 'Remarks',
            selector: row => row.payment_remarks,
            sortable: false,
			sortField: 'payment_remarks'
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
            sortField: 'created_on'
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
            sortField: 'cl.created_on'
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
            sortField: 'updated_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
            sortField: 'cl.updated_on'
        }
    ];
    
    if( activeCancel ) {
		collectionColumns.push(
            {
                name: 'Cancel Reason',
                selector: row => row.cancel_reason,
                sortable: true,
                sortField: 'cancel_reason'
            },
            {
                name: 'Cancel Date',
                selector: row => row.cancel_date,
                sortable: true,
                sortField: 'cancel_date'
            }
        );
	}
    if(!activeCancel){
        collectionColumns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => (
                    <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu" >
                        <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.collection_id,'CI',row.receipt_no)}><i className="bx bxs-download"></i> Download</button>
                        <button className="dropdown-item" onClick={(e) => handleCancelCollection(row)}><i className='bx bx-message-square-x'></i> Cancel Collection</button>
                        </div>
                        <button type="button" className="btn p-1 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bx bx-share-alt"></i>
                        </button>
                        <div className="dropdown-menu" >
                            <button className="dropdown-item" onClick={(e) => handleShareContent(row.collection_id,1,'CI')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                            <button className="dropdown-item" onClick={(e) => handleShareContent(row.collection_id,2,'CI')}><i className="bx bx-envelope"></i> Share via Email </button>
                        </div>
                    </div>
                ),
            },  
        );
    }

    const collectionDocColumns = [
        {
            name: 'Customer Name',
            selector: rows => rows.client_name,
            sortable: true,
			sortField: 'cu.party_name'
        },
        {
            name: 'Phone Number',
            selector: rows => rows.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Paid Amount',
            selector: rows => rows.paid_amount,
            sortable: true,
			sortField: 'paid_amount'
        },
        {
            name: 'Invoice Count',
            selector: rows => rows.inv_count,
            sortable: true,
			sortField: 'inv_count'
        }
    ];
    if(!activeCancel){
        collectionDocColumns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => (
                    <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-share-alt"></i>
                        </button>
                        <div className="dropdown-menu" >
                        <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.customer_id,'CC',row.client_name)}><i className="bx bxs-download"></i> Download</button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.customer_id,1,'CC')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.customer_id,2,'CC')}><i className="bx bx-envelope"></i> Share via Email </button>
                        </div>
                    </div>
                ),
            }
        );
    }
    const advancedColumns = [
        {
            name: 'Customer Name',
            selector: row => row.client_name,
            sortable: false,
			sortField: 'client_name'
        },
        {
            name: 'Phone Number',
            selector: row => row.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Advance Amount',
            selector: row => row.advance_amount,
            sortable: true,
			sortField: 'cl.advance_amount'
        },
        {
            name: 'Advance Receipt No',
            selector: row => row.receipt_no,
            sortable: true,
			sortField: 'cl.receipt_no'
        },
        {
            name: 'Payment Mode',
            selector: row => row.payment_mode,
            sortable: false,
			sortField: 'payment_mode'
        },
        {
            name: 'Received On',
            selector: row => row.payment_received_on,
            sortable: true,
			sortField: 'cl.payment_date'
        },
        {
            name: 'Transaction Ref.',
            selector: row => row.trans_ref,
            sortable: false,
			sortField: 'trans_ref'
        },
        {
            name: 'Remarks',
            selector: row => row.payment_remarks,
            sortable: false,
			sortField: 'remarks'
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
            sortField: 'created_on'
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
            sortField: 'cl.created_on'
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
            sortField: 'updated_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
            sortField: 'cl.updated_on'
        }
    ];
    if( activeCancel ) {
		advancedColumns.push(
            {
                name: 'Cancel Reason',
                selector: row => row.cancel_reason,
                sortable: false,
                sortField: 'cancel_reason'
            },
            {
                name: 'Cancel Date',
                selector: row => row.cancel_date,
                sortable: false,
                sortField: 'cancel_date'
            }
        );
	}
    if(!activeCancel){
        advancedColumns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => (
                    <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu" >
                        <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.collection_id,'AR',row.receipt_no)}><i className="bx bxs-download"></i> Download</button>
                        <button className="dropdown-item" onClick={(e) => handleCancelCollection(row)}><i className='bx bx-message-square-x'></i> Cancel Advance</button>
                        </div>
                        <button type="button" className="btn p-1 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bx bx-share-alt"></i>
                        </button>
                        <div className="dropdown-menu" >
                            <button className="dropdown-item" onClick={(e) => handleShareContent(row.collection_id,1,'AR')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                            <button className="dropdown-item" onClick={(e) => handleShareContent(row.collection_id,2,'AR')}><i className="bx bx-envelope"></i> Share via Email </button>
                        </div>
                    </div>
                ),
            }
        );
    }
    const advancedDocColumns = [
        {
            name: 'Customer Name',
            selector: row => row.client_name,
            sortable: true,
			sortField: 'cu.party_name'
        },
        {
            name: 'Phone Number',
            selector: row => row.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Advance Amount',
            selector: row => row.advance_amount,
            sortable: true,
			sortField: 'advance_amount'
        },
        {
            name: 'Receipt Count',
            selector: row => row.receipt_count,
            sortable: false,
			sortField: 'receipt_count'
        },
    ];
    if(!activeCancel){
        advancedDocColumns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => (
                    <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-share-alt"></i>
                        </button>
                        <div className="dropdown-menu" >
                        <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.customer_id,'AC',row.client_name)}><i className="bx bxs-download"></i> Download</button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.customer_id,1,'AC')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.customer_id,2,'AC')}><i className="bx bx-envelope"></i> Share via Email </button>
                        </div>
                    </div>
                ),
            }
        );
    }
    const otherIncomeColumns = [
        {
            name: 'Status',
            selector: row => row.payment_amt,
            sortable: false,
            sortField: 'income_type',
            cell: (row) => (
                row.payment_amt === null ? <Common.OverlayTrigger overlay={Common.popoverContent('','Payment Due')}><i className='bx bxs-calendar-exclamation' style={{'color': 'red'}}></i></Common.OverlayTrigger> : ''
            )
        },
        {
            name: 'Income Type',
            selector: row => row.income_type,
            sortable: false,
			sortField: 'income_type'
        },
        {
            name: 'Income Sub Type',
            selector: row => row.income_sub_type,
            sortable: false,
			sortField: 'income_sub_type'
        },
        {
            name: 'Voucher Number',
            selector: row => row.voucher_no,
            sortable: true,
			sortField: 'oi.voucher_no'
        },
        {
            name: 'Voucher Date',
            selector: row => row.voucher_date,
            sortable: true,
			sortField: 'oi.voucher_date'
        },
        {
            name: 'Voucher Remarks',
            selector: row => row.voucher_remarks,
            sortable: false,
			sortField: 'voucher_remarks'
        },
        {
            name: 'Taxable Amount',
            selector: row => row.taxable_amt,
            sortable: true,
			sortField: 'oi.taxable_amt'
        },
        {
            name: 'GST Amount',
            selector: row => row.gst_amt,
            sortable: true,
			sortField: 'oi.gst_amt'
        },
        {
            name: 'Total Amount',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'oi.total_amt'
        },
        {
            name: 'Paid Amount',
            selector: row => row.payment_amt,
            sortable: false,
			center: true,
			cell: (row) => (
				( row.payment_amt !== null ) ? <button className="btn btn-link text-decoration-underline" onClick={() => paymentModal(row,1)}>{row.payment_amt}</button> : ((!activeCancel)?<Button variant="primary" size="sm" onClick={(e)=>paymentModal(row,2)}>Pay</Button>:'')
			)
        },
        {
            name: 'Deduction Amount',
            selector: row => row.deduction_amt,
            sortable: false,
			center: true,
			cell: (row) => (
				( row.deduction_amt !== null && row.deduction_amt !== '0.00') ? <button className="btn btn-link text-decoration-underline" onClick={() => deductionModal(row.id,1)}>{row.deduction_amt}</button> :''
			)
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
            sortField: 'created_on'
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
            sortField: 'oi.created_on'
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
            sortField: 'updated_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
            sortField: 'oi.updated_on'
        }
    ];
    if( activeCancel ) {
		otherIncomeColumns.push(
            {
                name: 'Cancel Reason',
                selector: row => row.cancel_reason,
                sortable: true,
                sortField: 'cancel_reason'
            },
            {
                name: 'Cancel Date',
                selector: row => row.cancel_date,
                sortable: true,
                sortField: 'cancel_date'
            }
        );
	}
    if(!activeCancel){
        otherIncomeColumns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => (
                    <div className="dropdown">
                        <button type="button" className="btn me-2 p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu" >
                            <button className="dropdown-item" onClick={(e) => handleDownloadOtherIncomePdf(row.id)}><i className="bx bxs-download"></i> Download Voucher</button>
                            <button className="dropdown-item" onClick={(e) => handleCancelOtherIncome(row)}><i className='bx bx-message-square-x'></i> Cancel Other Income</button>
                        </div>
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-share-alt"></i>
                        </button>
                        <div className="dropdown-menu" >
                            <button className="dropdown-item" onClick={(e) => handleShareOtherIncomeContent(row.id,1)}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                            <button className="dropdown-item" onClick={(e) => handleShareOtherIncomeContent(row.id,2)}><i className="bx bx-envelope"></i> Share via Email </button>
                        </div>
                    </div>
                ),
            }
        );
    }
    const handleExportCSV = (e)=>{
        e.preventDefault();
        let switch_level = (switchChecked)?'document':'invoice';
        let active_cancel = (activeCancel)?'C':'A';
        Common.axios({
            method: 'post',
            url:props.url+'collection/download_csv',
			data:{switch_level:switch_level,active_tab:activeTab,pid:localStorage.getItem('fo_profileid'),'search':filterText,'dates':dates,'PAdates':PAdates,'active_cancel':active_cancel,'p_type':priorityType},
            // responseType: 'blob',
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Collection-${activeTab}-${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click(); */
			} else{
                setError(response.data.message);
            }
		    setTimeout(() => {
				setError('');
				setSucces('');
			}, 5000);
        }).catch(error => console.log(error));
    }
    const handleCancelCollection = (details)=>{
        setCollectionId(details.collection_id);
        setReceiptNo(details.receipt_no);
        setCollectionCancelRemarks('');
        setCollectionCancelShow(true);
    }
    const handleCancelCln = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['collectionId'] = collectionId;
		data['pid']          = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'collection/cancelCollection',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setCollectionCancelShow(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
					fetchCollectionData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType);
					return false;
                }                
                else if(response.data.success === 2){
                    setCancelCheckMessage(response.data.message);
				    setCancelCheckShow(true);
					return false;
                }
                else{
                    Common.toast.error(response.data.message);
                }
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }

    const handleCancelOtherIncome = (details)=>{        
        setOid(details.id);
        setReceiptNo(details.voucher_no);
        setCollectionCancelRemarks('');
        Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'collection/checkCancelEMI',
            data: {oid:details.id,pid:localStorage.getItem('fo_profileid')},
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
                setOtherIncomeCancelShow(true);
                return false;
			}
            else{
                setCommonModel(true);
                setCommonModelHeading('Other Income');
                setCommonModelBody(<div className="row"><div className="col-sm-12"><p>{response.data.message}</p></div></div>);
                setCommonModelFooter(<Button variant="danger" onClick={()=>setCommonModel(false)}>
				Close
			</Button>);
                return false;
            }
        }).catch(error => console.log(error));
    }
    const handleCancelOtherIn = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['vid'] = oid;
		data['pid'] = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'collection/cancelOtherIncome',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
                setOtherIncomeCancelShow(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
					fetchCollectionData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType);
					return false;
                }
			}
            else{
                Common.toast.error(response.data.message);
                return false;
            }
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }

    /* const [showCollectionDiv,setShowCollectionDiv]      = useState(false);
    const [ShowPendingDiv,setShowPendingDiv]            = useState(true);
    const [showAdvancedDiv,setShowAdvancedDiv]          = useState(false);
    const [showOtherIncomeDiv,setOtherIncomeDiv]        = useState(false); */
    function TabGroup() {
        const handleTab = penColChecked => {
            setPendingCollection(penColChecked);
            setActiveCancel(false);
            if (state !== null) {
                state.tab_type = ''
                window.history.replaceState({}, '')
            }
            setDefaultDuePriority([{'value': 'All', 'label': 'Dues Priority (All)','digits':''}]);
            setPriorityType('All')
            setSortOrder('')
            fetchCollectionData(switchChecked,penColChecked,1,perPage,dates,filterText,'',false,PAdates,'All')
            if(penColChecked === "Collected"){
                setShowCollectionDiv(true);
                setShowPendingDiv(false);
                setShowAdvancedDiv(false);
                setOtherIncomeDiv(false);
            }else if(penColChecked === "Pending"){
                setShowCollectionDiv(false);
                setShowPendingDiv(true);
                setShowAdvancedDiv(false);
                setOtherIncomeDiv(false);
            }
            else if(penColChecked === "Advance"){
                setShowPendingDiv(false);
                setShowCollectionDiv(false);
                setShowAdvancedDiv(true);
                setOtherIncomeDiv(false);
            }
            else{
                setShowPendingDiv(false);
                setShowCollectionDiv(false);
                setShowAdvancedDiv(false);
                setOtherIncomeDiv(true);
            }
        };
        return (
            <>
            <ButtonGroup className="tabWithInfo">
                {CollectionTypes.map(type => (
                <Tab
                    key={type}
                    active={activeTab === type}
                    onClick={() => handleTab(type)}
                >
                    {(totalDataCnt[type]!=='') ? type + ' ('+totalDataCnt[type]+')' : type}
                    <div className="tabAdditionalInfo">
                        {(type !== 'Other Income')?'Cust.#'+ totCustomer[type] + '|':''} Tot. Amt.(₹) {Common.numToWords(totInvAmt[type])}
                    </div>
                </Tab>
                ))}
            </ButtonGroup>
            </>
        );
    }
    const handleChange = nextChecked => {
        setChecked(nextChecked);
        fetchCollectionData(nextChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType)
        if(nextChecked){
            setshowIrnDiv(true);
            setshowInvoiceDiv(false);
        }else{
            setshowInvoiceDiv(true);
            setshowIrnDiv(false);
        }
    };
    const handleActiveCancel = nextChecked => {
        setActiveCancel(nextChecked);
        fetchCollectionData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,nextChecked,PAdates,priorityType);
    };
    const getDueDatePriorities = (e) => {
        let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		if(value){
			setPriorityType(value);
			setDefaultDuePriority([{'value':value,'label':label,'digits':''}]);
            fetchCollectionData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,value);
		}else{
            setBankAccount('');
			setDefaultDuePriority([]);
            fetchCollectionData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,'');
		}
    }

    const deductionModal = (cl_id,type = 0) => {
        let url = props.url+'collection/getDeduction/'+cl_id;
        if(type === 1){
            url = props.url+'collection/getOtherIncomeDeduction/'+cl_id;
        }
        Common.axios({
            method: 'get',
            url: url,
            // data:{cl_id:cl_id}
        }).then(function (response) {
            if(response.data.success){
                setTempDeduction(response.data.data);
                setDedModalShow(true);
            }
        }).catch(error => console.log(error));
    }
    const paymentModal = (details,type) => {
        setPaymentType(type);
        setPaymentDetails(details);
        setOid(details.id);
        setTaxableAmt(details.taxable_amt);
        setPaymentModalShow(true);
        setDeductions([]);
        setDeductionDiv(false)
    }
    const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setBankAccount(value);
		// setDefaultPayee('');
        if (value === '') {
			setDefaultPayee([]);
		} else {
			bankList.map((bank,i) => {
				if (bank.value === value) {
					setDefaultPayee([bank]);
				}
			});
		}
	}
    const setPaymentModeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setPaymentMode(value);
		if(value === 'Cash'){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/'+value,
			}).then(function (response) {
				if(response.data.success){
					if(response.data.data !== ''){
						setBankCashAccount(1);
						setDefaultPayee(response.data.data);
						setBankAccount(response.data.data[0].value);
                        setBankList(response.data.data);
					}else{
						setBankCashAccount(0);
						setDefaultPayee([]);
						setBankAccount(0);
					}
				}
			}).catch(error => console.log(error));
		}else{
			setBankCashAccount(1);
			setDefaultPayee([]);
            setListOfBanks();
		}
	}
    const setListOfBanks = () => {
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				// setDefaultPayee(response.data.defaultPayee);
				// if (response.data.defaultPayee.length > 0) {
				// 	setBankAccount(response.data.defaultPayee[0].value);
				// }
			}
		}).catch(error => console.log(error));
	}
    const handlePayment =(e) => {
		e.preventDefault();		
		if( payment_mode === '' ){
			Common.toast.error('Payment Mode is required');
			return false;
		}
        // payment_mode !== 'Cash' &&
		if( bankAccount === undefined || bankAccount === 0 || bankAccount === '' ){
			Common.toast.error('Bank Account is required.');
			return false;
		}
		let formData = new FormData(e.target);
		let pid = localStorage.getItem('fo_profileid');
		formData.append('pid', pid);
		formData.append('oid', oid);
        formData.append('payment_mode', payment_mode);
        formData.append('bankAccount', bankAccount);
        formData.append('deduction_amount', total_deduction);
		formData.append('deductions', JSON.stringify(deductions));
		let data = {};
		// convert the key/value pairs
		for (var [key, value] of formData.entries()) {
		   data[key] = value;
		}

		Common.axios({
			method: 'post',
			url: props.url+'collection/addOtherIncomePayment',
			data: data
		}).then(function (response) {
			if(response.data.success){
                setPaymentModalShow(false);
                setListOfBanks();
                setTotalDeduction('0.00')
                setDeductions([]);
                setDeductionDiv(false)
				fetchCollectionData(switchChecked,'Other Income',1,perPage,dates,filterText,sortOrder,false,PAdates,priorityType)
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
    const calcDeduction = (param,val) => {
		let percent = 0,amount = 0;
		if( param === 'per' ){
			percent = val;
			amount = (taxable_amt * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/taxable_amt * 100).toFixed(2);
		}
		setDeductionPer(percent);
		setDeductionAmt(amount);
	}
	// const deductionModal = () => {
	// 	setDeductionAmt('');setDeductionPer('');setDeductionRemarks('');
	// 	setDeductionModel(true);
	// }
	const setDeductionTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		setDeductionMaster(value);
		setDeductionMasterLabel(label)
		if (value === '') {
			setIsDeductionMasterSelected(false);
			// setDefaultDeductionSubtypeMasterSelected([])
			setDeductionSubtypeMasterList([])
		} else {
			setIsDeductionMasterSelected(true);
		}
		if (value !== '') {
			Common.axios({
				method: 'post',
				url: props.url+'ajax/getDeductionSubtypeMaster',
				data: {
					'pid': localStorage.getItem('fo_profileid'),
					'deduction_master_id':value
				}
			}).then(function (response) {
				if( response.data.success ){
					console.info(response.data.data)
					let dedSubtypeList = response.data.data;
					if (dedSubtypeList.length > 0) {
						setDeductionSubtypeMasterList(response.data.data);
					}
				}
			}).catch(error => console.log(error));
		} else {
			setDeductionSubtypeMaster(value);
			frmSaleDedSubtype.current.clear();
			setDeductionSubtypeMasterList([])
		}
	}
	const setDeductionSubTypeSelect = (e) => {
        if (e.length > 0) {
            setDeductionSubtypeMaster(e[0].label);
            setDeductionSubtypeMasterLabel(e[0].label)
        }
	}
	const addDeduction = () => {
		if( deduction_per === undefined || deduction_per === '' || deduction_per > 100 ){
			Common.toast.error('Deduction percent is invalid.');
			return false;
		}
		if( deduction_amt === undefined || deduction_amt === '' || Number(deduction_amt) === 0 ){
			Common.toast.error('Deduction amount should be greater than zero.');
			return false;
		}

		if( deductionMasterLabel === undefined || deductionMasterLabel === '' ){
			Common.toast.error('Deduction type is required.');
			return false;
		}
		let tot_ded = deductions.reduce(function(prev, current) {
			return Number(prev) + Number(current.deduction_amt)
		}, 0);

		if( Number(total_deduction) + Number(deduction_amt) > Number(taxable_amt) ){
			Common.toast.error('Total deduction amount cannot be greater than total taxable value.');
			return false;
		}
		let ded = {};
		ded['deduction_per'] = deduction_per;
		setDeductionPer('');
		ded['deduction_amt'] = deduction_amt;
		setDeductionAmt('');
		ded['deduction_type'] = deductionMasterLabel;
		frmSaleDedType.current.clear();
		ded['deduction_subtype'] = deductionSubtypeMasterLabel;
		frmSaleDedSubtype.current.clear();
		// setDefaultDeductionSubtypeMasterSelected([])
		ded['deduction_master_id'] = deductionMaster
		ded['deduction_subtype_label'] = deductionSubtypeMaster;
		// ded['deduction_remarks'] = deduction_remarks;
		// setDeductionRemarks('');

		setDeductions([...deductions, ded]);
		let tot_deduction = (Number(total_deduction) + Number(deduction_amt)).toFixed(2);
		setTotalDeduction(tot_deduction);
        setDeductionSubtypeMasterList([])
		setDeductionSubtypeMasterLabel('');
		setIsDeductionMasterSelected(false)
	}
	const removeDeduction = (index) => {
        let price = deductions[index].deduction_amt;
		setDeductions(deductions.filter((item,key) => key !== index));
        let tot_deduction = (Number(total_deduction) - Number(price)).toFixed(2);
		setTotalDeduction(tot_deduction);
	}
    const handleDueDateModalClose = () => {
        setDueDate('');
        setSelectedSaleId('');
        setDueDateModal(false);
    }
    const handleDueDateUpdate = () => {
        Common.axios({
			method: 'post',
			url: props.url+'sale/update-inv-due-date',
			data: {id:selectedSaleId,due_date:dueDate}
		}).then(function (response) {
			if(response.data.success){
                setDueDateModal(false)
                setDueDate('');
                setSelectedSaleId('')
                fetchCollectionData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates);
                Common.toast.success(response.data.message);
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
    }
  return (
    <>
    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
            {props.referrer === 'fynone' ? 
            <Layout label="collection-listing" site_logo={props.site_logo} site={props.site_name}/>
            :
            <MartLayout label="mart-collection-listing" {...props}/>
            }
            
            <div className="layout-page">
                    {props.referrer === 'fynone' ? 
                    <NavBar site={props.site_name} url={props.url}/>
					:
					<MartNavBar {...props}/>
					}
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3">{props.referrer === 'fynone' && (<><span className="text-muted fw-light">Income & Collections /</span></>)} Collection Listing
                            <span className="dropdown">
                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className='text-danger bx bxl-youtube bx-md'></i>
                                </button>
                                <div className="dropdown-menu" >
                                {props.referrer === 'fynmart' && <>
                                    <a href="https://youtu.be/8eMP-Bo-E7E" className="dropdown-item" target="_blank" rel="noreferrer"><span>Manage Collections</span></a>
                                    <a href="https://youtu.be/Za9-6kBhpGw" className="dropdown-item" target="_blank" rel="noreferrer"><span>Add and track Advances</span></a>
                                </>}
                                {props.referrer === 'fynone' && <>
                                    <a href="https://youtu.be/AdN62SitDks" className="dropdown-item" target="_blank" rel="noreferrer"><span>Manage Collections</span></a>
                                    <a href="https://youtu.be/Za9-6kBhpGw" className="dropdown-item" target="_blank" rel="noreferrer"><span>Add and track Advances</span></a>
                                    <a href="https://www.youtube.com/watch?v=wg1syPJGFec" className="dropdown-item" target="_blank" rel="noreferrer"><span>Other income</span></a>
                                </>}
                                </div>
                            </span>
                        </h4>
                        <div className="row pb-3">
                            <div className="col-md-9" id="collection_tab">
                                <TabGroup/>
                            </div>
                        </div>
                        {ShowPendingDiv && (<>
                            {showInvoiceDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    <div className="row">
										<div className="col-md-2 col-12 pending-filters mb-1">
                                            <button className={"btn btn-sm btn-info mobile-margin-left "+exportBtnClass} onClick={handleExportCSV}>
                                                <span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
                                                <i className="bx bx-download d-block d-sm-none"></i>
                                            </button>
                                        </div>
										<div className="col-md-2 col-12 pending-filters mb-1">
                                            <Typeahead selected={defaultDuePriority} clearButton id="frmDueDateTypes" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => getDueDatePriorities(e)} style={{'padding': '1px','marginTop':'-2px','height':'37px'}} placeholder="Choose Priority Type"
												options={[{value: 'All', label: 'Dues Priority (All)',description:''},{value: "1", label: "Overdue",description:"Due date < current date"},{value: "2", label: "Immediate due",description:"Due date between 0 to 7 days"},{value: "3", label: "Not yet due",description:"due date > current date + 7"},{value: "4", label: "No priority"}]}
												renderMenuItemChildren={(option: Item,props) => (
                                                    <>
                                                        <Highlighter search={props.text}>
                                                            {option.label}
                                                        </Highlighter>
                                                        <div>
                                                            <small>{option.description}</small>
                                                        </div>
                                                    </>
												)}
											/>
                                        </div>
                                        <div className="col-md-3 col-12 pending-filters text-right text-md-center mb-1">
                                            <DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,PAstart,PAend)}
												onCallback={PAsetDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{PAlabel}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Invoice Date</small>
                                        </div>
                                        <div className="col-md-1 col-12 pending-filters text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Customer Level'
                                                    onstyle='warning'
                                                    offlabel='Invoice Level'
                                                    offstyle='secondary'
                                                    width='150'
													size="sm"
                                                />
                                            </label>
                                        </div>
										<div className="col-md-4 col-12 pending-filters text-center text-md-end mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Customer Name, Phone Number, Invoice Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
                                    <DTE.DataTable columns={columns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                            {showIrnDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    <div className="row">
                                        <div className="col-md-2 col-12 mb-1">
                                            <button className={"btn btn-sm btn-info mobile-margin-left "+exportBtnClass} onClick={handleExportCSV}>
                                                <span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
                                                <i className="bx bx-download d-block d-sm-none"></i>
                                            </button>
                                        </div>
                                        <div className="col-md-4 col-12 pending-filters text-right text-md-center mb-1">
                                            <DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,PAstart,PAend)}
												onCallback={PAsetDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{PAlabel}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Payment Date</small>
                                        </div>
                                        <div className="col-md-2 col-12 pending-filters text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Customer Level'
                                                    onstyle='warning'
                                                    offlabel='Invoice Level'
                                                    offstyle='secondary'
                                                    width='150'
													size='sm'
                                                />
                                            </label>
                                        </div>
										<div className="col-md-4 col-12 text-center text-md-end mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Customer Name, Phone Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                        <div className="col-md-12 mb-1">
                                            <p className="text-danger text-center" id="popover-fo">Note: FIFO method of invoice knock off 
                                            <Common.OverlayTrigger overlay={Common.popoverContent('','Payments will be knocked off against invoice level dues based on FIFO method. Hence, multi payments and deductions are not possible in collections at customer level. Switch to Invoice level to perform multi payments and deductions.')}><i className='bx bxs-info-circle' ></i></Common.OverlayTrigger></p>
                                        </div>
                                    </div>
									<DTE.DataTable columns={docColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                        </>)}
                        {showCollectionDiv && (<>
                            {showInvoiceDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    <div className="row">
                                        <div className="col-md-2 col-sm-2 col-12 mb-1 collected-filters">
                                            <button className={"btn btn-sm btn-info mobile-margin-left "+exportBtnClass} onClick={handleExportCSV}>
                                                <span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
                                                <i className="bx bx-download d-block d-sm-none"></i>
                                            </button>
                                        </div>
                                        <div className="col-md-3 col-12 text-center mb-1 collected-filters">
											<DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,start,end)}
												onCallback={setDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Payment Date</small>
										</div>
                                        <div className="col-md-2 col-12 text-right text-md-center mb-1 collected-filters">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Customer Level'
                                                    onstyle='warning'
                                                    offlabel='Invoice Level'
                                                    offstyle='secondary'
                                                    width='150'
													size='sm'
                                                />
                                            </label>
                                        </div>
                                        <div className="col-md-1 col-12 text-right text-md-center mb-1 collected-filters">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleActiveCancel}
                                                    checked={activeCancel}
                                                    onlabel='Cancel'
                                                    onstyle='danger'
                                                    offlabel='Active'
                                                    offstyle='success'
                                                    width='100'
													size='sm'
                                                />
                                            </label>
                                        </div>
										<div className="col-md-4 col-12 text-right mb-1 collected-filters">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Customer Name, Phone Number, Invoice Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
									<DTE.DataTable columns={collectionColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                            {showIrnDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    <div className="row">
                                        <div className="col-md-2 col-12 pending-filters mb-1">
                                            <button className={"btn btn-sm btn-info mobile-margin-left "+exportBtnClass} onClick={handleExportCSV}>
                                                <span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
                                                <i className="bx bx-download d-block d-sm-none"></i>
                                            </button>
                                        </div>
                                        <div className="col-md-3 col-12 text-center mb-1">
											<DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,start,end)}
												onCallback={setDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Payment Date</small>
										</div>
                                        <div className="col-md-2 col-12 pending-filters text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Customer Level'
                                                    onstyle='warning'
                                                    offlabel='Invoice Level'
                                                    offstyle='secondary'
                                                    width='150'
													size='sm'
                                                />
                                            </label>
                                        </div>
                                        <div className="col-md-1 col-12 pending-filters text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleActiveCancel}
                                                    checked={activeCancel}
                                                    onlabel='Cancel'
                                                    onstyle='danger'
                                                    offlabel='Active'
                                                    offstyle='success'
                                                    width='100'
													size='sm'
                                                />
                                            </label>
                                        </div>
										<div className="col-md-4 col-12 pending-filters text-right mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Customer Name, Phone Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
									<DTE.DataTable columns={collectionDocColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                        </>)}
                        {showAdvancedDiv && (<>
                            {showInvoiceDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    <div className="row">
                                        <div className="col-md-3 col-12 mb-1 advance-filters">
											<div className="btn-group btn-group-sm" role="group">
											<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
                                                <span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
                                                <i className="bx bx-download d-block d-sm-none"></i>
                                            </button>
											<Common.Link to={props.referrer === "fynone" ? "/add-collection" : "/mart-collection-add"} className="btn btn-primary btn-sm">
                                                <i className="bx bx-plus"></i>Advance
                                            </Common.Link>
											</div>
                                        </div>
										<div className="col-md-3 col-12 text-end text-md-center mb-1 advance-filters">
                                            <DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,PAstart,PAend)}
												onCallback={PAsetDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{PAlabel}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Payment Date</small>
                                        </div>
										<div className="col-md-2 col-12 text-end text-md-center mb-1 advance-filters">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Customer Level'
                                                    onstyle='warning'
                                                    offlabel='Receipt Level'
                                                    offstyle='secondary'
                                                    width='150'
													size='sm'
                                                />
                                            </label>
                                        </div>
										<div className="col-md-4 col-12 text-center text-md-end mb-1 advance-filters">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Customer Name, Phone Number, Advance Receipt No')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-9"></div>
                                        <div className="col-md-3 col-12 text-right text-md-center mb-1 advance-filters">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleActiveCancel}
                                                    checked={activeCancel}
                                                    onlabel='Cancel'
                                                    onstyle='danger'
                                                    offlabel='Active'
                                                    offstyle='success'
                                                    width='100'
                                                    size='sm'
                                                />
                                            </label>
                                        </div>
                                    </div>
									<DTE.DataTable columns={advancedColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                            {showIrnDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {/* advance doc cols */}
                                    <div className="row">
                                        <div className="col-md-3 col-12 pending-filters mb-1">
											<div className="btn-group btn-group-sm" role="group">
												<button className={"btn btn-sm btn-info "+exportBtnClass} onClick={handleExportCSV}>
                                                    <span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
                                                    <i className="bx bx-download d-block d-sm-none"></i>
                                                </button>
												<Common.Link to={props.referrer === "fynone" ? "/add-collection" : "/mart-collection-add"} className="btn btn-primary btn-sm ms-2">
                                                    <i className="bx bx-plus"></i>Advance
                                                </Common.Link>
											</div>
                                        </div>
                                        <div className="col-md-3 col-12 pending-filters text-end text-md-center mb-1">
                                            <DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,PAstart,PAend)}
												onCallback={PAsetDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{PAlabel}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Payment Date</small>
                                        </div>
										<div className="col-md-2 col-12 pending-filters text-end text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Customer Level'
                                                    onstyle='warning'
                                                    offlabel='Receipt Level'
                                                    offstyle='secondary'
                                                    width='150'
													size='sm'
                                                />
                                            </label>
                                        </div>
                                        
                                        
										<div className="col-md-4 col-12 text-center text-md-end mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Customer Name, Phone Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-9"></div>
                                        <div className="col-md-3 col-12 text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleActiveCancel}
                                                    checked={activeCancel}
                                                    onlabel='Cancel'
                                                    onstyle='danger'
                                                    offlabel='Active'
                                                    offstyle='success'
                                                    width='100'
													size='sm'
                                                />
                                            </label>
                                        </div>
                                    </div>
									<DTE.DataTable columns={advancedDocColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                        </>)}
                        {showOtherIncomeDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-3 col-4 mb-1 other-income-filters">
											<div className="btn-group btn-group-sm" role="group">
											<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
                                                <span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
                                                <i className="bx bx-download d-block d-sm-none"></i>
                                            </button>
											<Common.Link to="/other-income" className="btn btn-primary btn-sm">
                                                <i className="bx bx-plus"></i><span className="hidden-phone">Other Income</span>
                                            </Common.Link>
											</div>
                                        </div>
                                        <div className="col-md-3 col-8 text-center mb-1">
											<DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,start,end)}
												onCallback={setDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Voucher Date</small>
										</div>
                                        <div className="col-md-2 col-12 text-end text-md-center mb-1 other-income-filters">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleActiveCancel}
                                                    checked={activeCancel}
                                                    onlabel='Cancel'
                                                    onstyle='danger'
                                                    offlabel='Active'
                                                    offstyle='success'
                                                    width='100'
													size='sm'
                                                />
                                            </label>
                                        </div>
										<div className="col-md-4 col-12 text-center text-md-end mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Voucher No')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
									<DTE.DataTable columns={otherIncomeColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                        </>)}
                    </div>
                </div>
                <Common.FYNTicket url={props.url}/>
            </div>
        </div>
    </div>
    <Modal show={dedModalShow} size={'lg'} onHide={()=>setDedModalShow(false)} aria-labelledby="deduction-modal" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title id="deduction-modal">Deductions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                {temp_deduction !== undefined && temp_deduction.length === 0 && <div className="text-center py-1">No deduction</div>}
                {temp_deduction !== undefined && temp_deduction.length > 0 && <>
                <div className="table-responsive text-nowrap">
                    <table className="table table-sm table-bordered">
                        <thead className="table-light">
                            <tr>
                                <th>Sl.</th>
                                <th align="right">Deduction (%)</th>
                                <th align="right">Deduction (<i className='bx bx-rupee' ></i>)</th>
                                <th>Deduction Type</th>
                                <th>Deduction Subtype</th>
                                {/* <th>Remarks</th> */}
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {temp_deduction.map((val,index) => (
                                <tr key={'ded1'+index}>
                                    <td>{index+1}</td>
                                    <td align="right">{Common.displayPercent(val.deduction_per)}</td>
                                    <td align="right">{val.deduction_amount}</td>
                                    <td>{val.deduction_type}</td>
                                    <td>{val.subtype_name}</td>
                                    {/* <td>{val.deduction_remarks}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                </>}
            </div>
        </Modal.Body>
    </Modal>
    <Modal size='md' show={collectionCancelShow} onHide={()=>setCollectionCancelShow(false)} aria-labelledby="Quotation-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Quotation-Cancel-modal">Cancel Collection ({receiptNo})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addEwbCancelFrm" onSubmit={handleCancelCln} autoComplete="off">
					<div className="row">
						<div className="col-sm-12">
                            <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                            <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} required/>
						</div>
                        <div className="col-sm-12">
                            <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                            <div className="input-group input-group-merge">
                                <textarea rows="2" id="cancel-remarks" name="cancel_remarks" className="form-control" placeholder="Cancel Remarks" aria-label="CancelRemarks" aria-describedby="Remarks" value={collectionCancelRemarks} onChange={(e) => setCollectionCancelRemarks(e.target.value)} required></textarea>
                            </div>
						</div>
					</div>
                    <div className="row ">
                        <div className="col-sm-12 mt-2">
							<p className="smaller_font text-danger">Note: Cancelling this payment will also cancel other payments or advance receipts or advance adjusted linked to this sale.</p>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-1">
							<button type='submit' className="btn btn-sm btn-danger"><i className='bx bx-message-square-x' ></i>&nbsp;Cancel</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
        <Modal show={cancelCheckShow} size='sm' onHide={()=>setCancelCheckShow(false)} aria-labelledby="Eway-Bill-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Cancel Collection</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<p><b>{cancelCheckMessage}</b></p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="danger" onClick={()=>setCancelCheckShow(false)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
        <Modal show={commonModel} size='sm' aria-labelledby="Common-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header>
			<Modal.Title>{commonModelHeading}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{commonModelBody}
			</Modal.Body>
			<Modal.Footer>
				{commonModelFooter}
			</Modal.Footer>
		</Modal>
        <Modal size='md' show={otherIncomeCancelShow} onHide={()=>setOtherIncomeCancelShow(false)} aria-labelledby="Other-Income-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Other-Income-Cancel-modal">Cancel Other Income ({receiptNo})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addEwbCancelFrm" onSubmit={handleCancelOtherIn} autoComplete="off">
					<div className="row">
						<div className="col-sm-12">
                            <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                            <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} required/>
						</div>
                        <div className="col-sm-12">
                            <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                            <div className="input-group input-group-merge">
                                <textarea rows="2" id="cancel-remarks" name="cancel_remarks" className="form-control" placeholder="Cancel Remarks" aria-label="CancelRemarks" aria-describedby="Remarks" value={collectionCancelRemarks} onChange={(e) => setCollectionCancelRemarks(e.target.value)} required></textarea>
                            </div>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-1">
							<button type='submit' className="btn btn-sm btn-danger"><i className='bx bx-message-square-x' ></i>&nbsp;Cancel</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
        <Modal show={paymentModalShow} size={ deduction_div ? 'xl':'md'} onHide={()=>setPaymentModalShow(false)} aria-labelledby="deduction-modal" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title id="deduction-modal">Other Income Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                {paymentDetails && paymentType === 1 && <>
                <div className="table-responsive text-nowrap">
                    <table className="table table-sm table-bordered">
                        <thead className="table-light">
                            <tr>
                                <th>Payment Mode</th>
                                <th align="right">Amount</th>
                                <th>Payment Date</th>
                                <th>Nickname</th>
                                <th>Payment Remarks</th>
                                <th>Trans Ref</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            <tr key='pay1'>
                                <td>{paymentDetails.payment_mode}</td>
                                <td align="right">{paymentDetails.payment_amt}</td>
                                <td>{paymentDetails.payment_date}</td>
                                <td>{paymentDetails.nickname}</td>
                                <td>{paymentDetails.payment_remarks}</td>
                                <td>{paymentDetails.trans_ref}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </>}
                {paymentDetails && paymentType === 2 && <>
                    {deduction_div && <>
                        <div className="col text-right">
                            <span id="payModalInfo" className="badge bg-warning">
                                Total Invoice Value: {Common.displayPrice(taxable_amt)}
                            </span>
                        </div>
                    </>}
                    <form id="formAddPayment" onSubmit={handlePayment} autoComplete="off" className='formAddPayment'>
                        <div className="row">
                            <div className={deduction_div ? 'col-sm-6':''}>
                                <div className="row">
                                    <label className="col-4 col-form-label" htmlFor="frmSaleTotal">Amount <span className="text-danger">*</span></label>
                                    <div className="col-8">
                                        <div className="input-group input-group-merge">
                                            <span className="input-group-text"><i className='bx bx-rupee'></i></span>
                                            <input type="text" className="form-control" id="frmSaleTotal" placeholder="0.00" name='received_amount' value={Number(paymentDetails.total_amt) - Number(total_deduction)} 
                                            readOnly/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-4 col-form-label" htmlFor="frmSalePayDate">Payment Date</label>
                                    <div className="col-8">
                                        <DatePicker name="payment_date" data-testid="frmSalePayDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setPaymentDate} value={payment_date} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-4 col-form-label" htmlFor="frmSalePayMode">Payment Mode</label>
                                    <div className="col-8">
                                        <Typeahead clearButton defaultSelected={paymentModes.slice(1, 2)} id="frmSalePayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
                                            options={paymentModes}
                                            renderMenuItemChildren={(option: Item,props) => (
                                                <Highlighter search={props.text}>
                                                    {option.label}
                                                </Highlighter>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-4 col-form-label" htmlFor="frmBankAccount">Bank Account <span className="text-danger">*</span></label>
                                    <div className="col-8">
                                        <Typeahead selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
                                            options={bankList}
                                            renderMenuItemChildren={(option: Item,props) => (
                                                <Highlighter search={props.text}>
                                                    {option.label}
                                                </Highlighter>
                                            )}
                                        />
                                        <small>To add additional bank account, Go to Account management - <a href='/bank-accounts'>Bank accounts</a></small>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-4 col-form-label" htmlFor="frmSalePayRemarks">Payment Remarks</label>
                                    <div className="col-8">
                                        <input type="text" name='payment_remarks' className="form-control" id="frmSalePayRemarks" placeholder="Payment Remarks" value={payment_remarks} onChange={(e) => setPaymentRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-4 col-form-label" htmlFor="frmSaleTransRef">Transaction Ref. No.</label>
                                    <div className="col-8">
                                        <input type="text" name='trans_ref' maxLength="100" className="form-control" id="frmSaleTransRef" placeholder="Transaction Ref." value={trans_ref} onChange={(e) => setTransRef(e.target.value)} autoComplete="off"/>
                                    </div>
                                </div>
                            </div>
                            <div className={deduction_div ? 'col-sm-6':'d-none'}>
                                {/*<div className="form-text text-right text-info">
                                    Total Invoice Value: {Common.displayPrice(total_amt)}, Total Deduction added: {Common.displayPrice(Number(total_deduction))}
                                </div>*/}
                                <div className="card">
                                    <h6 className="card-header">Deductions</h6>
                                    {deductions.length === 0 && <div className="text-center py-1">No deduction</div>}
                                    {deductions.length > 0 && <>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table table-sm table-bordered">
                                            <thead className="table-light">
                                                <tr>
                                                    <th align="center">Action</th>
                                                    <th>Sl.</th>
                                                    <th align="right">Deduction (%)</th>
                                                    <th align="right">Deduction (<i className='bx bx-rupee' ></i>)</th>
                                                    <th>Deduction Type</th>
                                                    <th>Deduction Subtype</th>
                                                    {/* <th>Remarks</th> */}
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {deductions.map((val,index) => (
                                                    <tr key={'ded'+index}>
                                                        <td align="center">
                                                            <button type="button" className="btn btn-sm" onClick={() => removeDeduction(index)}><i className='bx bx-trash'></i></button>
                                                        </td>
                                                        <td>{index+1}</td>
                                                        <td align="right">{Common.displayPercent(val.deduction_per)}%</td>
                                                        <td align="right">{Common.displayPrice(val.deduction_amt)}</td>
                                                        <td>{val.deduction_type}</td>
														<td>{val.deduction_subtype}</td>
														{/* <td>{val.deduction_remarks}</td> */}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    </>}
                                </div>
                                <div className="alert alert-secondary mt-3">
                                    <div className="row">
                                        <h4>Add Deduction</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                <label className="col-sm-5 col-form-label" htmlFor="frmSaleDedPer">Percent <span className="text-danger">*</span></label>
                                                <div className="col-sm-7">
                                                    <div className="input-group input-group-merge">
                                                        <span className="input-group-text"><i className='bx bxs-offer'></i></span>
                                                        <input type="text" className="form-control" id="frmSaleDedPer" placeholder="0.00" value={deduction_per} onChange={(e) => calcDeduction('per',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row text-right">
                                                <small>(Deduction at source and on base value)</small>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row">
                                                <label className="col-sm-4 col-form-label" htmlFor="frmSaleDedAmt">Amount <span className="text-danger">*</span></label>
                                                <div className="col-sm-8">
                                                    <div className="input-group input-group-merge">
                                                        <span className="input-group-text"><i className='bx bx-rupee'></i></span>
                                                        <input type="text" className="form-control" id="frmSaleDedAmt" placeholder="0.00" value={deduction_amt} onChange={(e) => calcDeduction('amt',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <label className="col-4 col-form-label" htmlFor="frmSaleDedType">Deduction Type <span className="text-danger">*</span></label>
                                        <div className="col-8">
                                            <Typeahead clearButton ref={frmSaleDedType} id="frmSaleDedType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDeductionTypeSelect(e)} placeholder="Choose Deduction Type"
                                                options={deductionMasterList}
                                                renderMenuItemChildren={(option: Item,props) => (
                                                    <Highlighter search={props.text}>
                                                        {option.label}
                                                    </Highlighter>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <label className="col-4 col-form-label" htmlFor="frmSaleDedRemarks">Deduction Subtype</label>
                                        <div className="col-8">
                                            {/* <input type="text" className="form-control" id="frmSaleDedRemarks" placeholder="Deduction Remarks" value={deduction_remarks} onChange={(e) => setDeductionRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/> */}
                                            <Typeahead clearButton id="frmSaleDedSubtype" allowNew newSelectionPrefix="Add: " ref={frmSaleDedSubtype} disabled={isDeductionMasterSelected === false ? true : false} labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDeductionSubTypeSelect(e)} placeholder="Choose Deduction Sub Type"
                                                options={deductionSubtypeMasterList}
                                                renderMenuItemChildren={(option: Item,props) => (
                                                    <Highlighter search={props.text}>
                                                        {option.label}
                                                    </Highlighter>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center mt-3">
                                        <button type="button" className="btn btn-sm btn-primary ms-1" onClick={addDeduction}><span className="tf-icons bx bx-save"></span>&nbsp;Add</button>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-3">
                                <button type="button" onClick={()=>{setDeductionDiv(!deduction_div);setDeductionAmt('');setDeductionPer('');frmSaleDedType.current.clear();}} className={"btn btn-sm btn-"+(deduction_div ? 'danger':'success')}>{deduction_div ? <><i className='bx bx-minus'></i></> : <><i className='bx bx-plus'></i></>} Deduction</button>
                                {bank_cash_account === 1 && (<>
                                    <button type='submit' className="btn btn-sm btn-primary ms-1" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                </>)}
                            </div>
                        </div>
                    </form>
                </>}
            </div>
        </Modal.Body>
    </Modal>
    <Modal {...props} size="sm" show={dueDateModal} onHide={handleDueDateModalClose}>
        <Modal.Header closeButton>
            <Modal.Title>Set Due Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <DatePicker name="due_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setDueDate} value={dueDate} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleDueDateUpdate}>
                Save
            </Button>
        </Modal.Footer>
    </Modal>
   </>
);

}