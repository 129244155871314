import React  from "react";
import {useParams} from "react-router-dom";
import * as Common from '../common/ImportUserEssentials';
import '../styles/mart-print.css';
export default function MartInvPrint(props){
	const [itemCount,setItemCount] = React.useState(0);
	const [invoiceData, setInvoiceData]    = React.useState([]);
	const [invItemData, setInvItemData]    = React.useState([]);
	const [paymentData, setPaymentData]    = React.useState([]);
	const { inv } = useParams();
	React.useEffect(() => {
		Common.axios({
			method: 'post',
			url:props.url+'martorders/invoiceDetails',
			data: {'sid':atob(inv)}
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setInvoiceData(response.data.data);
				setInvItemData(response.data.itemData);
				setPaymentData(response.data.paymentData);
				setItemCount(response.data.itemCount);
				setTimeout(() => { window.print() }, 1);
			}else{
				Common.toast.error(response.data.message);
			}
		}).catch(error => console.log(error));
	}, [props,inv]);
    return (
	<>
		<div style={{'width':'100%','padding':'5px','color':'black'}}>
			<div style={{'textAlign':'center'}}>
				{invoiceData.logo && (<>
					<div style={{'display':'flex','justify-content':'center'}}>
						<img
							src = {props.url+"public/assets/img/profile_logo/"+invoiceData.logo}
							alt="user-avatar"
							className="d-block rounded"
							height="40"
							width="40"
							id="uploadedAvatar"
						/>
					</div>
				</>)}
				<p style={{'fontSize':'15px','fontWeight':'bold','marginTop':'5px','marginBottom':'5px'}} ><span>{invoiceData.name}</span></p>
				<div className="top_header" dangerouslySetInnerHTML={{ __html: (invoiceData.contact_billing_details) }} />
				<div style={{'fontSize':'12px'}}>GSTIN:{invoiceData.gstin}</div>
				<p style={{'fontSize':'15px','fontWeight':'bold','marginTop':'5px','marginBottom':'5px'}} ><span>Tax Invoice</span></p>
			</div>
			<div>
				<div style={{'display':'inline','float':'left'}}>
					<p className="font-9 m-0">Inv No: {invoiceData.inv_no}</p>
					<p className="font-9 m-0">{(invoiceData.customer_mobile)?invoiceData.customer_name+' ('+invoiceData.customer_mobile+')':invoiceData.customer_name}</p>
				</div>
				<div style={{'display':'inline','float':'right'}}><small className="font-9">Date: {Common.displayDate(invoiceData.inv_date)}</small></div>
			</div>
			<div>
				<table style={{'width':'100%'}}>
					<thead style={{'borderTop':'1px solid','borderBottom':'1px solid'}}>
						<tr>
							<th className="font-12">S/N</th>
							<th className="font-12">Item Details</th>
							<th className="font-12" style={{'textAlign':'right'}}>Qty</th>
							<th className="font-12" style={{'textAlign':'right'}}>Amount</th>
						</tr>
					</thead>
					<tbody>
						{invItemData.map((val,index) => (
							<tr key={'item'+index}>
								<td className="font-12">{index+1}</td>
								<td className="font-12">{val.name}<br></br>
									<span style={{'fontSize':'9px'}}>{' ₹'+val.gross_amt}</span><br></br>
									<span style={{'fontSize':'9px'}}>{(invoiceData.customer_pos === invoiceData.seller_pos)?'SGST '+Number(val.gst_rt/2)+'% | CGST '+Number(val.gst_rt/2)+'%':'IGST '+val.gst_rt+'%'}</span>
								</td>
								<td className="font-12" align="right">{( (Number(val.qty) % 1 !== 0)?val.qty:Math.trunc(val.qty) ) +" "+ val.unit_code}</td>
								<td className="font-12" align="right">{val.amt}</td>
							</tr>
						))}
						<tr style={{'borderTop':'1px solid','borderBottom':'1px solid'}}>
							<td colSpan="2" align="center"><b className="font-12">Sub Total</b></td>
							<td align="right"><b className="font-12">{(Number(itemCount) % 1 !== 0)?itemCount:Math.trunc(itemCount)}</b></td>
							<td align="right"><b className="font-12">{(Number(invoiceData.total_taxable_amt) + Number(invoiceData.gst_amt)).toFixed(2)}</b></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div style={{'paddingTop':'10px'}}>
			{invoiceData.other_charges !== '0.00' && (<>
				<div style={{'display':'inline','float':'left'}}><p style={{'marginTop':'0px','marginBottom':'0px'}} ><span className="font-12">Other charge:</span></p></div>
				<div style={{'display':'inline','float':'right'}}><p style={{'marginBottom':'0px'}} ><span className="font-12">₹ {invoiceData.other_charges}</span></p></div>
				<br></br>
			</>)}
			{invoiceData.total_doc_discount !== '0.00' && (<>
				<div style={{'display':'inline','float':'left'}}><p style={{'marginTop':'0px','marginBottom':'0px'}} ><span className="font-12">Additional discount:</span></p></div>
				<div style={{'display':'inline','float':'right'}}><p style={{'marginBottom':'0px'}} ><span className="font-12">₹ {invoiceData.total_doc_discount}</span></p></div>
				<br></br>
			</>)}
			{invoiceData.round_off !== '0.00' && (<>
				<div style={{'display':'inline','float':'left'}}><p style={{'marginTop':'0px','marginBottom':'0px'}} ><span className="font-12">Round off:</span></p></div>
				<div style={{'display':'inline','float':'right'}}><p style={{'marginBottom':'0px'}} ><span className="font-12">₹ {invoiceData.round_off}</span></p></div>
			</>)}
			</div><br></br>
			<div style={{'paddingTop':'10px'}}>
				<div style={{'display':'inline','float':'left'}}><p style={{'fontSize':'15px','fontWeight':'bold','marginTop':'5px','marginBottom':'5px'}} ><span>Total Amount:</span></p></div>
				<div style={{'display':'inline','float':'right','borderTop':'1px solid','borderBottom':'1px solid'}}><p style={{'fontSize':'15px','fontWeight':'bold','marginTop':'5px','marginBottom':'5px'}} ><span>₹ {invoiceData.total_amt}</span></p></div>
			</div>
			{paymentData.length>0 && (<>
				<div style={{'paddingTop':'10px'}}>
					<div style={{'display':'inline','float':'right','width':'100%','textAlign':'center'}}>
						<p className="font-12" style={{'borderBottom':'1px solid','marginBottom':'0px'}}>Payment Details</p>
						{paymentData.map((val) => (
							<p className="font-12" style={{'margin':'0px'}}>{val.payment_mode}: {val.paid_amt}</p>
						))}
					</div>
				</div>
			</>)}
			<br></br>
			<br></br>
			{Number(invoiceData.discount) > 0 && (<>
				<div style={{'textAlign':'center'}}>
					<p style={{'fontSize':'15px','fontWeight':'bold','marginTopmargin':'5px','marginBottom':'5px'}} >Your Savings : Rs. {invoiceData.discount}</p>
				</div>
			</>)}
			<div className="font-12" style={{'textAlign':'center','marginTop':'4px'}}>
			<br></br>
				{Number(invoiceData.due_amt) > 0 && invoiceData.upi_url && (<>
					<p className="m-0">Scan to Pay</p>
					<img src={invoiceData.upi_url} alt="QR Code" width="150" height="150"></img><br></br>
				</>)}
				Thank You. Visit Again 
				<p><b>Powered by FynMart</b></p>
			</div>
		</div>
	</>
    );
}