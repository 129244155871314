import React,{useState,useEffect} from 'react';
import Layout from './MartLayout';
import NavBar from './MartNavBar';
import * as Common from '../common/ImportUserEssentials';
import { QRCode } from 'react-qrcode-logo';
import Button from 'react-bootstrap/Button';

export default function MartSettings(props){
	const qrref = React.useRef(null);
	const [upiSettingSaved,setUpiSettingSaved]      = useState(false);
	const [upiHandles,setUpiHandles]				= useState([]);
	const [upiPrefix,setUpiPrefix]					= useState('');
	const [upiHandle,setUpiHandle]					= useState();
    const [contact_name, setContactName]            = useState('');

	useEffect(() => {
		document.title = 'Settings | '+props.site_name;
        Common.axios({
            method: 'get',
            url: props.url+'ajax/getUpiHandle',
        }).then(function(response){
            if(response.data.success){
                setUpiHandles(response.data.data);
                setUpiHandle(response.data.data[0].name);
            }
        }).catch(error => console.log(error));
		fetchUpiDetails();
	}, [props]);
	const fetchUpiDetails = () => {
		Common.axios({
            method: 'post',
            url: props.url+'user/upiDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
        }).then(function (response) {
			if(response.data.success){
				setUpiSettingSaved(true);
				let parts = response.data.data.upi.split('@');
				setUpiPrefix(parts[0]);
				setUpiHandle('@'+parts[1]);
                setContactName(response.data.data.contact_name !== null ? response.data.data.contact_name : '');
			}
		}).catch(error => console.log(error));
	}
	const handleUpiSubmit = (e) => {
		e.preventDefault();
		let data = {};
        data['pid'] = localStorage.getItem('fo_profileid');
        data['createdby'] = localStorage.getItem('fo_loggedin');
		data['upi'] = upiPrefix+upiHandle;
		const canvas = document.getElementById('react-qrcode-logo');
		data['upi_qr'] = canvas.toDataURL();
		if (upiPrefix === '') {
            Common.toast.error('UPI cannot be left blank.');
            return false;
		}
		Common.axios({
            method: 'post',
            url:props.url+'user/updateUpi',
            data: data,
        }).then(function (response) {
			if(response.data.success){
				fetchUpiDetails();
                Common.toast.success(response.data.message);
				return false;
			}
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const downloadQRCode = () => {
		qrref.current?.download('png',contact_name.replace(/[^a-zA-Z ]/g, "")+'_QR')
	}
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="settings" {...props}/>
                <div className="layout-page">
                    <NavBar {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
						<h4 className="fw-bold py-3 mb-3">Settings</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className="row" id="saleSettingDiv">
                                                <div className="col">
                                                <h5>UPI Settings</h5>
                                                    <form id="formUpi" autoComplete="off" onSubmit={handleUpiSubmit}>
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label className="form-label" htmlFor="upi-prefix">UPI</label>
                                                                <div className="input-group mb-3">
                                                                    <input type="text" id="upi-prefix" name="upi_prefix" className="form-control" maxLength="50" placeholder="Enter UPI" aria-label="UPI" aria-describedby="UPI" value={upiPrefix} onChange={(e) => setUpiPrefix(Common.acceptUPI(e.target.value))} required disabled={upiSettingSaved?true:false}/>
                                                                    <button type="button" className="btn btn-outline-secondary" disabled={upiSettingSaved?true:false}>{upiHandle}</button>
                                                                    <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" disabled={upiSettingSaved?true:false}>
                                                                        <span className="visually-hidden">Toggle Dropdown</span>
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        {upiHandles.map((handle,index)=> {
                                                                            return <li key={"upi_li_"+index}><button type="button" className="dropdown-item" onClick={()=>setUpiHandle(handle.name)}>{handle.name}</button></li>
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {upiPrefix !== '' && <>
                                                        <div className="row">
                                                            <div className="text-center text-sm-start mb-2" style={{ background:"white",height: "auto", margin: "0 auto" }}>
                                                                <QRCode ref={qrref}
                                                                enableCORS={true}
                                                                size={256}
                                                                logoPadding={2}
                                                                removeQrCodeBehindLogo={true}
                                                                logoImage={props.fynone_logo}
                                                                value={"upi://pay?pa="+upiPrefix+upiHandle}
                                                                viewBox={`0 0 256 256`}
                                                                />
                                                            </div>
                                                        </div>
                                                        </>}
                                                        <div className="row ">
                                                            <div className="d-flex gap-2">
                                                                { (upiPrefix !== '') && <>
                                                                    {upiSettingSaved && <>
                                                                    <Button variant="info" size="sm" onClick={downloadQRCode} className="me-1"><i className='bx bx-download'></i> Download QR</Button>
                                                                    </>}
                                                                    {!upiSettingSaved && <>
                                                                    <Button variant="primary" size="sm" type="submit"><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
                                                                    </>}
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}