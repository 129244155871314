import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import * as Common from '../common/ImportUserEssentials';
import paymentModes from '../masters/PaymentModes.js';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';

export default function EditSellerPayable(props){
    const [payment_received_on,setPaymentReceivedOn] = useState(new Date());
    const [amount,setAmount]                         = useState('');
    const [paid_amount,setPaidAmount]                = useState('');
    const [due_amount,setDueAmount]                  = useState('');
    const [payment_mode, setPaymentMode]             = useState('UPI/QR');
    const [PaymentRemarks, setPaymentRemarks]        = useState('');
    const [data, setData]                            = useState([]);
    // const [saveBtnDisabled,setSaveBtnDisabled]       = useState(false);
    const [advance_amount, setAdvanceAmount]         = useState('');
	const [showAdvanceDiv, setShowAdvanceDiv]        = useState(false);
	const [trans_ref,setTransRef]                    = useState('');
	const [bankList, setBankList] 					 = useState([]);
	const [bankAccount,setBankAccount] 				 = useState(0);
	const [defaultPayee, setDefaultPayee]            = useState([]);
    const [bank_cash_account,setBankCashAccount] 	 = useState(1);

    const [successMsg,setSucces]                     = useState('');
    const [errorMessage,setError]                    = useState('');
    const { state } = Common.useLocation();
	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Edit Payable | '+props.site_name;
        if( state === null ){
			navigate('/');
		}
        
        let pid = localStorage.getItem('fo_profileid');
        Common.axios({
            method: 'post',
            url:props.url+'payable/getSellerLevelPayable',
            data:{se_id:state.se_id,pid:pid}
        }).then(function (response) {
            if(response.data.success){
                setData(response.data.data);
                setAmount(parseInt(response.data.data.due_amount));
                setPaidAmount(parseInt(response.data.data.due_amount));
                setDueAmount(0);
                setAdvanceAmount(0);
            }
        }).catch(error => console.log(error));

        Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
                setDefaultPayee(response.data.defaultPayee);
                if (response.data.defaultPayee.length > 0) {
                    setBankAccount(response.data.defaultPayee[0].value);
                }
			}
		}).catch(error => console.log(error));

	}, []);   

    const handleEditPayable = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
		if( payment_mode === '' ){
            Common.showLoader(false);
			Common.toast.error('Payment Mode is required');
			return false;
		}
        // payment_mode !== 'Cash' &&
        if( bankAccount === undefined || bankAccount === 0 || bankAccount === '' ){
            Common.showLoader(false);
			Common.toast.error('Bank Account is required.');
			return false;
		}
        let formData = new FormData(e.target);
        let pid = localStorage.getItem('fo_profileid');
        formData.append('pid', pid);
        formData.append('bankAccount', bankAccount);
        let url = props.url+'payable/editSellerLevelPayable';
        let data = {};
   
        // convert the key/value pairs
      for (var [key, value] of formData.entries()) { 
        data[key] = value;
       }
       if(parseFloat(data['paid_amount']) === 0){
            Common.showLoader(false);
            Common.toast.error('Paid amount is Required.');
            return false;
        }
        
        // setSaveBtnDisabled(true);
        Common.axios({
            method: 'post',
            url:url,
            data: {
                formData:data
            },
        }).then(function (response) {
            Common.showLoader(false);
			if(response.data.success){
                navigate("/payable-listing");
				setSucces(response.data.message);
			} else{
                setError(response.data.message);
            }

		    setTimeout(() => {
				setError('');
				setSucces('');
			}, 5000);
        }).catch(error => console.log(error));
    }
    const setFlatDueCalculation =(val)=>{
        setPaidAmount(val);
        let paid_amt = (val)?val:0;
        let dueAmount = amount - paid_amt;        
        let final_due_amount = (dueAmount >= 0?dueAmount:0);
        if (Math.sign(dueAmount) === -1) {                    
            let advance_amount = paid_amt - amount;
            setAdvanceAmount(advance_amount);
            setDueAmount(0);
            setShowAdvanceDiv(true);
        }else{
            setShowAdvanceDiv(false);
            setDueAmount(final_due_amount);
        }
    }
    const setPaymentModeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setPaymentMode(value);
		if(value === 'Cash'){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/'+value,
			}).then(function (response) {
				if(response.data.success){
					if(response.data.data !== ''){
						setBankCashAccount(1);
						setDefaultPayee(response.data.data);
						setBankAccount(response.data.data[0].value);
                        setBankList(response.data.data);
					}else{
						setBankCashAccount(0);
						setDefaultPayee([]);
						setBankAccount(0);
					}
				}
			}).catch(error => console.log(error));
		}else{
			setBankCashAccount(1);
			setDefaultPayee([]);
            setListOfBanks();
		}
	}
    const setListOfBanks = () => {
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				// setDefaultPayee(response.data.defaultPayee);
				// if (response.data.defaultPayee.length > 0) {
				// 	setBankAccount(response.data.defaultPayee[0].value);
				// }
			}
		}).catch(error => console.log(error));
	}
	const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setBankAccount(value);
		// setDefaultPayee('');
        if (value === '') {
			setDefaultPayee([]);
		} else {
			bankList.forEach((bank,i) => {
				if (bank.value === value) {
					setDefaultPayee([bank]);
				}
			});
		}
	}
  
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="edit-seller-payable" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Expenses & Payments /</span> Edit Payable</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                        {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                        {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>)}
                                        {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                        {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>)}
                                            <form id="formEditPayable" onSubmit={handleEditPayable} autoComplete="off" className='formEditPayable'>
                                                <div className="row">
                                                    <div className="mb-1 col-md-3 payable_label custome_list">
                                                        <label htmlFor="gstin" className="form-label">Seller Name</label>
                                                    </div>
                                                    <div className="mb-1 col-md-4">
                                                        <div className="input-group input-group-merge">
                                                            <span>{data.client_name}</span>
                                                        </div>
                                                        <input type='hidden' value={data.seller_id} name="seller_id"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-1 col-md-3 payable_label">
                                                        <label htmlFor="party_name" className="form-label">Phone Number</label>
                                                    </div>
                                                    <div className="mb-1 col-md-4">
                                                        <div className="input-group input-group-merge">
                                                            <span>{data.mobile}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-1 col-md-3 payable_label">
                                                        <label htmlFor="party_name" className="form-label">Amount</label>
                                                    </div>
                                                    <div className="mb-1 col-md-4">
                                                        <div className="input-group input-group-merge">
                                                            <span>{parseInt(data.due_amount)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-1 col-md-3 payable_label">
                                                        <label className="form-label">Invoice Count</label>
                                                    </div>
                                                    <div className="mb-1 col-md-4">
                                                        <div className="input-group input-group-merge">
                                                            <span>{parseInt(data.inv_count)}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="mb-1 col-md-3 payable_label">
                                                        <label htmlFor="party_name" className="form-label">Paid Amount <span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="mb-1 col-md-4">
                                                        <div className="input-group input-group-merge">
                                                            <input type="text" className="form-control" id="paid_amount" name="paid_amount" placeholder=""  value={paid_amount} onChange={(e) => setFlatDueCalculation(Common.acceptOnlyNumbers(e.target.value))} required inputMode="numeric"/>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1 col-md-2 payable_label">
                                                        <div className="input-group input-group-merge">
                                                        <label htmlFor="party_name" className="form-label">Due Amount: <span>{due_amount}</span></label>
                                                        <input type="hidden" id="due_amount" name="due_amount" value={due_amount} />
                                                        </div>
                                                    </div>                                                    
                                                    {showAdvanceDiv && (
                                                        <div className="row">
                                                            <div className="mb-1 col-md-3">
                                                            </div>
                                                            <div className="ms-2 col-md-8 payable_label" >
                                                                <div className="input-group input-group-merge">
                                                                <label htmlFor="party_name" className="form-label text-danger"><small><u>Note</u>: Amount paid is greater than due amount. Balance <b>{Common.displayPrice(advance_amount)}</b> will be added automatically to advance paid <Common.OverlayTrigger overlay={Common.popoverContent('','Last payment mode, payment date, payment remarks and transaction number will be considered for this advance amount.')}><i className='bx bxs-info-circle' ></i></Common.OverlayTrigger></small></label>
                                                                <input type="hidden" id="advance_amount" name="advance_amount" value={advance_amount} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="row">
                                                    <div className="mb-1 col-md-3 payable_label">
                                                        <label htmlFor="party_name" className="form-label">Payment Received On</label>
                                                    </div>
                                                    <div className="mb-1 col-md-4">
                                                        <div className="input-group input-group-merge from_datepicker">
                                                            <DatePicker name="payment_received_on" data-testid="payment_received_on" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setPaymentReceivedOn} value={payment_received_on} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-1 col-md-3 payable_label">
                                                        <label htmlFor="party_name" className="form-label">Payment Mode</label>
                                                    </div>
                                                    <div className="mb-1 col-md-4">
                                                        <Typeahead clearButton defaultSelected={paymentModes.slice(1, 2)} id="frmSalePayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
                                                            options={paymentModes}
                                                            renderMenuItemChildren={(option: Item) => (
                                                                <div>
                                                                    {option.label}
                                                                </div>
                                                            )}
                                                        />
                                                        <input type="text" hidden name="payment_mode" value={payment_mode} readOnly />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="row mt-3">
                                                        <div className="mb-1 col-md-3 collection_label">
                                                            <label htmlFor="frmBankAccount" className="form-label">Bank Account <span className="text-danger">*</span></label>
                                                        </div>
                                                        <div className="mb-1 col-md-4">
                                                            <Typeahead selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
                                                                options={bankList}
                                                                renderMenuItemChildren={(option: Item,props) => (
                                                                    <Highlighter search={props.text}>
                                                                        {option.label}
                                                                    </Highlighter>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="mb-1 col-md-3 collection_label">
                                                        </div>
                                                        <div className="mb-1 col-md-8">
                                                            <small>To add additional bank account, Go to Account management - <a href='/bank-accounts'>Bank accounts</a></small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-1 col-md-3 payable_label">
                                                        <label htmlFor="party_name" className="form-label">Transaction Ref.</label>
                                                    </div>
                                                    <div className="mb-1 col-md-4">
                                                        <div className="input-group input-group-merge">
                                                            <input type="text" className="form-control" id="trans_ref" name="trans_ref" placeholder=""  value={trans_ref} onChange={(e) => setTransRef(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-1 col-md-3 payable_label">
                                                        <label htmlFor="party_name" className="form-label">Payment Remarks</label>
                                                    </div>
                                                    <div className="mb-1 col-md-4">
                                                        <div className="input-group input-group-merge">
                                                            <textarea onChange={(e) => setPaymentRemarks(e.target.value)} className="form-control" id="PaymentRemarks" name="PaymentRemarks" value={PaymentRemarks}>
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                </div><br></br>
                                                <div className="row">
                                                    <div className="col-sm-7 text-right">
                                                        <a href="/payable-listing" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> <span className="hidden-phone">Back</span></a>
                                                        &nbsp;&nbsp;
                                                        {bank_cash_account === 1 && (<>
                                                            <button type='submit' className="btn btn-sm btn-primary" ><i className='bx bx-save' ></i> <span className="hidden-phone">Save</span></button>
                                                        </>)}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}