import React,{useState,useEffect} from 'react';

import Layout from './Layout';
import PartnerLayout from './PartnerLayout';
import NavBar from './NavBar';
import PartnerNavBar from './PartnerNavBar';
import Button from 'react-bootstrap/Button';
import * as Common from '../common/ImportUserEssentials';

export default function Profile(props){
    const [account_name, setAccountName] = useState('');
    const [mobile, setMobile]              = useState('');
    const [email, setEmail]        = useState('');

	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Profile | '+props.site_name;
        Common.axios({
            method: 'get',
            url:props.url+'user/details',
        }).then(function (response) {
            if(response.data.success){
                setAccountName(response.data.data.uname);
                setMobile(response.data.data.mobile); setEmail(response.data.data.email);
            }
        }).catch(error => console.log(error));
	}, [props]);

    const profileUpdate =(e) => {
        e.preventDefault();
        Common.axios({
          method: 'post',
          url:props.url+'user/update',
          data: {name:account_name},
        }).then(function (response) {
        if(response.data.success){
			Common.toast.success(response.data.message);
			return false;
		}
		Common.toast.error(response.data.message);
      }).catch(error => console.log(error));
    }
    return (
        <div className="layout-wrapper layout-menu-fixed layout-content-navbar">
            <div className="layout-container">
                {props.referrer === 'fynone' ? 
                    <Layout site_logo={props.site_logo} label="profile" site={props.site_name}/>
                    :
                    <PartnerLayout label="whats-new" {...props}/>
                }
                    <div className="layout-page">
                        {props.referrer === 'fynone' ? 
                            <NavBar site={props.site_name} url={props.url}/>
                            :
                            <PartnerNavBar {...props}/>
                        }
                            <div className="content-wrapper">
                                <div className="container-xxl flex-grow-1 container-p-y">
									<h4 className="fw-bold py-3 mb-3">My Details</h4>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card mb-4">
                                                {/*<h5 className="card-header">Profile Details</h5>*/}
                                                <div className="card-body mt-3">
                                                <form id="formAccountSettings" autoComplete="off" onSubmit={profileUpdate}>
                                                    <div className="row">
														<div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="userName">Name<span className="text-danger">*</span></label>
															<div className="input-group input-group-merge">
																<span className="input-group-text"><i className="bx bx-user"></i></span>
																<input type="text" className="form-control" id="userName" name="account_name" aria-label="Account Name" aria-describedby="basic-icon-default-fullname2" maxLength="50" required autoFocus value={account_name} onChange={(e) => setAccountName(e.target.value)}/>
															</div>
														</div>
														<div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="basic-icon-default-email">Mobile<span className="text-danger">*</span></label>
															<div className="input-group input-group-merge">
																<span className="input-group-text disabled-bg"><i className="bx bx-mobile"></i></span>
																<input type="text" id="basic-icon-default-email" tabIndex="-1" aria-disabled="true" className="form-control" placeholder="" aria-label="State" aria-describedby="basic-icon-default-mobile" value={mobile} required disabled/>
															</div>
														</div>
														<div className="mb-3 col-md-6">
															<label className="form-label" htmlFor="basic-icon-default-phone">Email</label>
															<div className="input-group input-group-merge">
																<span className="input-group-text disabled-bg"><i className="bx bx-envelope"></i></span>
																<input type="text" name="district" className="form-control" placeholder="" aria-label="District" value={email} tabIndex="-1" disabled/>
															</div>
														</div>
													</div>                      
													<div className="row mt-2">
														<div className="d-flex gap-2">
															<Button variant="info" size="sm" className="me-lg-2" onClick={() => navigate(-1)}><i className='bx bx-arrow-back'></i> Back</Button>
															<Button variant="primary" size="sm" type="submit"><span className="tf-icons bx bx-save"></span> Save</Button>
														</div>
													</div>
												</form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
					</div>
                    {props.referrer === 'fynone' ? 
                        <Common.FYNTicket url={props.url}/>
                        :
                        <Common.PartnerNeedHelp url={props.url}/>
                    }
                </div>
            </div>
        </div>
    );
}