import React from 'react'
import { Link  } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import {isMobile} from 'react-device-detect';
import Button from 'react-bootstrap/Button';
export default function Layout(props){
	const [accountMgmt_r,setaccountMgmt_r] = React.useState(true);
	const [incomeColl_r,setincomeColl_r] = React.useState(true);
	//const [incomeColl_w,setincomeColl_w] = React.useState(true);
	const [expensePay_r,setexpensePay_r] = React.useState(true);
	//const [expensePay_w,setexpensePay_w] = React.useState(true);
	const [inventoryMgmt_r,setinventoryMgmt_r] = React.useState(true);
	//const [inventoryMgmt_w,setinventoryMgmt_w] = React.useState(true);
	const [userMgmt_r,setuserMgmt_r] = React.useState(true);
	//const [userMgmt_w,setuserMgmt_w] = React.useState(true);
	// const [subscription_r,setsubscription_r] = React.useState(true);
	const [taxCompliance_r,setTaxCompliance_r] = React.useState(true);
	const [custSellMgmt_r,setCustSellMgmt_r] = React.useState(true);
	// const [custSellMgmt_w,setCustSellMgmt_w] = React.useState(true);
	const [loanInvestment_r,setLoanInvestment_r] = React.useState(true);
	// const [loanInvestment_w,setLoanInvestment_w] = React.useState(true);
	const [assetsMgmt_r,setAssetsMgmt_r] = React.useState(true);
	const [financialMetrics_r,setFinancialMetrics_r] = React.useState(true);
	const [mart_r,setMart_r] = React.useState(true);
	// const [financialMetrics_w,setFinancialMetrics_w] = React.useState(true);
	const [alertModal, setAlertModal] = React.useState(false);
	const [transactions_r,setTransactions_r] = React.useState(true);
	// const { state } = Common.useLocation();
	const navigate = Common.useNavigate();

	React.useEffect(() => {
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if (localStorage.getItem('fo_subplan') === '') {
			navigate('/pricing-plan');
		}
		// if ((localStorage.getItem('fo_subplan') === 'Free' && localStorage.getItem('fo_mart_url') === "1") ) {
		// 	navigate('/pricing-plan');
		// }
		if (localStorage.getItem('fo_subplan') === 'Free' && localStorage.getItem('fo_mart_url') === "0") {
			navigate('/mart');
		}

		if (localStorage.getItem('fo_subplan') === 'Basic' && localStorage.getItem('fo_collaboration') === '0') {
			if (props.label === 'user-listing' || props.label === 'gst' || props.label === 'user-groups') {
				setAlertModal(true)
			}
		} else {
			setAlertModal(false)
		}
		if( userPerm && Object.keys(userPerm).length > 0 ){
			//Read
			setaccountMgmt_r((userPerm[1]['view'] !== undefined && userPerm[1]['view'] === '0') ? false : true);
			setincomeColl_r((userPerm[2]['view'] !== undefined && userPerm[2]['view'] === '0') ? false : true);
			setexpensePay_r((userPerm[3]['view'] !== undefined && userPerm[3]['view'] === '0') ? false : true);
			setinventoryMgmt_r((userPerm[4]['view'] !== undefined && userPerm[4]['view'] === '0') ? false : true);
			setuserMgmt_r((userPerm[5]['view'] !== undefined && userPerm[5]['view'] === '0') ? false : true);
			// setsubscription_r((userPerm[6]['view'] !== undefined && userPerm[6]['view'] === '0') ? false : true);
			setTaxCompliance_r((userPerm[6]['view'] !== undefined && userPerm[6]['view'] === '0') ? false : true);
			setCustSellMgmt_r((userPerm[7]['view'] !== undefined && userPerm[7]['view'] === '0') ? false : true);
			setLoanInvestment_r((userPerm[8]['view'] !== undefined && userPerm[8]['view'] === '0') ? false : true);
			setAssetsMgmt_r((userPerm[9]['view'] !== undefined && userPerm[9]['view'] === '0') ? false : true);
			setFinancialMetrics_r((userPerm[10]['view'] !== undefined && userPerm[10]['view'] === '0') ? false : true);
			setMart_r((userPerm[11]['view'] !== undefined && userPerm[11]['view'] === '0') ? false : true);
			setTransactions_r((userPerm[12]['view'] !== undefined && userPerm[12]['view'] === '0') ? false : true);
			
			//Write
			//setincomeColl_w((userPerm[2]['write'] !== undefined && userPerm[2]['write'] === '0') ? false : true);
			//setexpensePay_w((userPerm[3]['write'] !== undefined && userPerm[3]['write'] === '0') ? false : true);
			//setinventoryMgmt_w((userPerm[4]['write'] !== undefined && userPerm[4]['write'] === '0') ? false : true);
			//setuserMgmt_w((userPerm[5]['write'] !== undefined && userPerm[5]['write'] === '0') ? false : true);
			// setCustSellMgmt_w((userPerm[6]['write'] !== undefined && userPerm[6]['write'] === '0') ? false : true);
			// setLoanInvestment_w((userPerm[8]['write'] !== undefined && userPerm[8]['write'] === '0') ? false : true);
			// setAssetsMgmt_w((userPerm[9]['write'] !== undefined && userPerm[9]['write'] === '0') ? false : true);
			// setFinancialMetrics_w((userPerm[10]['write'] !== undefined && userPerm[10]['write'] === '0') ? false : true);
		}
		if( userPerm !== null ){
			const script = document.createElement('script');
			const script1 = document.createElement('script');
			script.type = "application/javascript";
			script.src = "assets/vendor/js/menu.js";
			//script.async = true;
			document.body.appendChild(script);
			
			script.addEventListener('load', () => {
				script1.type = "application/javascript";
				script1.src = "assets/js/main.js";
				script1.async = true;
				document.body.appendChild(script1);
			})
			setTimeout(function(){ 
				document.body.removeChild(script);
				document.body.removeChild(script1);
			}, 1500);
		}
	},[]);
	const handleClick = (e) => {
		e.preventDefault()
		if (localStorage.getItem('fo_subplan') === 'Free' && localStorage.getItem('fo_collaboration') === '0') {
			setAlertModal(true)
		} else {
			setAlertModal(false)
		}
	}
	const handleRedirection = () => {
		if (props.label === 'pricing_plan') {
			window.location.reload();
		} else {
			navigate("/pricing-plan");
			return false;
		}
	}
    return (
		<aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
			<div className="app-brand demo">
				<a href="/" className="app-brand-link">
					<span className="app-brand-logo demo">
						<img alt="FYNONE Logo" height="70" width="auto" src={props.site_logo}></img>
					</span>
					{/* <span className="app-brand-text demo menu-text fw-bolder ms-2">{props.site}</span> */}
				</a>
				<a href="#" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
					<i className="bx bx-chevron-left bx-sm align-middle"></i>
				</a>
			</div>
			<div className="menu-inner-shadow"></div>
			<ul className="menu-inner py-1">				
				{/* <li className="menu-header small text-uppercase">
					<span className="menu-header-text">Menu</span>
				</li> */}
				<li className="menu-header small text-uppercase" style={{'margin': '0'}}><span className="menu-header-text">Basic {localStorage.getItem('fo_subplan') === 'Free' ? <>
							<i className='bx bxs-lock text-danger'></i>
						</> : <></>}</span></li>
				{accountMgmt_r && (<>
				<li className={(props.label === "profile" || props.label === "bank-accounts" || props.label === "add-bank-account" || props.label === "edit-bank-account" || props.label === "document-manager" || props.label === "data-security" || props.label === "pricing_plan" || props.label === "sale-settings") ? "menu-item active open" : "menu-item"}>
					<a href="#" className="menu-link menu-toggle">
						<i className="menu-icon tf-icons bx bxs-user"></i>
						<div data-i18n="Account Settings">Account Mgmt</div>
					</a>
					<ul className="menu-sub">
						{/*<li className={props.label === "profiles" ? "menu-item active" : "menu-item"}>
						<Link to="/profiles"  className="menu-link menu-link-toggle"><div data-i18n="Profiles">Profiles</div></Link>
						</li> */}
						{/* <li className={props.label === "invoice-remarks"? "menu-item active" : "menu-item"}>
						<Link to="/invoice-remarks"  className="menu-link menu-link-toggle"><div data-i18n="Invoice Terms">Invoice Terms</div></Link>
						</li> */}
						<li className={(props.label === "data-security") ? "menu-item active" : "menu-item"}>
						<Link to="/data-security"  className="menu-link menu-link-toggle"><div data-i18n="Data Security">Data Security</div></Link>							
						</li>
						<li className={(props.label === "bank-accounts" || props.label === "add-bank-account") ? "menu-item active" : "menu-item"}>
						<Link to="/bank-accounts"  className="menu-link menu-link-toggle"><div data-i18n="Banking">Banking</div></Link>							
						</li>
						<li className={(props.label === "document-manager" || props.label === "add-document-manager") ? "menu-item active" : "menu-item"}>
						<Link to="/document-manager"  className="menu-link menu-link-toggle"><div data-i18n="Document Manager">Document Manager</div></Link>
						</li>
						<li className={(props.label === "pricing_plan") ? "menu-item active" : "menu-item" }>
							<Link to="/pricing-plan"  className="menu-link menu-link-toggle"><div data-i18n="Pricing Plan">Pricing Plan</div></Link>					
						</li>
						<li className={props.label === "sale-settings" ? "menu-item active" : "menu-item"}>
							<Link to="/sale-settings" className="menu-link menu-link-toggle">
								<div data-i18n="Settings">Settings</div>
							</Link>
						</li>
					</ul>
				</li>
				</>)}
				{financialMetrics_r && (<>
				<li className={(props.label === "financial-summary" || props.label === "profit-loss-statement") ? "menu-item active open" : "menu-item"}>
					<a href="#" className="menu-link menu-toggle">
						<i className="menu-icon tf-icons bx bx-money"></i>
						<div data-i18n="Financial Metrics">Financial Metrics</div>
					</a>
					<ul className="menu-sub">
						<li className={(props.label === "financial-summary") ? "menu-item active" : "menu-item"}>
						<Link to="/financial-summary"  className="menu-link menu-link-toggle"><div data-i18n="Summary">Summary</div></Link>							
						</li>
						<li className={(props.label === "profit-loss-statement") ? "menu-item active" : "menu-item"}>
						<Link to="/profit-loss-statement"  className="menu-link menu-link-toggle"><div data-i18n="P&L Statement">P&L Statement</div></Link>							
						</li>
					</ul>
				</li>
				</>)}
				{incomeColl_r && (<>
				<li className={(props.label === "po-contract" || props.label === "add-sale" || props.label === "copy-sale"  || props.label === "sales-listing" || props.label === "collection-listing" || props.label === "new-collection" || props.label === "edit-collection" || props.label === "edit-customer-collection" || props.label === "quotation" || props.label === "add-quotation" || props.label === 'dashboard' || props.label === 'convert-sale-order') || props.label === "other-income" ? "menu-item active open" : "menu-item"}>
					<a href="#" className="menu-link menu-toggle">
						<i className="menu-icon tf-icons bx bx-rupee"></i>
						<div data-i18n="Income & Collections">Income & Collections</div>
					</a>
					<ul className="menu-sub">
						
						<li className={props.label === "quotation" ? "menu-item active" : "menu-item"}>
						<Link to="/quotation" className="menu-link menu-link-toggle">
							<div data-i18n="Quotations">Quotations and Sale Orders</div>
						</Link>
						</li>
						<li className={props.label === "po-contract" ? "menu-item active" : "menu-item"}>
						<Link to="/po-contract" className="menu-link menu-link-toggle">
							<div data-i18n="PO/Contract details">PO/Contract details</div>
						</Link>
						</li>
						<li className={props.label === "sales-listing" || props.label === "add-sale" ? "menu-item active" : "menu-item"}>
						<Link to="/sales-listing" className="menu-link menu-link-toggle">
							<div data-i18n="Created Bills">Add/View Invoice</div>
						</Link>
						</li>
						<li className={(props.label === "collection-listing" || props.label === "new-collection" || props.label === "edit-collection" || props.label === "edit-customer-collection" || props.label === "other-income") ? "menu-item active" : "menu-item"}>
						<Link to="/collection-listing" className="menu-link menu-link-toggle">
							<div data-i18n="Collection Listing">Collection Listing</div>
						</Link>
						</li>
						
						<li className={(props.label === "dashboard") ? "menu-item active" : "menu-item"}>
						<Link to="/dashboard" className="menu-link menu-link-toggle">
							<div data-i18n="Dashboard">Income Dashboard</div>
						</Link>
						</li>
					</ul>
				</li>
				</>)}
				{expensePay_r && (<>
				<li className={(props.label === "add-expense" || props.label === "expense-listing" || props.label === "edit-seller" || props.label === "payable-listing" || props.label === "add-payable" || props.label === "edit-payable" || props.label === "edit-seller-payable" || props.label === "expense-dashboard") || props.label === "other-expense" || props.label === 'po-listing' || props.label === 'add-po' ? "menu-item active open" : "menu-item"}>
				<a href="#" className="menu-link menu-toggle">
						<i className="menu-icon tf-icons bx bx-purchase-tag"></i>
						<div data-i18n="Expenses & Payments">Expenses & Payments</div>
					</a>
					<ul className="menu-sub">
						<li className={props.label === "po-listing" || props.label === "add-po" ? "menu-item active" : "menu-item"}>
							<Link to="/po-listing" className="menu-link menu-link-toggle">
								<div data-i18n="Created PO Contracts">Add/View PO Contract </div>
							</Link>
						</li>
						<li className={props.label === "expense-listing" || props.label === "add-expense" ? "menu-item active" : "menu-item"}>
							<Link to="/expense-listing" className="menu-link menu-link-toggle">
								<div data-i18n="Created Bills">Add/View Invoice </div>
							</Link>
						</li>
						<li className={(props.label === "payable-listing" || props.label === "add-payable" || props.label === "edit-payable" || props.label === "edit-seller-payable") ? "menu-item active" : "menu-item"}>
							<Link to="/payable-listing" className="menu-link menu-link-toggle">
								<div data-i18n="Payable Listing">Payable Listing</div>
							</Link>
						</li>
						
						<li className={(props.label === "expense-dashboard") ? "menu-item active" : "menu-item"}>
							<Link to="/expense-dashboard" className="menu-link menu-link-toggle">
								<div data-i18n="ExpenseDashboard">Expense Dashboard</div>
							</Link>
						</li>
					</ul>
				</li>
				</>)}
				{inventoryMgmt_r && (<>
				<li className={(props.label === "item-listing" || props.label === "add-item" || props.label === "dispatch-stock" || props.label === "edit-item" ) ? "menu-item active open" : "menu-item"}>
				<a href="#" className="menu-link menu-toggle">
						<i className="menu-icon tf-icons bx bx-store"></i>
						<div data-i18n="Inventory Management">Inventory management</div>
					</a>
					<ul className="menu-sub">
						<li className={props.label === "item-listing" || props.label === "add-item" || props.label === "edit-item" ? "menu-item active" : "menu-item"}>
							<Link to="/item-listing" className="menu-link menu-link-toggle">
								<div data-i18n="Item Listing">Add/View Item</div>
							</Link>
						</li>
						<li className={props.label === "dispatch-stock" ? "menu-item active" : "menu-item"}>
							<Link to="/dispatch-stock" className="menu-link menu-link-toggle">
								<div data-i18n="Dispatch Stock">Dispatch Stock</div>
							</Link>
						</li>
					</ul>
				</li>
				</>)}
				{userMgmt_r && (<>
				<li className={(props.label === "user-listing"|| props.label === "add-user" || props.label === "user-groups" ) ? "menu-item active open" : "menu-item"}>
					<a href="#" className="menu-link menu-toggle" onClick={(e) => handleClick(e)}>
						<i className="menu-icon tf-icons bx bxs-user-account"></i>
						<div data-i18n="User Management">User management</div>
					</a>
					<ul className="menu-sub">
						<li className={(props.label === "user-listing" || props.label === "add-user") ? "menu-item active" : "menu-item"}>
							<Link to="/user-listing" className="menu-link menu-link-toggle">
								<div data-i18n="User Listing">Add/View User</div>
							</Link>
						</li>
						<li className={(props.label === "user-groups") ? "menu-item active" : "menu-item"}>
							<Link to="/user-groups" className="menu-link menu-link-toggle">
								<div data-i18n="User Groups">User Groups</div>
							</Link>
						</li>
					</ul>
				</li>
				</>)}
				{custSellMgmt_r && (<>
				{/* <li className={props.label === "subscription" ? "menu-item active open" : "menu-item"}> */}
				<li className={(props.label === "customer-listing" || props.label === "seller-listing" || props.label === "add-seller" || props.label === "edit-seller" || props.label === "new-customer" || props.label === "edit-customer") ? "menu-item active open" : "menu-item"}>
					<a href="#" className="menu-link menu-toggle">
						<i className='menu-icon bx bxs-user-detail'></i>
						<div data-i18n="Customer and Seller mgmt">Customer and Seller mgmt</div>
					</a>
					<ul className="menu-sub">
						<li className={(props.label === "customer-listing" || props.label === "new-customer" || props.label === "edit-customer") ? "menu-item active" : "menu-item"}>
							<Link to="/customer-listing" className="menu-link menu-link-toggle">
								<div data-i18n="Customer Listing">Customer Listing</div>
							</Link>
						</li>
						<li className={(props.label === "seller-listing" || props.label === "add-seller" || props.label === "edit-seller") ? "menu-item active" : "menu-item"}>
							<Link to="/seller-listing" className="menu-link menu-link-toggle">
								<div data-i18n="Seller Listing">Seller Listing</div>
							</Link>
						</li>
					</ul>
				</li>
				</>)}
				{localStorage.getItem('fo_subplan') === 'Basic' && localStorage.getItem('fo_userrole') === 'Sub-User' ? <>
				
				</> : <>
					<li className="menu-header small text-uppercase" style={{'margin': '0'}}><span className="menu-header-text">Standard &nbsp;
					{localStorage.getItem('fo_subplan') === 'Basic' || localStorage.getItem('fo_subplan') === 'Free' ? <>
							<i className='bx bxs-lock text-danger'></i>
						</> : <></>}
					</span></li>
				</>}
				{loanInvestment_r && (<>
				<li className={(props.label === "loan-listing" || props.label === "add-loan" || props.label === "add-loan-given" || props.label === "investments-listing" || props.label === "add-investments" ) ? "menu-item active open" : "menu-item"}>
					{localStorage.getItem('fo_subplan') === 'Basic' ? <>

					<a href="#" className="menu-link menu-toggle" onClick={() => setAlertModal(true)}>
							<i className="menu-icon tf-icons bx bxs-coin-stack"></i>
							<div data-i18n="Loans & Investments">Loans & Investments</div>
						</a>
					</> : <>
					<a href="#" className="menu-link menu-toggle">
							<i className="menu-icon tf-icons bx bxs-coin-stack"></i>
							<div data-i18n="Loans & Investments">Loans & Investments</div>
						</a>
					</>}
					<ul className="menu-sub">
						<li className={props.label === "loan-listing" || props.label === "add-loan" ? "menu-item active" : "menu-item"}>
							<Link to="/loan-listing" className="menu-link menu-link-toggle">
								<div data-i18n="Item Listing">Loans</div>
							</Link>
						</li>
						<li className={props.label === "investments-listing" || props.label === "add-investments" ? "menu-item active" : "menu-item"}>
							<Link to="/investments-listing" className="menu-link menu-link-toggle">
								<div data-i18n="Investments Listing">Investments</div>
							</Link>
						</li>
					</ul>
				</li>
				</>)}
				{assetsMgmt_r && (<>
				<li className={(props.label === "assets-listing" || props.label === "add-asset" ) ? "menu-item active open" : "menu-item"}>
				{localStorage.getItem('fo_subplan') === 'Basic' ? <>
				<a href="#" className="menu-link menu-toggle" onClick={() => setAlertModal(true)}>
						<i className="menu-icon tf-icons bi bi-boxes"></i>
						<div data-i18n="Assets Management">Assets Management</div>
					</a>
				</> : <>
				<a href="#" className="menu-link menu-toggle">
						<i className="menu-icon tf-icons bi bi-boxes"></i>
						<div data-i18n="Assets Management">Assets Management</div>
					</a>
				</>}
					<ul className="menu-sub">
						<li className={props.label === "assets-listing" || props.label === "add-asset" ? "menu-item active" : "menu-item"}>
							<Link to="/assets-listing" className="menu-link menu-link-toggle">
								<div data-i18n="Item Listing">Add/view assets</div>
							</Link>
						</li>
					</ul>
				</li>
				</>)}
				{transactions_r && (<>
				<li className={(props.label === "transactions" || props.label === "journal-ledger") ? "menu-item active open" : "menu-item"}>
					{localStorage.getItem('fo_subplan') === 'Basic' ? <>
					<a href="#" className="menu-link menu-toggle" onClick={() => setAlertModal(true)}>
						<i className="menu-icon tf-icons bx bxs-bank"></i>
						<div data-i18n="Accounting">Accounting</div>
					</a>
					</> : <>
					<a href="#" className="menu-link menu-toggle">
						<i className="menu-icon tf-icons bx bxs-bank"></i>
						<div data-i18n="Accounting">Accounting</div>
					</a>
					</>}
				
					<ul className="menu-sub">
						<li className={props.label === "transactions" ? "menu-item active" : "menu-item"}>
							<Link to="/transactions" className="menu-link menu-link-toggle">
								<div data-i18n="Transaction Listing">Unposted Transactions</div>
							</Link>
						</li>
						<li className={props.label === "journal-ledger" ? "menu-item active" : "menu-item"}>
							<Link to="/journal-ledger" className="menu-link menu-link-toggle">
								<div data-i18n="Transaction Listing">Chart of Accounts</div>
							</Link>
						</li>
					</ul>
				</li>
				</>)}
				{(localStorage.getItem('fo_subplan') === 'Basic' || localStorage.getItem('fo_subplan') === 'Standard')  && localStorage.getItem('fo_userrole') === 'Sub-User' ? <>
				
				</> : <>
					<li className="menu-header small text-uppercase" style={{'margin': '0'}}>
						<span className="menu-header-text">
						PRO &nbsp;
						{localStorage.getItem('fo_subplan') === 'Free' || localStorage.getItem('fo_subplan') === 'Basic' || localStorage.getItem('fo_subplan') === 'Standard' ? <>
							<i className='bx bxs-lock text-danger'></i>
						</> : <></>}
						</span>
					</li>
				</>}
				{taxCompliance_r && (<>
				{/* <li className={props.label === "subscription" ? "menu-item active open" : "menu-item"}> */}
				<li className={(props.label === "tax_compliance") ? "menu-item active open" : "menu-item"}>
					{localStorage.getItem('fo_subplan') === 'Basic' || localStorage.getItem('fo_subplan') === 'Standard' ? <>
					<a href="#" className="menu-link menu-toggle" onClick={() => setAlertModal(true)}>
						<i className='menu-icon bx bx-notepad'></i>
						<div data-i18n="Tax Compliance">Tax Compliance</div>
					</a>
					</> : <>
					<a href="#" className="menu-link menu-toggle">
						<i className='menu-icon bx bx-notepad'></i>
						<div data-i18n="Tax Compliance">Tax Compliance</div>
					</a>
					</>}
					<ul className="menu-sub">
						 <li className={(props.label === "tax_compliance") ? "menu-item active" : "menu-item"}>
							<Link to="/gst" className="menu-link menu-link-toggle">
								<div data-i18n="GST">GST</div>
							</Link>
						</li>
					</ul>
				</li>
				</>)}
				<li className="menu-header small text-uppercase" style={{'margin': '0'}}><span className="menu-header-text">Extensions &nbsp;</span></li>
				{mart_r && (<>
				<li className="menu-item">
					{ !isMobile ?
					<a href="/mart" target="_blank" rel="noreferrer" className="menu-link">
						<i className='menu-icon bx bxs-store-alt'></i>
						<div data-i18n="FynMart - eCommerce & POS">FynMart - eCommerce & POS</div>
					</a>
					:
					<a href="/mart" className="menu-link">
						<i className='menu-icon bx bxs-store-alt'></i>
						<div data-i18n="FynMart - eCommerce & POS">FynMart - eCommerce & POS</div>
					</a>
					}
				</li>
				</>)}
			</ul>
			<Modal show={alertModal} size='sm' onHide={()=>setAlertModal(false)} aria-labelledby="alert-modal" backdrop="static" keyboard={false} centered>
			<Modal.Body>
				<div className="my-1">
					{/* Select options below to add more details to your report */}
					{/* This plan supports only single Owner. For unlimited users, please upgrade the subscription plan */}
					This plan supports only {localStorage.getItem('fo_subplan')} features. For access to more modules, please upgrade the subscription plan
					<div className="my-3">
						<div className="row justify-content-md-center">
							<div className="offset-sm-1 col-sm-6">
								<Button variant="primary" size="sm" onClick={handleRedirection}>Pricing Plan</Button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
		</aside>
	);
}