const doc_types = [
	{
		label: 'Invoice (INV)',
		value: 'INV'
	},
	{
		label: 'Credit Note (CRN)',
		value: 'CRN'
	},
	{
		label: 'Debit Note (DBN)',
		value: 'DBN'
	}
];
export default doc_types;