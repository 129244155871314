import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import * as Common from '../common/ImportUserEssentials';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

export default function AddBankAccount(props){
	const [payee_name, setPayeeName]                    = useState('');
	const [account_no, setAccountNo]                    = useState('');
	const [ifsc_code, setIfscCode]                      = useState('');
	const [swift_code, setSwiftCode]                    = useState('');
	const [bank_name, setBankName]                      = useState('');
    const [payment_terms,setPaymentTerms]               = useState('');
    const [payment_instructions,setPaymentInstructions] = useState('');
    const [nickname,setNickname]                        = useState('');
    const [bankBranchName,setBankBranchName]            = useState('');
    const [acc_type,setAcctype]                         = useState('current');    
    const [activeType, setActiveType]                   = useState('B');
	const [expire_date, setExpireDate]                  = useState('');
    const [enableBankPassbook, setEnableBankPassbook]   = useState('1');
	const [bankPassbookChecked,setBankPassbookChecked]  = useState(true);
    const [opening_balance,setOpeningBalance]           = useState('');
	const [opening_balance_date, setOpeningBalanceDate] = useState(new Date());
    // const [bank_cash_account,setBankCashAccount]        = useState(0);
    // const [bank_cash_data,setBankCashData]              = useState('');
    const [interestRate,setInterestRate]              = useState(0);
    const [maturityDate,setMaturityDate]              = useState('');
    const [maturityAmount,setMaturityAmount]            = useState('');

    const [dividerDisplay, setDividerDisplay] = useState(true);
	const [additionalItemDiv, setAdditionalItemDiv] = useState(false);
    	
	const [accountMgmt_w,setaccountMgmt_w] = React.useState(true);
	const navigate = Common.useNavigate();
	useEffect(() => {
        // User permissions
	    let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		document.title = 'Add Bank Account | '+props.site_name;
		if( userPerm && Object.keys(userPerm).length > 0 ){
			setaccountMgmt_w((userPerm[1]['write'] !== undefined && userPerm[1]['write'] === '0') ? false : true);
		}
        setDividerDisplay(true);
        // Common.axios({
		// 	method: 'get',
		// 	url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/0',
		// }).then(function (response) {
		// 	if(response.data.success){
		// 		setBankCashAccount(response.data.data);
        //         setBankCashData(response.data.details);
		// 	}
		// }).catch(error => console.log(error));
	}, []);
	if( !accountMgmt_w ){
		navigate("/not-authorized");
	}
    const handleSubmit = (e)=>{
        e.preventDefault();
        console.log('here f')
        Common.showLoader(true);
        if (interestRate > 100) {
            Common.showLoader(false);
            Common.toast.error('Interest Rate cannot be more than 100%');
            return false
        }
        Common.axios({
            method: 'post',
            url:props.url+'bank-accounts/add',
            data: {
                nickname:nickname,payee_name:payee_name,account_no:account_no,ifsc_code:ifsc_code,bank_name:bank_name,payment_terms:payment_terms,payment_instructions,pid:localStorage.getItem('fo_profileid'),bank_branch_name:bankBranchName,acc_type:acc_type,card_expire_date:expire_date,type:activeType,opening_balance:opening_balance,opening_balance_date:opening_balance_date,enableBankPassbook:enableBankPassbook,interest_rate:interestRate,maturity_date:maturityDate,maturity_amt:maturityAmount,swift_code:swift_code
            },
        }).then(function (response) {
            Common.showLoader(false);
			if(response.data.success){
                navigate("/bank-accounts");
				return false;
			}
			Common.toast.dismiss();
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    const handleTab = checked => {
		setActiveType(checked);
        if(checked === 'B'){
            setAcctype('current');
        }else{
            setAcctype('');
        }
        if(checked === 'R'){
            setPayeeName('Cash');
            setBankName('Cash');
            // setNickname('Cash');
        }else{
            setPayeeName('');
            setBankName('');
            setNickname('');
        }

        if (checked === 'F') { // check this
            setAcctype('Fixed Deposit')
        } else {
            setAcctype('')
        }
	};
    const getEnableBankPassbook = () => {
		setEnableBankPassbook((enableBankPassbook === '1') ? '0' : '1');
		setBankPassbookChecked((enableBankPassbook === '1') ? false : true);
	}
    const formatString= (value) => {
      
        let output = value.replace(
          /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
        ).replace(
          /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
        ).replace(
          /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
        ).replace(
          /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
        ).replace(
          /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
        ).replace(
          /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
        ).replace(
          /\/\//g, '/' // Prevent entering more than 1 `/`
        );
        setExpireDate(output);
      }
    const showAdditionalItemFields = () => {
		setAdditionalItemDiv( (additionalItemDiv) ? false : true );
	}

    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="add-bank-account" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
						<h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Account Management / <a href="/bank-accounts" className="text-muted">Bank Accounts</a> /</span> Add Bank Account</h4>
                            <div className="row">
                                <div className="col-md-12 col-12 text-center mb-1">
                                    <div className="form-check form-check-inline">
                                        <input id="bank_account" className="form-check-input" type="radio" value="B"  required checked={activeType === 'B'} onChange={(e) => handleTab(e.target.value)} />
                                        <label className="form-check-label" htmlFor="bank_account"> Bank Account</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="credit_card" className="form-check-input" type="radio" value="C" required  checked={activeType === 'C'} onChange={(e) => handleTab(e.target.value)} />
                                        <label className="form-check-label" htmlFor="credit_card"> Credit card</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="prepaid_instruments" className="form-check-input" type="radio" value="P" required  checked={activeType === 'P'} onChange={(e) => handleTab(e.target.value)} />
                                        <label className="form-check-label" htmlFor="prepaid_instruments"> Prepaid Instruments</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="cash" className="form-check-input" type="radio" value="R" required  checked={activeType === 'R'} onChange={(e) => handleTab(e.target.value)} />
                                        <label className="form-check-label" htmlFor="cash"> Cash</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="fixed_deposit" className="form-check-input" type="radio" value="F" required  checked={activeType === 'F'} onChange={(e) => handleTab(e.target.value)} />
                                        <label className="form-check-label" htmlFor="fixed_deposit"> Deposits</label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            {activeType === 'B' && (<>
                                                <form id="formBankAccount" autoComplete="off" onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="payee_name" className="form-label">Account Holder Name<span className="text-danger">*</span></label>
                                                            <input autoFocus type="text" className="form-control" id="payee_name" name="payee_name" placeholder="Max 100 chars" maxLength="100" value={payee_name} onChange={(e) => setPayeeName(Common.acceptOnlyChars(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="account_no" className="form-label">Account Number<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="account_no" name="account_no" maxLength="20"  placeholder="Max 20 chars"  value={account_no} onChange={(e) => setAccountNo(Common.acceptOnlyNumbers(e.target.value))} inputMode="numeric" required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="ifsc_code" className="form-label">IFSC Code<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="ifsc_code" name="ifsc_code" placeholder="Max 11 chars" maxLength="11" value={ifsc_code} onChange={(e) => setIfscCode(e.target.value.toUpperCase())} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="ifsc_code" className="form-label">Swift Code</label>
                                                            <input type="text" className="form-control" id="swift_code" name="swift_code" placeholder="Max 11 chars" maxLength="11" value={swift_code} onChange={(e) => setSwiftCode(e.target.value.toUpperCase())}/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="bank_name" className="form-label">Bank Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="bank_name" name="bank_name" placeholder="Max 100 chars" maxLength="100" value={bank_name} onChange={(e) => setBankName(Common.acceptOnlyChars(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="bank_branch_name" className="form-label">Bank Branch Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="bank_branch_name" name="bank_branch_name" placeholder="Max 100 chars" maxLength="100" value={bankBranchName} onChange={(e) => setBankBranchName(Common.acceptOnlyCharsNumbers(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="acc_type" className="form-label">Account Type<span className="text-danger">*</span></label>
                                                            {/* <input type="text" className="form-control" id="acc_type" name="acc_type" placeholder="Max 100 chars" maxLength="100" value={bankBranchName} onChange={(e) => setBankBranchName(Common.acceptOnlyCharsNumbers(e.target.value))} required/> */}
                                                            <select id="acc_type" className="form-select" name='acc_type'  value={acc_type} onChange={(e) => setAcctype(e.target.value)} required>
                                                                <option value="Current Account">Current Account</option>
                                                                <option value="Savings Account">Savings Account</option>
                                                                <option value="Salary Account">Salary Account</option>
                                                                <option value="NRI Accounts">NRI Accounts</option>
                                                                <option value="Overdraft">Overdraft</option>
                                                                <option value="Term Loan">Term Loan</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="nickname" className="form-label">Nick Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="nickname" name="nickname" placeholder="Max 100 chars" maxLength="100" value={nickname} onChange={(e) => setNickname(Common.acceptOnlyCharsNumbers(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label className="pt-4">
                                                                <input className="form-check-input me-1" type="checkbox" value={enableBankPassbook} onChange={getEnableBankPassbook} name='item_stock_movement' checked={bankPassbookChecked}/> Enable Bank Passbook
                                                            </label>
                                                        </div>
                                                        {bankPassbookChecked && (<>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="frmOpeningBalance" className="form-label">Opening Balance<span className="text-danger">*</span></label>
                                                                <input name="opening_balance" type="number" className="form-control" id="frmOpeningBalance" placeholder="Enter Opening Balance" value={opening_balance} onChange={(e) => setOpeningBalance(e.target.value)} required/>
                                                                <small>Note: All collections & payments done before entering the opening balance date will not be considered in the passbook</small>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="frmOpeningBalanceDate" className="form-label">Opening Balance Date<span className="text-danger">*</span></label>
                                                                <DatePicker name="opening_balance_date" calendarIcon={null} clearIcon={null} disableCalendar={true} data-testid="frmOpeningBalanceDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningBalanceDate} value={opening_balance_date} disabled />
                                                            </div>
                                                        </>)}
                                                        <div className={"row " + (dividerDisplay ? 'd-block':'d-none')}>
                                                            <div className="col">
                                                                <div className="divider">
                                                                    <div className="divider-text"><i className={'bx bx-chevrons-'+ (additionalItemDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showAdditionalItemFields}>{additionalItemDiv ? 'Hide':'Show'} Payment Terms/Instructions</Common.Link><i className={'bx bx-chevrons-'+ (additionalItemDiv ? "up":"down")+' bx-fade-up'}></i></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {additionalItemDiv &&  <>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="payment_terms" className="form-label">Payment Terms</label>
                                                                <textarea rows="5" maxLength="250" id="payment_terms" name="payment_terms" className="form-control" placeholder="Payment Terms" aria-label="Payment Terms" value={payment_terms} onChange={(e) => setPaymentTerms(e.target.value)} ></textarea>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="payment_instructions" className="form-label">Payment Instruction</label>
                                                                <textarea rows="5" maxLength="250" id="payment_instructions" name="payment_instructions" className="form-control" placeholder="Payment Instructions" aria-label="Payment Instructions" value={payment_instructions} onChange={(e) => setPaymentInstructions(e.target.value)} ></textarea>
                                                            </div>
                                                        </>}
                                                    </div>
                                                    <div className="row ">
                                                        <div className="d-flex gap-2">
                                                            <a href="/bank-accounts" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                            <button type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>)}
                                            {activeType === 'C' && (<>
                                                <form id="formBankAccount" autoComplete="off" onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="payee_name" className="form-label">Credit card holder name<span className="text-danger">*</span></label>
                                                            <input autoFocus type="text" className="form-control" id="payee_name" name="payee_name" placeholder="Max 100 chars" maxLength="100" value={payee_name} onChange={(e) => setPayeeName(Common.acceptOnlyChars(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="account_no" className="form-label">Credit card number (Last 4 Digits)<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="account_no" name="account_no" minLength="4" maxLength="4"  placeholder="Max 4 chars"  value={account_no} onChange={(e) => setAccountNo(Common.acceptOnlyNumbers(e.target.value))} inputMode="numeric" required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="bank_name" className="form-label">Credit card bank name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="bank_name" name="bank_name" placeholder="Max 100 chars" maxLength="100" value={bank_name} onChange={(e) => setBankName(Common.acceptOnlyChars(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="expire_date" className="form-label">Credit card expiry date<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="expire_date" name="expire_date" placeholder="MM/YY" maxLength="5" value={expire_date} onChange={(e) => formatString(e.target.value)} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="nickname" className="form-label">Nick Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="nickname" name="nickname" placeholder="Max 100 chars" maxLength="100" value={nickname} onChange={(e) => setNickname(Common.acceptOnlyCharsNumbers(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label className="pt-4">
                                                                <input className="form-check-input me-1" type="checkbox" value={enableBankPassbook} onChange={getEnableBankPassbook} name='item_stock_movement' checked={bankPassbookChecked}/> Enable Bank Passbook
                                                            </label>
                                                        </div>
                                                        {bankPassbookChecked && (<>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="frmOpeningBalance" className="form-label">Opening Balance<span className="text-danger">*</span></label>
                                                                <input name="opening_balance" type="number" className="form-control" id="frmOpeningBalance" placeholder="Enter Opening Balance" value={opening_balance} onChange={(e) => setOpeningBalance(e.target.value)} required/>
                                                                <small>Note: All collections & payments done before entering the opening balance date will not be considered in the passbook</small>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="frmOpeningBalanceDate" className="form-label">Opening Balance Date<span className="text-danger">*</span></label>
                                                                <DatePicker name="opening_balance_date" calendarIcon={null} clearIcon={null} disableCalendar={true} data-testid="frmOpeningBalanceDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningBalanceDate} value={opening_balance_date} disabled />
                                                            </div>
                                                        </>)}
                                                    </div>
                                                    <div className="row ">
                                                        <div className="d-flex gap-2">
                                                            <a href="/bank-accounts" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                            <button type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>)}
                                            {activeType === 'P' && (<>
                                                <form id="formBankAccount" autoComplete="off" onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="bank_name" className="form-label">Wallet name<span className="text-danger">*</span></label>
                                                            <select id="bank_name" className="form-select" name='bank_name'  value={bank_name} onChange={(e) => {setBankName(e.target.value);setPayeeName(e.target.value)}} required>
                                                                <option value="">Select Wallet</option>
                                                                <option value="Paytm">Paytm</option>
                                                                <option value="Google Pay">Google Pay</option>
                                                                <option value="PhonePe">PhonePe</option>
                                                                <option value="MobiKwik">MobiKwik</option>
                                                                <option value="Jio Money">Jio Money</option>
                                                                <option value="Amazon Pay">Amazon Pay</option>
                                                                <option value="Others">Others</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="nickname" className="form-label">Nick Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="nickname" name="nickname" placeholder="Max 100 chars" maxLength="100" value={nickname} onChange={(e) => setNickname(Common.acceptOnlyCharsNumbers(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label className="pt-4">
                                                                <input className="form-check-input me-1" type="checkbox" value={enableBankPassbook} onChange={getEnableBankPassbook} name='item_stock_movement' checked={bankPassbookChecked}/> Enable Bank Passbook
                                                            </label>
                                                        </div>
                                                        {bankPassbookChecked && (<>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="frmOpeningBalance" className="form-label">Opening Balance<span className="text-danger">*</span></label>
                                                                <input name="opening_balance" type="number" className="form-control" id="frmOpeningBalance" placeholder="Enter Opening Balance" value={opening_balance} onChange={(e) => setOpeningBalance(e.target.value)} required/>
                                                                <small>Note: All collections & payments done before entering the opening balance date will not be considered in the passbook</small>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="frmOpeningBalanceDate" className="form-label">Opening Balance Date<span className="text-danger">*</span></label>
                                                                <DatePicker name="opening_balance_date" calendarIcon={null} clearIcon={null} disableCalendar={true} data-testid="frmOpeningBalanceDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningBalanceDate} value={opening_balance_date} disabled />
                                                            </div>
                                                        </>)}
                                                    </div>
                                                    <div className="row ">
                                                        <div className="d-flex gap-2">
                                                            <a href="/bank-accounts" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                            <button type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>)}
                                            
                                            {activeType === 'R' && (<>
                                                <form id="formBankAccount" autoComplete="off" onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="payee_name" className="form-label">Payee name<span className="text-danger">*</span></label>
                                                            <input autoFocus type="text" className="form-control" id="payee_name" name="payee_name" placeholder="Max 100 chars" maxLength="100" value="Cash" required readOnly/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="nickname" className="form-label">Nick Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="nickname" name="nickname" placeholder="Max 100 chars" maxLength="100" value={nickname} required onChange={(e) => setNickname(e.target.value)}/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label className="pt-4">
                                                                <input className="form-check-input me-1" type="checkbox" value={enableBankPassbook} onChange={getEnableBankPassbook} name='item_stock_movement' checked={bankPassbookChecked}/> Enable Bank Passbook
                                                            </label>
                                                        </div>
                                                        {bankPassbookChecked && (<>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="frmOpeningBalance" className="form-label">Opening Balance<span className="text-danger">*</span></label>
                                                                <input name="opening_balance" type="number" className="form-control" id="frmOpeningBalance" placeholder="Enter Opening Balance" value={opening_balance} onChange={(e) => setOpeningBalance(e.target.value)} required/>
                                                                <small>Note: All collections & payments done before entering the opening balance date will not be considered in the passbook</small>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="frmOpeningBalanceDate" className="form-label">Opening Balance Date<span className="text-danger">*</span></label>
                                                                <DatePicker name="opening_balance_date" calendarIcon={null} clearIcon={null} disableCalendar={true} data-testid="frmOpeningBalanceDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningBalanceDate} value={opening_balance_date} disabled />
                                                            </div>
                                                        </>)}
                                                    </div>
                                                    <div className="row ">
                                                        <div className="d-flex gap-2">
                                                            <a href="/bank-accounts" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                            <button type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                                            {/* {bank_cash_account === 0 && (<>
                                                            <button role="button" type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                                            </>)} */}
                                                        </div>
                                                    </div>
                                                </form>
                                            </>)}
                                            {activeType === 'F' && (<>
                                                <form id="formBankAccount" autoComplete="off" onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="payee_name" className="form-label">Account Holder Name<span className="text-danger">*</span></label>
                                                            <input autoFocus type="text" className="form-control" id="payee_name" name="payee_name" placeholder="Max 100 chars" maxLength="100" value={payee_name} onChange={(e) => setPayeeName(Common.acceptOnlyChars(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="account_no" className="form-label">Account Number<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="account_no" name="account_no" maxLength="20"  placeholder="Max 20 chars"  value={account_no} onChange={(e) => setAccountNo(Common.acceptOnlyNumbers(e.target.value))} inputMode="numeric" required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="ifsc_code" className="form-label">IFSC Code</label>
                                                            <input type="text" className="form-control" id="ifsc_code" name="ifsc_code" placeholder="Max 11 chars" maxLength="11" value={ifsc_code} onChange={(e) => setIfscCode(e.target.value.toUpperCase())} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="bank_name" className="form-label">Bank Name</label>
                                                            <input type="text" className="form-control" id="bank_name" name="bank_name" placeholder="Max 100 chars" maxLength="100" value={bank_name} onChange={(e) => setBankName(Common.acceptOnlyChars(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="bank_branch_name" className="form-label">Bank Branch Name</label>
                                                            <input type="text" className="form-control" id="bank_branch_name" name="bank_branch_name" placeholder="Max 100 chars" maxLength="100" value={bankBranchName} onChange={(e) => setBankBranchName(Common.acceptOnlyCharsNumbers(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="acc_type" className="form-label">Account Type</label>
                                                            {/* <input type="text" className="form-control" id="acc_type" name="acc_type" placeholder="Max 100 chars" maxLength="100" value={bankBranchName} onChange={(e) => setBankBranchName(Common.acceptOnlyCharsNumbers(e.target.value))}/> */}
                                                            <select id="acc_type" className="form-select" name='acc_type'  value={acc_type} onChange={(e) => setAcctype(e.target.value)} required>
                                                                <option value="Fixed Deposit Account">Fixed Deposit Account</option>
                                                                <option value="Recurring Deposit Account">Recurring Deposit Account</option>
                                                                <option value="Sweep FD">Sweep FD</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="nickname" className="form-label">Nick Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="nickname" name="nickname" placeholder="Max 100 chars" maxLength="100" value={nickname} onChange={(e) => setNickname(Common.acceptOnlyCharsNumbers(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label className="pt-4">
                                                                <input className="form-check-input me-1" type="checkbox" value={enableBankPassbook} onChange={getEnableBankPassbook} name='item_stock_movement' checked={bankPassbookChecked}/> Enable Bank Passbook
                                                            </label>
                                                        </div>
                                                        {bankPassbookChecked && (<>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="frmOpeningBalance" className="form-label">Opening Balance</label>
                                                                <input name="opening_balance" type="number" className="form-control" id="frmOpeningBalance" placeholder="Enter Opening Balance" value={opening_balance} onChange={(e) => setOpeningBalance(e.target.value)} required/>
                                                                <small>Note: All collections & payments done before entering the opening balance date will not be considered in the passbook</small>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label htmlFor="frmOpeningBalanceDate" className="form-label">Opening Balance Date</label>
                                                                <DatePicker name="opening_balance_date" calendarIcon={null} clearIcon={null} disableCalendar={true} data-testid="frmOpeningBalanceDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningBalanceDate} value={opening_balance_date} disabled />
                                                            </div>
                                                        </>)}
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="interest_rate" className="form-label">Interest Rate %</label>
                                                            <input type="text" className="form-control" id="interest_rate" name="interest_rate" placeholder='Max 100 %' value={interestRate} onChange={(e) => setInterestRate(Common.acceptOnlyDecimal(e.target.value))} required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="maturity_date" className="form-label">Maturity Date</label>
                                                            <DatePicker data-testid="maturity_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" minDate={new Date()} className="form-control" onChange={setMaturityDate} value={maturityDate} />
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="maturity_amt" className="form-label">Maturity Amount</label>
                                                            <input type="text" className="form-control" id="maturity_amt" name="maturity_amt" placeholder='Maturity Amount' value={maturityAmount} onChange={(e) => setMaturityAmount(Common.acceptOnlyDecimal(e.target.value))} required/>
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="d-flex gap-2">
                                                            <a href="/bank-accounts" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                            <button type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}