import React from 'react';
import * as Common from '../common/ImportUserEssentials';

export default function CustomInvFields(props){
    const [customFieldName, setCustomFieldName]   = React.useState(props.datas?props.datas.field_name:'');
    const [customFieldValue, setCustomFieldValue] = React.useState(props.datas?props.datas.field_value:'');
    const [displayCustomInvField,setDisplayCustomInvField] = React.useState(props.datas && props.datas.is_cus_det_enabled === 0 ? '': 'checked');
    const [displayCustomInvFieldVal,setDisplayCustomInvFieldVal] =  React.useState(props.datas?props.datas.is_cus_det_enabled:1);

    const toggeDisplayCustomField = () => {
        setDisplayCustomInvField((displayCustomInvField) ? '' : 'checked');
        setDisplayCustomInvFieldVal((displayCustomInvFieldVal) ? '0': '1')
    }
	
    return  (<>
    <div className="row" key={"iak_"+props.len} id={"ia_"+props.len}>
        <div className="mb-3 col-md-5">
            <div className="form-floating">
                <input placeholder="E.g., Color" type="text" className="form-control custom_fields" id={`custom_field_name_${props.len}`} name={`custom_field_name_${props.len}`}  value={customFieldName} onChange={(e) => setCustomFieldName(e.target.value)} required />
                <label htmlFor={`custom_field_name_${props.len}`}>Field Name <span className="text-danger">*</span></label>
            </div>
        </div>
        <div className="mb-3 col-md-5">
            <div className="form-floating">
                <input placeholder="E.g., Blue" type="text" className="form-control" id={`custom_field_value_${props.len}`} name={`custom_field_value_${props.len}`}  value={customFieldValue} onChange={(e) => setCustomFieldValue(e.target.value)} required />
                <label htmlFor={`custom_field_value_${props.len}`} >Field Value <span className="text-danger">*</span></label>
            </div>
        </div>
        <div className="mb-3 col-md-2 ms-auto mt-3 text-center ">
        <Common.OverlayTrigger overlay={Common.popoverContent('','If selected, data will be displayed in invoice print pdf')}><input className="form-check-input me-1 mr-4 checkbox-class" type="checkbox" id={`custom_field_check_${props.len}`} name={`custom_field_check_${props.len}`} value="1" checked={displayCustomInvField} onChange={toggeDisplayCustomField} /></Common.OverlayTrigger>
            <button type="button" className="btn btn-sm btn-danger" onClick={(e) => e.target.closest('#ia_'+props.len).remove()}><i className='bx bx-trash'></i></button>
        </div>
    </div>
    </>);
}