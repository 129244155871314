import React from 'react'
import * as Common from '../common/ImportUserEssentials';
//import {isMobile} from 'react-device-detect';

export default function Layout(props){
	const [mart_r,setMart_r] = React.useState(true);
	const navigate = Common.useNavigate();
	React.useEffect(() => {
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( userPerm && Object.keys(userPerm).length > 0 ){
			//Read
			setMart_r((userPerm[11]['view'] !== undefined && userPerm[11]['view'] === '0') ? false : true);
		}
		if( userPerm !== null ){
			const script = document.createElement('script');
			const script1 = document.createElement('script');
			script.type = "application/javascript";
			script.src = "assets/vendor/js/menu.js";
			//script.async = true;
			document.body.appendChild(script);
			
			script.addEventListener('load', () => {
				script1.type = "application/javascript";
				script1.src = "assets/js/main.js";
				script1.async = true;
				document.body.appendChild(script1);
			})
			setTimeout(function(){ 
				document.body.removeChild(script);
				document.body.removeChild(script1);
			}, 1500);
		}
		if( !mart_r ){
			navigate('/login');
		}
	},[]);
	const handleFynOneRedirection =() => {
		localStorage.setItem('fo_mart_url','1');
        navigate('/login');
    }
    return (
		<aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
			<div className="app-brand demo">
				<a href="/mart" className="app-brand-link">
					<span className="app-brand-logo demo">
						<img alt="FYNMART Merchant Logo" height="60" width="auto" src={props.site_logo}></img>
					</span>
				</a>
				<a href="#" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
					<i className="bx bx-chevron-left bx-sm align-middle"></i>
				</a>
			</div>
			<div className="menu-inner-shadow"></div>
			<ul className="menu-inner py-1">				
				<li className="menu-header small text-uppercase">
					<span className="menu-header-text">Menu</span>
				</li>
				<li className={(props.label === "mart-dashboard" ) ? "menu-item active" : "menu-item"}>
					<a href="/mart-dashboard" className="menu-link">
						<i className='menu-icon bx bxs-dashboard'></i>
						<div data-i18n="Mart Dashboard">Dashboard</div>
					</a>
				</li>
				<li className={(props.label === "mart-inventory" || props.label === "mart-inventory-add" || props.label === "mart-inventory-edit") ? "menu-item active" : "menu-item"}>
					<a href="/mart-inventory" className="menu-link">
						<i className="menu-icon tf-icons bx bx-store"></i>
						<div data-i18n="Mart Dashboard">Inventory</div>
					</a>
				</li>
				<li className={(props.label === "mart-categories" ) ? "menu-item active" : "menu-item"}>
					<a href="/mart-categories" className="menu-link">
						<i className="menu-icon tf-icons bx bx-category"></i>
						<div data-i18n="Mart Dashboard">Categories</div>
					</a>
				</li>
				<li className={(props.label === "mart-orders" || props.label === "mart-add-order" ) ? "menu-item active" : "menu-item"}>
					<a href="/mart-orders" className="menu-link">
						<i className='menu-icon bx bx-package'></i>
						<div data-i18n="Mart Orders">Orders</div>
					</a>
				</li>				
				<li className={(props.label === "mart-collection-listing" || props.label === "mart-collection-add" || props.label === "mart-collection-edit" || props.label === "mart-collection-customer-edit") ? "menu-item active" : "menu-item"}>
					<a href="/mart-collection-listing" className="menu-link">
						<i className="menu-icon tf-icons bx bx-rupee"></i>
						<div data-i18n="Mart Collection">Collection</div>
					</a>
				</li>
				<li className={(props.label === "mart-customer-listing" || props.label === "mart-customer-add" || props.label === "mart-customer-edit") ? "menu-item active" : "menu-item"}>
					<a href="/mart-customer-listing" className="menu-link">
						<i className='menu-icon tf-icons bx bxs-user-detail'></i>
						<div data-i18n="Customer Managment">Customer Managment</div>
					</a>
				</li>
				<li className={(props.label === "settings" ) ? "menu-item active" : "menu-item"}>
					<a href="/settings" className="menu-link">
						<i className="menu-icon tf-icons bx bx-cog"></i>
						<div data-i18n="Mart Settings">Settings</div>
					</a>
				</li>
				{/* {isMobile &&  */}
				<li className="mt-3 px-3 d-grid mx-auto">
					<button type="button" className="btn btn-primary" onClick={(e) => handleFynOneRedirection()}><div data-i18n="Back to FynOne"><i className='bx bx-arrow-back'></i> Back to FynOne</div></button>
				</li>
				{/* } */}
			</ul>
		</aside>
	);
}