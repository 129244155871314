import React from 'react'
export default function Layout(props){
	React.useEffect(() => {
		const script = document.createElement('script');
		const script1 = document.createElement('script');
		script.type = "application/javascript";
		script.src = "assets/vendor/js/menu.js";
		//script.async = true;
		document.body.appendChild(script);
		
		script.addEventListener('load', () => {
			script1.type = "application/javascript";
			script1.src = "assets/js/main.js";
			script1.async = true;
			document.body.appendChild(script1);
		})
		setTimeout(function(){ 
			document.body.removeChild(script);
			document.body.removeChild(script1);
		}, 1500);
	},[]);
    return (
		<aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
			<div className="app-brand demo justify-content-center">
				<a href="/" className="app-brand-link">
					<span className="app-brand-logo demo">
						<img alt="FYNMART Logo" height="65" width="auto" src={props.site_logo}></img>
					</span>
				</a>
				<a href="#" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
					<i className="bx bx-chevron-left bx-sm align-middle"></i>
				</a>
			</div>
			<div className="menu-inner-shadow"></div>
			<ul className="menu-inner py-1">
				<li className="menu-header small text-uppercase"><span className="menu-header-text">Menu</span></li>
				<li className={(props.label === "home") ? "menu-item active open" : "menu-item"}>
					<a href="/home" className="menu-link">
						<i className="menu-icon tf-icons bx bx-store-alt"></i>
						<div data-i18n="Stores">Stores</div>
					</a>
				</li>
				<li className={(props.label === "orders") ? "menu-item active open" : "menu-item"}>
					<a href="/orders" className="menu-link">
						<i className="menu-icon tf-icons bx bx-package"></i>
						<div data-i18n="Account Settings">Orders</div>
					</a>
				</li>
			</ul>
		</aside>
	);
}