import React, { useState,useEffect } from 'react';
import BusinessAccount from './BusinessAccount';
// import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';

export default function AddProfile(props){
	// User permissions
	// let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	
	// const [account_type, setAccountType] = useState('');
	const [stateMaster, setStateMaster]             = useState([]);
	// const [accountMgmt_w,setaccountMgmt_w] = React.useState(false);
	const [role,setRole] = React.useState('');
	const [profileMasters, setProfileMasters] = useState([]);
	const [ownerCount, setOwnerCount] = useState(0);
	// const [firstTimeLogin,setFirstTimeLogin] = React.useState(false);
	// const [youtubeModal,setYoutubeModal] = React.useState(false);
	// const handleYoutubeViewClose = () => setYoutubeModal(false);
	const navigate = Common.useNavigate();

	useEffect(() => {
		document.title = 'Add Profile | '+props.site_name;
		if( !localStorage.getItem('fo_loggedin') ){
			navigate("/");
		}
		// if( userPerm && Object.keys(userPerm).length > 0 ){
		// 	// Write permission
		// 	setaccountMgmt_w((userPerm[1]['write'] !== undefined && userPerm[1]['write'] === '0') ? false : true);
		// }
		
		// checkFirstTimeLogin()
		// if (firstTimeLogin === true) {
		// 	setYoutubeModal(true);
		// 	updateFirstTimeLogin();
		// }
		getAccountType();
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_state_master',
		}).then(function (response) {
			if( response.data.success ){
				setStateMaster(response.data.data);
			}
		}).catch(error => console.log(error));
	}, []);
	// }, [firstTimeLogin]);
	/*if( !accountMgmt_w ){
		navigate("/not-authorized");
	}*/
	// const handleSubmit =(e) => {
	// 	e.preventDefault();
	// }
	const getAccountType = () => {
		// setAccountType(actype);
		Common.axios({
			method: 'get',
			url: props.url+'user/masterProfileList',
		}).then(function (response) {
			if( response.data.success ){
				setProfileMasters(response.data.data);
				setRole(response.data.data[0].role);
				let ownerCount = 0;
				for (let i = 0; i < response.data.data.length; i++) {
					if (response.data.data[i].role === 'Owner') {
						ownerCount++;
						break;
					}
				}
				setOwnerCount(ownerCount);
				return false;
			}
		}).catch(error => console.log(error));
	}
	// const checkFirstTimeLogin = () => {
	// 	Common.axios({
	// 		method: 'post',
	// 		url:props.url+'user/checkFirstTimeLogin',
	// 		data: {
	// 			uid: localStorage.getItem('fo_loggedin'),
	// 		},
	// 	}).then(function (response) {
	// 		if( response.data.success ){
	// 			if (response.data.is_first_login === '1') {
	// 				setFirstTimeLogin(true)
					
	// 			}
	// 			return false;
	// 		}
	// 		Common.toast.dismiss();
	// 		Common.toast.error(response.data.message);
	// 	}).catch(error => console.log(error));
	// }
	// const updateFirstTimeLogin = () => {
	// 	Common.axios({
	// 		method: 'post',
	// 		url:props.url+'user/updateFirstTimeLogin',
	// 		data: {
	// 			uid: localStorage.getItem('fo_loggedin'),
	// 		},
	// 	}).then(function (response) {
	// 		if( response.data.success ){
	// 			return false;
	// 		}
	// 		Common.toast.dismiss();
	// 		Common.toast.error(response.data.message);
	// 	}).catch(error => console.log(error));
	// }
	// const handleClick = () => {
	// 	setYoutubeModal(false);
	// }

    return (
		<div className="container-xxl flex-grow-1 container-p-y">
			<h4 className="fw-bold py-1 text-center"><span className="text-muted fw-light"></span>Profile Setup</h4>
			<div className="row justify-content-center">
				<div className="col-sm-6">
					{/* <div className="mb-3">
						<div className="card h-100">
							<div className="card-body">
								<form id="account_type1" className="text-center" onSubmit={handleSubmit}>
									<h5>Choose Your Account Type</h5>
									<div className="form-check form-check-inline">
										<input id="paccount_type" name="account_type" className="form-check-input" type="radio" value="Personal"  required checked={account_type === 'Personal'} onChange={(e) => getAccountType(e.target.value)} />
										<label className="form-check-label" htmlFor="paccount_type" style={{'marginRight':'2.7rem'}}> Personal </label><br/>
									</div>
									<div className="form-check form-check-inline">
										<input id="baccount_type" name="account_type" className="form-check-input" type="radio" value="Bussiness" required  checked={account_type === 'Bussiness'} onChange={(e) => getAccountType(e.target.value)} />
										<label className="form-check-label" htmlFor="baccount_type"> Business </label><br/>
									</div>
								</form>
								<div style={{'display': 'flex', "justifyContent":'center'}}>
									<small style={{'fontSize': '70%','marginLeft': '30px'}}>Easy money management</small>
									<small style={{'fontSize': '70%','marginLeft': '30px'}}>Business accounting software</small>
								</div>
							</div>
						</div>
					</div> */}
					<div className="">
						<BusinessAccount state={stateMaster} site_logo={props.site_logo} mart_logo={props.mart_logo} url={props.url} profileMasters={profileMasters} ownerCount={ownerCount} role={role}/>
						{/* {account_type === '' ? <></> : account_type === 'Personal'? <PersonalAccount state={stateMaster} url={props.url} profileMasters={profileMasters} role={role}/>:} */}
					</div>
				</div>
			</div>
            <Common.FYNTicket url={props.url}/>
			{/* <Modal {...props} centered show={youtubeModal} onHide={handleYoutubeViewClose} size="md" aria-labelledby="whatsnew-modal" backdrop="static" keyboard={false}>
			<Modal.Header>
				<Modal.Title>Welcome to FynOne</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			Hey There!! Welcome to FynOne - the Billing & Accounting app for Bharat<br/><br/>
FynOne is feature loaded with deep focus on Compliance, Collaboration & Simplicity. To have a quick walkthrough of the features, visit the video link below that's hosted on Youtube. 
			</Modal.Body>
			<Modal.Footer>
				<a className='btn btn-primary' href='https://youtu.be/uFOhnS8QuEA' target='_blank'>Watch</a>
				<button type='button' className='btn btn-outline-secondary' onClick={handleClick}>Skip</button>
			</Modal.Footer>
		</Modal> */}
		</div>
    );

}