import React  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

export default function AssetsManagementListing(props){
	const [filterText, setFilterText] = React.useState('');
	const [DTpending, setDTPending] = React.useState(true);
	const [dt_load,setDtLoad] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [assetsList, setAssetsList] = React.useState([]);
    const [exportBtnClass,setExportBtnClass] = React.useState('d-none');
    const [activeTab, setActiveTab]  = React.useState(false);
	const [asset_id,setAssetID] 	 = React.useState('');
	const [sellAssetShow, setSellAssetShow] = React.useState(false);
	const [markAsScrapShow, setMarkAsScrapShow] = React.useState(false);
	const [uploadWarrantyModal, setUploadWarrantyModal] = React.useState(false);
	const [scrap_date,setScrapDate] 		  = React.useState('');
    const [warrantyItemDocument,setWarrantyItemDocument] = React.useState('');
	const [asset_sold_to,setAssetSoldTo] 		  = React.useState('');
	const [sale_price,setSalePrice] 		  = React.useState('');
	const [sale_date,setSaleDate] 		  = React.useState('');

	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	const [assetsMgmt_r,setAssetsMgmt_r] = React.useState(true);
	const [assetsMgmt_w,setAssetsMgmt_w] = React.useState(true);
	const [sortOrder,setSortOrder]       = React.useState('');

	const navigate = Common.useNavigate();
	
    React.useEffect(() => {
        document.title = 'Assets Management Listing | '+props.site_name;
		
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Read permission
			setAssetsMgmt_r((userPerm[9]['view'] !== undefined && userPerm[9]['view'] === '0') ? false : true);
		}
		if( Object.keys(userPerm).length > 0 ){
			setAssetsMgmt_w((userPerm[9]['write'] !== undefined && userPerm[9]['write'] === '0') ? false : true);
		}
		if (localStorage.getItem('fo_subplan') === 'Basic') {
			navigate("/pricing-plan");
		}
		loadDataTable(1,perPage,filterText,sortOrder,activeTab);
	}, [props,dt_load,filterText]);
	if( !assetsMgmt_r ){
		navigate("/not-authorized");
	}
	const loadDataTable = (page,perPage,filterText,sort='',active_tab=activeTab) => {
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'assets/listing/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText,'active_tab':active_tab}
        }).then(function (response) {
			setExportBtnClass('d-none');
			Common.showLoader(false);
            if(response.data.success){
				setAssetsList(response.data.data);
				setTotalRows(response.data.total);
				if(response.data.data.length > 0){
					setExportBtnClass('d-block');
				}
				setDTPending(false);
            }
        }).catch(error => console.log(error));
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,sortOrder,activeTab);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText,sortOrder,activeTab);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection),activeTab);
	};
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
    const columns = [
        {
            name: 'Asset type',
            selector: row => row.asset_type,
            sortable: false,
			sortField: 'asset_type',
			cell: (row) => (
				row.asset_type
			)
        },
        {
            name: 'Asset Name',
            selector: row => row.asset_type_name,
            sortable: true,
			sortField: 'asset_type_name',
			cell: (row) => (
				row.asset_type_name
			)
        },
		{
            name: 'Asset id',
            selector: row => row.asset_id,
            sortable: true,
			sortField: 'asset_id',
			cell: (row) => (
				row.asset_id
			)
        },
		{
            name: 'Asset provider',
            selector: row => row.provider_name,
            sortable: true,
			sortField: 'provider_name',
			cell: (row) => (
				row.provider_name
			)
        },
		{
            name: 'Purchase value',
            selector: row => row.purchase_value,
            sortable: true,
			sortField: 'purchase_value',
			cell: (row) => (
				row.purchase_value
			)
        },
		{
            name: 'Purchase date',
            selector: row => row.purchase_date,
            sortable: true,
			sortField: 'purchase_date',
			cell: (row) => (
				row.purchase_date
			)
        },
		{
            name: 'Useful life',
            selector: row => row.useful_life,
            sortable: true,
			sortField: 'useful_life',
			cell: (row) => (
				row.useful_life
			)
        },
		{
            name: 'Depreciation value',
            selector: row => row.dep_value,
            sortable: true,
			sortField: 'dep_value',
			cell: (row) => (
				row.dep_value
			)
        },
		{
            name: 'Current value',
            selector: row => row.current_value,
            sortable: true,
			sortField: 'current_value',
			cell: (row) => (
				row.current_value
			)
        },
        {
            name: 'Warranty Attachment',
            selector: row => row.warranty_file,
            sortable: false,
			sortField: 'warranty_file',
			cell: (row) => (
				row.warranty_file !== '' ? <><button type="button" onClick={(e) => handleDocDownload(row.warranty_file)} className="btn btn-sm btn-primary mr-2" >Download</button> <i className='bx bx-trash' onClick={(e) => handleDocDelete(row.warranty_file,row.id)}></i></> : <button type="button" onClick={(e) => showUploadDocModal(true, row.id)} className="btn btn-sm btn-info" >Upload</button>
			)
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
			sortField: 'created_by',
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on',
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
			sortField: 'updated_by',
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on',
        },

    ];
	if( activeTab ) {
		columns.unshift({
            name: 'Status',
            selector: row => row.doc_status,
            sortable: false,
			sortField: 'doc_status',
			cell: (row) => (
				(row.doc_status === 'S') ? "Sold": "Mark as scrap" 
			)
        });
	}
	if(!activeTab){
        columns.unshift(
            {
				name: 'Action',
				button: true,
				cell: (row) => ( assetsMgmt_w === true ? <>
					<div className="dropdown">
						<button type="button" className="btn me-2 p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
						<i className="bx bx-dots-vertical-rounded"></i>
						</button>
						<div className="dropdown-menu" >
							<button className="dropdown-item" onClick={(e) => handleSellAsset(row.id)}><i className='bx bx-money'></i> Sell Asset</button>
							<button className="dropdown-item" onClick={(e) => handleMarkAsScrap(row.id)}><i className='bx bx-message-square-x'></i> Mark as scrap</button>
						</div>
					  </div>
				  </> :<></>
				)
			},  
        );
    }
    const handleActiveRemoved = nextChecked => {
        setActiveTab(nextChecked);
        loadDataTable(1,perPage,filterText,sortOrder,nextChecked);
    };
	const handleExportCSV = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'assets/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText,'active_tab':activeTab},
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
			}
        }).catch(error => console.log(error));
    }
	const handleSellAsset = (val) => {
		setAssetID(val);
		setSellAssetShow(true);
	}
	const handleMarkAsScrap = (val) => {
		setAssetID(val);
		setMarkAsScrapShow(true);
	}
	const showUploadDocModal = (flag, asset_id) => {
        setAssetID(asset_id)
        if (flag === false)
            setUploadWarrantyModal(false);
        else
            setUploadWarrantyModal(true);
    }
	const handleWarrantyDocument = (e) => {
        const fileTypes = ['application/pdf', 'image/jpeg', 'image/jpg','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/png', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
		if (e.target.files[0] !== undefined) {
			if (!fileTypes.includes(e.target.files[0].type)) {
				Common.toast.error('File format not supported');
				e.target.value = null;
				return false;
			} else {
				if (e.target.files[0].size > (5 * 125000)) {
					Common.toast.error('File size should be less than 5MB');
					e.target.value = null;
					return false;
				} else {
					setWarrantyItemDocument(e.target.files[0]);
				}
			}
		}
        Common.toast.dismiss();
    };
	const uploadWarrantyDocument = (e) => {
        let fileData = new FormData();
        fileData.append('files', warrantyItemDocument);
        fileData.append('pid', localStorage.getItem('fo_profileid'));
        fileData.append('asset_id', asset_id);
        Common.axios.post(props.url+'assets/upload_warratny_file', fileData, {
            mode: 'cors',
            headers: { 'Content-Type':  'multipart/form-data' }
        }).then(function (response) {
            setUploadWarrantyModal(false);
			loadDataTable(1,perPage,filterText,sortOrder,activeTab);
        }).catch(error => console.log(error));
    }
	const handleDocDownload = (filePath) => {
        Common.showLoader(true);
        Common.axios({
            method: 'get',
            url:props.url+'assets/doc_download/'+encodeURIComponent(btoa(filePath)) + '/' + localStorage.getItem('fo_profileid'),
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				Common.toast.dismiss();
                // window.location.href = props.url+response.data.url;
                const url = props.url+response.data.url;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.data.file_name);
                document.body.appendChild(link);
                link.click();
                return false;
			} 
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    };
    const handleDocDelete = (filepath, aid) => {
        confirmAlert({
            title: 'Delete Document',
            message: 'Are you sure you want to delete?',
            buttons: [{
                    label: 'Yes',
                    onClick: () => {
                        Common.showLoader(true);
                        Common.axios({
                            method: 'post',
                            url:props.url+'assets/del_document/',
                            data: {
                                filepath:filepath,
                                asset_id:aid
                            }
                        }).then(function (response) {
                            Common.showLoader(false);
                            if(response.data.success){
                                loadDataTable(1,perPage,filterText,sortOrder,activeTab);
                                Common.toast.success(response.data.message);
                            } 
                            Common.toast.dismiss();
                        }).catch(error => console.log(error));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log('no')
                    }
                }
            ]
        });
    }
	const handleMarkAsScrapData =() => {
		if(!scrap_date){
			Common.toast.error('Scrap Date is required.');
			return false;
		}
		Common.axios({
			method: 'post',
			url: props.url+'assets/update',
			data: {scrap_date:scrap_date,asset_id:asset_id}
		}).then(function (response) {
			if(response.data.success){
				setMarkAsScrapShow(false);
				loadDataTable(1,perPage,filterText,sortOrder,activeTab);
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const handleSellAssetData =() => {
		if(!asset_sold_to){
			Common.toast.error('Asset Sold to is required.');
			return false;
		}
		if(!sale_price){
			Common.toast.error('Sale Price is required.');
			return false;
		}
		if(!sale_date){
			Common.toast.error('Sale Date is required.');
			return false;
		}
		Common.axios({
			method: 'post',
			url: props.url+'assets/update',
			data: {asset_sold_to:asset_sold_to,sale_price:sale_price,sale_date:sale_date,asset_id:asset_id}
		}).then(function (response) {
			if(response.data.success){
				setSellAssetShow(false);
				loadDataTable(1,perPage,filterText,sortOrder,activeTab);
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
    return (
		<>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="assets-listing" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Assets Management /</span> Listing</h4>
							<div className="row ">
								<div id="sdt_cont" className="col-sm-12">
                                    { assetsMgmt_w && (<>
                                        <div className="row">
                                            <div className="col-md-3 col-12 listing-tabs mb-1">
                                                <div className="btn-group btn-group-sm" role="group">
                                                    <button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
                                                        <span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
                                                        <i className="bx bx-download d-block d-sm-none"></i>
                                                    </button>
                                                    <Common.Link to="/add-asset" className="btn btn-primary btn-sm">
                                                        <i className="bx bx-plus"></i>Add Asset
                                                    </Common.Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-1"></div>
                                            <div className="col-md-1 col-12 listing-tabs text-right text-md-center mb-1">
                                                <label>
                                                    <BootstrapSwitchButton
                                                        onChange={handleActiveRemoved}
                                                        checked={activeTab}
                                                        onlabel='Removed'
                                                        onstyle='danger'
                                                        offlabel='Active'
                                                        offstyle='success'
                                                        width='100'
                                                        size='sm'
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-md-4 col-12 text-center text-md-end mb-1">
                                                <Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Invoice Number, Customer')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
                                                <DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                                            </div>
                                        </div>
                                    
                                    </>)}
                                    <DTE.DataTable className="dataTables_wrapper rdt_AdjDrp" columns={columns} data={assetsList} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
								</div>
							</div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal show={markAsScrapShow} size='sm' onHide={()=>setMarkAsScrapShow(false)} aria-labelledby="Scrap -Date-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Mark as Scrap</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row mb-3">
					<div className="col-sm-4">
						<label htmlFor="frmScrapDate" className="form-label">Scrap Date<span className="text-danger">*</span>:&nbsp;&nbsp;</label>
					</div>
					<div className="col-sm-8">
						<DatePicker name="scrap_date" data-testid="frmScrapDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setScrapDate} value={scrap_date} />
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={()=>handleMarkAsScrapData()}>
				Submit
			</Button>
			</Modal.Footer>
		</Modal>
		<Modal show={sellAssetShow} size='md' onHide={()=>setSellAssetShow(false)} aria-labelledby="Sell-Asset-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Sell Asset</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row mb-3">
					<div className="col-sm-4">
						<label htmlFor="frmAssetSoldTo" className="form-label">Asset sold to<span className="text-danger">*</span>:&nbsp;&nbsp;</label>
					</div>
					<div className="col-sm-8">
						<input name="asset_provider_name" type="text" className="form-control" id="frmAssetSoldTo" placeholder="Enter Asset sold to" onChange={(e) => { setAssetSoldTo(Common.acceptOnlyCharsNumbers(e.target.value)); }}  value={asset_sold_to} />
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-sm-4">
						<label htmlFor="frmSalePrice" className="form-label">Sale Price<span className="text-danger">*</span>:&nbsp;&nbsp;</label>
					</div>
					<div className="col-sm-8">
						<input type="text" name="sale_price" className="form-control" id="frmSalePrice" placeholder="0.00" value={sale_price} onChange={(e) => { setSalePrice(Common.acceptOnlyDecimal(e.target.value)); }}/>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-sm-4">
						<label htmlFor="frmSaleDate" className="form-label">Sale Date<span className="text-danger">*</span>:&nbsp;&nbsp;</label>
					</div>
					<div className="col-sm-8">
						<DatePicker name="sale_date" data-testid="frmSaleDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setSaleDate} value={sale_date} />
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={()=>handleSellAssetData()}>
				Submit
			</Button>
			</Modal.Footer>
		</Modal>
		<Modal show={uploadWarrantyModal} size='sm' onHide={()=>showUploadDocModal(false, asset_id)} aria-labelledby="Eway-Bill-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Select File to upload</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Default file input example</label>
                            <input className="form-control" type="file" name="formFile" id="formFile" onChange={handleWarrantyDocument} />
                        </div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={(e) => uploadWarrantyDocument(e)}>
				Upload
			</Button>
			<Button variant="danger" onClick={()=>showUploadDocModal(false,asset_id)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
		</>
    );
}