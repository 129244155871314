import React  from "react";
import Layout from './MartLayout';
import NavBar from './MartNavBar';
import * as Common from '../common/ImportUserEssentials';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import ImageUploading from 'react-images-uploading';

export default function MartItemPhotos(props){
	const navigate = Common.useNavigate();
	const glbox = GLightbox({
		touchNavigation: true,
		selector: '.glightbox'
	});
	const { state } = Common.useLocation();
	const [images, setImages] = React.useState([]);
	const maxNumber = 6;
	const maxFileSize = 5*1024;

	const onChange = (imageList, addUpdateIndex) => {
		glbox.destroy();
		if( imageList.length === 0 ){
			delImages();
			return false;
		}
		const deleteAfter = images.length - imageList.length;
		
		saveImages(imageList,deleteAfter);
		console.log(imageList);
		// data for submit
		//setImages(imageList);
	};
	const delImages = () => {
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'martinventory/deleteMedia',
			data: {martid:Common.secureLocalStorage.getItem('fo_martid'),id:state.itemid},
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				Common.toast.success(response.data.message);
				loadMedia(state.itemid);
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const showError = (errors, files) => {
		if( errors.maxNumber ){
			Common.toast.error('Number of selected images exceeds: '+maxNumber);
			return false;
		}
		if( errors.acceptType ){
			Common.toast.error('Selected file type is not allowed');
			return false;
		}
		if( errors.maxFileSize ){
			Common.toast.error('Selected file size exceeds: '+maxFileSize);
			return false;
		}
	}
	const saveImages = (imgs,deleteAfter) => {
		if( imgs.length === 0 ){
			Common.toast.error('Selected at least one media to upload.');
			return false;
		}

		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'martinventory/addMedia',
			data: {martid:Common.secureLocalStorage.getItem('fo_martid'),id:state.itemid,media:imgs,delete_after:deleteAfter},
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				Common.toast.success(response.data.message);
				loadMedia(state.itemid);
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	React.useEffect(() => {
		document.title = 'Item Media | '+props.site_name;
		loadMedia(state.itemid);
		if (state === null) {
			navigate('/');
		}
	}, [props]);
	React.useEffect(() => {
		glbox.init();
	}, [images]);
	const loadMedia = (itemid) => {
		Common.axios({
			method: 'post',
			url:props.url+'martinventory/loadMedia',
			data: {martid:Common.secureLocalStorage.getItem('fo_martid'),id:state.itemid},
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				let imgs = response.data.data;
				const newImages = [];
				imgs.forEach((data) => {
					newImages.push({
						'data_url' : data.data_url,
						'file' : {'type':data.type}
					});
				});
				setImages(newImages)
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="mart-inventory" {...props}/>
                <div className="layout-page">
                    <NavBar {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
							<h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Inventory /</span> Item Media (Image/Video) - {state.itemName}
								<span className="dropdown">
									<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
									<i className='text-danger bx bxl-youtube bx-md'></i>
									</button>
									<div className="dropdown-menu" >
										<a href="https://youtu.be/OiTyZjloCgE" className="dropdown-item" target="_blank" rel="noreferrer"><span>Add product images & videos</span></a>
									</div>
								</span>
							</h4>
							<div className="row">
								<ImageUploading
									multiple={true}
									value={images}
									onChange={onChange}
									maxNumber={maxNumber}
									acceptType={['jpg','png','mp4','avi']}
									allowNonImageType={true}
									dataURLKey="data_url"
									onError={showError}
								>
									{({
									  imageList,
									  onImageUpload,
									  onImageRemoveAll,
									  onImageUpdate,
									  onImageRemove
									}) => (
										// write your building UI
										<div className="upload__image-wrapper">
											{imageList.length < 6 && <>
											<button className="btn btn-sm btn-outline-primary" onClick={onImageUpload}>
												<span className="tf-icons bx bx-upload"></span>&nbsp;Click here to Upload
											</button></>}
											<button className="ms-2 btn btn-sm btn-outline-danger" onClick={onImageRemoveAll}><i className='bx bx-window-close'></i>&nbsp;Remove all media</button>
											<div className="mt-3 my-3"><h5 className="d-inline">Uploaded Media</h5> <small className="text-danger">(Max 6 files allowed)</small></div>
											{imageList.length === 0 && <><div className="text-center"><div className="alert alert-info" role="alert">No media uploaded.</div></div></>}
											{imageList.length > 0 && <>
											<div className="border rounded" key="glight_box">
												<div className="row row-cols-2 row-cols-md-3 p-3">
													{imageList.map((image, index) => (
														<div key={index} className="col mb-2">
														  <div className="card text-center h-100">
															<a href={image['data_url']} data-type={(image['file']['type'] === 'image/png' || image['file']['type'] === 'image/jpeg') ? "image" : "video"} data-width="100%" className="glightbox" data-gallery="gallery">
																{ (image['file']['type'] === 'image/png' || image['file']['type'] === 'image/jpeg') && <>
																	<img className="card-img-top" src={image['data_url']} alt="" width="100" />
																</>}
																{ (image['file']['type'] === 'video/mp4' || image['file']['type'] === 'video/avi') && <>
																<video className="w-100" controls>
																	<source src={image['data_url']} type={image['file']['type']}/>
																	Your browser does not support the video tag.
																</video></>}
															</a>
															<div className="card-body p-0"></div>
															<div className="card-footer">
																<button className="btn btn-sm btn-warning" onClick={() => onImageUpdate(index)}>Update</button>
																<button className="ms-2 btn btn-sm btn-danger" onClick={() => {onImageRemove(index)}}>Remove</button>
															</div>
														  </div>
														</div>
													))}
												</div>
											</div>
											<div className="text-center py-2">
												<a href="/mart-inventory" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> <span className="hidden-phone">Back</span></a>
											</div>
											</>}
										</div>
									)}
								  </ImageUploading>
							</div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
    );
}