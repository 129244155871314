import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import * as Common from '../common/ImportUserEssentials';
import Moment from 'react-moment';
import Modal from 'react-bootstrap/Modal';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import paymentModes from '../masters/PaymentModes.js';
// import deductionList from '../masters/Deductions.js';
import Button from 'react-bootstrap/Button';

export default function EditPayable(props){
    const [payment_mode, setPaymentMode]            = useState('UPI/QR');
    const [data, setData]                           = useState([]);
    const [purchase_id, setPurchaseId]              = useState([]);
    const [payable_id, setPayableId]          		= useState('');
    const [seller_id, setSellerId]          		= useState('');
    const [saveBtnDisabled, setSaveBtnDisabled]     = useState(true);
    const [advance_amount, setAdvanceAmount]        = useState(0);
	const [showAdvanceDiv, setShowAdvanceDiv]       = useState(false);
	const [payments,setPayments]                    = useState([]);
	const [total_payment,setTotalPayment]           = useState('0.00');
	const [total_amt,setTotalAmt]                   = useState('0.00');
	const [due,setDue]                              = useState('0.00');
	// const [dueDate, setDueDate]                     = useState('');
	//const [total_deductions,setTotalDeductions]     = useState('0.00');
	const [payModalShow, setPayModalShow]           = useState(false);
	const [received,setReceived]                    = useState('');
	const [payment_date,setPaymentDate]             = useState(new Date());
	const [trans_ref,setTransRef]                   = useState('');
	const [payment_remarks,setPaymentRemarks]       = useState('');
	const [deduction_div,setDeductionDiv]           = useState(false);
	const [deduction_amt,setDeductionAmt]           = useState('');
	const [deduction_per,setDeductionPer]           = useState('');
	// const [deduction_type,setDeductionType]         = useState('');
	const [deductions,setDeductions]                = useState([]);
	const [total_deduction,setTotalDeduction]       = useState('0.00');
	const frmSaleDedType                            = React.createRef();
	const frmSaleDedSubtype                         = React.createRef();
	const [temp_deduction,setTempDeduction]         = useState([]);
	const paymentReceivedRef                        = React.createRef();
	// const [deduction_remarks,setDeductionRemarks]   = useState('');
	const [dedModalShow,setDedModalShow]            = useState(false);
	const [totalPaidAmt,setTotalPaidAmt]            = useState('0.00');
	const [totaldeductAmt,setTotalDeductionAmt]     = useState('0.00');
    const [total_taxable_amt,setTotalTaxableAmt]    = useState('');
	const [bankList, setBankList] 					= useState([]);
	const [bankAccount,setBankAccount] 				= useState(0);
	const [defaultPayee, setDefaultPayee] 			= useState([]);
    const [bank_cash_account,setBankCashAccount] 	= useState(1);
    const [responseStatus,setResponseStatus] 		= useState(0);

	// Advance Adjust
	const [advanceAdjustModalShow, setAdvanceAdjustModalShow] = useState(false);
	const [advanceBalance,setAdvanceBalance]  	 	   		 = useState('');
	const [adjustedAmount,setAdjustedAmount]  	 	     	 = useState('');
	const [advanceAdjustedRemarks,setAdvanceAdjustedRemarks] = useState('');
	const [advanceAdjust,setAdvanceAdjust] 					 = useState([]);
	const [totalAdjustedAmount,setTotalAdvanceAdjust]  		 = useState('');

    const [successMsg,setSucces]                    = useState('');
    const [errorMessage,setError]                   = useState('');

	const [deductionMasterList, setDeductionMasterList]       = useState([]);
	const [deductionMaster, setDeductionMaster]               = useState('');
	const [deductionMasterLabel, setDeductionMasterLabel]     = useState('')

	const [deductionSubtypeMasterList, setDeductionSubtypeMasterList] = useState([]);
	const [deductionSubtypeMaster, setDeductionSubtypeMaster] = useState('');
	const [deductionSubtypeMasterLabel, setDeductionSubtypeMasterLabel] = useState('');
	const [isDeductionMasterSelected, setIsDeductionMasterSelected] = useState(false);
    const { state } = Common.useLocation();
	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Edit Payable | '+props.site_name;
        if( state === null ){
			navigate('/');
		}
        Common.axios({
            method: 'post',
            url:props.url+'payable/getPayable',
            data:{pa_id:state.paid,pid:localStorage.getItem('fo_profileid')}
        }).then(function (response) {
            if(response.data.success){
                setData(response.data.data);
                setPurchaseId(response.data.data.purchase_id);
                setTotalAmt((response.data.data.amount));
                setTotalPaidAmt((response.data.data.paid_amt));
                setTotalDeductionAmt(response.data.data.deduction_amount);
                setTotalTaxableAmt(response.data.data.total_taxable_amt);
				setPayableId(response.data.data.payables_id);
				setSellerId(response.data.data.seller_id);
				if(advanceBalance === ''){
					setAdvanceBalance((response.data.advance)?response.data.advance.closing_balance:'');
				}
				if(responseStatus === 0){
					setDue(response.data.data.due_amount);
				}
				setResponseStatus(1);
            }
        }).catch(error => console.log(error));

        // let dueAmt = (Number(total_amt) - Number(total_payment) - Number(total_deduction) - Number(totalPaidAmt) - Number(totaldeductAmt)).toFixed(2);
		// setDue(dueAmt);
		if(responseStatus === 0){
			let dueAmt = Number(due) - Number(total_payment) - Number(total_deduction);
			setDue(dueAmt);
			if( dueAmt < 0 ){
				let advance_amount = (Number(total_payment) + Number(total_deduction) + Number(totalPaidAmt) + Number(totaldeductAmt)) - Number(total_amt);
				setAdvanceAmount(advance_amount);
				setShowAdvanceDiv(true);
			} else {
				setAdvanceAmount(0);
				setShowAdvanceDiv(false);
			}
		}
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				setDefaultPayee(response.data.defaultPayee);
				if (response.data.defaultPayee.length > 0) {
					setBankAccount(response.data.defaultPayee[0].value);
				}
			}
		}).catch(error => console.log(error));

		// deduction master
		Common.axios({
			method: 'get',
			url:props.url+'ajax/getDeductionMaster',
		}).then(function (response) {
			if(response.data.success){
				setDeductionMasterList(response.data.data);
			}
		}).catch(error => console.log(error));

	}, [total_amt,total_payment,total_deduction]);

    const removePayment = (index) => {
		let price = payments[index].received;
		let ded_amt = payments[index].deductions.reduce(function(prev, current) {
			return Number(prev) + Number(current.deduction_amt)
		}, 0);
		let total_ded = Number(total_deduction) - Number(ded_amt);
		mainCalculation('deduction',total_ded);

		setPayments(payments.filter((item,key) => key !== index));
		let total_pay = Number(total_payment) - Number(price);

		let dueAmt = Number(due) + Number(price) + Number(ded_amt);
		setDue(dueAmt);

		if( dueAmt < 0 ){
			let advance_amount = (Number(total_pay) + Number(total_ded)) - Number(total_amt);
			setAdvanceAmount(advance_amount);
			setShowAdvanceDiv(true);
		} else {
			setAdvanceAmount(0);
			setShowAdvanceDiv(false);
		}

		mainCalculation('received',total_pay);
        ((Number(total_pay) > 0) ? setSaveBtnDisabled(false) : setSaveBtnDisabled(true));
	}
	const addPayment = () => {
		if( payment_mode === '' ){
			Common.toast.error('Payment Mode is required');
			return false;
		}
		if( received === undefined || Number(received) === 0 ){
			Common.toast.error('Received amount should be greater than zero.');
			return false;
		}
		// payment_mode !== 'Cash' &&
		if( bankAccount === undefined || bankAccount === 0 || bankAccount === '' ){
			Common.toast.error('Bank Account is required.');
			return false;
		}
		
		let pay = {};
		pay['received'] = received;
		pay['payment_date'] = payment_date;
		pay['payment_mode'] = payment_mode;
		pay['payment_remarks'] = payment_remarks;
		pay['trans_ref'] = trans_ref;
		pay['deductions'] = deductions;
		pay['bankAccount'] = bankAccount;
		let total_pay = Number(total_payment) + Number(received);

		setPayments([...payments, pay]);
		paymentModal('hide');
		mainCalculation('received',total_pay);
		
		let tot_ded = deductions.reduce(function(prev, current) {
			return Number(prev) + Number(current.deduction_amt)
		}, 0);
		mainCalculation('deduction',Number(total_deduction) + Number(tot_ded));
        ((Number(total_pay) > 0) ? setSaveBtnDisabled(false) : setSaveBtnDisabled(true));
		let dueAmt = (Number(due) - Number(received) - Number(tot_ded)).toFixed(2);
		let absDue = Math.abs(dueAmt);
		setDue((absDue === 0)?0:dueAmt);
		if( dueAmt < 0 ){
			let advance_amount = (Number(total_pay) + Number(tot_ded) + Number(totalPaidAmt) + Number(totaldeductAmt)) - Number(total_amt);
			setAdvanceAmount(advance_amount);
			setShowAdvanceDiv(true);
		} else {
			setAdvanceAmount(0);
			setShowAdvanceDiv(false);
		}
	}
	const removeDeduction = (index) => {
		let price = deductions[index].deduction_amt;
		setDeductions(deductions.filter((item,key) => key !== index));
		let totReceived = Number(received) + Number(price);
		setReceived(totReceived);
		let dueAmt = Number(due) + Number(price);
		setDue(dueAmt);
		
		if( dueAmt < 0 ){
			let advance_amount = (Number(total_payment) + Number(total_deduction)) - Number(total_amt);
			setAdvanceAmount(advance_amount);
			setShowAdvanceDiv(true);
		} else {
			setAdvanceAmount(0);
			setShowAdvanceDiv(false);
		}
	}
	const addAdvanceAdjust = () => {
		if( adjustedAmount === undefined || Number(adjustedAmount) === 0 ){
			Common.toast.error('Received amount should be greater than zero.');
			return false;
		}
		if( Number(advanceBalance) < Number(adjustedAmount) ){
			Common.toast.error('Invoice amount is greated than advance balance amount.');
			return false;
		}
		let advance = {};
		advance['adjustedAmount'] = adjustedAmount;
		advance['advanceAdjustedRemarks'] = advanceAdjustedRemarks;
		let total_advance = Number(totalAdjustedAmount) + Number(adjustedAmount);

		setAdvanceAdjust([...advanceAdjust, advance]);
		advanceModal('hide');
		setTotalAdvanceAdjust(total_advance);
		setAdvanceBalance(Number(advanceBalance) - Number(adjustedAmount));
		let total_pay = Number(total_payment) + Number(adjustedAmount);
		// setTotalPayment(total_pay);
		let dueAmt = Number(due) - Number(adjustedAmount);
		setDue(dueAmt);

		if( dueAmt < 0 ){
			let advance_amount = (Number(total_pay) + Number(total_deduction)) - Number(total_amt);
			setAdvanceAmount(advance_amount);
			setShowAdvanceDiv(true);
		} else {
			setAdvanceAmount(0);
			setShowAdvanceDiv(false);
		}

		mainCalculation('received',total_pay);
		((Number(total_pay) > 0) ? setSaveBtnDisabled(false) : setSaveBtnDisabled(true));
	}
	const removeAdvanceAdjust = (index) => {
		let price = advanceAdjust[index].adjustedAmount;

		setAdvanceAdjust(advanceAdjust.filter((item,key) => key !== index));
		let total_advance = Number(totalAdjustedAmount) - Number(price);
		setTotalAdvanceAdjust(total_advance);
		setAdvanceBalance(Number(advanceBalance) + Number(price));
		let total_pay = Number(total_payment) - Number(price);
		// setTotalPayment(total_pay);
		let dueAmt = Number(due) + Number(price);
		setDue(dueAmt);
		if( dueAmt < 0 ){
			let advance_amount = (Number(total_pay) + Number(total_deduction)) - Number(total_amt);
			setAdvanceAmount(advance_amount);
			setShowAdvanceDiv(true);
		} else {
			setAdvanceAmount(0);
			setShowAdvanceDiv(false);
		}
		mainCalculation('received',total_pay);
		((Number(total_pay) > 0) ? setSaveBtnDisabled(false) : setSaveBtnDisabled(true));
	}
    const mainCalculation = (param,val) => {
		// let total_temp = 0,recv_temp = 0,ded_temp=0,due_temp = 0;
		let total_temp = 0;
		if( total_amt !== undefined ){
			total_temp = total_amt;
		}
		if( param === 'total_amt' ){
			total_temp = val;
			setTotalAmt(total_temp);
		}
		// if( total_payment !== undefined ){
		// 	recv_temp = total_payment;
		// }
		if( param === 'received' ){
			setTotalPayment(val.toFixed(2));
		}
		// if( total_deduction !== undefined ){
		// 	ded_temp = total_deduction;
		// }
		if( param === 'deduction' ){
			setTotalDeduction(Number(val).toFixed(2));
		}
	}
    const calcDeduction = (param,val) => {
		let percent = 0,amount = 0;
		if( param === 'per' ){
			percent = val;
            amount = (total_taxable_amt * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
            percent = (val/total_taxable_amt * 100).toFixed(2);
		}
		setDeductionPer(percent);
		setDeductionAmt(amount);
	}
	const addDeduction = () => {
		if( deduction_per === undefined || deduction_per === '' || deduction_per > 100 ){
			Common.toast.error('Deduction percent is invalid.');
			return false;
		}
		if( deduction_amt === undefined || deduction_amt === '' || Number(deduction_amt) === 0 ){
			Common.toast.error('Deduction amount should be greater than zero.');
			return false;
		}

		if( deductionMasterLabel === undefined || deductionMasterLabel === '' ){
			Common.toast.error('Deduction type is required.');
			return false;
		}
		let tot_ded = deductions.reduce(function(prev, current) {
			return Number(prev) + Number(current.deduction_amt)
		}, 0);

		if( Number(total_deduction) + Number(tot_ded) + Number(deduction_amt) + Number(totaldeductAmt) > Number(total_taxable_amt) ){
			Common.toast.error('Total deduction amount cannot be greater than total taxable value.');
			return false;
		}
		let ded = {};
		ded['deduction_per'] = deduction_per;
		setDeductionPer('');
		ded['deduction_amt'] = deduction_amt;
		setDeductionAmt('');
		ded['deduction_type'] = deductionMasterLabel;
		frmSaleDedType.current.clear();
		ded['deduction_subtype'] = deductionSubtypeMasterLabel;
		frmSaleDedSubtype.current.clear();
		ded['deduction_master_id'] = deductionMaster
		ded['deduction_subtype_label'] = deductionSubtypeMaster;
		// ded['deduction_remarks'] = deduction_remarks;
		// setDeductionRemarks('');

		setDeductions([...deductions, ded]);
		let totReceived = Number(received) - Number(deduction_amt);
		setReceived(totReceived);
		setDeductionSubtypeMasterList([])
		setDeductionSubtypeMasterLabel('');
		setIsDeductionMasterSelected(false)
	}    
	const deductionModal = (param) => {
		setDedModalShow(true);
		setTempDeduction(param);
	}
    const paymentModal = (param) => {
		if( param === 'show' ){
			setPayModalShow(true);
		}
		if( param === 'hide' ){
			setPayModalShow(false );
			setReceived('');
			setPaymentDate(new Date());
			setPaymentMode('UPI/QR');
			setListOfBanks();
			setDefaultPayee([])
			setBankAccount(0)
			setPaymentRemarks('');
			setTransRef('');
			setDeductionDiv(false);
			setDeductions([]);
			setIsDeductionMasterSelected(false)
		}
	}
	const advanceModal = (param) => {
		if( param === 'show' ){
			setAdvanceAdjustModalShow(true);
		}
		if( param === 'hide' ){
			setAdvanceAdjustModalShow(false );
			setAdjustedAmount('');
			setAdvanceAdjustedRemarks('');
		}
	}

    const checkPayment = () => {
		if( received === undefined || Number(received) === 0 ){
			Common.toast.error('Enter the payment before adding deduction.');
			paymentReceivedRef.current.focus();
			return false;
		}
	}
    const setDeductionTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		setDeductionMaster(value);
		setDeductionMasterLabel(label)
		if (value === '') {
			setIsDeductionMasterSelected(false);
			setDeductionSubtypeMasterList([])
		} else {
			setIsDeductionMasterSelected(true);
		}
		if (value !== '') {
			Common.axios({
				method: 'post',
				url: props.url+'ajax/getDeductionSubtypeMaster',
				data: {
					'pid': localStorage.getItem('fo_profileid'),
					'deduction_master_id':value
				}
			}).then(function (response) {
				if( response.data.success ){
					console.info(response.data.data)
					let dedSubtypeList = response.data.data;
					if (dedSubtypeList.length > 0) {
						setDeductionSubtypeMasterList(response.data.data);
					}
				}
			}).catch(error => console.log(error));
		} else {
			setDeductionSubtypeMaster(value);
			frmSaleDedSubtype.current.clear();
			setDeductionSubtypeMasterList([])
		}
	}
	const setDeductionSubTypeSelect = (e) => {
		if (e.length > 0) {
			setDeductionSubtypeMaster(e[0].label);
			setDeductionSubtypeMasterLabel(e[0].label)
		}
	}
    const setPaymentModeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setPaymentMode(value);
		if(value === 'Cash'){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/'+value,
			}).then(function (response) {
				if(response.data.success){
					if(response.data.data !== ''){
						setBankCashAccount(1);
						setDefaultPayee(response.data.data);
						setBankAccount(response.data.data[0].value);
						setBankList(response.data.data);
					}else{
						setBankCashAccount(0);
						setDefaultPayee([]);
						setBankAccount(0);
					}
				}
			}).catch(error => console.log(error));
		}else{
			setBankCashAccount(1);
			setDefaultPayee([]);
			setListOfBanks();
		}
	}
	const setListOfBanks = () => {
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				// setDefaultPayee(response.data.defaultPayee);
				// if (response.data.defaultPayee.length > 0) {
				// 	setBankAccount(response.data.defaultPayee[0].value);
				// }
			}
		}).catch(error => console.log(error));
	}
	const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setBankAccount(value);
		// setDefaultPayee('');
		if (value === '') {
			setDefaultPayee([]);
		} else {
			bankList.forEach((bank,i) => {
				if (bank.value === value) {
					setDefaultPayee([bank]);
				}
			});
		}
	}

    const handleEditPayable = (e)=>{
        e.preventDefault();
        let formData = new FormData(e.target);
        let pid = localStorage.getItem('fo_profileid');
        formData.append('pid', pid);
		formData.append('total_deduction', Number(total_deduction) + Number(totaldeductAmt));
		formData.append('total_payment', Number(total_payment) + Number(totalPaidAmt));
        if( payments.length > 0 ){
			formData.append('payments', JSON.stringify(payments));
		}
		if( advanceAdjust.length > 0 ){
			formData.append('advanceAdjust', JSON.stringify(advanceAdjust));
		}
        let url = props.url+'payable/edit';
        let data = {};
   
        // convert the key/value pairs
      for (var [key, value] of formData.entries()) { 
        data[key] = value;
       }
       if(Number(data['paid_amount'])===0){
            Common.toast.error('Paid amount is Required.');
            return false;
        }       
        setSaveBtnDisabled(true);
        Common.axios({
            method: 'post',
            url:url,
            data: {
                formData:data
            },
        }).then(function (response) {
			if(response.data.success){
                navigate("/payable-listing");
				setSucces(response.data.message);
			} else{
                setError(response.data.message);
            }

		    setTimeout(() => {
				setError('');
				setSucces('');
			}, 5000);
        }).catch(error => console.log(error));
    }
  
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="edit-payable" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Expenses & Payments /</span> Edit Payable</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                        {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                        {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>)}
                                        {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                        {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>)}
                                            <form id="formEditPayable" onSubmit={handleEditPayable} autoComplete="off" className='formEditPayable'>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="mb-1 col-5">
                                                                <label htmlFor="gstin" className="form-label">Seller Name</label>
                                                            </div>
                                                            <div className="mb-1 col-7">
                                                                <div className="input-group input-group-merge">
                                                                    <span>{data.client_name}</span>
                                                                </div>
                                                                <input type='hidden' value={payable_id} name="payables_id"/>
                                                                <input type='hidden' value={purchase_id} name="purchase_id"/>
                                                                <input type='hidden' value={seller_id} name="seller_id"/>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="mb-1 col-5">
                                                                <label htmlFor="party_name" className="form-label">Phone Number</label>
                                                            </div>
                                                            <div className="mb-1 col-7">
                                                                <div className="input-group input-group-merge">
                                                                    <span>{data.mobile}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="mb-1 col-5">
                                                                <label htmlFor="party_name" className="form-label">Invoice Number</label>
                                                            </div>
                                                            <div className="mb-1 col-7">
                                                                <div className="input-group input-group-merge">
                                                                    <span>{data.inv_num}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="mb-1 col-5">
                                                                <label htmlFor="party_name" className="form-label">Invoice Date</label>
                                                            </div>
                                                            <div className="mb-1 col-7">
                                                                <div className="input-group input-group-merge">
                                                                <span>{data.inv_date}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="mb-1 col-5">
                                                                <label htmlFor="party_name" className="form-label">Total</label>
                                                            </div>
                                                            <div className="mb-1 col-7">
                                                                <div className="input-group input-group-merge">
                                                                <span>{total_amt}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {showAdvanceDiv && (
                                                            <div className="row mt-1">
                                                                <label className="col-5 col-form-label"></label>
                                                                <div className="col-7">
                                                                    <div className="input-group input-group-merge">
                                                                    <label htmlFor="party_name" className="form-label text-danger"><small><u>Note</u>: Amount paid is greater than due amount. Balance <b>{Common.displayPrice(advance_amount)}</b> will be added automatically to advance paid <Common.OverlayTrigger overlay={Common.popoverContent('','Last payment mode, payment date, payment remarks and transaction number will be considered for this advance amount.')}><i className='bx bxs-info-circle' ></i></Common.OverlayTrigger></small> </label>
                                                                    <input type="hidden" id="advance_amount" name="advance_amount" value={advance_amount} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="row">
                                                            <div className="mb-1 col-5">
                                                                <label htmlFor="party_name" className="form-label">Total Payment</label>
                                                            </div>
                                                            <div className="mb-1 col-7">
                                                                <div className="input-group input-group-merge">
                                                                <span>{Number(total_payment) + Number(totalPaidAmt)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="mb-1 col-5">
                                                                <label htmlFor="party_name" className="form-label">Total Deductions</label>
                                                            </div>
                                                            <div className="mb-1 col-7">
                                                                <div className="input-group input-group-merge">
                                                                <span>{Number(total_deduction) + Number(totaldeductAmt)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="mb-1 col-5">
                                                                <label htmlFor="party_name" className="form-label">Due</label>
                                                            </div>
                                                            <div className="mb-1 col-7">
                                                                <div className="input-group input-group-merge">
                                                                <span>{due}</span>
                                                                <input type="hidden" id="due_amount" name="due_amount" value={due} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div id="summaryDiv" className="row justify-content-md-center">
                                                            <div className="col-md-12" id="summaryDivL">
                                                                <div className="text-right mb-2">
																{ advanceBalance > 0 && <>
																	<Button variant="info" size="sm" onClick={()=>{advanceModal('show');}}><i className='bx bx-plus' ></i> Advance adjust</Button>&nbsp;&nbsp;
																</>}
                                                                    <button type="button" onClick={()=>{paymentModal('show');setReceived(due);}} className="btn btn-sm btn-success"><i className='bx bx-plus' ></i> Payment</button>
                                                                </div>
                                                                <div className="card">
                                                                    <h6 className="card-header">Payments</h6>
                                                                    {payments.length === 0 && <div className="text-center py-1">No payments done</div>}
                                                                    {payments.length > 0 && <>
                                                                    <div className="table-responsive text-nowrap">
                                                                        <table className="table table-sm">
                                                                            <thead className="table-light">
                                                                                <tr>
                                                                                    <th align="center">Action</th>
                                                                                    <th>Sl.</th>
                                                                                    <th align="right">Received</th>
                                                                                    <th>Date</th>
                                                                                    <th>Mode</th>
																			        <th>Total Deduction</th>
                                                                                    <th>Remarks</th>
                                                                                    <th>Transaction Ref.</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="table-border-bottom-0">
                                                                                {payments.map((val,index) => (
                                                                                    <tr key={'pay'+index}>
                                                                                        <td align="center">
                                                                                            <button type="button" className="btn btn-sm" onClick={() => removePayment(index,val.received)}><i className='bx bx-trash'></i></button>
                                                                                        </td>
                                                                                        <td>{index+1}</td>
                                                                                        <td align="right">{Common.displayPrice(val.received)}</td>
                                                                                        <td>
                                                                                            <Moment format={Common.date_format}>{val.payment_date}</Moment>
                                                                                        </td>
                                                                                        <td>{val.payment_mode}</td>
                                                                                        <td align="right">
																					{(val.deductions.reduce(function(prev, current) {
																						return prev + +current.deduction_amt
																					}, 0) > 0) ? <>
																						<button className="btn btn-link text-decoration-underline" onClick={() => deductionModal(val.deductions)}>
																						{Common.displayPrice(val.deductions.reduce(function(prev, current) {
																							return prev + +current.deduction_amt
																						}, 0))}
																						</button>
																					</> : '-'}
																				</td>
                                                                                        <td>{val.payment_remarks}</td>
                                                                                        <td>{val.trans_ref}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    </>}
                                                                </div>
																<div className="card">
																	<h6 className="card-header">Advance adjust</h6>
																	{advanceAdjust.length === 0 && <div className="text-center py-1">No advance adjust done</div>}
																	{advanceAdjust.length > 0 && <>
																	<div className="table-responsive text-nowrap">
																		<table className="table table-sm table-bordered">
																			<thead className="table-light">
																				<tr>
																					<th align="center">Action</th>
																					<th>Sl.</th>
																					<th align="right">Advance Adjust</th>
																					<th>Remarks</th>
																				</tr>
																			</thead>
																			<tbody className="table-border-bottom-0">
																				{advanceAdjust.map((val,index) => (
																					<tr key={'advance'+index}>
																						<td align="center">
																							<button type="button" className="btn btn-sm" onClick={() => removeAdvanceAdjust(index)}><i className='bx bx-trash'></i></button>
																						</td>
																						<td>{index+1}</td>
																						<td align="right">{Common.displayPrice(val.adjustedAmount)}</td>
																						<td>{val.advanceAdjustedRemarks}</td>
																					</tr>
																				))}
																			</tbody>
																		</table>
																	</div>
																	</>}
																</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="row">
                                                    <div className="col-sm-7 text-right">
                                                        <a href="/payable-listing" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                        &nbsp;&nbsp;
                                                        <button type='submit' disabled={saveBtnDisabled} className="btn btn-sm btn-primary" ><i className='bx bx-save' ></i> Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        <Modal size={ deduction_div ? 'xl':'md'} show={payModalShow} onHide={()=>paymentModal('hide')} aria-labelledby="payment-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<div className="col-4 modal-mobile-view">
					<Modal.Title id="payment-modal">Payment {payments.length + 1}</Modal.Title>
				</div>
				<div className="col text-right">
					<span id="payModalInfo" className="badge bg-warning">
						Total Invoice Value: {Common.displayPrice(total_amt)}
						{/* { deduction_div && <>
						, Total Deduction added: {Common.displayPrice(Number(total_deduction) + Number(totaldeductAmt))}
						</>} */}
					</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className={deduction_div ? 'col-sm-6':''}>
						<div className="row">
							<label className="col-4 col-form-label" htmlFor="frmSaleTotal">Amount <span className="text-danger">*</span></label>
							<div className="col-8">
								<div className="input-group input-group-merge">
									<span className="input-group-text"><i className='bx bx-rupee'></i></span>
									<input ref={paymentReceivedRef} type="text" className="form-control" id="frmSaleTotal" placeholder="0.00" value={received} 
										onChange={(e) => {
											setReceived(Common.acceptOnlyDecimal(e.target.value));
											setDeductionPer('');
											setDeductionAmt('');
										}}
									autoComplete="off"/>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmSalePayDate">Payment Date</label>
							<div className="col-8">
								<DatePicker data-testid="frmSalePayDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setPaymentDate} value={payment_date} />
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmSalePayMode">Payment Mode</label>
							<div className="col-8">
								<Typeahead clearButton defaultSelected={paymentModes.slice(1, 2)} id="frmSalePayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
									options={paymentModes}
									renderMenuItemChildren={(option: Item,props) => (
										<Highlighter search={props.text}>
											{option.label}
										</Highlighter>
									)}
								/>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmBankAccount">Bank Account <span className="text-danger">*</span></label>
							<div className="col-8">
								<Typeahead selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
									options={bankList}
									renderMenuItemChildren={(option: Item,props) => (
										<Highlighter search={props.text}>
											{option.label}
										</Highlighter>
									)}
								/>
								<small>To add additional bank account, Go to Account management - <a href='/bank-accounts'>Bank accounts</a></small>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmSalePayRemarks">Payment Remarks</label>
							<div className="col-8">
								<input type="text" className="form-control" id="frmSalePayRemarks" placeholder="Payment Remarks" value={payment_remarks} onChange={(e) => setPaymentRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmSaleTransRef">Transaction Ref. No.</label>
							<div className="col-8">
								<input type="text" maxLength="100" className="form-control" id="frmSaleTransRef" placeholder="Transaction Ref." value={trans_ref} onChange={(e) => setTransRef(e.target.value)} autoComplete="off"/>
							</div>
						</div>
					</div>
					<div className={deduction_div ? 'col-sm-6':'d-none'}>
						{/*<div className="form-text text-right text-info">
							Total Invoice Value: {Common.displayPrice(total_amt)}, Total Deduction added: {Common.displayPrice(Number(total_deduction))}
						</div>*/}
						<div className="card">
							<h6 className="card-header">Deductions</h6>
							{deductions.length === 0 && <div className="text-center py-1">No deduction</div>}
							{deductions.length > 0 && <>
							<div className="table-responsive text-nowrap">
								<table className="table table-sm table-bordered">
									<thead className="table-light">
										<tr>
											<th align="center">Action</th>
											<th>Sl.</th>
											<th align="right">Deduction (%)</th>
											<th align="right">Deduction (<i className='bx bx-rupee' ></i>)</th>
											<th>Deduction Type</th>
											<th>Deduction Subtype</th>
											{/* <th>Remarks</th> */}
										</tr>
									</thead>
									<tbody className="table-border-bottom-0">
										{deductions.map((val,index) => (
											<tr key={'ded'+index}>
												<td align="center">
													<button type="button" className="btn btn-sm" onClick={() => removeDeduction(index)}><i className='bx bx-trash'></i></button>
												</td>
												<td>{index+1}</td>
												<td align="right">{Common.displayPercent(val.deduction_per)}%</td>
												<td align="right">{Common.displayPrice(val.deduction_amt)}</td>
												<td>{val.deduction_type}</td>
												<td>{val.deduction_subtype}</td>
												{/* <td>{val.deduction_remarks}</td> */}
											</tr>
										))}
									</tbody>
								</table>
							</div>
							</>}
						</div>
						<div className="alert alert-secondary mt-3">
							<div className="row">
								<h4>Add Deduction</h4>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="row">
										<label className="col-sm-5 col-form-label" htmlFor="frmSaleDedPer">Percent <span className="text-danger">*</span></label>
										<div className="col-sm-7">
											<div className="input-group input-group-merge">
												<span className="input-group-text"><i className='bx bxs-offer'></i></span>
												<input type="text" className="form-control" id="frmSaleDedPer" placeholder="0.00" value={deduction_per} onFocus={checkPayment} onChange={(e) => calcDeduction('per',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
											</div>
										</div>
									</div>
									<div className="row text-right">
                                        <small>(Deduction at source and on base value)</small>
									</div>
								</div>
								<div className="col-6">
									<div className="row">
										<label className="col-sm-4 col-form-label" htmlFor="frmSaleDedAmt">Amount <span className="text-danger">*</span></label>
										<div className="col-sm-8">
											<div className="input-group input-group-merge">
												<span className="input-group-text"><i className='bx bx-rupee'></i></span>
												<input type="text" className="form-control" id="frmSaleDedAmt" placeholder="0.00" value={deduction_amt} onFocus={checkPayment} onChange={(e) => calcDeduction('amt',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-4 col-form-label" htmlFor="frmSaleDedType">Deduction Type <span className="text-danger">*</span></label>
								<div className="col-8">
									<Typeahead clearButton ref={frmSaleDedType} id="frmSaleDedType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDeductionTypeSelect(e)} placeholder="Choose Deduction Type"
										options={deductionMasterList}
										renderMenuItemChildren={(option: Item,props) => (
											<Highlighter search={props.text}>
												{option.label}
											</Highlighter>
										)}
									/>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-4 col-form-label" htmlFor="frmSaleDedRemarks">Deduction Subtype</label>
								<div className="col-8">
									{/* <input type="text" className="form-control" id="frmSaleDedRemarks" placeholder="Deduction Remarks" value={deduction_remarks} onChange={(e) => setDeductionRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/> */}
									<Typeahead clearButton id="frmSaleDedSubtype" allowNew newSelectionPrefix="Add: " ref={frmSaleDedSubtype} disabled={isDeductionMasterSelected === false ? true : false} labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDeductionSubTypeSelect(e)} placeholder="Choose Deduction Sub Type"
										options={deductionSubtypeMasterList}
										renderMenuItemChildren={(option: Item,props) => (
											<Highlighter search={props.text}>
												{option.label}
											</Highlighter>
										)}
									/>
								</div>
							</div>
							<div className="text-center mt-3">
								<button type="button" className="btn btn-sm btn-primary ms-1" onClick={addDeduction}><span className="tf-icons bx bx-save"></span>&nbsp;Add</button>
							</div>
						</div>
					</div>
				</div>
				<div className="text-center mt-3">
					<button type="button" onClick={()=>{setDeductionDiv(!deduction_div);setDeductionAmt('');setDeductionPer('');frmSaleDedType.current.clear();}} className={"btn btn-sm btn-"+(deduction_div ? 'danger':'success')}>{deduction_div ? <><i className='bx bx-minus'></i></> : <><i className='bx bx-plus'></i></>} Deduction</button>
					{bank_cash_account === 1 && (<>
						<button type="button" className="btn btn-sm btn-primary ms-1" onClick={addPayment}><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
					</>)}
				</div>
			</Modal.Body>
		</Modal>
		<Modal show={dedModalShow} onHide={()=>setDedModalShow(false)} aria-labelledby="deduction-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="deduction-modal">Deductions</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					{temp_deduction !== undefined && temp_deduction.length === 0 && <div className="text-center py-1">No deduction</div>}
					{temp_deduction !== undefined && temp_deduction.length > 0 && <>
					<div className="table-responsive text-nowrap">
						<table className="table table-sm table-bordered">
							<thead className="table-light">
								<tr>
									<th>Sl.</th>
									<th align="right">Deduction (%)</th>
									<th align="right">Deduction (<i className='bx bx-rupee' ></i>)</th>
									<th>Deduction Type</th>
									<th>Deduction Subtype</th>
									{/* <th>Remarks</th> */}
								</tr>
							</thead>
							<tbody className="table-border-bottom-0">
								{temp_deduction.map((val,index) => (
									<tr key={'ded1'+index}>
										<td>{index+1}</td>
										<td align="right">{Common.displayPercent(val.deduction_per)}%</td>
										<td align="right">{Common.displayPrice(val.deduction_amt)}</td>
										<td>{val.deduction_type}</td>
										<td>{val.deduction_subtype}</td>
										{/* <td>{val.deduction_remarks}</td> */}
									</tr>
								))}
							</tbody>
						</table>
					</div>
					</>}
				</div>
			</Modal.Body>
		</Modal>
		<Modal size='md' show={advanceAdjustModalShow} onHide={()=>advanceModal('hide')} aria-labelledby="advance-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<div className="col-4 modal-mobile-view">
					<Modal.Title id="advance-modal">Advance adjust</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<label className="col-5 col-form-label" htmlFor="frmAdvanceBalance">Total Advance Balance</label>
					<div className="col-7">
						<div className="input-group input-group-merge">
							<span className="input-group-text"><i className='bx bx-rupee'></i></span>
							<input type="text" readOnly className="form-control" id="frmAdvanceBalance" placeholder="0.00" value={advanceBalance}
							autoComplete="off"/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<label className="col-5 col-form-label" htmlFor="frmAdjustedAmount">Adjusted amount <span className="text-danger">*</span></label>
					<div className="col-7">
						<div className="input-group input-group-merge">
							<span className="input-group-text"><i className='bx bx-rupee'></i></span>
							<input type="text" className="form-control" id="frmAdjustedAmount" placeholder="0.00" value={adjustedAmount}
								onChange={(e) => {
									setAdjustedAmount(Common.acceptOnlyDecimal(e.target.value));
								}}
							autoComplete="off"/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<label className="col-5 col-form-label" htmlFor="frmSalePayRemarks">Advance Adjusted Remarks</label>
					<div className="col-7">
						<input type="text" className="form-control" id="frmSalePayRemarks" placeholder="Remarks" value={advanceAdjustedRemarks} onChange={(e) => setAdvanceAdjustedRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/>
					</div>
				</div>
				<div className="text-center mt-3">
					<Button variant="primary" size="sm" className="ms-1" onClick={addAdvanceAdjust}><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
				</div>
			</Modal.Body>
		</Modal>
        </>
    );
}