import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import paymentModes from '../masters/PaymentModes.js';
import * as Common from '../common/ImportUserEssentials';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';

export default function AddInvestments(props){
    const [saveBtnDisabled,setSaveBtnDisabled]         = useState(false);
	const [shareholder_master,setShareholderMaster]    = useState([]);
	const [shareholder,setShareholder]                 = useState('');	
	const [demat_account,setDematAccount]             = useState('');
	const [face_value,setFaceValue] 		          = useState('');
	const [number_of_share,setNumberOfShares] 		  = useState('');
	const [price_per_share,setPricePerShare] 		  = useState('');
	const [investment_amount,setInvestmentAmt] 		  = useState('');
	const [investment_date,setInvestmentDate] 		  = useState('');
	const [folio_numbers,setFolioNumbers] 		      = useState('');
	const [bankList, setBankList] 					  = useState([]);
	const [bankAccount,setBankAccount] 				  = useState(0);
	const [defaultPayee, setDefaultPayee] 			  = useState([]);
	const [payment_mode,setPaymentMode]               = useState('UPI/QR');

	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Add Investments | '+props.site_name;
		if (localStorage.getItem('fo_subplan') === 'Basic') {
			navigate("/pricing-plan");
		}
		// get Shareholder
		Common.axios({
			method: 'post',
				url: props.url+'investments/fetch_shareholder',
				data: {
					'pid': localStorage.getItem('fo_profileid')
				}
		}).then(function (response) {
			if( response.data.success ){
				setShareholderMaster(response.data.data);
			}
		}).catch(error => console.log(error));

		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				setDefaultPayee(response.data.defaultPayee);
				if (response.data.defaultPayee.length > 0) {
					setBankAccount(response.data.defaultPayee[0].value);
				}
			}
		}).catch(error => console.log(error));

	}, []);
    const setShareholderSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setShareholder(value);
	}
	const calcuateInvestmentAmt = (val,tytpe) => {
		let totInvestmentAmt = 0;
		if(tytpe === 'NS'){
			totInvestmentAmt = val * price_per_share;
		}
		else if(tytpe === 'PS'){
			totInvestmentAmt = number_of_share * val;
		}
		setInvestmentAmt(totInvestmentAmt);
	}
    const handleInvestments =(e) => {
		e.preventDefault();
		let formData = new FormData(e.target);
		let pid = localStorage.getItem('fo_profileid');
		formData.append('pid', pid);
        formData.append('shareholder', shareholder);
        formData.append('bankAccount', bankAccount);
		formData.append('payment_mode', payment_mode);
		let data = {};
		// convert the key/value pairs
		for (var [key, value] of formData.entries()) {
		   data[key] = value;
		}
		if( data.investment_date === ''){
			Common.toast.dismiss();
			Common.toast.error('Investment Date is required.');
			return false;
		}
		if( payment_mode === '' ){
			Common.showLoader(false);
			Common.toast.error('Payment Mode is required');
			return false;
		}
		// payment_mode !== 'Cash' &&
		if( bankAccount === undefined || bankAccount === 0 || bankAccount === '' ){
			Common.showLoader(false);
			Common.toast.error('Bank Account is required.');
			return false;
		}
        setSaveBtnDisabled(true);
		Common.axios({
			method: 'post',
			url: props.url+'investments/add',
			data: data
		}).then(function (response) {
			if(response.data.success){
				navigate("/investments-listing");
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const setPaymentModeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		console.log(value);
		setPaymentMode(value);
		if(value === 'Cash'){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/'+value,
			}).then(function (response) {
				if(response.data.success){
					if(response.data.data !== ''){
						setDefaultPayee([response.data.data[0]]);
						setBankAccount(response.data.data[0].value);
						setBankList(response.data.data);
					}else{
						setDefaultPayee([]);
						setBankAccount(0);
					}
				}
			}).catch(error => console.log(error));
		}else{
			setDefaultPayee([]);
			setBankAccount(0);
			setListOfBanks();
		}
	}
	const setListOfBanks = () => {
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				// setDefaultPayee(response.data.defaultPayee);
				// if (response.data.defaultPayee.length > 0) {
				// 	setBankAccount(response.data.defaultPayee[0].value);
				// }
			}
		}).catch(error => console.log(error));
	}
	const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : 0;
		setBankAccount(value);
		if (value === 0) {
			setDefaultPayee([]);
		} else {
			bankList.forEach((bank,i) => {
				if (bank.value === value) {
					setDefaultPayee([bank]);
				}
			});
		}
	}
	const handleLoanDate = (e) => {
		console.log(e);
		if (e === null) {
			setInvestmentDate('')
			setDefaultPayee([]);
			setListOfBanks();
		} else {
			setInvestmentDate(e)
		}
	}
  
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="add-investments" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Loans & Investments /</span> Add Investments</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            <form id="formAddCollection" onSubmit={handleInvestments} autoComplete="off" className='formAddCollection'>
                                            <div className="row" id="collectionAddDiv">
													<div className="col-12">
														<div className="accordion" id="salesAccordion">
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingTwo">
																	<button type="button" className="accordion-button" data-bs-target="#accordionVoucher" aria-expanded="false" aria-controls="accordionVoucher" disabled>
                                                                        Investments Details
																	</button>
																</h2>
																<div id="accordionVoucher" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <Typeahead clearButton allowNew newSelectionPrefix="Add: " id="frmShareholderName" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setShareholderSelect(e)} options={shareholder_master} placeholder="Choose Shareholder"
                                                                                            renderMenuItemChildren={(option: Item,props) => (
                                                                                                <>
                                                                                                <Highlighter search={props.text}>
                                                                                                    {option.label}
                                                                                                </Highlighter>
                                                                                                </>
                                                                                            )}
                                                                                        />
																					<label htmlFor="frmShareholderName">Shareholder Name <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" name="demat_account" className="form-control" id="frmDematAccount" placeholder="Enter Demat account" value={demat_account} onChange={(e) => { setDematAccount(Common.acceptOnlyCharsNumbers(e.target.value)); }}/>
																					<label htmlFor="frmDematAccount">Demat account</label>
																				</div>
																			</div>
																		</div>
                                                                        <div className="row">
                                                                            <div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" name="face_value" className="form-control" id="frmFaceValue" placeholder="0.00" value={face_value} onChange={(e) => { setFaceValue(Common.acceptOnlyDecimal(e.target.value)); }} required/>
																					<label htmlFor="frmFaceValue">Face value per share <span className="text-danger">*</span></label>
																				</div>
																			</div>
                                                                            <div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" name="number_of_share" className="form-control" id="frmNumberOfShares" placeholder="0.000" value={number_of_share} onChange={(e) => { setNumberOfShares(Common.acceptOnlyDecimal(e.target.value));calcuateInvestmentAmt(e.target.value,'NS'); }} required/>
																					<label htmlFor="frmNumberOfShares">Number of shares <span className="text-danger">*</span></label>
																				</div>
																			</div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" name="price_per_share" className="form-control" id="frmPricePerShare" placeholder="0.00" value={price_per_share} onChange={(e) => { setPricePerShare(Common.acceptOnlyDecimal(e.target.value));calcuateInvestmentAmt(e.target.value,'PS'); }} required/>
																					<label htmlFor="frmPricePerShare">Price per share <span className="text-danger">*</span></label>
																				</div>
																			</div>
                                                                            <div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" name="investment_amount" className="form-control" id="frmInvestmentAmt" placeholder="0.00" value={investment_amount} readOnly/>
																					<label htmlFor="frmInvestmentAmt">Investment amount </label>
																				</div>
																			</div>
                                                                        </div>
                                                                        <div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <DatePicker name="investment_date" data-testid="frmInvestmentDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={(e) => handleLoanDate(e)} value={investment_date} />
																					<label htmlFor="frmInvestmentDate">Investment date <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input name="folio_numbers" type="text"  className="form-control" id="frmFolioNumbers" placeholder="Enter Folio Numbers" onChange={(e) => { setFolioNumbers(e.target.value); }} value={folio_numbers} />
																					<label htmlFor="frmFolioNumbers">Folio numbers</label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																			{investment_date && <>
																				<div className="form-floating">
																				<Typeahead defaultSelected={paymentModes.slice(1, 2)} clearButton id="frmPayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
																					options={paymentModes}
																					renderMenuItemChildren={(option: Item,props) => (
																						<Highlighter search={props.text}>
																							{option.label}
																						</Highlighter>
																					)}
																				/>
																					<label htmlFor="frmPayMode">Payment Mode <span className="text-danger">*</span></label>
																				</div>
																			</>}
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																			{investment_date && <>
																				<div className="form-floating">
																				<Typeahead selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
																					options={bankList}
																					renderMenuItemChildren={(option: Item,props) => (
																						<Highlighter search={props.text}>
																							{option.label}
																						</Highlighter>
																					)}
																				/>
																					<label htmlFor="frmBankAccount">Bank Account <span className="text-danger">*</span></label>
																				</div>
																				<small>To add additional bank account, Go to Account management - <a href='/bank-accounts'>Bank accounts</a></small>
																			</>}
																			</div>
																		</div>
																		<div className="row mt-4">
																			<div className="col-sm-7 text-right">
																				<a href="/investments-listing" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
																				&nbsp;&nbsp;
																				<button type='submit' className="btn btn-sm btn-primary" disabled={saveBtnDisabled} ><i className='bx bx-save' ></i> Save</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}