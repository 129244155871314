import React from 'react'
import * as Common from '../common/ImportUserEssentials';

export default function PostLogin(props){
	const navigate = Common.useNavigate();
	React.useEffect(() => {
		Common.axios({
			method: 'post',
			url: props.url+'user/profilePermission',
			data: {pid:localStorage.getItem('fo_profileid')},
		}).then(function (response) {
			if( response.data.success ){
				localStorage.setItem('fo_userperm',JSON.stringify(response.data.data));
				if( Object.keys(response.data.data).length > 0 ){
					if(response.data.data[1]['view'] !== undefined && response.data.data[1]['view'] === '1' ){
						navigate("/profiles");
						return false;
					}
					if(response.data.data[2]['view'] !== undefined && response.data.data[2]['view'] === '1' ){
						navigate("/sales-listing");
						return false;
					}
					if(response.data.data[3]['view'] !== undefined && response.data.data[3]['view'] === '1' ){
						navigate("/expense-listing");
						return false;
					}
					if(response.data.data[4]['view'] !== undefined && response.data.data[4]['view'] === '1' ){
						navigate("/item-listing");
						return false;
					}
					if(response.data.data[5]['view'] !== undefined && response.data.data[5]['view'] === '1' ){
						navigate("/user-listing");
						return false;
					}
					if(response.data.data[6]['view'] !== undefined && response.data.data[6]['view'] === '1' ){
						navigate("/gst");
						return false;
					}
					if(response.data.data[7]['view'] !== undefined && response.data.data[7]['view'] === '1' ){
						navigate("/customer-listing");
						return false;
					}
					if(response.data.data[8]['view'] !== undefined && response.data.data[8]['view'] === '1' ){
						navigate("/loan-listing");
						return false;
					}
					if(response.data.data[9]['view'] !== undefined && response.data.data[9]['view'] === '1' ){
						navigate("/assets-listing");
						return false;
					}
					if(response.data.data[10]['view'] !== undefined && response.data.data[10]['view'] === '1' ){
						navigate("/financial-summary");
						return false;
					}
					if(response.data.data[12]['view'] !== undefined && response.data.data[12]['view'] === '1' ){
						navigate("/transactions");
						return false;
					}
				}
				// navigate("/sales-listing");
				navigate("/financial-summary");
				return false;
			}
			if( response.data.action !== undefined && response.data.action === 'session'){
				Common.destroySession();
				navigate("/login");
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));

		/*if( userPerm && Object.keys(userPerm).length > 0 ){
			//
			//Read
			setaccountMgmt_r((userPerm[1]['view'] !== undefined && userPerm[1]['view'] === '0') ? false : true);
			setincomeColl_r((userPerm[2]['view'] !== undefined && userPerm[2]['view'] === '0') ? false : true);
			setexpensePay_r((userPerm[3]['view'] !== undefined && userPerm[3]['view'] === '0') ? false : true);
			setinventoryMgmt_r((userPerm[4]['view'] !== undefined && userPerm[4]['view'] === '0') ? false : true);
			setuserMgmt_r((userPerm[5]['view'] !== undefined && userPerm[5]['view'] === '0') ? false : true);
			setsubscription_r((userPerm[6]['view'] !== undefined && userPerm[6]['view'] === '0') ? false : true);
		}*/
	},[props,navigate]);
}