const supply_types = [
  {
    label: 'Business to Customer (B2C)',
    value: 'B2C'
  },
  {
    label: 'Business to Business (B2B)',
    value: 'B2B'
  },
  {
    label: 'Business to Business Amended (B2BA)',
    value: 'B2BA'
  },
  {
    label: 'Credit Debit Notes (CDN)',
    value: 'CDN'
  },
  {
    label: 'Credit Debit Notes Amended (CDNA)',
    value: 'CDNA'
  },
  {
    label: 'Import of Goods (IMPG)',
    value: 'IMPG'
  },
  {
    label: 'Imports from SEZ (IMPGSEZ)',
    value: 'IMPGSEZ'
  },
  {
    label: 'Tax Collected at Source (TCS)',
    value: 'TCS'
  },
  {
    label: 'Tax Deducted at Source (TDS)',
    value: 'TDS'
  }
];
export default supply_types;