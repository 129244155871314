import React  from "react";
import Layout from './PartnerLayout';
import NavBar from './PartnerNavBar';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert';
import ImageUploading from 'react-images-uploading';

export default function PartnerDetails(props){
	const [DTpending, setDTPending]                     = React.useState(true);
	const [dt_load,setDtLoad]                           = React.useState(true);
	const [sortPartner,setSortPartner]                  = React.useState('');
	const [filterText, setFilterText]                   = React.useState('');
	const [totalDataCnt,setTotalDataCount]              = React.useState(0);
	const [partners, setPartnerList]                    = React.useState([]);
	const [totalRows, setTotalRows]                     = React.useState(0);
	const [perPage, setPerPage]                         = React.useState(10);
    const [partnerItemDocument,setPartnerItemDocument]  = React.useState('');
    const [uploadPartnerModal, setUploadPartnerModal]   = React.useState(false);
	const [uploadType,setUploadType]                    = React.useState('');
	const [partnerId,setPartnerId]                      = React.useState('');
	const [accountType,setAccountType]                  = React.useState('');
	const [userId,setUserId]                            = React.useState('');
	const [addProfileModelShow,setAddProfileModelShow]  = React.useState(false);
    let maxLenName                                      = 30;
	const [account_name, setAccountName] 	            = React.useState('');
    const [nameCharCntDiv, setNameCharCntDiv]           = React.useState(false);
    const [nameCharCnt, setNameCharCnt] 	            = React.useState('0');
	const [state, setState]             	            = React.useState('');
	const [district, setDistrict]        	            = React.useState('');
	const [pin, setPin]                  	            = React.useState('');
	const [images, setImages] 			 	            = React.useState('');
	const [currentPage, setCurrentPage]                 = React.useState(1);

    React.useEffect(() => {
        document.title = 'Partners | '+props.site_name;
        Common.axios({
			method: 'get',
			url: props.url+'partners/getPartner',
		}).then(function (response) {
			if( response.data.success ){
                if(response.data.data){
                    let account_type = response.data.data.type;
                    localStorage.setItem('fo_partnerType',account_type);
                    setAccountType(account_type);
                    loadDataTable(1,perPage,filterText,sortPartner,response.data.data.type,account_type);
                    return false;
                }else{
                    window.location.href = '/landing-partner';
                    return false;
                }
			}else{
                Common.toast.error(response.data.message);
            }
		}).catch(error => console.log(error));
	}, [props,perPage,dt_load,filterText]);

	const loadDataTable = (page,perPage,filterText,sort='',account_type) => {
		Common.axios({
            method: 'post',
            url:props.url+'partners/listing',
			data: {'page':page,'perPage':perPage,'sort':sort,'search':filterText,'account_type':account_type}
        }).then(function (response) {
            if(response.data.success){
				setPartnerList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				return false;
            }else{
                Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
	}
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
        setCurrentPage(page);
		loadDataTable(page,perPage,filterText,sortPartner,accountType);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortPartner(btoa(column.sortField+'####'+sortDirection));
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection),accountType);
	};
	const columns = [
        {
            name: 'Partner Name',
            selector: row => row.name,
            sortable: true,
			sortField: 'name'
        },
        {
            name: 'Partner Phone',
            selector: row => row.mobile,
            sortable: true,
			sortField: 'mobile'
        },
        {
            name: 'Partner email',
            selector: row => row.email,
            sortable: true,
			sortField: 'email'
        },
        {
            name: 'Account Name',
            selector: row => row.profile_name,
            sortable: false,
			sortField: 'profile_name',
            cell: (row) => (
                <div>
                        {(row.profile_logo || row.profile_name)?(row.profile_logo)?<span><img src = {props.url+"public/assets/img/profile_logo/"+row.profile_logo} alt="user-avatar"  className="d-block rounded" height="30" width="30" />{row.profile_name}</span>:row.profile_name:(accountType === "partner")?<Button variant="primary" size="sm" onClick={(e)=>addProfileModal(row.id)} data-bs-toggle="tooltip" data-bs-placement="top" title="Add Profile">Add</Button>:''}
                </div>
			)
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
			sortField: 'type',
            cell: (row) => (
                (row.type)?row.type[0].toUpperCase() + row.type.slice(1):''
			)
        },
        {
            name: 'Distributor',
            selector: row => row.distributor_name,
            sortable: true,
			sortField: 'distributor_name',
            cell: (row) => (
                (row.type !== "distributor")?row.distributor_name:''
			)
        },
        {
            name: 'Partner Code',
            selector: row => row.ref_code,
            sortable: true,
			sortField: 'ref_code'
        },
        {
            name: 'Document',
            selector: row => row.filepath,
            sortable: false,
			sortField: 'filepath',
			cell: (row) => (
				(accountType === 'admin')?(row.filepath !== null ? <><button type="button"  style={{"width": "17rem"}} onClick={(e) => handlePartnerDocDownload(row.filepath,row.partner_id,1)} className="btn btn-sm btn-primary">Download</button> <i className='bx bx-trash' onClick={(e) => handlePartnerDocDelete(row.filepath,row.partner_id,1)}></i></> : <button type="button" onClick={(e) => showUploadPartnerDocModal(true, row.partner_id,1)} className="btn btn-sm btn-info" >Upload</button>):(row.filepath !== null ? <button type="button"  style={{"width": "17rem"}} onClick={(e) => handlePartnerDocDownload(row.filepath,row.partner_id,1)} className="btn btn-sm btn-primary">Download</button>:'')
			)
        },
        {
            name: 'Status',
            selector: row => row.partner_status,
            sortable: false,
			sortField: 'partner_status',
			cell: (row) => (
				(row.partner_status === "1")?'Active':'Inactive'
			)
        },
	];
    const addProfileModal = (id) => {
        setUserId(id);
        setAddProfileModelShow(true);
    }
    const handlePartnerDocDownload = (filePath) => {
        Common.showLoader(true);
        Common.axios({
            method: 'get',
            url:props.url+'partner/partnerDocumentDownload/'+encodeURIComponent(btoa(filePath)),
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				Common.toast.dismiss();
                const url = props.url+response.data.url;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.data.file_name);
                document.body.appendChild(link);
                link.click();
                return false;
			} 
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    };
    const handlePartnerDocDelete = (filepath, id, type) => {
        confirmAlert({
            title: 'Delete Document',
            message: 'Are you sure you want to delete?',
            buttons: [{
                    label: 'Yes',
                    onClick: () => {
                        Common.showLoader(true);
                        Common.axios({
                            method: 'post',
                            url:props.url+'partners/deletePartnerDocument',
                            data: {
                                filepath:filepath,
                                partnerId:id
                            }
                        }).then(function (response) {
                            Common.showLoader(false);
                            if(response.data.success){
								loadDataTable(currentPage,perPage,filterText,sortPartner,accountType);
                                Common.toast.success(response.data.message);
                            } 
                            Common.toast.dismiss();
                        }).catch(error => console.log(error));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log('no')
                    }
                }
            ]
        });
    }
    const showUploadPartnerDocModal = (flag, id, type) => {
        setPartnerId(id);
        setUploadType(type);
        if (flag === false)
            setUploadPartnerModal(false);
        else
            setUploadPartnerModal(true);
    }
    const uploadPartnerDocument = (e) => {
        let fileData = new FormData();
        fileData.append('files', partnerItemDocument);
        fileData.append('partnerId', partnerId);
        fileData.append('uploadType', uploadType);
        Common.axios.post(props.url+'partners/uploadPartnerFile', fileData, {
            mode: 'cors',
            headers: { 'Content-Type':  'multipart/form-data' }
        }).then(function (response) {
            setUploadPartnerModal(false);
			loadDataTable(currentPage,perPage,filterText,sortPartner,accountType);
        }).catch(error => console.log(error));
    }
    const handlePartnerDocument = (e) => {
        const fileTypes = ['application/pdf', 'image/jpeg', 'image/jpg','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/png', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (e.target.files[0] !== undefined) {
            if (!fileTypes.includes(e.target.files[0].type)) {
                Common.toast.error('File format not supported');
                e.target.value = null;
                return false;
            } else {
                if (e.target.files[0].size > (5 * 1048576)) {
                    Common.toast.error('File size should be less than 5MB');
                    e.target.value = null;
                    return false;
                } else {
                    setPartnerItemDocument(e.target.files[0]);
                }
            }
        }
        Common.toast.dismiss();
    };
    const setAccountNamePlus = (val) => {
		let name = Common.acceptOnlyCharsNumbers(val);
		setAccountName(name);
		setNameCharCnt(name.length);
	}
    const handleKeyDown =(val,e) => {
		e.preventDefault();
		let pincode= Common.acceptOnlyNumbers(e.target.value);
		setPin(pincode);
		if( pincode.length === 6 ){
			populatePinData(pincode);
		}
    }
	const populatePinData = (pincode) => {
		Common.axios({
			method: 'post',
			url:props.url+'ajax/getDataByPin',
			data: {pincode:pincode},
		}).then(function (response) {
			if(response.data.success){
				setState(response.data.stateid);
				setDistrict(response.data.district);
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
    const onChange = (imageList) => {
		setImages(imageList);
	};

    let imageUrl = "../assets/img/default-user-img.jpg";

    const addPartnerProfile =(e) => {
		e.preventDefault();
		let data = {};
		data['pid'] = localStorage.getItem('fo_profileid');
		data['account_name'] = account_name;
		data['pin'] = pin;
		data['state'] = state;
		data['district'] = district;
		data['type'] = 'Business';
		data['id'] = userId;
		data['logo'] = images;
		data['account_type'] = 'partners';
		data['address'] = '';
		data['profile_master_id'] = 0;
		data['legal_name'] = '';
		data['trade_name'] = '';
		data['gstin'] = '';
		data['address_type'] = '0';
		data['bank_name'] = '';
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'user/create_profile',
			data: data,
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
                setAddProfileModelShow(false);
                loadDataTable(currentPage,perPage,filterText,sortPartner,accountType);
                return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
    return (
	<>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="partners" {...props}/>
                <div className="layout-page">
                    <NavBar {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3">Partners</h4>
							<div className="row">
								<div className="col-md-4 col-12 mb-1 text-center text-md-start">
                                    { localStorage.getItem('fo_partnerType') === "distributor" && (<>
                                        <a href="/add-partner" className="btn btn-sm btn-primary">
                                            <i className='bx bx-plus'></i> <span className="hidden-phone">Partner</span>
                                        </a>
                                    </>)}
                                </div>
								<div className="col-md-4 col-12 text-center mb-1" >
								</div>
								<div className="col-md-4 col-12 text-center text-md-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Partner Name, Partner Mobile, Distributor')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
                            <div className="row">
                                <div className="col-md-12">
                                    <DTE.DataTable className="dataTables_wrapper rdt_AdjDrp" title={"Partners ("+totalDataCnt+")"} columns={columns} data={partners} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} persistTableHead/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.PartnerNeedHelp url={props.url}/>
                </div>
            </div>
        </div>
        <Modal show={uploadPartnerModal} size='sm' onHide={()=>showUploadPartnerDocModal(false, partnerId)} aria-labelledby="Partner-File_Upload-Modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Select File to upload</Modal.Title>
			</Modal.Header>
			<Modal.Body>
                <div className="row">
					<div className="col-sm-12">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Default file input example</label>
                            <input className="form-control" type="file" name="formFile" id="formFile" onChange={handlePartnerDocument} />
                        </div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={(e) => uploadPartnerDocument(e)}>
				Upload
			</Button>
			<Button variant="danger" onClick={()=>showUploadPartnerDocModal(false,partnerId)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
        <Modal show={addProfileModelShow} size='md' onHide={()=>setAddProfileModelShow(false)} aria-labelledby="Add-Profile-Modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Add Profile</Modal.Title>
			</Modal.Header>
			<Modal.Body>
            <div className="row">
					<div className="col-sm-12">
                        <form id="bussiness_account" className="account_type_form mt-3" autoComplete="off">
                            <div className="button-wrapper">
                                <div className="App">
                                    <ImageUploading
                                        value={images}
                                        onChange={onChange}
                                        dataURLKey="data_url"
                                        acceptType={["jpg","png"]}
                                        maxFileSize="51200"
                                    >
                                        {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemove,
                                        errors,
                                        }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div key={0} className="image-item">
                                                        <img
                                                            //src={image['data_url']}
                                                            src = {imageList.length > 0 ? imageList[0]['data_url'] : imageUrl}
                                                            alt="user-avatar"
                                                            className="d-block rounded"
                                                            height="100"
                                                            width="100"
                                                            id="uploadedAvatar"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="button-wrapper mt-4">
                                                        <label htmlFor="upload" className="btn btn-sm btn-primary me-2 mb-2" tabIndex="0" onClick={onImageUpload}>
                                                            <span className="d-none d-sm-block">Upload Logo</span>
                                                            <i className="bx bx-upload d-block d-sm-none"></i>
                                                        </label>
                                                        <button type="button" className="btn btn-sm btn-outline-secondary account-image-reset mb-2" onClick={() => onImageRemove(0)}>
                                                            <i className="bx bx-reset d-block d-sm-none"></i>
                                                            <span className="d-none d-sm-block">Reset</span>
                                                        </button>
                                                        <p className="text-muted mb-0 smaller_font">Allowed JPG or PNG. Max size of 50KB</p>
                                                    </div>
                                                </div>
                                                {errors && (
                                                    <div className="col-sm-12">
                                                        {errors.acceptType && (
                                                        <span className="text-danger smaller_font">* Your selected file type is not allow</span>
                                                        )}
                                                        {errors.maxFileSize && (
                                                        <span className="text-danger smaller_font">* Selected file size exceed maxFileSize</span>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        )}
                                    </ImageUploading>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="business-name">Account Name<span className="text-danger">*</span></label>
                                <input autoFocus maxLength="30" id="business-name" type="text" className="form-control" name="account_name" placeholder="Account Name" aria-label="Account Name" required value={account_name} 
                                    onFocus={(e) => setNameCharCntDiv(true)}
                                    onBlur={(e) => setNameCharCntDiv(false)}
                                    onChange={(e) => setAccountNamePlus(e.target.value)}
                                />
                                { nameCharCntDiv && <><span className="input_char_count">{nameCharCnt}/{maxLenName}</span></>}
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="business-pin">Pin Code<span className="text-danger">*</span></label>
                                <input id="business-pin" type="text" name="pin" className="form-control" placeholder="Pin Code" aria-label="Pin Code" maxLength="6" inputMode="numeric" value={pin} onChange={(e) => handleKeyDown(e.target.value,e)}/>
                            </div>
                            <div className="form-text text-center">One year PRO subscription to FynOne will be automatically added to this account.</div>
                        </form>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={(e) => addPartnerProfile(e)}>
				Submit
			</Button>
			<Button variant="danger" onClick={()=>setAddProfileModelShow(false,partnerId)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
	</>
    );
}