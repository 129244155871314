import React,{useState, useRef}  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import 'react-date-picker/dist/DatePicker.css';
import * as Common from '../common/ImportUserEssentials';
import Modal from 'react-bootstrap/Modal';
import * as DTE from '../common/ImportDataTableEssentials';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default function FinancialSummary(props) {
    const [grossRevenue, setGrossRevenue]           = useState('0.00');
    const [netRevenue, setNetRevenue]               = useState('0.00');
    const [totalExpenses, setTotalExpenses]         = useState('0.00');
    const [ebitda, setEbitda]                       = useState('0.00');
    const [ebitdaPrecentage, setEbitdaPrecentage]   = useState('0.00');
    const [collectionAmt, setCollectionAmt]         = useState('0.00');
    const [paymentAmt, setPaymentAmt]               = useState('0.00');
    const [netCashflowamt, setNetCashflowamt]       = useState('0.00');
    // const [openingBaltatus, setOpeningBaltatus]     = useState(0);
    // const [openingBalanceAmt, setOpeningBalanceAmt] = useState('0.00');
    const [closingBalanceAmt, setClosingBalanceAmt] = useState('0.00');
    const [otherIncomeAmt, setOtherIncomeAmt]       = useState('0.00');
    const [otherExpensesAmt, setOtherExpensesAmt]   = useState('0.00');
    const [totCollectionAmt, setTotCollectionAmt]   = useState('0.00');
    const [totPaymentAmt, setTotPaymentAmt]         = useState('0.00');
    const [loanTakenAmt, setLoanTakenAmt]           = useState('0.00');
    // const [totalLoanAmt, setTotLoanAmt]             = useState('0.00');
    // const [totalRepaymentAmt, setTotRepaymentAmt]   = useState('0.00');
    // const [totalDueAmt, setTotDueAmt]               = useState('0.00');
    const [loanGivenAmt, setLoanGivenAmt]           = useState('0.00');
    // const [totalLoanGivenAmt, setTotLoanGivenAmt]   = useState('0.00');
    // const [totalRepayGivenAmt, setTotRepayGivenAmt] = useState('0.00');
    // const [totalDueGivenAmt, setTotDueGivenAmt]     = useState('0.00');
    const [totalInvtsAmt, setTotInvtsAmt]           = useState('0.00');
    const [closingBal, setClosingBal]               = useState([]);
    const [loanTakenDataProviderWise, setLoanTakenDataProviderWise]   = useState([]);
    const [loanGivenDataReceipentWise, setLoanGivenDataReceipentWise] = useState([]);
    const [investmentDataShareHolderWise, setInvestmentDataShareHolderWise] = useState([]);
    const [pendingCollectionAmt, setPendingCollectionAmt] = useState('0.00');
    const [pendingPayablesAmt, setPendingPayablesAmt]     = useState('0.00');
    const [closeBalanceModal, setCloseBalanceModal] = useState(false);
    const [loanTakenModal, setLoanTakenModal] = useState(false);
    const [loanGivenModal, setLoanGivenModal] = useState(false);
    const [investmentModal, setInvestmentModal] = useState(false);


    const [YTDSummary, setYTDSummary]                     = useState('0');
    const [YTDgrossRevenue, setYTDGrossRevenue]           = useState('0.00');
    const [YTDnetRevenue, setYTDNetRevenue]               = useState('0.00');
    const [YTDtotalExpenses, setYTDTotalExpenses]         = useState('0.00');
    const [YTDebitda, setYTDEbitda]                       = useState('0.00');
    const [YTDebitdaPrecentage, setYTDEbitdaPrecentage]   = useState('0.00');
    const [YTDcollectionAmt, setYTDCollectionAmt]         = useState('0.00');
    const [YTDpaymentAmt, setYTDPaymentAmt]               = useState('0.00');
    const [YTDnetCashflowamt, setYTDNetCashflowamt]       = useState('0.00');
    // const [YTDopeningBaltatus, setYTDOpeningBaltatus]     = useState(0);
    // const [YTDopeningBalanceAmt, setYTDOpeningBalanceAmt] = useState('0.00');
    // const [YTDclosingBalanceAmt, setYTDClosingBalanceAmt] = useState('0.00');
    const [YTDotherIncomeAmt, setYTDOtherIncomeAmt]       = useState('0.00');
    const [YTDotherExpensesAmt, setYTDOtherExpensesAmt]   = useState('0.00');
    const [YTDtotCollectionAmt, setYTDTotCollectionAmt]   = useState('0.00');
    const [YTDtotPaymentAmt, setYTDTotPaymentAmt]         = useState('0.00');
    const [YTDloanTakenAmt, setYTDLoanTakenAmt]           = useState('0.00');
    // const [YTDtotalLoanAmt, setYTDTotLoanAmt]             = useState('0.00');
    // const [YTDtotalRepaymentAmt, setYTDTotRepaymentAmt]   = useState('0.00');
    // const [YTDtotalDueAmt, setYTDTotDueAmt]               = useState('0.00');
    const [YTDloanGivenAmt, setYTDLoanGivenAmt]           = useState('0.00');
    // const [YTDtotalLoanGivenAmt, setYTDTotLoanGivenAmt]   = useState('0.00');
    // const [YTDtotalRepayGivenAmt, setYTDTotRepayGivenAmt] = useState('0.00');
    // const [YTDtotalDueGivenAmt, setYTDTotDueGivenAmt]     = useState('0.00');
    const [YTDtotalInvtsAmt, setYTDTotInvtsAmt]           = useState('0.00');
    const [YTDClosingBal, setYTDClosingBal]               = useState([]);
    const [YTDloanTakenDataProviderWise, setYTDloanTakenDataProviderWise]   = useState([]);
    // const [YTDloanGivenDataReceipentWise, setYTDloanGivenDataReceipentWise] = useState([]);
    const [YTDinvestmentDataShareHolderWise, setYTDinvestmentDataShareHolderWise] = useState([]);
    // const [YTDpendingCollectionAmt, setYTDpendingCollectionAmt] = useState('0.00');
    // const [YTDpendingPayablesAmt, setYTDpendingPayablesAmt] = useState('0.00');
    const [YTDcloseBalanceModal, setYTDCloseBalanceModal] = useState(false);
    const [YTDloanTakenModal, setYTDloanTakenModal] = useState(false);
    const [YTDloanGivenModal, setYTDloanGivenModal] = useState(false);
    const [YTDinvestmentModal, setYTDinvestmentModal] = useState(false);
    const [YTDLabel, setYTDLabel] = useState('YTD Summary');
    const tableRef = useRef(null);

    const [viewType, setViewType] = useState('1');

    const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
    const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
    
    const [YTDdates, setYTDDates] = React.useState({
		YTDstart: moment().startOf('year').add(3, 'months'),
		YTDend: moment().endOf('year').subtract(9, 'months').add(1, 'years'),
    });
    const { YTDstart, YTDend } = YTDdates;
	const YTDlabel = YTDstart.format('DD-MM-YYYY') + ' ~ ' + YTDend.format('DD-MM-YYYY');
    React.useEffect(() => {
        document.title = 'Financial Metrics | '+props.site_name;
        Common.axios({
            method: 'post',
            url:props.url+'financial-metrics/financial-summary/',
            data: {'pid':Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),'dates':dates,'YTDdates':YTDdates,'YTDSummary':YTDSummary},
        }).then(function (response) {
			if(response.data.success){
                let gross_revenue = Number(response.data.data.summaryData.total_amt) + Number(response.data.data.summaryOtherIncomeData.total_amt);
                let gst_amt = Number(response.data.data.summaryData.gst_amt) + Number(response.data.data.summaryOtherIncomeData.gst_amt);
                let net_revenue = gross_revenue - gst_amt;
                let total_expenses = Number(response.data.data.expensesSummaryData.total_taxable_amt) + Number(response.data.data.otherExpensesSummaryData.total_taxable_amt);
                let ebitda = net_revenue - total_expenses;
                setGrossRevenue(gross_revenue);
                setNetRevenue(net_revenue);
                setTotalExpenses(total_expenses);
                setEbitda(ebitda);
                let ebitda_pre = net_revenue > 0 ? ((ebitda/net_revenue) * 100):'-';
                setEbitdaPrecentage(ebitda_pre);

                setCollectionAmt(response.data.data.collectionSummaryData.credit_amount);
                setOtherIncomeAmt(response.data.data.otherIncomeData.total_amt);

                setPaymentAmt(response.data.data.paymentSummaryData.credit_amount);
                setOtherExpensesAmt(response.data.data.otherExpensesData.total_amt);
                let totoal_collection = Number(response.data.data.collectionSummaryData.credit_amount) + Number(response.data.data.otherIncomeData.total_amt);
                setTotCollectionAmt(totoal_collection);
                let totoal_payment = Number(response.data.data.paymentSummaryData.credit_amount) + Number(response.data.data.otherExpensesData.total_amt);
                setTotPaymentAmt(totoal_payment);
                let net_cash_flow = (totoal_collection + Number(response.data.data.loanTakenData.loan_amt) + Number(response.data.data.investmentData.total_amt)) - (totoal_payment + Number(response.data.data.loanGivenData.loan_amt));
                setNetCashflowamt(net_cash_flow);
                // setOpeningBalanceAmt(response.data.data.bankOpeningBalance);
                setClosingBalanceAmt(response.data.data.bankClosingBalance);
                // setOpeningBaltatus(response.data.data.opening_balance_status);
                setLoanTakenAmt(response.data.data.loanTakenData.loan_amt);
                // setTotLoanAmt(response.data.data.loanTakenData.total_amt);
                // setTotRepaymentAmt(response.data.data.loanTakenData.payment);
                // setTotDueAmt(response.data.data.loanTakenData.due);
                setLoanGivenAmt(response.data.data.loanGivenData.loan_amt);
                // setTotLoanGivenAmt(response.data.data.loanGivenData.total_amt);
                // setTotRepayGivenAmt(response.data.data.loanGivenData.payment);
                // setTotDueGivenAmt(response.data.data.loanGivenData.due);
                setTotInvtsAmt(response.data.data.investmentData.total_amt);
                let list = response.data.data.closingBalance.filter((row) => {
                    return row.closing_balance !== 0;
                });
                setClosingBal(list);
                setPendingCollectionAmt(response.data.data.pendingCollectionAmt.pending_collection_amt);
                setPendingPayablesAmt(response.data.data.pendingPayablesAmt.pending_payables_amt);

                setLoanTakenDataProviderWise(response.data.data.loanTakenDataProviderWise);
                setLoanGivenDataReceipentWise(response.data.data.loanGivenDataReceipentWise);
                setInvestmentDataShareHolderWise(response.data.data.investmentDataShareHolderWise)

                if(YTDSummary === '0' || YTDSummary === '1'){
                    let gross_revenue = Number(response.data.data.YTDsummaryData.total_amt) + Number(response.data.data.YTDsummaryOtherIncomeData.total_amt);
                    let gst_amt = Number(response.data.data.YTDsummaryData.gst_amt) + Number(response.data.data.YTDsummaryOtherIncomeData.gst_amt);
                    let net_revenue = gross_revenue - gst_amt;
                    let total_expenses = Number(response.data.data.YTDexpensesSummaryData.total_taxable_amt) + Number(response.data.data.YTDotherExpensesSummaryData.total_taxable_amt);
                    let ebitda = net_revenue - total_expenses;
                    setYTDGrossRevenue(gross_revenue);
                    setYTDNetRevenue(net_revenue);
                    setYTDTotalExpenses(total_expenses);
                    setYTDEbitda(ebitda);
                    let ebitda_pre = net_revenue > 0 ? ((ebitda/net_revenue) * 100):'-';
                    setYTDEbitdaPrecentage(ebitda_pre);

                    setYTDCollectionAmt(response.data.data.YTDcollectionSummaryData.credit_amount);
                    setYTDOtherIncomeAmt(response.data.data.YTDotherIncomeData.total_amt);

                    setYTDPaymentAmt(response.data.data.YTDpaymentSummaryData.credit_amount);
                    setYTDOtherExpensesAmt(response.data.data.YTDotherExpensesData.total_amt);
                    let totoal_collection = Number(response.data.data.YTDcollectionSummaryData.credit_amount) + Number(response.data.data.YTDotherIncomeData.total_amt);
                    setYTDTotCollectionAmt(totoal_collection);
                    let totoal_payment = Number(response.data.data.YTDpaymentSummaryData.credit_amount) + Number(response.data.data.YTDotherExpensesData.total_amt);
                    setYTDTotPaymentAmt(totoal_payment);
                    let net_cash_flow = (totoal_collection + Number(response.data.data.YTDloanTakenData.loan_amt) + Number(response.data.data.YTDinvestmentData.total_amt)) - (totoal_payment + Number(response.data.data.YTDloanGivenData.loan_amt));
                    setYTDNetCashflowamt(net_cash_flow);
                    // setYTDOpeningBaltatus(response.data.data.YTDopeningBalanceStatus);
                    // setYTDOpeningBalanceAmt(response.data.data.YTDbankOpeningBalance);
                    // setYTDClosingBalanceAmt(response.data.data.YTDbankClosingBalance);

                    setYTDLoanTakenAmt(response.data.data.YTDloanTakenData.loan_amt);
                    // setYTDTotLoanAmt(response.data.data.YTDloanTakenData.total_amt);
                    // setYTDTotRepaymentAmt(response.data.data.YTDloanTakenData.payment);
                    // setYTDTotDueAmt(response.data.data.YTDloanTakenData.due);
                    setYTDLoanGivenAmt(response.data.data.YTDloanGivenData.loan_amt);
                    // setYTDTotLoanGivenAmt(response.data.data.YTDloanGivenData.total_amt);
                    // setYTDTotRepayGivenAmt(response.data.data.YTDloanGivenData.payment);
                    // setYTDTotDueGivenAmt(response.data.data.YTDloanGivenData.due);
                    setYTDTotInvtsAmt(response.data.data.YTDinvestmentData.total_amt);
                    let ytd_list = response.data.data.YTDclosingBalance.filter((row) => {
                        return row.closing_balance !== 0;
                    });
                    setYTDClosingBal(ytd_list);
                    // setYTDpendingCollectionAmt(response.data.data.YTDpendingCollectionAmt.pending_collection_amt);
                    // setYTDpendingPayablesAmt(response.data.data.YTDpendingPayablesAmt.pending_payables_amt);

                    setYTDloanTakenDataProviderWise(response.data.data.YTDloanTakenDataProviderWise);
                    // setYTDloanGivenDataReceipentWise(response.data.data.YTDloanGivenDataReceipentWise);
                    setYTDinvestmentDataShareHolderWise(response.data.data.YTDinvestmentDataShareHolderWise)
                }
				return false;
			}
        }).catch(error => console.log(error));
	}, [dates, YTDdates]);
    const setDatePicker = (start, end) => {
        setDates({ start, end });
        setYTDSummary('1');
    }
    const YTDsetDatePicker = (YTDstart, YTDend) => {
        setYTDDates({ YTDstart, YTDend });
        setYTDSummary('1');
        setYTDLabel(YTDstart.format('DD-MM-YYYY') + ' ~ ' + YTDend.format('DD-MM-YYYY'))
    }
    const copyBtnClickCallback = function(e) {
        const code = tableRef.current.innerHTML;
        navigator.clipboard.writeText(code);
        Common.toast.success('Copied');
        e.target.focus();

    };
    const handleIndividualClosingBalance = () => {
        setCloseBalanceModal(true);
    }
    // const handleLoanTaken = () => {
    //     setLoanTakenModal(true)
    // }
    // const handleLoanGiven = () => {
    //     setLoanGivenModal(true)
    // }
    // const handleInvestments = () => {
    //     setInvestmentModal(true)
    // }
    // const handleYTDIndividualClosingBalance = () => {
    //     setYTDCloseBalanceModal(true);
    // }
    // const handleYTDLoanTaken = () => {
    //     setYTDloanTakenModal(true)
    // }
    // const handleYTDLoanGiven = () => {
    //     setYTDloanGivenModal(true)
    // }
    // const handleYTDInvestments = () => {
    //     setYTDinvestmentModal(true)
    // }
    return(
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Layout site_logo={props.site_logo} label="financial-summary" site={props.site_name}/>
                    <div className="layout-page">
                        <NavBar site={props.site_name} url={props.url}/>
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Financial Metrics /</span> Summary</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <div className="row mb-3">
                                                    <div className="col-md-4 col-lg-4 col-12  mb-3">
                                                        <h5>Summary</h5>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 col-12 mb-3">
                                                        <select id="view_type" className="form-select" name='view_type' value={viewType} onChange={(e) => setViewType(e.target.value)} required style={{'padding': '0.29rem'}}>
                                                            <option value="1">Comparative View</option>
                                                            <option value="2">Standalone View</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 text-center text-md-start mb-1">
                                                        <button id="copy" onClick={copyBtnClickCallback} className="btn btn-sm btn-info mb-2"><i className='bx bx-copy'></i> <span className="hidden-phone">Copy Data</span></button>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className={viewType === '2' ? "col-md-4 offset-md-4" : "col-md-4 offset-md-1"}>
                                                        <DateRangePicker
                                                        initialSettings={DTE.DatePickerPredefined(moment,start,end)}
                                                        onCallback={setDatePicker}>
                                                            <div className="input-group input-group-sm">
                                                                <span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
                                                                <div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
                                                                    <i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
                                                                </div>
                                                            </div>
                                                        </DateRangePicker>
                                                        <small style={{'marginLeft' : '2.2rem'}}>Date range based on Transaction date</small>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                    {viewType === '1' ? <>
                                                        <div className="col-md-4 " id="mobile-ytd-hide">
                                                            <DateRangePicker
                                                            initialSettings={DTE.DatePickerPredefined(moment,YTDstart,YTDend)}
                                                            onCallback={YTDsetDatePicker}>
                                                                <div className="input-group input-group-sm">
                                                                    <span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
                                                                    <div id="ytd-reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
                                                                        <i className="fa fa-calendar"></i>&nbsp;<span>{YTDlabel}</span> <i className="fa fa-caret-down"></i>
                                                                    </div>
                                                                </div>
                                                            </DateRangePicker>
                                                            <small style={{'marginLeft' : '2.2rem'}}>Date range based on Transaction date</small>
                                                        </div>
                                                    </> :<></> }
                                                </div>
                                                {viewType === '1' ? <>
                                                    <div className="row" ref={tableRef}>
                                                        <div className="col-md-6 col-lg-6 col-12 mb-3 border-end">
                                                            <div className="text-center my-4">
                                                                <h5>{label}</h5>
                                                            </div>
                                                            <div className="table-responsive mb-3" >
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width='50%'>Gross Revenue(₹) <span><Common.OverlayTrigger overlay={Common.popoverContent('','Add Sale')}><Common.Link to="/add-sale" className="btn btn-sm btn-primary text-white p-0"> <i className='bx bx-plus'></i></Common.Link></Common.OverlayTrigger></span></th>
                                                                            <th width='50%'>Net Revenue(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(grossRevenue).toFixed(2)}</td>
                                                                            <td>{Number(netRevenue).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <thead>
                                                                        <tr>
                                                                            <th width='50%'>Operating Expenses(₹) <span><Common.OverlayTrigger overlay={Common.popoverContent('','Add Expense')}><Common.Link to="/add-expense" className="btn btn-sm btn-primary text-white p-0"> <i className='bx bx-plus'></i></Common.Link></Common.OverlayTrigger></span></th>
                                                                            <th width='50%'>EBITDA/Operating Profit(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(totalExpenses).toFixed(2)}</td>
                                                                            <td>{Number(ebitda).toFixed(2)+' ('+((ebitdaPrecentage !=='-')?Number(ebitdaPrecentage).toFixed(2):ebitdaPrecentage)+'%)'}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="table-responsive mb-3">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Collections(₹)</th>
                                                                            <th>Other Income(₹)</th>
                                                                            <th>Total(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(collectionAmt).toFixed(2)}</td>
                                                                            <td>{Number(otherIncomeAmt).toFixed(2)}</td>
                                                                            <td>{Number(totCollectionAmt).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Payments(₹)</th>
                                                                            <th>Other Expenses(₹)</th>
                                                                            <th>Total(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(paymentAmt).toFixed(2)}</td>
                                                                            <td>{Number(otherExpensesAmt).toFixed(2)}</td>
                                                                            <td>{Number(totPaymentAmt).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    {loanTakenAmt > 0 && loanGivenAmt > 0 && totalInvtsAmt > 0 ? <>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{loanTakenAmt > 0 ? <>Loan Taken Amount(₹)</> : <></>}</th>
                                                                                <th>{loanGivenAmt > 0 ? <>Loan Given Amount(₹)</> : <></>}</th>
                                                                                <th>{totalInvtsAmt > 0 ? <>Investment Amount(₹)</> : <></>}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{loanTakenAmt > 0 ? <>{Number(loanTakenAmt).toFixed(2)}</> : <></>}</td>
                                                                                <td>{loanGivenAmt > 0 ? <>{Number(loanGivenAmt).toFixed(2)}</> : <></>}</td>
                                                                                <td>{totalInvtsAmt > 0 ? <>{Number(totalInvtsAmt).toFixed(2)}</> : <></>}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </> : <>
                                                                        {loanTakenAmt > 0 || loanGivenAmt > 0 || totalInvtsAmt > 0 ? <>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>{loanTakenAmt > 0 ? <>Loan Taken Amount(₹)</> : <></>}</th>
                                                                                    <th>{loanGivenAmt > 0 ? <>Loan Given Amount(₹)</> : <></>}</th>
                                                                                    <th>{totalInvtsAmt > 0 ? <>Investment Amount(₹)</> : <></>}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{loanTakenAmt > 0 ? <>{Number(loanTakenAmt).toFixed(2)}</> : <></>}</td>
                                                                                    <td>{loanGivenAmt > 0 ? <>{Number(loanGivenAmt).toFixed(2)}</> : <></>}</td>
                                                                                    <td>{totalInvtsAmt > 0 ? <>{Number(totalInvtsAmt).toFixed(2)}</> : <></>}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </> : <></>}
                                                                    </>}
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-center" colSpan="3">Net cashflow(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="text-center" colSpan="3">{Number(netCashflowamt).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div id="mobile-ytd-show" className="row" style={{'display' : 'none'}}> 
                                                            {/* {viewType === '1' ? <> */}
                                                            <div className="col-md-4 ">
                                                                <DateRangePicker
                                                                initialSettings={DTE.DatePickerPredefined(moment,YTDstart,YTDend)}
                                                                onCallback={YTDsetDatePicker}>
                                                                    <div className="input-group input-group-sm">
                                                                        <span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
                                                                        <div id="ytd-reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
                                                                            <i className="fa fa-calendar"></i>&nbsp;<span>{YTDlabel}</span> <i className="fa fa-caret-down"></i>
                                                                        </div>
                                                                    </div>
                                                                </DateRangePicker>
                                                                <small style={{'marginLeft' : '2.2rem'}}>Date range based on Transaction date</small>
                                                            </div>
                                                        {/* </> :<></> } */}
                                                        </div>
                                                        <div className="col-md-6 col-lg-6 col-12 mb-3">
                                                            <div className="text-center my-4">
                                                                <h5>{YTDLabel}</h5>
                                                            </div>
                                                            <div className="table-responsive mb-3">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width='50%'>Gross Revenue(₹)</th>
                                                                            <th width='50%'>Net Revenue(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(YTDgrossRevenue).toFixed(2)}</td>
                                                                            <td>{Number(YTDnetRevenue).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <thead>
                                                                        <tr>
                                                                            <th width='50%'>Operating Expenses(₹)</th>
                                                                            <th width='50%'>EBITDA/Operating Profit(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(YTDtotalExpenses).toFixed(2)}</td>
                                                                            <td>{Number(YTDebitda).toFixed(2)+' ('+((YTDebitdaPrecentage !=='-')?Number(YTDebitdaPrecentage).toFixed(2):YTDebitdaPrecentage)+'%)'}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="table-responsive mb-3">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Collections(₹)</th>
                                                                            <th>Other Income(₹)</th>
                                                                            <th>Total(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(YTDcollectionAmt).toFixed(2)}</td>
                                                                            <td>{Number(YTDotherIncomeAmt).toFixed(2)}</td>
                                                                            <td>{Number(YTDtotCollectionAmt).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Payments(₹)</th>
                                                                            <th>Other Expenses(₹)</th>
                                                                            <th>Total(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(YTDpaymentAmt).toFixed(2)}</td>
                                                                            <td>{Number(YTDotherExpensesAmt).toFixed(2)}</td>
                                                                            <td>{Number(YTDtotPaymentAmt).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    {YTDloanTakenAmt > 0 && YTDloanGivenAmt > 0 && YTDtotalInvtsAmt > 0 ? <>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{YTDloanTakenAmt > 0 ? <>Loan Taken Amount(₹)</> : <></>}</th>
                                                                                <th>{YTDloanGivenAmt > 0 ? <>Loan Given Amount(₹)</> : <></>}</th>
                                                                                <th>{YTDtotalInvtsAmt > 0 ? <>Investment Amount(₹)</> : <></>}</th>
                                                                                
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{YTDloanTakenAmt > 0 ? <>{Number(YTDloanTakenAmt).toFixed(2)}</> : <></>}</td>
                                                                                <td>{YTDloanGivenAmt > 0 ? <>{Number(YTDloanGivenAmt).toFixed(2)}</> : <></>}</td>
                                                                                <td>{YTDtotalInvtsAmt > 0 ? <>{Number(YTDtotalInvtsAmt).toFixed(2)}</> : <></>}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </> : <>
                                                                    {YTDloanTakenAmt > 0 || YTDloanGivenAmt > 0 || YTDtotalInvtsAmt > 0 ? <>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{YTDloanTakenAmt > 0 ? <>Loan Taken Amount(₹)</> : <></>}</th>
                                                                                <th>{YTDloanGivenAmt > 0 ? <>Loan Given Amount(₹)</> : <></>}</th>
                                                                                <th>{YTDtotalInvtsAmt > 0 ? <>Investment Amount(₹)</> : <></>}</th>
                                                                                
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{YTDloanTakenAmt > 0 ? <>{Number(YTDloanTakenAmt).toFixed(2)}</> : <></>}</td>
                                                                                <td>{YTDloanGivenAmt > 0 ? <>{Number(YTDloanGivenAmt).toFixed(2)}</> : <></>}</td>
                                                                                <td>{YTDtotalInvtsAmt > 0 ? <>{Number(YTDtotalInvtsAmt).toFixed(2)}</> : <></>}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </>:<></>}
                                                                    </>}
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-center" colSpan="3">Net cashflow(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="text-center" colSpan="3">{Number(YTDnetCashflowamt).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : <>
                                                    <div className="row" ref={tableRef}>
                                                        <div className="col-md-12 col-lg-12 col-12 mb-3">
                                                            <div className="text-center my-4">
                                                                <h5>{label}</h5>
                                                            </div>
                                                            <div className="table-responsive mb-3" >
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width='50%'>Gross Revenue(₹) <span><Common.OverlayTrigger overlay={Common.popoverContent('','Add Sale')}><Common.Link to="/add-sale" className="btn btn-sm btn-primary text-white p-0"> <i className='bx bx-plus'></i></Common.Link></Common.OverlayTrigger></span></th>
                                                                            <th width='50%'>Net Revenue(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(grossRevenue).toFixed(2)}</td>
                                                                            <td>{Number(netRevenue).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <thead>
                                                                        <tr>
                                                                            <th width='50%'>Operating Expenses(₹) <span><Common.OverlayTrigger overlay={Common.popoverContent('','Add Expense')}><Common.Link to="/add-expense" className="btn btn-sm btn-primary text-white p-0"> <i className='bx bx-plus'></i></Common.Link></Common.OverlayTrigger></span></th>
                                                                            <th width='50%'>EBITDA/Operating Profit(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(totalExpenses).toFixed(2)}</td>
                                                                            <td>{Number(ebitda).toFixed(2)+' ('+((ebitdaPrecentage !=='-')?Number(ebitdaPrecentage).toFixed(2):ebitdaPrecentage)+'%)'}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="table-responsive mb-3">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Collections(₹)</th>
                                                                            <th>Other Income(₹)</th>
                                                                            <th>Total(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(collectionAmt).toFixed(2)}</td>
                                                                            <td>{Number(otherIncomeAmt).toFixed(2)}</td>
                                                                            <td>{Number(totCollectionAmt).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Payments(₹)</th>
                                                                            <th>Other Expenses(₹)</th>
                                                                            <th>Total(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{Number(paymentAmt).toFixed(2)}</td>
                                                                            <td>{Number(otherExpensesAmt).toFixed(2)}</td>
                                                                            <td>{Number(totPaymentAmt).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    {loanTakenAmt > 0 && loanGivenAmt > 0 && totalInvtsAmt > 0 ? <>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{loanTakenAmt > 0 ? <>Loan Taken Amount(₹)</> : <></>}</th>
                                                                                <th>{loanGivenAmt > 0 ? <>Loan Given Amount(₹)</> : <></>}</th>
                                                                                <th>{totalInvtsAmt > 0 ? <>Investment Amount(₹)</> : <></>}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{loanTakenAmt > 0 ? <>{Number(loanTakenAmt).toFixed(2)}</> : <></>}</td>
                                                                                <td>{loanGivenAmt > 0 ? <>{Number(loanGivenAmt).toFixed(2)}</> : <></>}</td>
                                                                                <td>{totalInvtsAmt > 0 ? <>{Number(totalInvtsAmt).toFixed(2)}</> : <></>}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </> : <>
                                                                        {loanTakenAmt > 0 || loanGivenAmt > 0 || totalInvtsAmt > 0 ? <>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>{loanTakenAmt > 0 ? <>Loan Taken Amount(₹)</> : <></>}</th>
                                                                                    <th>{loanGivenAmt > 0 ? <>Loan Given Amount(₹)</> : <></>}</th>
                                                                                    <th>{totalInvtsAmt > 0 ? <>Investment Amount(₹)</> : <></>}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{loanTakenAmt > 0 ? <>{Number(loanTakenAmt).toFixed(2)}</> : <></>}</td>
                                                                                    <td>{loanGivenAmt > 0 ? <>{Number(loanGivenAmt).toFixed(2)}</> : <></>}</td>
                                                                                    <td>{totalInvtsAmt > 0 ? <>{Number(totalInvtsAmt).toFixed(2)}</> : <></>}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </> : <></>}
                                                                    </>}
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-center" colSpan="3">Net cashflow(₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="text-center" colSpan="3">{Number(netCashflowamt).toFixed(2)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                                <h1 className="divider-as-on">
                                                    <em><strong>As on {moment().format('DD-MM-YYYY')}</strong></em>
                                                </h1>
                                                <div className="row mb-3">
                                                    <div className="table-responsive mb-3">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Bank Balance(Closing)(₹)</th>
                                                                    <th>Total Pending Collections(₹)</th>
                                                                    <th>Total Pending Payaments(₹)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><button className="btn btn-link p-0" onClick={() => handleIndividualClosingBalance()}>{Number(closingBalanceAmt).toFixed(2)}</button></td>
                                                                    <td><a href="/collection-listing">{Number(pendingCollectionAmt).toFixed(2)}</a></td>
                                                                    <td><a href="/payable-listing">{Number(pendingPayablesAmt).toFixed(2)}</a></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Common.FYNTicket url={props.url}/>
                    </div>
                </div>
            </div>
            <Modal show={closeBalanceModal} size={'lg'} onHide={()=>setCloseBalanceModal(false)} aria-labelledby="close-balance-modal" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="close-balance-modal">Closing Balance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="table-responsive text-nowrap">
                            <table className="table table-sm table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th>Nickname</th>
                                        <th align="right">Amount</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {closingBal.length > 0 ? <>
                                        {closingBal.map((data, index) => {
                                            return (
                                                <tr key={'key-'+index}>
                                                    <td>{data.nickname}</td>
                                                    <td>{Number(data.closing_balance).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </> : <>
                                    <td colSpan={2} style={{'textAlign': 'center'}}>No Records</td>
                                    </>}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={loanTakenModal} size={'lg'} onHide={()=>setLoanTakenModal(false)} aria-labelledby="close-balance-modal" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="close-balance-modal">Loan Taken</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="table-responsive text-nowrap">
                            <table className="table table-sm table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th>Name</th>
                                        <th>Loan Amount</th>
                                        <th>Payment Amount</th>
                                        <th>Due</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {loanTakenDataProviderWise.length > 0 ? <>
                                        {loanTakenDataProviderWise.map((data, index) => {
                                            return (
                                                <tr key={'key-'+index}>
                                                    <td>{data.name}</td>
                                                    <td>{Number(data.loan_amt).toFixed(2)}</td>
                                                    <td>{Number(data.payment).toFixed(2)}</td>
                                                    <td>{Number(data.due).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </> : <>
                                        <td colSpan={4} style={{'textAlign': 'center'}}>No Records</td>
                                    </>}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={loanGivenModal} size={'lg'} onHide={()=>setLoanGivenModal(false)} aria-labelledby="close-balance-modal" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="close-balance-modal">Loan Given</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="table-responsive text-nowrap">
                            <table className="table table-sm table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th>Name</th>
                                        <th>Loan Amount</th>
                                        <th>Payment Amount</th>
                                        <th>Due</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {loanGivenDataReceipentWise.length > 0 ? <>
                                        {loanGivenDataReceipentWise.map((data, index) => {
                                            return (
                                                <tr key={'key-'+index}>
                                                    <td>{data.name}</td>
                                                    <td>{Number(data.principle).toFixed(2)}</td>
                                                    <td>{Number(data.payment).toFixed(2)}</td>
                                                    <td>{Number(data.due).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </> : <>
                                        <td colSpan={4} style={{'textAlign': 'center'}}>No Records</td>
                                    </>}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={investmentModal} size={'lg'} onHide={()=>setInvestmentModal(false)} aria-labelledby="close-balance-modal" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="close-balance-modal">Investments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="table-responsive text-nowrap">
                            <table className="table table-sm table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th>Name</th>
                                        <th>Share Price</th>
                                        <th>Investment Amount</th>
                                        <th>Premium Amount</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {investmentDataShareHolderWise.length > 0 ? <>
                                        {investmentDataShareHolderWise.map((data, index) => {
                                            return (
                                                <tr key={'key-'+index}>
                                                    <td>{data.name}</td>
                                                    <td>{Number(data.price).toFixed(2)}</td>
                                                    <td>{Number(data.investment_amt).toFixed(2)}</td>
                                                    <td>{Number(data.premium_amt).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </> : <>
                                        <td colSpan={4} style={{'textAlign': 'center'}}>No Records</td>
                                    </>}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={YTDcloseBalanceModal} size={'lg'} onHide={()=>setYTDCloseBalanceModal(false)} aria-labelledby="close-balance-modal" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="close-balance-modal">YTD Closing Balance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="table-responsive text-nowrap">
                            <table className="table table-sm table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th>Nickname</th>
                                        <th align="right">Amount</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {YTDClosingBal.length > 0 ? <>
                                        {YTDClosingBal.map((data, index) => {
                                            return (
                                                <tr key={'key-'+index}>
                                                    <td>{data.nickname}</td>
                                                    <td>{Number(data.closing_balance).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </> : <>
                                        <td colSpan={2} style={{'textAlign': 'center'}}>No Records</td>
                                    </>}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={YTDloanTakenModal} size={'lg'} onHide={()=>setYTDloanTakenModal(false)} aria-labelledby="close-balance-modal" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="close-balance-modal">YTD Loan Taken</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="table-responsive text-nowrap">
                            <table className="table table-sm table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th>Name</th>
                                        <th>Loan Amount</th>
                                        <th>Payment Amount</th>
                                        <th>Due</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {YTDloanTakenDataProviderWise.length > 0 ? <>
                                        {YTDloanTakenDataProviderWise.map((data, index) => {
                                            return (
                                                <tr key={'key-'+index}>
                                                    <td>{data.name}</td>
                                                    <td>{Number(data.loan_amt).toFixed(2)}</td>
                                                    <td>{Number(data.payment).toFixed(2)}</td>
                                                    <td>{Number(data.due).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </> : <>
                                        <td colSpan={4} style={{'textAlign': 'center'}}>No Records</td>
                                    </>}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={YTDloanGivenModal} size={'lg'} onHide={()=>setYTDloanGivenModal(false)} aria-labelledby="close-balance-modal" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="close-balance-modal">YTD Loan Given</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="table-responsive text-nowrap">
                            <table className="table table-sm table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th>Name</th>
                                        <th>Loan Amount</th>
                                        <th>Payment Amount</th>
                                        <th>Due</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {YTDloanTakenDataProviderWise.length > 0 ? <>
                                        {YTDloanTakenDataProviderWise.map((data, index) => {
                                            return (
                                                <tr key={'key-'+index}>
                                                    <td>{data.name}</td>
                                                    <td>{Number(data.loan_amt).toFixed(2)}</td>
                                                    <td>{Number(data.payment).toFixed(2)}</td>
                                                    <td>{Number(data.due).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </> : <>
                                        <td colSpan={4} style={{'textAlign': 'center'}}>No Records</td>
                                    </>}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={YTDinvestmentModal} size={'lg'} onHide={()=>setYTDinvestmentModal(false)} aria-labelledby="close-balance-modal" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="close-balance-modal">YTD Investments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="table-responsive text-nowrap">
                            <table className="table table-sm table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th>Name</th>
                                        <th>Share Price</th>
                                        <th>Investment Amount</th>
                                        <th>Premium Amount</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {YTDinvestmentDataShareHolderWise.length > 0 ? <>
                                        {YTDinvestmentDataShareHolderWise.map((data, index) => {
                                            return (
                                                <tr key={'key-'+index}>
                                                    <td>{data.name}</td>
                                                    <td>{Number(data.price).toFixed(2)}</td>
                                                    <td>{Number(data.investment_amt).toFixed(2)}</td>
                                                    <td>{Number(data.premium_amt).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </> : <>
                                        <td colSpan={4} style={{'textAlign': 'center'}}>No Records</td>
                                    </>}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}