const dc_supply_types = [
    {
      label: 'Job Work (JB)',
      value: '4'
    },
    {
      label: 'For Own Use (FOU)',
      value: '5'
    },
    {
      label: 'Job Work Returns (JWR)',
      value: '6'
    },
    {
      label: 'Sales Return (SR)',
      value: '7'
    },
    {
      label: 'SKD/CKD/Lots (SKD/CKD/Lots)',
      value: '9'
    },
    {
      label: 'Line Sales (LS)',
      value: '10'
    },
    {
      label: 'Recipient Not Known (RNK)',
      value: '11'
    },
    {
      label: 'Exhibition or Fairs (EF)',
      value: '12'
    },
    {
      label: 'Others (OTH)',
      value: '8'
    },
  ];
  export default dc_supply_types;