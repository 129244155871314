import React,{useState}  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as Common from '../common/ImportUserEssentials';
import Select from "react-select";
import styled from 'styled-components';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import * as DTE from '../common/ImportDataTableEssentials';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { confirmAlert } from 'react-confirm-alert';
import SaleOrder from './SaleOrder';
import TagManager from 'react-gtm-module';

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;

export default function Quotation(props){
	const [totalDataCnt,setTotalDataCount] = React.useState({'Active':'','Cancelled':'','Expired':''});
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
	const [DTpending, setDTPending] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
    const [quotations, setQuotations] = useState([]);
	const [exportBtnClass,setExportBtnClass]    = React.useState('d-none');

	const [quotationCancelShow,setQuotationCancelShow] = React.useState(false);
	const [quotationCancelRemarks,setQuotationCancelRemarks] = React.useState('');
	const [quotationId,setQuotationId] = React.useState('');
	const [invNo,setInvNo] = React.useState('');
	const [totCustomer,setTotCustomer] = React.useState({'Active':'-','Cancelled':'-'});
	const [totInvAmt,setTotInvAmt] = React.useState({'Active':'-','Cancelled':'-'});
    const invoiceTabTypes = ['Active', 'Cancelled', 'Expired'];
    const [activeTab, setActCan] = React.useState(invoiceTabTypes[0]);
	const [convertInvoiceMsgShow,setConvertInvoiceMsgShow] = React.useState('');
	const mainTabTypes = ['Quotations', 'Sale Orders'];
	const [mainTab, setMainTab] = React.useState(mainTabTypes[0]);
	const [sortOrder,setSortOrder] = React.useState('');
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
	const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
    const navigate = Common.useNavigate();
	const { state } = Common.useLocation();
	
	// User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	const [incomeColl_w,setincomeColl_w] = React.useState(true);
	
    React.useEffect(() => {
        document.title = 'Quotations & Sale Orders | '+props.site_name;
		const hostname = window.location.hostname;
		if( hostname === 'mart.fynone.com' ){
			const tagManagerArgs = {
				gtmId: 'G-RF6E5H5MTZ'
			}

			TagManager.initialize(tagManagerArgs);
		}
		if (state !== null) {
			setMainTab(mainTabTypes[state.tab_type])
		}
		if( Object.keys(userPerm).length > 0 ){
			setincomeColl_w((userPerm[2]['write'] !== undefined && userPerm[2]['write'] === '0') ? false : true);
		}
		loadDataTable(1,perPage,filterText,dates);
    }, [props,dt_load,filterText,dates]);
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,dates,sortOrder);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText,dates,sortOrder);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,dates,btoa(column.sortField+'####'+sortDirection));
	};
    const loadDataTable = (page,perPage,filterText,dates,sort='',active_tab=activeTab) => {
		Common.axios({
            method: 'post',
            url:props.url+'quotation/listing/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'dates':dates,'sort':sort,'search':filterText,'active_tab':active_tab}
        }).then(function (response) {
			setExportBtnClass('d-none');
            if(response.data.success){
				setQuotations(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(prevState => ({
				...prevState,
					[active_tab]: response.data.total
				}));
				setDTPending(false);
				if(response.data.total > 0){
					setExportBtnClass('');
				}
				setTotCustomer(prevState => ({
				...prevState,
					[active_tab]: response.data.tabInfo.customer
				}));
				setTotInvAmt(prevState => ({
				...prevState,
					[active_tab]: response.data.tabInfo.amount
				}));
            }
        }).catch(error => console.log(error));
	}
    const columns = [
    	{
    		name: 'Action',
            button: true,
            cell: (row) => (
                <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu" >
                  <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.id,1,row.inv_no)}><i className="bx bxs-download"></i> Download</button>
                  <button className="dropdown-item" onClick={(e) => handleConvertSaleOrder(row.id,row.convert_to_sale)}><i className='bx bxs-file'></i> Convert to Sale Order </button>
				  <button className="dropdown-item" onClick={(e) => handleCopyQuotation(row.id,'copy',row.convert_to_sale)}><i className='bx bxs-copy'></i> Copy Quotation</button>
                  <button className="dropdown-item" onClick={(e) => handleCopyQuotation(row.id,'edit',row.convert_to_sale)}><i className='bx bxs-edit'></i> Edit Quotation </button>
				  <button className="dropdown-item" onClick={(e) => handleCancelQutn(row)}><i className='bx bx-message-square-x'></i> Cancel Quotation</button>
                  <button className="dropdown-item" onClick={(e) => handleMarkExpired(e,row.id,row.convert_to_sale)}><i className='bx bx-calendar-exclamation'></i> Mark as Expired </button>
                </div>
				<button type="button" className="btn p-1 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bx bx-share-alt"></i>
                </button>
				<div className="dropdown-menu">
					<button className="dropdown-item" onClick={(e) => handleShareContent(row.id,2)}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                  	<button className="dropdown-item" onClick={(e) => handleShareContent(row.id,3)}><i className="bx bx-envelope"></i> Share via Email </button>
				</div>
              </div>
            )
    	},
        {
            name: 'Quotations No.',
            selector: row => row.inv_no,
            sortable: true,
			sortField: 'inv_no'
        },
        {
            name: 'Quotations Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: false,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt (₹)',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        },
        {
            name: 'Convert Sale Order',
            selector: row => row.convert_to_sale,
            sortable: false,
			center: true,
			cell: (row) => (
				( row.convert_to_sale === '1' ) ? <Common.OverlayTrigger overlay={Common.popoverContent('','Converted to Sale Order')}><i className='bx bxs-check-square display-6'></i></Common.OverlayTrigger> : ''
			)
        },
        {
            name: 'Expiry Date',
            selector: row => row.expiry_date,
            sortable: true,
			sortField: 'expiry_date'
        },
		{
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
			sortField: 'created_by'
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on'
        },
		{
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
			sortField: 'updated_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on'
        }
        
	];
	const ExpiredColumns = [
		{
			name: 'Action',
			button: true,
			cell: (row) => (
				<div className="dropdown">
				<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
				  <i className="bx bx-dots-vertical-rounded"></i>
				</button>
				<div className="dropdown-menu" >
					<button className="dropdown-item" onClick={(e) => handleCopyQuotation(row.id,'copy',row.convert_to_sale)}><i className='bx bxs-copy'></i> Copy Quotation</button>
				</div>
			  </div>
			)
		},
        {
            name: 'Quotations No.',
            selector: row => row.inv_no,
            sortable: true,
			sortField: 'inv_no'
        },
        {
            name: 'Quotations Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: true,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt (₹)',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        },
        {
            name: 'Expiry Date',
            selector: row => row.expiry_date,
            sortable: true,
			sortField: 'expiry_date'
        }
	];
	const handleMarkExpired = (e, val, convert_to_sale) => {
		if(convert_to_sale === '1'){
			setConvertInvoiceMsgShow(true);
			return false;
		}
        e.preventDefault();
        confirmAlert({
            title: 'Mark as Expired',
            message: 'Are you sure you want to mark as Expired?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    Common.showLoader(true);

                    let data = {};
                    
                    data['qid'] = val;
                    data['pid'] = localStorage.getItem('fo_profileid');
            
                    Common.axios({
                        method: 'post',
                        url:props.url+'quotation/mark_expired',
                        data: data,
                    }).then(function (response) {
                        Common.showLoader(false);
                        if(response.data.success){
                            Common.toast.dismiss();
                            if(response.data.success === 1){
                                Common.toast.success(response.data.message);
                                loadDataTable(1,perPage,filterText,dates,sortOrder);
                                return false;
                            }else{
                                Common.toast.error(response.data.message);
                            }
                            return false;
                        } 
                        const err_html = () => (
                            Common.parse(response.data.message)
                        );
                        Common.toast.error(err_html);
                        Common.showLoader(false);
                    }).catch(error => console.log(error));
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
    }
	const setDatePicker = (start, end) => {
		setDates({ start, end });
		// setDtLoad(!dt_load);
	  }
	const handleConvertSaleOrder =(val,convert_to_sale) => {
		if(convert_to_sale === '1'){
			setConvertInvoiceMsgShow(true);
			return false;
		}
        navigate('/convert-sale-order',
        {
            state: {
                qid: val,
				type: 'quotation'
            }
        });
    }
	const handleCopyQuotation =(val,action,convert_to_sale) => {
		if(action === 'edit' && convert_to_sale === '1'){
			setConvertInvoiceMsgShow(true);
			return false;
		}
        navigate('/add-quotation',
        {
            state: {
                qid: val,
				action: action
            }
        });
    }
	const cancelColumns = [
		{
			name: 'Action',
			button: true,
			cell: (row) => (incomeColl_w === true ? <>
					<div className="dropdown">
					<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
					  <i className="bx bx-dots-vertical-rounded"></i>
					</button>
					<div className="dropdown-menu" >
					  <button className="dropdown-item" onClick={(e) => handleCopyQuotation(row.id,'copy')}><i className='bx bxs-copy'></i> Copy Quotation</button>
					</div>
				  </div>
				</>:<></>
			)
		},
        {
            name: 'Quotations No.',
            selector: row => row.inv_no,
            sortable: true,
			sortField: 'inv_no'
        },
        {
            name: 'Quotations Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: true,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        },
        {
            name: 'Cancel Reason',
            selector: row => row.cancel_reason,
            sortable: false,
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on'
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: true,
			sortField: 'created_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on'
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: true,
			sortField: 'updated_by'
        }
	];
	const handleExportCSV = (e)=>{
		e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'quotation/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText,'dates':dates,'active_tab':activeTab},
            // responseType: 'blob',
        }).then(function (response) {
			// console.log(response.data);return;
			if(response.data.success){
				window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Quotation-${activeTab}-${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click(); */
			} else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
	}
	const handleDownloadPdf =(val,status,invNum) => {
		Common.showLoader(true);
		let url = props.url+'quotation/getData/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status));
        Common.axios({
            method: 'get',
            url:url,
            // responseType: 'blob',
        }).then(function (response) {
			Common.showLoader(false);
			// console.log(response.data);
            if(response.data.success){
				window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
				let currentdate = new Date();
				let timesec = currentdate.getTime().toString().substring(2, 6);
				let fileName = currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() + "_" + timesec;
                link.setAttribute('download', invNum+'_'+fileName+'.pdf');
                document.body.appendChild(link);
                link.click(); */
            }
            else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => {console.log(error);Common.showLoader(false);});
	}
	const handleShareContent =(val,status) => {
		let url = props.url+'quotation/getData/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status));
		Common.axios({
			method: 'get',
			url:url,
		}).then(function (response) {
			if(response.data.success){
				if(parseInt(status) === 2){
					window.open("https://api.whatsapp.com/send?text="+response.data.data.body,"_blank");
				}else{
					window.open("mailto:?subject="+response.data.data.subject+"&body="+response.data.data.body,"_blank");
				}
			}
		}).catch(error => console.log(error));
	}
	const handleCancelQutn = (details)=>{
		if(details.convert_to_sale === '1'){
			setConvertInvoiceMsgShow(true);
			return false;
		}
        setQuotationId(details.id);
		setInvNo(details.inv_no);
        setQuotationCancelRemarks('');
		setQuotationCancelShow(true);
    }
	const handleCancelQuotation = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['quotationid'] = quotationId;
		data['pid']         = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'quotation/cancelQuotation',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setQuotationCancelShow(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
					loadDataTable(1,perPage,filterText,dates,sortOrder);
					// window.location.reload(true);
					return false;
                }else{
                    Common.toast.error(response.data.message);
                }
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
	const [showActiveDiv,setShowActiveDiv]     = React.useState(true);
    const [showCancelledDiv,setShowCancelledDiv] = React.useState(false);
    const [showExpiredDiv,setShowExpiredDiv] = React.useState(false);
	function TabGroup() {
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{mainTabTypes.map(type => (
				<Tab
					key={type}
					active={mainTab === type}
					onClick={() => handleMainTab(type)}
				>
					{type}
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
	const handleMainTab = penColChecked => {
		setMainTab(penColChecked);
		setSortOrder('')
		if(penColChecked === 'Quotations'){
			loadDataTable(1,perPage,filterText,dates,'',penColChecked);
		}
	};
	const handleTab = penColChecked => {
		setActCan(penColChecked);
		loadDataTable(1,perPage,filterText,dates,sortOrder,penColChecked);
		if(penColChecked === "Active"){
			setShowActiveDiv(true);
			setShowCancelledDiv(false);
			setShowExpiredDiv(false);
		}
		else if(penColChecked === "Cancelled"){
			setShowActiveDiv(false);
			setShowCancelledDiv(true);
			setShowExpiredDiv(false);
		}
		else{
			setShowActiveDiv(false);
			setShowCancelledDiv(false);
			setShowExpiredDiv(true);
		}
	};
    return (
    <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="quotation" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3">
								<span className="text-muted fw-light">Income & Collections /</span> Quotation Listing <span className="dropdown">
									<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
										<i className='text-danger bx bxl-youtube bx-md'></i>
									</button>
									<div className="dropdown-menu" >
										<a href="https://youtu.be/S9Ypb4s-TTo" className="dropdown-item" target="_blank" rel="noreferrer"><span>Quotation & Sale Order</span></a>
									</div>
								</span>
							</h4>
							<div className="row">
								<div className="pb-3">
									<div className="col-md-12">
										<TabGroup/>
									</div>
								</div>
								{mainTab === 'Quotations' && (<>
                                <div id="sdt_cont" className="col-sm-12">
									{showActiveDiv && (<>
										<div className="row">
											<div className="col-md-4 col-12 mb-1 text-center text-md-start">
												<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
												{incomeColl_w && (<>
													<Common.Link to="/add-quotation" className="btn btn-sm btn-primary"> <i className="bx bx-plus"></i>Add Quotation</Common.Link>
												</>)}
											</div>
											<div className="col-md-4 col-12 text-center mb-1" >
												<DateRangePicker
													initialSettings={DTE.DatePickerPredefined(moment,start,end)}
													onCallback={setDatePicker}>
													<div className="input-group input-group-sm">
														<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
														<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
															<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
														</div>
													</div>
												</DateRangePicker>
												<small>Date range based on Quotation Date</small>
											</div>
											<div className="col-md-4 col-12 text-center text-md-end mb-1">
												<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Customer Name, Inv No.')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
												<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
											</div>
											<div className="col-md-12 col-12 text-center mb-1">
												<div className="form-check form-check-inline">
													<input id="active_type" className="form-check-input" type="radio" value="Active"  required checked={activeTab === 'Active'} onChange={(e) => handleTab(e.target.value)} />
													<label className="form-check-label" htmlFor="active_type"> Active {(activeTab === 'Active' && totalDataCnt[activeTab] !== '') ? ' ('+totalDataCnt[activeTab]+')' : ''} </label>
													{activeTab === 'Active' && (<>
														<div className="tabAdditionalInfo">
															Cust.# {totCustomer[activeTab]} | Tot. Amt.(₹) {Common.numToWords(totInvAmt[activeTab])}
														</div>
													</>)}
												</div>
												<div className="form-check form-check-inline">
													<input id="cancelled_type" className="form-check-input" type="radio" value="Cancelled" required  checked={activeTab === 'Cancelled'} onChange={(e) => handleTab(e.target.value)} />
													<label className="form-check-label" htmlFor="cancelled_type"> Cancelled {(activeTab === 'Cancelled' && totalDataCnt[activeTab] !== '') ? ' ('+totalDataCnt[activeTab]+')' : ''}</label>
													{activeTab === 'Active' && (<>
														<div className="tabAdditionalInfo">
															Cust.# - | Tot. Amt.(₹) -
														</div>
													</>)}
												</div>
												<div className="form-check form-check-inline">
													<input id="expired_type" className="form-check-input" type="radio" value="Expired" required  checked={activeTab === 'Expired'} onChange={(e) => handleTab(e.target.value)} />
													<label className="form-check-label" htmlFor="expired_type"> Expired {(activeTab === 'Expired' && totalDataCnt[activeTab] !== '') ? ' ('+totalDataCnt[activeTab]+')' : ''}</label>
													{activeTab === 'Active' && (<>
														<div className="tabAdditionalInfo">
															Cust.# - | Tot. Amt.(₹) -
														</div>
													</>)}
												</div>
											</div>
										</div>
										<DTE.DataTable title={"Quotation"} className="dataTables_wrapper rdt_AdjDrp" columns={columns} data={quotations} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</>)}
									{showCancelledDiv && (<>
										<div className="row">
											<div className="col-md-3 col-12 listing-tabs mb-1">
												<button className={"btn btn-sm btn-info "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
											</div>
											<div className="col-md-5 col-12 text-center mb-1">
												<DateRangePicker
													initialSettings={DTE.DatePickerPredefined(moment,start,end)}
													onCallback={setDatePicker}>
													<div className="input-group input-group-sm">
														<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
														<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
															<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
														</div>
													</div>
												</DateRangePicker>
												<small>Date range based on Quotation Date</small>
											</div>
											<div className="col-md-4 col-12 text-center text-md-end mb-1">
												<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Quotations Number, Customer')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
												<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
											</div>
											<div className="col-md-12 col-12 text-center mb-1">
												<div className="form-check form-check-inline">
													<input id="active_type" className="form-check-input" type="radio" value="Active"  required checked={activeTab === 'Active'} onChange={(e) => handleTab(e.target.value)} />
													<label className="form-check-label" htmlFor="active_type"> Active {(activeTab === 'Active' && totalDataCnt[activeTab] !== '') ? ' ('+totalDataCnt[activeTab]+')' : ''}</label>
													{activeTab === 'Cancelled' && (<>
														<div className="tabAdditionalInfo">
															Cust.# - | Tot. Amt.(₹) -
														</div>
													</>)}
												</div>
												<div className="form-check form-check-inline">
													<input id="cancelled_type" className="form-check-input" type="radio" value="Cancelled" required  checked={activeTab === 'Cancelled'} onChange={(e) => handleTab(e.target.value)} />
													<label className="form-check-label" htmlFor="cancelled_type"> Cancelled {(activeTab === 'Cancelled' && totalDataCnt[activeTab] !== '') ? ' ('+totalDataCnt[activeTab]+')' : ''}</label>													
													{activeTab === 'Cancelled' && (<>
														<div className="tabAdditionalInfo">
															Cust.# {totCustomer[activeTab]} | Tot. Amt.(₹) {Common.numToWords(totInvAmt[activeTab])}
														</div>
													</>)}
												</div>
												<div className="form-check form-check-inline">
													<input id="expired_type" className="form-check-input" type="radio" value="Expired" required  checked={activeTab === 'Expired'} onChange={(e) => handleTab(e.target.value)} />
													<label className="form-check-label" htmlFor="expired_type"> Expired {(activeTab === 'Expired' && totalDataCnt[activeTab] !== '') ? ' ('+totalDataCnt[activeTab]+')' : ''}</label>
													{activeTab === 'Cancelled' && (<>
														<div className="tabAdditionalInfo">
															Cust.# - | Tot. Amt.(₹) -
														</div>
													</>)}
												</div>
											</div>
										</div>
										<DTE.DataTable title="Quotation" className="rdt_AdjDrp" columns={cancelColumns} data={quotations} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</>)}
									{showExpiredDiv && (<>
										<div className="row">
											<div className="col-md-3 col-12 listing-tabs mb-1">
												<button className={"btn btn-sm btn-info "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
											</div>
											<div className="col-md-5 col-12 text-center mb-1">
												<DateRangePicker
													initialSettings={DTE.DatePickerPredefined(moment,start,end)}
													onCallback={setDatePicker}>
													<div className="input-group input-group-sm">
														<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
														<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
															<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
														</div>
													</div>
												</DateRangePicker>
												<small>Date range based on Quotation Date</small>
											</div>
											<div className="col-md-4 col-12 text-center text-md-end mb-1">
												<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Quotations Number, Customer')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
												<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
											</div>
											<div className="col-md-12 col-12 text-center mb-1">
												<div className="form-check form-check-inline">
													<input id="active_type" className="form-check-input" type="radio" value="Active"  required checked={activeTab === 'Active'} onChange={(e) => handleTab(e.target.value)} />
													<label className="form-check-label" htmlFor="active_type"> Active {(activeTab === 'Active' && totalDataCnt[activeTab] !== '') ? ' ('+totalDataCnt[activeTab]+')' : ''}</label>
													{activeTab === 'Expired' && (<>
														<div className="tabAdditionalInfo">
															Cust.# - | Tot. Amt.(₹) -
														</div>
													</>)}
												</div>
												<div className="form-check form-check-inline">
													<input id="cancelled_type" className="form-check-input" type="radio" value="Cancelled" required  checked={activeTab === 'Cancelled'} onChange={(e) => handleTab(e.target.value)} />
													<label className="form-check-label" htmlFor="cancelled_type"> Cancelled {(activeTab === 'Cancelled' && totalDataCnt[activeTab] !== '') ? ' ('+totalDataCnt[activeTab]+')' : ''}</label>
													{activeTab === 'Expired' && (<>
														<div className="tabAdditionalInfo">
															Cust.# - | Tot. Amt.(₹) -
														</div>
													</>)}
												</div>
												<div className="form-check form-check-inline">
													<input id="expired_type" className="form-check-input" type="radio" value="Expired" required  checked={activeTab === 'Expired'} onChange={(e) => handleTab(e.target.value)} />
													<label className="form-check-label" htmlFor="expired_type"> Expired {(activeTab === 'Expired' && totalDataCnt[activeTab] !== '') ? ' ('+totalDataCnt[activeTab]+')' : ''}</label>
													{activeTab === 'Expired' && (<>
														<div className="tabAdditionalInfo">
															Cust.# {totCustomer[activeTab]} | Tot. Amt.(₹) {Common.numToWords(totInvAmt[activeTab])}
														</div>
													</>)}
												</div>
											</div>
										</div>
										<DTE.DataTable title="Quotation" className="rdt_AdjDrp" columns={ExpiredColumns} data={quotations} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</>)}
                                </div>
								</>)}
								{mainTab === 'Sale Orders' && (<>
									<SaleOrder url={props.url}/>
								</>)}
                            </div>

                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal size='md' show={quotationCancelShow} onHide={()=>setQuotationCancelShow(false)} aria-labelledby="Quotation-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Quotation-Cancel-modal">Cancel Quotation ({invNo})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addEwbCancelFrm" onSubmit={handleCancelQuotation} autoComplete="off">
					<div className="row">
						<div className="col-sm-12">
                            <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                            <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} required/>
						</div>
                        <div className="col-sm-12">
                            <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                            <div className="input-group input-group-merge">
                                <textarea rows="2" id="cancel-remarks" name="cancel_remarks" className="form-control" placeholder="Cancel Remarks" aria-label="CancelRemarks" aria-describedby="Remarks" value={quotationCancelRemarks} onChange={(e) => setQuotationCancelRemarks(e.target.value)} required></textarea>
                            </div>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-3">
							<button type='submit' className="btn btn-sm btn-danger"><i className='bx bx-message-square-x' ></i>&nbsp;Cancel</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
		<Modal show={convertInvoiceMsgShow} size='sm' onHide={()=>setConvertInvoiceMsgShow(false)} aria-labelledby="Convert-Invoice_Msg-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Quotation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<p><b>There is an active sale order linked to this quotation. Cancel that sale order to edit/cancel this quotation</b></p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="danger" onClick={()=>setConvertInvoiceMsgShow(false)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
    </>
    );
}