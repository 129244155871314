import React,{ useState } from 'react';
import Select from "react-select";
import * as Common from '../common/ImportUserEssentials';
import Modal from 'react-bootstrap/Modal';
import '../styles/add-profile.css';

export default function BussinessAccount(props){
	let maxLenName = 30;
	const [account_name, setAccountName] 	  = useState('');
    const [nameCharCntDiv, setNameCharCntDiv] = useState(false);
    const [nameCharCnt, setNameCharCnt] 	  = useState('0');
	const [legal_name, setLegalName] 		  = useState('');
	const [trade_name, setTradeName] 		  = useState('');
	const [state, setState]             	  = useState('');
	const [district, setDistrict]        	  = useState('');
	const [pin, setPin]                  	  = useState('');
	const [address, setAddress]          	  = useState('');
	//const [upi, setUpi]                  = useState('');
	const [gstin, setGstin]              	  = useState('');
	const [address_type, setAddressType] 	  = useState('0');
	const [backBtn, setBackBtn]               = useState(false);
	const [images, setImages] 			 	  = useState('');
	const [masterProfile, setMasterProfile]	  = useState(0);
	const [bank_name, setBankName]            = useState('');
	const [payee_name, setPayeeName]          = useState('');
	const [disableField, setDisableField]     = useState(false);
	const [formData, setFormData]             = useState('');
	const [accountTypeModal,setAccountTypeModal] = useState(false);
	const navigate = Common.useNavigate();
	React.useEffect(() => {
		if( localStorage.getItem('fo_profileid') !== '0' ){
			setBackBtn(true);
		}
	});
	/*const onChange = (imageList) => {
		setImages(imageList);
	};*/
	const setAccountNamePlus = (val) => {
		let name = Common.acceptOnlyCharsNumbers(val);
		setAccountName(name);
		setNameCharCnt(name.length);
	}
    const handleKeyDown =(val,e) => {
		e.preventDefault();
		let pincode= Common.acceptOnlyNumbers(e.target.value);
		setPin(pincode);
		if( pincode.length === 6 ){
			populatePinData(pincode);
		}
    }
	const populatePinData = (pincode) => {
		Common.axios({
			method: 'post',
			url:props.url+'ajax/getDataByPin',
			data: {pincode:pincode},
		}).then(function (response) {
			if(response.data.success){
				setState(response.data.stateid);
				setDistrict(response.data.district);
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const handleSubmit =(e) => {
		e.preventDefault();
		let id = localStorage.getItem('fo_loggedin');
		let data = {};
		data['pid'] = localStorage.getItem('fo_profileid');
		data['account_name'] = account_name;
		data['legal_name'] = legal_name;
		data['trade_name'] = trade_name;
		data['pin'] = pin;
		data['state'] = state;
		data['district'] = district;
		data['address'] = address;
		data['type'] = 'Business';
		data['gstin'] = gstin;
		data['address_type'] = address_type;
		data['id'] = id;
		data['logo'] = images;
		data['profile_master_id'] = masterProfile;
		data['bank_name'] = bank_name;
		data['payee_name'] = payee_name;
		setFormData(data);
		Common.axios({
			method: 'get',
			url: props.url+'/user/details',
		}).then(function (response) {
			if( response.data.success ){
				if(response.data.data.tax_prof === "0"){
					setAccountTypeModal(true);
				}else{
					data['account_type'] = 'fynone';
					handleFormDataSubmit('fynone',data);
				}
			}
		}).catch(error => console.log(error));
	}
	const handleFormDataSubmit =(account_type,form_data="") => {
		if(form_data === ""){
			formData.account_type = account_type;
			form_data = formData;
		}
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'user/create_profile',
			data: form_data,
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				let countPid = parseInt(response.data.user_profile_map.count_pid)
				if (countPid > 1) {
					Common.axios({
						method: 'post',
						url:props.url+'user/update',
						data: {current_profile:response.data.current_profile_details.id},
					}).then(function (res) {
						if( res.data.success ){
							localStorage.setItem('fo_profileid',response.data.current_profile_details.id);
							if(response.data.current_profile_details.subplan === 'Free'){
								localStorage.setItem('fo_mart_url','0');
							}
							window.location.reload(false);
						}
					}).catch(error => console.log(error));
					navigate('/profiles');
					return false;
				} else {
					localStorage.setItem('fo_mart_url','0');
					navigate('/financial-summary',
					{
						state: {
							pid: response.data.current_profile_details.id,
							// tab_type: 1,
						}
					})
					return false;
				}
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const handleGstinData =(e) => {
		let value = e.target.value
		if (value.length === 15) {
			Common.showLoader(true);
			let url = props.url+'ajax/fetch_gstin_details';
			Common.axios({
				method: 'post',
				url:url,
				data: {gstin:value},
			}).then(function (response) {
				Common.showLoader(false);
				if(response.data.status){
					if(response.data.data.status_cd === "0"){
						Common.toast.dismiss();
						Common.toast.error(response.data.data.error.message);
						return false;
					}
					setDisableField(true);
					setLegalName(response.data.data.lgnm);
					if( response.data.data.tradeNam !== undefined ){
						setTradeName(response.data.data.tradeNam);
					}
					if( response.data.data.pradr !== undefined ){
						if( response.data.data.pradr.addr.pncd !== undefined ){
							setPin(response.data.data.pradr.addr.pncd);
							populatePinData(response.data.data.pradr.addr.pncd);
						}
						let tempaddr = '';
						if( response.data.data.pradr.addr.bno !== undefined ){
							if( tempaddr !== '' ){ tempaddr += ' ';}
							tempaddr += response.data.data.pradr.addr.bno;
						}
						if( response.data.data.pradr.addr.flno !== undefined ){
							if( tempaddr !== '' ){ tempaddr += ' ';}
							tempaddr += response.data.data.pradr.addr.flno;
						}
						if( response.data.data.pradr.addr.bnm !== undefined ){
							if( tempaddr !== '' ){ tempaddr += ' ';}
							tempaddr += response.data.data.pradr.addr.bnm;
						}
						if( response.data.data.pradr.addr.st !== undefined ){
							if( tempaddr !== '' ){ tempaddr += ', ';}
							tempaddr += response.data.data.pradr.addr.st;
						}
						if( response.data.data.pradr.addr.loc !== undefined ){
							if( tempaddr !== '' ){ tempaddr += ', ';}
							tempaddr += response.data.data.pradr.addr.loc;
						}
						setAddress(tempaddr);
					}
					return false;
				}
				Common.toast.dismiss();
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
	}

    return(
	<>
		<div className="card" >
			<div className="card-body">
				<form id="bussiness_account" className="account_type_form mt-3" autoComplete="off">
					{/* <div className="mb-3">
						<div className="button-wrapper">
							<div className="App">
								<ImageUploading										
									value={images}
									onChange={onChange}
									dataURLKey="data_url"
									acceptType={["jpg","png"]}
									maxFileSize="51200"
								>
									{({
									imageList,
									onImageUpload,
									onImageRemove,
									errors,
									}) => (
									// write your building UI
									<div className="upload__image-wrapper">
										<div className="row">
											<div className="col-sm-5">
												<div key={0} className="image-item">
													<img
														//src={image['data_url']}
														src = {imageList != '' ? imageList[0]['data_url'] : '../assets/img/default-user-img.jpg'}
														alt="user-avatar"
														className="d-block rounded"
														height="100"
														width="100"
														id="uploadedAvatar"
													/>
												</div>
											</div>
											<div className="col-sm-7">
												<div className="button-wrapper mt-4">
													<label htmlFor="upload" className="btn btn-sm btn-primary me-2 mb-2" tabIndex="0" onClick={onImageUpload}>
														<span className="d-none d-sm-block">Upload Logo</span>
														<i className="bx bx-upload d-block d-sm-none"></i>
													</label>
													<button type="button" className="btn btn-sm btn-outline-secondary account-image-reset mb-2" onClick={() => onImageRemove(0)}>
														<i className="bx bx-reset d-block d-sm-none"></i>
														<span className="d-none d-sm-block">Reset</span>
													</button>
													<p className="text-muted mb-0 smaller_font">Allowed JPG or PNG. Max size of 50KB</p>
												</div>
											</div>
											{errors && (
												<div className="col-sm-12">
													{errors.acceptType && (
													<span className="text-danger smaller_font">* Your selected file type is not allow</span>
													)}
													{errors.maxFileSize && (
													<span className="text-danger smaller_font">* Selected file size exceed maxFileSize</span>
													)}
												</div>
											)}
										</div>
									</div>
									)}
								</ImageUploading>
							</div>
						</div>
					</div>
					<hr/> */}
					<div className="mb-3">
						<label className="form-label" htmlFor="business-name">Account Name<span className="text-danger">*</span></label>
						<input autoFocus maxLength="30" id="business-name" type="text" className="form-control" name="account_name" placeholder="Account Name" aria-label="Account Name" required value={account_name} 
							onFocus={(e) => setNameCharCntDiv(true)}
							onBlur={(e) => setNameCharCntDiv(false)}
							onChange={(e) => setAccountNamePlus(e.target.value)}
						/>
						{ nameCharCntDiv && <><span className="input_char_count">{nameCharCnt}/{maxLenName}</span></>}
					</div>
					<div className="mb-3">
						<label className="form-label" htmlFor="business-gstin">GSTIN (Optional)</label>
						<input type="text" id="business-gstin" name="gstin" className="form-control" placeholder="GSTIN (Optional)" aria-label="GSTIN" aria-describedby="basic-icon-default-phone2" maxLength="15" value={gstin} onChange={(e) => {handleGstinData(e);setGstin(e.target.value.toUpperCase())}}/>
						<div className="form-text">GSTIN Data will be auto fetched.</div>
					</div>
					{gstin.length >= 3 ? <>
						<div className="mb-3">
							<label className="form-label" htmlFor="business-lgname">Legal Name</label>
							<input id="business-lgname" type="text" className="form-control" disabled={disableField} name="legal_name" placeholder="Legal Name" aria-label="Legal Name" value={legal_name} onChange={(e) => setLegalName(Common.acceptOnlyCharsNumbers(e.target.value))}/>
						</div>
						<div className="mb-3">
							<label className="form-label" htmlFor="business-trname">Trade Name</label>
							<input id="business-trname" type="text" className="form-control" disabled={disableField} name="trade_name" placeholder="Trade Name" aria-label="Trade Name" value={trade_name} onChange={(e) => setTradeName(Common.acceptOnlyCharsNumbers(e.target.value))}/>
						</div>
					</> : <></>}
					<div className="mb-3">
						<label className="form-label" htmlFor="business-pin">Pin Code<span className="text-danger">*</span></label>
						<input id="business-pin" type="text" name="pin" className="form-control" placeholder="Pin Code" aria-label="Pin Code" maxLength="6" inputMode="numeric" value={pin} onChange={(e) => handleKeyDown(e.target.value,e)}/>
					</div>
					{pin.length === 6 ? <>
						<div className="mb-3">
							<label className="form-label" htmlFor="business-district">District</label>
							<input type="text" id="business-district" name="district" className="form-control" placeholder="District" aria-label="District" aria-describedby="District" value={district} onChange={(e) => setDistrict(e.target.value)}/>
						</div>
						<div className="mb-3">
							<label className="form-label" htmlFor="business-state">State</label>
							<Select id="business-state" placeholder="Select State" value={props.state[Common.stateIndex(props.state,state)]} name='state' options={props.state} onChange={(e) => setState(e.value)}/>
						</div>
						<div className="mb-3">
							<label className="form-label" htmlFor="business-address">Address</label>
							{/* <textarea id="business-address" name="address" className="form-control" placeholder="Address" aria-label="Address" aria-describedby="basic-icon-default-message2" value={address} onChange={(e) => setAddress(e.target.value)}></textarea> */}
							<input type='text' id="business-address" name="address" maxLength="100" className="form-control" placeholder="Address" aria-label="Address" aria-describedby="basic-icon-default-message2" value={address} onChange={(e) => setAddress(e.target.value)} />
						</div>
						<div className="mb-3">
							<label className="form-label" htmlFor="business-address-type">Address Type</label>
							<Select id="business-address-type" placeholder="Select Address Type" name='address_type' options={Common.optRegOffices} onChange={(e) => setAddressType(e.value)} required/>
						</div>
					</> : <></>}
					{props.profileMasters != '' &&
						<div className="mb-3">
							<label className="form-label" htmlFor="business-master-profile">Master Profile</label><Common.OverlayTrigger overlay={Common.popoverContent('','Link new profiles to an existing master profile for viewing dashboards at individual profile level and at consolidated level. Ex: if the new profile is a branch or warehouse, then link it to an existing profile of a head office or registered office. You can then view info at branch level and also consolidated at entity level.')}><i className='bx bx-question-mark'></i></Common.OverlayTrigger>
							<Select id="business-master-profile" placeholder="Select Master Profile" name='master_profile' options={props.profileMasters} onChange={(e) => setMasterProfile(e.value)}/>
						</div>
					}
					<div className="mb-3">
						<label htmlFor="bank_name" className="form-label">Your preferred UPI App </label>
						<select id="bank_name" className="form-select" name='bank_name'  value={bank_name} onChange={(e) => {setBankName(e.target.value);setPayeeName(e.target.value)}}>
							<option value="">Select Wallet</option>
							<option value="Paytm">Paytm</option>
							<option value="Google Pay">Google Pay</option>
							<option value="PhonePe">PhonePe</option>
							<option value="MobiKwik">MobiKwik</option>
							<option value="Jio Money">Jio Money</option>
							<option value="Amazon Pay">Amazon Pay</option>
							<option value="Others">Others</option>
						</select>
					</div>
					<div className="d-flex gap-2">
						{backBtn && (<>
							<a href="/profiles" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
						</>)}
						<button type="button" onClick={handleSubmit} className="btn btn-sm btn-primary"><i className='bx bx-save' ></i> Submit</button>
					</div>
				</form>
			</div>
		</div>
		<Modal size="lg" show={accountTypeModal} onHide={()=>setAccountTypeModal(false)} aria-labelledby="export-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="export-modal">Choose Product</Modal.Title>
			</Modal.Header>
			<Modal.Body className="py-0">
				<div className="my-3" id="account_section">
					<div className="row">
						<div className="col-lg-6 mb-md-0 mb-6">
							<div className="card1">
								<div className="my-3 pt-2 text-center">
									<img alt="FYNMART Logo" height="65" width="auto" src={props.mart_logo}></img>
								</div>
								<h5><b>FynMart: Community eCommerce</b></h5>
								<p className="small">Your ultimate online store allowing only your customers to Select, Order, Pay with access to exclusive offers and deals from you.
								Free subscription with 500 complimentary credits. Zero commission. Platform fees just 5 Rs/order.</p>
								<div className="dimmer"></div>
								<div className="go-corner" >
								<div className="go-arrow">
									→
								</div>
								</div>
								<div className="hide text-center">
									<button className="btn btn-sm btn-primary" onClick={() => handleFormDataSubmit('fynmart') }>Proceed</button>
								</div>
							</div>
							<div className="text-center">
								<button className="btn p-0" type='button'><a href="https://youtu.be/3_2cZqFaE2Y" target="_blank" rel="noreferrer"><i className='text-danger bx bxl-youtube bx-md'></i></a></button>
							</div>
						</div>
						<div className="col-lg-6 mb-md-0 mb-6">
							<div className="card2">
								<div className="my-3 pt-2 text-center">
									<img alt="FYNONE Logo" height="65" width="auto" src={props.site_logo}></img>
								</div>
								<h5><b>FynOne: Super App for every business</b></h5>
								<p className="small">Your mini ERP covering Billing & E-invoicing, Income & Collections, Expense & Payments, Inventory & Eway bills, Accounting & Tax compliance + FynMart
								Free 21 days trial, basic plan starts at only 124 per month. Unlimited users.</p>
								
								<div className="go-corner">
								<div className="go-arrow">
									→
								</div>
								</div>
								<div className="hide text-center">
									<button className="btn btn-sm btn-primary" onClick={() => handleFormDataSubmit('fynone') }>Proceed</button>
								</div>
							</div>
							<div className="text-center">
								<button className="btn p-0" type='button'><a href="https://youtu.be/uFOhnS8QuEA" target="_blank" rel="noreferrer"><i className='text-danger bx bxl-youtube bx-md'></i></a></button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</>
    );
}