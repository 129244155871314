import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import MartLayout from './MartLayout';
import NavBar from './NavBar';
import MartNavBar from './MartNavBar';
import Select from "react-select";
import * as Common from '../common/ImportUserEssentials';

export default function CustomerEdit(props){
	const [party_name, setPartyName]    = useState('');
	const [phone, setPhone]    = useState('');
	const [gstin, setGstin]    = useState('');
	const [legal_name, setLegalName]    = useState('');
	const [trade_name, setTradeName]    = useState('');
	const [pincode, setPinCode]         = useState('');
	const [stateid,setState]             = useState('');
    const [district,setDistrict]        = useState('');
    const [addr,setAddr]                = useState('');
    const [customer_id,setCustomerID]   = useState('');
	const [email,setEmail]         	 = useState('');
	const [state_master,setStateMaster]    = useState('');
	
	const [incomeColl_w,setincomeColl_w] = React.useState(true);
    const { state }                     = Common.useLocation();
	const navigate                      = Common.useNavigate();

	useEffect(() => {
        if( props.referrer === "fynone" ){
            document.title = 'Edit Customer | '+props.site_name;
		}
		if( props.referrer === "fynmart" ){
			document.title = 'Mart Customer Edit | '+props.site_name;
		}
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Write permission
			setincomeColl_w((userPerm[2]['write'] !== undefined && userPerm[2]['write'] === '0') ? false : true);
		}
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_state_master',
		}).then(function (response) {
			if( response.data.success ){
				setStateMaster(response.data.data);
			}
		}).catch(error => console.log(error));
        Common.axios({
            method: 'post',
            url:props.url+'customer/getCustomerDetails',
            data:{cu_id:state.cu_id,pid:localStorage.getItem('fo_profileid')}
        }).then(function (response) {
            if(response.data.success){
                setCustomerID(response.data.data.id);
                setPartyName(response.data.data.party_name);
                setGstin(response.data.data.gstin);
                setPhone(response.data.data.mobile);
                setLegalName(response.data.data.legal_name);
                setTradeName(response.data.data.trade_name);
                setPinCode(response.data.data.pincode);
                setState(response.data.data.state);
                setDistrict(response.data.data.district);
                setAddr(response.data.data.addr);
                setEmail(response.data.data.email);
            }
        }).catch(error => console.log(error));
	}, [props,state]);
	if( !incomeColl_w ){
		navigate("/not-authorized");
	}
    const handleCustomer = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'customer/edit',
            data: {
                party_name:party_name,legal_name:legal_name,trade_name:trade_name,pincode:pincode,state:stateid,district:district,addr:addr,email:email,pid:localStorage.getItem('fo_profileid'),customer_id:customer_id
            },
        }).then(function (response) {
			if(response.data.success){
                if( props.referrer === "fynmart" ){
					navigate("/mart-customer-listing");
				}else{
					navigate("/customer-listing");
				}
				return false;
			}
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }

    const getPincodeDetails =(val) => {
        let pincode= Common.acceptOnlyNumbers(val);
		setPinCode(pincode);
		if( pincode.length === 6 ){
			Common.axios({
				method: 'post',
				url:props.url+'ajax/getDataByPin',
				data: {pincode:pincode},
			}).then(function (response) {
				if(response.data.success){
					setState(response.data.stateid);
					setDistrict(response.data.district);
				}
			}).catch(error => console.log(error));
		}
    }

    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                {props.referrer === 'fynone' ? 
                <Layout label="edit-customer" site_logo={props.site_logo} site={props.site_name}/>
                :
                <MartLayout label="mart-customer-edit" {...props}/>
                }
                <div className="layout-page">
                    {props.referrer === 'fynone' ? 
                    <NavBar site={props.site_name} url={props.url}/>
					:
					<MartNavBar {...props}/>
					}
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3">{props.referrer === 'fynone' && (<><span className="text-muted fw-light">Income & Collections /</span></>)} Edit Customer
                            <span className="dropdown">
                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className='text-danger bx bxl-youtube bx-md'></i>
                                </button>
                                <div className="dropdown-menu" >
									<a href="https://youtu.be/2iMbFNtVaew" className="dropdown-item" target="_blank" rel="noreferrer"><span>Customer Management</span></a>
                                </div>
                            </span>
                        </h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            <form id="editCustomerFrm" autoComplete="off">
                                                <div className="row">
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="party_name" className="form-label">Customer Name<span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" id="party_name" name="party_name" placeholder="Customer Name" maxLength="100" value={party_name} onChange={(e) => setPartyName(e.target.value)} required/>
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label htmlFor="mobile" className="form-label">Phone No</label>
                                                        <input type="text" className="form-control" id="mobile" name="mobile" value={phone} readOnly />
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="gstin" className="form-label">Gstin</label>
                                                        <input type="text" className="form-control" id="gstin" name="gstin" placeholder="Gstin" maxLength="15" value={gstin} readOnly />
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="legal_name" className="form-label">Legal Name</label>
                                                        <input type="text" className="form-control" id="legal_name" name="legal_name" placeholder="Legal Name" maxLength="100" value={legal_name} onChange={(e) => setLegalName(e.target.value)}/>
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="trade_name" className="form-label">Trade Name</label>
                                                        <input type="text" className="form-control" id="trade_name" name="trade_name" placeholder="Trade Name" maxLength="100" value={trade_name} onChange={(e) => setTradeName(e.target.value)}/>
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="pincode" className="form-label">Pin Code</label>
                                                        <input type="text" className="form-control" id="pincode" name="pincode" placeholder="Pin Code" maxLength="6" value={pincode} onChange={(e) => getPincodeDetails(e.target.value)} inputMode="numeric" />
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="state" className="form-label">State</label>
														<Select id="state" placeholder="Select State" name='state' value={state_master[Common.stateIndex(state_master,stateid)]} options={state_master} onChange={(e) => setState(e.value)}/>
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="district" className="form-label">District</label>
                                                        <input type="text" className="form-control" id="district" name="district" placeholder="District" value={district} onChange={(e) => setDistrict(e.target.value)}/>
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="addr" className="form-label">Address</label>
                                                        <textarea id="addr" name="addr" className="form-control" placeholder="Address" aria-label="Address" aria-describedby="basic-icon-default-message2"  value={addr} onChange={(e) => setAddr(e.target.value)} ></textarea>
                                                    </div>
                                                    <div className="mb-3 col-md-6">
														<label htmlFor="email" className="form-label">Email</label>
														<input type="email" className="form-control" id="email" name="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                    </div>
												</div>
                                                <div className="row ">
													<div className="d-flex gap-2">
                                                        <a href={props.referrer === "fynone" ? "/customer-listing" : "/mart-customer-listing"} role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
														<button className="btn btn-sm btn-primary" onClick={handleCustomer}><span className="tf-icons bx bx-save"></span>&nbsp;<span className="hidden-phone">Save</span></button>
													</div>
												</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}