const payment_modes = [
	{
		label: 'Cash',
		value: 'Cash'
	},
	{
		label: 'UPI/QR',
		value: 'UPI/QR'
	},
	{
		label: 'Credit/Debit Card',
		value: 'Credit/Debit Card'
	},
	{
		label: 'Netbanking',
		value: 'Netbanking'
	},
	{
		label: 'Mobile Wallets',
		value: 'Mobile Wallets'
	},
	{
		label: 'NEFT/RTGS/IMPS',
		value: 'NEFT/RTGS/IMPS'
	},
	{
		label: 'Cheque/DD',
		value: 'Cheque/DD'
	},
	{
		label: 'EMI',
		value: 'EMI'
	},
	{
		label: 'BNPL',
		value: 'BNPL'
	}
];
export default payment_modes;