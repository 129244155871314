import React,{useState}  from "react";
import Layout from './Layout';
import MartLayout from './MartLayout';
import NavBar from './NavBar';
import MartNavBar from './MartNavBar';
import * as Common from '../common/ImportUserEssentials';
import { confirmAlert } from 'react-confirm-alert'; // Import
import * as DTE from '../common/ImportDataTableEssentials';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import TagManager from 'react-gtm-module';

export default function CustomerListing(props){
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
	const [DTpending, setDTPending] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
    const [customer, setCustomerList]    = useState([]);
	const [exportBtnClass,setExportBtnClass]    = React.useState('d-none');
    const [switchChecked, setChecked]           = useState(false);
    const [activeType, setActiveType]           = useState('0');
    const [downloadStatus, setDownloadStatus]   = useState('1');
    const [sortOrder,setSortOrder]              = useState('');
    const navigate = Common.useNavigate();

	// User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	const [incomeColl_w,setincomeColl_w] = React.useState(true);

    React.useEffect(() => {
        if( props.referrer === "fynone" ){
            document.title = 'Customer Listing | '+props.site_name;
		}
		if( props.referrer === "fynmart" ){
			document.title = 'Mart Customer Listing | '+props.site_name;
		}
		if( Object.keys(userPerm).length > 0 ){
			setincomeColl_w((userPerm[2]['write'] !== undefined && userPerm[2]['write'] === '0') ? false : true);
		}
		const hostname = window.location.hostname;
		if( hostname === 'mart.fynone.com' ){
			const tagManagerArgs = {
				gtmId: 'G-RF6E5H5MTZ'
			}

			TagManager.initialize(tagManagerArgs);
		}
		loadDataTable(1,perPage,filterText, sortOrder, '1');
    },[props,filterText,dt_load]);
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText, sortOrder, '1');
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText, sortOrder, '1');
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
        setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection), '1');
	};
    const loadDataTable = (page,perPage,filterText,sort='', status) => {
		Common.axios({
            method: 'post',
            url: props.url+'customer/listing/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText, 'status':status}
        }).then(function (response) {
			setExportBtnClass('d-none');
            if(response.data.success){
                setCustomerList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				if(response.data.total > 0){
					setExportBtnClass('');
				}
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const handleExportCSV = (e)=>{
		e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'customer/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText,'status': downloadStatus},
            // responseType: 'blob',
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Customer-${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click(); */
			} else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
	}
    const columns = [
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                incomeColl_w === true ? row.status == '1' ? 
                <>
                    <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu" >
                            <button className="dropdown-item" onClick={(e) => handleEdit(row.id)}><i className="bx bx-edit"></i> Edit </button>
                            <button className="dropdown-item" onClick={(e) => handleDelete(e, row.id)}><i className="bx bx-trash"></i> Delete </button>
                        </div>
                    </div>
                </> : 
                <>
                    {'Deleted'}
                </> : <></>
            ),
        },
        {
            name: 'Customer Name',
            selector: row => row.party_name,
            sortable: true,
			sortField: 'party_name'
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Gstin',
            selector: row => row.gstin,
            sortable: false,
			sortField: 'gstin'
        },
        {
            name: 'Pincode',
            selector: row => row.pincode,
            sortable: true,
			sortField: 'pincode'
        },
        {
            name: 'State',
            selector: row => row.statename,
            sortable: true,
			sortField: 'statename'
        },
        {
            name: 'District',
            selector: row => row.district,
            sortable: true,
			sortField: 'district'
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
            sortField: 'created_by',
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
            sortField: 'created_on',
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
            sortField: 'updated_by',
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
            sortField: 'updated_on',
        }
    ];
    const handleEdit =(val) => {
        let edit_url = (props.referrer === 'fynone')?'edit-customer':'mart-customer-edit';
        navigate('/'+edit_url,
        {
            state: {
                cu_id: val
            }
        });
    }

    const handleDelete =(e, val) => {
        e.preventDefault();
        confirmAlert({
            title: 'Delete Customer',
            message: 'Are you sure you want to delete?',
            buttons: [{
                label: 'Yes',
                onClick: () => {
                    Common.showLoader(true);
                    Common.axios({
                        method: 'post',
                        url: props.url+'customer/delete',
                        data:{
                            cid:val,pid:localStorage.getItem('fo_profileid')
                        }
                    }).then(function (response) {
                        if(response.data.success){
                            // const rows = customer.filter(item => item.id !== val)
                            // setCustomerList(rows)
                            loadDataTable(1,perPage,filterText, sortOrder, '1');
                            Common.toast.success(response.data.message);
                        } else {
                            Common.toast.error(response.data.message);
                        }
                        Common.showLoader(false);
                    }).catch(error => console.log(error));
                }
            },
            {
                label: 'No',
                onClick: () => {console.log('no')}
            }]
        });
    }
    const handleChange = (e) => {
        switchChecked === true ? setChecked(false) : setChecked(true);
        console.log(switchChecked);
        activeType === '1' ? setActiveType('0') : setActiveType('1');
        switchChecked === true ? setDownloadStatus('1') : setDownloadStatus('0');
        loadDataTable(1,perPage,filterText, sortOrder, activeType);
    }
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                {props.referrer === 'fynone' ? 
                <Layout label="customer-listing" site_logo={props.site_logo} site={props.site_name}/>
                :
                <MartLayout label="mart-customer-listing" {...props}/>
                }
                <div className="layout-page">
                    {props.referrer === 'fynone' ? 
                    <NavBar site={props.site_name} url={props.url}/>
					:
					<MartNavBar {...props}/>
					}
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3">{props.referrer === 'fynone' && (<><span className="text-muted fw-light">Income & Collections /</span></>)} Customer Listing
                                <span className="dropdown">
                                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='text-danger bx bxl-youtube bx-md'></i>
                                    </button>
                                    <div className="dropdown-menu" >
                                        <a href="https://youtu.be/2iMbFNtVaew" className="dropdown-item" target="_blank" rel="noreferrer"><span>Customer Management</span></a>
                                    </div>
                                </span>
                            </h4>
							<div className="row">
								<div className="col-md-6 col-12 mb-1 text-center text-md-start">
									<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
									{incomeColl_w && (<>
										<Common.Link to={props.referrer === "fynone" ? "/new-customer" : "/mart-customer-add"} className="btn btn-sm btn-primary"> <i className="bx bx-plus"></i>Customer</Common.Link>
									</>)}
                                    <label style={{'marginLeft': '0.2rem'}}>
                                        <BootstrapSwitchButton
                                            onChange={handleChange}
                                            checked={switchChecked}
                                            onlabel='Deleted'
                                            onstyle='danger'
                                            offlabel='Active'
                                            offstyle='success'
                                            width='100'
                                            size="sm"
                                        />
                                    </label>
								</div>
								<div className="col-md-6 col-12 text-center text-md-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Party Name, Mobile, Gstin, Pincode, State, District')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
                            <div className="row">
                                <div className="col-md-12">
                                    <DTE.DataTable className="rdt_AdjDrp" title={"Customer ("+totalDataCnt+")"} columns={columns} data={customer} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} persistTableHead/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
       </>
    );
}