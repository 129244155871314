import React, { useState,useEffect } from 'react'
import { Link, useParams  } from 'react-router-dom';
import AccounHeader from './AccountHeader';
import { AES } from 'crypto-js';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import '../styles/page-auth.css';

export default function ResetPassword(props){
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword]     = useState('');
	const [successMsg,setSuccess]  = useState('');
    const [errorMessage,setError] = useState('');
    const [clicked,setClicked] = useState('bx-hide');
    const [clicked1,setClicked1] = useState('bx-hide');
    const {hash} = useParams();	
	const navigate = useNavigate();
	useEffect(() => {
		document.title = 'Reset Password | '+props.site_name;
	}, [props]);
    const showHidePass=(e,id)=>{
        e.preventDefault();
        if(document.getElementById(id).getAttribute('type')==='text'){
           document.getElementById(id).setAttribute('type', 'password');
		   ( id === 'password' ) ? setClicked('bx-hide') : setClicked1('bx-hide');
        }else if(document.getElementById(id).getAttribute('type')==='password'){
           document.getElementById(id).setAttribute('type', 'text');
		   ( id === 'confirm-password' ) ? setClicked('bx-show') : setClicked1('bx-show');
        }
     }
     const handleSubmit =(e) => {
        e.preventDefault();
        let url = props.url+'reset/submit';
        axios({
            method: 'post',
            url:url,
            data: {
                confirm_password: AES.encrypt(confirm_password, props.site_key).toString(),
				password: AES.encrypt(password,props.site_key).toString(),
				hash:hash
            },
        }).then(function (response) {
			if(response.data.error){
				setError(response.data.message);
			} else{
				setSuccess(response.data.message);
				setTimeout(() => {
					navigate("/login");
				}, 3000);
			}
        }).catch(error => console.log(error));
    }
    return(
        <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="card">
              <div className="card-body">
              <AccounHeader site_logo={props.site_logo} site_name={props.site_name} />
              <h4 className="mb-2">Reset Password 🔒</h4>
              <p className="mb-4">Enter your Password</p>
      
                <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit}>
                {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>)}
				{successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
				{successMsg}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
				</div>)}
                  <div className="mb-3 form-password-toggle">
                    <label className="form-label" htmlFor="password">Password</label>
                    <div className="input-group input-group-merge">
                      <input type="password" id="password"  className="form-control" name="password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                        aria-describedby="password" value={password} required onChange={(e) => setPassword(e.target.value)}
                      />
                      <span className="input-group-text cursor-pointer"><i className={'bx '+clicked } onClick={(e) =>showHidePass(e,'password')} ></i></span>
                    </div>
                  </div>
                  <div className="mb-3 form-password-toggle">
                    <label className="form-label" htmlFor="password">Confirm Password</label>
                    <div className="input-group input-group-merge">
                      <input type="password" id="confirm_password"  className="form-control" name="confirm_password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                        aria-describedby="password" value={confirm_password} required onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <span className="input-group-text cursor-pointer"><i className={'bx '+clicked1 } onClick={(e) =>showHidePass(e,'confirm_password')} ></i></span>
                    </div>
                  </div>
                  
                  <button className="btn btn-primary d-grid w-100">Submit</button>
                </form>
      
                <div className="text-center">
                <Link to="/login"  className="d-flex align-items-center justify-content-center">
                  <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                  Back to login
                </Link>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}