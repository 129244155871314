import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import * as Common from '../common/ImportUserEssentials';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

export default function EditBankAccount(props){
	// User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	
	const [payee_name, setPayeeName]                    = useState('');
	const [account_no, setAccountNo]                    = useState('');
	const [ifsc_code, setIfscCode]                      = useState('');
	const [bank_name, setBankName]                      = useState('');
    const [payment_terms,setPaymentTerms]               = useState('');
    const [payment_instructions,setPaymentInstructions] = useState('');
    const [nickname,setNickname]                        = useState('');
    const [bankBranchName,setBankBranchName]            = useState('');
    const [acc_type,setAcctype]                         = useState('current');    
    const [activeType, setActiveType]                   = useState('B');
	const [expire_date, setExpireDate]                  = useState('');
    const { state }                                     = Common.useLocation();
    const [bid,setBid]                                  = useState('');
    	
	const [accountMgmt_w,setaccountMgmt_w] = React.useState(true);
	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Edit Bank Account | '+props.site_name;
		if( userPerm && Object.keys(userPerm).length > 0 ){
			setaccountMgmt_w((userPerm[1]['write'] !== undefined && userPerm[1]['write'] === '0') ? false : true);
		}
        setBid(state.bid);
        Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/details/'+localStorage.getItem('fo_profileid')+'/'+state.bid,
		}).then(function (response) {
			if(response.data.success){
                setPayeeName(response.data.data.payee_name);
                setAccountNo(response.data.data.account_no);
                setIfscCode(response.data.data.ifsc_code);
                setBankName(response.data.data.bank_name);
                setBankBranchName(response.data.data.bank_branch_name);
                setAcctype(response.data.data.acc_type);
                setPaymentTerms(response.data.data.payment_terms);
                setPaymentInstructions(response.data.data.payment_instructions);
                setNickname(response.data.data.nickname);
                setExpireDate(response.data.data.card_expire_date);
                setActiveType(response.data.data.type);
			}
		}).catch(error => console.log(error));
	}, []);
	if( !accountMgmt_w ){
		navigate("/not-authorized");
	}
    const handleSubmit = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'bank-accounts/update',
            data: {
                nickname:nickname,payee_name:payee_name,id:bid,pid:localStorage.getItem('fo_profileid')
            },
        }).then(function (response) {
			if(response.data.success){
                navigate("/bank-accounts");
				return false;
			}
			Common.toast.dismiss();
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    const handleTab = checked => {
		setActiveType(checked);
        if(checked === 'B'){
            setAcctype('current');
        }else{
            setAcctype('');
        }
        if(checked === 'R'){
            setPayeeName('Cash');
            setBankName('Cash');
            setNickname('Cash');
        }else{
            setPayeeName('');
            setBankName('');
            setNickname('');
        }
	};
    const formatString= (value) => {
      
        let output = value.replace(
          /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
        ).replace(
          /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
        ).replace(
          /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
        ).replace(
          /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
        ).replace(
          /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
        ).replace(
          /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
        ).replace(
          /\/\//g, '/' // Prevent entering more than 1 `/`
        );
        setExpireDate(output);
      }

    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="edit-bank-account" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
						<h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Account Management / <a href="/bank-accounts" className="text-muted">Bank Accounts</a> /</span> Edit Bank Account</h4>
                            <div className="row">
                                <div className="col-md-12 col-12 text-center mb-1">
                                    <div className="form-check form-check-inline">
                                        <input id="bank_account" className="form-check-input" type="radio" value="B"  required checked={activeType === 'B'} onChange={(e) => handleTab(e.target.value)} disabled />
                                        <label className="form-check-label" htmlFor="bank_account"> Bank Account</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="credit_card" className="form-check-input" type="radio" value="C" required  checked={activeType === 'C'} onChange={(e) => handleTab(e.target.value)} disabled />
                                        <label className="form-check-label" htmlFor="credit_card"> Credit card</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="prepaid_instruments" className="form-check-input" type="radio" value="P" required  checked={activeType === 'P'} onChange={(e) => handleTab(e.target.value)} disabled />
                                        <label className="form-check-label" htmlFor="prepaid_instruments"> Prepaid Instruments</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="cash" className="form-check-input" type="radio" value="R" required  checked={activeType === 'R'} onChange={(e) => handleTab(e.target.value)} disabled />
                                        <label className="form-check-label" htmlFor="cash"> Cash</label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            {activeType === 'B' && (<>
                                                <form id="formBankAccount" autoComplete="off" onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="payee_name" className="form-label">Account Holder Name<span className="text-danger">*</span></label>
                                                            <input autoFocus type="text" className="form-control" id="payee_name" name="payee_name" placeholder="Max 100 chars" maxLength="100" value={payee_name} onChange={(e) => setPayeeName(Common.acceptOnlyChars(e.target.value))} disabled required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="account_no" className="form-label">Account Number<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="account_no" name="account_no" maxLength="20"  placeholder="Max 20 chars"  value={account_no} onChange={(e) => setAccountNo(Common.acceptOnlyNumbers(e.target.value))} inputMode="numeric" disabled required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="ifsc_code" className="form-label">IFSC Code<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="ifsc_code" name="ifsc_code" placeholder="Max 11 chars" maxLength="11" value={ifsc_code} onChange={(e) => setIfscCode(e.target.value.toUpperCase())} disabled required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="bank_name" className="form-label">Bank Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="bank_name" name="bank_name" placeholder="Max 100 chars" maxLength="100" value={bank_name} onChange={(e) => setBankName(Common.acceptOnlyChars(e.target.value))} disabled required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="bank_branch_name" className="form-label">Bank Branch Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="bank_branch_name" name="bank_branch_name" placeholder="Max 100 chars" maxLength="100" value={bankBranchName} onChange={(e) => setBankBranchName(Common.acceptOnlyCharsNumbers(e.target.value))} disabled required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="acc_type" className="form-label">Account Type<span className="text-danger">*</span></label>
                                                            {/* <input type="text" className="form-control" id="acc_type" name="acc_type" placeholder="Max 100 chars" maxLength="100" value={bankBranchName} onChange={(e) => setBankBranchName(Common.acceptOnlyCharsNumbers(e.target.value))} required/> */}
                                                            <select id="acc_type" className="form-select" name='acc_type'  value={acc_type} onChange={(e) => setAcctype(e.target.value)} disabled required>
                                                                <option value="current" selected={acc_type === 'current'}>Current Account</option>
                                                                <option value="savings" selected={acc_type === 'savings'}>Savings Account</option>
                                                                <option value="salary" selected={acc_type === 'salary'}>Salary Account</option>
                                                                <option value="FD" selected={acc_type === 'FD'}>Fixed Deposit Account</option>
                                                                <option value="RD" selected={acc_type === 'RD'}>Recurring Deposit Account</option>
                                                                <option value="NRI" selected={acc_type === 'NRI'}>NRI Accounts</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="payment_terms" className="form-label">Payment Terms</label>
                                                            <textarea rows="5" maxLength="250" id="payment_terms" name="payment_terms" className="form-control" placeholder="Payment Terms" aria-label="Payment Terms" value={payment_terms} onChange={(e) => setPaymentTerms(e.target.value)} disabled ></textarea>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="payment_instructions" className="form-label">Payment Instruction</label>
                                                            <textarea rows="5" maxLength="250" id="payment_instructions" name="payment_instructions" className="form-control" placeholder="Payment Instructions" aria-label="Payment Instructions" value={payment_instructions} onChange={(e) => setPaymentInstructions(e.target.value)} disabled ></textarea>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="nickname" className="form-label">Nick Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="nickname" name="nickname" placeholder="Max 100 chars" maxLength="100" value={nickname} onChange={(e) => setNickname(Common.acceptOnlyCharsNumbers(e.target.value))} required/>
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="d-flex gap-2">
                                                            <a href="/bank-accounts" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                            <button type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>)}
                                            {activeType === 'C' && (<>
                                                <form id="formBankAccount" autoComplete="off" onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="payee_name" className="form-label">Credit card holder name<span className="text-danger">*</span></label>
                                                            <input autoFocus type="text" className="form-control" id="payee_name" name="payee_name" placeholder="Max 100 chars" maxLength="100" value={payee_name} onChange={(e) => setPayeeName(Common.acceptOnlyChars(e.target.value))} disabled required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="account_no" className="form-label">Credit card number (Last 4 Digits)<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="account_no" name="account_no" minLength="4" maxLength="4"  placeholder="Max 4 chars"  value={account_no} onChange={(e) => setAccountNo(Common.acceptOnlyNumbers(e.target.value))} inputMode="numeric" disabled required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="bank_name" className="form-label">Credit card bank name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="bank_name" name="bank_name" placeholder="Max 100 chars" maxLength="100" value={bank_name} onChange={(e) => setBankName(Common.acceptOnlyChars(e.target.value))} disabled required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="expire_date" className="form-label">Credit card expire date<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="expire_date" name="expire_date" placeholder="MM/YY" maxLength="7" value={expire_date} onChange={(e) => formatString(e.target.value)} disabled required/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="nickname" className="form-label">Nick Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="nickname" name="nickname" placeholder="Max 100 chars" maxLength="100" value={nickname} onChange={(e) => setNickname(Common.acceptOnlyCharsNumbers(e.target.value))} required/>
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="d-flex gap-2">
                                                            <a href="/bank-accounts" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                            <button type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>)}
                                            {activeType === 'P' && (<>
                                                <form id="formBankAccount" autoComplete="off" onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="bank_name" className="form-label">Wallet name<span className="text-danger">*</span></label>
                                                            <select id="bank_name" className="form-select" name='bank_name'  value={bank_name} onChange={(e) => {setBankName(e.target.value);setPayeeName(e.target.value)}} disabled required>
                                                                <option value="">Select Wallet</option>
                                                                <option value="Paytm" selected={bank_name === 'Paytm'}>Paytm</option>
                                                                <option value="" selected={bank_name === 'PhonePe'}>PhonePe</option>
                                                                <option value="MobiKwik" selected={bank_name === 'MobiKwik'}>MobiKwik</option>
                                                                <option value="Jio Money" selected={bank_name === 'Jio Money'}>Jio Money</option>
                                                                <option value="Amazon Pay" selected={bank_name === 'Amazon Pay'}>Amazon Pay</option>
                                                                <option value="Others" selected={bank_name === 'Others'}>Others</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="nickname" className="form-label">Nick Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="nickname" name="nickname" placeholder="Max 100 chars" maxLength="100" value={nickname} onChange={(e) => setNickname(Common.acceptOnlyCharsNumbers(e.target.value))} required/>
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="d-flex gap-2">
                                                            <a href="/bank-accounts" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                            <button type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>)}
                                            
                                            {activeType === 'R' && (<>
                                                <form id="formBankAccount" autoComplete="off" onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="payee_name" className="form-label">Payee name<span className="text-danger">*</span></label>
                                                            <input autoFocus type="text" className="form-control" id="payee_name" name="payee_name" placeholder="Max 100 chars" maxLength="100" value="Cash" required readOnly/>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="nickname" className="form-label">Nick Name<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" id="nickname" name="nickname" placeholder="Max 100 chars" maxLength="100" value="Cash" required readOnly/>
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="d-flex gap-2">
                                                            <a href="/bank-accounts" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                            <button type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}