import React  from "react";
import Layout from './MartLayout';
import NavBar from './MartNavBar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';

export default function MartCategories(props){
	const [totalRows, setTotalRows] = React.useState(0);
	const [itotalRows, setiTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const iperPage = 10;
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [filterText, setFilterText] = React.useState('');
	const [ifilterText, setiFilterText] = React.useState('');
	const [DTpending, setDTPending] = React.useState(true);
	const [dt_load,setDtLoad] = React.useState(true);
	const [sortOrder,setSortOrder] = React.useState('');
	const [categories, setCatList]    = React.useState([]);
	const [items, setItemList]    = React.useState([]);
	const [addModal, setAddModal] = React.useState(false);
	const [addItemModal, setAddItemModal] = React.useState(false);
	const handleViewClose = () => setAddModal(false);

	const [cat_name,setCatName] = React.useState('');
	const [cat_id,setCatId] = React.useState('');
	const [item_columns,setItemColumns] = React.useState([]);
    React.useEffect(() => {
        document.title = 'Categories | '+props.site_name;
		loadDataTable(1,perPage,filterText,sortOrder);
	}, [props,dt_load,filterText,perPage]);
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			loadDataTable(1,perPage,'',sortOrder);
			setFilterText('');
		}
	};
	const loadDataTable = (page,perPage,filterText,sort) => {
		Common.axios({
            method: 'post',
            url:props.url+'mart/listCategory',
			data: {martid:Common.secureLocalStorage.getItem('fo_martid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText}
        }).then(function (response) {
            if(response.data.success){
				setCatList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				return false;
            }

			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const loadItemsTable = (catid,page,iperPage,ifilterText) => {
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'mart/categoryItems/',
			data: {'id':Common.AES.encrypt(catid, props.site_key).toString(),'martid':Common.AES.encrypt(Common.secureLocalStorage.getItem('fo_martid'), props.site_key).toString(),'page':page,'perPage':iperPage,'search':ifilterText}
        }).then(function (response) {
            if(response.data.success){
                setItemList(response.data.data);
				setiTotalRows(response.data.total);
				setAddItemModal(true);
            }
			Common.showLoader(false);
        }).catch(error => console.log(error));
	}
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,sortOrder);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection));
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection));
	};
	const mapCategoryItems = (id,cid,page,param) => {
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url: props.url+'mart/mapCategoryItems',
			data: {martid:Common.AES.encrypt(Common.secureLocalStorage.getItem('fo_martid'), props.site_key).toString(),'id':Common.AES.encrypt(id, props.site_key).toString(),'cid':Common.AES.encrypt(cid, props.site_key).toString(),'param':Common.AES.encrypt(param, props.site_key).toString()}
		}).then(function (response) {
            if(response.data.success){
				Common.showLoader(false);
				Common.toast.success(response.data.message);
				loadItemsTable(cid,page,iperPage,ifilterText);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const modifyItems = (val) => {
		setCatId(val);
		setItemColumns([
			{
				name: 'Action',
				button: true,
				cell: (row) => ( row.cid === null ? <>
						<button title="Add to Category" onClick={(e) => mapCategoryItems(row.id,val,row.page,'add')} className="btn btn-sm btn-success" ><i className='bx bxs-plus-circle'></i></button>
				  </> : <><button title="Remove from Category" onClick={(e) => mapCategoryItems(row.id,val,row.page,'del')} className="btn btn-sm btn-danger" ><i className='bx bx-x-circle'></i></button></>

				),
			},
			{
				name: 'Item Name',
				selector: row => row.name,
				sortable: true,
				sortField: 'name'
			},
			{
				name: 'Item Unit',
				selector: row => row.item_unit ,
				sortable: true,
				sortField: 'item_unit'
			},
			{
				name: 'Sale Item Price / Unit (₹)',
				selector: row => row.sale_price,
				sortField: 'sale_price',
				right: true,
				style: {
					'text-align': 'right',
					'display': 'grid'
				}
			},
			{
				name: 'Sale Total Price (₹)',
				selector: row => row.sale_total_price,
				sortField: 'sale_total_price',
				right: true,
				style: {
					'text-align': 'right',
					'display': 'grid'
				}
			},
			{
				name: 'Purchase Item Price / Unit (₹)',
				selector: row => row.purchase_price,
				sortField: 'purchase_price',
				right: true,
				style: {
					'text-align': 'right',
					'display': 'grid'
				}
			},
			{
				name: 'Purchase Total Price (₹)',
				selector: row => row.purchase_total_price ,
				sortField: 'purchase_total_price',
				right: true,
				style: {
					'text-align': 'right',
					'display': 'grid'
				}
			}
		]);
		loadItemsTable(val,1,iperPage,ifilterText);
	}
	const addCategory = (val) => {
		setCatId(0);
		setCatName('');
		setAddModal(true);
	}
	const editCategory = (val,name) => {
		setCatId(val);
		setCatName(name);
		setAddModal(true);
	}
	const saveCategory = (e) => {
		e.preventDefault();
		let formData = new FormData(e.target);
		formData.append('martid',Common.secureLocalStorage.getItem('fo_martid'));
		let data = {};

		for (var [key, value] of formData.entries()) {
		   data[key] = Common.AES.encrypt(value, props.site_key).toString();
		}
		Common.axios({
			method: 'post',
			url: props.url+'mart/category',
			data: data
		}).then(function (response) {
            if(response.data.success){
				Common.toast.success(response.data.message);
				setCatId(0);
                setAddModal(false);
				loadDataTable(1,perPage,filterText,sortOrder);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const categoryStatus = (checked,id) => {
		Common.axios({
			method: 'post',
			url: props.url+'mart/categoryUpdate',
			data: {martid:Common.AES.encrypt(Common.secureLocalStorage.getItem('fo_martid'), props.site_key).toString(),'status':checked,'id':Common.AES.encrypt(id, props.site_key).toString()}
		}).then(function (response) {
            if(response.data.success){
				Common.toast.success(response.data.message);
				loadDataTable(1,perPage,filterText,sortOrder);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const columns = [
    	{
            name: 'Action',
            button: true,
            cell: (row) => ( 
				<div className="dropdown">
					<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
						<i className="bx bx-dots-vertical-rounded"></i>
					</button>
					<div className="dropdown-menu" >
						<button onClick={(e) => editCategory(row.id,row.name)} className="dropdown-item" ><i className='bx bx-pencil'></i> Edit </button>
						<button onClick={(e) => modifyItems(row.id)} className="dropdown-item" ><i className='bx bx-wrench'></i> Modify Items</button>
					</div>
              	</div>
            ),
        },
		{
			name: 'Status',
			selector: row => row.status,
			sortable: false,
			sortField: 'status',
			cell: (row) => (
				<BootstrapSwitchButton
					checked={(row.status === '1' )}
					onlabel='Active'
					offlabel='Inactive'
					onstyle="success"
					offstyle="danger"
					size="sm"
					width={250}
					onChange={(checked: boolean) => {
						categoryStatus(checked,row.id);
					}}
				/>
			)
		},
        {
            name: 'Category Name',
            selector: row => row.name,
            sortable: true,
			sortField: 'name'
        },
        {
            name: 'Created On',
            selector: row => Common.displayDateTime(row.createdon),
            sortable: true,
			sortField: 'createdon'
        },
        {
            name: 'Created By',
            selector: row => row.createdby_name,
            sortable: true,
			sortField: 'createdby_name'
        },
		{
            name: 'Updated On',
            selector: row => (row.updatedon !== '0000-00-00 00:00:00') ? Common.displayDateTime(row.updatedon) : '-',
            sortable: true,
			sortField: 'updatedon'
        },
		{
            name: 'Updated By',
            selector: row => (row.updatedon !== '0000-00-00 00:00:00') ? row.updatedby_name : '-',
            sortable: true,
			sortField: 'updatedby_name'
        },
    ];

    return (
		<>
		<Modal size={"sm"} show={addModal} onHide={handleViewClose} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>Add Category</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form name="add_category_frm" method="POST" onSubmit={saveCategory} autoComplete="off">
					<input type="hidden" name="id" value={cat_id}/>
					<Common.Row className="mt-1">
						<Common.Col>
							<input type="text" maxLength="20" name="name" className="form-control" placeholder="Enter Category Name" onChange={(e)=>setCatName(Common.acceptOnlyCharsNumbers(e.target.value))} value={cat_name} required/>
							<small className="text-danger">Note: Max 20 characters allowed</small>
						</Common.Col>
					</Common.Row>
					<div className="text-center mt-3">
						<Button type="submit" variant="primary" className="btn-sm"><i className='bx bx-save'></i>Save</Button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
		<Modal size={"lg"} show={addItemModal} onHide={()=>setAddItemModal(false)} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>Modify Items in Category</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="text-center text-sm-start col-sm-6 col-12 mb-1">
						<span className="badge rounded-pill bg-danger me-2">Added</span>
						<span className="badge rounded-pill bg-success">Not Added</span>
					</div>
					<div className="text-end col-sm-6 col-12 mb-1">
						<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Item Name, Item Unit')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
						<DTE.FilterComponent placeholder="Search" onFilter={e => {setiFilterText(e.target.value);loadItemsTable(cat_id,1,iperPage,e.target.value);}} onClear={()=>{setiFilterText('');loadItemsTable(cat_id,1,iperPage,'')}} filterText={ifilterText} />
					</div>
				</div>
				<div className="row">
					<div className="table-responsive text-nowrap">
						<DTE.DataTable className="rdt_AdjDrp" columns={item_columns} data={items} progressPending={DTpending} pagination paginationServer paginationTotalRows={itotalRows} paginationPerPage={iperPage} onChangePage={(pag)=>{loadItemsTable(cat_id,pag,iperPage,ifilterText)}}/>
					</div>
				</div>
			</Modal.Body>
		</Modal>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="mart-categories" {...props}/>
                <div className="layout-page">
                    <NavBar {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3">Categories List
								<span className="dropdown">
									<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
									<i className='text-danger bx bxl-youtube bx-md'></i>
									</button>
									<div className="dropdown-menu" >
										<a href="https://youtu.be/oqny5bdqJok" className="dropdown-item" target="_blank" rel="noreferrer"><span>Categorize products</span></a>
									</div>
								</span>
							</h4>
							<div className="row">
								<div className="col-md-6 col-4 mb-1 text-start">
									<Common.Link onClick={addCategory} className="btn btn-sm btn-primary"> <i className='bx bx-plus'></i><span className="hidden-phone">Category</span></Common.Link>
								</div>
								<div className="col-md-6 col-8 text-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Category Name')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
                            <div className="row">
                                <div className="table-responsive">
                                    <DTE.DataTable className="rdt_AdjDrp" title={"Categories ("+totalDataCnt+")"} columns={columns} data={categories} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} paginationResetDefaultPage={dt_load} onSort={handleSort} sortServer/>
                                </div>
                            </div>
						</div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		</>
    );
}