export const DatePickerPredefined = (moment,start,end) => ({
	"autoApply": true,
	startDate: start.toDate(),
	endDate: end.toDate(),
	/*"maxSpan": {
        "days": 30
    },*/
	ranges: {
		Today: [moment().toDate(), moment().toDate()],
		Yesterday: [
			moment().subtract(1, 'days').toDate(),
			moment().subtract(1, 'days').toDate(),
		],
		'Last 7 Days': [
			moment().subtract(6, 'days').toDate(),
			moment().toDate(),
		],
		'Last 30 Days': [
			moment().subtract(29, 'days').toDate(),
			moment().toDate(),
		],
		'This Month': [
			moment().startOf('month').toDate(),
			moment().endOf('month').toDate(),
		],
		'Last Month': [
			moment().subtract(1, 'month').startOf('month').toDate(),
			moment().subtract(1, 'month').endOf('month').toDate(),
		],
		'Current Financial Year': [
			moment().startOf('year').add(3, 'months').toDate(),
			moment().endOf('year').subtract(9, 'months').add(1, 'years').toDate(),
		],
		'Last One Year': [
			moment().subtract(1, 'years').toDate(),
			moment().toDate(),
		]
	}
});