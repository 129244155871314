import React,{useState}  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import 'react-date-picker/dist/DatePicker.css';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

export default function DocumentManager(props) {
    const [documents, setDocuments] = useState([]);
    const [DTpending, setDTPending] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
    const [dt_load,setDtLoad] = useState(true);
    const [accountMgmt_w,setaccountMgmt_w] = React.useState(true);
    const [exportBtnClass,setExportBtnClass]    = React.useState('');
    const [switchChecked, setChecked]           = useState(false);
    const [activeType, setActiveType]           = useState('0');
    const [downloadStatus, setDownloadStatus]   = useState('1');
    const [sortOrder, setSortOrder]             = useState('');
    const navigate = Common.useNavigate();
    React.useEffect(() => {
        document.title = 'Document Manager | '+props.site_name;
        let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
        if( userPerm && Object.keys(userPerm).length > 0 ){
            setaccountMgmt_w((userPerm[1]['write'] !== undefined && userPerm[1]['write'] === '0') ? false : true);
        }
        loadDataTable(1,perPage,filterText, '', '1');
	}, [props,dt_load,filterText]);
    const loadDataTable = (page,perPage,filterText,sort='', isDocDeleted) => {
		Common.axios({
            method: 'post',
            url:props.url+'bank-accounts/document-manager/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText, 'status': isDocDeleted}
        }).then(function (response) {
            if(response.data.success){
				setDocuments(response.data.data);
				setTotalRows(response.data.total);
                if (response.data.total > 0){
                    setExportBtnClass('');
                } else {
                    setExportBtnClass('d-none');
                }
				setDTPending(false);
            }
        }).catch(error => console.log(error));
	};
    const handlePerRowsChange = async (newPerPage, page) => {
		// loadDataTable(page,newPerPage,filterText, '', '1');
		setPerPage(newPerPage);
	};
    const handlePageChange = page => {
		loadDataTable(page,perPage,filterText, sortOrder, '1');
	};
    const handleSort = (column, sortDirection) => {
        setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection), '1');
	};
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
    
    const editDocument = (val) => {
        navigate('/add-document-manager',{state: {
            rowId: val.id,
            type:'edit',
            doc_type_id:val.doc_type_id
        }});
    }
    const deleteDocument = (e, val) => {
        e.preventDefault();
        confirmAlert({
            title: 'Delete Document',
            message: 'Are you sure you want to delete?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    Common.showLoader(true);

                    let data = {};
                    
                    data['id'] = val;
                    data['pid'] = localStorage.getItem('fo_profileid');
            
                    Common.axios({
                        method: 'post',
                        url:props.url+'bank-accounts/delete-document',
                        data: data,
                    }).then(function (response) {
                        Common.showLoader(false);
                        if(response.data.success){
                            Common.toast.dismiss();
                            if(response.data.success === 1){
                                loadDataTable(1,perPage,filterText, sortOrder, '1');
                                Common.toast.success(response.data.message);
                                return false;
                            }else{
                                Common.toast.error(response.data.message);
                            }
                            return false;
                        } 
                        const err_html = () => (
                            Common.parse(response.data.message)
                        );
                        Common.toast.error(err_html);
                        Common.showLoader(false);
                    }).catch(error => console.log(error));
                }
              },
              {
                label: 'No',
                onClick: () => {console.log('no')}
              }
            ]
          });
    }
    const columns = [
        {
            name: 'Action',
            button: true,
            cell: (row) => (accountMgmt_w === true ? row.is_deleted === '1' ? <>
                <div className="dropdown">
                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="true">
                    <i className="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div className="dropdown-menu datatable_menu " data-popper-placement="bottom-end" >
                        <button onClick={(e) => editDocument(row)}  className="dropdown-item"><i className="bx bx-book-reader me-1"></i>Edit</button>
                        <button onClick={(e) => deleteDocument(e, row.id)}  className="dropdown-item"><i className="bx bx-lock-alt me-1"></i> Delete</button>
                    </div>
                </div>
                </> : <>{'Deleted'}</> : <></>
            )
        },
        {
            name: 'Document Title',
            sortable: true,
			sortField: 'title',
			cell: (row) => (
				row.title + (row.default === '1' ? ' (Default)':'')
			)
        },
        {
            name: 'Document Description',
            selector: row => row.type,
            sortable: false,
			sortField: 'description',
			cell: (row) => (
				row.description
			)
        },
		{
            name: 'Tags',
            selector: row => row.file_path,
            sortable: false,
			sortField: 'path',
			cell: (row) => (
				row.tags
			)
        },
		{
            name: 'Document Type',
            selector: row => row.title,
            sortable: false,
			sortField: 'title',
			cell: (row) => (
				row.name
			)
        },
		{
            name: 'Download Attachment',
            selector: row => row.file_path,
            sortable: false,
			sortField: 'file_path',
			cell: (row) => (
				row.file_path == '' ? ''  : <button type="button" onClick={(e) => handleDocDownload(row.file_path)} className="btn btn-sm btn-primary" >Download</button>
			)
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
            sortField: 'created_by',
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
            sortField: 'created_on',
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
            sortField: 'updated_by',
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
            sortField: 'updated_on',
        }
    ];
    const handleDocDownload = (filePath) => {
        Common.showLoader(true);
        Common.axios({
            method: 'get',
            url:props.url+'bank-accounts/download/'+encodeURIComponent(btoa(filePath)) + '/' + localStorage.getItem('fo_profileid'),
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				Common.toast.dismiss();
                // const url = props.url+response.data.url;
                window.location.href = props.url+response.data.url;
                /* const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.data.file_name);
                document.body.appendChild(link);
                link.click(); */
                return false;
			} 
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    };
    const handleExportCSV = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'bank-accounts/downloadDocuments',
            data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText,'status': downloadStatus},
            // responseType: 'blob',
        }).then(function (response) {
            if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `User-${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click(); */
            } else{
                Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
    }
    const handleChange = (e) => {
        switchChecked === true ? setChecked(false) : setChecked(true);
        activeType === '1' ? setActiveType('0') : setActiveType('1');
        switchChecked === true ? setDownloadStatus('1') : setDownloadStatus('0');
        loadDataTable(1,perPage,filterText, sortOrder, activeType);
    }
    return(
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Layout site_logo={props.site_logo} label="document-manager" site={props.site_name}/>
                    <div className="layout-page">
                        <NavBar site={props.site_name} url={props.url}/>
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Account Management /</span> Document Manager</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <div className="col-md-4 col-6 mb-1"></div>
                                        <div className="col-md-4 col-6 text-right text-md-center mb-1">
                                        </div> */}
                                        <div className="my-2">
                                            <label style={{'marginRight':'0.5rem'}}>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Deleted'
                                                    onstyle='danger'
                                                    offlabel='Active'
                                                    offstyle='success'
                                                    width='100'
                                                    size="sm"
                                                />
                                            </label>
                                            <button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
                                            <a href="/add-document-manager" className="btn btn-sm btn-primary">
                                                <i className='bx bx-plus'></i> <span className="hidden-phone">Document</span>
                                            </a>
											<span className="float-end">
												<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Document Title, Tags')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
												<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
											</span>
                                        </div>
                                        
                                        <DTE.DataTable className="rdt_AdjDrp" title={"Document Manager"} columns={columns}  data={documents} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} persistTableHead/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Common.FYNTicket url={props.url}/>
                    </div>
                </div>
            </div>
        </>
    );
}