import React,{useState,useCallback}  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import styled from 'styled-components';
import * as Common from '../common/ImportUserEssentials';
import Modal from 'react-bootstrap/Modal';
import * as DTE from '../common/ImportDataTableEssentials';
import Select from "react-select";
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import paymentModes from '../masters/PaymentModes.js';
// import deductionList from '../masters/Deductions.js';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;
		
export default function PayableListing(props){
    const { state } = Common.useLocation();
	const [totalDataCnt,setTotalDataCount] = useState({'Pending':'','Paid':'','Advance':'','Other Expense':''});
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
	const [DTpending, setDTPending] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
    const [successMsg,setSucces]                = useState('');
    const [errorMessage,setError]               = useState('');
    const [showInvoiceDiv,setshowInvoiceDiv]    = useState(true);
    const [showIrnDiv,setshowIrnDiv]            = useState(false);
    const [switchChecked, setChecked]           = useState(false);
    const [dueDate, setDueDate]                 = useState('');
    const [dueDateModal, setDueDateModal]       = useState(false);
    const [selectedPurchaseId, setSelectedPurchaseId]   = useState('');
    const PayableTypes                          = ['Pending', 'Paid', 'Advance', 'Other Expense'];
    let tabType = ''
    if (state !== null) {
        tabType = PayableTypes[state.tab_type];
    } else {
        tabType = PayableTypes[0]
    }
    // const [activeTab, setPendingPayables]     = useState(PayableTypes[0]);
    const [activeTab, setPendingPayables]     = useState(tabType);
    const [data, setData]                       = useState([]);
    const [exportBtnClass,setExportBtnClass]    = useState('d-none');
	const [dedModalShow,setDedModalShow]        = useState(false);
	const [temp_deduction,setTempDeduction]     = useState([]);
    const navigate = Common.useNavigate();
	const [totCustomer,setTotCustomer] = useState({'Pending':'-','Paid':'-','Advance':'-','Other Expense':'-'});
	const [totInvAmt,setTotInvAmt] = useState({'Pending':'-','Paid':'-','Advance':'-','Other Expense':'-'});
    const [uploadPaidModal, setUploadPaidModal] = useState(false);
    const [itemDocument,setItemDocument]        = useState('');

    // Cancel Tab
    const [activeCancel, setActiveCancel]       = useState(false);
    const [payableCancelShow,setPayableCancelShow] = useState(false);
	const [payableCancelRemarks,setPayableCancelRemarks] = useState('');
	const [payableId,setPayableId] = useState('');
	const [receiptNo,setReceiptNo] = useState('');
	const [cancelCheckShow, setCancelCheckShow] = useState(false);
	const [cancelCheckMessage, setCancelCheckMessage] = useState('');
    
    const [otherExpenseCancelShow,setOtherExpenseCancelShow] = React.useState(false);
	const [paymentModalShow,setPaymentModalShow] = useState(false);
	const [paymentDetails,setPaymentDetails] 	 = useState([]);
	const [paymentType,setPaymentType] 	 = useState('');
	const [oid,setOid] = useState('');
	const [payment_date,setPaymentDate]             = useState(new Date());
	const [trans_ref,setTransRef]                   = useState('');
	const [payment_remarks,setPaymentRemarks]       = useState('');
	const [bankList, setBankList] 					= useState([]);
	const [bankAccount,setBankAccount] 				= useState(0);
	const [defaultPayee, setDefaultPayee] 			= useState([]);
    const [bank_cash_account,setBankCashAccount] 	= useState(1);
    const [payment_mode, setPaymentMode]            = useState('UPI/QR');
    const [uploadVoucherModal, setUploadVoucherModal] = useState(false);
    const [voucherItemDocument,setvoucherItemDocument] = useState('');
    const [uploadType,setUploadType] = useState('');
    const [deduction_div,setDeductionDiv]           = useState(false);
	const [deduction_amt,setDeductionAmt]           = useState('');
	const [deduction_per,setDeductionPer]           = useState('');
	const [deductions,setDeductions]                = useState([]);
	const [total_deduction,setTotalDeduction]       = useState('0.00');
	const frmSaleDedType                            = React.createRef();
    const frmSaleDedSubtype                         = React.createRef();
	const [taxable_amt,setTaxableAmt]               = useState('');

    //common Model	
	const [commonModel, setCommonModel]    	  		 = useState(false);
	const [commonModelHeading,setCommonModelHeading] = useState('');
	const [commonModelBody,setCommonModelBody] 		 = useState('');
	const [commonModelFooter,setCommonModelFooter] 	 = useState('');
    const [showPayablesDiv,setShowPayablesDiv]          = useState(false);
    const [ShowPendingDiv,setShowPendingDiv]            = useState(true);
    const [showAdvancedDiv,setShowAdvancedDiv]          = useState(false);
    const [showOtherExpenseDiv,setOtherExpenseDiv]      = useState(false);
    const dueDatePriorityList = [{value: 'All', label: 'Dues Priority (All)',description:''},{value: "1", label: "Overdue",description:"Due date < current date"},{value: "2", label: "Immediate due",description:"Due date between 0 to 7 days"},{value: "3", label: "Not yet due",description:"due date > current date + 7"},{value: "4", label: "No priority"}];
	const [defaultDuePriority,setDefaultDuePriority] 	 = useState([{'value': 'All', 'label': 'Dues Priority (All)','digits':''}]);
    const [priorityType, setPriorityType]                = useState('All');

    const [deductionMasterList, setDeductionMasterList]       = useState([]);
	const [deductionMaster, setDeductionMaster]               = useState('');
	const [deductionMasterLabel, setDeductionMasterLabel]     = useState('')

	const [deductionSubtypeMasterList, setDeductionSubtypeMasterList] = useState([]);
	const [deductionSubtypeMaster, setDeductionSubtypeMaster] = useState('')
	const [deductionSubtypeMasterLabel, setDeductionSubtypeMasterLabel] = useState('');
	const [isDeductionMasterSelected, setIsDeductionMasterSelected] = useState(false);
    const [sortOrder,setSortOrder] = React.useState('');
	
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
    const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');

    const [PAdates, setPADates] = React.useState({
		PAstart: moment().subtract(1, 'years'),
		PAend: moment(),
    });
    const { PAstart, PAend } = PAdates;
	const PAlabel = PAstart.format('DD-MM-YYYY') + ' ~ ' + PAend.format('DD-MM-YYYY');
	
    React.useEffect(() => {
        document.title = 'Payable Listing | '+props.site_name;
        if (state !== null) {
            setPendingPayables(PayableTypes[state.tab_type])
            if (state.tab_type === 2) {
                setShowPendingDiv(false);
                setShowPayablesDiv(false);
                setShowAdvancedDiv(true);
                setOtherExpenseDiv(false);
            } else if (state.tab_type === 3) {
                setShowPendingDiv(false);
                setShowPayablesDiv(false);
                setShowAdvancedDiv(false);
                setOtherExpenseDiv(true);
            }
        }
        if( !localStorage.getItem('fo_loggedin') ){
            navigate("/");
        }
        Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
                fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType);
                setBankList(response.data.items);
                setDefaultPayee(response.data.defaultPayee);
                if (response.data.defaultPayee.length > 0) {
                    setBankAccount(response.data.defaultPayee[0].value);
                }
                
			}
		}).catch(error => console.log(error));

        // deduction master
		Common.axios({
			method: 'get',
			url:props.url+'ajax/getDeductionMaster',
		}).then(function (response) {
			if(response.data.success){
				setDeductionMasterList(response.data.data);
			}
		}).catch(error => console.log(error));
    },[props,dt_load,filterText,dates,PAdates]);
	const setDatePicker = (start, end) => {
        setDates({ start, end });
        setDtLoad(!dt_load);
    }
    const PAsetDatePicker = (PAstart, PAend) => {
        setPADates({ PAstart, PAend });
    }
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		fetchPayableData(switchChecked,activeTab,page,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType)
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		fetchPayableData(switchChecked,activeTab,page,newPerPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType)
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
        setSortOrder(btoa(column.sortField+'####'+sortDirection))
		fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,btoa(column.sortField+'####'+sortDirection),activeCancel,PAdates,priorityType);
	};
    const fetchPayableData = useCallback(async (switchChecked, activeTab,page,perPage,dates,filterText='',sort='',activeCancel, PAdates,type='All') => {
        let switch_level = (switchChecked)?'document':'invoice';
        let active_cancel = (activeCancel)?'C':'A';
        Common.axios({
            method: 'POST',
            url:props.url+'payable/listing',
            data:{switch_level:switch_level,active_tab:activeTab,pid:localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText,'dates':dates,'PAdates':PAdates,'active_cancel':active_cancel,'p_type':type}
        }).then(function (response) {
			setExportBtnClass('d-none');
            if(response.data.success){
                setData(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(prevState => ({
					...prevState,
					[activeTab]: response.data.total
				}));
				setDTPending(false);
				if(response.data.data.length > 0){
					setExportBtnClass('d-block');
				}
				setTotCustomer(prevState => ({
				...prevState,
					[activeTab]: response.data.tabInfo.seller
				}));
				setTotInvAmt(prevState => ({
				...prevState,
					[activeTab]: response.data.tabInfo.amount
				}));
            }
        }).catch(error => console.log(error));
     }, [props]);
    const columns = [
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                 <div>
                    <button className="btn p-1 btn-sm btn-light" onClick={(e) => handleEdit(row.payables_id)}><i className="bx bx-edit"></i> </button>
                    <button type="button" className="btn p-1 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-share-alt"></i>
                    </button>
                    <div className="dropdown-menu" >
                        {/* <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.payables_id,'PI',row.inv_num)}><i className="bx bxs-download"></i> Download</button> */}
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.payables_id,1,'PI')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.payables_id,2,'PI')}><i className="bx bx-envelope"></i> Share via Email </button>
                    </div>
                </div>
            ),
        },
        {
            name: 'Priority',
            selector: row =>  parseInt(row.days) < 0 ? <Common.OverlayTrigger overlay={Common.popoverContent('','Over Due')}><i style={{'color' : 'red','fontSize':'1.5rem'}} className='bx bxs-alarm'></i></Common.OverlayTrigger> : parseInt(row.days) >= 0 && parseInt(row.days) <= 7 ? <Common.OverlayTrigger overlay={Common.popoverContent('','Immediate due')}><i style={{'color' : 'orange','fontSize': '1.5rem'}} className='bx bxs-alarm'></i></Common.OverlayTrigger> : parseInt(row.days) > 7 ? <Common.OverlayTrigger overlay={Common.popoverContent('','Not Due Yet')}><i style={{'color' : '#cfcf7e','fontSize':'1.5rem'}} className='bx bxs-alarm'></i></Common.OverlayTrigger> :<></>,
            sortable: false,
			sortField: 'days'
        },
        {
            name: 'Seller Name',
            selector: row => row.client_name,
            sortable: false,
			sortField: 'client_name'
        },
        {
            name: 'Phone Number',
            selector: row => row.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Invoice Amount',
            selector: row => row.amount,
            sortable: true,
			sortField: 'pu.total_amt'
        },
        {
            name: 'Invoice Number',
            selector: row => row.inv_num ,
            sortable: true,
			sortField: 'pu.doc_no'
        },
        {
            name: 'Invoice Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'pu.doc_date'
        },
        {
            name: 'Due Amount(₹)',
            selector: row => row.due_amount,
            sortable: true,
			sortField: 'pu.due_amt'
        },
        {
            name: 'Due Date',
            selector: row => row.days === null ? <button style={{"display":"contents"}} className="btn btn-link" onClick={(e)=>handleDueDate(row.purchase_id)}><i className='bx bx-pencil'></i></button> : row.due_date,
            sortable: true,
			sortField: 'pu.due_date'
        },
        {
            name: 'Paid Amount(₹)',
            selector: row => row.paid_amount,
            sortable: false,
			sortField: 'pa.credit_amount'
        },
    ];
    const docColumns = [
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                <div>
                    <button className="btn btn-sm btn-light" onClick={(e) => handleCustomerEdit(row.seller_id)}><i className="bx bx-edit"></i> </button>
                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false"><i className="bx bx-share-alt"></i></button>
                    <div className="dropdown-menu" >
                        {/* <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.seller_id,'PC',row.client_name)}><i className="bx bxs-download"></i> Download</button> */}
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.seller_id,1,'PC')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.seller_id,2,'PC')}><i className="bx bx-envelope"></i> Share via Email </button>
                    </div>
                </div>
            ),
        },
        {
            name: 'Seller Name',
            selector: rows => rows.client_name,
            sortable: true,
			sortField: 'client_name'
        },
        {
            name: 'Phone Number',
            selector: rows => rows.mobile,
            sortable: false,
			sortField: 've.mobile'
        },
        {
            name: 'Due Amount',
            selector: rows => rows.due_amount,
            sortable: true,
			sortField: 'pu.due_amt'
        },
        {
            name: 'Invoice Count',
            selector: rows => rows.inv_count,
            sortable: false,
			sortField: 'inv_count'
        }
    ];
    const handleEdit =(val) => {
        navigate('/edit-payable',
        {
            state: {
                paid: val
            }
        });
    }
    const handleCustomerEdit =(val) => {
        navigate('/edit-seller-payable',
        {
            state: {
                se_id: val
            }
        });
    }
    const handleShareContent =(val,status,type) => {
        let url = props.url+'payable/shareContent';
        let pid = localStorage.getItem('fo_profileid');
        Common.axios({
            method: 'POST',
            url:url,
            data:{paid:val,status:status,type:type,pid:pid},
        }).then(function (response) {
            if(response.data.success){
                if(status === 1){
                    window.open("https://api.whatsapp.com/send?text="+response.data.data.body,"_blank");
                }else{
                    window.open("mailto:?subject="+response.data.data.subject+"&body="+response.data.data.body,"_blank");
                }
            }
        }).catch(error => console.log(error));
    }
    const handleShareOtherExpenseContent =(val,status) => {
        let url = props.url+'payable/otherExpenseShareContent';
        let pid = localStorage.getItem('fo_profileid');
        Common.axios({
            method: 'POST',
            url:url,
            data:{oid:val,status:status,pid:pid},
        }).then(function (response) {
            if(response.data.success){
                if(status === 1){
                    window.open("https://api.whatsapp.com/send?text="+response.data.data.body,"_blank");
                }else{
                    window.open("mailto:?subject="+response.data.data.subject+"&body="+response.data.data.body,"_blank");
                }
            }
        }).catch(error => console.log(error));
    }
    
    const handleDownloadPdf =(val,type,invNum) => { 
		Common.axios({
			method: 'POST',
			url: props.url+'payable/payableDownloadPDF',
			data:{paid:val,type:type,pid:localStorage.getItem('fo_profileid')},
			// responseType: 'blob',
		}).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
				/* const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
                let tabType = '';
                switch(type){
                    case 'PC':
                        tabType = 'Pending_';
                        break;
                    case 'CC':
                        tabType = 'Paid_';
                        break;
                    case 'AC':
                        tabType = 'Advance_';
                        break;
                    default:
                        tabType = '';

                }
                let currentdate = new Date();
				let timesec = currentdate.getTime().toString().substring(2, 6);
				let fileName = currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() + "_" + timesec;
                link.setAttribute('download', invNum+'_'+tabType+fileName+'.pdf');
				document.body.appendChild(link);
				link.click(); */
			}
			else{
				setError(response.data.message);
			}
		}).catch(error => console.log(error));
    }

    const handleDownloadOtherExpensePdf =(val) => { 
		Common.axios({
			method: 'POST',
			url: props.url+'payable/otherExpensePDF',
			data:{oid:val,pid:localStorage.getItem('fo_profileid')},
		}).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
			}
			else{
				setError(response.data.message);
			}
		}).catch(error => console.log(error));
    }
    const handleDueDate = (purchase_id) => {
        setDueDateModal(true);
        setSelectedPurchaseId(purchase_id);
    }

    const payableColumns = [
        {
            name: 'Seller Name',
            selector: row => row.client_name,
            sortable: false,
			sortField: 'client_name'
        },
        {
            name: 'Phone Number',
            selector: row => row.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Invoice Number',
            selector: row => row.inv_num ,
            sortable: true,
			sortField: 'pu.doc_no'
        },
        {
            name: 'Invoice Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'pu.doc_date'
        },
        {
            name: 'Download Attachment',
            selector: row => row.filepath,
            sortable: false,
			sortField: 'file_path',
			cell: (row) => (
				row.filepath !== '' ? <><button type="button" onClick={(e) => handleDocDownload(row.filepath,row.payables_id)} className="btn btn-sm btn-primary mr-2" >Download</button> <i className='bx bx-trash' onClick={(e) => handleDocDelete(row.filepath,row.payables_id)}></i></> : <button type="button" onClick={(e) => showUploadDocModal(true, row.payables_id)} className="btn btn-sm btn-info" >Upload</button>
			)
        },
        {
            name: 'Paid Amount',
            selector: row => row.paid_amount,
            sortable: true,
			sortField: 'pa.credit_amount'
        },
        {
            name: 'Payment Receipt No',
            selector: row => row.receipt_no,
            sortable: true,
			sortField: 'pa.receipt_no'
        },
        {
            name: 'Payment Mode',
            selector: row => row.payment_mode,
            sortable: false,
			sortField: 'payment_mode'
        },
        {
            name: 'Total Deductions',
            cell: (row) => (
                <Button variant="link" size="sm" className="text-decoration-underline" onClick={() => deductionModal(row.payables_id)}>
                  {row.deduction_amount}
                </Button>
              ),
        },
        {
            name: 'Paid On',
            selector: row => row.payment_received_on,
            sortable: true,
			sortField: 'pa.payment_date'
        },
        {
            name: 'Transaction Ref.',
            selector: row => row.trans_ref,
            sortable: false,
			sortField: 'trans_ref'
        },
        {
            name: 'Remarks',
            selector: row => row.payment_remarks,
            sortable: false,
			sortField: 'payment_remarks'
        },
		{
			name: 'Created By',
			selector: row => row.created_by,
			sortable: false,
			sortField: 'created_by'
		},
		{
			name: 'Created On',
			selector: row => row.created_on,
			sortable: true,
			sortField: 'pa.created_on'
		},
		{
			name: 'Updated By',
			selector: row => row.updated_by,
			sortable: false,
			sortField: 'updated_by'
		},
		{
			name: 'Updated On',
			selector: row => row.updated_on,
			sortable: true,
			sortField: 'pa.updated_on'
		}
    ];
    if( activeCancel ) {
		payableColumns.push(
            {
                name: 'Cancel Reason',
                selector: row => row.cancel_reason,
                sortable: false,
                sortField: 'cancel_reason'
            },
            {
                name: 'Cancel Date',
                selector: row => row.cancel_date,
                sortable: true,
                sortField: 'pa.cancel_date'
            }
        );
	}
    if(!activeCancel){
        payableColumns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => (
                    <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu" >
                            <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.payables_id,'CI',row.receipt_no)}><i className="bx bxs-download"></i> Download</button>
                            <button className="dropdown-item" onClick={(e) => handleCancelPayable(row)}><i className='bx bx-message-square-x'></i> Cancel Payable</button>
                        </div>
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-share-alt"></i>
                        </button>
                        <div className="dropdown-menu" >
                            <button className="dropdown-item" onClick={(e) => handleShareContent(row.payables_id,1,'CI')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                            <button className="dropdown-item" onClick={(e) => handleShareContent(row.payables_id,2,'CI')}><i className="bx bx-envelope"></i> Share via Email </button>
                        </div>
                    </div>
                ),
            },  
        );
    }
    const payableDocColumns = [
        {
            name: 'Seller Name',
            selector: rows => rows.client_name,
            sortable: true,
			sortField: 've.party_name'
        },
        {
            name: 'Phone Number',
            selector: rows => rows.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Paid Amount',
            selector: rows => rows.paid_amount,
            sortable: true,
			sortField: 'paid_amount'
        },
        {
            name: 'Invoice Count',
            selector: rows => rows.inv_count,
            sortable: false,
			sortField: 'inv_count'
        }
    ];
    if(!activeCancel){
        payableDocColumns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => (
                    <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-share-alt"></i>
                        </button>
                        <div className="dropdown-menu" >
                        <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.seller_id,'CC',row.client_name)}><i className="bx bxs-download"></i> Download</button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.seller_id,1,'CC')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.seller_id,2,'CC')}><i className="bx bx-envelope"></i> Share via Email </button>
                        </div>
                    </div>
                ),
            }
        );
    }
    const advancedColumns = [
        {
            name: 'Seller Name',
            selector: row => row.client_name,
            sortable: false,
			sortField: 'client_name'
        },
        {
            name: 'Phone Number',
            selector: row => row.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Advance Amount',
            selector: row => row.advance_amount,
            sortable: true,
			sortField: 'pa.advance_amount'
        },
        {
            name: 'Advance Receipt No',
            selector: row => row.receipt_no,
            sortable: true,
			sortField: 'pa.receipt_no'
        },
        {
            name: 'Payment Mode',
            selector: row => row.payment_mode,
            sortable: false,
			sortField: 'payment_mode'
        },
        {
            name: 'Paid On',
            selector: row => row.payment_received_on,
            sortable: true,
			sortField: 'pa.payment_date'
        },
        {
            name: 'Transaction Ref.',
            selector: row => row.trans_ref,
            sortable: false,
			sortField: 'trans_ref'
        },
        {
            name: 'Remarks',
            selector: row => row.payment_remarks,
            sortable: false,
			sortField: 'remarks'
        },
		{
			name: 'Created By',
			selector: row => row.created_by,
			sortable: false,
			sortField: 'created_by'
		},
		{
			name: 'Created On',
			selector: row => row.created_on,
			sortable: true,
			sortField: 'pa.created_on'
		}
    ];
    if( activeCancel ) {
		advancedColumns.push(
            {
                name: 'Updated By',
                selector: row => row.updated_by,
                sortable: false,
                sortField: 'updated_by'
            },
            {
                name: 'Updated On',
                selector: row => row.updated_on,
                sortable: true,
                sortField: 'pa.updated_on'
            },
            {
                name: 'Cancel Reason',
                selector: row => row.cancel_reason,
                sortable: false,
                sortField: 'cancel_reason'
            },
            {
                name: 'Cancel Date',
                selector: row => row.cancel_date,
                sortable: true,
                sortField: 'pa.cancel_date'
            }
        );
	}
    if(!activeCancel){
        advancedColumns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => (
                    <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu" >
                            <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.payables_id,'AR',row.receipt_no)}><i className="bx bxs-download"></i> Download</button>
                            <button className="dropdown-item" onClick={(e) => handleCancelPayable(row)}><i className='bx bx-message-square-x'></i> Cancel Payable</button>
                        </div>
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-share-alt"></i>
                        </button>
                        <div className="dropdown-menu" >
                            <button className="dropdown-item" onClick={(e) => handleShareContent(row.payables_id,1,'AR')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                            <button className="dropdown-item" onClick={(e) => handleShareContent(row.payables_id,2,'AR')}><i className="bx bx-envelope"></i> Share via Email </button>
                        </div>
                    </div>
                ),
            }
        );
    }
    const advancedDocColumns = [
        {
            name: 'Seller Name',
            selector: row => row.client_name,
            sortable: true,
			sortField: 've.party_name'
        },
        {
            name: 'Phone Number',
            selector: row => row.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Advance Amount',
            selector: row => row.advance_amount,
            sortable: true,
			sortField: 'advance_amount'
        },
        {
            name: 'Receipt Count',
            selector: row => row.receipt_count,
            sortable: true,
			sortField: 'receipt_count'
        },
    ];
    if(!activeCancel){
        advancedDocColumns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => (
                    <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-share-alt"></i>
                        </button>
                        <div className="dropdown-menu" >
                        <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.seller_id,'AC',row.client_name)}><i className="bx bxs-download"></i> Download</button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.seller_id,1,'AC')}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                        <button className="dropdown-item" onClick={(e) => handleShareContent(row.seller_id,2,'AC')}><i className="bx bx-envelope"></i> Share via Email </button>
                        </div>
                    </div>
                ),
            }
        );
    }
    const otherExpenseColumns = [
        {
            name: 'Status',
            selector: row => row.payment_amt,
            sortable: false,
            sortField: 'income_type',
            cell: (row) => (
                row.payment_amt === null ? <Common.OverlayTrigger overlay={Common.popoverContent('','Payment Due')}><i className='bx bxs-calendar-exclamation' style={{'color': 'red'}}></i></Common.OverlayTrigger> : ''
            )
        },
        {
            name: 'Expense Type',
            selector: row => row.expenses_type,
            sortable: false,
			sortField: 'expenses_type'
        },
        {
            name: 'Expense Sub Type',
            selector: row => row.expenses_sub_type,
            sortable: false,
			sortField: 'expenses_sub_type'
        },
        {
            name: 'Voucher Number',
            selector: row => row.voucher_no,
            sortable: true,
			sortField: 'oe.voucher_no'
        },
        {
            name: 'Voucher Date',
            selector: row => row.voucher_date,
            sortable: true,
			sortField: 'oe.voucher_date'
        },
        {
            name: 'Voucher Remarks',
            selector: row => row.voucher_remarks,
            sortable: false,
			sortField: 'voucher_remarks'
        },
        {
            name: 'Taxable Amount',
            selector: row => row.taxable_amt,
            sortable: true,
			sortField: 'oe.taxable_amt'
        },
        {
            name: 'GST Amount',
            selector: row => row.gst_amt,
            sortable: true,
			sortField: 'oe.gst_amt'
        },
        {
            name: 'Total Amount',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'oe.total_amt'
        },
        {
            name: 'Paid Amount',
            selector: row => row.payment_amt,
            sortable: false,
			center: true,
			cell: (row) => (
				( row.payment_amt !== null ) ? <Button variant="link" size="sm" className="text-decoration-underline" onClick={() => paymentModal(row,1)}>{row.payment_amt}</Button> : ((!activeCancel) ?<Button variant="primary" size="sm" onClick={(e)=>paymentModal(row,2)}>Pay</Button>:'')
			)
        },
        {
            name: 'Deduction Amount',
            selector: row => row.deduction_amt,
            sortable: false,
			center: true,
			cell: (row) => (
				( row.deduction_amt !== null && row.deduction_amt !== '0.00') ? <Button variant="link" size="sm" className="text-decoration-underline" onClick={() => deductionModal(row.id,1)}>{row.deduction_amt}</Button> :''
			)
        },
        {
            name: 'Pre Payment',
            selector: row => row.voucher_level_file_path,
            sortable: false,
			sortField: 'voucher_level_file_path',
			cell: (row) => (
				row.voucher_level_file_path !== null ? <><button type="button"  style={{"width": "17rem"}} onClick={(e) => handleVoucherDocDownload(row.voucher_level_file_path,row.id,1)} className="btn btn-sm btn-primary">Download</button> <i className='bx bx-trash' onClick={(e) => handleVoucherDocDelete(row.voucher_level_file_path,row.id,1)}></i></> : <button type="button" onClick={(e) => showUploadVoucherDocModal(true, row.id,1)} className="btn btn-sm btn-info" >Upload</button>
			)
        },
        {
            name: 'Post Payment',
            selector: row => row.payment_level_file_path,
            sortable: false,
			sortField: 'payment_level_file_path',
			cell: (row) => (
				row.payment_level_file_path !== null ? <><button type="button"  style={{"width": "17rem"}} onClick={(e) => handleVoucherDocDownload(row.payment_level_file_path,row.id,2)} className="btn btn-sm btn-primary">Download</button> <i className='bx bx-trash' onClick={(e) => handleVoucherDocDelete(row.payment_level_file_path,row.id,2)}></i></> : <button type="button" onClick={(e) => showUploadVoucherDocModal(true, row.id,2)} className="btn btn-sm btn-info" >Upload</button>
			)
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
            sortField: 'created_on'
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
            sortField: 'oe.created_on'
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
            sortField: 'updated_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
            sortField: 'oe.updated_on'
        }
    ];
    if( activeCancel ) {
		otherExpenseColumns.push(
            {
                name: 'Cancel Reason',
                selector: row => row.cancel_reason,
                sortable: true,
                sortField: 'cancel_reason'
            },
            {
                name: 'Cancel Date',
                selector: row => row.cancel_date,
                sortable: true,
                sortField: 'cancel_date'
            }
        );
	}
    if(!activeCancel){
        otherExpenseColumns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => (
                    <div className="dropdown">
                        <button type="button" className="btn me-2 p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu" >
                            <button className="dropdown-item" onClick={(e) => handleDownloadOtherExpensePdf(row.id)}><i className="bx bxs-download"></i> Download Voucher</button>
                            <button className="dropdown-item" onClick={(e) => handleCancelOtherExpense(row)}><i className='bx bx-message-square-x'></i> Cancel Other Expense</button>
                        </div>
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-share-alt"></i>
                        </button>
                        <div className="dropdown-menu" >
                            <button className="dropdown-item" onClick={(e) => handleShareOtherExpenseContent(row.id,1)}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                            <button className="dropdown-item" onClick={(e) => handleShareOtherExpenseContent(row.id,2)}><i className="bx bx-envelope"></i> Share via Email </button>
                        </div>
                    </div>
                ),
            }
        );
    }
    const handleVoucherDocDelete = (filepath, id, type) => {
        confirmAlert({
            title: 'Delete Document',
            message: 'Are you sure you want to delete?',
            buttons: [{
                    label: 'Yes',
                    onClick: () => {
                        Common.showLoader(true);
                        Common.axios({
                            method: 'post',
                            url:props.url+'payable/del_other_expens_document/',
                            data: {
                                filepath:filepath,
                                oid:id,
                                type:type
                            }
                        }).then(function (response) {
                            Common.showLoader(false);
                            if(response.data.success){
								fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType);
                                Common.toast.success(response.data.message);
                            } 
                            Common.toast.dismiss();
                        }).catch(error => console.log(error));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log('no')
                    }
                }
            ]
        });
    }
	const showUploadVoucherDocModal = (flag, id, type) => {
        setOid(id);
        setUploadType(type);
        if (flag === false)
            setUploadVoucherModal(false);
        else
            setUploadVoucherModal(true);
    }
	const handleVoucherDocument = (e) => {
        const fileTypes = ['application/pdf', 'image/jpeg', 'image/jpg','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/png', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (e.target.files[0] !== undefined) {
            if (!fileTypes.includes(e.target.files[0].type)) {
                Common.toast.error('File format not supported');
                e.target.value = null;
                return false;
            } else {
                if (e.target.files[0].size > (5 * 1048576)) {
                    Common.toast.error('File size should be less than 5MB');
                    e.target.value = null;
                    return false;
                } else {
                    setvoucherItemDocument(e.target.files[0]);
                }
            }
        }
        Common.toast.dismiss();
    };
	const uploadVoucherDocument = (e) => {
        let fileData = new FormData();
        fileData.append('files', voucherItemDocument);
        fileData.append('pid', localStorage.getItem('fo_profileid'));
        fileData.append('oid', oid);
        fileData.append('uploadType', uploadType);
        Common.axios.post(props.url+'payable/upload_voucher_file', fileData, {
            mode: 'cors',
            headers: { 'Content-Type':  'multipart/form-data' }
        }).then(function (response) {
            setUploadVoucherModal(false);
			fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType);
        }).catch(error => console.log(error));
    }
	const handleVoucherDocDownload = (filePath) => {
        Common.showLoader(true);
        Common.axios({
            method: 'get',
            url:props.url+'payable/voucher_download/'+encodeURIComponent(btoa(filePath)) + '/' + localStorage.getItem('fo_profileid') + '/' + oid,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				Common.toast.dismiss();
                const url = props.url+response.data.url;
                console.log(url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.data.file_name);
                document.body.appendChild(link);
                link.click();
                return false;
			} 
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    };

    const showUploadDocModal = (flag, payables_id) => {
        setPayableId(payables_id)
        if (flag === false)
            setUploadPaidModal(false);
        else
            setUploadPaidModal(true);
    }
    const handleExportCSV = (e)=>{
        e.preventDefault();
        let switch_level = (switchChecked)?'document':'invoice';
        let active_cancel = (activeCancel)?'C':'A';
        Common.axios({
            method: 'post',
            url:props.url+'payable/download_csv',
			data:{switch_level:switch_level,active_tab:activeTab,pid:localStorage.getItem('fo_profileid'),'search':filterText,'dates':dates,'PAdates':PAdates,'active_cancel':active_cancel,'p_type':priorityType},
            // responseType: 'blob',
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Payables-${activeTab}-${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click(); */
			} else{
                setError(response.data.message);
            }

		    setTimeout(() => {
				setError('');
				setSucces('');
			}, 5000);
        }).catch(error => console.log(error));
    }

    const handleCancelPayable = (details)=>{
        setPayableId(details.payables_id);
        setReceiptNo(details.receipt_no);
        setPayableCancelRemarks('');
        setPayableCancelShow(true);
    }
    const handleCancelCln = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['payableId'] = payableId;
		data['pid']       = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'payable/cancelPayable',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setPayableCancelShow(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
					fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType);
					return false;
                }
                else if(response.data.success === 2){
                    setCancelCheckMessage(response.data.message);
				    setCancelCheckShow(true);
					return false;
                }
                else{
                    Common.toast.error(response.data.message);
                }
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }

    const handleCancelOtherExpense = (details)=>{
        setOid(details.id);
        setReceiptNo(details.voucher_no);
        setPayableCancelRemarks('');
        Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'payable/checkCancelEMI',
            data: {oid:details.id,pid:localStorage.getItem('fo_profileid')},
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
                setOtherExpenseCancelShow(true);
                return false;
			}
            else{
                setCommonModel(true);
                setCommonModelHeading('Other Expenses');
                setCommonModelBody(<div className="row"><div className="col-sm-12"><p>{response.data.message}</p></div></div>);
                setCommonModelFooter(<Button variant="danger" onClick={()=>setCommonModel(false)}>
				Close
			</Button>);
                return false;
            }
        }).catch(error => console.log(error));
    }
    const handleCancelOtherIn = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['vid'] = oid;
		data['pid'] = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'payable/cancelOtherExpense',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
                setOtherExpenseCancelShow(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
					fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType);
					return false;
                }
                else{
                    Common.toast.error(response.data.message);
                }
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    const handleDueDateModalClose = () => {
        setDueDate('');
        setSelectedPurchaseId('');
        setDueDateModal(false);
    }
    const handleDueDateUpdate = () => {
        Common.axios({
            method: 'post',
            url: props.url+'purchase/update-inv-due-date',
            data: {id:selectedPurchaseId,due_date:dueDate}
        }).then(function (response) {
            if(response.data.success){
                setDueDateModal(false)
                setDueDate('');
                setSelectedPurchaseId('')
                fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType);
                Common.toast.success(response.data.message);
                return false;
            }
            Common.toast.dismiss();
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }

    /* const [showPayablesDiv,setShowPayablesDiv]          = useState(false);
    const [ShowPendingDiv,setShowPendingDiv]            = useState(true);
    const [showAdvancedDiv,setShowAdvancedDiv]          = useState(false);
    const [showOtherExpenseDiv,setOtherExpenseDiv]      = useState(false); */
    function TabGroup() {
        const handleTab = penColChecked => {
            setPendingPayables(penColChecked);
            setActiveCancel(false);
            if (state !== null) {
                state.tab_type = ''
                window.history.replaceState({}, '')
            }
            setDefaultDuePriority([{'value': 'All', 'label': 'Dues Priority (All)','digits':''}]);
            setPriorityType('All')
            setSortOrder('');
            fetchPayableData(switchChecked,penColChecked,1,perPage,dates,filterText,'',false,PAdates,'All')
            if(penColChecked === "Paid"){
                setShowPayablesDiv(true);
                setShowPendingDiv(false);
                setShowAdvancedDiv(false);
                setOtherExpenseDiv(false);
            }else if(penColChecked === "Pending"){
                setShowPayablesDiv(false);
                setShowPendingDiv(true);
                setShowAdvancedDiv(false);
                setOtherExpenseDiv(false);
            }
            else if(penColChecked === "Advance"){
                setShowPendingDiv(false);
                setShowPayablesDiv(false);
                setShowAdvancedDiv(true);
                setOtherExpenseDiv(false);
            }else{
                setShowPendingDiv(false);
                setShowPayablesDiv(false);
                setShowAdvancedDiv(false);
                setOtherExpenseDiv(true);
            }
        };
        
        return (
            <>
            <ButtonGroup className="tabWithInfo">
                {PayableTypes.map(type => (
                <Tab
                    key={type}
                    active={activeTab === type}
                    onClick={() => handleTab(type)}
                >
                    {(totalDataCnt[type]!=='') ? type + ' ('+totalDataCnt[type]+')' : type}
                    <div className="tabAdditionalInfo">
                        {/* Seller.# {totCustomer[type]} | Tot. Amt.(₹) {Common.numToWords(totInvAmt[type])} */}
                        {(type !== 'Other Expense')?'Seller.#'+ totCustomer[type] + '|':''} Tot. Amt.(₹) {Common.numToWords(totInvAmt[type])}
                    </div>
                </Tab>
                ))}
            </ButtonGroup>
            </>
        );
    }
    const handleChange = nextChecked => {
        setChecked(nextChecked);
        fetchPayableData(nextChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType)
        if(nextChecked){
            setshowIrnDiv(true);
            setshowInvoiceDiv(false);
        }else{
            setshowInvoiceDiv(true);
            setshowIrnDiv(false);
        }
    };
    const handleActiveCancel = nextChecked => {
        setActiveCancel(nextChecked);
        fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,nextChecked,PAdates,priorityType);
    };
    const getDueDatePriorities = (e) => {
        let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		if(value){
			setPriorityType(value);
			setDefaultDuePriority([{'value':value,'label':label,'digits':''}]);
            fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,value);
		}else{
            setBankAccount('');
			setDefaultDuePriority([]);
            fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,'');
		}
    }

    const deductionModal = (cl_id,type = 0) => {
        let url = props.url+'payable/getDeduction/'+cl_id;
        if(type === 1){
            url = props.url+'payable/getOtherExpenseDeduction/'+cl_id;
        }
        Common.axios({
            method: 'get',
            url: url,
            // data:{cl_id:cl_id}
        }).then(function (response) {
            if(response.data.success){
                setTempDeduction(response.data.data);
                setDedModalShow(true);
            }
        }).catch(error => console.log(error));
    }
    const handleDocument = (e) => {
        const fileTypes = ['application/pdf', 'image/jpeg', 'image/jpg','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/png', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (e.target.files[0] !== undefined) {
            if (!fileTypes.includes(e.target.files[0].type)) {
                Common.toast.error('File format not supported');
                e.target.value = null;
                return false;
            } else {
                if (e.target.files[0].size > (5 * 1048576)) {
                    Common.toast.error('File size should be less than 5MB');
                    e.target.value = null;
                    return false;
                } else {
                    setItemDocument(e.target.files[0]);
                }
            }
        }
        // Common.toast.dismiss();
    };
    const uploadDocument = (e) => {
        let fileData = new FormData();
        fileData.append('files', itemDocument);
        fileData.append('pid', localStorage.getItem('fo_profileid'));
        fileData.append('payable_id', payableId);
        fileData.append('from_datatable', '1');
        Common.axios.post(props.url+'payable/upload_file', fileData, {
            mode: 'cors',
            headers: { 'Content-Type':  'multipart/form-data' }
        }).then(function (response) {
            setUploadPaidModal(false);
            fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType);
            
        }).catch(error => console.log(error));
    }
    const handleDocDownload = (filePath, payablesId) => {
        Common.showLoader(true);
        Common.axios({
            method: 'get',
            url:props.url+'payable/doc_download/'+encodeURIComponent(btoa(filePath)) + '/' + localStorage.getItem('fo_profileid') + '/' + payablesId,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				Common.toast.dismiss();
                // window.location.href = props.url+response.data.url;
                const url = props.url+response.data.url;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.data.file_name);
                document.body.appendChild(link);
                link.click();
                return false;
			} 
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    };
    const handleDocDelete = (filepath, payablesId) => {
        confirmAlert({
            title: 'Delete Document',
            message: 'Are you sure you want to delete?',
            buttons: [{
                    label: 'Yes',
                    onClick: () => {
                        Common.showLoader(true);
                        Common.axios({
                            method: 'post',
                            url:props.url+'payable/del_document/',
                            data: {
                                filepath:filepath,
                                payables_id:payablesId
                            }
                        }).then(function (response) {
                            Common.showLoader(false);
                            if(response.data.success){
                                fetchPayableData(switchChecked,activeTab,1,perPage,dates,filterText,sortOrder,activeCancel,PAdates,priorityType);
                                Common.toast.success(response.data.message);
                            } 
                            Common.toast.dismiss();
                        }).catch(error => console.log(error));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log('no')
                    }
                }
            ]
        });
    }
    const paymentModal = (details,type) => {
        setPaymentType(type);
        setPaymentDetails(details);
        setOid(details.id);
        setTaxableAmt(details.taxable_amt);
        setPaymentModalShow(true);
        setDeductions([]);
        setDeductionDiv(false)
    }
    const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setBankAccount(value);
		// setDefaultPayee('');
        if (value === '') {
			setDefaultPayee([]);
		} else {
			bankList.forEach((bank,i) => {
				if (bank.value === value) {
					setDefaultPayee([bank]);
				}
			});
		}
	}
    const setPaymentModeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setPaymentMode(value);
		if(value === 'Cash'){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/'+value,
			}).then(function (response) {
				if(response.data.success){
					if(response.data.data !== ''){
						setBankCashAccount(1);
						setDefaultPayee(response.data.data);
						setBankAccount(response.data.data[0].value);
                        setBankList(response.data.data);
					}else{
						setBankCashAccount(0);
						setDefaultPayee([]);
						setBankAccount(0);
					}
				}
			}).catch(error => console.log(error));
		}else{
			setBankCashAccount(1);
			setDefaultPayee([]);
            setListOfBanks();
		}
	}
    const setListOfBanks = () => {
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
			}
		}).catch(error => console.log(error));
	}
    const handlePayment =(e) => {
		e.preventDefault();		
		if( payment_mode === '' ){
			Common.toast.error('Payment Mode is required');
			return false;
		}
        // payment_mode !== 'Cash' &&
		if( bankAccount === undefined || bankAccount === 0 || bankAccount === '' ){
			Common.toast.error('Bank Account is required.');
			return false;
		}
		let formData = new FormData(e.target);
		let pid = localStorage.getItem('fo_profileid');
		formData.append('pid', pid);
		formData.append('oid', oid);
        formData.append('payment_mode', payment_mode);
        formData.append('bankAccount', bankAccount);
        formData.append('deduction_amount', total_deduction);
		formData.append('deductions', JSON.stringify(deductions));
		let data = {};
		// convert the key/value pairs
		for (var [key, value] of formData.entries()) {
		   data[key] = value;
		}

		Common.axios({
			method: 'post',
			url: props.url+'payable/addOtherExpensePayment',
			data: data
		}).then(function (response) {
			if(response.data.success){
                setPaymentModalShow(false);
                setListOfBanks();
                setTotalDeduction('0.00')
                setDeductions([]);
                setDeductionDiv(false)
				fetchPayableData(switchChecked,'Other Expense',1,perPage,dates,filterText,sortOrder,false,PAdates,priorityType)
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
    const calcDeduction = (param,val) => {
		let percent = 0,amount = 0;
		if( param === 'per' ){
			percent = val;
			amount = (taxable_amt * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/taxable_amt * 100).toFixed(2);
		}
		setDeductionPer(percent);
		setDeductionAmt(amount);
	}
	// const deductionModal = () => {
	// 	setDeductionAmt('');setDeductionPer('');setDeductionRemarks('');
	// 	setDeductionModel(true);
	// }
	const setDeductionTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		setDeductionMaster(value);
		setDeductionMasterLabel(label)
		if (value === '') {
			setIsDeductionMasterSelected(false);
			setDeductionSubtypeMasterList([])
		} else {
			setIsDeductionMasterSelected(true);
		}
		if (value !== '') {
			Common.axios({
				method: 'post',
				url: props.url+'ajax/getDeductionSubtypeMaster',
				data: {
					'pid': localStorage.getItem('fo_profileid'),
					'deduction_master_id':value
				}
			}).then(function (response) {
				if( response.data.success ){
					console.info(response.data.data)
					let dedSubtypeList = response.data.data;
					if (dedSubtypeList.length > 0) {
						setDeductionSubtypeMasterList(response.data.data);
					}
				}
			}).catch(error => console.log(error));
		} else {
			setDeductionSubtypeMaster(value);
			frmSaleDedSubtype.current.clear();
			setDeductionSubtypeMasterList([])
		}
	}
	const setDeductionSubTypeSelect = (e) => {
        if (e.length > 0) {
            setDeductionSubtypeMaster(e[0].label);
            setDeductionSubtypeMasterLabel(e[0].label)
        }
	}
	const addDeduction = () => {
		if( deduction_per === undefined || deduction_per === '' || deduction_per > 100 ){
			Common.toast.error('Deduction percent is invalid.');
			return false;
		}
		if( deduction_amt === undefined || deduction_amt === '' || Number(deduction_amt) === 0 ){
			Common.toast.error('Deduction amount should be greater than zero.');
			return false;
		}

		if( deductionMasterLabel === undefined || deductionMasterLabel === '' ){
			Common.toast.error('Deduction type is required.');
			return false;
		}

		if( Number(total_deduction) + Number(deduction_amt) > Number(taxable_amt) ){
			Common.toast.error('Total deduction amount cannot be greater than total taxable value.');
			return false;
		}
		let ded = {};
		ded['deduction_per'] = deduction_per;
		setDeductionPer('');
		ded['deduction_amt'] = deduction_amt;
		setDeductionAmt('');
		ded['deduction_type'] = deductionMasterLabel;
		frmSaleDedType.current.clear();
		ded['deduction_subtype'] = deductionSubtypeMasterLabel;
		frmSaleDedSubtype.current.clear();
		ded['deduction_master_id'] = deductionMaster
		ded['deduction_subtype_label'] = deductionSubtypeMaster;
		// ded['deduction_remarks'] = deduction_remarks;
		// setDeductionRemarks('');

		setDeductions([...deductions, ded]);
		let tot_deduction = (Number(total_deduction) + Number(deduction_amt)).toFixed(2);
		setTotalDeduction(tot_deduction);
        setDeductionSubtypeMasterList([])
		setDeductionSubtypeMasterLabel('');
		setIsDeductionMasterSelected(false)
	}
	const removeDeduction = (index) => {
        let price = deductions[index].deduction_amt;
		setDeductions(deductions.filter((item,key) => key !== index));
        let tot_deduction = (Number(total_deduction) - Number(price)).toFixed(2);
		setTotalDeduction(tot_deduction);
	}

  return (
    <>
    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
            <Layout label="payable-listing" site_logo={props.site_logo} site={props.site_name}/>
            <div className="layout-page">
                <NavBar site={props.site_name} url={props.url}/>
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Expenses & Payments /</span> Payables Listing</h4>
                        <div className="row pb-3">
                            <div className="col-md-8" id="collection_tab">
                                <TabGroup/>
                            </div>
                        </div>
                        {ShowPendingDiv && (<>
                            {showInvoiceDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    <div className="row">
										<div className="col-md-2 col-12 mb-1">
                                            <button className={"btn btn-sm btn-info mobile-margin-left "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
                                        </div>
                                        <div className="col-md-2 col-12 pending-filters mb-1">
                                            <Typeahead selected={defaultDuePriority} clearButton id="frmDueDateTypes" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => getDueDatePriorities(e)} placeholder="Choose Priority Type"
												options={dueDatePriorityList}
												renderMenuItemChildren={(option: Item,props) => (
													<>
                                                        <Highlighter search={props.text}>
                                                            {option.label}
                                                        </Highlighter>
                                                        <div>
                                                            <small>{option.description}</small>
                                                        </div>
                                                    </>
												)}
											/>
                                        </div>
                                        <div className="col-md-3 col-12 pending-filters text-right text-md-center mb-1">
                                            <DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,PAstart,PAend)}
												onCallback={PAsetDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{PAlabel}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Invoice Date</small>
                                        </div>
                                        <div className="col-md-1 col-12 payable-listing text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Seller Level'
                                                    onstyle='warning'
                                                    offlabel='Invoice Level'
                                                    offstyle='secondary'
                                                    width='150'
													size="sm"
                                                />
                                            </label>
                                        </div>
										<div className="col-md-4 col-12 text-center text-md-end mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Seller Name, Phone Number, Invoice Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
                                    <DTE.DataTable columns={columns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                            {showIrnDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    <div className="row">
                                        <div className="col-md-2 col-12 payable-listing mb-1">
                                            <button className={"btn btn-sm btn-info mobile-margin-left "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
                                        </div>
                                        <div className="col-md-4 col-12 pending-filters text-right text-md-center mb-1">
                                            <DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,PAstart,PAend)}
												onCallback={PAsetDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{PAlabel}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Invoice Date</small>
                                        </div>
                                        <div className="col-md-2 col-12 payable-listing text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Seller Level'
                                                    onstyle='warning'
                                                    offlabel='Invoice Level'
                                                    offstyle='secondary'
                                                    width='150'
													size='sm'
                                                />
                                            </label>
                                        </div>
										<div className="col-md-4 col-12 text-center text-md-end mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Seller Name, Phone Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                        <div className="col-md-12 mb-1">
                                            <p className="text-danger text-center" id="popover-fo">Note: FIFO method of invoice knock off 
                                            <Common.OverlayTrigger overlay={Common.popoverContent('','Payments will be knocked off against invoice level dues based on FIFO method. Hence, multi payments and deductions are not possible in payables at customer level. Switch to Invoice level to perform multi payments and deductions.')}><i className='bx bxs-info-circle' ></i></Common.OverlayTrigger></p>
                                        </div>
                                    </div>
									<DTE.DataTable columns={docColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                        </>)}
                        {showPayablesDiv && (<>
                            {showInvoiceDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    <div className="row">
                                        <div className="col-md-2 col-12 payable-listing mb-1">
                                            <button className={"btn btn-sm btn-info mobile-margin-left "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
                                        </div>
                                        <div className="col-md-3 col-12 payable-listing text-center mb-1">
											<DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,start,end)}
												onCallback={setDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Payment Date</small>
										</div>
                                        <div className="col-md-2 col-12 payable-listing text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Seller Level'
                                                    onstyle='warning'
                                                    offlabel='Invoice Level'
                                                    offstyle='secondary'
                                                    width='150'
													size='sm'
                                                />
                                            </label>
                                        </div>
                                        <div className="col-md-1 col-12 payable-listing text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleActiveCancel}
                                                    checked={activeCancel}
                                                    onlabel='Cancel'
                                                    onstyle='danger'
                                                    offlabel='Active'
                                                    offstyle='success'
                                                    width='100'
													size='sm'
                                                />
                                            </label>
                                        </div>
										<div className="col-md-4 col-12 payable-listing text-right mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Seller Name, Phone Number, Invoice Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
									<DTE.DataTable columns={payableColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                            {showIrnDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    <div className="row">
                                        <div className="col-md-2 col-12 payable-listing mb-1">
                                            <button className={"btn btn-sm btn-info mobile-margin-left "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
                                        </div>
                                        <div className="col-md-3 col-12 payable-listing text-center mb-1">
											<DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,start,end)}
												onCallback={setDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Payment Date</small>
										</div>
                                        <div className="col-md-2 col-12 payable-listing text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Seller Level'
                                                    onstyle='warning'
                                                    offlabel='Invoice Level'
                                                    offstyle='secondary'
                                                    width='150'
													size='sm'
                                                />
                                            </label>
                                        </div>
                                        <div className="col-md-1 col-12 payable-listing text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleActiveCancel}
                                                    checked={activeCancel}
                                                    onlabel='Cancel'
                                                    onstyle='danger'
                                                    offlabel='Active'
                                                    offstyle='success'
                                                    width='100'
													size='sm'
                                                />
                                            </label>
                                        </div>
										<div className="col-md-4 col-12 payable-listing text-right mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Seller Name, Phone Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
									<DTE.DataTable columns={payableDocColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                        </>)}
                        {showAdvancedDiv && (<>
                            {showInvoiceDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    <div className="row">
                                        <div className="col-md-3 col-12 payable-listing mb-1">
											<div className="btn-group btn-group-sm" role="group">
											<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
											<Common.Link to="/add-payable" className="btn btn-primary btn-sm"><i className="bx bx-plus"></i>Advance</Common.Link>										
											</div>
                                        </div>
                                        <div className="col-md-3 col-12 text-end text-md-center mb-1 advance-filters">
                                            <DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,PAstart,PAend)}
												onCallback={PAsetDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{PAlabel}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Payment Date</small>
                                        </div>
										<div className="col-md-2 col-12 payable-listing text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Seller Level'
                                                    onstyle='warning'
                                                    offlabel='Receipt Level'
                                                    offstyle='secondary'
                                                    width='150'
													size='sm'
                                                />
                                            </label>
                                        </div>
                                        
										<div className="col-md-4 col-12 text-center text-md-end mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Seller Name, Phone Number, Advance Receipt No')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-9"></div>
                                        <div className="col-md-3 col-12 payable-listing text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleActiveCancel}
                                                    checked={activeCancel}
                                                    onlabel='Cancel'
                                                    onstyle='danger'
                                                    offlabel='Active'
                                                    offstyle='success'
                                                    width='100'
													size='sm'
                                                />
                                            </label>
                                        </div>
                                    </div>
									<DTE.DataTable columns={advancedColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                            {showIrnDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">
                                    {successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
                                    {errorMessage}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>)}
                                    <div className="row">
                                        <div className="col-md-3 col-12 payable-listing mb-1">
											<div className="btn-group btn-group-sm" role="group">
												<button className={"btn btn-sm btn-info "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
												<Common.Link to="/add-payable" className="btn btn-primary btn-sm ms-2"><i className="bx bx-plus"></i>Advance</Common.Link>
											</div>
                                        </div>
                                        <div className="col-md-3 col-12 text-end text-md-center mb-1 advance-filters">
                                            <DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,PAstart,PAend)}
												onCallback={PAsetDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{PAlabel}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Payment Date</small>
                                        </div>
										<div className="col-md-2 col-12 payable-listing text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleChange}
                                                    checked={switchChecked}
                                                    onlabel='Seller Level'
                                                    onstyle='warning'
                                                    offlabel='Receipt Level'
                                                    offstyle='secondary'
                                                    width='150'
													size='sm'
                                                />
                                            </label>
                                        </div>                                        
										<div className="col-md-4 col-12 text-center text-md-end mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Seller Name, Phone Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-9"></div>
                                        <div className="col-md-3 col-12 payable-listing text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleActiveCancel}
                                                    checked={activeCancel}
                                                    onlabel='Cancel'
                                                    onstyle='danger'
                                                    offlabel='Active'
                                                    offstyle='success'
                                                    width='100'
													size='sm'
                                                />
                                            </label>
                                        </div>
                                    </div>
									<DTE.DataTable columns={advancedDocColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                            </>)}
                        </>)}
                        {showOtherExpenseDiv && (<>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-3 col-4 payable-listing mb-1">
											<div className="btn-group btn-group-sm" role="group">
											<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
                                                <span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
                                                <i className="bx bx-download d-block d-sm-none"></i>
                                            </button>
											<Common.Link to="/other-expense" className="btn btn-primary btn-sm">
                                                <i className="bx bx-plus"></i><span className="hidden-phone">Other Expense</span>
                                            </Common.Link>
											</div>
                                        </div>
                                        <div className="col-md-3 col-8 text-center mb-1">
											<DateRangePicker
												initialSettings={DTE.DatePickerPredefined(moment,start,end)}
												onCallback={setDatePicker}>
												<div className="input-group input-group-sm">
													<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
													<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
														<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
													</div>
												</div>
											</DateRangePicker>
                                            <small>Date range based on Voucher Date</small>
										</div>
                                        <div className="col-md-2 col-12 payable-listing text-right text-md-center mb-1">
                                            <label>
                                                <BootstrapSwitchButton
                                                    onChange={handleActiveCancel}
                                                    checked={activeCancel}
                                                    onlabel='Cancel'
                                                    onstyle='danger'
                                                    offlabel='Active'
                                                    offstyle='success'
                                                    width='100'
													size='sm'
                                                />
                                            </label>
                                        </div>
										<div className="col-md-4 col-12 text-center text-md-end mb-1">
											<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Voucher No')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
											<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
										</div>
                                    </div>
									<DTE.DataTable columns={otherExpenseColumns} data={data} className="dataTables_wrapper rdt_AdjDrp" progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} />
                                </div>
                            </div>
                        </>)}
                    </div>
                </div>
                <Common.FYNTicket url={props.url}/>
            </div>
        </div>
    </div>
    <Modal show={dedModalShow} size={'lg'} onHide={()=>setDedModalShow(false)} aria-labelledby="deduction-modal" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title id="deduction-modal">Deductions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                {temp_deduction !== undefined && temp_deduction.length === 0 && <div className="text-center py-1">No deduction</div>}
                {temp_deduction !== undefined && temp_deduction.length > 0 && <>
                <div className="table-responsive text-nowrap">
                    <table className="table table-sm table-bordered">
                        <thead className="table-light">
                            <tr>
                                <th>Sl.</th>
                                <th align="right">Deduction (%)</th>
                                <th align="right">Deduction (<i className='bx bx-rupee' ></i>)</th>
                                <th>Deduction Type</th>
                                <th>Deduction Subtype</th>
                                {/* <th>Remarks</th> */}
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {temp_deduction.map((val,index) => (
                                <tr key={'ded1'+index}>
                                    <td>{index+1}</td>
                                    <td align="right">{Common.displayPercent(val.deduction_per)}</td>
                                    <td align="right">{val.deduction_amount}</td>
                                    <td>{val.deduction_type}</td>
                                    <td>{val.subtype_name}</td>
                                    {/* <td>{val.deduction_remarks}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                </>}
            </div>
        </Modal.Body>
    </Modal>
    <Modal size='md' show={payableCancelShow} onHide={()=>setPayableCancelShow(false)} aria-labelledby="Quotation-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Quotation-Cancel-modal">Cancel Payable ({receiptNo})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addEwbCancelFrm" onSubmit={handleCancelCln} autoComplete="off">
					<div className="row">
						<div className="col-sm-12">
                            <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                            <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} required/>
						</div>
                        <div className="col-sm-12">
                            <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                            <div className="input-group input-group-merge">
                                <textarea rows="2" id="cancel-remarks" name="cancel_remarks" className="form-control" placeholder="Cancel Remarks" aria-label="CancelRemarks" aria-describedby="Remarks" value={payableCancelRemarks} onChange={(e) => setPayableCancelRemarks(e.target.value)} required></textarea>
                            </div>
						</div>
					</div>
                    <div className="row ">
                        <div className="col-sm-12 mt-2">
							<p className="smaller_font text-danger">Note: Cancelling this payment will also cancel other payments or advance receipts or advance adjusted linked to this sale.</p>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-1">
							<button type='submit' className="btn btn-sm btn-danger"><i className='bx bx-message-square-x' ></i>&nbsp;Cancel</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
        <Modal show={cancelCheckShow} size='sm' onHide={()=>setCancelCheckShow(false)} aria-labelledby="Eway-Bill-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Cancel Payable</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<p><b>{cancelCheckMessage}</b></p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="danger" onClick={()=>setCancelCheckShow(false)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
        <Modal show={uploadPaidModal} size='sm' onHide={()=>showUploadDocModal(false, payableId)} aria-labelledby="Eway-Bill-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Select File to upload</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Default file input example</label>
                            <input className="form-control" type="file" name="formFile" id="formFile" onChange={handleDocument} />
                        </div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={(e) => uploadDocument(e)}>
				Upload
			</Button>
			<Button variant="danger" onClick={()=>showUploadDocModal(false,payableId)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
        <Modal show={commonModel} size='sm' aria-labelledby="Common-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header>
			<Modal.Title>{commonModelHeading}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{commonModelBody}
			</Modal.Body>
			<Modal.Footer>
				{commonModelFooter}
			</Modal.Footer>
		</Modal>
        <Modal size='md' show={otherExpenseCancelShow} onHide={()=>setOtherExpenseCancelShow(false)} aria-labelledby="Other-Income-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Other-Income-Cancel-modal">Cancel Other Expense ({receiptNo})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addEwbCancelFrm" onSubmit={handleCancelOtherIn} autoComplete="off">
					<div className="row">
						<div className="col-sm-12">
                            <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                            <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} required/>
						</div>
                        <div className="col-sm-12">
                            <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                            <div className="input-group input-group-merge">
                                <textarea rows="2" id="cancel-remarks" name="cancel_remarks" className="form-control" placeholder="Cancel Remarks" aria-label="CancelRemarks" aria-describedby="Remarks" value={payableCancelRemarks} onChange={(e) => setPayableCancelRemarks(e.target.value)} required></textarea>
                            </div>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-1">
							<button type='submit' className="btn btn-sm btn-danger"><i className='bx bx-message-square-x' ></i>&nbsp;Cancel</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
        <Modal show={paymentModalShow} size={ deduction_div ? 'xl':'md'} onHide={()=>setPaymentModalShow(false)} aria-labelledby="deduction-modal" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="deduction-modal">Other Expense Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    {paymentDetails && paymentType === 1 && <>
                    <div className="table-responsive text-nowrap">
                        <table className="table table-sm table-bordered">
                            <thead className="table-light">
                                <tr>
                                    <th>Payment Mode</th>
                                    <th align="right">Amount</th>
                                    <th>Payment Date</th>
                                    <th>Nickname</th>
                                    <th>Payment Remarks</th>
                                    <th>Trans Ref</th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                <tr key='pay1'>
                                    <td>{paymentDetails.payment_mode}</td>
                                    <td align="right">{paymentDetails.payment_amt}</td>
                                    <td>{paymentDetails.payment_date}</td>
                                    <td>{paymentDetails.nickname}</td>
                                    <td>{paymentDetails.payment_remarks}</td>
                                    <td>{paymentDetails.trans_ref}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </>}
                    {paymentDetails && paymentType === 2 && <>
                        {deduction_div && <>
                            <div className="col text-right">
                                <span id="payModalInfo" className="badge bg-warning">
                                    Total Invoice Value: {Common.displayPrice(taxable_amt)}
                                </span>
                            </div>
                        </>}
                        <form id="formAddPayment" onSubmit={handlePayment} autoComplete="off" className='formAddPayment'>
                            <div className="row">
                                <div className={deduction_div ? 'col-sm-6':''}>
                                    <div className="row">
                                        <label className="col-4 col-form-label" htmlFor="frmSaleTotal">Amount <span className="text-danger">*</span></label>
                                        <div className="col-8">
                                            <div className="input-group input-group-merge">
                                                <span className="input-group-text"><i className='bx bx-rupee'></i></span>
                                                <input type="text" className="form-control" id="frmSaleTotal" placeholder="0.00" name='received_amount' value={Number(paymentDetails.total_amt) - Number(total_deduction)} 
                                                readOnly/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <label className="col-4 col-form-label" htmlFor="frmSalePayDate">Payment Date</label>
                                        <div className="col-8">
                                            <DatePicker name="payment_date" data-testid="frmSalePayDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setPaymentDate} value={payment_date} />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <label className="col-4 col-form-label" htmlFor="frmSalePayMode">Payment Mode</label>
                                        <div className="col-8">
                                            <Typeahead clearButton defaultSelected={paymentModes.slice(1, 2)} id="frmSalePayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
                                                options={paymentModes}
                                                renderMenuItemChildren={(option: Item,props) => (
                                                    <Highlighter search={props.text}>
                                                        {option.label}
                                                    </Highlighter>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <label className="col-4 col-form-label" htmlFor="frmBankAccount">Bank Account <span className="text-danger">*</span></label>
                                        <div className="col-8">
                                            <Typeahead disabled={payment_mode === 'Cash'?true:false} selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
                                                options={bankList}
                                                renderMenuItemChildren={(option: Item,props) => (
                                                    <Highlighter search={props.text}>
                                                        {option.label}
                                                    </Highlighter>
                                                )}
                                            />
                                            <small>To add additional bank account, Go to Account management - <a href='/bank-accounts'>Bank accounts</a></small>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <label className="col-4 col-form-label" htmlFor="frmSalePayRemarks">Payment Remarks</label>
                                        <div className="col-8">
                                            <input type="text" name='payment_remarks' className="form-control" id="frmSalePayRemarks" placeholder="Payment Remarks" value={payment_remarks} onChange={(e) => setPaymentRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <label className="col-4 col-form-label" htmlFor="frmSaleTransRef">Transaction Ref. No.</label>
                                        <div className="col-8">
                                            <input type="text" name='trans_ref' maxLength="100" className="form-control" id="frmSaleTransRef" placeholder="Transaction Ref." value={trans_ref} onChange={(e) => setTransRef(e.target.value)} autoComplete="off"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={deduction_div ? 'col-sm-6':'d-none'}>
                                    {/*<div className="form-text text-right text-info">
                                        Total Invoice Value: {Common.displayPrice(total_amt)}, Total Deduction added: {Common.displayPrice(Number(total_deduction))}
                                    </div>*/}
                                    <div className="card">
                                        <h6 className="card-header">Deductions</h6>
                                        {deductions.length === 0 && <div className="text-center py-1">No deduction</div>}
                                        {deductions.length > 0 && <>
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-sm table-bordered">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th align="center">Action</th>
                                                        <th>Sl.</th>
                                                        <th align="right">Deduction (%)</th>
                                                        <th align="right">Deduction (<i className='bx bx-rupee' ></i>)</th>
                                                        <th>Deduction Type</th>
														<th>Deduction Subtype</th>
														{/* <th>Remarks</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody className="table-border-bottom-0">
                                                    {deductions.map((val,index) => (
                                                        <tr key={'ded'+index}>
                                                            <td align="center">
                                                                <button type="button" className="btn btn-sm" onClick={() => removeDeduction(index)}><i className='bx bx-trash'></i></button>
                                                            </td>
                                                            <td>{index+1}</td>
                                                            <td align="right">{Common.displayPercent(val.deduction_per)}%</td>
                                                            <td align="right">{Common.displayPrice(val.deduction_amt)}</td>
                                                            <td>{val.deduction_type}</td>
															<td>{val.deduction_subtype}</td>
															{/* <td>{val.deduction_remarks}</td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        </>}
                                    </div>
                                    <div className="alert alert-secondary mt-3">
                                        <div className="row">
                                            <h4>Add Deduction</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row">
                                                    <label className="col-sm-5 col-form-label" htmlFor="frmSaleDedPer">Percent <span className="text-danger">*</span></label>
                                                    <div className="col-sm-7">
                                                        <div className="input-group input-group-merge">
                                                            <span className="input-group-text"><i className='bx bxs-offer'></i></span>
                                                            <input type="text" className="form-control" id="frmSaleDedPer" placeholder="0.00" value={deduction_per} onChange={(e) => calcDeduction('per',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row text-right">
                                                    <small>(Deduction at source and on base value)</small>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row">
                                                    <label className="col-sm-4 col-form-label" htmlFor="frmSaleDedAmt">Amount <span className="text-danger">*</span></label>
                                                    <div className="col-sm-8">
                                                        <div className="input-group input-group-merge">
                                                            <span className="input-group-text"><i className='bx bx-rupee'></i></span>
                                                            <input type="text" className="form-control" id="frmSaleDedAmt" placeholder="0.00" value={deduction_amt} onChange={(e) => calcDeduction('amt',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className="col-4 col-form-label" htmlFor="frmSaleDedType">Deduction Type <span className="text-danger">*</span></label>
                                            <div className="col-8">
                                                <Typeahead clearButton ref={frmSaleDedType} id="frmSaleDedType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDeductionTypeSelect(e)} placeholder="Choose Deduction Type"
                                                    options={deductionMasterList}
                                                    renderMenuItemChildren={(option: Item,props) => (
                                                        <Highlighter search={props.text}>
                                                            {option.label}
                                                        </Highlighter>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className="col-4 col-form-label" htmlFor="frmSaleDedRemarks">Deduction Subtype</label>
                                            <div className="col-8">
                                                {/* <input type="text" className="form-control" id="frmSaleDedRemarks" placeholder="Deduction Remarks" value={deduction_remarks} onChange={(e) => setDeductionRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/> */}
                                                <Typeahead clearButton id="frmSaleDedSubtype" allowNew newSelectionPrefix="Add: " ref={frmSaleDedSubtype} disabled={isDeductionMasterSelected === false ? true : false} labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDeductionSubTypeSelect(e)} placeholder="Choose Deduction Sub Type"
                                                    options={deductionSubtypeMasterList}
                                                    renderMenuItemChildren={(option: Item,props) => (
                                                        <Highlighter search={props.text}>
                                                            {option.label}
                                                        </Highlighter>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center mt-3">
                                            <button type="button" className="btn btn-sm btn-primary ms-1" onClick={addDeduction}><span className="tf-icons bx bx-save"></span>&nbsp;Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-3">
                                    <button type="button" onClick={()=>{setDeductionDiv(!deduction_div);setDeductionAmt('');setDeductionPer('');frmSaleDedType.current.clear();}} className={"btn btn-sm btn-"+(deduction_div ? 'danger':'success')}>{deduction_div ? <><i className='bx bx-minus'></i></> : <><i className='bx bx-plus'></i></>} Deduction</button>
                                    {bank_cash_account === 1 && (<>
                                        <button type='submit' className="btn btn-sm btn-primary ms-1" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
                                    </>)}
                                </div>
                            </div>
                        </form>
                    </>}
                </div>
            </Modal.Body>
        </Modal>
        <Modal show={uploadVoucherModal} size='sm' onHide={()=>showUploadVoucherDocModal(false, payableId)} aria-labelledby="Eway-Bill-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Select File to upload</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Default file input example</label>
                            <input className="form-control" type="file" name="formFile" id="formFile" onChange={handleVoucherDocument} />
                        </div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={(e) => uploadVoucherDocument(e)}>
				Upload
			</Button>
			<Button variant="danger" onClick={()=>showUploadVoucherDocModal(false,payableId)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
        <Modal {...props} size="sm" show={dueDateModal} onHide={handleDueDateModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Set Due Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DatePicker name="due_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setDueDate} value={dueDate} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleDueDateUpdate}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
   </>
);

}