import React,{useState}  from "react";
import Layout from './MartLayout';
import NavBar from './MartNavBar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import { confirmAlert } from 'react-confirm-alert';

export default function MartInventory(props){
	const navigate = Common.useNavigate();
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [filterText, setFilterText] = React.useState('');
	const [DTpending, setDTPending] = React.useState(true);
	const [dt_load,setDtLoad] = React.useState(true);
	const [sortOrder,setSortOrder] = React.useState('');
	
	const [items, setItemList]    = React.useState([]);
	const [viewModal, setViewModal] = React.useState(false);
	const handleViewClose = () => setViewModal(false);
	const [item_details, setItemDetails] = useState([]);
		
    React.useEffect(() => {
        document.title = 'Inventory | '+props.site_name;
		loadDataTable(1,perPage,filterText,sortOrder);
	}, [props,dt_load,filterText,perPage]);
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const loadDataTable = (page,perPage,filterText,sort='') => {
		Common.axios({
            method: 'post',
            url:props.url+'martinventory/listing',
			data: {martid:Common.secureLocalStorage.getItem('fo_martid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText}
        }).then(function (response) {
            if(response.data.success){
				setItemList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,sortOrder);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection));
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection));
	};
	const viewItem = (val) => {
		Common.axios({
            method: 'get',
            url:props.url+'items/details/'+localStorage.getItem('fo_profileid')+'/'+val,
        }).then(function (response) {
            if(response.data.success){
                setItemDetails(response.data.data);
				setViewModal(true);
            }
        }).catch(error => console.log(error));
	}
	const addPhotoItem = (val,item_name) => {
		navigate('/mart-item-photos',
		{
			state: {
				itemid: val,
				itemName: item_name,
			}
		});
	}
	const editItem = (val) => {
        navigate('/mart-edit-inventory',
        {
            state: {
                item_id: val
            }
        });
	}
	const deleteItem = (e,val) => {
		e.preventDefault();
		confirmAlert({
            title: 'Delete Item',
            message: 'Are you sure you want to delete?',
            buttons: [{
                label: 'Yes',
                onClick: () => {
                    Common.showLoader(true);
			        Common.axios({
			            method: 'get',
			            url:props.url+'items/deleteItem/'+localStorage.getItem('fo_profileid')+'/'+val,
			        }).then(function (response) {
			            if(response.data.success){
							loadDataTable(1,perPage,filterText,sortOrder);
							Common.toast.success(response.data.message);
			            }else {
							Common.toast.error(response.data.message);
						}
						Common.showLoader(false);
			        }).catch(error => console.log(error));
                }
            },
            {
                label: 'No',
                onClick: () => {console.log('no')}
            }]
        });
	}
	const columns = [
    	{
            name: 'Action',
            button: true,
            cell: (row) => ( row.status !== '0' ? <><div className="dropdown">
					<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
						<i className="bx bx-dots-vertical-rounded"></i>
					</button>
					<div className="dropdown-menu" >
						<button onClick={(e) => viewItem(row.id)} className="dropdown-item" ><i className='bx bx-book-reader'></i> View </button>
						<button onClick={(e) => editItem(row.id)} className="dropdown-item" ><i className="bx bx-edit"></i> Edit </button>
						<button onClick={(e) => deleteItem(e, row.id)} className="dropdown-item" ><i className="bx bx-trash"></i> Delete </button>
						<button onClick={(e) => addPhotoItem(row.iid,row.name)} className="dropdown-item" ><i className='bx bx-images'></i> Add/View Media</button>
					</div>
              </div></> : <>{'Deleted'}</>
            ),
        },
        {
            name: 'Item Name',
            selector: row => row.name,
            sortable: true,
			sortField: 'name'
        },
        {
            name: 'Item Unit',
            selector: row => row.item_unit ,
            sortable: true,
			sortField: 'item_unit'
        },
        {
            name: 'Sale Item Price / Unit (₹)',
            selector: row => row.sale_price,
            sortable: true,
			sortField: 'sale_price',
			right: true,
			style: {
				'text-align': 'right',
				'display': 'grid'
			}
        },
		{
            name: 'Sale Total Price (₹)',
            selector: row => row.sale_total_price,
            sortable: true,
			sortField: 'sale_total_price',
			right: true,
			style: {
				'text-align': 'right',
				'display': 'grid'
			}
        },
        {
            name: 'Purchase Item Price / Unit (₹)',
            selector: row => row.purchase_price,
            sortable: true,
			sortField: 'purchase_price',
			right: true,
			style: {
				'text-align': 'right',
				'display': 'grid'
			}
        },
		{
            name: 'Purchase Total Price (₹)',
            selector: row => row.purchase_total_price ,
            sortable: true,
			sortField: 'purchase_total_price',
			right: true,
			style: {
				'text-align': 'right',
				'display': 'grid'
			}
        },
        {
            name: 'Current Stock',
            selector: row => row.current_stock,
            sortable: true,
			sortField: 'current_stock'
        },
        {
            name: 'Min Order Qty',
            selector: row => row.min_qty,
            sortable: true,
			sortField: 'min_qty'
        }
    ];
	
    return (
		<>
		<Modal size={"lg"} show={viewModal} onHide={handleViewClose} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>Details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Common.Row>
					<Common.Col><strong>Description : </strong> {item_details.name}</Common.Col>
					<Common.Col><strong>Unit : </strong> {item_details.uqc_unit}</Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col>
						<strong>Is it a Service? : </strong> {item_details.service === '0' ? <>
							<label><input className="mx-1" type="radio" name="service" checked disabled/>Product</label>
							<label><input className="mx-1" type="radio" name="service"  disabled/>Service</label>
						</> : <>
							<label><input className="mx-1" type="radio" name="service"  disabled/>Product</label>
							<label><input className="mx-1" type="radio" name="service" checked  disabled/>Service</label>
						</>}
					</Common.Col>
					<Common.Col><strong>HSN Code : </strong> {item_details.hsn}</Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col><strong>Min Order Qty : </strong> {item_details.min_qty}</Common.Col>
					<Common.Col><strong>GST Rate : </strong> {item_details.gst_rate}</Common.Col>
				</Common.Row>

				{item_details.item_service === '0' && item_details.stock_movement === '1' ? <>
					<Common.Row>
						<Common.Col><strong>Current Stock : </strong> {item_details.current_stock}</Common.Col>
						<Common.Col><strong>Track Item stock movement : </strong>{item_details.stock_movement === '1' ? 'Yes' : 'No'}</Common.Col>
					</Common.Row>
								
					<Common.Row>
						<Common.Col><strong>Opening Stock : </strong> {item_details.stockOpening}</Common.Col>
						<Common.Col><strong>Opening Stock Date : </strong> {item_details.stockOpeningDate}</Common.Col>
					</Common.Row>
				</>: item_details.item_service === '0' && item_details.stock_movement === '0' ? <>
					<Common.Row>
						<Common.Col><strong>Track Item stock movement : </strong>{item_details.stock_movement === '1' ? 'Yes' : 'No'}</Common.Col>
					</Common.Row>
				</>:<></>}
				
				<Common.Row className="mt-1">
					<Common.Col><h6 className='bg-secondary text-white text-center mt-3'><strong>Sale</strong></h6></Common.Col>
					<Common.Col><h6 className='bg-secondary text-white text-center mt-3'><strong>Purchase</strong></h6></Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col>
						<strong>Price/Unit : </strong> {Common.displayPrice(item_details.sale_price)}<br></br>
						<strong>Discount (-): </strong> {Common.displayPrice(item_details.sale_discount)}<br></br>
						<strong>Gross Amount : </strong> {Common.displayPrice(item_details.sale_price-item_details.sale_discount)}<br></br>
						<strong>GST : </strong> {Common.displayPrice(item_details.sale_gst_amt)}<br></br>
						<strong>Total Price : </strong> {Common.displayPrice(item_details.sale_total_price)}
					</Common.Col>
					<Common.Col>
						<strong>Price/Unit : </strong> {Common.displayPrice(item_details.purchase_price)}<br></br>
						<strong>Discount (-): </strong> {Common.displayPrice(item_details.purchase_discount)}<br></br>
						<strong>Gross Amount : </strong> {Common.displayPrice(item_details.purchase_price-item_details.purchase_discount)}<br></br>
						<strong>GST : </strong> {Common.displayPrice(item_details.purchase_gst_amt)}<br></br>
						<strong>Total Price : </strong> {Common.displayPrice(item_details.purchase_total_price)}
					</Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col><h6 className='bg-secondary text-white text-center mt-3'><strong>Item Details</strong></h6></Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col>
						<strong>SKU Code : </strong> {item_details.sku_code}<br></br>
						<strong>Barcode : </strong> {item_details.barcode}<br></br>
						<strong>Safety Stock : </strong> {item_details.safety_stock}
					</Common.Col>
					<Common.Col>
						<strong>Reorder Point : </strong> {item_details.reorder_point} <br></br>
						<strong>Reorder Quantity : </strong> {item_details.reorder_qty} <br></br>
						<strong>Maximum Stock Level : </strong> {item_details.max_stock_level}
					</Common.Col>
				</Common.Row>
				{item_details.itemCustomFields !== undefined && item_details.itemCustomFields.length > 0 ? <>
					<Common.Row className="mt-1">
						<Common.Col><h6 className='text-center mt-3'><strong>Custom Fields</strong></h6></Common.Col>
					</Common.Row>
					{item_details.itemCustomFields.map((field, i) =>{
						return (
							<Common.Row className="mt-1">
								<Common.Col>
									<strong>Field Name : </strong> {field.item_field_name}<br></br>
								</Common.Col>
								<Common.Col>
									<strong>Field Value : </strong> {field.item_field_value}<br></br>
								</Common.Col>
							</Common.Row>
						)
					})}
						
				</>:<></>}
				
			</Modal.Body>
		</Modal>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="mart-inventory" {...props}/>
                <div className="layout-page">
                    <NavBar {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3">Inventory List
								<span className="dropdown">
                                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='text-danger bx bxl-youtube bx-md'></i>
                                    </button>
                                    <div className="dropdown-menu" >
                                        <a href="https://youtu.be/o2zZagDTzIs" className="dropdown-item" target="_blank" rel="noreferrer"><span>Add inventory</span></a>
										<a href="https://youtu.be/OiTyZjloCgE" className="dropdown-item" target="_blank" rel="noreferrer"><span>Add product images & videos</span></a>
                                    </div>
								</span>
							</h4>
							<div className="row">
								<div className="col-md-6 col-12 mb-1 text-center text-md-start">
									<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Refresh')}>
										<Button variant="primary" className="btn-sm me-1" onClick={()=>{setDtLoad(!dt_load);setDTPending(true)}}><i className='bx bx-refresh'></i></Button>
									</Common.OverlayTrigger>
									<a href="/mart-add-inventory" className="btn btn-sm btn-primary"><i className='bx bx-plus'></i><span className="hidden-phone">Inventory</span></a>
								</div>
								<div className="col-md-6 col-12 text-center text-md-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Item Name, Item Unit')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
                            <div className="row">
                                <div className="col-md-12">
                                    <DTE.DataTable className="rdt_AdjDrp" title={"Inventory ("+totalDataCnt+")"} columns={columns} data={items} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
                                </div>
                            </div>
						</div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		</>
    );
}