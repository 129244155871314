import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Mart from './Mart';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const parsedData = window.location.host.split(".");
if( parsedData.length > 1 && parsedData[0] === 'mart'){
	root.render(
		<Mart />
	);
} else {
	root.render(
		<App />
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
