import React,{useState}  from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as Common from '../common/ImportUserEssentials';
import Select from "react-select";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import * as DTE from '../common/ImportDataTableEssentials';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default function SaleOrder(props){
	const [totalDataCnt,setTotalDataCount] = React.useState({'Active':'','Cancelled':''});
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
	const [DTpending, setDTPending] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
    const [saleOrders, setSaleOrders] = useState([]);
	const [exportBtnClass,setExportBtnClass]    = React.useState('d-none');

	const [saleOrdersCancelShow,setSaleOrdersCancelShow] = React.useState(false);
	const [saleOrdersCancelRemarks,setSaleOrdersCancelRemarks] = React.useState('');
	const [saleOrdersId,setSaleOrdersId] = React.useState('');
	const [invNo,setInvNo] = React.useState('');
	const [totCustomer,setTotCustomer] = React.useState({'Active':'-','Cancelled':'-'});
	const [totInvAmt,setTotInvAmt] = React.useState({'Active':'-','Cancelled':'-'});
    const invoiceTabTypes = ['Active', 'Cancelled'];
    const [activeTab, setActCan] = React.useState(invoiceTabTypes[0]);
	const [convertInvoiceMsgShow,setConvertInvoiceMsgShow] = React.useState('');
	const [sortOrder,setSortOrder] = React.useState('');
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
	const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
    const navigate = Common.useNavigate();
    
    // User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	const [incomeColl_w,setincomeColl_w] = React.useState(true);
    React.useEffect(() => {
        if( Object.keys(userPerm).length > 0 ){
			setincomeColl_w((userPerm[2]['write'] !== undefined && userPerm[2]['write'] === '0') ? false : true);
		}
		loadDataTable(1,perPage,filterText,dates,sortOrder);
    }, [props,dt_load,filterText,dates]);
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,dates,sortOrder);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText,dates,sortOrder);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
        setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,dates,btoa(column.sortField+'####'+sortDirection));
	};
    const loadDataTable = (page,perPage,filterText,dates,sort='',active_tab=activeTab) => {
		Common.axios({
            method: 'post',
            url:props.url+'sale_orders/listing/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'dates':dates,'sort':sort,'search':filterText,'active_tab':active_tab}
        }).then(function (response) {
			setExportBtnClass('d-none');
            if(response.data.success){
				setSaleOrders(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(prevState => ({
				...prevState,
					[active_tab]: response.data.total
				}));
				setDTPending(false);
				if(response.data.total > 0){
					setExportBtnClass('');
				}
				setTotCustomer(prevState => ({
				...prevState,
					[active_tab]: response.data.tabInfo.customer
				}));
				setTotInvAmt(prevState => ({
				...prevState,
					[active_tab]: response.data.tabInfo.amount
				}));
            }
        }).catch(error => console.log(error));
	}
    const columns = [
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu" >
                  <button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.id,1,row.inv_no)}><i className="bx bxs-download"></i> Download</button>
                  
                  <button className="dropdown-item" onClick={(e) => handleConvertInvoice(row.id,row.convert_to_sale)}><i className='bx bxs-file'></i> Convert to Invoice </button>
				  <button className="dropdown-item" onClick={(e) => handleCopySaleOrders(row.id,'copy',row.convert_to_sale)}><i className='bx bxs-copy'></i> Copy Sale Orders</button>
                  <button className="dropdown-item" onClick={(e) => handleCopySaleOrders(row.id,'edit',row.convert_to_sale)}><i className='bx bxs-edit'></i> Edit Sale Orders </button>
				  <button className="dropdown-item" onClick={(e) => handleCancelQutn(row)}><i className='bx bx-message-square-x'></i> Cancel Sale Orders</button>
                </div>
                <button type="button" className="btn p-1 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bx bx-share-alt"></i>
                </button>
                <div className="dropdown-menu" >
                    <button className="dropdown-item" onClick={(e) => handleShareContent(row.id,2)}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
                    <button className="dropdown-item" onClick={(e) => handleShareContent(row.id,3)}><i className="bx bx-envelope"></i> Share via Email </button>
                </div>
              </div>
            )
        },
        {
            name: 'Sale Orders No.',
            selector: row => row.inv_no,
            sortable: true,
			sortField: 'inv_no'
        },
        {
            name: 'Sale Orders Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: false,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt (₹)',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        },
        {
            name: 'Convert Invoice',
            selector: row => row.convert_to_sale,
            sortable: false,
			center: true,
			cell: (row) => (
				( row.convert_to_sale === '1' ) ? <Common.OverlayTrigger overlay={Common.popoverContent('','Converted to Invoice')}><i className='bx bxs-check-square display-6'></i></Common.OverlayTrigger> : ''
			)
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on'
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
			sortField: 'created_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on'
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
			sortField: 'updated_by'
        }
	];
	const setDatePicker = (start, end) => {
		setDates({ start, end });
		// setDtLoad(!dt_load);
	  }
	const handleConvertInvoice =(val,convert_to_sale) => {
        if(convert_to_sale === '1'){
			setConvertInvoiceMsgShow(true);
			return false;
		}
        navigate('/convert-sale',
        {
            state: {
                soid: val,
				type: 'sale_orders'
            }
        });
    }
	const handleCopySaleOrders =(val,action,convert_to_sale='0') => {
        if(action === 'edit' && convert_to_sale === '1'){
			setConvertInvoiceMsgShow(true);
			return false;
		}
        navigate('/add-sale-orders',
        {
            state: {
                soid: val,
				action: action
            }
        });
    }
	const cancelColumns = [
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu" >
                  <button className="dropdown-item" onClick={(e) => handleCopySaleOrders(row.id,'copy')}><i className='bx bxs-copy'></i> Copy Sale Orders</button>
                </div>
              </div>
            )
        },
        {
            name: 'Sale Orders No.',
            selector: row => row.inv_no,
            sortable: true,
			sortField: 'inv_no'
        },
        {
            name: 'Sale Orders Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: true,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        },
        {
            name: 'Cancel Reason',
            selector: row => row.cancel_reason,
            sortable: false,
        }
	];

    const handleExportCSV = (e)=>{
		e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'sale_orders/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText,'dates':dates,'active_tab':activeTab},
            // responseType: 'blob',
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `SaleOrders-${activeTab}-${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click(); */
			} else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
	}
	const handleDownloadPdf =(val,status,invNum) => {
		Common.showLoader(true);
		let url = props.url+'sale_orders/getData/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status));
        Common.axios({
            method: 'get',
            url:url,
            // responseType: 'blob',
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
				let currentdate = new Date();
				let timesec = currentdate.getTime().toString().substring(2, 6);
				let fileName = currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() + "_" + timesec;
                link.setAttribute('download', invNum+'_'+fileName+'.pdf');
                document.body.appendChild(link);
                link.click(); */
            }
            else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => {console.log(error);Common.showLoader(false);});
	}
	const handleShareContent =(val,status) => {
		let url = props.url+'sale_orders/getData/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status));
		Common.axios({
			method: 'get',
			url:url,
		}).then(function (response) {
			if(response.data.success){
				if(parseInt(status) === 2){
					window.open("https://api.whatsapp.com/send?text="+response.data.data.body,"_blank");
				}else{
					window.open("mailto:?subject="+response.data.data.subject+"&body="+response.data.data.body,"_blank");
				}
			}
		}).catch(error => console.log(error));
	}
	const handleCancelQutn = (details)=>{
		if(details.convert_to_sale === '1'){
			setConvertInvoiceMsgShow(true);
			return false;
		}
        setSaleOrdersId(details.id);
		setInvNo(details.inv_no);
        setSaleOrdersCancelRemarks('');
		setSaleOrdersCancelShow(true);
    }
	const handleCancelSaleOrders = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['saleOrdersId'] = saleOrdersId;
		data['pid']         = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'sale_orders/cancelSaleOrders',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setSaleOrdersCancelShow(false);
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
					loadDataTable(1,perPage,filterText,dates,sortOrder);
					// window.location.reload(true);
					return false;
                }else{
                    Common.toast.error(response.data.message);
                }
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    const [showActiveDiv,setShowActiveDiv]     = React.useState(true);
    const [showCancelledDiv,setShowCancelledDiv] = React.useState(false);
	const handleTab = penColChecked => {
		setActCan(penColChecked);
		loadDataTable(1,perPage,filterText,dates,sortOrder,penColChecked);
		if(penColChecked === "Active"){
			setShowActiveDiv(true);
			setShowCancelledDiv(false);
		}
		else{
			setShowActiveDiv(false);
			setShowCancelledDiv(true);
		}
	};
    return (
    <>
        <div id="sdt_cont" className="col-sm-12">
            {showActiveDiv && (<>
                <div className="row">
                    <div className="col-md-4 col-12 mb-1 text-center text-md-start">
                        <button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
                        {incomeColl_w && (<>
                            <Common.Link to="/add-sale-orders" className="btn btn-sm btn-primary"> <i className="bx bx-plus"></i>Add Sale Order</Common.Link>
                        </>)}
                    </div>
                    <div className="col-md-4 col-12 text-center mb-1" >
                        <DateRangePicker
                            initialSettings={DTE.DatePickerPredefined(moment,start,end)}
                            onCallback={setDatePicker}>
                            <div className="input-group input-group-sm">
                                <span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
                                <div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
                                    <i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
                                </div>
                            </div>
                        </DateRangePicker>
                        <small>Date range based on Sale Order Date</small>
                    </div>
                    <div className="col-md-4 col-12 text-center text-md-end mb-1">
                        <Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Customer Name, Inv No.')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
                        <DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                    </div>
                    <div className="col-md-12 col-12 text-center mb-1">
                        <div className="form-check form-check-inline">
                            <input id="active_type" className="form-check-input" type="radio" value="Active"  required checked={activeTab === 'Active'} onChange={(e) => handleTab(e.target.value)} />
                            <label className="form-check-label" htmlFor="active_type"> Active {(activeTab === 'Active' && totalDataCnt[activeTab] !== '') ? ' ('+totalDataCnt[activeTab]+')' : ''} </label>
                            {activeTab === 'Active' && (<>
                                <div className="tabAdditionalInfo">
                                    Cust.# {totCustomer[activeTab]} | Tot. Amt.(₹) {Common.numToWords(totInvAmt[activeTab])}
                                </div>
                            </>)}
                        </div>
                        <div className="form-check form-check-inline">
                            <input id="cancelled_type" className="form-check-input" type="radio" value="Cancelled" required  checked={activeTab === 'Cancelled'} onChange={(e) => handleTab(e.target.value)} />
                            <label className="form-check-label" htmlFor="cancelled_type"> Cancelled</label>
                            {activeTab === 'Active' && (<>
                                <div className="tabAdditionalInfo">
                                    Cust.# - | Tot. Amt.(₹) -
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
                <DTE.DataTable title={"Sale Orders"} className="dataTables_wrapper rdt_AdjDrp" columns={columns} data={saleOrders} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
            </>)}
            {showCancelledDiv && (<>
                <div className="row">
                    <div className="col-md-3 col-12 listing-tabs mb-1">
                        <button className={"btn btn-sm btn-info "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
                    </div>
                    <div className="col-md-5 col-12 text-center mb-1">
                        <DateRangePicker
                            initialSettings={DTE.DatePickerPredefined(moment,start,end)}
                            onCallback={setDatePicker}>
                            <div className="input-group input-group-sm">
                                <span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
                                <div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
                                    <i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
                                </div>
                            </div>
                        </DateRangePicker>
                        <small>Date range based on Sale Order Date</small>
                    </div>
                    <div className="col-md-4 col-12 text-center text-md-end mb-1">
                        <Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Sale Orders Number, Customer')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
                        <DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                    </div>
                    <div className="col-md-12 col-12 text-center mb-1">
                        <div className="form-check form-check-inline">
                            <input id="active_type" className="form-check-input" type="radio" value="Active"  required checked={activeTab === 'Active'} onChange={(e) => handleTab(e.target.value)} />
                            <label className="form-check-label" htmlFor="active_type"> Active </label>
                            {activeTab === 'Cancelled' && (<>
                                <div className="tabAdditionalInfo">
                                    Cust.# - | Tot. Amt.(₹) -
                                </div>
                            </>)}
                        </div>
                        <div className="form-check form-check-inline">
                            <input id="cancelled_type" className="form-check-input" type="radio" value="Cancelled" required  checked={activeTab === 'Cancelled'} onChange={(e) => handleTab(e.target.value)} />
                            <label className="form-check-label" htmlFor="cancelled_type"> Cancelled {(activeTab === 'Cancelled' && totalDataCnt[activeTab] !== '') ? ' ('+totalDataCnt[activeTab]+')' : ''}</label>
                            {activeTab === 'Cancelled' && (<>
                                <div className="tabAdditionalInfo">
                                    Cust.# {totCustomer[activeTab]} | Tot. Amt.(₹) {Common.numToWords(totInvAmt[activeTab])}
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
                <DTE.DataTable title="Sale Orders" className="rdt_AdjDrp" columns={cancelColumns} data={saleOrders} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
            </>)}
        </div>
		<Modal size='md' show={saleOrdersCancelShow} onHide={()=>setSaleOrdersCancelShow(false)} aria-labelledby="Sale-Orders-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Sale-Orders-Cancel-modal">Cancel Sale Order ({invNo})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addEwbCancelFrm" onSubmit={handleCancelSaleOrders} autoComplete="off">
					<div className="row">
						<div className="col-sm-12">
                            <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                            <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} required/>
						</div>
                        <div className="col-sm-12">
                            <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                            <div className="input-group input-group-merge">
                                <textarea rows="2" id="cancel-remarks" name="cancel_remarks" className="form-control" placeholder="Cancel Remarks" aria-label="CancelRemarks" aria-describedby="Remarks" value={saleOrdersCancelRemarks} onChange={(e) => setSaleOrdersCancelRemarks(e.target.value)} required></textarea>
                            </div>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-3">
							<button type='submit' className="btn btn-sm btn-danger"><i className='bx bx-message-square-x' ></i>&nbsp;Cancel</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
		<Modal show={convertInvoiceMsgShow} size='sm' onHide={()=>setConvertInvoiceMsgShow(false)} aria-labelledby="Convert-Invoice_Msg-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Sale Order</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<p><b>There is an active invoice linked to this sale order. Cancel that invoice to cancel this sale order</b></p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="danger" onClick={()=>setConvertInvoiceMsgShow(false)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
    </>
    );
}