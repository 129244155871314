import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import paymentModes from '../masters/PaymentModes.js';
import * as Common from '../common/ImportUserEssentials';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import { CSVLink } from "react-csv";

export default function AddLoan(props){
    // const [saveBtnDisabled,setSaveBtnDisabled]         = useState(false);
	const [loan_provider_master,setLoanProviderMaster] = useState([]);
	const [loan_provider,setLoanProvider]              = useState('');

	const [loan_amt,setLoanAmt]              		  = useState('');
	const [loan_taken_date,setLoanTakenDate] 		  = useState('');
	const [loan_period,setLoanPeriod]        	  	  = useState('');
	const [moratorium_period,setMoratoriumPeriod]	  = useState(0);
	const [total_loan_period,setTotalLoanPeriod] 	  = useState('');
	const [repayment_frequency,setRepaymentFrequency] = useState('');
	const [repayment_due_date,setRepaymentDueDate] 	  = useState('');
	const [rate_of_intrest,setRateOfInterest] 	      = useState('');
	const [loan_schedule_div,setLoanScheduleDiv]      = useState('');
	const [loanSchedule,setloanSchedule] 			  = useState([]);
	const [EMIAmount,setEMIAmount] 	      			  = useState('');
	const [totalEMIAmount,setTotalEMIAmount] 	      = useState('');
	const [towardsInterest,setTowardsInterest] 	      = useState('');
	const [towardsPrincipal,setTowardsPrincipal] 	  = useState('');
	const [bankList, setBankList] 					  = useState([]);
	const [bankAccount,setBankAccount] 				  = useState(0);
	const [defaultPayee, setDefaultPayee] 			  = useState([]);
	const [payment_mode,setPaymentMode]               = useState('UPI/QR');

	const [csvData,setCsvData] 	  = useState('');
	const [csvHeader,setCsvHeader] 	  = useState('');

	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Add Loan | '+props.site_name;
		if (localStorage.getItem('fo_subplan') === 'Basic') {
			navigate("/pricing-plan");
		}

		// get Loan Provider
		Common.axios({
			method: 'post',
				url: props.url+'loans/fetch_loan_provider',
				data: {
					'pid': localStorage.getItem('fo_profileid')
				}
		}).then(function (response) {
			if( response.data.success ){
				setLoanProviderMaster(response.data.data);
			}
		}).catch(error => console.log(error));

		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				setDefaultPayee(response.data.defaultPayee);
				if (response.data.defaultPayee.length > 0) {
					setBankAccount(response.data.defaultPayee[0].value);
				}
			}
		}).catch(error => console.log(error));

	}, []);
    const setLoanProviderSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setLoanProvider(value);
	}
	const calcuateTotalLoanPeriod = (val,tytpe) => {
		let totMonth = 0;
		if(tytpe === 'LP'){
			totMonth = (val * 12) - moratorium_period;
		}
		else if(tytpe === 'MP'){
			totMonth = (loan_period * 12) - val;
		}
		setTotalLoanPeriod(totMonth);
	}
    const calculateLoanSchedule = () => {
		if(loan_amt <= 0){
			Common.toast.error('Loan Amount is required');
			return false;
		}
		if(rate_of_intrest <= 0){
			Common.toast.error('Rate of Intrest is required');
			return false;
		}
		let p = loan_amt;
		let n = (loan_period * 12) - moratorium_period;
		let i = (rate_of_intrest/12)/100;
		if(n <= 0){
			Common.toast.error('Loan period cannot be less than 1');
			return false;
		}
		let result = [];let csv_data = [];
		let pi = p*i;
		let ai = Math.pow((1+i), n);
		let ab = pi*ai;

		let ef = (Math.pow(1+i, n) - 1);
		let emi = (ab/ef);
		setEMIAmount(Number(emi).toFixed(2));
		setTotalEMIAmount(Number(emi * n).toFixed(2));

		let pp = loan_amt;
		let towardsPrincipal = 0;
		let towardsInterest = 0;
		for(let j = 1;j<=n;j++){
			let pi = pp * i;
			let pri_amt = (emi-pi);

			result.push({PrincipalOS: Number(pp).toFixed(4), TowardsInterest: Number(pi).toFixed(4),TowardsPrincipal:Number(pri_amt).toFixed(4),EMI:Number(emi).toFixed(4)});

			csv_data.push({PrincipalOS: Number(pp).toFixed(2), TowardsInterest: Number(pi).toFixed(2),TowardsPrincipal:Number(pri_amt).toFixed(2),EMI:Number(emi).toFixed(2)});

			towardsPrincipal += pri_amt;
			towardsInterest += pi;
			pp = pp-(emi-pi);
		}
		let headers = [
			{ label: "Principal O/s", key: "PrincipalOS" },
			{ label: "Towards Interest", key: "TowardsInterest" },
			{ label: "Towards Principal", key: "TowardsPrincipal" },
			{ label: "EMI", key: "EMI" }
		];
		csv_data.push({PrincipalOS: '', TowardsInterest: Number(towardsInterest).toFixed(2),TowardsPrincipal:Number(towardsPrincipal).toFixed(2),EMI:Number(emi * n).toFixed(2)});
		setCsvData(csv_data);
		setCsvHeader(headers);

		setloanSchedule(result);
		setTowardsInterest(Number(towardsInterest).toFixed(2));
		setTowardsPrincipal(Number(towardsPrincipal).toFixed(2));
		setLoanScheduleDiv(true);
	}
    const handleLoan =(e) => {
		e.preventDefault();
		Common.showLoader(true);
		let formData = new FormData(e.target);
		let pid = localStorage.getItem('fo_profileid');
		formData.append('pid', pid);
		formData.append('loan_schedule', JSON.stringify(loanSchedule));
        formData.append('loan_provider', loan_provider);
        formData.append('EMIAmount', EMIAmount);
        formData.append('bankAccount', bankAccount);
        formData.append('payment_mode', payment_mode);
		let data = {};
		// convert the key/value pairs
		for (var [key, value] of formData.entries()) {
		   data[key] = value;
		}

		if( data.loan_taken_date !== '' && (bankAccount === undefined || bankAccount === 0 || bankAccount === '')){
			Common.showLoader(false);
			Common.toast.dismiss();
			Common.toast.error('Bank Account is required.');
			return false;
		}
		if( data.loan_taken_date && payment_mode === '' ){
			Common.showLoader(false);
			Common.toast.error('Payment Mode is required');
			return false;
		}
        // setSaveBtnDisabled(true);
		Common.axios({
			method: 'post',
			url: props.url+'loans/add',
			data: data
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				navigate("/loan-listing");
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const setPaymentModeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		console.log(value);
		setPaymentMode(value);
		if(value === 'Cash'){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/'+value,
			}).then(function (response) {
				if(response.data.success){
					if(response.data.data !== ''){
						setDefaultPayee([response.data.data[0]]);
						setBankAccount(response.data.data[0].value);
						setBankList(response.data.data);
					}else{
						setDefaultPayee([]);
						setBankAccount(0);
					}
				}
			}).catch(error => console.log(error));
		}else{
			setDefaultPayee([]);
			setBankAccount(0);
			setListOfBanks();
		}
	}
	const setListOfBanks = () => {
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				// setDefaultPayee(response.data.defaultPayee);
				// if (response.data.defaultPayee.length > 0) {
				// 	setBankAccount(response.data.defaultPayee[0].value);
				// }
			}
		}).catch(error => console.log(error));
	}
	const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : 0;
		setBankAccount(value);
		// setDefaultPayee('');
		if (value === 0) {
			setDefaultPayee([]);
		} else {
			bankList.forEach((bank,i) => {
				if (bank.value === value) {
					setDefaultPayee([bank]);
				}
			});
		}
	}
	const handleLoanDate = (e) => {
		if (e === null) {
			setLoanTakenDate('')
			setDefaultPayee([]);
			setListOfBanks();
		} else {
			setLoanTakenDate(e)
		}
	}

    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="add-loan" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Loans & Investments /</span> Add Loan Taken</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            <form id="formAddCollection" onSubmit={handleLoan} autoComplete="off" className='formAddCollection'>
                                            <div className="row" id="collectionAddDiv">
													<div className="col-12">
														<div className="accordion" id="salesAccordion">
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingTwo">
																	<button type="button" className="accordion-button" data-bs-target="#accordionVoucher" aria-expanded="false" aria-controls="accordionVoucher" disabled>
																		Loan Details
																	</button>
																</h2>
																<div id="accordionVoucher" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <Typeahead clearButton allowNew newSelectionPrefix="Add: " id="frmLoanProvider" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setLoanProviderSelect(e)} options={loan_provider_master} placeholder="Choose Loan Provider"
                                                                                            renderMenuItemChildren={(option: Item,props) => (
                                                                                                <>
                                                                                                <Highlighter search={props.text}>
                                                                                                    {option.label}
                                                                                                </Highlighter>
                                                                                                </>
                                                                                            )}
                                                                                        />
																					<label htmlFor="frmLoanProvider">Loan Provider <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" name="loan_amt" className="form-control" id="frmLoanAmt" placeholder="0.00" value={loan_amt} onChange={(e) => { setLoanAmt(Common.acceptOnlyDecimal(e.target.value)); }} required/>
																					<label htmlFor="frmLoanAmt">Loan Amount <span className="text-danger">*</span></label>
																				</div>
																			</div>
																		</div>
                                                                        <div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <DatePicker name="loan_taken_date" data-testid="frmVoucherDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={(e) => {handleLoanDate(e)}} value={loan_taken_date} />
																					<label htmlFor="frmLoanTakenDate">Date of Loan taken</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input name="loan_period" type="text" maxLength="12" className="form-control" id="frmLoanPeriod" placeholder="Enter Loan Period" onChange={(e) => { setLoanPeriod(Common.acceptOnlyDecimal(e.target.value));calcuateTotalLoanPeriod(Common.acceptOnlyDecimal(e.target.value),'LP'); }}  value={loan_period} />
																					<label htmlFor="frmLoanPeriod">Loan Period (in years)</label>
																				</div>
																			</div>
																		</div>
                                                                        <div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input name="moratorium_period" type="text"  className="form-control" id="frmMoratoriumPeriod" placeholder="Enter Moratorium Period" onChange={(e) => { setMoratoriumPeriod(Common.acceptOnlyDecimal(e.target.value));calcuateTotalLoanPeriod(Common.acceptOnlyDecimal(e.target.value),'MP'); }} value={moratorium_period} />
																					<label htmlFor="frmMoratoriumPeriod">Moratorium period (in months)</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input name="total_loan_period" type="text" maxLength="12" className="form-control" id="frmTotalLoanPeriod" placeholder="Enter Total Loan Period" value={total_loan_period} readOnly />
																					<label htmlFor="frmTotalLoanPeriod">Total Loan Period (in Months)</label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col-md-3 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <select id="frmRepaymentFrequency" className="form-select" name='repayment_frequency'  value={repayment_frequency} onChange={(e) => {setRepaymentFrequency(e.target.value);}} required>
                                                                                        <option value="">Select</option>
                                                                                        <option value="Monthly">Monthly</option>
                                                                                    </select>
																					<label htmlFor="frmRepaymentFrequency">Repayment frequency <span className="text-danger">*</span></label>
																				</div>
																			</div>
                                                                            <div className="col-md-3 col-sm-6 mb-3">
																				<div className="form-floating">
                                                                                    <input type="text" name="rate_of_intrest" className="form-control" id="frmRateOfIntrest" placeholder="0.00" onChange={(e) => { setRateOfInterest(Common.acceptOnlyDecimal(e.target.value)); }} value={rate_of_intrest}  required/>
																					<label htmlFor="frmRateOfIntrest">Rate of interest (PA) <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
                                                                                <div className="form-floating">
																					<select id="frmRepaymentDueDate" className="form-select" name='repayment_due_date'  value={repayment_due_date} onChange={(e) => {setRepaymentDueDate(e.target.value);}}>
																						<option value="">Select</option>
																						<option value="1">1</option>
																						<option value="2">2</option>
																						<option value="3">3</option>
																						<option value="4">4</option>
																						<option value="5">5</option>
																						<option value="6">6</option>
																						<option value="7">7</option>
																						<option value="8">8</option>
																						<option value="9">9</option>
																						<option value="10">10</option>
																						<option value="11">11</option>
																						<option value="12">12</option>
																						<option value="13">13</option>
																						<option value="14">14</option>
																						<option value="15">15</option>
																						<option value="16">16</option>
																						<option value="17">17</option>
																						<option value="18">18</option>
																						<option value="19">19</option>
																						<option value="20">20</option>
																						<option value="21">21</option>
																						<option value="22">22</option>
																						<option value="23">23</option>
																						<option value="24">24</option>
																						<option value="25">25</option>
																						<option value="26">26</option>
																						<option value="27">27</option>
																						<option value="28">28</option>
																						<option value="29">29</option>
																						<option value="30">30</option>
																						<option value="31">31</option>
																					</select>
																					<label htmlFor="frmRepaymentDueDate">Repayment due date of a month</label>
																				</div>
																			</div>
																		</div>
                                                                        <div className="row">
																			{/* payment mode here */}
																			<div className="col-md-6 col-sm-6 mb-3">
																			{loan_taken_date && <>
																				<div className="form-floating">
																				<Typeahead defaultSelected={paymentModes.slice(1, 2)} clearButton id="frmPayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
																					options={paymentModes}
																					renderMenuItemChildren={(option: Item,props) => (
																						<Highlighter search={props.text}>
																							{option.label}
																						</Highlighter>
																					)}
																				/>
																					<label htmlFor="frmPayMode">Payment Mode <span className="text-danger">*</span></label>
																				</div>
																			</>}
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																			{loan_taken_date && <>
																				<div className="form-floating">
																				<Typeahead selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
																					options={bankList}
																					renderMenuItemChildren={(option: Item,props) => (
																						<Highlighter search={props.text}>
																							{option.label}
																						</Highlighter>
																					)}
																				/>
																					<label htmlFor="frmBankAccount">Bank Account <span className="text-danger">*</span></label>
																				</div>
																			</>}
																			</div>
																		</div>
																		<div className="row">
																			<div className="col-sm-12 text-center">
																				<button type='button' className="btn btn-sm btn-success"  onClick={()=>{calculateLoanSchedule();}} ><i className='bx bx-calculator'></i> Calculate Loan Schedule</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
                                                    {loan_schedule_div && (<>
                                                    <div className="col-12">
														<div className="accordion" id="salesAccordion">
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingTwo">
																	<button type="button" className="accordion-button" data-bs-target="#accordionVoucher" aria-expanded="false" aria-controls="accordionVoucher" disabled>
																		Loan Schedule
																	</button>
																</h2>
																<div id="accordionVoucher" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="card">
																				<div className="row">
																					<div className="col-sm-8">
																						<h6 className="card-header">EMI per month : { EMIAmount } | Total Repayment : { totalEMIAmount }</h6>
																					</div>
																					<div className="col-sm-4 text-right">
																						<CSVLink data={csvData} headers={csvHeader} filename={"Loan Schedule.csv"} className="btn btn-primary btn-sm" target="_blank"><i className='bx bx-download'></i> Download CSV</CSVLink>
																					</div>
																				</div>
																				{loanSchedule.length === 0 && <div className="text-center py-1">No Data Available</div>}
																				{loanSchedule.length > 0 && <>
																				<div className="table-responsive text-nowrap">
																					<table className="table table-sm table-bordered">
																						<thead className="table-light">
																							<tr>
																								<th>Sl.</th>
																								<th align="right">Principal O/s</th>
																								<th align="right">Towards Interest</th>
																								<th align="right">Towards Principal</th>
																								<th align="right">EMI</th>
																							</tr>
																						</thead>
																						<tbody className="table-border-bottom-0">
																							{loanSchedule.map((val,index) => (
																								<tr key={'pay'+index}>
																									<td>{index+1}</td>
																									<td align="right">{Common.displayPrice(val.PrincipalOS)}</td>
																									<td align="right">{Common.displayPrice(val.TowardsInterest)}</td>
																									<td align="right">{Common.displayPrice(val.TowardsPrincipal)}</td>
																									<td align="right">{Common.displayPrice(val.EMI)}</td>
																								</tr>
																							))}
																							<tr key='pay_total'>
																								<td></td>
																								<td></td>
																								<td align="right">{Common.displayPrice(towardsInterest)}</td>
																								<td align="right">{Common.displayPrice(towardsPrincipal)}</td>
																								<td align="right">{Common.displayPrice(totalEMIAmount)}</td>
																							</tr>
																						</tbody>
																					</table>
																				</div>
																				</>}
																			</div>
                                                                        </div>
																		<div className="row mt-4">
																			<div className="col-sm-7 text-right">
																				<a href="/loan-listing" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
																				&nbsp;&nbsp;
																				<button type='submit' className="btn btn-sm btn-primary"><i className='bx bx-save' ></i> Save</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
                                                    </>)}
												</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}