import React from 'react';
import AccounHeader from './AccountHeader';
import '../styles/page-misc.css';

export default function Privacy(props) {
	React.useEffect(() => {
		document.title = 'FYN ONE - Billing & Accounting app for Bharat | Privacy';
	}, []);
	return (
		<div className="container-xxl container-p-y">
			<AccounHeader site_logo={props.site_logo} site_name={props.site_name} />
			<div className="other-wrapper mx-5">
				<div className="text-center"><h2 className="mt-5 mx-2">Privacy Notice & Cookie Policy</h2></div>
				<div>Whilst most of our business and the visitors to our site are B2B(business-to-business), we respect all personal data (including individually recognisable corporate email addresses) and understand that occasionally, some individuals, sole traders and partnerships may also use our products and services. Therefore, the provisions, measures and rights in this privacy notice are applied to all forms of data collected on our site and through our company.</div>
				<div className="mt-5">
					<div className="subHead">Who We Are</div>
					<b>FynOne</b>, a product of Fynamics Techno Solutions Pvt Ltd is a company registered in Bangalore, Karnataka, India under GST Id: 29ABACS0877M1ZS with our registered office at: #302, Margosa Plaza, #50-56, Margosa Road, Malleswaram, Bangalore – 560 055.
				</div>
				<div className="mt-5">
					<div className="subHead">Information That We Collect</div>
					<b>FynOne</b> processes your personal information to meet our legal, statutory and contractual obligations and to provide you with our products and services. We will never collect any unnecessary personal data from you and do not process your information in any other way than already specified in this notice.
				</div>
				<div className="mt-5">
					<div className="subHead">The Personal Data That We Collect From and Process Is: -</div>
					<ul className="list-unstyled">
						<li>Name</li>
						<li>Business or Home Address</li>
						<li>Mobile Number</li>
						<li>Email Address</li>
					</ul>
				</div>
				<div className="mt-5">
					<div className="subHead">We Will Collect Information From You If You: –</div>
					<ul className="list-unstyled">
						<li>Contact us via email or telephone</li>
						<li>Place an order for products or services on our website</li>
						<li>Complete online contact form</li>
						<li>Provide your contact details to us when registering to use online services on our website</li>
						<li>Contact us offline for example by telephone and email</li>
						<li>Visit or browse our website in accordance with our cookie policy at the end of this notice</li>
					</ul>
				</div>
				<div className="mt-5">
					<div className="subHead">How We Use Your Personal Data (Legal Basis for Processing)</div>
					<b>FynOne</b> takes your privacy very seriously and will never disclose, share or sell your data without your consent, unless required to do so by law. We only retain your data for as long as is necessary and for the purposes specified in this notice.
					<br/><br/><br/>
					We <b>do not</b> ask you to sign up to any marketing or mailing lists and will only contact you after you show interest on the solutions that we provide. Processing for any purpose other than those specified in this policy are only done with your consent, which you are free to withdraw at any time.
				</div>
				<div className="mt-5">
					<div className="subHead">The purposes and reasons for processing your personal data are detailed below: –</div>
					<ul className="list-unstyled">
						<li>We collect your personal data in the performance of a contract and to provide you with our products and document updates</li>
						<li>We use your personal information to answer your queries and provide industry specific advice</li>
						<li>We collect and store your personal data as part of our legal obligation for business accounting and tax purposes</li>
					</ul>
					We may also contact you for feedback on your use of our products, services or our website and may need to use your information to send important notices, such as updated document content where there have been regulation / law revisions or changes to our terms, conditions and policies.
				</div>
				<div className="mt-5">
					<div className="subHead">Your Rights</div>
					You have the right to access personal information that FynOne hold or process about you and to request information about: –
					<ul className="list-unstyled mt-2">
						<li>What personal data we hold</li>
						<li>The purposes of the processing</li>
						<li>The categories of personal data concerned</li>
						<li>The recipients to whom the personal data has / will be disclosed</li>
						<li>How long we intend to store your personal data for</li>
						<li>If we did not collect the data directly from you, information about the source</li>
					</ul>
					If you believe that we hold any incomplete or inaccurate data about you, you have the right to ask us to correct and / or complete the information and we will strive to update / correct it as quickly as possible, unless there is a valid reason for not doing so, at which point you will be notified.
					<br/><br/>
					You also have the right to request erasure of your personal data or to restrict processing in accordance with the data protection law, as well as to object to any direct marketing from us and to be informed about any automated decision-making that we use.
					<br/><br/>
					If we receive a request for any of the above rights, we may ask you to verify your identity before acting on the relevant request; this is to ensure that your data is protected and kept secure. If you create an online account with us, you will be able to modify and update your own personal information by logging into your user account.
				</div>
				<div className="mt-5">
					<div className="subHead">Sharing and Disclosing Your Personal Information</div>
					We do not share or disclose any of your personal information without your consent, other than for the purposes specified in this notice, where there is a legal requirement or to enforce our terms and conditions. <b>FynOne</b> does not transfer your data outside of the EEA(European Economic Area) and will always ask for consent if this becomes a requirement.
					<br/><br/>
					We utilise the below processors / controllers who act on our behalf to provide the below business functions and services. (They act in accordance with instructions from us and comply fully with this and their own privacy notice, the data protection laws and any other appropriate confidentiality and security measures).
				</div>
				<div className="mt-5">
					<div className="subHead">The Third-Party Processors / Controllers That We Work With Are: –</div>
					We use PayPal and CC Avenue to process the credit / debit card transactions on our website; with our checkout using an embedded form that securely accepts the customer’s payment details and directly passes them to PayPal and CC Avenue’s servers, ensuring maximum security. We do not have access to any sensitive card information and do not utilise the option to store such information for a return visit; further protecting your data. PayPal and CC Avenue act as a data controller for your personal data and have one of the highest levels of security in the industry.
				</div>
				<div className="mt-5">
					<div className="subHead">Safeguarding Measures</div>
					<b>FynOne</b> takes your privacy seriously and take every reasonable measure and precaution to protect and secure your personal data. We work hard to protect you and your information from unauthorised access, alteration, disclosure or destruction and have several layers of security measures in place, including secure hosting of our website, anti-virus, firewall and malware protections on all device and networks, use of a site-wide SSL certificate, forcing securing passwords for users creating accounts and securing access to our site.
				</div>
				<div className="mt-5">
					<div className="subHead">Consequences of Not Providing Your Data</div>
					You are not obligated to provide your personal information to FynOne, however, as it is required to process your request and meet our business obligations, we will be unable to provide you with our products without it.
				</div>
				<div className="mt-5">
					<div className="subHead">How Long We Keep Your Data</div>
					<b>FynOne</b> retains personal information for as long as it is necessary, and we have strict review and retention policies in place to meet these obligations. Data retention policies are as per mutually agreed terms in the contract.
				</div>
				<div className="mt-5">
					<div className="subHead">Lodging A Complaint</div>
					<b>FynOne</b> only processes your personal information in compliance with this privacy notice and in accordance with the relevant data protection laws. If, however you wish to raise a complaint regarding the processing of your personal data or are unsatisfied with how we have handled your information, you have the right to lodge a complaint with the supervisory authority.
				</div>
				<div className="mt-5">
					<div className="subHead">Fynamics Techno Solutions Pvt Ltd</div>
					No 202, Spearhead Towers,<br/>
					#50-56 Margosa Road, Malleswaram<br/>
					Bangalore – 560 055<br/>
					Email: support@fynone.com
				</div>
				<div className="mt-5">
					<div className="subHead">Use of Cookies on Our Website</div>
					A ‘cookie’ is a small piece of data sent from a website and stored on the user’s computer by the user’s web browser while the user is browsing. When you visit a site that uses cookies for the first time, a cookie is downloaded onto your computer / mobile device so that the next time you visit that site, your device will remember useful information such as visited pages or logging in options.
					<br/><br/>
					Under the GDPR, if a cookie acts as an online identifier or is classed as personal data, you have the right to turn such cookies off when visiting a website. At FynOne we only use cookies on our site that are essential for the normal functioning of the website and as such cannot be turned off. These include cookies for our online checkout, contact form and website security. We do not use any non-essential cookies (i.e. adverts, analytics, third-parties, social media etc) and so do not have an option to turn these off. However, you can also control the settings of website cookies in your browser settings.
				</div>
				<div className="mt-5">
					<div className="subHead">External Links</div>
					Our website may have links to other sites which includes third party websites or resources. We are not responsible for the privacy policies and information practices of third party websites. You should exercise caution and look at the privacy statement applicable to the website in question.
				</div>
				<div className="mt-5">
					<div className="subHead">Personal Information Policy Image and Video Capture</div>
					Any images captured during events organized and hosted by us should not be misused. That includes pictures of our staff and training participants.
				</div>
				<div className="mt-5">
					<div className="subHead">Sharing your personal Information</div>
					If you have purchased our products, we may ask you for testimonial and if you authorize us, we will display your testimonial, photos and videos on our website or in our social media channels like Facebook, YouTube or Twitter. You should be aware that your publicly identifiable information could be used to send you promotional, unsolicited messages. We are not responsible for your personal information which you have chosen to display.
					<br/><br/>
					If you don't want us to feature your pictures/testimonials on our website or on our social media channels, you can write a mail to support@fynone.com
				</div>
				<div className="mt-5">
					<div className="subHead">Personal Information Corrections</div>
					You can contact us if you notice that the information we are holding is incorrect or incomplete. Please send us a mail to support@fynone.com
				</div>
				<div className="mt-5">
					<div className="subHead">Changes to Privacy Policy</div>
					This privacy policy terms may change time to time and we reserve the right to change the terms of this privacy policy at any time. When we update the privacy policy, all our registered users will be notified via email.
				</div>
				<div className="mt-5">
					<div className="subHead">Deleting Personal Information</div>
					If you would like to delete your account, write to support@fynone.com
				</div>
				<div className="mt-5">
					<div className="subHead">Privacy Policy Questions</div>
					If you have any questions regarding our Privacy Policy, please write to support@fynone.com
				</div>
				<div className="text-center mt-5">
					<a href="/" className="btn btn-info">Back to Home</a>
				</div>
			</div>
		</div>
	)
}