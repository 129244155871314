import React,{useState,useEffect} from 'react';
import {isMobile} from 'react-device-detect';
import Layout from './Layout';
import NavBar from './NavBar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import * as Common from '../common/ImportUserEssentials';
import '../styles/user_groups.css';
export default function AddUser(props){
	const [dt_load,setDtLoad] = React.useState(true);
	const [name, setName]         = useState('');
	const [groups, setGroups]         = useState([]);
	const [userMgmt_w,setuserMgmt_w] = React.useState(true);
	const navigate = Common.useNavigate();
	const [viewDetails,setViewDetails] = React.useState();
	const [modifyModal, setModifyModal] = useState(false);

	const [initialVal, setInitialValue] = useState(props);
	useEffect(() => {
		document.title = 'User Groups| '+props.site_name;
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));

		if( Object.keys(userPerm).length > 0 ){
			// Write permission
			setuserMgmt_w((userPerm[5]['write'] !== undefined && userPerm[5]['write'] === '0') ? false : true);
		}
		loaduserGroups();
	}, [props.site_name,dt_load]);
	if( !userMgmt_w ){
		navigate("/not-authorized");
	}

	function loaduserGroups(){
		Common.axios({
            method: 'post',
            url:props.url+'user-groups/list',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
        }).then(function (response) {
            if(response.data.success){
				setGroups(response.data.data);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const modify = (id) => {
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'user-groups/viewDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),'id':id}
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
				setViewDetails(response.data.data);
				setInitialValue({
					list1: response.data.itemListNo,
					list2: response.data.itemListYes
				})
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
		setModifyModal(true);
	}
	const formSubmit = (e) => {
		e.preventDefault();
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'user-groups/add',
			data: {'name':Common.AES.encrypt(name, props.site_key).toString(),'pid':Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()},
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setDtLoad(!dt_load);
				Common.toast.success(response.data.message);
				setName('');
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const setUgDetails = (param,e) => {
		let details = viewDetails;
		if( param === 'status' ){
			details[param] = e ? '1' : '0';
		} else {
			details[param] = e.target.value;
		}
		setViewDetails(details);
		setDtLoad(!dt_load);
	}
	function updateDetails(e){
		e.preventDefault();
		setModifyModal(false);
		Common.axios({
			method: 'post',
			url: props.url+'user-groups/updateDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),'details':Common.AES.encrypt(JSON.stringify(viewDetails), props.site_key).toString(),'list2':Common.AES.encrypt(JSON.stringify(initialVal.list2), props.site_key).toString()}
		}).then(function (response) {
            if(response.data.success){
				setDtLoad(!dt_load);
				Common.toast.success(response.data.message);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const handleChange = (e) => {
        let value = parseInt(e.target.value);
        initialVal.list1.map((res1) => {
            if (parseInt(res1.id) === value) {
                res1.checked = e.target.checked;
                return res1.checked
            }
            return null;
        })

        initialVal.list2.map((res2) => {
            if (parseInt(res2.id) === value) {
                res2.checked = e.target.checked;
                return res2.checked 
            }
			return null;
        })

        //update list state
        setInitialValue({
            list1: initialVal.list1,
            list2: initialVal.list2
        });
    }
	const LeftlistDisplay = (initialVal.list1 !== undefined && initialVal.list1.length) ? (initialVal.list1.map(leftRes => {
        return (
            <div key={"left_"+leftRes.id} className="subContainer">
				<div key={"ck_"+leftRes.id} className="form-check m-2">
					<input key={"chk_"+leftRes.id} className="form-check-input" type="checkbox" checked={leftRes.checked} value={leftRes.id} id={"check_"+leftRes.id} onChange={handleChange}/>
					<label key={"label_"+leftRes.id} className="form-check-label" htmlFor={"check_"+leftRes.id}> {leftRes.text} </label><button key={"btn_"+leftRes.id} type="button" className="btn btn-sm btn-link p-0" data-bs-toggle="collapse" data-bs-target={"#accordion"+leftRes.id} aria-expanded="true" aria-controls={"accordion_"+leftRes.id}><i className='bx bx-plus'></i></button>
					<div key={"accordion_"+leftRes.id} id={"accordion"+leftRes.id} className="text-break collapse">
					  <small>Mobile: {leftRes.mobile}</small>
                    </div>
				</div>
            </div>
        )
    })
    ) : (
            <div className="text-center text-danger">No users</div>
        )

	const handleMove = (direction) => {
        if (direction === 'left') {
            initialVal.list1.map((result) => {
                if (result.checked === true) {
                    initialVal.list2.push(result);
                    setInitialValue({
                        list1: initialVal.list1.filter(i => !i.checked),
                        list2: [...initialVal.list2]
                    });
                }
                return null
            });

        }
        else {
            if (direction === 'right') {
                initialVal.list2.map((result) => {
                    if (result.checked === true) {
                        initialVal.list1.push(result);
                        setInitialValue({
                            list1: [...initialVal.list1],
                            list2: initialVal.list2.filter(i => !i.checked)
                        });
                    }
                    return null
                });
            }
        }
    }

    const moveAllData = (direction) => {
        if (direction === 'left') {
            setInitialValue({
                list1: [...initialVal.list1, ...initialVal.list2],
                list2: []
            })
        } else {
            setInitialValue({
                list2: [...initialVal.list2, ...initialVal.list1],
                list1: []
            })
        }
    }
	const RightlistDisplay = (initialVal.list2 !== undefined && initialVal.list2.length) ? (initialVal.list2.map(rightRes => {
        return (
			<div key={"right_"+rightRes.id} className="subContainer" >
				<div key={"ck_"+rightRes.id} className="form-check m-2">
					<input key={"chk_"+rightRes.id} className="form-check-input" type="checkbox" checked={rightRes.checked} value={rightRes.id} onChange={handleChange} id={"check_"+rightRes.id}/>
					<label key={"label_"+rightRes.id} className="form-check-label" htmlFor={"check_"+rightRes.id}> {rightRes.text} </label><button key={"btn_"+rightRes.id} type="button" className="btn btn-sm btn-link p-0" data-bs-toggle="collapse" data-bs-target={"#accordion"+rightRes.id} aria-expanded="true" aria-controls={"accordion_"+rightRes.id}><i className='bx bx-plus'></i></button>
					<div key={"accordion_"+rightRes.id} id={"accordion"+rightRes.id} className="text-break collapse">
						<small>Mobile: {rightRes.mobile}</small>
					</div>
				</div>
			</div>
        )
    })
    ) : (
            <div className="text-center text-danger">No users</div>
        )
    return (
        <>
        <div id="fo_usergroup" className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="user-groups" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">User Management /</span> User Groups</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
											<form onSubmit={formSubmit} autoComplete="off">
												<div className="row justify-content-md-center">
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="ugroup" className="form-label">User Group<span className="text-danger">*</span></label>
                                                        <div className="input-group input-group-merge">
                                                            <span className="input-group-text"><i className="bx bx-group"></i></span>
                                                            <input type="text" className="form-control" id="ugroup" name="name" placeholder="Enter Name (Max 25 chars)" maxLength="25" value={name} onChange={(e)=>setName(Common.acceptOnlyCharsNumbers(e.target.value))} required />
															<Button variant="primary" onClick={formSubmit}>Add</Button>
                                                        </div>
                                                    </div>
												</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div className="row mb-5 justify-content-center">
								{groups.map( (grp,index) => {
									return (<div key={"crd_"+index} className="card-group col-md-6 col-lg-4">
										<div className="card mb-3">
											<div className="card-body">
												<h5 className="card-title">{grp.name} <Button onClick={(e)=>modify(grp.id)} variant="primary btn-sm"><i className='bx bxs-edit'></i></Button></h5>
												<div className="card-text text-center">
													{grp.users.map( (user,index) => {
														return (
															<div key={"avatar_"+index} className="avatar d-inline-block">
																<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent(user.text)}>
																	<p data-letters={Common.userInitial(user.text)}></p>
																</Common.OverlayTrigger>
															</div>
														)
													})}
													{grp.users.length === 0 &&
													<div className="alert alert-warning">No users</div>
													}
												</div>
											</div>
											<div className="card-footer pt-0 text-center">
												<div><small className="text-muted">Created By: {grp.createdby} on {Common.displayDate(grp.createdon)}</small></div>
												{grp.updatedon !== null && grp.updatedon !== '0000-00-00 00:00:00' && <>
												<div><small className="text-muted">Updated By: {grp.updatedby} on {Common.displayDate(grp.updatedon)}</small></div>
												</>}
											</div>
										</div>
									</div>)
								})}
								{(groups.length === 0 ) && 
								<div className="col text-center"><div className="alert alert-primary" role="alert">No user groups yet!</div></div>
								}
							</div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal fullscreen={isMobile} show={modifyModal} onHide={()=>{setModifyModal(false);setViewDetails()}} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title className="text-capitalize">Modify User Group</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="modifyDetails" onSubmit={updateDetails} autoComplete="off">
					<Common.Row className="mb-3">
						<label htmlFor="ugname" className="col-form-label col-md-3">User Group<span className="text-danger">*</span></label>
						<div className="col-md-9"><input maxLength="25" className="form-control" id="ugname" type="text" name="name" onChange={(e)=>setUgDetails('name',e)} value={viewDetails !== undefined ? viewDetails.name : ''} required/></div>
					</Common.Row>
					<Common.Row className="mb-3">
						<label htmlFor="ugstatus" className="col-form-label col-md-3">Status</label>
						<div className="col-md-9">
							<BootstrapSwitchButton
								checked={viewDetails !== undefined ? (viewDetails.status === '1' ) : '0'}
								onlabel='Active'
								offlabel='Inactive'
								onstyle="success"
								offstyle="danger"
								width={100}
								onChange={(checked: boolean) => {
									setUgDetails('status',checked);
								}}
							/>
						</div>
					</Common.Row>
					<Common.Row>
						<div className="containerDnd bg-secondary rounded">
							<div className="left-side"><h6 className="text-center"><u>Users not in Group</u></h6>{LeftlistDisplay}</div>
							<div className="buttons-container">
								<button key="move_btn_1" type="button" onClick={() => { handleMove('left') }}><i className='bx bx-chevron-right'></i></button><br />
								<button key="move_btn_2" type="button" onClick={() => { moveAllData('right') }}><i className='bx bx-chevrons-right'></i></button><br />
								<button key="move_btn_3" type="button" onClick={() => { handleMove('right') }}><i className='bx bx-chevron-left'></i></button><br />
								<button key="move_btn_4" type="button" onClick={() => { moveAllData('left') }}><i className='bx bx-chevrons-left'></i></button><br />
							</div>
							<div className="right-side"><h6 className="text-center"><u>Users in Group</u></h6>{RightlistDisplay}</div>
						</div>
					</Common.Row>
					<Common.Row className="mt-5">
						<div className="text-center">
							<button type="submit" className="btn btn-sm btn-primary"><span className="bx bx-save"></span>&nbsp;Save</button>
						</div>
					</Common.Row>
				</form>
			</Modal.Body>
		</Modal>
        </>
    );
}