import React,{useState} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import Select from "react-select";
import Button from 'react-bootstrap/Button';
import * as Common from '../common/ImportUserEssentials';
import supplyTypes from '../masters/SupplyTypes.js';
import docTypes from '../masters/DocTypes.js';
import paymentModes from '../masters/PaymentModes.js';
// import deductionList from '../masters/Deductions.js';
import { Typeahead,AsyncTypeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import '../styles/sales.css';
import Moment from 'react-moment';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Modal from 'react-bootstrap/Modal';
import CustomInvFields from './CustomInvFields';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import '../styles/text-editor.css';
import * as DTE from '../common/ImportDataTableEssentials';
import ItemCustomFields from './ItemCustomFields';

export default function CopySale(props){
	// User Type
	let p1 = false;/*,p2 = false,p3 = false,p4 = false;*/

	const [state_master, setStateMaster] = useState([]);
	const [income_type_master, setIncomeTypeMaster] = useState([]);
	const [income_subtype_master, setIncomeSubTypeMaster] = useState([]);
	const [profile_details,setProfileDetails] = useState({name:'',gstin:'',legal_name:'',trade_name:'',addr:'',district:'',pincode:'',state:'',declaration:'',tnc:'',licence:''});
	const [payee,setPayee] = useState('');
	const [cid, setCid]        = useState(0);
	const [update_cust,setUpdateCust] = useState(0);
	const [custSel,setCustSel] = useState([]);
	const [cust_name, setCustName]        = useState('');
	const [cust_phone, setCustPhone]      = useState('');
	const [cust_phone_disabled, setCustPhoneDisabled]      = useState(false);
	const [custAddiDiv,setCustAddiDiv]    = useState(false);
	const [invAddiDiv,setInvAddiDiv]    = useState(false);
	const [docTaxAddiDiv,setDocTaxAddiDiv]    = useState(false);
	const [cust_gstin,setCustGstin]    = useState('');
	const [cust_legal_name,setCustLegalName]   = useState('');
	const [cust_trade_name,setCustTradeName]   = useState('');
	const [cust_pincode, setCustPincode]       = useState('');
	const [cust_addr,setCustAddr]              = useState('');
	const [cust_district,setCustDistrict]      = useState('');
	const [cust_state,setCustState]            = useState([]);
	const [cust_pos,setCustPOS]            = useState([]);
	const [cust_email, setCustEmail] = useState('');
	const [doc_no,setDocNo]            = useState('');
	const [draftDocNo,setDraftDocNo]   = useState('');
	const [doc_type,setDocType]            = useState([{'value':'INV','label':'','digits':''}]);
	const [doc_type_action,setDocAction] = useState(0);
	const [doc_date, setDocDate] = useState(new Date());
	const [ref_doc_date, setRefDocDate] = useState('');
	const [isRefLoading, setIsRefLoading] = useState(false);
	const [refInvoice, setRefInvoice] = useState([]);
	const [refInvSel,setRefInvSel] = useState([]);
	const [refId,setRefId] = useState('');
	const [refInvNo,setRefInvNo] = useState('');
	const [cdReason,setCDReason] = useState('');
	const [refTotalValue,setRefTotalValue] = useState('');
	const [refPaymentDue,setRefPaymentDue] = useState('');
	// const [supply_type, setSupplyType]        = useState('B2C');
	const [supply_type,setSupplyType] = useState([{'value':'B2C','label':'','digits':''}]);
	const itemRefs = React.useRef([]);
	const [itemDiv,setItemDiv] = useState(false);
	const [invRemarksDiv,setInvRemarksDiv] = useState(false);
	const [dispDetDiv,setDispDetDiv] = useState(false);
	const [shipToDiv,setShipToDiv] = useState(false);
	const [total_amt,setTotalAmt]  = useState('');
	const [total_amt_disabled,setTotalAmtDisabled]  = useState(false);
	const [total_payment,setTotalPayment]  = useState('');
	const [total_deduction,setTotalDeduction]  = useState('');
	const [payments,setPayments] = useState([]);
	const [received,setReceived]                        = useState('');
	const [payment_date,setPaymentDate]  = useState(new Date());
	const [payment_mode,setPaymentMode]  = useState('UPI/QR');
	const [payment_remarks,setPaymentRemarks]  = useState('');
	const [trans_ref,setTransRef]  = useState('');
	const [due,setDue]                     = useState('');
	const [dueDate, setDueDate]            = useState('');
	const [taxes,setTaxes]    = useState([]);
	const [rev_chrg,setRevChrg]  = useState('N');
	const [income_type,setIncomeType]  = useState('');
	const [income_subtype,setIncomeSubType]  = useState('');
	const [igst_intra,setIgstIntra]  = useState('N');
	const [deductions,setDeductions] = useState([]);
	const [deduction_amt,setDeductionAmt]  = useState('');
	const [deduction_per,setDeductionPer]  = useState('');
	const frmSalePO = React.createRef();
	const frmSaleDedType = React.createRef();
	const frmSaleDedSubtype = React.createRef();
	const paymentReceivedRef = React.createRef();
	//const [deduction_remarks,setDeductionRemarks]  = useState('');
	const [incomeColl_w,setincomeColl_w] = React.useState(true);
	const [isCustLoading, setIsCustLoading] = useState(false);
	const [isItemLoading, setIsItemLoading] = useState(false);
	const navigate = Common.useNavigate();
	const [customers, setCustomers] = useState([]);
	const [items, setItems] = useState([]);
	const [payees, setPayeeMaster] = useState([]);
	const [defaultPayee, setDefaultPayee] = useState([]);
	const [po_master, setPoMaster] = useState([]);
    const [advance_amount, setAdvanceAmount] = useState(0);
	const [showAdvanceDiv, setShowAdvanceDiv] = useState(false);
	const [payModalShow, setPayModalShow] = useState(false);
	const [dedModalShow,setDedModalShow] = useState(false);
	const [temp_deduction,setTempDeduction] = useState([]);
	const [deduction_div,setDeductionDiv] = useState(false);
	const [input_items, setInputItems] = useState([])
	const [sale_items_loop,setSaleItemsLoop] = useState([0]);
	const [allocation_centre_master,setAllocationCentreMaster] = useState([]);
	const [allocation_centre,setAllocationCentre] = useState('');
	const [declaration, setDeclaration]       = useState('');
	const [tnc, setTnc]       = useState('');
	const [licence, setLicence]       = useState('');
	const [dispFrName, setDispFrName]       = useState('');
	const [dispFrAddr, setDispFrAddr]       = useState('');
	const [dispFrLoc, setDispFrLoc]       = useState('');
	const [dispFrPin, setDispFrPin]       = useState('');
	const [dispFrState, setDispFrState]       = useState([]);
	const [shipToGstin, setShipToGstin]       = useState('');
	const [shipToLglName, setShipToLglName]       = useState('');
	const [shipToTrdName, setShipToTrdName]       = useState('');
	const [shipToAddr, setShipToAddr]       = useState('');
	const [shipToLoc, setShipToLoc]       = useState('');
	const [shipToPin, setShipToPin]       = useState('');
	const [shipToState, setShipToState]       = useState([]);
	const [docIgst, setDocIgst]       = useState('0.00');
	const [docCgst, setDocCgst]       = useState('0.00');
	const [docSgst, setDocSgst]       = useState('0.00');
	const [totalItemCount, setTotalItemCount]        = useState([]);
	const [quickItemModalShow,setquickItemModalShow] = useState(false);
	const [expDetDiv,setExpDetDiv] = useState(false);
	const [isExpPortCodeLoading, setIsExpPortCodeLoading] = useState(false);
	const [portCodeMaster, setPortCodeMaster] = useState([]);
	const [portCode,setPortCode] = useState('');
	const [isExpForCurLoading, setIsExpForCurLoading] = useState(false);
	const [currencyMaster, setCurrencyMaster] = useState([]);
	const [expForCur,setExpForCur] = useState('');
	const [isExpCountryCodeLoading, setIsExpCountryCodeLoading] = useState(false);
	const [countryCodeMaster, setCountryCodeMaster] = useState([]);
	const [expCountryCode,setExpCountryCode] = useState('');
	const [expRefClm,setExpRefClm]  = useState('N');
	const [expShipbNo,setExpShipbNo] = useState('');
	const [expShipbDate,setExpShipbDate] = useState(new Date());
	const [expDutyAmt,setExpDutyAmt] = useState('');
	const [bankList, setBankList] = useState([]);
	const [bankAccount,setBankAccount] = useState(0);
    const [bank_cash_account,setBankCashAccount] = useState(1);
	const [etin,setEtin] = useState('');
	const [isAccountHeadSelected,setIsAccountHeadSelected] = useState(false);
	const [isNegativeBal, setIsNegativeBal] = useState(false);
	const [allowNegStock,setAllowNegStock] = useState(0);

	// PO/Contract Details
	const [po_no, setPoNo] = useState(0);

	// Quick Add Item
	// const gstRtRef = React.useRef('');
	const [itemName, setItemsName]         		  = useState('');
    const [item_unit, setItemUnit]         		  = useState('28');
	// const [item_service,setItemService] 		  = useState(false);
    const [itemHSN, setItemHSN]       			  = useState('');
    const [opening_stock, setOpeningStock] 		  = useState('0');
	const [opening_stock_date, setOpeningStockDt] = useState(new Date());
    const [min_qty, setMinQty]         		 	  = useState('1');
    const [item_price, setItemPrice]       	 	  = useState('');
	const [discount_amt,setDiscountAmt]  	 	  = useState('');
	const [discount_per,setDiscountPer]  	 	  = useState('');
	const [disPerDisabled,setDisPerDisabled] 	  = useState(true);
	const [disAmtDisabled,setDisAmtDisabled] 	  = useState(true);
    const [gross_amt, setGrossAmt]       	 	  = useState('');
    const [item_total, setItemTotal]       	 	  = useState('');
    const [units,setUnits]                 	 	  = useState([]);
    const [gst_rate, setGstRate]       		 	  = useState('');
    const [gst_amt, setGstAmt]       		 	  = useState('');
	const [dupTimeout, setDupTimeout] 		 	  = useState(null);
	const [itemServiceType, setItemServiceType] 	  = useState('');
    const [itemStockMovement, setItemStockMovement]       = useState('0');
	const [itemStockChecked,setItemStockChecked]  = useState(false);
	const [purchase_item_price, setPurchaseItemPrice] = useState('');
    const [purchase_gross_amt, setPurchaseGrossAmt] = useState('');
    const [purchase_item_total, setPurchaseItemTotal] = useState('');
	const [purchase_discount_amt,setPurchaseDiscountAmt]  = useState('');
	const [purchase_discount_per,setPurchaseDiscountPer]  = useState('');
	const [disPurchasePerDisabled,setDisPurchasePerDisabled]  = useState(true);
	const [disPurchaseAmtDisabled,setDisPurchaseAmtDisabled]  = useState(true);
    const [purchase_gst_amt, setPurchaseGstAmt]       = useState('');
    const [gst_rate_id, setGstRateId]       = useState('');

	const [doc_taxable_amt,setDoctaxableAmt]  	  = useState('0.00');
	const [doc_gst_amt,setDocGstAmt]  	 	  	  = useState('0.00');
	const [doc_total_amt,setDocTotalAmt]  	  	  = useState('0.00');
	const [docOtherCharge,setDocOtherCharge]  	  = useState('');
	const [docRoundoff,setDocRoundoff]  		  = useState('');
	const [docDiscount,setDocDiscount]  	  	  = useState('');
	const [docGstRate, setDocGstRate] 			  = useState([]);
	const [customInvFields,setCustomInvFields]	  = useState(false);
    const [customInputList, setCustomInputList]	  = useState([]);
	const [prefixSetting, setPrefixSetting]    	  = useState(false);
	const [submitType, setSubmitType]    	  	  = useState('0');
	const [sid, setSid]    	  	  				  = useState('');
	const [paymentCheckShow, setPaymentCheckShow] = useState(false);
	const [actionType, setActionType]    	  	  = useState('copy');

	// Customer list
	const [DTpending, setDTPending] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [custListModalShow, setCustListModalShow] = useState(false);
	const [customers_list_modal, setCustomersListModal] = useState([]);
	const [filterText, setFilterText] = React.useState('');
    const { state }                     = Common.useLocation();
	const [expPortSel,setExpPortSel] = useState([]);
	const [expCurrencySel,setExpCurrencySel] = useState([]);
	const [expCountrySel,setExpCountrySel] = useState([]);
	const [poNoSel,setPoNoSel] = useState([]);
	const [incomeTypeSel,setIncomeTypeSel] = useState([]);
	const [incomeSubTypeSel,setIncomeSubTypeSel] = useState([]);
	const [acentreSel,setAcentreSel] = useState([]);

	const [dividerDisplay, setDividerDisplay] = useState(true);
	const [additionalItemDiv, setAdditionalItemDiv] = useState(true);
	const [skuCode, setSkuCode] = useState('');
	const [barcode, setBarcode] = useState('');
	const [safetyStock, setSafetyStock] = useState('');
	const [reorderPoint, setReorderPoint] = useState('');
	const [reorderQty, setReorderQty] = useState('');
	const [maximumStockLevel, setMaximumStockLevel] = useState('');
	const [customItemFields,setCustomItemFields]	  = useState(false);
	const [customItemInputList, setCustomItemInputList]	  = useState([]);
	const [saveDraftInvoice, setSaveDraftInvoice] = useState('');
	const [draftInvoiceMsgShow,setDraftInvoiceMsgShow]	  = useState(false);
	const [copyType, setCopyType] = useState(0);

	// Advance Adjust
	const [advanceAdjustModalShow, setAdvanceAdjustModalShow] = useState(false);
	const [advanceBalance,setAdvanceBalance]  	 	   		 = useState('');
	const [adjustedAmount,setAdjustedAmount]  	 	     	 = useState('');
	const [advanceAdjustedRemarks,setAdvanceAdjustedRemarks] = useState('');
	const [advanceAdjust,setAdvanceAdjust] 					 = useState([]);
	const [totalAdjustedAmount,setTotalAdvanceAdjust]  		 = useState('');
	
	const [salePurchasedividerDisplay, setSalePurchaseDividerDisplay] = useState(true);
	const [salePurchaseAdditionalItemDiv, setSalePurchaseAdditionalItemDiv] = useState(true);
    const [itemAdditional,setItemAdditional] = useState(true);

	const [deductionMasterList, setDeductionMasterList] = useState([]);
	// const [defaultDeductionMasterSelected, setDefaultDeductionMasterSelected] = useState([]);
	const [deductionMaster, setDeductionMaster] = useState('');
	const [deductionMasterLabel, setDeductionMasterLabel] = useState('')

	const [deductionSubtypeMasterList, setDeductionSubtypeMasterList] = useState([])
	// const [defaultDeductionSubtypeMasterSelected, setDefaultDeductionSubtypeMasterSelected] = useState([])
	const [deductionSubtypeMaster, setDeductionSubtypeMaster] = useState('')
	const [deductionSubtypeMasterLabel, setDeductionSubtypeMasterLabel] = useState('');
	const [isDeductionMasterSelected, setIsDeductionMasterSelected] = useState(false);
	const [invoiceGenType, setInvoiceGenType] = useState(0);

	const paginationComponentOptions = {
		noRowsPerPage: true
	};

	// Bypass client-side filtering by returning `true`. Results are already
	const filterBy = () => true;


	React.useEffect(() => {
		if((state !== null)){
			setActionType(state.action);
			setCopyType(state.copy_type);
		}
		let tabTitle = (actionType === 'copy')?'Add Sale':'Edit Draft';
		document.title = tabTitle+' | '+props.site_name;
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Write permission
			setincomeColl_w((userPerm[2]['write'] !== undefined && userPerm[2]['write'] === '0') ? false : true);
		}
		const getInvNo = (doc_type,type = 0,supply_type,doc_date) => {
			if(state === null || (state !== null && state.action === 'copy' && state.copy_type === 0) || (type === 1  && state.copy_type === 0) || (state !== null && state.action === 'edit' && state.copy_type === 0 && invoiceGenType === 1) || (state !== null && state.action === 'copy' && state.copy_type === 1 && invoiceGenType === 1)){
			// if(state === null || type === 1){
				Common.axios({
					method: 'post',
					url:props.url+'sales/genInvNo',
					data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),doc_type:doc_type,supply_type:supply_type,doc_date:doc_date}
				}).then(function (response) {
					if(response.data.success){
						setDocNo(response.data.data);
						return false;
					}
					Common.toast.error(response.data.message);
				}).catch(error => console.log(error))
			}
		}
		// Fetch invoice No
		getInvNo(doc_type.length > 0?doc_type[0].value:'INV',0,supply_type,doc_date);
		const inv_interval = setInterval(() => {getInvNo(doc_type.length > 0?doc_type[0].value:'INV',0,supply_type,doc_date)},10000);
		Common.axios({
			method: 'post',
			url:props.url+'user/profileDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
		}).then(function (response) {
			if(response.data.success ){
				if( response.data.data !== null ){
					setProfileDetails(response.data.data);
					setDeclaration(response.data.data.declaration);
					setTnc(response.data.data.tnc);
					setLicence(response.data.data.licence);

					if(response.data.data.prefix === ''){
						setPrefixSetting(true);
						return false;
					}
				}
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
		// State Master
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_state_master',
		}).then(function (response) {
			if( response.data.success ){
				setStateMaster(response.data.data);
			}
		}).catch(error => console.log(error));

		//get invoice type
		Common.axios({
			method: 'get',
			url:props.url+'sales/getInvoiceType',
		}).then(function (response) {
			if(response.data.success){
				//setInvoiceTypeData(response.data.data);
			}
		}).catch(error => console.log(error));

		//get allocation centre
		Common.axios({
			method: 'get',
			url:props.url+'sales/getAllocationCentre/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setAllocationCentreMaster(response.data.data);
			}
		}).catch(error => console.log(error));
		//get unit
		let url = props.url+'ajax/getUnits';
		Common.axios({
			method: 'get',
			url:url,
		}).then(function (response) {
			if(response.data.success){
				setUnits(response.data.data);
			}
		}).catch(error => console.log(error));

		// get tax rates
		Common.axios({
			method: 'get',
			url:props.url+'ajax/getTaxes',
		}).then(function (response) {
			if(response.data.success){
				setTaxes(response.data.data);
			}
		}).catch(error => console.log(error));

		// deduction master
		Common.axios({
			method: 'get',
			url:props.url+'ajax/getDeductionMaster',
		}).then(function (response) {
			if(response.data.success){
				setDeductionMasterList(response.data.data);
				// setDefaultDeductionMasterSelected([response.data.data[0]]);
			}
		}).catch(error => console.log(error));
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setPayeeMaster(response.data.items);
				setBankList(response.data.items);
				if (response.data.defaultPayee.length > 0) {
					setBankAccount(response.data.defaultPayee[0].value);
				}
			}
		}).catch(error => console.log(error));
		// getIncomeTypeMaster(doc_type,0);
        if(state && state.sid && doc_type_action === 0){
			setSid(state.sid);
            Common.showLoader(true);
            Common.axios({
                method: 'get',
                url: props.url+'sale/fetch_sale_data/'+state.sid,
            }).then(function (response) {
                if( response.data.success ){
                    Common.showLoader(false);
					if(state.action === 'edit'){
						setDraftDocNo(response.data.data.saleData.inv_no);
						if (response.data.data.saleData.inv_no !== '') {
							setDocNo(response.data.data.saleData.inv_no);
						} else {
							getInvNo(response.data.data.saleData.doc_type,1,[{'value':response.data.data.saleData.supply_type,'label':'','digits':''}],response.data.data.saleData.inv_date)
						}
						setDocDate(response.data.data.saleData.inv_date);
					}
					if(state.action === 'copy'){
						setDocNo(response.data.data.saleData.inv_no !== ''?response.data.data.saleData.inv_no:getInvNo(response.data.data.saleData.doc_type,1,[{'value':response.data.data.saleData.supply_type,'label':'','digits':''}],response.data.data.saleData.inv_date));
						getInvNo(response.data.data.saleData.doc_type,1,[{'value':response.data.data.saleData.supply_type,'label':'','digits':''}],response.data.data.saleData.inv_date);
						if(state.copy_type === 1){
							setDocDate(response.data.data.saleData.inv_date);
						}
					}
                    setCustomerDetails(response.data.data.customerDetails);
                    setPoNo('' + response.data.data.saleData.poid);
                    setPoNoSel(response.data.data.saleData.poid !== '0' ? response.data.data.poData:[]);
                    setDocType([{'value':response.data.data.saleData.doc_type,'label':'','digits':''}]);
					setDocAction(1);
					getIncomeTypeMaster(response.data.data.saleData.doc_type,0);
					setRefInvSel(response.data.data.saleData.ref_inv_no !== '' ? response.data.data.refinvData:[]);
					setRefId(response.data.data.saleData.ref_inv_no !== '' ? response.data.data.saleData.ref_inv_no:'');
					setRefInvNo(response.data.data.saleData.ref_inv_no !== '' ? response.data.data.refinvData[0].inv_no:'');
					setRefDocDate(response.data.data.saleData.ref_inv_no !== '' ? response.data.data.refinvData[0].inv_date:'');
					setCDReason(response.data.data.saleData.ref_inv_no !== '' ? response.data.data.saleData.cd_reason:'');
					setRefTotalValue(response.data.data.saleData.ref_inv_no !== '' ? response.data.data.refinvData[0].total_amt:'');
					setRefPaymentDue(response.data.data.saleData.ref_inv_no !== '' ? response.data.data.refinvData[0].due_amt:'');
                    setIncomeType(response.data.data.saleData.income_type_master_id);
					setIncomeSubType(response.data.data.saleData.income_subtype_master_id !== "0" ? response.data.data.saleData.income_subtype_master_id:'')
                    setAllocationCentre(response.data.data.saleData.acid !== '0' ? response.data.data.acentreData[0].name:[]);
                    setIncomeTypeSel(response.data.data.saleData.income_type_master_id !== '0' ? response.data.data.incomeTypeData:[]);
					setIncomeSubTypeSel(response.data.data.saleData.income_subtype_master_id !== '0' ? response.data.data.income_subtype_details:[]);
					setIsAccountHeadSelected(true)
                    setAcentreSel(response.data.data.saleData.acid !== '0' ? response.data.data.acentreData:[]);
                    setRevChrg(response.data.data.saleData.rev_chrg);
                    setIgstIntra(response.data.data.saleData.igst_intra);
                    setSupplyType([{'value':response.data.data.saleData.supply_type,'label':'','digits':''}]);
					setEtin(response.data.data.saleData.etin);
                    setInputItems(response.data.data.itemData);
                    setDocDiscount(response.data.data.saleData.total_doc_discount);
                    setDocOtherCharge(response.data.data.saleData.other_charges);
                    setDocRoundoff(response.data.data.saleData.round_off);
                    setDoctaxableAmt(response.data.data.saleData.total_taxable_amt);
                    setDocGstAmt(Number(response.data.data.saleData.igst_amt) + Number(response.data.data.saleData.cgst_amt) + Number(response.data.data.saleData.sgst_amt));
                    setDocIgst(response.data.data.saleData.igst_amt);
					setDocCgst(response.data.data.saleData.cgst_amt);
					setDocSgst(response.data.data.saleData.sgst_amt);
                    setDocTotalAmt(response.data.data.saleData.total_amt);
                    setTotalAmt(response.data.data.saleData.total_amt);
                    setDue(response.data.data.saleData.total_amt);
					if (response.data.data.saleData.due_date !== null) {
						setDueDate(response.data.data.saleData.due_date);
					}
                    setPayee(response.data.data.saleData.payee);
                    setDefaultPayee(response.data.data.payeeData);
                    if(response.data.data.itemDataCount > 0){
                        setItemDiv(true);
                        setTotalAmtDisabled(true);
                    }
                    if(response.data.data.itemDataCount > 1){
                        let itemCount = [];
                        for(let i = 0; i <= response.data.data.itemDataCount - 1; i++){
                            itemCount.push(i)
                        }
                        setSaleItemsLoop(itemCount);
                    }
                    let invRem = false;
                    if(response.data.data.saleData.declaration !== ''){
                        setDeclaration(response.data.data.saleData.declaration);
                        invRem = true;
                    }
                    if(response.data.data.saleData.tnc !== ''){
                        setTnc(response.data.data.saleData.tnc);
                        invRem = true;
                    }
                    if(response.data.data.saleData.licence !== ''){
                        setLicence(response.data.data.saleData.licence);
                        invRem = true;
                    }
                    setInvRemarksDiv(invRem);
                    if(response.data.data.dispDetails !== null){
                        setDispDetDiv(true);
                        setDispFrName(response.data.data.dispDetails.name);
                        setDispFrAddr(response.data.data.dispDetails.addr);
                        setDispFrLoc(response.data.data.dispDetails.loc);
						if(response.data.data.dispDetails.pin){
							populatePinData('dispatch',response.data.data.dispDetails.pin);
							setDispFrState([{'value':response.data.data.dispDetails.state,'label':'','digits':''}]);
						}
                    }
                    if(response.data.data.shipToDetails !== null){
                        setShipToDiv(true);
                        setShipToGstin(response.data.data.shipToDetails.gstin);
                        setShipToLglName(response.data.data.shipToDetails.legal_name);
                        setShipToTrdName(response.data.data.shipToDetails.trade_name);
                        setShipToAddr(response.data.data.shipToDetails.addr);
                        setShipToLoc(response.data.data.shipToDetails.loc);
						if(response.data.data.shipToDetails.pin){
							populatePinData('shipto',response.data.data.shipToDetails.pin)
							setShipToState([{'value':response.data.data.shipToDetails.state,'label':'','digits':''}]);
						}
                    }
                    if(response.data.data.exportDetails !== null){
                        setExpDetDiv(true);
                        setExpShipbNo(response.data.data.exportDetails.Exp_ShipBNo);
                        setExpShipbDate(response.data.data.exportDetails.Exp_ShipBDt);
                        setPortCode(response.data.data.exportDetails.Exp_Port);
                        setExpPortSel((response.data.data.exportDetails.Exp_Port !== '')?response.data.data.portData:'');
                        setExpRefClm(response.data.data.exportDetails.Exp_RefClm);
                        setExpForCur(response.data.data.exportDetails.Exp_ForCur);
                        setExpCurrencySel((response.data.data.exportDetails.Exp_ForCur !== '')?response.data.data.currencyData:'');
                        setExpCountryCode(response.data.data.exportDetails.Exp_CntCode);
                        setExpCountrySel((response.data.data.exportDetails.Exp_CntCode !=='')?response.data.data.countryData:'');
                        setExpDutyAmt(response.data.data.exportDetails.Exp_Duty);
                    }
                    if(response.data.data.customInvFields !== ''){
                        setCustomInvFields(true);
                        let customInput = [];
                        response.data.data.customInvFields.map((vals,inds) => (
                            customInput.push(<CustomInvFields key={inds} len={inds} datas={vals}/>)
                        ))
                        setCustomInputList(customInput);
                    }
                    if(response.data.data.totalGstAmount !== ''){
                        let gstRateList = [];
                        response.data.data.totalGstAmount.forEach( (val,index)=>{
                            let gst_rate_arr = {};
                            let gst_amount = {};
                            gst_amount['igst_amt'] = (Number(val.igst_amt)).toFixed(2);
                            gst_amount['cgst_amt'] = (Number(val.cgst_amt)).toFixed(2);
                            gst_amount['sgst_amt'] = (Number(val.sgst_amt)).toFixed(2);
                            gst_rate_arr[val.gst_rt] = gst_amount;
                            gstRateList.push(gst_rate_arr);
                        })
                        setDocGstRate(gstRateList);
                    }
                }
            }).catch(error => console.log(error));
        }

		return () => clearInterval(inv_interval);
	}, [props,doc_type,supply_type,doc_date]);
	if( !incomeColl_w ){
		navigate("/not-authorized");
	}
	const getIncomeTypeMaster = (docType,callFrom) => {
		setIncomeSubTypeSel([]);
		setIncomeSubType('');
		let type = (docType === "CRN") ? 2 : 1;
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_income_types/'+type,
		}).then(function (response) {
			if( response.data.success ){
				setIncomeTypeMaster(response.data.data);
				if(callFrom === 1){
					setIncomeTypeSelect([response.data.data[0]]);
				}
			}
		}).catch(error => console.log(error));
	}
	const columns = [
        {
            name: 'Party Name',
            selector: row => row.party_name,
            sortable: true,
			sortField: 'party_name',
			cell: (row) => (
				<Button variant="" className="text-break p-0 text-decoration-underline" onClick={e=>{setCustomerDetails([row]);setCustListModalShow(false);}}>{row.party_name.substring(0,15)}</Button>
			)
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
			sortField: 'mobile'
        },
        {
            name: 'Gstin',
            selector: row => row.gstin,
            sortable: true,
			sortField: 'gstin'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
			sortField: 'email'
        }
    ];

	const handlePageChange = page => {
		loadCustTableModal(page,filterText);
	};
	const handleSort = (column, sortDirection) => {
		loadCustTableModal(1,filterText,btoa(column.sortField+'####'+sortDirection));
	};
	const loadCustTableModal = (page,filterText,sort='') => {
		Common.axios({
			method: 'post',
			url: props.url+'customer/listingInSale/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'sort':sort,'search':filterText}
		}).then(function (response) {
			if(response.data.success){
				setCustomersListModal(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const customerListModal = (param) => {
		if( param === 'show' ){
			setCustListModalShow(true);
			loadCustTableModal(1,filterText);
		}
		if( param === 'hide' ){
			setCustListModalShow(false );
			setCustomersListModal([]);
		}
	}
	const handleGstinData =(param,e) => {
		let value = e.target.value
		if (value.length === 15) {
			Common.showLoader(true);
			Common.axios({
				method: 'post',
				url: props.url+'ajax/fetch_gstin_details',
				data: {gstin:value},
			}).then(function (response) {
				Common.showLoader(false);
				if(response.data.status){
					if(response.data.data.status_cd === "0"){
						Common.toast.error(response.data.data.error.message);
						return false;
					}
					if( param === 'customer' ){
						setCustLegalName(response.data.data.lgnm);
						if( response.data.data.tradeNam !== undefined ){
							setCustTradeName(response.data.data.tradeNam);
						}
						if( response.data.data.pradr !== undefined ){
							if( response.data.data.pradr.addr.pncd !== undefined ){
								setCustPincode(response.data.data.pradr.addr.pncd);
								populatePinData(param,response.data.data.pradr.addr.pncd);
							}
							let tempaddr = '';
							if( response.data.data.pradr.addr.bno !== undefined ){
								if( tempaddr !== '' ){ tempaddr += ' ';}
								tempaddr += response.data.data.pradr.addr.bno;
							}
							if( response.data.data.pradr.addr.flno !== undefined ){
								if( tempaddr !== '' ){ tempaddr += ' ';}
								tempaddr += response.data.data.pradr.addr.flno;
							}
							if( response.data.data.pradr.addr.bnm !== undefined ){
								if( tempaddr !== '' ){ tempaddr += ' ';}
								tempaddr += response.data.data.pradr.addr.bnm;
							}
							if( response.data.data.pradr.addr.st !== undefined ){
								if( tempaddr !== '' ){ tempaddr += ', ';}
								tempaddr += response.data.data.pradr.addr.st;
							}
							if( response.data.data.pradr.addr.loc !== undefined ){
								if( tempaddr !== '' ){ tempaddr += ', ';}
								tempaddr += response.data.data.pradr.addr.loc;
							}
							if( response.data.data.gstin !== undefined ){
								setCustPOS([{'value':'','label':'','digits':response.data.data.gstin.slice(0,2)}]);
							}
							setCustAddr(tempaddr);
						}
					}
					if( param === 'shipto' ){
						setShipToLglName(response.data.data.lgnm);
						setShipToTrdName(response.data.data.tradeNam);
						if( response.data.data.pradr !== undefined ){
							if( response.data.data.pradr.addr.pncd !== undefined ){
								populatePinData(param,response.data.data.pradr.addr.pncd);
							}
							let tempaddr = '';
							if( response.data.data.pradr.addr.bno !== undefined ){
								if( tempaddr !== '' ){ tempaddr += ' ';}
								tempaddr += response.data.data.pradr.addr.bno;
							}
							if( response.data.data.pradr.addr.flno !== undefined ){
								if( tempaddr !== '' ){ tempaddr += ' ';}
								tempaddr += response.data.data.pradr.addr.flno;
							}
							if( response.data.data.pradr.addr.bnm !== undefined ){
								if( tempaddr !== '' ){ tempaddr += ' ';}
								tempaddr += response.data.data.pradr.addr.bnm;
							}
							if( response.data.data.pradr.addr.st !== undefined ){
								if( tempaddr !== '' ){ tempaddr += ', ';}
								tempaddr += response.data.data.pradr.addr.st;
							}
							if( response.data.data.pradr.addr.loc !== undefined ){
								if( tempaddr !== '' ){ tempaddr += ', ';}
								tempaddr += response.data.data.pradr.addr.loc;
							}
							setShipToAddr(tempaddr);
						}
					}
					return false;
				}
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
	}

	const addAnotherItem = () => {
		setSaleItemsLoop([...sale_items_loop,sale_items_loop.length]);
	}
	const removeItem = (cnt,docGstRate,div) => {
		let allItems = input_items[cnt];
		if(allItems){
			let gstRate = input_items[cnt].gst_rt;
			if( docGstRate.length > 0 ){
				docGstRate.forEach( (values,index2)=>{
					if(values.hasOwnProperty(gstRate)){
						let all_gst_amt = values[gstRate];
						let gst_amount = {};
						gst_amount['igst_amt'] = (Number(all_gst_amt['igst_amt']) - Number(allItems.igst_amt)).toFixed(2);
						gst_amount['sgst_amt'] = (Number(all_gst_amt['sgst_amt']) - Number(allItems.sgst_amt)).toFixed(2);
						gst_amount['cgst_amt'] = (Number(all_gst_amt['cgst_amt']) - Number(allItems.cgst_amt)).toFixed(2);
						if(gst_amount['igst_amt'] == 0.00 && gst_amount['sgst_amt'] == 0 && gst_amount['cgst_amt'] == 0){
							let newDocGstRate = docGstRate.filter((itms,indexes) => {
								return indexes !== index2;
							});
							setDocGstRate(newDocGstRate);
						}else{
							docGstRate[index2][gstRate] = gst_amount;
							setDocGstRate(docGstRate);
						}
					}
				})
			}
		}
		let new_input_items = input_items.filter((itm,index) => {
			return index !== cnt;
		});
		let igst = 0,cgst = 0,sgst = 0;

		if( new_input_items.length > 0 ){
			new_input_items.forEach( (val,index)=>{
				new_input_items[index]['igst_amt'] = Number(val.gst_amt * val.item_qty).toFixed(2);
				new_input_items[index]['cgst_amt'] = '0.00';
				new_input_items[index]['cgst_amt'] = '0.00';
				if( profile_details !==null && (profile_details.gstin !== '') && (profile_details.gstin !== 'URP') && (cust_pos[0] !== undefined) && (cust_pos[0]['digits'] === profile_details.gstin.slice(0,2)) ){
					new_input_items[index]['igst_amt'] = '0.00';
					new_input_items[index]['cgst_amt'] = Number(val.gst_amt/2 * val.item_qty).toFixed(2);
					new_input_items[index]['sgst_amt'] = Number(val.gst_amt/2 * val.item_qty).toFixed(2);
				}
				igst += Number(new_input_items[index]['igst_amt']);
				cgst += Number(new_input_items[index]['cgst_amt']);
				sgst += Number(new_input_items[index]['sgst_amt']);
			})
		}
		setDocIgst(igst.toFixed(2));
		setDocCgst(cgst.toFixed(2));
		setDocSgst(sgst.toFixed(2));
		setInputItems(new_input_items);
		if( div ){
			setSaleItemsLoop(sale_items_loop.slice(0,-1));
		}
		let amt = new_input_items.reduce(function(prev, current) {
			return Number(prev) + Number(current.item_tot_price)
		}, 0);

		let taxamt = new_input_items.reduce(function(prev, current) {
			return Number(prev) + Number((current.price * current.item_qty) - current.item_discount)
		}, 0);

		let totalAmt = (Number(amt) + Number(docOtherCharge) - Number(docDiscount)) + (Number(docRoundoff));
		// let totalAmt = (Number(amt));
		setTotalAmt(totalAmt.toFixed(2));
		setDocTotalAmt(totalAmt.toFixed(2));

		let total_gst_amt = Number(igst) + Number(cgst) + Number(sgst);
		setDocGstAmt(total_gst_amt.toFixed(2));
		// let doc_taxable_amt = Number(amt) - Number(total_gst_amt);
		let doc_taxable_amt = Number(taxamt);
		setDoctaxableAmt(doc_taxable_amt.toFixed(2));
		summaryTotalCal(totalAmt.toFixed(2),total_payment,total_deduction);
	}
	const summaryTotalCal = (total_amt,total_payment,total_deduction) => {
		let dueAmt = (Number(total_amt) - Number(total_payment) - Number(total_deduction)).toFixed(2);
		let absDue = Math.abs(dueAmt);
		setDue((absDue === 0)?0:dueAmt);
		if( dueAmt < 0 ){
			let advance_amount = Number(total_payment) + Number(total_deduction) - Number(total_amt);
			setAdvanceAmount(advance_amount);
			setShowAdvanceDiv(true);
		} else {
			setAdvanceAmount(0);
			setShowAdvanceDiv(false);
		}
	}
	const finalDocTotalCal = (otherCharge,roundoff,discount) => {
		if(otherCharge === '.' || roundoff === '.' || discount === '.'){
			return false;
		}
		let total_amount = (Number(doc_gst_amt) + Number(doc_taxable_amt) + Number(otherCharge) - Number(discount)) + (Number(roundoff));
		setTotalAmt(total_amount.toFixed(2));
		setDocTotalAmt(total_amount.toFixed(2));
		summaryTotalCal(total_amount.toFixed(2),total_payment,total_deduction);
	}
	const handleDocRoundoff = (roundoff) => {
		if((Number(0.99) < Number(roundoff)) || (Number(-0.99) > Number(roundoff))){
			Common.toast.error('Round off amount should be between -0.99 to 0.99');
			return false;
		}
		setDocRoundoff(roundoff);
		finalDocTotalCal(docOtherCharge,roundoff,docDiscount);
	}
	const removePayment = (index) => {
		let price = payments[index].received;
		let ded_amt = payments[index].deductions.reduce(function(prev, current) {
			return Number(prev) + Number(current.deduction_amt)
		}, 0);

		let total_ded = Number(total_deduction) - Number(ded_amt);
		setTotalDeduction(total_ded);//mainCalculation('deduction',total_ded);

		setPayments(payments.filter((item,key) => key !== index));
		let total_pay = Number(total_payment) - Number(price);
		setTotalPayment(total_pay);//mainCalculation('received',total_pay);
		summaryTotalCal(total_amt,total_pay,total_ded)
	}
	const addPayment = () => {
		if( received === undefined || Number(received) === 0 ){
			Common.toast.error('Received amount should be greater than zero.');
			return false;
		}
		if( payment_mode === '' ){
			Common.toast.error('Payment Mode is required');
			return false;
		}
		// payment_mode !== 'Cash' &&
		if( bankAccount === undefined || bankAccount === 0 || bankAccount === '' ){
			Common.toast.error('Bank Account is required.');
			return false;
		}

		let pay = {};
		pay['received'] = received;
		pay['payment_date'] = payment_date;
		pay['payment_mode'] = payment_mode;
		pay['payment_remarks'] = payment_remarks;
		pay['trans_ref'] = trans_ref;
		pay['deductions'] = deductions;
		pay['bankAccount'] = bankAccount;
		let total_pay = Number(total_payment) + Number(received);

		setPayments([...payments, pay]);
		paymentModal('hide');
		setTotalPayment(total_pay);//mainCalculation('received',total_pay);

		let tot_ded = deductions.reduce(function(prev, current) {
			return Number(prev) + Number(current.deduction_amt)
		}, 0);
		setTotalDeduction(Number(total_deduction) + Number(tot_ded));//mainCalculation('deduction',Number(total_deduction) + Number(tot_ded));
		summaryTotalCal(total_amt,total_pay,Number(total_deduction) + Number(tot_ded));
	}
	const addAdvanceAdjust = () => {
		if( adjustedAmount === undefined || Number(adjustedAmount) === 0 ){
			Common.toast.error('Received amount should be greater than zero.');
			return false;
		}
		if( Number(advanceBalance) < Number(adjustedAmount) ){
			Common.toast.error('Invoice amount is greated than advance balance amount.');
			return false;
		}
		let advance = {};
		advance['adjustedAmount'] = adjustedAmount;
		advance['advanceAdjustedRemarks'] = advanceAdjustedRemarks;
		let total_advance = Number(totalAdjustedAmount) + Number(adjustedAmount);

		setAdvanceAdjust([...advanceAdjust, advance]);
		advanceModal('hide');
		setTotalAdvanceAdjust(total_advance);
		setAdvanceBalance(Number(advanceBalance) - Number(adjustedAmount));
		let total_pay = Number(total_payment) + Number(adjustedAmount);
		setTotalPayment(total_pay);

		summaryTotalCal(total_amt,total_pay,total_deduction);
	}
	const removeAdvanceAdjust = (index) => {
		let price = advanceAdjust[index].adjustedAmount;

		setAdvanceAdjust(advanceAdjust.filter((item,key) => key !== index));
		let total_advance = Number(totalAdjustedAmount) - Number(price);
		setTotalAdvanceAdjust(total_advance);
		setAdvanceBalance(Number(advanceBalance) + Number(price));
		let total_pay = Number(total_payment) - Number(price);
		setTotalPayment(total_pay);
		summaryTotalCal(total_amt,total_pay,total_deduction);
	}
	const removeDeduction = (index) => {
		let price = deductions[index].deduction_amt;
		setDeductions(deductions.filter((item,key) => key !== index));
		let totReceived = Number(received) + Number(price);
		setReceived(totReceived);
	}
	const calcDeduction = (param,val) => {
		let percent = 0,amount = 0;
		let tot_cal_amt = (doc_taxable_amt > 0)?doc_taxable_amt:total_amt;
		if( param === 'per' ){
			percent = val;
			amount = (tot_cal_amt * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/tot_cal_amt * 100).toFixed(2);
		}
		setDeductionPer(percent);
		setDeductionAmt(amount);
	}
	const addDeduction = () => {
		if( deduction_per === undefined || deduction_per === '' || deduction_per > 100 ){
			Common.toast.error('Deduction percent is invalid.');
			return false;
		}
		if( deduction_amt === undefined || deduction_amt === '' || Number(deduction_amt) === 0 ){
			Common.toast.error('Deduction amount should be greater than zero.');
			return false;
		}

		if( deductionMasterLabel === undefined || deductionMasterLabel === '' ){
			Common.toast.error('Deduction type is required.');
			return false;
		}
		let tot_ded = deductions.reduce(function(prev, current) {
			return Number(prev) + Number(current.deduction_amt)
		}, 0);
		let tot_cal_amt = (doc_taxable_amt > 0)?doc_taxable_amt:total_amt;
		if( Number(total_deduction) + Number(tot_ded) + Number(deduction_amt) > Number(tot_cal_amt) ){
			Common.toast.error('Total deduction amount cannot be greater than total taxable value.');
			return false;
		}
		let ded = {};
		ded['deduction_per'] = deduction_per;
		setDeductionPer('');
		ded['deduction_amt'] = deduction_amt;
		setDeductionAmt('');
		ded['deduction_type'] = deductionMasterLabel;
		frmSaleDedType.current.clear();
		ded['deduction_subtype'] = deductionSubtypeMasterLabel;
		frmSaleDedSubtype.current.clear();
		// setDefaultDeductionSubtypeMasterSelected([])
		ded['deduction_master_id'] = deductionMaster
		ded['deduction_subtype_label'] = deductionSubtypeMaster;
		// ded['deduction_remarks'] = deduction_remarks;
		// setDeductionRemarks('');

		setDeductions([...deductions, ded]);
		let totReceived = Number(received) - Number(deduction_amt);
		setReceived(totReceived);
		setDeductionSubtypeMasterList([])
		setDeductionSubtypeMasterLabel('');
		setIsDeductionMasterSelected(false)
	}
	const paymentModal = (param) => {
		if( param === 'show' ){
			setPayModalShow(true);
		}
		if( param === 'hide' ){
			setPayModalShow(false );
			setReceived('');
			setPaymentDate(new Date());
			setPaymentMode('UPI/QR');
			setListOfBanks();
			setDefaultPayee([])
			setBankAccount(0)
			setPaymentRemarks('');
			setTransRef('');
			setDeductionDiv(false);
			setDeductions([]);
			setIsDeductionMasterSelected(false)
		}
	}
	const deductionModal = (param) => {
		setDedModalShow(true);
		setTempDeduction(param);
	}

	const advanceModal = (param) => {
		if( param === 'show' ){
			setAdvanceAdjustModalShow(true);
		}
		if( param === 'hide' ){
			setAdvanceAdjustModalShow(false );
			setAdjustedAmount('');
			setAdvanceAdjustedRemarks('');
		}
	}

	const setCustomerDetails = (e) => {
		// frmSalePO.current.clear();
		if(e[0] === undefined) {
			setCustSel([]);
			setCustName('');
			setCustPhone('');
			setCustPhoneDisabled(false);
			setCid(0);
			setCustGstin('');
			setCustLegalName('');
			setCustTradeName('');
			setCustPincode('');
			setCustAddr('');
			setCustDistrict('');
			setCustState([]);
			setCustPOS([]);
			setCustEmail('');
			setSupplyType([{'value':'B2C','label':'','digits':''}]);
			return;
		}
		setCustSel(e);
		setCustName((e[0]) ? e[0].party_name : '');
		let phone = (e[0].mobile) ? e[0].mobile : '';
		setCustPhone(phone);
		setCid(e[0].id.startsWith('new') ? '':e[0].id);
		getAdvanceBalance(e[0].id.startsWith('new') ? '':e[0].id);
		getPOContractsForId(e[0].id.startsWith('new') ? '':e[0].id);
		let gstin = (e[0]) ? e[0].gstin : '';
		if( gstin !== undefined ){
			setCustGstin(gstin);
			if( gstin !== '' && gstin !== 'URP' ){
				setCustPOS([{'value':'','label':'','digits':gstin.slice(0,2)}]);
				setSupplyType([{'value':'B2B','label':'','digits':''}]);
			}else{
				setSupplyType([{'value':'B2C','label':'','digits':''}]);
			}
		}

		if( e[0].legal_name !== undefined ){
			setCustLegalName(e[0].legal_name);
		}
		if( e[0].trade_name !== undefined ){
			setCustTradeName(e[0]?.trade_name);
		}
		if( e[0].pincode !== undefined ){
			setCustPincode(e[0]?.pincode);
		}
		if( e[0].addr !== undefined ){
			setCustAddr(e[0]?.addr);
		}
		if( e[0].district !== undefined ){
			setCustDistrict(e[0]?.district);
		}
		if( e[0].email !== undefined ){
			setCustEmail(e[0]?.email);
		}
		let custState = e[0]?.state;
		if( custState !== undefined && custState !== '' ){
			setCustState([{'value':custState,'label':'','digits':''}]);
		}
		setCustPhoneDisabled(true);
		setIncomeTypeSelect([income_type_master[0]]);
	}
	const calculateTotalAmt = (item_service='') => {
		let igst = 0, cgst = 0, sgst = 0;
		input_items.forEach( (val,index)=>{
			if(item_service === ''){
				input_items[index]['item_discount'] = Number(val.discount * val.item_qty).toFixed(2);
				input_items[index]['item_tot_price'] = Number(val.total_price * val.item_qty).toFixed(2);
			}
			if(item_service === 2){
				if (val.price !== '' && val.item_qty !== '') {
					let price = parseFloat(val.price)
					let itemQty = parseInt(val.item_qty)
					if (price > 0 && itemQty > 0) {
						input_items[index]['item_discount_per'] = Number(((val.discount * val.item_qty)/(val.price * val.item_qty)) * 100).toFixed(2);
					}
				}
			}
			input_items[index]['igst_amt'] = Number(val.gst_amt * val.item_qty).toFixed(2);
			input_items[index]['cgst_amt'] = '0.00';
			input_items[index]['sgst_amt'] = '0.00';
			if( profile_details !==null && (profile_details.gstin !== '') && (profile_details.gstin !== 'URP') && (cust_pos[0] !== undefined) && (cust_pos[0]['digits'] === profile_details.gstin.slice(0,2)) ){
				input_items[index]['igst_amt'] = '0.00';
				input_items[index]['cgst_amt'] = Number(val.gst_amt/2 * val.item_qty).toFixed(2);
				input_items[index]['sgst_amt'] = Number(val.gst_amt/2 * val.item_qty).toFixed(2);
			}
			igst += Number(input_items[index]['igst_amt']);
			cgst += Number(input_items[index]['cgst_amt']);
			sgst += Number(input_items[index]['sgst_amt']);
		})
		setDocIgst(igst.toFixed(2));
		setDocCgst(cgst.toFixed(2));
		setDocSgst(sgst.toFixed(2));
		let amt = input_items.reduce(function(prev, current) {
			return Number(prev) + Number(current.item_tot_price)
		}, 0);

		let taxamt = input_items.reduce(function(prev, current) {
			return Number(prev) + Number((current.price * current.item_qty) - current.item_discount)
		}, 0);

		let totalAmt = (Number(amt) + Number(docOtherCharge) - Number(docDiscount)) + (Number(docRoundoff));
		setDocTotalAmt(totalAmt.toFixed(2));
		setTotalAmt(totalAmt.toFixed(2));

		let total_gst_amt = Number(igst) + Number(cgst) + Number(sgst);
		setDocGstAmt(total_gst_amt.toFixed(2));
		//let doc_taxable_amt = Number(amt) - Number(total_gst_amt);
		let doc_taxable_amt = Number(taxamt);
		setDoctaxableAmt(doc_taxable_amt.toFixed(2));

		summaryTotalCal(amt.toFixed(2),total_payment,total_deduction);
	}
	const setItemQty = (val,cnt,docGstRate) => {
		let newArr = [...input_items];

		newArr[cnt]['item_qty'] = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;

		setInputItems(newArr);
		let gst_rate_data = calculateGstRate(cnt,'sub',docGstRate);
		calculateTotalAmt();
		calculateGstRate(cnt,'add',gst_rate_data);
	}
	const handleItemPrice = (val,cnt,type,docGstRates,gst_rate_id='') => {
		let newArr = [...input_items];
		let gst_rate_data = calculateGstRate(cnt,'sub',docGstRates);
		let itemQty = newArr[cnt]['item_qty'];
		if(type === 'item_price'){
			let itmPrice = Number(val) - Number(input_items[cnt].discount);
			let gstAmt = Number((itmPrice * newArr[cnt]['gst_rt']) / 100);
			let totalPrice = ((Number(val) + Number(gstAmt)) - Number(input_items[cnt].discount));
			// newArr[cnt]['total_price'] = Number(totalPrice).toFixed(2);
			newArr[cnt]['total_price'] = Number(totalPrice);
			newArr[cnt]['price'] = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
			newArr[cnt]['gst_amt'] = Number(gstAmt);
			newArr[cnt]['item_tot_price'] = Number(totalPrice * itemQty).toFixed(2);
			setInputItems(newArr);
			calculateTotalAmt(2);
		}
		if(type === 'item_discount'){
			let discount_per = Number((val / (newArr[cnt]['price'] * itemQty)) * 100).toFixed(2);
			let itmPrice = Number(newArr[cnt]['price']) - Number(val / itemQty);
			let gstAmt = Number((itmPrice * newArr[cnt]['gst_rt']) / 100);
			let totalPrice = ((Number(newArr[cnt]['price']) + Number(gstAmt)) - Number(val / itemQty));
			newArr[cnt]['discount'] = Number(val / itemQty).toFixed(2);
			newArr[cnt]['item_discount'] = val;
			newArr[cnt]['item_discount_per'] = discount_per;
			newArr[cnt]['gst_amt'] = Number(gstAmt);
			// newArr[cnt]['total_price'] = Number(totalPrice).toFixed(2);
			newArr[cnt]['total_price'] = Number(totalPrice);
			newArr[cnt]['item_tot_price'] = Number(totalPrice * itemQty).toFixed(2);
			setInputItems(newArr);
			calculateTotalAmt(2);
		}
		if(type === 'item_discount_per'){
			let discount_per = val;
			val = Number(newArr[cnt]['price'] * itemQty * val/100).toFixed(2);
			let itmPrice = Number(newArr[cnt]['price']) - Number(val / itemQty);
			let gstAmt = Number((itmPrice * newArr[cnt]['gst_rt']) / 100);
			let totalPrice = ((Number(newArr[cnt]['price']) + Number(gstAmt)) - Number(val / itemQty));
			newArr[cnt]['discount'] = Number(val / itemQty).toFixed(2);
			newArr[cnt]['item_discount'] = val;
			newArr[cnt]['item_discount_per'] = discount_per;
			newArr[cnt]['gst_amt'] = Number(gstAmt);
			// newArr[cnt]['total_price'] = Number(totalPrice).toFixed(2);
			newArr[cnt]['total_price'] = Number(totalPrice);
			newArr[cnt]['item_tot_price'] = Number(totalPrice * itemQty).toFixed(2);
			setInputItems(newArr);
			calculateTotalAmt(1);
		}
		if(type === 'item_tot_price'){
			let finalamt = Number(input_items[cnt].item_discount) + (Number(val) / ((Number(newArr[cnt]['gst_rt'])/100)+1));
			let taxableamt = Number(finalamt) - Number(input_items[cnt].item_discount);
			let gstAmt = Number((taxableamt * newArr[cnt]['gst_rt']) / 100);
			newArr[cnt]['price'] = Number(finalamt / itemQty).toFixed(2);
			newArr[cnt]['gst_amt'] = Number(gstAmt / itemQty);
			// newArr[cnt]['total_price'] = Number(val / itemQty).toFixed(2);
			newArr[cnt]['total_price'] = Number(val / itemQty);
			newArr[cnt]['item_tot_price'] = val;
			setInputItems(newArr);
			calculateTotalAmt(2);
		}
		if(type === 'gst_rate'){
			val = val?val:0;
			let itmPrice = Number(newArr[cnt]['price']) - Number(input_items[cnt].discount);
			let gstAmt = Number((itmPrice * val) / 100);
			let totalPrice = ((Number(newArr[cnt]['price']) + Number(gstAmt)) - Number(input_items[cnt].discount));
			// newArr[cnt]['total_price'] = Number(totalPrice).toFixed(2);
			newArr[cnt]['total_price'] = Number(totalPrice);
			newArr[cnt]['gst_amt'] = Number(gstAmt);
			newArr[cnt]['item_tot_price'] = Number(totalPrice * itemQty).toFixed(2);
			newArr[cnt]['gst_rt'] = val;
			newArr[cnt]['item_gst_rate'] = val;
			newArr[cnt]['gst_rate_id'] = gst_rate_id;
			setInputItems(newArr);
			calculateTotalAmt(2);
		}
		calculateGstRate(cnt,'add',gst_rate_data);
	}
	const calculateGstRate = (cnt,action,docGstRate) => {
		let allItems = input_items[cnt];
		let gstRate = input_items[cnt].gst_rt;
		let gst_rate_data = '';
		if(action === 'sub'){
			if( docGstRate.length > 0 ){
				docGstRate.forEach( (val1,index1)=>{
					if(val1.hasOwnProperty(gstRate)){
						let all_gst_amt = val1[gstRate];
						let gst_amount = {};
						gst_amount['igst_amt'] = (Number(all_gst_amt['igst_amt']) - Number(allItems.igst_amt)).toFixed(2);
						gst_amount['sgst_amt'] = (Number(all_gst_amt['sgst_amt']) - Number(allItems.sgst_amt)).toFixed(2);
						gst_amount['cgst_amt'] = (Number(all_gst_amt['cgst_amt']) - Number(allItems.cgst_amt)).toFixed(2);
						if(gst_amount['igst_amt'] == '0.00' && gst_amount['sgst_amt'] == '0.00' && gst_amount['cgst_amt'] == '0.00'){
							let newDocGstRate = docGstRate.filter((itms,indexes) => {
								return indexes !== index1;
							});
							setDocGstRate(newDocGstRate);
							gst_rate_data = newDocGstRate;
						}else{
							docGstRate[index1][gstRate] = gst_amount;
							setDocGstRate(docGstRate);
							gst_rate_data = docGstRate;
						}
					}
				})
			}
		}else{
			if(docGstRate.length > 0 ){
				var rate_exist = docGstRate.some(el => el.hasOwnProperty(gstRate));
				if(rate_exist){
					docGstRate.forEach( (val3,index3)=>{
						if(val3.hasOwnProperty(gstRate)){
							let all_gst_amt = val3[gstRate];
							let gst_amount = {};
							gst_amount['igst_amt'] = (Number(all_gst_amt['igst_amt']) + Number(allItems.igst_amt)).toFixed(2);
							gst_amount['sgst_amt'] = (Number(all_gst_amt['sgst_amt']) + Number(allItems.sgst_amt)).toFixed(2);
							gst_amount['cgst_amt'] = (Number(all_gst_amt['cgst_amt']) + Number(allItems.cgst_amt)).toFixed(2);
							docGstRate[index3][gstRate] = gst_amount;
							setDocGstRate(docGstRate);
						}
					})
				}
				else{
					let gst_rate_arr = {};
					let gst_amount = {};
					gst_amount['igst_amt'] = Number(allItems.igst_amt).toFixed(2);
					gst_amount['cgst_amt'] = Number(allItems.cgst_amt).toFixed(2);
					gst_amount['sgst_amt'] = Number(allItems.sgst_amt).toFixed(2);
					gst_rate_arr[gstRate] = gst_amount;
					setDocGstRate([...docGstRate,gst_rate_arr]);
				}
			}else{
				let gst_rate_arr = {};
				let gst_amount = {};
				gst_amount['igst_amt'] = Number(allItems.igst_amt).toFixed(2);
				gst_amount['cgst_amt'] = Number(allItems.cgst_amt).toFixed(2);
				gst_amount['sgst_amt'] = Number(allItems.sgst_amt).toFixed(2);
				gst_rate_arr[gstRate] = gst_amount;
				setDocGstRate([...docGstRate,gst_rate_arr]);
			}
		}
		return gst_rate_data;
	}
	const setItemDesc = (cnt,val) => {
		let newArr = [...input_items];
		newArr[cnt]['description'] = val;
		setInputItems(newArr);
	}
	const setItemDetails = (e,cnt,docGstRate) => {
		if(e.length > 0 && (e[0] !== undefined) ){
			let total_amnt = total_amt;
			if( input_items.length === 0 ){
				setTotalAmt('');
				total_amnt = 0;
			}
			e[0]['item_tot_price'] = e[0]?.total_price;
			e[0]['item_qty'] = 1;
			e[0]['item_discount'] = e[0]?.discount;
			e[0]['item_discount_per'] = ( ((e[0]?.discount * e[0]['item_qty']) / (e[0]?.price * e[0]['item_qty'])) * 100).toFixed(2);
			e[0]['item_gst_rate'] = e[0]?.gst_rt;
			e[0]['item_igst_rate'] = e[0]?.gst_rt;
			e[0]['item_sgst_rate'] = '0.00';
			e[0]['item_cgst_rate'] = '0.00';
			e[0]['igst_amt'] = e[0]?.gst_amt;
			e[0]['cgst_amt'] = '0.00';
			e[0]['sgst_amt'] = '0.00';
			e[0]['allow_neg_stock'] === '1' ? setIsNegativeBal(true):setIsNegativeBal(false)
			if( profile_details !==null && (profile_details.gstin !== '') && (profile_details.gstin !== 'URP') && (cust_pos[0] !== undefined) && (cust_pos[0]['digits'] === profile_details.gstin.slice(0,2)) ){
				e[0]['igst_amt'] = '0.00';
				e[0]['cgst_amt'] = (Number(e[0]?.gst_amt)/2).toFixed(2);
				e[0]['sgst_amt'] = (Number(e[0]?.gst_amt)/2).toFixed(2);
			}
			if(docGstRate.length > 0 && (e[0] !== undefined) ){
				var rate_exist = docGstRate.some(el => el.hasOwnProperty(e[0]['item_igst_rate']));
				if(rate_exist){
					docGstRate.forEach( (val,index)=>{
						if(val.hasOwnProperty(e[0]['item_igst_rate'])){
							let all_gst_amt = val[e[0]['item_igst_rate']];
							let gst_amount = {};
							gst_amount['igst_amt'] = (Number(all_gst_amt['igst_amt']) + Number(e[0]['igst_amt'])).toFixed(2);
							gst_amount['sgst_amt'] = (Number(all_gst_amt['sgst_amt']) + Number(e[0]['sgst_amt'])).toFixed(2);
							gst_amount['cgst_amt'] = (Number(all_gst_amt['cgst_amt']) + Number(e[0]['cgst_amt'])).toFixed(2);
							docGstRate[index][e[0]['item_igst_rate']] = gst_amount;
							setDocGstRate(docGstRate);
						}
					})
				}
				else{
					let gst_rate_arr = {};
					let gst_amount = {};
					gst_amount['igst_amt'] = Number(e[0]['igst_amt']).toFixed(2);
					gst_amount['cgst_amt'] = Number(e[0]['cgst_amt']).toFixed(2);
					gst_amount['sgst_amt'] = Number(e[0]['sgst_amt']).toFixed(2);
					gst_rate_arr[e[0]['item_gst_rate']] = gst_amount;
					setDocGstRate([...docGstRate,gst_rate_arr]);
				}
			}else{
				let gst_rate_arr = {};
				let gst_amount = {};
				gst_amount['igst_amt'] = Number(e[0]['igst_amt']).toFixed(2);
				gst_amount['cgst_amt'] = Number(e[0]['cgst_amt']).toFixed(2);
				gst_amount['sgst_amt'] = Number(e[0]['sgst_amt']).toFixed(2);
				gst_rate_arr[e[0]['item_gst_rate']] = gst_amount;
				setDocGstRate([...docGstRate,gst_rate_arr]);
			}
			setDocIgst((Number(docIgst) + Number(e[0]['igst_amt'])).toFixed(2));
			setDocCgst((Number(docCgst) + Number(e[0]['cgst_amt'])).toFixed(2));
			setDocSgst((Number(docSgst) + Number(e[0]['sgst_amt'])).toFixed(2));
			e[0]['disabled'] = true;

			setInputItems([...input_items,e[0]]);
			let totalAmt = Number(total_amnt) + Number(e[0]?.total_price);

			// let total_amount = (Number(totalAmt) + Number(docOtherCharge) - Number(docDiscount)) + (Number(docRoundoff));
			let total_amount = (Number(totalAmt));
			setTotalAmt(total_amount.toFixed(2));
			setDocTotalAmt(total_amount.toFixed(2));

			let total_gst_amt = (Number(docIgst) + Number(e[0]['igst_amt'])) + (Number(docCgst) + Number(e[0]['cgst_amt'])) + (Number(docSgst) + Number(e[0]['sgst_amt']));
			setDocGstAmt(total_gst_amt.toFixed(2));

			let doc_tax_amt = Number(doc_taxable_amt) + Number((e[0]['price'] * e[0]['item_qty']) - e[0]['item_discount']);

			setDoctaxableAmt(doc_tax_amt.toFixed(2));
			summaryTotalCal(totalAmt,total_payment,total_deduction);
			setTotalAmtDisabled(true);
			return false;
		}
		if(cnt > -1) {
			// alert(input_items[cnt]['total_price']);
			let total = Number(total_amt) - Number(input_items[cnt]['total_price']);
			if( total === 0 ){
				setTotalAmtDisabled(false);
			}
			setTotalAmt(total);
			summaryTotalCal(total,total_payment,total_deduction);
			setInputItems((input_items) => input_items.filter((_, index) => index !== cnt));
		}
	}
	const checkPayment = () => {
		if( received === undefined || Number(received) === 0 ){
			Common.toast.error('Enter the payment before adding deduction.');
			paymentReceivedRef.current.focus();
			return false;
		}
	}
	const setPayeeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setPayee(value);
		setDefaultPayee('');
	}
	const setPortCodeSelect = (e) => {
		let value = (e[0]) ? e[0].id : '';
		setPortCode(value);
        setExpPortSel('');
	}
	const setForCurSelect = (e) => {
		let value = (e[0]) ? e[0].id : '';
		setExpForCur(value);
        setExpCurrencySel('');
	}
	const setCountryCodeSelect = (e) => {
		let value = (e[0]) ? e[0].id : '';
		setExpCountryCode(value);
        setExpCountrySel('');
	}
	const setAllocationCentreSelect = (e) => {
		let value = (e[0]) ? e[0].name : '';
		setAllocationCentre(value);
        // setAcentreSel('');
		if (value === '') {
			setAcentreSel([])
		} else {
			setAcentreSel(e)
		}
	}
	const setDocDateSelect = (e) => {
		setInvoiceGenType(1);
		setDocDate(e);
	}
	// const setSupplyTypeSelect = (e) => {
	// 	let value = (e[0]) ? e[0].value : '';
	// 	setSupplyType(value);
	// }
	const setDocTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setDocType(e);
		setDocAction(1);
		getIncomeTypeMaster(value,1);
	}
	const setPaymentModeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setPaymentMode(value);
		if(value === 'Cash'){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/'+value,
			}).then(function (response) {
				if(response.data.success){
					if(response.data.data !== ''){
						setBankCashAccount(1);
						setDefaultPayee(response.data.data);
						setBankAccount(response.data.data[0].value);
						setBankList(response.data.data);
					}else{
						setBankCashAccount(0);
						setDefaultPayee([]);
						setBankAccount(0);
					}
				}
			}).catch(error => console.log(error));
		}else{
			setBankCashAccount(1);
			setDefaultPayee([]);
			setListOfBanks();
		}
	}
	const setListOfBanks = () => {
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				// setDefaultPayee(response.data.defaultPayee);
				// if (response.data.defaultPayee.length > 0) {
				// 	setBankAccount(response.data.defaultPayee[0].value);
				// }
			}
		}).catch(error => console.log(error));
	}
	const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setBankAccount(value);
		// setDefaultPayee('');
		if (value === '') {
			setDefaultPayee([]);
		} else {
			bankList.forEach((bank,i) => {
				if (bank.value === value) {
					setDefaultPayee([bank]);
				}
			});
		}
	}
	const setIncomeTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setIncomeType(value);
        // setIncomeTypeSel('');
		if (value === '') {
			setIncomeTypeSel([]);
		} else {
			setIncomeTypeSel(e);
		}
		e.length === 0 ? setIsAccountHeadSelected(false) : setIsAccountHeadSelected(true);
		// Income subTypes Master
		if (value !== '') {
			Common.axios({
				method: 'post',
				url: props.url+'ajax/fetch_income_subtypes',
				data: {
					'pid': localStorage.getItem('fo_profileid'),
					'income_type_master_id':e[0].value
				}
			}).then(function (response) {
				if( response.data.success ){
					setIncomeSubTypeMaster(response.data.data);
				}
			}).catch(error => console.log(error));
		} else {
			setIncomeSubType(value);
			setIncomeSubTypeSel([]);
		}
	}
	const setIncomeSubTypeSelect = (e) => {
		let value = '';
		if (e.length > 0 && e[0].id === undefined) {
			value = e[0].value;
		} else if (e.length > 0 && e[0].id !== undefined) {
			value = e[0].label;
		}
		setIncomeSubType(value)
		// setIncomeSubTypeSel('');
		if (value === '') {
			setIncomeSubTypeSel([])
		} else {
			setIncomeSubTypeSel(e)
		}
	}
	const setDeductionTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		setDeductionMaster(value);
		setDeductionMasterLabel(label)
		if (value === '') {
			setIsDeductionMasterSelected(false);
			// setDefaultDeductionSubtypeMasterSelected([])
		} else {
			setIsDeductionMasterSelected(true);
		}
		if (value !== '') {
			Common.axios({
				method: 'post',
				url: props.url+'ajax/getDeductionSubtypeMaster',
				data: {
					'pid': localStorage.getItem('fo_profileid'),
					'deduction_master_id':value
				}
			}).then(function (response) {
				if( response.data.success ){
					let dedSubtypeList = response.data.data;
					if (dedSubtypeList.length > 0) {
						setDeductionSubtypeMasterList(response.data.data);
					}
				}
			}).catch(error => console.log(error));
		} else {
			setDeductionSubtypeMaster(value);
			frmSaleDedSubtype.current.clear();
			setDeductionSubtypeMasterList([])
		}
	}
	const setDeductionSubTypeSelect = (e) => {
		if (e.length > 0) {
			setDeductionSubtypeMaster(e[0].label);
			setDeductionSubtypeMasterLabel(e[0].label)
		}
	}
	const setPOSelect = (e) => {
		let value = (e[0]) ? e[0].id : '';
		setPoNo(value);
        setPoNoSel(e);
	}
	// const setCDReasonSelect = (e) => {
	// 	let value = (e[0]) ? e[0].value : '';
	// 	setCDReason(value);
	// 	setCdReasonSel('');
	// }
	const setCustGstinSelect = (e) => {
		setCustGstin(e);
		if( e !== '' && e !== 'URP' ){
			setSupplyType([{'value':'B2B','label':'','digits':''}]);
		}else{
			setSupplyType([{'value':'B2C','label':'','digits':''}]);
		}
	}
	const handleSubmit =(e) => {
		e.preventDefault();
		if(draftDocNo !== '' && actionType === 'edit' && submitType === '1' && saveDraftInvoice === '0'){
			setDraftInvoiceMsgShow(true);
			return false
		}
		Common.showLoader(true);
		let errors = [];
		let formData = new FormData(e.target);
		let pid = localStorage.getItem('fo_profileid');
		formData.append('pid', pid);
		formData.append('cid', cid);
		formData.append('cust_name', cust_name);
		formData.append('cust_state', ((cust_state[0]) ? cust_state[0].value : ''));
		formData.append('payee', payee);
		formData.append('doc_type', ((doc_type[0]) ? doc_type[0].value : 'INV'));
		formData.append('supply_type', ((supply_type[0]) ? supply_type[0].value : 'B2C'));
		formData.append('income_type', income_type);
		formData.append('income_subtype', income_subtype);
		formData.append('update_cust', update_cust);
		formData.append('total_deduction', total_deduction);
		formData.append('allocation_centre', allocation_centre);
		formData.append('etin', etin);
		formData.append('rev_chrg', rev_chrg);
		formData.append('igst_intra', igst_intra);
		formData.append('total_payment', total_payment);
		formData.append('poid', po_no);
		formData.append('pos', (cust_pos[0]!== undefined ? JSON.stringify(cust_pos[0]) : ''));
		formData.append('dispFrState', ((dispFrState[0]) ? dispFrState[0].value : ''));
		formData.append('shipToState', ((shipToState[0]) ? shipToState[0].value : ''));
		formData.append('total_taxable_amt', doc_taxable_amt);
		formData.append('other_charges', docOtherCharge);
		formData.append('round_off', docRoundoff);
		formData.append('total_doc_discount', docDiscount);
		formData.append('portCode', portCode);
		formData.append('expForCur', expForCur);
		formData.append('expCountryCode', expCountryCode);
		formData.append('submitType', submitType);
		formData.append('sid', sid);
		// formData.append('copy_type', state.copy_type);
		formData.append('copy_type', copyType);
		formData.append('actionType', actionType);
		formData.append('totalAdjustedAmount', totalAdjustedAmount);
		formData.append('saveDraftInvoice', saveDraftInvoice);
		formData.append('cdReason', cdReason);
		formData.append('refId', refId);
		formData.append('refInvNo', refInvNo);
		formData.append('ref_doc_date', ref_doc_date);
		if (due <= 0) {
			formData.append('due_date', '');
		}
		if(invRemarksDiv){
			formData.append('declaration', declaration);
			formData.append('tnc', tnc);
			formData.append('licence', licence);
		}

		// for tab redirection
		let tab_type = 0;
		if (submitType === '1') {
			tab_type = 2;
		}

		if( input_items.length > 0 ){
			input_items.forEach((val,index) => {
				if( (Number(val.current_stock) < Number(val.item_qty)) && val.item_service === '0' && val.stock_movement === '1' && submitType === '0' && val.allow_neg_stock === "0"){
					errors.push('Item '+(index+1)+': Current stock is less than order quantity.');
					return false;
				}
				if( Number(val.item_qty) < Number(val.min_qty) && val.item_service === '0' && submitType === '0' ){
					errors.push('Item '+(index+1)+': Order quantity should be greater than min order quantity.');
					return false;
				}
				if(Math.sign(val.price) === -1){
					errors.push('Item '+(index+1)+': Item price should not be negative value.');
					return false;
				}
				if(Math.sign(val.item_tot_price) === -1){
					errors.push('Item '+(index+1)+': Item total price should not be negative value.');
					return false;
				}
				if(!val.gst_rate_id){
					errors.push('Item '+(index+1)+': Item GST Rate is required.');
					return false;
				}
			});
			formData.append('items', JSON.stringify(input_items));
		}
		if( (payments.length > 0 && submitType === '1') || (advanceAdjust.length > 0 && submitType === '1') ){
			Common.showLoader(false);
			setPaymentCheckShow(true);
			return false;
		}
		if( payments.length > 0 && submitType === '0' ){
			formData.append('payments', JSON.stringify(payments));
		}
		if( advanceAdjust.length > 0 && submitType === '0' ){
			formData.append('advanceAdjust', JSON.stringify(advanceAdjust));
		}
		if( docGstRate.length > 0 ){
			formData.append('docGstRate', JSON.stringify(docGstRate));
		}
		let data = {};

		// convert the key/value pairs
		for (var [key, value] of formData.entries()) {
		   data[key] = value;
		}

		if( data.cust_name === '' ){
			errors.push('Customer name cannot be blank.');
		}
		if( (data.cust_gstin !== '' && data.cust_gstin !== 'URP' && data.cust_gstin !== undefined) && !Common.validGstin(data.cust_gstin)){
			errors.push('Customer Details: GSTIN is invalid.');
		}
		if (income_type === '' || income_type === undefined || income_type === '0') {
			errors.push('Income Type cannot be blank<br/>');
		}
		/*if( data.pos === '' ){
			errors.push('Customer Details: POS cannot be blank.');
		}*/
		if( data.doc_type === '' ){
			errors.push('Invoice Data : Document Type cannot be blank<br/>');
		}
		if( data.doc_type === 'CRN' && Number(refPaymentDue) < Number(data.total_amt)){
			errors.push('Invoice Data : Payment due should be lesser than or equal to total amount<br/>');
		}
		if( dispDetDiv && submitType === '0' ){
			if( data.dispFrName === '' ){
				errors.push('Dispatch From Details: Company name cannot be blank.');
			}
			if( data.dispFrAddr === '' ){
				errors.push('Dispatch From Details: Address cannot be blank.');
			}
			if( data.dispFrLoc === '' ){
				errors.push('Dispatch From Details: Location cannot be blank.');
			}
			if( data.dispFrPin === '' ){
				errors.push('Dispatch From Details: Pincode cannot be blank.');
			}
			if( data.dispFrState === '' ){
				errors.push('Dispatch From Details: State cannot be blank.');
			}
		}
		if( shipToDiv && submitType === '0' ){
			if( data.shipToLglName === '' ){
				errors.push('Ship To Details: Legal name cannot be blank.');
			}
			if( (data.shipToGstin !== '' && data.shipToGstin !== 'URP' && data.shipToGstin !== undefined) && !Common.validGstin(data.shipToGstin)){
				errors.push('Ship To Details: Company GSTIN is invalid.');
			}
			if( data.shipToAddr === '' ){
				errors.push('Ship To Details: Address cannot be blank.');
			}
			if( data.shipToLoc === '' ){
				errors.push('Ship To Details: Location cannot be blank.');
			}
			if( data.shipToPin === '' ){
				errors.push('Ship To Details: Pincode cannot be blank.');
			}
			if( data.shipToState === '' ){
				errors.push('Ship To Details: State cannot be blank.');
			}
		}
		if( expDetDiv && submitType === '0' ){
			if( data.expShipbNo === '' ){
				errors.push('Export Details: Shipping Bill Number cannot be blank.');
			}
			if( data.expShipbDate === '' ){
				errors.push('Export Details: Shipping Bill Date cannot be blank.');
			}
			if( data.portCode === '' ){
				errors.push('Export Details: Port Code cannot be blank.');
			}
			if( data.expForCur === ''){
				errors.push('Export Details: Foreign Currency Code cannot be blank.');
			}
			if( data.expCountryCode === '' ){
				errors.push('Export Details: Country Code cannot be blank.');
			}
			if( data.expRefClm === '' ){
				errors.push('Export Details: Supplier for Refund cannot be blank.');
			}
			if( data.expDutyAmt === '' ){
				errors.push('Export Details: Export Duty Amount cannot be blank.');
			}
		}
		if( errors.length > 0 ){
			Common.showLoader(false);
			let err_list = '';
			errors.forEach((val, index) => {
				err_list += '<li>'+val+'</li>';
			})
			const err_html = () => (
				<ul><strong>List of Errors:</strong>{Common.parse(err_list)}</ul>
			);
			Common.toast.error(err_html);
			return false;
		}
		let url = props.url+'sales/add';
		if((submitType === '1' && actionType === 'edit') || (submitType === '0' && actionType === 'edit')){
			url = props.url+'sales/edit';
		}
		Common.axios({
			method: 'post',
			url: url,
			data: data
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				navigate("/sales-listing",
				{
					state: {
						tab_type: tab_type,
						pid:localStorage.getItem('fo_profileid')
					}
				}
				);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const customerSearch = (query: string) => {
		setIsCustLoading(true);

		fetch(props.url+`customer/search/`+localStorage.getItem('fo_profileid')+`/${query}`)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setCustomers(items);
			setIsCustLoading(false);
		});
	};
	const refInvoiceSearch = (query) => {
		setIsRefLoading(true);
		let datas = encodeURIComponent(window.btoa(query));
		fetch(props.url+`sale/refInvoice/`+localStorage.getItem('fo_profileid')+'/'+cid+'/'+datas)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setRefInvoice(items);
			setIsRefLoading(false);
		});
	};
	const itemSearch = (query: string,count) => {
		setIsItemLoading(true);
		fetch(props.url+`items/search/`+localStorage.getItem('fo_profileid')+`/${query}`+`/S`)
			.then((resp) => resp.json())
			.then(({ items,total_count }: Response) => {
			setTotalItemCount([{'name':count,'value':total_count}]);
			setItems(items);
			setItemsName(query);
			setIsItemLoading(false);
		})
	};
	const expPortCodeSearch = (query: string) => {
		setIsExpPortCodeLoading(true);

		fetch(props.url+`ajax/getPortData/${query}`)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setPortCodeMaster(items);
			setIsExpPortCodeLoading(false);
		});
	};
	const expForCurSearch = (query: string) => {
		setIsExpForCurLoading(true);

		fetch(props.url+`ajax/getCurrencyData/${query}`)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setCurrencyMaster(items);
			setIsExpForCurLoading(false);
		});
	};
	const expCountryCodeSearch = (query: string) => {
		setIsExpCountryCodeLoading(true);

		fetch(props.url+`ajax/getCountryCode/${query}`)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setCountryCodeMaster(items);
			setIsExpCountryCodeLoading(false);
		});
	};

	const getAdvanceBalance = (cid: string) => {
		if (cid !== '') {
			Common.axios({
				method: 'get',
				url: props.url+'collection/getAdvanceDetails/'+localStorage.getItem('fo_profileid')+'/'+cid,
			}).then(function (response) {
				if( response.data.success ){
					setAdvanceBalance(response.data.data.closing_balance);
				}
			}).catch(error => console.log(error));
		}
	};


	const getPOContractsForId = (cid: string) => {
		if (cid !== '') {
			fetch(props.url+`po-contract/search/`+localStorage.getItem('fo_profileid')+`/${cid}`)
				.then((resp) => resp.json())
				.then(({ items }: Response) => {
				setPoMaster(items);
			});
		}
	};
	const setRefInvoiceDetails = (e) => {
		if(e[0] === undefined) {
			console.log(e[0])
			setRefInvSel([]);
			setRefDocDate('');
			return;
		}
		setRefInvSel(e);
		setRefId(e[0].id.startsWith('new') ? '':e[0].id);
		setRefInvNo(e[0].inv_no);

		if( e[0].inv_date !== undefined ){
			setRefDocDate(e[0].inv_date);
		}
		setRefTotalValue(e[0].total_amt);
		setRefPaymentDue(e[0].due_amt);
		setTotalAmt(e[0].due_amt);
	}
	const showCustAdditional = () => {
		setCustAddiDiv( (custAddiDiv) ? false : true );
	}
	const showDocTaxAdditional = () => {
		setDocTaxAddiDiv( (docTaxAddiDiv) ? false : true );
	}
	const toggleItemDiv = () => {
		setItemDiv((itemDiv) ? false : true);
		if( !itemDiv ){
			setTotalAmt('');
			setDocOtherCharge('');
			setDocRoundoff('');
			setDocDiscount('');
			calculateTotalAmt();
		}
		if(itemDiv){
			setInputItems([]);
			setTotalAmtDisabled(false);
			setDocGstRate([]);
		}
	}
	const toggeInvRemarksDiv = () => {
		setInvRemarksDiv((invRemarksDiv) ? false : true);
	}
	const toggeDispDetDiv = () => {
		setDispDetDiv((dispDetDiv) ? false : true);
	}
	const toggeShipToDiv = () => {
		setShipToDiv((shipToDiv) ? false : true);
	}
	const toggeExpDetDiv = () => {
		setExpDetDiv((expDetDiv) ? false : true);
	}
	const toggeCustomInvFields = () => {
		setCustomInvFields((customInvFields) ? false : true);
		if(!customInvFields){
			handleCustomInvFields();
		}else{
			setCustomInputList([]);
		}
	}
	const populatePinData = (param,pincode) => {
		switch(param) {
			case 'customer':
				setCustPincode(pincode);
				if( pincode.length=== 6 ){
					Common.axios({
						method: 'post',
						url:props.url+'ajax/getDataByPin',
						data: {pincode:pincode},
					}).then(function (response) {
						if(response.data.success){
							if( response !== undefined && response.data.stateid !== undefined && response.data.stateid !== '' ){
								setCustState([{'value':response.data.stateid,'label':'','digits':''}]);
							}
							setCustDistrict(response.data.district);
							return false;
						}
						Common.toast.error(response.data.message);
					}).catch(error => console.log(error));
				}
				break;
			case 'dispatch':
				setDispFrPin(pincode);
				if( pincode.length=== 6 ){
					Common.axios({
						method: 'post',
						url:props.url+'ajax/getDataByPin',
						data: {pincode:pincode},
					}).then(function (response) {
						if(response.data.success){
							if( response !== undefined && response.data.stateid !== undefined && response.data.stateid !== '' ){
								setDispFrState([{'value':response.data.stateid,'label':'','digits':''}]);
							}
							setDispFrLoc(response.data.district);
							return false;
						}
						Common.toast.error(response.data.message);
					}).catch(error => console.log(error));
				}
				break;
			case 'shipto':
				setShipToPin(pincode);
				if( pincode.length=== 6 ){
					Common.axios({
						method: 'post',
						url:props.url+'ajax/getDataByPin',
						data: {pincode:pincode},
					}).then(function (response) {
						if(response.data.success){
							if( response !== undefined && response.data.stateid !== undefined && response.data.stateid !== '' ){
								setShipToState([{'value':response.data.stateid,'label':'','digits':''}]);
							}
							setShipToLoc(response.data.district);
							return false;
						}
						Common.toast.error(response.data.message);
					}).catch(error => console.log(error));
				}
				break;
			default:
				break;
		}
	}
	/*const mainCalculation = (param,val) => {
		let total_temp = 0,recv_temp = 0,ded_temp=0,due_temp = 0;
		if( total_amt !== undefined ){
			total_temp = total_amt;
		}
		if( param === 'total_amt' ){
			total_temp = val;
			setTotalAmt(total_temp);
		}
		if( total_payment !== undefined ){
			recv_temp = total_payment;
		}
		if( param === 'received' ){
			recv_temp = val;
			setTotalPayment(recv_temp.toFixed(2));
		}
		if( total_deduction !== undefined ){
			ded_temp = total_deduction;
		}
		if( param === 'deduction' ){
			ded_temp = val;
			setTotalDeduction(Number(ded_temp).toFixed(2));
		}
		summaryTotalCal(total_temp,recv_temp,ded_temp);
	}*/

	const quickItemCalculation = (param,val) => {
		if( param === 'item_price' ){
			setItemPrice(val);
			setGrossAmt((Number(val) - Number(discount_amt)).toFixed(2));
			let gstAmt = (((Number(val) - Number(discount_amt)) * gst_rate)/100).toFixed(2);
			setGstAmt(gstAmt);
			setItemTotal(((Number(val) - Number(discount_amt)) + Number(gstAmt)).toFixed(2));
			if( Number(val) > 0 ){
				setDisPerDisabled(false);
				setDisAmtDisabled(false);
			}
			if( Number(val) === 0 ){
				setDisPerDisabled(true);
				setDisAmtDisabled(true);
			}
			//  || Number(item_price) === 0
			if( Number(val) === 0){
				setGrossAmt('');
				setItemTotal('');
				setGstAmt('');
			}
		}
		if( param === 'discount_amt' ){
			setGrossAmt((Number(item_price) - Number(val)).toFixed(2));
			let gstAmt = (((Number(item_price) - Number(val)) * gst_rate)/100).toFixed(2);
			setGstAmt(gstAmt);
			setItemTotal(((Number(item_price) - Number(val)) + Number(gstAmt)).toFixed(2));
		}
		if( param === 'gst_rate' ){
			setGrossAmt((Number(item_price) - Number(discount_amt)).toFixed(2));
			setGstRate(val);
			let gstAmt = ((gross_amt * val)/100).toFixed(2);
			setGstAmt(gstAmt);
			setItemTotal(((Number(item_price) - Number(discount_amt)) + Number(gstAmt)).toFixed(2));
		}
	}
	const calcPurchaseDiscount = (param,val) => {
		let percent = 0,amount = 0, price = purchase_item_price;
		if( param === 'per' ){
			percent = val;
			amount = (price * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/price * 100).toFixed(2);
		}
		setPurchaseDiscountPer(percent);
		setPurchaseDiscountAmt(amount);
		purchaseItemCalculation('discount_amt',amount);
	}
	const purchaseItemCalculation = (param,val) => {
		if( param === 'item_price' ){
			setPurchaseItemPrice(val);
			setPurchaseGrossAmt((Number(val) - Number(purchase_discount_amt)).toFixed(2));
			let gstAmt = (((Number(val) - Number(purchase_discount_amt)) * gst_rate)/100).toFixed(2);
			setPurchaseGstAmt(gstAmt);
			setPurchaseItemTotal(((Number(val) - Number(purchase_discount_amt)) + Number(gstAmt)).toFixed(2));
			if( Number(val) > 0 ){
				setDisPurchasePerDisabled(false);
				setDisPurchaseAmtDisabled(false);
			}
			if( Number(val) === 0 ){
				setDisPurchasePerDisabled(true);
				setDisPurchaseAmtDisabled(true);
			}
			//  || Number(purchase_item_price) === 0
			if( Number(val) === 0){
				setPurchaseGrossAmt('');
				setPurchaseItemTotal('');
				setPurchaseGstAmt('');
			}
		}
		if( param === 'discount_amt' ){
			setPurchaseGrossAmt((Number(purchase_item_price) - Number(val)).toFixed(2));
			let gstAmt = (((Number(purchase_item_price) - Number(val)) * gst_rate)/100).toFixed(2);
			setPurchaseGstAmt(gstAmt);
			setPurchaseItemTotal(((Number(purchase_item_price) - Number(val)) + Number(gstAmt)).toFixed(2));
		}
		if( param === 'gst_rate' ){
			setPurchaseGrossAmt((Number(purchase_item_price) - Number(purchase_discount_amt)).toFixed(2));
			let gstAmt = ((purchase_gross_amt * val)/100).toFixed(2);
			setPurchaseGstAmt(gstAmt);
			setPurchaseItemTotal(((Number(purchase_item_price) - Number(purchase_discount_amt)) + Number(gstAmt)).toFixed(2));
		}
	}
	const setCustomItemQty = (val,cnt) => {
		let newArr = '';
		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
		setSafetyStock(newArr);
	}
	const setReorderPointValue = (val,cnt) => {
		let newArr = '';
		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
		setReorderPoint(newArr);
	}
	const setReorderQtyValue = (val,cnt) => {
		let newArr = '';
		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
		setReorderQty(newArr);
	}
	const setMaxStockLevelValue = (val,cnt) => {
		let newArr = '';
		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
		setMaximumStockLevel(newArr);
	}
	const handleCustomItemInvFields=()=>{
		const allWithClass = Array.from(
			document.getElementsByClassName('custom_fields')
		);
		if(allWithClass.length <= 9){
        	setCustomItemInputList(customItemInputList.concat(<ItemCustomFields key={customItemInputList.length} len={customItemInputList.length}/>));
		}
		else{
			Common.toast.error('Max Custom Fields Reached');
		}
    }
    const toggeCustomInputInvFields = () => {
		setCustomItemInputList((customItemFields) ? false : true);
		if(!customItemFields){
			handleCustomItemInvFields();
		}else{
			setCustomItemFields([]);
		}
	}
	const showAdditionalItemFields = () => {
		setAdditionalItemDiv( (additionalItemDiv) ? false : true );
	}
	const showSalePurchaseAdditionalItemFields = () => {
		setSalePurchaseAdditionalItemDiv( (salePurchaseAdditionalItemDiv) ? false : true );
	}

	// Quick Add Item
	const quickAddItem = () => {
		setItemPrice('');
		setOpeningStock('');
		setMinQty('1');
		setOpeningStockDt(new Date());
		setDiscountAmt('');
		setDiscountPer('');
		// setItemService('');
		setItemHSN('');
		setGrossAmt('');
		setGstRate('');
		setGstAmt('');
		setItemTotal('');
		setquickItemModalShow(true);
	}
	const handleAddiFields=(val)=>{
		// if (val === '') {
		// 	setDividerDisplay(false)
		// 	setSalePurchaseDividerDisplay(false)
		// }
		setItemsName(val);
		if( dupTimeout ){
			clearTimeout(dupTimeout);
			setDupTimeout(null);
		}
		if( val !== '' && val.length >= 3 ){
			setItemAdditional(true);
			setDupTimeout(setTimeout(() => {
				Common.axios({
					method: 'post',
					url:props.url+'items/duplicate',
					data: {'name':val,'pid':localStorage.getItem('fo_profileid')}
				}).then(function (response) {
					if(!response.data.success){
						resetForm();
						Common.toast.error(response.data.message);
					}
				}).catch(error => console.log(error));
			}, 2000));
			return false;
		}
		setItemAdditional(true);
		setItemServiceType('');
		return false;
    }
	const handleItem = (e)=>{
        e.preventDefault();
        let formData = new FormData(e.target);
        if(itemName===""){
            Common.toast.error("Item Name is Required.");
            return false;
        }
		if(itemName.length < 3){
            Common.toast.error("Item Name should have minimum 3 characters.");
            return false;
        }
		if (itemServiceType !== '1') {
			if (isNegativeBal === false && opening_stock < 0) {
				Common.toast.dismiss();
				Common.toast.error("Opening Stock cannot be Negative.");
				return false;
			}
		}

        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}

		data['item_unit'] = (itemServiceType === '1')?29:item_unit;
		data['discount'] = discount_amt;
		data['allow_neg_stock'] = allowNegStock;
		data['purchase_discount'] = purchase_discount_amt;
		data['gst_rt'] = gst_rate;
		data['gst_rate_id'] = gst_rate_id;
		data['item_service'] = itemServiceType;
		data['opening_stock'] = (itemServiceType === '1')?0:opening_stock;
		data['min_qty'] = (itemServiceType === '1')?0:min_qty;
		// data['item_hsn'] = (itemServiceType === '1')?'99'+itemHSN:itemHSN;
		if (itemServiceType === '1') {
			if (itemHSN !== '') {
				data['item_hsn'] = '99'+itemHSN;
			} else {
				data['item_hsn'] = '';
			}
		} else {
			data['item_hsn'] = itemHSN;
		}
		data['pid'] = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'items/addRecord',
            data: data,
        }).then(function (response) {
			if(response.data.success){
				// navigate("/item-listing");
				Common.toast.success(response.data.message);
				setItemsName(''); setItemUnit('28'); setItemPrice('');
				resetForm();
				itemSearch(itemName);
				setquickItemModalShow(false);
				setItemStockChecked(false);
			}else{
				Common.toast.error(response.data.message);
			}
		});
    }
	const calcDiscount = (param,val) => {
		let percent = 0,amount = 0, price = item_price;
		if( param === 'per' ){
			percent = val;
			amount = (price * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/price * 100).toFixed(2);
		}
		setDiscountPer(percent);
		setDiscountAmt(amount);
		quickItemCalculation('discount_amt',amount);
	}
	const resetForm = () => {
		handleAddiFields('');
		setItemUnit('28');
		setItemPrice('');
		setOpeningStock('');
		setMinQty('1');
		setOpeningStockDt(new Date());
		setDiscountAmt('');
		setDiscountPer('');
		// setItemService('');
		setItemHSN('');
		setGrossAmt('');
		setGstRate('');
		setGstAmt('');
		setItemTotal('');
		setItemServiceType('');
		setPurchaseItemPrice('');
		setPurchaseGrossAmt('');
		setPurchaseItemTotal('');
		setPurchaseDiscountAmt('');
		setPurchaseDiscountPer('');
		setDisPurchasePerDisabled(true);
		setDisPurchaseAmtDisabled(true);
		setPurchaseGstAmt('');
		setItemStockMovement(0);
		setSkuCode('');
		setBarcode('');
		setReorderQty('');
		setReorderPoint('');
		setSafetyStock('');
		setMaximumStockLevel('');
		// setDividerDisplay(false);
		setAdditionalItemDiv(true);
		setSalePurchaseAdditionalItemDiv(true);
		// setSalePurchaseDividerDisplay(false);
		setItemAdditional(true);
	}
	const getItemServiceType = (actype) => {
		if(itemName === ''){
			Common.toast.error('Item Name is Required.');
			return false;
		}
		if(itemName.length < 3){
            Common.toast.error("Item Name should have minimum 3 characters.");
            return false;
        }
		setItemServiceType(actype);
		setDividerDisplay(true);
		setSalePurchaseDividerDisplay(true);
	}
	const getItemStockMovement = () => {
		setItemStockMovement((itemStockMovement === '1') ? '0' : '1');
		setItemStockChecked((itemStockMovement === '1') ? false : true);
		if(itemStockMovement === '1'){
			setOpeningStock();
		}
	}
	const handleCustomInvFields=()=>{
		const allWithClass = Array.from(
			document.getElementsByClassName('custom_fields')
		);
		if(allWithClass.length <= 9){
        	setCustomInputList(customInputList.concat(<CustomInvFields key={customInputList.length} len={customInputList.length}/>));
		}
		else{
			Common.toast.error('Max Custom Fields Reached');
		}
    }
	const saleSettings =() => {
        navigate('/sale-settings',
		{
			state: {
				set_type: 1
			}
		});
    }
	const  modules  = {
        toolbar: [
            // [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["italic", "underline", "strike"],
            // [{ color: [] }, { background: [] }],
            // [{ script:  "sub" }, { script:  "super" }],
            ["blockquote", "code-block"],
            [{ list:  "ordered" }, { list:  "bullet" }],
            // ["clean"],
        ],
    };
	const copyWithInvCheck =(copyType,subType) => {
		if(copyType === 1){
			Common.toast.error('Invoice save draft not available for Copy with invoice number');
			return false;
		}
		setSubmitType(subType);
	}
	if( profile_details !==null && (profile_details.gstin === '' || profile_details.gstin === 'URP') ){
		p1 = true;
	}
    return(
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="add-sale" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Income & Collections /</span> {(actionType === 'copy')?'Add Sale':'Edit Draft'}
								<span className="dropdown">
									<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
									<i className='text-danger bx bxl-youtube bx-md'></i>
									</button>
									<div className="dropdown-menu" >
										<a href="https://youtu.be/enOCR8pBVdY" className="dropdown-item" target="_blank" rel="noreferrer"><span>Edit/Amend invoice</span></a>
									</div>
								</span>
							</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <div className="card-body">
											<form id="frmSales" onSubmit={handleSubmit} autoComplete="off">
												<div className="row" id="saledAddDiv">
													<div className="col">
														<div className="accordion" id="salesAccordion">
															<div className="card accordion-item active">
																<h2 className="accordion-header" id="headingOne">
																	<button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionMyDetails" aria-expanded="false" aria-controls="accordionMyDetails">
																		{profile_details.name}
																	</button>
																</h2>
																<div id="accordionMyDetails" className="accordion-collapse collapse" data-bs-parent="#salesAccordion" >
																	<div className="accordion-body py-3">
																		<div className="row">
																			{!p1 && <>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleGstin" placeholder="Enter GSTIN" value={profile_details.gstin} disabled/>
																					<label htmlFor="frmSaleGstin">GSTIN</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleLegalNm" placeholder="Enter Legal Name" value={profile_details.legal_name} disabled/>
																					<label htmlFor="frmSaleLegalNm">Legal Name</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleTradeNm" placeholder="Enter Trade Name" value={profile_details.trade_name} disabled/>
																					<label htmlFor="frmSaleTradeNm">Trade Name</label>
																				</div>
																			</div>
																			</>}
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleAddr" placeholder="Enter Address" value={profile_details.addr} disabled/>
																					<label htmlFor="frmSaleAddr">Address</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleLoc" placeholder="Enter Location" value={profile_details.district} disabled/>
																					<label htmlFor="frmSaleLoc">Location</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Select classNamePrefix="react-select" className="form-control" id="frmSaleState" placeholder="Select State" name='state' value={state_master[Common.stateIndex(state_master,profile_details.state)]} options={state_master} isDisabled={true} disabled/>
																					<label htmlFor="frmSaleState">State</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSalePincode" placeholder="Enter Pincode" value={profile_details.pincode} disabled/>
																					<label htmlFor="frmSalePincode">Pincode</label>
																				</div>
																			</div>
																			<div className="col-md-8 col-sm-6 mb-3">
																				<div className="form-floating">
																				<Typeahead clearButton selected={defaultPayee} id="frmSalePayeeDtls" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPayeeSelect(e)} options={payees} placeholder="Choose Payee"
																						renderMenuItemChildren={(option: Item,props) => (
																							<>
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																							<div>
																								<small>Bank Name: {option.bank_name}</small>, <small>Acc No.: {option.account_no}</small>
																							</div>
																							</>
																						)}
																					/>
																					<label htmlFor="frmSalePayeeDtls">Payee</label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingTwo">
																	<button type="button" className="accordion-button" data-bs-target="#accordionCustomer" aria-expanded="false" aria-controls="accordionCustomer" disabled>
																		Customer Details
																	</button>
																</h2>
																<div id="accordionCustomer" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row mb-2">
																			<Button className="text-end" variant="" size="sm" onClick={()=>customerListModal('show')}><Common.OverlayTrigger overlay={Common.popoverContent('Customer List','Click to search Customer by Name, GSTIN, Phone, Email.')}><i className='bx bxs-right-top-arrow-circle'></i></Common.OverlayTrigger></Button>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<AsyncTypeahead selected={custSel} clearButton allowNew newSelectionPrefix="Add: " filterBy={filterBy} id="sale-customer" className="floatingTypeahead" isLoading={isCustLoading} labelKey="party_name" minLength={3} onSearch={customerSearch} options={customers} placeholder="Search (min 3 chars)" onChange={(e) => setCustomerDetails(e)}
																						renderMenuItemChildren={(option: Item,props) => (
																							<div>
																								<Highlighter search={props.text}>
																									{option.party_name}
																								</Highlighter>
																								<div>
																									{(option.gstin !== 'URP' && option.gstin !== '') && <><small>Gstin: {option.gstin}</small>, </>}
																									<small>Mobile: {option.mobile}</small>
																								</div>
																							</div>
																						)}
																					/>
																					<label htmlFor="frmSaleCName">Customer Name <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6">
																				<div className="form-floating">
																					<input name="cust_phone" type="text" maxLength="10" className="form-control" id="frmSaleCPhone" placeholder="Enter Phone" value={cust_phone} onChange={(e) => setCustPhone(Common.acceptOnlyNumbers(e.target.value))} disabled={cust_phone_disabled}/>
																					{cust_phone_disabled && <span id="custPhoneEdit"
																						onClick = {function(e){
																							setCustPhoneDisabled(false);
																							setUpdateCust(1);
																						}}><Common.OverlayTrigger overlay={Common.popoverContent('Click to Edit Phone','On submit, phone number will be updated for the customer.')}><i className="bx bx-edit"></i></Common.OverlayTrigger></span>}
																					<label htmlFor="frmSaleCPhone">Phone</label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col">
																				<div className="divider">
																					<div className="divider-text"><i className={'bx bx-chevrons-'+ (custAddiDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showCustAdditional}>{custAddiDiv ? 'Hide':'Show'} additional fields</Common.Link><i className={'bx bx-chevrons-'+ (custAddiDiv ? "up":"down")+' bx-fade-up'}></i></div>
																				</div>
																			</div>
																		</div>
																		{custAddiDiv && <>
																		<div className="row">
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_gstin" type="text" className="form-control" id="frmSaleCGstin" placeholder="Enter Gstin" value={cust_gstin} maxLength="15" onChange={(e) => {handleGstinData('customer',e);setCustGstinSelect(Common.acceptOnlyCharsNumbers(e.target.value.toUpperCase()))}}/>
																					<label htmlFor="frmSaleCGstin">Gstin</label>
																					<div className="form-text">GSTIN Data will be auto fetched.</div>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_legal_name" type="text" className="form-control" id="frmSaleCLgNm" placeholder="Enter Legal Name" value={cust_legal_name} maxLength="100" onChange={(e) => setCustLegalName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmSaleCLgNm">Legal Name</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_trade_name" type="text" className="form-control" id="frmSaleCTrdNm" placeholder="Enter Trade Name" value={cust_trade_name} maxLength="100" onChange={(e) => setCustTradeName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmSaleCTrdNm">Trade Name</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_pincode" type="text" className="form-control" id="frmSaleCPincode" placeholder="Enter Pin Code" value={cust_pincode} maxLength="6" onChange={(e) => populatePinData('customer',Common.acceptOnlyNumbers(e.target.value))}/>
																					<label htmlFor="frmSaleCPincode">Pin Code</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_addr" type="text" className="form-control" id="frmSaleCAddr" placeholder="Enter Address" value={cust_addr} maxLength="250" onChange={(e) => setCustAddr(e.target.value)}/>
																					<label htmlFor="frmSaleCAddr">Address</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_district" type="text" className="form-control" id="frmSaleCDist" placeholder="Enter District" value={cust_district} maxLength="100" onChange={(e) => setCustDistrict(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmSaleCDist">District</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton id="frmSaleCState" labelKey="label" key="value" className="floatingTypeahead" placeholder="Select State"
																						options={state_master}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																						)}
																						selected={cust_state.length > 0 ? [state_master.find(obj=>{return obj.value == cust_state[0].value})] : []}
																						onChange={(e)=>{setCustState(e)}}
																					/>
																					<label htmlFor="frmSaleCState">State</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton id="frmSalePOS" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setCustPOS(e)} placeholder="Select POS"
																						options={state_master}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																						)}
																						selected={cust_pos.length > 0 ? [state_master.find(obj=>{return obj.digits == cust_pos[0].digits})] : []}
																					/>
																					<label htmlFor="frmSalePOS">POS</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_email" type="text" className="form-control" id="frmPOCEmail" placeholder="Enter Customer Email" value={cust_email} maxLength="100" onChange={(e) => setCustEmail(e.target.value)}/>
																					<label htmlFor="frmPOCEmail">Email</label>
																				</div>
																			</div>
																		</div>
																		</>}
																	</div>
																</div>
															</div>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingThree">
																	<button type="button" className="accordion-button" data-bs-target="#accordionInvoice" aria-expanded="false" aria-controls="accordionInvoice" disabled>
																		Invoice Data
																	</button>
																</h2>
																<div id="accordionInvoice" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" name="doc_no" className="form-control" id="frmSaleInvNo" placeholder="Enter Inv No." value={doc_no} readOnly/>
																					<label htmlFor="frmSaleInvNo">Document No<span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<DatePicker name="doc_date" data-testid="frmSaleDocDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={(e) => setDocDateSelect(e)} value={doc_date} />
																					<label htmlFor="frmSaleDocDate">Document Date<span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton id="frmSaleDocType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDocTypeSelect(e)} placeholder="Choose ..."
																						options={docTypes}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																						)}
                                                                                        selected={doc_type.length > 0 ? [docTypes.find(obj=>{return obj.value == doc_type[0].value})] : []}
																					/>
																					<label htmlFor="frmSaleDocType">Document Type <span className="text-danger">*</span></label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead selected={poNoSel} clearButton useCache={false} id="frmSalePO" ref={frmSalePO} className="floatingTypeahead" labelKey="po_no" onChange={(e) => setPOSelect(e)} options={po_master}
																						renderMenuItemChildren={(option: Item,props) => (
																							<>
																								<Highlighter search={props.text}>
																									{option.po_no}
																								</Highlighter>
																								<div>
																								{option.po_end_date !== '0000-00-00' && <>
																									<small>End Date: {Common.displayDate(option.po_end_date)}</small>
																									,
																									</>}<small>Balance: {option.balance}</small>
																								</div>
																							</>
																						)}
																					/>
																					<label htmlFor="frmSalePO">PO/Contract Number</label>
																				</div>
																			</div>
																		</div>
																		{ doc_type.length > 0 && (doc_type[0].value === 'CRN' || doc_type[0].value === 'DBN') && (<>
																		<div className='row'>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<AsyncTypeahead selected={refInvSel} clearButton  filterBy={filterBy} id="sale-ref_doc-no" className="floatingTypeahead" isLoading={isRefLoading} labelKey="inv_no" minLength={3} onSearch={refInvoiceSearch} options={refInvoice} placeholder="Search (min 3 chars)" onChange={(e) => setRefInvoiceDetails(e)}
																						renderMenuItemChildren={(option: Item,props) => (
																							<div>
																								<Highlighter search={props.text}>
																									{option.inv_no}
																								</Highlighter>
																							</div>
																						)}
																					/>
																					<label htmlFor="sale-ref_doc-no">Ref Document No. <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<DatePicker name="ref_document_date" calendarIcon={null} clearIcon={null} disableCalendar={true} data-testid="frmSaleDocDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setRefDocDate} value={ref_doc_date} disabled />
																					<label htmlFor="frmSaleDocDate">Ref Document Date<span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cdReason" type="text" maxLength="250" className="form-control" id="cd-reason" placeholder="Enter Note Reason" value={cdReason} onChange={(e)=>setCDReason(Common.acceptOnlyChars(e.target.value))} required/>
																					<label htmlFor="cd-reason">Note  Reason <span className="text-danger">*</span></label>
																				</div>
																			</div>
																		</div>
																		</>)}
																		<div className="row">
																			<div className="col">
																				<div className="divider">
																					<div className="divider-text"><i className={'bx bx-chevrons-'+ (invAddiDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={()=>setInvAddiDiv( (invAddiDiv) ? false : true )}>{invAddiDiv ? 'Hide':'Show'} additional fields</Common.Link><i className={'bx bx-chevrons-'+ (invAddiDiv ? "up":"down")+' bx-fade-up'}></i></div>
																				</div>
																			</div>
																		</div>
																		{invAddiDiv && <>
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="etin" placeholder="ECOM GSTIN" value={etin} maxLength={15} onChange={(e)=>setEtin(e.target.value)}/>
																					<label htmlFor="Etin">ECOM GSTIN</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton  id="frmSaleSupplyType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setSupplyType(e)} options={supplyTypes} placeholder="Choose supply type"
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																						)}
																						selected={supply_type.length > 0 ? [supplyTypes.find(obj=>{return obj.value == supply_type[0].value})] : []}
																					/>
																					<label htmlFor="frmSaleSupplyType">Supply Type</label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<label htmlFor="frmSaleRevChrg">Reverse Charge</label>
																				<div className="col">
																					<div className="form-check form-check-inline">
																						<input className="form-check-input" type="radio" name="rev_chrg" id="revchrgYes" value="Y" onChange={(e) => setRevChrg(e.target.value)} checked={rev_chrg === "Y"}/>
																						<label className="form-check-label" htmlFor="revchrgYes">Yes</label>
																					</div>
																					<div className="form-check form-check-inline">
																						<input className="form-check-input" type="radio" name="rev_chrg" id="revchrgNo" value="N" onChange={(e) => setRevChrg(e.target.value)} checked={rev_chrg === "N"}/>
																						<label className="form-check-label" htmlFor="revchrgNo">No</label>
																					</div>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<label htmlFor="frmSaleRevChrg">Transaction IGST on Intra</label>
																				<div className="col">
																					<div className="form-check form-check-inline">
																						<input className="form-check-input" type="radio" name="igst_intra" id="trIGSTYes" value="Y" onChange={(e) => setIgstIntra(e.target.value)} checked={igst_intra === "Y"}/>
																						<label className="form-check-label" htmlFor="trIGSTYes">Yes</label>
																					</div>
																					<div className="form-check form-check-inline">
																						<input className="form-check-input" type="radio" name="igst_intra" id="trIGSTNo" value="N" onChange={(e) => setIgstIntra(e.target.value)} checked={igst_intra === "N"}/>
																						<label className="form-check-label" htmlFor="trIGSTNo">No</label>
																					</div>
																				</div>
																			</div>
																		</div>
																		</>}
																	</div>
																</div>
															</div>
															{itemDiv && <>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingFour">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionItem" aria-expanded="false" aria-controls="accordionItem">
																		Item Data
																	</button>
																</h2>
																<div id="accordionItem" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		{ sale_items_loop.map((count) => (
																		<div key={'item_'+count} id={'item_'+count} className="row">
																			<div className="col-6 my-1">
																			 <div className="row">
																			  <div className="mb-3 col">
																					<strong>Item: {count+1}</strong> {input_items.length > 1 && <button onClick={() => {removeItem(count,docGstRate,true);itemRefs.current[count]?.clear();}} className="btn btn-sm btn-danger" type="button"><i className='bx bx-trash'></i></button>}
																			  </div>
																			  <div className="mb-3 col text-right">
																				{totalItemCount[0] && totalItemCount[0].name == count && totalItemCount[0].value == 0 && <span><i className='bx bxs-bolt'></i><span className='text-info cursor-pointer' onClick={() => {quickAddItem()}}>Quick Add</span></span>}
																			  </div>
																			 </div>
																			</div>
																			<div className="col-6 my-1"></div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<AsyncTypeahead clearButton useCache={false} ref={el => (itemRefs.current[count] = el)} disabled={input_items[count] && input_items[count]['disabled']} filterBy={filterBy} id={'frmSaleItmName_'+count} className="floatingTypeahead" isLoading={isItemLoading} labelKey="name" onSearch={term => {itemSearch(term,count);}} options={items} placeholder="Search (min 3 chars)" onChange={(e) => setItemDetails(e,count,docGstRate)}
																						renderMenuItemChildren={(option: Item,props) => (
																							<div>
																								<Highlighter search={props.text}>
																									{option.name}
																								</Highlighter>
																								<div>
																									<small>Price: {Common.displayPrice(option.total_price)}</small>
																									{option.item_service === '0' && option.stock_movement === '1' && <>
																									, <small>Available: {option.current_stock === '0.00' || option.current_stock === 0 ? 'Out of Stock' : Number(option.current_stock)}</small>
																									</>}
																								</div>
																							</div>
																						)}
																						selected={(input_items[count] ? [input_items[count]] : [])}
																						emptyLabel = "Add as new in Inventory mgmt."
																					/>
																					{input_items[count] && input_items[count]['disabled'] && <span id="itemDelete"
																						onClick = {function(e){
																							removeItem(count,docGstRate);
																							itemRefs.current[count]?.clear();
																						}}><i className="bx bx-x"></i></span>}
																					<label htmlFor={"frmSaleItmName_"+count}>Item Name</label>
																				</div>
																				{input_items[count] && input_items[count]['current_stock'] !== undefined && input_items[count]['item_service'] === '0' && input_items[count]['stock_movement'] === '1' && <>
																				<div className="form-text">
																					Current Stock: {input_items[count]['current_stock'] === '0.00' || input_items[count]['current_stock'] === 0 ? 'Out of Stock' : Number(input_items[count]['current_stock'])}
																				</div>
																				</>}
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmSaleItmDesc_"+count} placeholder="Enter Description" disabled={input_items[count]? false : true} value={(input_items[count] && input_items[count]['description']) ? input_items[count]['description'] : ''} onChange={(e)=>setItemDesc(count,e.target.value)}/>
																					<label htmlFor={"frmSaleItmDesc_"+count}>Item Description</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" step=".001" min="0" className="form-control" id={'frmSaleItmQty_'+count} placeholder="Enter quantity" value={(input_items[count] && input_items[count]['item_qty']) ? input_items[count]['item_qty'] : ''} onChange={(e) => setItemQty(Common.acceptOnlyDecimal(e.target.value),count,docGstRate)} disabled={input_items[count]? false : true} /*readOnly={ (input_items[count] && input_items[count]['item_service'] === '1')?true:false }*//>
																					<label htmlFor={'frmSaleItmQty_'+count}>Quantity</label>
																				</div>
																				{input_items[count] && input_items[count]['min_qty'] > 0 && <>
																				<div className="form-text">
																					Min Order Qty: {Common.displayPercent(input_items[count]['min_qty']) || ''}
																				</div>
																				</>}
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmSaleItmUnit_"+count} placeholder="Enter Unit" value={(input_items[count] && input_items[count]['unit_name']) ? input_items[count]['unit_name'] : ''} readOnly/>
																					<label htmlFor={"frmSaleItmUnit_"+count}>Unit</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmSaleItmHsn_"+count} placeholder="Enter HSN" value={(input_items[count] && input_items[count]['hsn']) ? input_items[count]['hsn'] : ''} readOnly/>
																					<label htmlFor={"frmSaleItmHsn_"+count}>HSN</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmSaleItmPrice_"+count} placeholder="Enter Price" value={(input_items[count] && input_items[count]['price']) ? input_items[count]['price'] : ''} readOnly={ (input_items[count])?false:true } onChange={(e) => handleItemPrice(Common.acceptOnlyDecimal(e.target.value),count,'item_price', docGstRate)}/>
																					<label htmlFor={"frmSaleItmPrice_"+count}>Price/Unit</label>
																				</div>
																			</div>
																			{/* <div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="number" className="form-control" id={"frmSaleItmDiscount_"+count} placeholder="Enter Discount" value={(input_items[count] && input_items[count]['item_discount']) ? input_items[count]['item_discount'] : ''} readOnly={ (input_items[count])?false:true } onChange={(e) => handleItemPrice(e.target.value,count,'item_discount')} />
																					<label htmlFor={"frmSaleItmDiscount_"+count}>Discount</label>
																				</div>
																			</div> */}
																			<div className="col-md-2 col-sm-3 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmSaleItmDiscount_"+count} placeholder="Enter Discount" value={(input_items[count] && input_items[count]['item_discount']) ? input_items[count]['item_discount'] : ''} readOnly={ (input_items[count])?false:true } onChange={(e) => handleItemPrice(Common.acceptOnlyDecimal(e.target.value),count,'item_discount', docGstRate)} />
																					<label htmlFor={"frmSaleItmDiscount_"+count}>Disc in ₹</label>
																				</div>
																			</div>
																			<div className="col-md-2 col-sm-3 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmSaleItmDiscountPer_"+count} placeholder="Enter Discount %" value={(input_items[count] && input_items[count]['item_discount_per']) ? input_items[count]['item_discount_per'] : ''} readOnly={ (input_items[count])?false:true } onChange={(e) => handleItemPrice(Common.acceptOnlyDecimal(e.target.value),count,'item_discount_per', docGstRate)} />
																					<label htmlFor={"frmSaleItmDiscountPer_"+count}>Disc in %</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead disabled={ (input_items[count])?false:true } clearButton id="gst_rate" key="id" className="floatingTypeahead border-end rounded-end" onChange={(e) => {handleItemPrice((e[0] ? e[0].val : ''),count,'gst_rate',docGstRate,e[0] ? e[0].id : '');}} placeholder="Select GST"
																						options={taxes}
																						labelKey="rate"
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.rate}
																							</Highlighter>
																						)}
																						selected={input_items[count] && input_items[count]['gst_rate_id'] ? [taxes.find(obj=>{return obj.id == input_items[count]['gst_rate_id']})] : []}
																					/>
																					<label htmlFor={"frmSaleItmGstRate_"+count}>GST Rate</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="number" className="form-control" id="frmSaleItmIgst_1" placeholder="Enter IGST" value={(input_items[count] && input_items[count]['igst_amt']) ? input_items[count]['igst_amt'] : ''} readOnly/>
																					<label htmlFor="frmSaleItmIgst_1">IGST Amount</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="number" className="form-control" id={'frmSaleItmCgst_'+count} placeholder="Enter CGST" value={(input_items[count] && input_items[count]['cgst_amt']) ? input_items[count]['cgst_amt'] : ''} readOnly/>
																					<label htmlFor={'frmSaleItmCgst_'+count}>CGST Amount</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="number" className="form-control" id="frmSaleItmSgst_1" placeholder="Enter SGST" value={(input_items[count] && input_items[count]['sgst_amt']) ? input_items[count]['sgst_amt'] : ''} readOnly/>
																					<label htmlFor="frmSaleItmSgst_1">SGST Amount</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleItmTotPrice_1" placeholder="Enter Total" value={(input_items[count] && input_items[count]['item_tot_price']) ? input_items[count]['item_tot_price'] : ''} readOnly={ (input_items[count])?false:true }  onChange={(e) => handleItemPrice(Common.acceptOnlyDecimal(e.target.value),count,'item_tot_price', docGstRate)}/>
																					<label htmlFor="frmSaleItmTotPrice_1">Total Price</label>
																				</div>
																			</div>
																		</div>
																		))}
																		<div className="text-center"><button type="button" onClick={addAnotherItem} className="pull-right btn btn-sm btn-primary"><i className='bx bx-plus'></i> Add Item</button></div>
																	</div>
																</div>
															</div>
															</>}
															{invRemarksDiv && <>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingFive">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionInvRemarks" aria-expanded="false" aria-controls="accordionInvRemarks">
																		Invoice Terms
																	</button>
																</h2>
																<div id="accordionInvRemarks" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="nav-align-top mb-4">
																			<ul className="nav nav-tabs nav-fill" role="tablist">
																			  <li className="nav-item">
																				<button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-declaration" aria-controls="navs-declaration" aria-selected="true">
																				  Declaration <Common.OverlayTrigger overlay={Common.popoverContent('','Declaration will be added to the left side of the Authorised Signatory section.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
																				</button>
																			  </li>
																			  <li className="nav-item">
																				<button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-tnc" aria-controls="navs-tnc" aria-selected="false">
																				  Terms and Conditions <Common.OverlayTrigger overlay={Common.popoverContent('','Terms and condition will be added at the end of the invoice.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
																				</button>
																			  </li>
																			  <li className="nav-item">
																				<button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-licence" aria-controls="navs-licence" aria-selected="false">
																				  Additional Licence <Common.OverlayTrigger overlay={Common.popoverContent('','Additonal licence will be added in Bill From (Seller) details.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
																				</button>
																			  </li>
																			</ul>
																			<div className="tab-content">
																				<div className="tab-pane fade show active" id="navs-declaration" role="tabpanel">
																					<ReactQuill
																						modules={modules}
																						theme='snow'
																						value={declaration}
																						onChange={setDeclaration}
																						style={{minHeight: '150px'}}
																					/>
																					{/* <textarea rows="5" name="declaration" className="form-control" placeholder="Declaration" aria-label="Declaration" aria-describedby="Declaration" value={declaration} onChange={(e) => setDeclaration(e.target.value)}></textarea> */}
																				</div>
																				<div className="tab-pane fade" id="navs-tnc" role="tabpanel">
																					<ReactQuill
																						modules={modules}
																						theme='snow'
																						value={tnc}
																						onChange={setTnc}
																						style={{minHeight: '150px'}}
																					/>
																					{/* <textarea rows="5" name="tnc" className="form-control" placeholder="Terms and Conditions" aria-label="Terms and Conditions" aria-describedby="Terms and Conditions" value={tnc} onChange={(e) => setTnc(e.target.value)}></textarea> */}
																				</div>
																				<div className="tab-pane fade" id="navs-licence" role="tabpanel">
																					<ReactQuill
																						modules={modules}
																						theme='snow'
																						value={licence}
																						onChange={setLicence}
																						style={{minHeight: '150px'}}
																					/>
																					{/* <textarea rows="5" name="licence" className="form-control" placeholder="Additional Licence" aria-label="Additional Licence" aria-describedby="Additional Licence" value={licence} onChange={(e) => setLicence(e.target.value)}></textarea> */}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															</>}
															{dispDetDiv && <>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingSix">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionDispDet" aria-expanded="false" aria-controls="accordionInvRemarks">
																		Dispatch From Details
																	</button>
																</h2>
																<div id="accordionDispDet" className="accordion-collapse collapse show" aria-labelledby="headingSix" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="dispFrName" type="text" minLength="3" maxLength="100" className="form-control" id="frmSaleDispFrName" placeholder="Enter (3-100) chars" value={dispFrName} onChange={(e)=>setDispFrName(Common.acceptOnlyCharsNumbers(e.target.value))}/>
																					<label htmlFor="frmSaleDispFrName">Company Name <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="dispFrAddr" type="text" minLength="3" maxLength="100" className="form-control" id="frmSaleDispFrAddr" placeholder="Enter (3-100) chars" value={dispFrAddr} onChange={(e)=>setDispFrAddr(e.target.value)}/>
																					<label htmlFor="frmSaleDispFrAddr">Address <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="dispFrPin" type="text" minLength="6" maxLength="6" className="form-control" id="frmSaleDispFrPin" placeholder="Enter 6 chars" value={dispFrPin} onChange={(e)=>populatePinData('dispatch',Common.acceptOnlyNumbers(e.target.value))}/>
																					<label htmlFor="frmSaleDispFrPin">Pincode <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="dispFrLoc" type="text" minLength="3" maxLength="100" className="form-control" id="frmSaleDispFrLoc" placeholder="Enter (3-100) chars" value={dispFrLoc} onChange={(e)=>setDispFrLoc(Common.acceptOnlyCharsNumbers(e.target.value))}/>
																					<label htmlFor="frmSaleDispFrLoc">Location <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton id="frmSaleDispFrSt" labelKey="label" key="value" className="floatingTypeahead" placeholder="Select State"
																						options={state_master}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																						)}
																						selected={dispFrState.length > 0 ? [state_master.find(obj=>{return obj.value == dispFrState[0].value})] : []}
																						onChange={(e)=>{setDispFrState(e)}}
																					/>
																					<label htmlFor="frmSaleDispFrSt">State <span className="text-danger">*</span></label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															</>}
															{shipToDiv && <>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingSeven">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionShipTo" aria-expanded="false" aria-controls="accordionInvRemarks">
																		Ship To Details
																	</button>
																</h2>
																<div id="accordionShipTo" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="shipToGstin" maxLength="15" type="text" className="form-control" id="frmSaleShipToGstin" placeholder="Enter 15 chars" value={shipToGstin} onChange={(e)=>{handleGstinData('shipto',e);setShipToGstin(Common.acceptOnlyCharsNumbers(e.target.value))}}/>
																					<label htmlFor="frmSaleShipToGstin">Company GSTIN</label>
																					<div className="form-text">GSTIN Data will be auto fetched.</div>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="shipToLglName" type="text" minLength="3" maxLength="100" className="form-control" id="frmSaleShipToLglName" placeholder="Enter (3-100) chars" value={shipToLglName} onChange={(e)=>setShipToLglName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmSaleShipToLglName">Legal Name <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="shipToTrdName" type="text" minLength="3" maxLength="100" className="form-control" id="frmSaleShipToTrdName" placeholder="Enter (3-100) chars" value={shipToTrdName} onChange={(e)=>setShipToTrdName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmSaleShipToTrdName">Trade Name</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="shipToAddr" type="text" minLength="3" maxLength="100" className="form-control" id="frmSaleShipToAddr" placeholder="Max 100 chars" value={shipToAddr} onChange={(e)=>setShipToAddr(e.target.value)}/>
																					<label htmlFor="frmSaleShipToAddr">Address <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="shipToPin" type="text" minLength="6" maxLength="6" className="form-control" id="frmSaleShipToPin" placeholder="Enter 6 chars" value={shipToPin} onChange={(e)=>populatePinData('shipto',Common.acceptOnlyNumbers(e.target.value))}/>
																					<label htmlFor="frmSaleShipToPin">Pincode <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="shipToLoc" type="text" minLength="3" maxLength="100" className="form-control" id="frmSaleShipToLoc" placeholder="Enter (3-100) chars" value={shipToLoc} onChange={(e)=>setShipToLoc(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmSaleShipToLoc">Location <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton id="frmSaleShipToSt" labelKey="label" key="value" className="floatingTypeahead" placeholder="Select State"
																						options={state_master}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																						)}
																						selected={shipToState.length > 0 ? [state_master.find(obj=>{return obj.value == shipToState[0].value})] : []}
																						onChange={(e)=>{setShipToState(e)}}
																					/>
																					<label htmlFor="frmSaleShipToSt">State <span className="text-danger">*</span></label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															</>}
															{expDetDiv && <>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingSeven">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionExpDet" aria-expanded="false" aria-controls="accordionExpDet">
																		Export Details
																	</button>
																</h2>
																<div id="accordionExpDet" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="expShipbNo" type="text" className="form-control" id="frmExpShippingBillNumber" placeholder="Enter Shipping Bill Number" value={expShipbNo} onChange={(e)=>setExpShipbNo(e.target.value)}/>
																					<label htmlFor="frmExpShippingBillNumber">Shipping Bill Number  <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																				<DatePicker name="expShipbDate" data-testid="frmExpShipbDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setExpShipbDate} value={expShipbDate} />
																					<label htmlFor="frmExpShipbDate">Shipping Bill Date  <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																			<div className="form-floating">
																					<AsyncTypeahead selected={expPortSel} clearButton filterBy={filterBy} id='frmExpPortCode' className="floatingTypeahead" isLoading={isExpPortCodeLoading} minLength={3} onSearch={expPortCodeSearch} labelKey="port_name" key="id" placeholder="Search (min 3 chars)" onChange={(e) => setPortCodeSelect(e)}
																						options={portCodeMaster}
																						renderMenuItemChildren={(option: Item,props) => (
																							<div>
																								<Highlighter search={props.text}>
																									{option.port_name}
																								</Highlighter>
																								<div>
																									<small>Port Code: {option.port_code}</small>
																								</div>
																							</div>
																						)}
																					/>
																					<label htmlFor="frmExpPortCode">Port Code <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																			<label htmlFor="frmSaleRevChrg">Supplier for Refund <span className="text-danger">*</span></label>
																				<div className="col">
																					<div className="form-check form-check-inline">
																						<input className="form-check-input" type="radio" name="expRefClm" id="expRefYes" value="Y" onChange={(e) => setExpRefClm(e.target.value)} checked={expRefClm === "Y"}/>
																						<label className="form-check-label" htmlFor="expRefYes">Yes</label>
																					</div>
																					<div className="form-check form-check-inline">
																						<input className="form-check-input" type="radio" name="expRefClm" id="expRefNo" value="N" onChange={(e) => setExpRefClm(e.target.value)} checked={expRefClm === "N"}/>
																						<label className="form-check-label" htmlFor="expRefNo">No</label>
																					</div>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<AsyncTypeahead selected={expCurrencySel} clearButton filterBy={filterBy} id='frmExpForCur' className="floatingTypeahead" isLoading={isExpForCurLoading} minLength={3} onSearch={expForCurSearch} labelKey="currency_name" key="id" placeholder="Search (min 3 chars)" onChange={(e) => setForCurSelect(e)}
																						options={currencyMaster}
																						renderMenuItemChildren={(option: Item,props) => (
																							<div>
																								<Highlighter search={props.text}>
																									{option.currency_name}
																								</Highlighter>
																								<div>
																									<small>Currency Code: {option.currency_code}</small>
																								</div>
																							</div>
																						)}
																					/>
																					<label htmlFor="frmExpForCur">Foreign Currency Code <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																			<div className="form-floating">
																				<AsyncTypeahead selected={expCountrySel} clearButton filterBy={filterBy} id='frmExpCountryCode' className="floatingTypeahead" isLoading={isExpCountryCodeLoading} minLength={3} onSearch={expCountryCodeSearch} labelKey="country_name" key="id" placeholder="Search (min 3 chars)" onChange={(e) => setCountryCodeSelect(e)}
																					options={countryCodeMaster}
																					renderMenuItemChildren={(option: Item,props) => (
																						<div>
																							<Highlighter search={props.text}>
																								{option.country_name}
																							</Highlighter>
																							<div>
																								<small>Country Code: {option.country_code}</small>
																							</div>
																						</div>
																					)}
																				/>
																				<label htmlFor="frmExpCountryCode">Country Code <span className="text-danger">*</span></label>
																			</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="expDutyAmt" type="text" className="form-control" id="frmExpDutyAmt" placeholder="Enter Export Duty Amount" value={expDutyAmt} onChange={(e)=>setExpDutyAmt(Common.acceptOnlyDecimal(e.target.value))}/>
																					<label htmlFor="frmExpDutyAmt">Export Duty Amount <span className="text-danger">*</span></label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															</>}
															{customInvFields && <>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingSeven">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#customInvFields" aria-expanded="false" aria-controls="accordioncustomInvFields">
																		Custom Invoice Fields
																	</button>
																</h2>
																<div id="customInvFields" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			{customInputList}
																			<div className="mb-3 text-center">
																				<button type="button" className="btn btn-sm btn-primary me-2" onClick={handleCustomInvFields}><i className='bx bx-plus'></i> Add Fields</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															</>}
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingThreeA">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionInvoiceVal" aria-expanded="false" aria-controls="accordionInvoice" disabled>
																		Document Value
																	</button>
																</h2>
																<div id="accordionInvoiceVal" className="accordion-collapse collapse show" aria-labelledby="headingThreeA" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleDocTaxableAmount" value={doc_taxable_amt} readOnly/>
																					<label htmlFor="frmSaleDocTaxableAmount">Total Base Value</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleDocGstAmount" value={doc_gst_amt} readOnly/>
																					<label htmlFor="frmSaleDocGstAmount">GST Value</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleDocTotalAmount" value={doc_total_amt} readOnly/>
																					<label htmlFor="frmSaleDocTotalAmount">Total Value</label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col">
																				<div className="divider">
																					<div className="divider-text"><i className={'bx bx-chevrons-'+ (docTaxAddiDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showDocTaxAdditional}>{docTaxAddiDiv ? 'Hide':'Show'} additional fields</Common.Link><i className={'bx bx-chevrons-'+ (docTaxAddiDiv ? "up":"down")+' bx-fade-up'}></i></div>
																				</div>
																			</div>
																		</div>
																		{docTaxAddiDiv && <>
																		{docGstRate.map((vals,inds) => (
																			<div key={'main_gst'+inds}>
																			{Object.keys(vals).map((key5, index5) => {
																				return (
																					<div className="row" key={'gst'+inds}>
																						<div className="col-md-4 col-sm-6 mb-3">
																							<div className="row">
																								<div className="col-md-8 col-sm-6 mb-3">
																									<div className="form-floating">
																										<input type="text" className="form-control" id="frmSaleDocIgst" value={vals[key5].igst_amt} readOnly/>
																										<label htmlFor="frmSaleDocIgst">IGST</label>
																									</div>
																								</div>
																								<div className="col-md-4 col-sm-6 mb-3 ms-auto mt-auto mb-0 text-end ps-0">
																									<span>@{(key5!=='0.00')?key5:0}%</span>
																								</div>
																							</div>
																						</div>

																						<div className="col-md-4 col-sm-6 mb-3">
																							<div className="row">
																								<div className="col-md-8 col-sm-6 mb-3">
																									<div className="form-floating">
																										<input type="text" className="form-control" id="frmSaleDocCgst" value={vals[key5].cgst_amt} readOnly/>
																										<label htmlFor="frmSaleDocCgst">CGST</label>
																									</div>
																								</div>
																								<div className="col-md-4 col-sm-6 mb-3 ms-auto mt-auto mb-0 text-end ps-0">
																									<span>@{(key5!=='0.00')?(key5 / 2).toFixed(2):0 }%</span>
																								</div>
																							</div>
																						</div>


																						<div className="col-md-4 col-sm-6 mb-3">
																							<div className="row">
																								<div className="col-md-8 col-sm-6 mb-3">
																									<div className="form-floating">
																										<input type="text" className="form-control" id="frmSaleDocSgst" value={vals[key5].sgst_amt} readOnly/>
																										<label htmlFor="frmSaleDocSgst">SGST</label>
																									</div>
																								</div>
																								<div className="col-md-4 col-sm-6 mb-3 ms-auto mt-auto mb-0 text-end ps-0">
																									<span>@{(key5!=='0.00')?(key5 / 2).toFixed(2):0 }%</span>
																								</div>
																							</div>
																						</div>
																					</div>
																				);
																			})}
																			</div>
																		))}
																		<div className="row">
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleDocDiscount" value={docDiscount} placeholder="0.00" onChange={(e) => { setDocDiscount(Common.acceptOnlyDecimal(e.target.value));finalDocTotalCal(docOtherCharge,docRoundoff,Common.acceptOnlyDecimal(e.target.value)); }} />
																					<label htmlFor="frmSaleDocDiscount">Addl Discount after GST</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleDocOtherCharge" value={docOtherCharge} placeholder="0.00" onChange={(e) => { setDocOtherCharge(Common.acceptOnlyDecimal(e.target.value));finalDocTotalCal(Common.acceptOnlyDecimal(e.target.value),docRoundoff,docDiscount); }} />
																					<label htmlFor="frmSaleDocOtherCharge">Other Charge</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="number" className="form-control" id="frmSaleDocRoundoff" value={docRoundoff} placeholder="0.00" onChange={(e) => { handleDocRoundoff(e.target.value); }} />
																					<label htmlFor="frmSaleDocRoundoff">Round off</label>
																				</div>
																			</div>
																		</div>
																		</>}
																	</div>
																</div>
															</div>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingThreeA">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionInvoiceVal" aria-expanded="false" aria-controls="accordionInvoice" disabled>
																		Others
																	</button>
																</h2>
																<div id="accordionInvoiceVal" className="accordion-collapse collapse show" aria-labelledby="headingThreeA" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-4 col-sm-4 mb-3">
																				<div className="form-floating">
																					<Typeahead selected={acentreSel} clearButton allowNew newSelectionPrefix="Add: " id="frmSaleAllocCentre" labelKey="name" key="id" className="floatingTypeahead" placeholder="Select Allocation Centre"
																						options={allocation_centre_master}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.name}
																							</Highlighter>
																						)}
																						onChange={(e)=>{setAllocationCentreSelect(e)}}
																					/>
																					<label htmlFor="frmSaleAllocCentre">Allocation Centre</label>
																					<div className="form-text" style={{'fontSize':'60%'}}>Use this field to maintain custom cost/profit centers</div>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-4 mb-3">
																				<div className="form-floating">
																					<Typeahead selected={incomeTypeSel} clearButton id="frmSaleIncomeType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setIncomeTypeSelect(e)} options={income_type_master} placeholder="Choose account head"
																						renderMenuItemChildren={(option: Item,props) => (
																							<>
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																							<div>
																								<small>{option.description}</small>
																							</div>
																							</>
																						)}
																					/>
																					<label htmlFor="frmSaleIncomeType">Income Type<span className="text-danger">*</span></label>
																				</div>
																			</div>

																			<div className="col-md-4 col-sm-4 mb-3">
																				<div className="form-floating">
																					<Typeahead selected={incomeSubTypeSel} allowNew newSelectionPrefix="Add: " disabled={isAccountHeadSelected === false ? true : false} clearButton id="frmSaleIncomeSubType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setIncomeSubTypeSelect(e)} options={income_subtype_master} placeholder="Choose Income SubType"
																						renderMenuItemChildren={(option: Item,props) => (
																							<>
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																							<div>
																								<small>{option.label}</small>
																							</div>
																							</>
																						)}
																					/>
																					<label htmlFor="frmSaleIncomeType">Income Sub Type</label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-sm-3">
														<div className="list-group">
															<label className="list-group-item">
																<input className="form-check-input me-1" type="checkbox" checked={itemDiv === true} onChange={toggleItemDiv}/>
																Item Data
															</label>
															<label className="list-group-item">
																<input className="form-check-input me-1" type="checkbox" checked={invRemarksDiv === true} onChange={toggeInvRemarksDiv}/>
																Invoice Terms
															</label>
															<label className="list-group-item">
																<input className="form-check-input me-1" type="checkbox" checked={dispDetDiv === true} onChange={toggeDispDetDiv}/>
																Dispatch From Details
															</label>
															<label className="list-group-item">
																<input className="form-check-input me-1" type="checkbox" checked={shipToDiv === true} onChange={toggeShipToDiv}/>
																Ship To Details
															</label>
															<label className="list-group-item">
																<input className="form-check-input me-1" type="checkbox" checked={expDetDiv === true} onChange={toggeExpDetDiv}/>
																Export Details
															</label>
															<label className="list-group-item">
																<input className="form-check-input me-1" type="checkbox" checked={customInvFields === true} onChange={toggeCustomInvFields}/>
																Custom Invoice Fields
															</label>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col">
														<div className="divider">
															<div className="divider-text">Invoice Summary</div>
														</div>
													</div>
												</div>
												<div id="summaryDiv" className="row justify-content-md-center">
													<div className="col-md-8" id="summaryDivL">
													{ doc_type.length > 0 && (doc_type[0].value === 'INV') && <>
														<div className="card">
															<h6 className="card-header">Payments</h6>
															{payments.length === 0 && <div className="text-center py-1">No payments done</div>}
															{payments.length > 0 && <>
															<div className="table-responsive text-nowrap">
																<table className="table table-sm table-bordered">
																	<thead className="table-light">
																		<tr>
																			<th align="center">Action</th>
																			<th>Sl.</th>
																			<th align="right">Received</th>
																			<th>Date</th>
																			<th>Mode</th>
																			<th>Total Deduction</th>
																			<th>Remarks</th>
																			<th>Transaction Ref.</th>
																		</tr>
																	</thead>
																	<tbody className="table-border-bottom-0">
																		{payments.map((val,index) => (
																			<tr key={'pay'+index}>
																				<td align="center">
																					<button type="button" className="btn btn-sm" onClick={() => removePayment(index)}><i className='bx bx-trash'></i></button>
																				</td>
																				<td>{index+1}</td>
																				<td align="right">{Common.displayPrice(val.received)}</td>
																				<td>
																					<Moment format={Common.date_format}>{val.payment_date}</Moment>
																				</td>
																				<td>{val.payment_mode}</td>
																				<td align="right">
																					{(val.deductions.reduce(function(prev, current) {
																						return prev + +current.deduction_amt
																					}, 0) > 0) ? <>
																						<Button variant="" size="sm" className="border-0 bg-white text-dark text-decoration-underline" onClick={() => deductionModal(val.deductions)}>
																						{Common.displayPrice(val.deductions.reduce(function(prev, current) {
																							return prev + +current.deduction_amt
																						}, 0))}
																						</Button>
																					</> : '-'}
																				</td>
																				<td>{val.payment_remarks}</td>
																				<td>{val.trans_ref}</td>
																			</tr>
																		))}
																	</tbody>
																</table>
															</div>
															</>}
														</div>
														<div className="card">
															<h6 className="card-header">Advance adjust</h6>
															{advanceAdjust.length === 0 && <div className="text-center py-1">No advance adjust done</div>}
															{advanceAdjust.length > 0 && <>
															<div className="table-responsive text-nowrap">
																<table className="table table-sm table-bordered">
																	<thead className="table-light">
																		<tr>
																			<th align="center">Action</th>
																			<th>Sl.</th>
																			<th align="right">Advance Adjust</th>
																			<th>Remarks</th>
																		</tr>
																	</thead>
																	<tbody className="table-border-bottom-0">
																		{advanceAdjust.map((val,index) => (
																			<tr key={'advance'+index}>
																				<td align="center">
																					<button type="button" className="btn btn-sm" onClick={() => removeAdvanceAdjust(index)}><i className='bx bx-trash'></i></button>
																				</td>
																				<td>{index+1}</td>
																				<td align="right">{Common.displayPrice(val.adjustedAmount)}</td>
																				<td>{val.advanceAdjustedRemarks}</td>
																			</tr>
																		))}
																	</tbody>
																</table>
															</div>
															</>}
														</div>
													</>}
													</div>
													<div className="col-md-4" id="summaryDivR">
													{ doc_type.length > 0 && (doc_type[0].value === 'INV') && <>
														<div className="card">
															<div className="row">
																<label className="col-4 col-form-label" htmlFor="frmSaleTotal">Total <span className="text-danger">*</span></label>
																<div className="col-8">
																	<div className="input-group input-group-merge">
																		<span className={"input-group-text "+(total_amt_disabled?'disabled-bg':'')}><i className='bx bx-rupee'></i></span>
																		<input type="text" name="total_amt" className="form-control" id="frmSaleTotal" placeholder="0.00" value={total_amt>0?total_amt:refPaymentDue} onChange={(e) => { setTotalAmt(Common.acceptOnlyDecimal(e.target.value));summaryTotalCal(Common.acceptOnlyDecimal(e.target.value),total_payment,total_deduction); }} readOnly={total_amt_disabled} required/>
																	</div>
																</div>
																<div className="col-12">
																	{ total_amt > 0 && <>
																	<div className="text-right mt-1">
																	{ advanceBalance > 0 && <>
																		<Button variant="info" size="sm" onClick={()=>{advanceModal('show');}}><i className='bx bx-plus' ></i> Advance adjust</Button>&nbsp;&nbsp;
																	</>}
																		<Button variant="success" size="sm" onClick={()=>{paymentModal('show'); setReceived(due);}}><i className='bx bx-plus' ></i> Payment</Button>
																	</div>
																	</>}
																</div>
															</div>
															<div className='row'>
																<div className='col-12'>
																	<Common.Calculator url={props.url} />
																</div>
															</div>
															{showAdvanceDiv && (Math.sign(total_payment) === 1) && (
																<div className="row">
																	<div className="alert alert-warning mb-0 py-1 mt-1">
																		<label className="text-danger mb-0"><small><u>Note</u>: Amount collected is greater than due amount. Balance <b>{Common.displayPrice(advance_amount)}</b> will be added automatically to advance paid <Common.OverlayTrigger overlay={Common.popoverContent('','Last payment mode, payment date, payment remarks and transaction number will be considered for this advance amount.')}><i className='bx bxs-info-circle' ></i></Common.OverlayTrigger></small></label>
																		<input type="hidden" name="advance_amount" value={advance_amount} />
																	</div>
																</div>
															)}
															<div className="row mt-3">
																<label className="col-4 col-form-label" htmlFor="frmSalePayments">Total Payment</label>
																<div className="col-8">
																	<div className="input-group input-group-merge">
																		<span className="input-group-text disabled-bg"><i className='bx bx-rupee'></i></span>
																		<input type="text" className="form-control" id="frmSalePayments" placeholder="0.00" value={total_payment} readOnly/>
																	</div>
																</div>
															</div>
															<div className="row mt-3">
																<label className="col-4 col-form-label" htmlFor="frmSaleDeductions">Total Deduction</label>
																<div className="col-8">
																	<div className="input-group input-group-merge">
																		<span className="input-group-text disabled-bg"><i className='bx bx-rupee'></i></span>
																		<input type="text" className="form-control" id="frmSaleDeductions" placeholder="0.00" value={total_deduction} readOnly/>
																	</div>
																</div>
															</div>
															<div className="row mt-3">
																<label className="col-4 col-form-label" htmlFor="frmSaleDue">Due</label>
																<div className="col-8">
																	<div className="input-group input-group-merge">
																		<span className="input-group-text disabled-bg"><i className='bx bx-rupee'></i></span>
																		<input type="text" name="due" className="form-control" id="frmSaleDue" placeholder="0.00" value={due} readOnly/>
																	</div>
																</div>
															</div>
															{due > 0 ? <>
																<div className="row mt-3">
																	<label className="col-4 col-form-label" htmlFor="frmSaleDue">Due Date</label>
																	<div className="col-8">
																		<div className="input-group input-group-merge">
																			{/* <span className="input-group-text disabled-bg"><i className='bx bx-rupee'></i></span> */}
																			<DatePicker name="due_date" data-testid="frmDueDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setDueDate} value={dueDate} />
																		</div>
																	</div>
																</div>
															</> : <></>}
														</div>
														</>}
														{ doc_type.length > 0 && (doc_type[0].value === 'CRN' || doc_type[0].value === 'DBN') && <>
														<div className="card">
															<div className="row">
																<label className="col-4 col-form-label" htmlFor="frmSaleDue">Ref inv total value</label>
																<div className="col-8">
																	<div className="input-group input-group-merge">
																		<span className="input-group-text disabled-bg"><i className='bx bx-rupee'></i></span>
																		<input type="text" name="due" className="form-control" id="frmSaleDue" placeholder="0.00" value={refTotalValue} readOnly/>
																	</div>
																</div>
															</div>
															<div className="row mt-3">
																<label className="col-4 col-form-label" htmlFor="frmSalePayments">Payment due</label>
																<div className="col-8">
																	<div className="input-group input-group-merge">
																		<span className="input-group-text disabled-bg"><i className='bx bx-rupee'></i></span>
																		<input type="text" className="form-control" id="frmSalePayments" placeholder="0.00" value={refPaymentDue} readOnly/>
																	</div>
																</div>
															</div>
															<div className="row mt-3">
																<label className="col-4 col-form-label" htmlFor="frmSaleTotal">Total <span className="text-danger">*</span></label>
																<div className="col-8">
																	<div className="input-group input-group-merge">
																		<span className={"input-group-text "+(total_amt_disabled?'disabled-bg':'')}><i className='bx bx-rupee'></i></span>
																		<input type="text" name="total_amt" className="form-control" id="frmSaleTotal" placeholder="0.00" value={total_amt} onChange={(e) => { setTotalAmt(Common.acceptOnlyDecimal(e.target.value));summaryTotalCal(Common.acceptOnlyDecimal(e.target.value),total_payment,total_deduction); }} readOnly={total_amt_disabled} required/>
																	</div>
																</div>
															</div>
														</div>
													</>}
													</div>
												</div>
												<div className="row mt-5">
													<div className="text-center">
													{ submitType === '0' && <>
														<a href="/sales-listing" role="button" className="me-3 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
														<button type="button" onClick={(e)=>{copyWithInvCheck(copyType,'1');}} className="btn btn-sm btn-secondary me-3" ><i className='bx bxs-edit'></i>&nbsp;Save Draft</button>
														<button type="submit" onClick={(e)=>{setSubmitType('0');setSaveDraftInvoice('1')}} className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Save Invoice</button>
													</>}
													{ submitType === '1' && <>
														<button type="button" className="me-3 btn btn-sm btn-info" disabled><i className='bx bx-arrow-back' disabled></i> Back</button>
														<button type="button" onClick={(e)=>{copyWithInvCheck(copyType,'0');}} className="btn btn-sm btn-danger me-3" ><i className='bx bx-message-square-x'></i>&nbsp;Save Draft</button>
														<button type="submit" onClick={(e)=>setSubmitType('0')} className="btn btn-sm btn-primary" disabled><span className="tf-icons bx bx-save"></span>&nbsp;Save Invoice</button>
														<div className="mt-3">
															<button type="submit" className='btn btn-sm btn-success' onClick={(e)=>setSaveDraftInvoice('1')}>Save with Invoice Number</button>&nbsp;&nbsp;
															<button type="submit" className='btn btn-sm btn-primary' onClick={(e)=>setSaveDraftInvoice('0')}>Save without Invoice Number</button>
														</div>
													</>}
													</div>
												</div>
											</form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal size={ deduction_div ? 'xl':'md'} show={payModalShow} onHide={()=>paymentModal('hide')} aria-labelledby="payment-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<div className="col-4">
					<Modal.Title id="payment-modal">Payment {payments.length + 1}</Modal.Title>
				</div>
				<div className="col text-right">
					<span id="payModalInfo" className="badge bg-warning">
						Total Invoice Value: {Common.displayPrice(total_amt)}
						{/* { deduction_div && <>
						, Total Deduction added: {Common.displayPrice(Number(total_deduction))}
						</>} */}
					</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className={deduction_div ? 'col-sm-6':''}>
						<div className="row">
							<label className="col-4 col-form-label" htmlFor="frmSaleTotal">Amount <span className="text-danger">*</span></label>
							<div className="col-8">
								<div className="input-group input-group-merge">
									<span className="input-group-text"><i className='bx bx-rupee'></i></span>
									<input ref={paymentReceivedRef} type="text" className="form-control" id="frmSaleTotal" placeholder="0.00" value={received}
										onChange={(e) => {
											setReceived(Common.acceptOnlyDecimal(e.target.value));
											setDeductionPer('');
											setDeductionAmt('');
										}}
									autoComplete="off"/>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmSalePayDate">Payment Date</label>
							<div className="col-8">
								<DatePicker data-testid="frmSalePayDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setPaymentDate} value={payment_date} />
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmSalePayMode">Payment Mode</label>
							<div className="col-8">
								<Typeahead clearButton defaultSelected={paymentModes.slice(1, 2)} id="frmSalePayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
									options={paymentModes}
									renderMenuItemChildren={(option: Item,props) => (
										<Highlighter search={props.text}>
											{option.label}
										</Highlighter>
									)}
								/>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmBankAccount">Bank Account <span className="text-danger">*</span></label>
							<div className="col-8">
								<Typeahead selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
									options={bankList}
									renderMenuItemChildren={(option: Item,props) => (
										<Highlighter search={props.text}>
											{option.label}
										</Highlighter>
									)}
								/>
								<small>To add additional bank account, Go to Account management - <a href='/bank-accounts'>Bank accounts</a></small>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmSalePayRemarks">Payment Remarks</label>
							<div className="col-8">
								<input type="text" className="form-control" id="frmSalePayRemarks" placeholder="Payment Remarks" value={payment_remarks} onChange={(e) => setPaymentRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmSaleTransRef">Transaction Ref. No.</label>
							<div className="col-8">
								<input type="text" maxLength="100" className="form-control" id="frmSaleTransRef" placeholder="Transaction Ref." value={trans_ref} onChange={(e) => setTransRef(e.target.value)} autoComplete="off"/>
							</div>
						</div>
					</div>
					<div className={deduction_div ? 'col-sm-6':'d-none'}>
						{/*<div className="form-text text-right text-info">
							Total Invoice Value: {Common.displayPrice(total_amt)}, Total Deduction added: {Common.displayPrice(Number(total_deduction))}
						</div>*/}
						<div className="card">
							<h6 className="card-header">Deductions</h6>
							{deductions.length === 0 && <div className="text-center py-1">No deduction</div>}
							{deductions.length > 0 && <>
							<div className="table-responsive text-nowrap">
								<table className="table table-sm table-bordered">
									<thead className="table-light">
										<tr>
											<th align="center">Action</th>
											<th>Sl.</th>
											<th align="right">Deduction (%)</th>
											<th align="right">Deduction (<i className='bx bx-rupee' ></i>)</th>
											<th>Deduction Type</th>
											<th>Deduction Subtype</th>
											{/* <th>Remarks</th> */}
										</tr>
									</thead>
									<tbody className="table-border-bottom-0">
										{deductions.map((val,index) => (
											<tr key={'ded'+index}>
												<td align="center">
													<button type="button" className="btn btn-sm" onClick={() => removeDeduction(index)}><i className='bx bx-trash'></i></button>
												</td>
												<td>{index+1}</td>
												<td align="right">{Common.displayPercent(val.deduction_per)}%</td>
												<td align="right">{Common.displayPrice(val.deduction_amt)}</td>
												<td>{val.deduction_type}</td>
												<td>{val.deduction_subtype}</td>
												{/* <td>{val.deduction_remarks}</td> */}
											</tr>
										))}
									</tbody>
								</table>
							</div>
							</>}
						</div>
						<div className="alert alert-secondary mt-3">
							<div className="row">
								<h4>Add Deduction</h4>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="row">
										<label className="col-sm-5 col-form-label" htmlFor="frmSaleDedPer">Percent <span className="text-danger">*</span></label>
										<div className="col-sm-7">
											<div className="input-group input-group-merge">
												<span className="input-group-text"><i className='bx bxs-offer'></i></span>
												<input type="text" className="form-control" id="frmSaleDedPer" placeholder="0.00" value={deduction_per} onFocus={checkPayment} onChange={(e) => calcDeduction('per',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
											</div>
										</div>
									</div>
									<div className="row text-right">
										<small>(Deduction at source and on base value)</small>
									</div>
								</div>
								<div className="col-6">
									<div className="row">
										<label className="col-sm-4 col-form-label" htmlFor="frmSaleDedAmt">Amount <span className="text-danger">*</span></label>
										<div className="col-sm-8">
											<div className="input-group input-group-merge">
												<span className="input-group-text"><i className='bx bx-rupee'></i></span>
												<input type="text" className="form-control" id="frmSaleDedAmt" placeholder="0.00" value={deduction_amt} onFocus={checkPayment} onChange={(e) => calcDeduction('amt',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-4 col-form-label" htmlFor="frmSaleDedType">Deduction Type <span className="text-danger">*</span></label>
								<div className="col-8">
									<Typeahead clearButton ref={frmSaleDedType} id="frmSaleDedType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDeductionTypeSelect(e)} placeholder="Choose Deduction Type"
										options={deductionMasterList}
										renderMenuItemChildren={(option: Item,props) => (
											<Highlighter search={props.text}>
												{option.label}
											</Highlighter>
										)}
									/>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-4 col-form-label" htmlFor="frmSaleDedRemarks">Deduction Subtype</label>
								<div className="col-8">
									{/* <input type="text" className="form-control" id="frmSaleDedRemarks" placeholder="Deduction Remarks" value={deduction_remarks} onChange={(e) => setDeductionRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/> */}
									<Typeahead clearButton id="frmSaleDedSubtype" allowNew newSelectionPrefix="Add: " ref={frmSaleDedSubtype} disabled={isDeductionMasterSelected === false ? true : false} labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDeductionSubTypeSelect(e)} placeholder="Choose Deduction Sub Type"
										options={deductionSubtypeMasterList}
										renderMenuItemChildren={(option: Item,props) => (
											<Highlighter search={props.text}>
												{option.label}
											</Highlighter>
										)}
									/>
								</div>
							</div>
							<div className="text-center mt-3">
								<Button variant="primary" size="sm" className="ms-1" onClick={addDeduction}><span className="tf-icons bx bx-save"></span>&nbsp;Add</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="text-center mt-3">
					<Button size="sm" onClick={()=>{setDeductionDiv(!deduction_div);setDeductionAmt('');setDeductionPer('');frmSaleDedType.current.clear();frmSaleDedSubtype.current.clear()}} variant={(deduction_div ? 'danger':'success')}>{deduction_div ? <><i className='bx bx-minus'></i></> : <><i className='bx bx-plus'></i></>} Deduction</Button>
					{bank_cash_account === 1 && (<>
						<Button variant="primary" size="sm" className="ms-1" onClick={addPayment}><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
					</>)}
				</div>
			</Modal.Body>
		</Modal>
		<Modal show={dedModalShow} onHide={()=>setDedModalShow(false)} aria-labelledby="deduction-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="deduction-modal">Deductions</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					{temp_deduction !== undefined && temp_deduction.length === 0 && <div className="text-center py-1">No deduction</div>}
					{temp_deduction !== undefined && temp_deduction.length > 0 && <>
					<div className="table-responsive text-nowrap">
						<table className="table table-sm table-bordered">
							<thead className="table-light">
								<tr>
									<th>Sl.</th>
									<th align="right">Deduction (%)</th>
									<th align="right">Deduction (<i className='bx bx-rupee' ></i>)</th>
									<th>Deduction Type</th>
									<th>Deduction Subtype</th>
									{/* <th>Remarks</th> */}
								</tr>
							</thead>
							<tbody className="table-border-bottom-0">
								{temp_deduction.map((val,index) => (
									<tr key={'ded1'+index}>
										<td>{index+1}</td>
										<td align="right">{Common.displayPercent(val.deduction_per)}%</td>
										<td align="right">{Common.displayPrice(val.deduction_amt)}</td>
										<td>{val.deduction_type}</td>
										<td>{val.deduction_subtype}</td>
										{/* <td>{val.deduction_remarks}</td> */}
									</tr>
								))}
							</tbody>
						</table>
					</div>
					</>}
				</div>
			</Modal.Body>
		</Modal>
		<Modal size='xl' show={quickItemModalShow} onHide={()=>setquickItemModalShow(false)} aria-labelledby="quick-item-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="quick-item-modal">Item</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addItmFrm" onSubmit={handleItem} autoComplete="off">
					<div className="row">
						<div className="col-sm-6">
							<div className="row">
								<div className="mb-3 col">
									<label htmlFor="item_name" className="form-label">Item Name <span className="text-danger">*</span></label>
									<input autoFocus type="text" className="form-control" id="item_name" name="item_name" placeholder="E.g., Mobile" value={itemName} onChange={(e) => handleAddiFields(e.target.value)} required/>
									<div className="form-text">
										Required: Minimum 3 characters
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="row">
								<div className="mb-3 col">
									<label className="form-label">Please Select One <span className="text-danger">*</span></label><br></br>
									<div className="form-check form-check-inline">
										<input id="product_type" name="service_type" className="form-check-input" type="radio" value="0"  required checked={itemServiceType === '0'} onChange={(e) => getItemServiceType(e.target.value)} />
										<label className="form-check-label" htmlFor="product_type"> Product </label>
									</div>
									<div className="form-check form-check-inline">
										<input id="service_type" name="service_type" className="form-check-input" type="radio" value="1" required  checked={itemServiceType === '1'} onChange={(e) => getItemServiceType(e.target.value)} />
										<label className="form-check-label" htmlFor="service_type"> Service </label>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6">
						{itemAdditional && itemServiceType !== "" && <>
							<div className="row">
								<div className="mb-3 col">
								{itemServiceType === "0" && <>
									<label htmlFor="item_unit" className="form-label">Item Unit <span className="text-danger">*</span></label>
									<Typeahead clearButton id="item_unit" key="id" className="floatingTypeahead" onChange={(e) => setItemUnit(e[0] ? e[0].id : '')} placeholder="Select Unit"
										options={units}
										labelKey={option => `${option.description} ( ${option.code} )`}
										renderMenuItemChildren={(option: Item,props) => (
											<Highlighter search={props.text}>
												{option.description +' ( '+ option.code+' )'}
											</Highlighter>
										)}
										defaultSelected={[units.find(obj=>{return obj.code === 'NOS'})]}
									/>
								</>}
								{itemServiceType === "1" && <>
									<label htmlFor="item_unit" className="form-label">Item Unit <span className="text-danger">*</span></label>
									<Typeahead disabled id="item_unit" key="id" className="floatingTypeahead"
										options={units}
										labelKey={option => `${option.description} ( ${option.code} )`}
										renderMenuItemChildren={(option: Item,props) => (
											<Highlighter search={props.text}>
												{option.description +' ( '+ option.code+' )'}
											</Highlighter>
										)}
										defaultSelected={[units.find(obj=>{return obj.code === 'OTH'})]}
									/>
								</>}
								</div>
								<div className="mb-3 col">
									<label htmlFor="item_hsn" className="form-label">HSN Code</label>
									<div className="input-group input-group-merge">
										{itemServiceType === "1" && <>
											<div className="input-group-prepend">
												<div className="input-group-text border-start rounded-start">99</div>
											</div>
										</>}
										<input type="text" className="form-control" id="item_hsn" name="item_hsn" placeholder={(itemServiceType === '1') ? "E.g., 99094112" : "E.g., 99882112"} value={itemHSN} maxLength={(itemServiceType === '1') ? '6' : '8'} onChange={(e) => setItemHSN(e.target.value)} />
									</div>
								</div>
							</div>
							{itemServiceType !== "1" && <>
							<div className="row">
								{/* <div className="mb-3 col">
									<label htmlFor="min_qty" className="form-label">Min Order Qty <span className="text-danger">*</span></label>&nbsp;&nbsp;
									<Common.OverlayTrigger overlay={Common.popoverContent('','The minimum quantity to be ordered')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
									<input type="text" className="form-control" id="min_qty" name="min_qty" placeholder="" value={min_qty} onChange={(e) => setMinQty(Common.acceptOnlyDecimal(e.target.value))} required/>
								</div> */}
								<div className="mb-3 col">
									<label className="pt-4">
										<input className="form-check-input me-1" type="checkbox" value={itemStockMovement} onChange={getItemStockMovement} name='item_stock_movement' checked={itemStockChecked}/> Track Item stock movement
									</label>
								</div>
								{itemStockChecked === true && <>
									<div className="mb-3 col">
										<label className="pt-4">Allow negative balance</label>
										<label className="">
											<input name="default-radio-1" className="form-check-input" type="radio" value={isNegativeBal} id="defaultRadio2" onClick={(e) => {setIsNegativeBal(true); setAllowNegStock(1);} } /> Yes
											<input name="default-radio-1" className="form-check-input" type="radio" value={isNegativeBal} id="defaultRadio2" style={{'marginLeft': '1rem'}} onClick={(e) => {setIsNegativeBal(false); setAllowNegStock(0);}} defaultChecked /> No
										</label>
									</div>
								</>}
							</div>
							</>}
							{/* {itemServiceType !== "1" && <>
							<div className="row">
								<div className="mb-3 col">
									<label htmlFor="opening_stock" className="form-label">Opening Stock Qty</label>
									<div className="input-group input-group-merge">
										<input type="number" className="form-control" id="opening_stock" name="opening_stock" placeholder="" value={opening_stock} onChange={(e) => setOpeningStock(e.target.value)} disabled = {(itemStockMovement === '1') ? false : true} />
									</div>
								</div>
								<div className="mb-3 col">
									<label htmlFor="opening_stock_date" className="form-label">Opening Stock Qty as on Date</label>
									<DatePicker name="opening_stock_date" data-testid="opening_stock_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningStockDt} value={opening_stock_date} disabled = {(itemStockMovement === '1') ? false : true} />
								</div>
							</div>
							</>} */}
							</>}
						</div>
						<div className="col-sm-6">
						{itemAdditional && itemServiceType !== "" && <>
							<div className="row">
								<div className="mb-3 col-md-6">
									<label className="form-label" htmlFor="gst_rate">GST</label>
									<div className="input-group input-group-merge">
										<span className={"input-group-text"}><i className='bx bxs-offer'></i></span>
										<Typeahead clearButton id="gst_rate" key="id" className="floatingTypeahead border-end rounded-end" onChange={(e) => {quickItemCalculation('gst_rate',(e[0] ? e[0].val : ''));setGstRateId(e[0] ? e[0].id : '');purchaseItemCalculation('gst_rate',(e[0] ? e[0].val : ''));} } placeholder="Select GST"
											options={taxes}
											labelKey="rate"
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.rate}
												</Highlighter>
											)}
											defaultSelected={taxes.slice(0,1)}
										/>
									</div>
								</div>
								{itemServiceType !== "1" && <>
									<div className="mb-3 col-md-6">
										<label htmlFor="min_qty" className="form-label">Min Order Qty <span className="text-danger">*</span></label>&nbsp;&nbsp;
										<Common.OverlayTrigger overlay={Common.popoverContent('','The minimum quantity to be ordered')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
										<input type="text" className="form-control" id="min_qty" name="min_qty" placeholder="" value={min_qty} onChange={(e) => setMinQty(Common.acceptOnlyDecimal(e.target.value))} required/>
									</div>
								</>}
							</div>
							{itemStockChecked === true && itemServiceType !== "1" && <>
							<div className="row">
								<div className="mb-3 col">
									<label htmlFor="opening_stock" className="form-label">Opening Stock Qty</label>
									<div className="input-group input-group-merge">
										<input type="number" className="form-control" id="opening_stock" name="opening_stock" placeholder="" value={opening_stock} onChange={(e) => setOpeningStock(e.target.value)} disabled = {(itemStockMovement === '1') ? false : true} />
									</div>
								</div>
								<div className="mb-3 col">
									<label htmlFor="opening_stock_date" className="form-label">Opening Stock Qty as on Date</label>
									<DatePicker name="opening_stock_date" data-testid="opening_stock_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningStockDt} value={opening_stock_date} disabled = {(itemStockMovement === '1') ? false : true} />
								</div>
							</div>
							</>}
							{/* {itemStockChecked === true && itemServiceType !== "1" && <>
							<div className="row">
								<div className="mb-3 col">
									<label htmlFor="opening_stock" className="form-label">Opening Stock Qty</label>
									<div className="input-group input-group-merge">
										<input type="number" className="form-control" id="opening_stock" name="opening_stock" placeholder="" value={opening_stock} onChange={(e) => setOpeningStock(e.target.value)} disabled = {(itemStockMovement === '1') ? false : true} />
									</div>
								</div>
								<div className="mb-3 col">
									<label htmlFor="opening_stock_date" className="form-label">Opening Stock Qty as on Date</label>
									<DatePicker name="opening_stock_date" data-testid="opening_stock_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningStockDt} value={opening_stock_date} disabled = {(itemStockMovement === '1') ? false : true} />
								</div>
							</div>
							</>} */}
							{/* {itemServiceType !== "1" && <>
							<div className="row">
								<div className="mb-3 col">
									<label htmlFor="min_qty" className="form-label">Min Order Qty <span className="text-danger">*</span></label>&nbsp;&nbsp;
									<Common.OverlayTrigger overlay={Common.popoverContent('','The minimum quantity to be ordered')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
									<input type="text" className="form-control" id="min_qty" name="min_qty" placeholder="" value={min_qty} onChange={(e) => setMinQty(Common.acceptOnlyDecimal(e.target.value))} required/>
								</div>
								<div className="mb-3 col">
									<label className="pt-4">
										<input className="form-check-input me-1" type="checkbox" value={itemStockMovement} onChange={getItemStockMovement} name='item_stock_movement' checked={itemStockChecked}/> Track Item stock movement
									</label>
								</div>
							</div>
							</>} */}
							</>}
						</div>
						<div className={"row " + (salePurchasedividerDisplay ? 'd-block':'d-none')}>
							<div className="col">
								<div className="divider">
									<div className="divider-text"><i className={'bx bx-chevrons-'+ (salePurchaseAdditionalItemDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showSalePurchaseAdditionalItemFields}>{salePurchaseAdditionalItemDiv ? 'Hide':'Show'} Sale & Purchase Price fields</Common.Link><i className={'bx bx-chevrons-'+ (salePurchaseAdditionalItemDiv ? "up":"down")+' bx-fade-up'}></i></div>
								</div>
							</div>
						</div>
						{salePurchaseAdditionalItemDiv && itemAdditional && <>
						<div className="col-sm-6 border-end">
							<h6 className='text-center mt-3'>Sale</h6>
							<div className="mb-3 col">
								<label htmlFor="item_price" className="form-label">Item Price / Unit <span className="text-danger">*</span></label>
								<div className="input-group input-group-merge">
									<span className="input-group-text"><i className="bx bx-rupee"></i></span>
									<input type="text" className="form-control" id="item_price" name="item_price" placeholder="0.00"  value={item_price} onChange={(e) => {quickItemCalculation('item_price',Common.acceptOnlyDecimal(e.target.value));setDiscountAmt('');setDiscountPer('');}}  />
								</div>
							</div>
							<div className="mb-3 col">
								<label className="form-label" htmlFor="discount_per">Discount</label>
								<div className="input-group input-group-merge">
									<span className={"input-group-text "+(disPerDisabled ? "disabled-bg" : "")}><i className='bx bxs-offer'></i></span>
									<input type="text" className="form-control" id="discount_per" placeholder="0.00" value={discount_per} onChange={(e) => calcDiscount('per',Common.acceptOnlyDecimal(e.target.value))} disabled={disPerDisabled} />
									<span className={"input-group-text "+(disAmtDisabled ? "disabled-bg" : "")}><i className='bx bx-rupee'></i></span>
									<input type="text" className="form-control" id="discount_amt" placeholder="0.00" value={discount_amt} onChange={(e) => calcDiscount('amt',Common.acceptOnlyDecimal(e.target.value))} disabled={disAmtDisabled} />
								</div>
							</div>
							<div className="mb-3 col">
								<label htmlFor="gross_amt" className="form-label">Gross Amount</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
									<input type="number" className="form-control" id="gross_amt" placeholder="0.00"  min="0" value={gross_amt} readOnly />
								</div>
								<div className="form-text">
									Calculation: Price/Unit - Discount
								</div>
							</div>
							<div className="mb-3 col">
								<label className="form-label" htmlFor="gst_rate">GST Amount</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className='bx bx-rupee'></i></span>
									<input type="text" className="form-control" name="gst_amt" placeholder="0.00" value={gst_amt} readOnly />
								</div>
							</div>
							<div className="mb-3 col">
								<label htmlFor="item_total" className="form-label">Item Total</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
									<input type="number" className="form-control" id="item_total" name="total_price" placeholder="0.00"  min="0" value={item_total} readOnly/>
								</div>
								<div className="form-text">
									Calculation: Gross Amount + GST Amount
								</div>
							</div>
						</div>
						</>}
						{salePurchaseAdditionalItemDiv && itemAdditional && <>
						<div className="col-sm-6">
							<h6 className='text-center mt-3'>Purchase</h6>
							<div className="mb-3 col">
								<label htmlFor="purchase_item_price" className="form-label">Item Price / Unit <span className="text-danger">*</span></label>
								<div className="input-group input-group-merge">
									<span className="input-group-text"><i className="bx bx-rupee"></i></span>
									<input type="text" className="form-control" id="purchase_item_price" name="purchase_item_price" placeholder="0.00"  min="0" value={purchase_item_price} onChange={(e) => {purchaseItemCalculation('item_price',Common.acceptOnlyDecimal(e.target.value));setPurchaseDiscountAmt('');setPurchaseDiscountPer('');}}  />
								</div>
							</div>
							<div className="mb-3 col">
								<label className="form-label" htmlFor="purchase_discount_per">Discount</label>
								<div className="input-group input-group-merge">
									<span className={"input-group-text "+(disPurchasePerDisabled ? "disabled-bg" : "")}><i className='bx bxs-offer'></i></span>
									<input type="text" className="form-control" id="purchase_discount_per" placeholder="0.00" value={purchase_discount_per} onChange={(e) => calcPurchaseDiscount('per',Common.acceptOnlyDecimal(e.target.value))} disabled={disPurchasePerDisabled} />
									<span className={"input-group-text "+(disPurchaseAmtDisabled ? "disabled-bg" : "")}><i className='bx bx-rupee'></i></span>
									<input type="text" className="form-control" id="purchase_discount_amt" placeholder="0.00" value={purchase_discount_amt} onChange={(e) => calcPurchaseDiscount('amt',Common.acceptOnlyDecimal(e.target.value))} disabled={disPurchaseAmtDisabled} />
								</div>
							</div>
							<div className="mb-3 col">
								<label htmlFor="purchase_gross_amt" className="form-label">Gross Amount</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
									<input type="number" className="form-control" id="purchase_gross_amt" placeholder="0.00"  min="0" value={purchase_gross_amt} readOnly />
								</div>
								<div className="form-text">
									Calculation: Price/Unit - Discount
								</div>
							</div>
							<div className="mb-3 col">
								<label htmlFor="purchase_gst_amt" className="form-label">GST Amount</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
									<input type="text" className="form-control" id="purchase_gst_amt" name="purchase_gst_amt" placeholder="0.00"  min="0" value={purchase_gst_amt} readOnly />
								</div>
							</div>
							<div className="mb-3 col">
								<label htmlFor="purchase_item_total" className="form-label">Item Total</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
									<input type="number" className="form-control" id="purchase_item_total" name="purchase_item_total" placeholder="0.00"  min="0" value={purchase_item_total} readOnly/>
								</div>
								<div className="form-text">
									Calculation: Gross Amount + GST Amount
								</div>
							</div>
						</div>
						</>}
					</div>
					<div className={"row " + (dividerDisplay ? 'd-block':'d-none')}>
						<div className="col">
							<div className="divider">
								<div className="divider-text"><i className={'bx bx-chevrons-'+ (additionalItemDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showAdditionalItemFields}>{additionalItemDiv ? 'Hide':'Show'} additional fields</Common.Link><i className={'bx bx-chevrons-'+ (additionalItemDiv ? "up":"down")+' bx-fade-up'}></i></div>
							</div>
						</div>
					</div>
					{additionalItemDiv &&
					<>
						<div className='row'>
							<div className="col-md-12" id="collection_tab">
								<div className="nav-align-top mb-4">
									<ul className="nav nav-tabs nav-fill" role="tablist">
										<li className="nav-item">
											<button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="true">
												Item Details
											</button>
										</li>
										<li className="nav-item">
											<button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-profile" aria-controls="navs-justified-profile" aria-selected="false" onClick={toggeCustomInputInvFields}>
												Custom Fields
											</button>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane fade show active" id="navs-justified-home" role="tabpanel">
											<div className='row'>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="sku_code" className="form-label">SKU Code </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control" id="sku_code" name="sku_code" value={skuCode} onChange={(e)=> {setSkuCode(e.target.value);}} />
														</div>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="barcode" className="form-label">Barcode </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control" id="barcode" name="barcode" value={barcode} onChange={(e)=> {setBarcode(e.target.value);}} />
														</div>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="safety_stock" className="form-label">Safety Stock </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control" id="safety_stock" name="safety_stock" placeholder="0.000" step=".001" min="0" value={safetyStock} onChange={(e)=> {setCustomItemQty(Common.acceptOnlyDecimal(e.target.value));}} />
														</div>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="reorder_point" className="form-label">Reorder Point </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control" id="reorder_point" name="reorder_point" placeholder="0.000" step=".001" min="0" value={reorderPoint} onChange={(e)=> {setReorderPointValue(Common.acceptOnlyDecimal(e.target.value));}} />
														</div>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="reorder_qty" className="form-label">Reorder Quantity </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control"  id="reorder_qty" name="reorder_qty" placeholder="0.000" step=".001" min="0" value={reorderQty} onChange={(e)=> {setReorderQtyValue(Common.acceptOnlyDecimal(e.target.value));}} />
														</div>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="max_stock_level" className="form-label">Maximum Stock Level </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control" id="max_stock_level" name="max_stock_level" placeholder="0.000" step=".001" min="0" value={maximumStockLevel} onChange={(e)=> {setMaxStockLevelValue(Common.acceptOnlyDecimal(e.target.value));}} />
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="tab-pane fade" id="navs-justified-profile" role="tabpanel">
											<div className='row'>
												<div className='col-md-1'></div>
												<div className='col-md-10'>
													{/* <div className="card accordion-item"> */}
														<div id="customInvFields" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#salesAccordion">
															{customItemInputList}
															<div className="mb-3 text-center">
																<button type="button" className="btn btn-sm btn-primary me-2" onClick={handleCustomItemInvFields}><i className='bx bx-plus'></i> Add Fields</button>
															</div>
														</div>
													{/* </div> */}
												</div>
												<div className='col-md-1'></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</>}
					<div className="row ">
						<div className="col-sm-10">
							<button type='submit' className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
							&nbsp;&nbsp;
							<button type='submit' onClick={resetForm} className="btn btn-sm btn-secondary" ><span className="tf-icons bx bx-reset"></span>&nbsp;Reset</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
		<Modal show={prefixSetting} size='sm' aria-labelledby="Prefix-Setting-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header>
			<Modal.Title>Prefix  Setting</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<p><b>Invoice Prefix is missing. Pl add that to start billing activities. <button className='border-0 bg-white p-0 text-primary cursor-pointer' onClick={(e)=>saleSettings()}>Click Here</button> or visit Income Settings section</b></p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			</Modal.Footer>
		</Modal>
		<Modal show={custListModalShow} size="lg" onHide={()=>{setCustListModalShow(false);setFilterText('')}} aria-labelledby="customer-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="text-end">
					<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Party Name, Mobile, Gstin, Email')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
					<DTE.FilterComponent placeholder="Search" onFilter={e => {setFilterText(e.target.value);loadCustTableModal(1,e.target.value);}} onClear={e=>{setFilterText('');loadCustTableModal(1,'');}} filterText={filterText} />
					</div>
				</div>
				<div className="row">
					{customers_list_modal !== undefined && customers_list_modal.length === 0 && <div className="text-center py-1">No Customer</div>}
					{customers_list_modal !== undefined && customers_list_modal.length > 0 && <>
					<div className="table-responsive text-nowrap">
						<DTE.DataTable className="rdt_AdjDrp" title={"Customer ("+totalDataCnt+")"} columns={columns} data={customers_list_modal} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} paginationComponentOptions={paginationComponentOptions} onChangePage={handlePageChange} onSort={handleSort} sortServer/>
					</div>
					</>}
				</div>
			</Modal.Body>
		</Modal>
		<Modal show={paymentCheckShow} size='sm' onHide={()=>setPaymentCheckShow(false)} aria-labelledby="Payment-check-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Payment</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<p className="mb-0"><b>Invoice having payment or advance adjusted details cannot be saved as draft. Pl delete payment info and retry</b></p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="danger" onClick={()=>setPaymentCheckShow(false)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
		<Modal size='md' show={advanceAdjustModalShow} onHide={()=>advanceModal('hide')} aria-labelledby="advance-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<div className="col-4 modal-mobile-view">
					<Modal.Title id="advance-modal">Advance adjust</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<label className="col-5 col-form-label" htmlFor="frmAdvanceBalance">Total Advance Balance</label>
					<div className="col-7">
						<div className="input-group input-group-merge">
							<span className="input-group-text disabled-bg"><i className='bx bx-rupee'></i></span>
							<input type="text" readOnly className="form-control" id="frmAdvanceBalance" placeholder="0.00" value={advanceBalance}
							autoComplete="off"/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<label className="col-5 col-form-label" htmlFor="frmAdjustedAmount">Adjusted amount <span className="text-danger">*</span></label>
					<div className="col-7">
						<div className="input-group input-group-merge">
							<span className="input-group-text"><i className='bx bx-rupee'></i></span>
							<input type="text" className="form-control" id="frmAdjustedAmount" placeholder="0.00" value={adjustedAmount}
								onChange={(e) => {
									setAdjustedAmount(Common.acceptOnlyDecimal(e.target.value));
								}}
							autoComplete="off"/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<label className="col-5 col-form-label" htmlFor="frmSalePayRemarks">Advance Adjusted Remarks</label>
					<div className="col-7">
						<input type="text" className="form-control" id="frmSalePayRemarks" placeholder="Remarks" value={advanceAdjustedRemarks} onChange={(e) => setAdvanceAdjustedRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/>
					</div>
				</div>
				<div className="text-center mt-3">
					<Button variant="primary" size="sm" className="ms-1" onClick={addAdvanceAdjust}><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
				</div>
			</Modal.Body>
		</Modal>
		<Modal show={draftInvoiceMsgShow} size='sm' onHide={()=>setDraftInvoiceMsgShow(false)} aria-labelledby="Convert-Invoice_Msg-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Save Draft</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<p><b>This draft has already been saved earlier with Invoice number. Hence, saving this draft without invoice number now is not allowed.</b></p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="danger" onClick={()=>setDraftInvoiceMsgShow(false)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
        </>
      );
}