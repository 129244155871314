import React from 'react';
import AccounHeader from './AccountHeader';
import '../styles/page-misc.css';

export default function DataPurging(props) {
	React.useEffect(() => {
		document.title = 'FYN ONE - Billing & Accounting app for Bharat | Data Purging';
	}, []);
	return (
		<div className="container-xxl container-p-y">
			<AccounHeader site_logo={props.site_logo} site_name={props.site_name} />
			<div className="text-center mx-5">
				<div><h2 className="mt-5 mx-2">Data Deletion/Purging</h2></div>
				<div>We believe you should have complete control over your data and hence support a permanent data, profile or account deletion process. Email us at support@fynone.com for permanent deletion of profile data or user account and we'll acknowledge the same within 48 business hours.</div>
				<div className="text-center mt-5">
					<a href="/" className="btn btn-info">Back to Home</a>
				</div>
			</div>
		</div>
	)
}