import axios from "axios";
import toast from 'react-hot-toast';
import { Link,useNavigate,useLocation } from 'react-router-dom';
import { AES } from 'crypto-js';
import  secureLocalStorage  from  "react-secure-storage";
export { axios };
export { toast };
export { Link };
export { useNavigate };
export { useLocation };
export { AES };
export {secureLocalStorage};
export const acceptOnlyChars = (string) => {
	return string.replace(/[^a-z ]/gi, '');
};
export const acceptOnlyNumbers = (string) => {
	return string.replace(/[^0-9]/gi, '');
};
export const acceptOnlyCharsNumbersNoSpace = (string) => {
	return string.replace(/[^a-z0-9]/gi, '');
};