import React,{useEffect} from 'react';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import PureCounter from "@srexi/purecounterjs";
import TagManager from 'react-gtm-module';
import Carousel from 'react-bootstrap/Carousel'; 

export default function Landing(props){
    useEffect(() => {
		document.title = 'FYN ONE - Simplifying Financial Management';
		new PureCounter();
		GLightbox({
			selector: '.glightbox'
		});
		const hostname = window.location.hostname;
		if( hostname === 'www.fynone.com' ){
			const tagManagerArgs = {
				gtmId: 'G-RF6E5H5MTZ'
			}
	 
			TagManager.initialize(tagManagerArgs);
		}
		const script = document.createElement('script');

        script.src = 'landing/js/main.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
	}, []);
	return (
		<React.Fragment>
			<header id="header" className="fixed-top ">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-xl-9 d-flex align-items-center justify-content-lg-between">
							<a href="/" className="logo me-auto me-lg-0"><img src="landing/img/logo.png" alt="FynOne Logo" className="img-fluid" height="40"/></a>
							<nav id="navbar" className="navbar order-last order-lg-0">
								<ul>
									<li><a className="nav-link scrollto active" href="#hero">Home</a></li>
									<li><a className="nav-link scrollto" href="#about">About</a></li>
									<li><a className="nav-link scrollto" href="#services">Modules</a></li>
									<li><a className="nav-link scrollto " href="#api">API</a></li>
									<li><a className="nav-link scrollto" href="#pricing">Pricing</a></li>
									<li><a className="nav-link scrollto" href="#faq">FAQ</a></li>
									<li><a className="nav-link scrollto" href="#contact">Contact</a></li>
								</ul>
								<i className="bi bi-list mobile-nav-toggle"></i>
							</nav>
							<a href="/login" className="get-started-btn">Login/SignUp</a>
						</div>
					</div>
				</div>
			</header>
			<section id="hero" className="d-flex flex-column justify-content-center">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-8">
							<h1>Billing & Accounting app for Bharat</h1>
							<h2>Simple, Collaborative & Compliance driven</h2>
							<h3 style={{'color' : '#fff'}}>#HarKoiAccountant</h3>
							<a href="landing/video/Billing_Software.mp4" data-gallery="gallery1" className="glightbox play-btn mb-4"><span className="d-none">FynOne Video</span></a>
						</div>
					</div>
				</div>
			</section>
			<main id="main">
				<section id="about" className="about">
					<div className="container">
						<div className="section-title">
							<h2>About Us</h2>
							<p>FYN ONE is proudly made in India for its ever ambitious and fast growing SMBs (Small and Medium Businesses) who want an easy to understand Billing & Accounting solution that is secure, accessible anytime and anywhere and embedded with various compliance requirements like GST filing. The app is from the house of Fynamics - India's most trusted full stack GST solutions provider and an approved GSP (GST Suvidha Provider)</p>
						</div>
						<div className="row content">
							<div className="col-lg-6">
								<p>The most comprehensive suite of business finance applications for small businesses, stores, retail outlets and shops</p>
								<ul>
									<li><i className="ri-check-double-line"></i> Simple: Easy interace, auto suggestions, no prior training required</li>
									<li><i className="ri-check-double-line"></i> Collaboration: Share invoices with customers, add unlimited users with permission based access</li>
									<li><i className="ri-check-double-line"></i> Compliance: Realtime GSTIN checks, automatic taxpayer validation, 1 click einvoice and eway bill generation, file GST returns, perform 2B recon for max ITC claim</li>
								</ul>
							</div>
							<div className="col-lg-6 pt-4 pt-lg-0">
								<p>Bharat ka finance manager app, ideal for individuals & freelancers as well as small & medium businesses covering Income & Collections, Expenses & Payments, Inventory management, Accounting, Asset management, Loans & Investments and much more.</p>
								<p>Available both on web and app with auto sync, so you can access data anytime, anywhere</p>
								<p>21 days free trial with pocket friendly annual renewals starting at just 124 per month</p>
								<a href="#services" className="btn-learn-more">Learn More</a>
							</div>
						</div>
					</div>
				</section>
				<section id="services" className="services">
					<div className="container">
						<div className="section-title">
							<h2>Modules</h2>
							<p>Multiple finance modules to keep your business running 24*7, from enquiry to collections, purchases to payments and much more.</p>
						</div>
						<div className="row">
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch">
								<div className="icon-box">
									<div className="icon"><i className="bx bxl-dribbble"></i></div>
									<h4>Multi profile management</h4>
									<p>Manage multiple personal or business profiles including GST enablement, Einvoice enablement and more</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
								<div className="icon-box">
									<div className="icon"><i className="bx bx-file"></i></div>
									<h4>Income & Collections management</h4>
									<p>Generate bills of supply or GST invoices, share invoices to customers, manage collections at invoice level or account level.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
								<div className="icon-box">
									<div className="icon"><i className="bx bx-tachometer"></i></div>
									<h4>Expenses & Payments management</h4>
									<p>Track every expense, get auto notified on due dates, review top sellers across categories.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
								<div className="icon-box">
									<div className="icon"><i className="bx bx-world"></i></div>
									<h4>Inventory Management</h4>
									<p>Create item master with sale & purchase price. Update opening stock, min order quantity, UQC, HSN etc.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
								<div className="icon-box">
									<div className="icon"><i className="bx bx-slideshow"></i></div>
									<h4>Dashboard</h4>
									<p>Multitudes of financial & stock statements covering cashflow, profit&loss, stock in-hand, stock movement reports and more</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
								<div className="icon-box">
									<div className="icon"><i className="bx bx-arch"></i></div>
									<h4>Subscriptions</h4>
									<p>Starts as low as Rs. 124 per profile/month with unlimited users and unlimited transactions</p>
								</div>
							</div>
						</div>
					</div>
				</section>
					<section id="playstore-banner" className="playstore-banner">
					<div className="container">
						<div className="section-title">
							<h2>Download the app, its free!</h2>
						</div>
						<div className="row">
							<div className="mt-4 p-2 bg-dark text-white rounded">
								<div style={{'display':'flex'}}>
									<img alt="FynOne Play Store" className='img-fluid d-block w-25 h-50 download-img' style={{'cursor':'pointer'}} src="landing/img/google-play-badge.png" height="100px" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.fynamics.fynone', '_blank')}/>
									<h3 className="download-text text-center">Get Fynone app from playstore.</h3>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id='testimonials'>
					<div className="container">
						<div className="section-title">
							<h2>Everyone loves FynOne</h2>
							<p>Testimonials from few of our clients</p>
						</div>
						<div className="row">
							<Carousel interval={5000}>
								<Carousel.Item>
									<img className='d-block zoom' src={"landing/img/nsic.png"} alt='First slide' />
									<h5 className='text-center' style={{"marginBottom":'3rem'}}>The National Small Industries Corporation Ltd. (NSIC)</h5>
								</Carousel.Item>
								<Carousel.Item>
									<img className='d-block zoom' src={"landing/img/corelmatrix.png"} alt='Second slide' />
									<h5 className='text-center' style={{"marginBottom":'3rem'}}>Coralmetrix Consulting LLP</h5>
								</Carousel.Item>
								<Carousel.Item>
									<img className='d-block zoom' src={"landing/img/NatarajEnterprises.png"} alt='Third slide' />
									<h5 className='text-center' style={{"marginBottom":'3rem'}}>Nataraj Enterprises</h5>
								</Carousel.Item>
							</Carousel>
						</div>
					</div>
				</section> 
				<section id='recognitions'>
					<div className="container">
						<div className="section-title mb-3">
							<h2>Recognitions</h2>
							<p>FynOne is recognized by top Institutions & Associations</p>
						</div>
						<div className="row">
							<Carousel interval={5000}>
								<Carousel.Item>
									<h3 className='text-center'>The Institute of Chartered Accountants of India (ICAI)</h3>
									<p className='text-center' style={{"marginBottom":'3rem'}}><a href='/chartered-accountants' target='_blank' style={{'color': 'blue'}}>Committee for Members in Practice (CMP)</a></p>
								</Carousel.Item>
								<Carousel.Item>
									<h3 className='text-center'>Institute of Company Secretaries of India (ICSI)</h3>
									<p className='text-center' style={{"marginBottom":'3rem'}}><a href='/company-secretaries' target='_blank' style={{'color': 'blue'}}>Benefits for Members in Practise</a></p>
								</Carousel.Item>
								<Carousel.Item>
									<h3 className='text-center'>Government of Karnataka</h3>
									<p className='text-center' style={{"marginBottom":'3rem'}}><a href='https://itbtst.karnataka.gov.in/page/Tenders/Empanelled+Startups+List/en' rel="noreferrer" target='_blank' style={{'color': 'blue'}}>Department of Electronic Information Technology, Biotechnology and Science & Technology</a></p>
								</Carousel.Item>
							</Carousel>
						</div>
					</div>
				</section>
				<section id="cta" className="cta">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 text-center text-lg-start">
								<h3>Data Security at its Core</h3>
								<img alt="FynOne Security" src="landing/img/security.png" height="100"/>
							</div>
							<div className="col-lg-6 text-center text-lg-start">
								<p>With our infosec team & senior management having decades of hands-on experience and holding various professional certifications like CISA, CISSP, CISM, ITIL etc. its only imperative that everything we do would be with Security in mind. All our audits and licenses have been awarded only after a thorough review and rigorous scrutiny of our systems and applications.</p>
							</div>
						</div>
					</div>
				</section>
				<section id="features" className="features">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 order-2 order-lg-1">
								<div className="icon-box mt-5 mt-lg-0">
									<i className="bx bx-receipt"></i>
									<h4>Built with GST compliance</h4>
									<p>Realtime customer and seller GSTIN checks with GSTN, POS, HSN & tax rate validations</p>
								</div>
								<div className="icon-box mt-5">
									<i className="bx bx-cube-alt"></i>
									<h4>Extensive Collaboration</h4>
									<p>Extend permission based access to anyone including external CA firms & others</p>
								</div>
								<div className="icon-box mt-5">
									<i className="bx bx-images"></i>
									<h4>Simplicity Driven</h4>
									<p>No training required, easy form fills with AI driven suggestions & validations</p>
								</div>
								<div className="icon-box mt-5">
									<i className="bx bx-shield"></i>
									<h4>Economical Pricing</h4>
									<p>Starting at just 124 per month with zero commitments, easy online renewals</p>
								</div>
							</div>
							<div className="image col-lg-6 order-1 order-lg-2" style={{'backgroundImage': 'url("landing/img/features.jpg")'}}></div>
						</div>
					</div>
				</section>
				<section id="counts" className="counts">
					<div className="container">
						<div className="text-center title">
							<h3>Fynamics in numbers</h3>
							<p>Founded in 2018, Fynamics has grown to be a comprehensive suite of Tax Technoology products for companies of all sizes</p>
						</div>
						<div className="row counters position-relative">
							<div className="col-lg-3 col-6 text-center">
								<span data-purecounter-start="0" data-purecounter-end="2500" data-purecounter-duration="1" className="purecounter"></span>
								<p>Clients</p>
							</div>
							<div className="col-lg-3 col-6 text-center">
								<span data-purecounter-start="0" data-purecounter-end="20" data-purecounter-duration="1" className="purecounter"></span>
								<p>Modules</p>
							</div>
							<div className="col-lg-3 col-6 text-center">
								<span data-purecounter-start="0" data-purecounter-end="80" data-purecounter-duration="1" className="purecounter"></span>
								<p>Million Einvoices</p>
							</div>
							<div className="col-lg-3 col-6 text-center">
								<span data-purecounter-start="0" data-purecounter-end="6" data-purecounter-duration="1" className="purecounter"></span>
								<p>Years</p>
							</div>
						</div>
					</div>
				</section>
				<section id="api" className="about">
					<div className="container">
						<div className="section-title">
							<h2>API Integrations</h2>
							<p>Want to integrate your billing/accounting/collections/payments/ERP platforms with FYN ONE? Its very easy with our Open APIs and web hooks. </p>
						</div>
						<div className="row content">
							<div className="col-lg-6">
								<p>Every module has API integration capability for easy and realtime data transters between your system and FYN ONE</p>
								<ul>
									<li><i className="ri-check-double-line"></i> Low Code: Plain text JSON request and response formats</li>
									<li><i className="ri-check-double-line"></i> Low Maintenance: Availability of custom fields for adding additional data points on later date</li>
									<li><i className="ri-check-double-line"></i> High Security: Token based authentication, TLS 1.2 & higher, connections over HTTPS</li>
								</ul>
							</div>
							<div className="col-lg-6 pt-4 pt-lg-0">
								<p>Detailed API documentations available as Swagger Docs as well as Postman Collections.</p>
								<p>Complimentary Sandbox access to integrate and test the APIs before moving to Production. Continued Sandbox access even post Go Live for future builds.</p>
								<a href="#contact" className="btn-learn-more">Get in Touch</a>
							</div>
						</div>
					</div>
				</section>
				<section id="pricing" className="pricing">
					<div className="container">
						<div className="section-title">
							<h2>Pricing</h2>
							<p>Unlock the power of habit with our <strong>21-day free trial</strong> (15 standard + 6 extended), turning financial management into a seamless part of your routine</p>
						</div>
						<div className="row">
							<div className="col-lg-4 col-md-6">
								<div className="box">
									<h2>Basic</h2>
									<p>For startups and small companies</p>
									<h4><sup><i className='bx bx-rupee'></i></sup>124<span> <sub className='h6 text-muted pricing-duration mt-auto mb-2 fw-normal'>/ month</sub> </span></h4>
									<div className="text-center">
										{/* <small className="price-yearly price-yearly-toggle text-muted">7 days free trial</small> */}
										<small className="price-yearly price-yearly-toggle">Billed Annually - ₹ 1488/-</small>
									</div>
									<ul>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>Business profiles with or without GST</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>Generate bills & Tax invoices</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>Income & Collections management</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>Expense & Payments management</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>Inventory Management</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>Unlimited user management with permission based access</li>
										{/* <li className="na">GST Compliance</li> */}
										{/* <li className="na">Unlimited users</li> */}
									</ul>
									<div className="btn-wrap">
										<a href="/login" className="btn-buy" target="_blank" rel="noreferrer">Register Now</a>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 mt-4 mt-md-0">
								<div className="box recommended">
									<div className="position-absolute end-0 me-4 top-0 mt-2">
										<span className="badge bg-label-success text-success">POPULAR</span>
									</div>
									<h2>Standard</h2>
									<p className="text-center">For mid sized companies and CA firms</p>
									<div className="text-center">
										<div className="d-flex justify-content-center">
										<sup className="h6 pricing-currency mt-3 mb-0 me-1 text-success">₹</sup>
										<h1 className="price-toggle price-yearly display-4 text-success mb-0">199</h1>
										{/* <h1 className="price-toggle price-monthly display-4 text-primary mb-0 d-none">49</h1> */}
										<sub className="h6 text-muted pricing-duration mt-auto mb-2 fw-normal">/month</sub>
										</div>
										<small className="price-yearly price-yearly-toggle text-muted">Billed Annually - ₹ 2388/-</small>
										
									</div>
									<ul>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"></span>Everything in Basic+</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>Accounting module with automated ledger creation</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>Financial Statements incl income statement, Balance sheet, Cashflow stmt</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>Asset management including auto depreciation calculation</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>Loans and investment management</li>
									</ul>
									<div className="btn-wrap">
										<a href="/login" className="btn-buy" target="_blank" rel="noreferrer">Register Now</a>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
								<div className="box">
									{/* <span className="recommended-badge">PRO +</span> */}
									<h2>PRO</h2>
									{/* <h4><sup><i className='bx bx-rupee'></i></sup>7,199<span> / Billed Annually</span></h4> */}
									<p className="text-center">Solution for big organizations</p>
									<div className="text-center">
										<div className="d-flex justify-content-center">
										<sup className="h6 text-success pricing-currency mt-3 mb-0 me-1">₹</sup>
										<h1 className="price-toggle price-yearly display-4 text-success mb-0">599</h1>
										{/* <h1 className="price-toggle price-monthly display-4 text-primary mb-0 d-none">99</h1> */}
										<sub className="h6 pricing-duration mt-auto mb-2 fw-normal text-muted">/month</sub>
										</div>
										<small className="price-yearly price-yearly-toggle text-muted">Billed Annually - ₹ 7188/-</small>
										
									</div>
									<ul>
									<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"></span>Everything in Standard+</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>Single click Einvoice generation with IRN number and QR code in pdf copy</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>EWay bill management including Delivery Challan</li>
										<li><span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-success me-2"><i className="bx bx-check bx-xs"></i></span>GST returns covering R1, 3B, ITC04 as well as vendor 2B reconciliation</li>
									</ul>
									<div className="btn-wrap">
										<a href="/login" className="btn-buy">Register Now</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="faq" className="faq">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
								<div className="content">
									<h3>Frequently Asked <strong>Questions</strong></h3>
									<p>Here are some common questions to help you know us better</p>
								</div>
								<div className="accordion-list">
									<ul>
										<li>
											<button type="button" data-bs-toggle="collapse" className="btn btn-link text-left text-decoration-none w-100 collapse" data-bs-target="#accordion-list-1">Why should we use FYN ONE?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></button>
											<div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">
												<p>While there are many billing and accounting apps in the market, FYN ONE stands apart as the only app that is simple to use, built-in with tax compliance and supporting multi user collaboration with best-in-class data security, thereby creating a digital revolution in the way you maintain your books</p>
											</div>
										</li>
										<li>
											<button data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="btn btn-link text-decoration-none w-100 collapsed">What is a GSP<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></button>
											<div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
												<p>GSP stands for GST Suvidha Provider. These are approved service providers for direct API connectors with NIC for Einvoice, Eway bill and the GSTN for GST related activities. Only a GSP can connect to the GSTN and NIC. You'll see our company "Fynamics Techno Solutions Pvt Ltd" under the GSP list on GSTN</p>
											</div>
										</li>
										<li>
											<button data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="btn btn-link text-decoration-none w-100 collapsed">What can we expect in coming months?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></button>
											<div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
												<p>We have big plans for launching various business finance solutions for SMBs. Expect us to get into Payroll, HR compliance, Direct Tax, Ecommerce listing and much more. We are constantly working to supercharge your growth.</p>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{'backgroundImage': 'url("landing/img/faq.jpg")'}}>&nbsp;</div>
						</div>
					</div>
				</section>
				<section id="contact" className="contact">
					<div className="container">
						<div className="section-title">
							<h2>Contact</h2>
							<p>Email, Call, WhatsApp, Message or feel free to even come over to our Office! Our coordinates are as below:</p>
						</div>
					</div>
					<div className="container">
						<div className="row mt-5">
							<div className="col-lg-4">
								<div className="info">
									<div className="address">
										<i className="ri-map-pin-line"></i>
										<h4>Location:</h4>
										<p>#302, 3rd Floor, Spearhead Towers,<br/>50-56 Margosa Road,<br/>Malleswaram, Bangalore, India - 560055</p>
									</div>
									<div className="email">
										<i className="ri-mail-line"></i>
										<h4>Email:</h4>
										<p>hello@fynamics.tech</p>
									</div>
									<div className="phone">
										<i className="ri-phone-line"></i>
										<h4>Call:</h4>
										<p>+91 96633 55662</p>
									</div>
								</div>
							</div>
							<div className="col-lg-8 mt-5 mt-lg-0">
								<iframe title="FynOne Address" src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3887.451328850033!2d77.56653527390588!3d13.006905914105582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x3bae173cde1e370d%3A0x81ec7113090c296c!2sFynamics%20Techno%20Solutions%20Pvt.%20Ltd.%2C%20No.%20202%2C%20II%20Floor%2C%20Spearhead%20Towers%2C%20%2350%2C%2056%2C%20Margosa%20Rd%2C%20Malleshwaram%2C%20Bengaluru%2C%20Karnataka%20560055!3m2!1d13.0069007!2d77.5691102!5e0!3m2!1sen!2sin!4v1685015828328!5m2!1sen!2sin" style={{border:0, width: "100%", height: "350px"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
							</div>
						</div>
					</div>
				</section>
			</main>
			<footer id="footer">
				<div className="container">
					<div className="row">
						<div className="col-md-7 mb-3 text-center">
							<h3>FYN ONE</h3>
							<p>The Billing & Accounting app for Bharat</p>
							<div className="social-links">
								<a href="https://twitter.com/Fynone_official" target="_blank" rel="noreferrer" className="twitter"><i className="bx bxl-twitter"></i></a>
								<a href="https://www.facebook.com/profile.php?id=100094403341519" target="_blank" rel="noreferrer" className="facebook"><i className="bx bxl-facebook"></i></a>
								<a href="https://instagram.com/fynone_official?igshid=NGExMmI2YTkyZg==" target="_blank" rel="noreferrer" className="instagram"><i className="bx bxl-instagram"></i></a>
								<a href="https://www.youtube.com/channel/UCcgwyw_fdBIFBDArgbnEJjw" target="_blank" rel="noreferrer" className="youtube"><i className="bx bxl-youtube"></i></a>
								<a href="https://www.linkedin.com/in/fyn-one-14660a281/" target="_blank" rel="noreferrer" className="linkedin"><i className="bx bxl-linkedin"></i></a>
							</div>
							<div className="copyright">
								FYN ONE is part of the FinTech suite at Fynamics
							</div>
							<div className="credits">
								Visit <a href="https://www.fynamics.tech/" target="_blank" rel="noreferrer">Fynamics.Tech</a> for details
							</div>
						</div>
						<div className="col-md-5 mb-3 text-center text-md-start">
							<h4>Links</h4>
							<ul className="list-unstyled">
								<div className="row">
									<div className="col-6">
										<li className="mb-2"><a href="/privacy">Privacy Policy</a></li>
										<li className="mb-2"><a href="/tnc">Terms and Conditions</a></li>
										<li className="mb-2"><a href="/datapurging">Data Purging</a></li>
									</div>
									<div className="col-6">
										<li className="mb-2"><a href="/shipping">Shipping & Delivery Policy</a></li>
										<li className="mb-2"><a href="/cancellation">Cancellation & Refund Policy</a></li>
										<li className="mb-2"><a href="/landing-partner">Partners</a></li>
									</div>
								</div>
							</ul>
						  </div>
					</div>
				</div>
			</footer>
			
			<a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
		</React.Fragment>
	);
}