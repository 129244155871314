import React,{useState}  from "react";
import Layout from './PartnerLayout';
import NavBar from './PartnerNavBar';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-date-picker/dist/DatePicker.css';
import '../styles/add-document.css';
import * as Common from '../common/ImportUserEssentials';
import { WithContext as ReactTags } from 'react-tag-input';

export default function AddPartnerPolicy(props) {
    const [document_title, setDocumentTitle]             = useState('');
    const [document_description, setDocumentDescription] = useState('');
    const [doc, setDocu]                                 = useState('');
    const [tags, setTags]                                = useState([]);
    const [documentId, setDocumentId]                    = useState(false);
    const [toggleFileInput, setToggleFileInput]          = useState(true);
    const [isAddPageLoaded, setIsAddPageLoaded]          = useState(true);
    const [uploadedFileName, setUploadedFileName]        = useState('');
    const [uploadedFileUrl, setUploadedFileUrl]          = useState('');
    const [selected, setSelected]                        = useState([]);
	const [partnerList, setPartnerList]                  = useState([]);
	const [defaultPartner, setDefaultPartner]            = useState([{'value': 'All', 'label': 'All','digits':''}]);
    const [partner_id, setPartnerId]                     = useState('All');

    const { state } = Common.useLocation();
    const navigate = Common.useNavigate();
    React.useEffect(() => {
        document.title = 'Add Partner Policy | '+props.site_name;
        if( state === null ){
            navigate('/add-partner-policy');
            setIsAddPageLoaded(true);
            Common.axios({
                method: 'get',
                url:props.url+'partner-policy/getTags',
            }).then(function (response) {
                if(response.data.success){
                    var filteredArray = response.data.tagString.split(',').filter(function(item, pos){
                        return response.data.tagString.split(',').indexOf(item)== pos;
                    });
                    setTags(filteredArray);
                }
            }).catch(error => console.log(error));

		} else {
            setIsAddPageLoaded(false);
            setToggleFileInput(false);

            Common.axios({
                method: 'post',
                url:props.url+'partner-policy/get-document-details',
                data:{doc_id:state.rowId}
            }).then(function (response) {
                if(response.data.success){
                    setDocumentTitle(response.data.data.title);
                    setDocumentDescription(response.data.data.description);
                    let fileName = response.data.data.file_path.replace(/^.*[\\/]/, '');
                    setUploadedFileName(fileName);
                    setUploadedFileUrl(props.url+'public/assets/files/partners/policy_document/'+fileName);
                    if (response.data.data.tags !== "") {
                        var tagStrinArr = response.data.tagString.split(',').filter(function(item, pos){
                            return response.data.tagString.split(',').indexOf(item)== pos;
                        });
                        setTags(tagStrinArr);

                        var filteredArray = response.data.data.tags.split(',').filter(function(item, pos){
                            return response.data.data.tags.split(',').indexOf(item)== pos;
                        });
                        let tagObj = [{
                            id: filteredArray[0],
                            text: filteredArray[0]
                        }]
                        let obj = {};
                        if (filteredArray.length > 1) {
                            for (let index = 1; index <= filteredArray.length - 1; index++) {
                                obj = {
                                    id: filteredArray[index],
                                    text: filteredArray[index]
                                }
                                tagObj.push(obj);
                            }
                        }
                        setSelected(tagObj);
                    }
                    if (response.data.data.partner_uid !== null) {
                        setDefaultPartner([{'value': response.data.partnerDetails.value, 'label': response.data.partnerDetails.label,'digits':''}]);
                    }
                    setDocumentId(state.rowId);
                }
            }).catch(error => console.log(error));
        }
        Common.axios({
			method: 'get',
			url:props.url+'partner/search/',
		}).then(function (response) {
			if(response.data.success){
				setPartnerId('All');
				setPartnerList([{value:"All", label:"All"},...response.data.items]);
			}
		}).catch(error => console.log(error));

	}, []);
    const suggestions = tags.map((tag,i) => {
        return {
            id:tag,
            text:tag
        };
    });
    const KeyCodes = {
        comma: 188,
        enter: 13,
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];
        const handleDelete = (i) => {
        setSelected(selected.filter((tag, index) => index !== i));
    };

    const setPartnerSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		if(value){
			setPartnerId(value);
			setDefaultPartner([{'value':value,'label':label,'digits':''}]);
		}else{
			setPartnerId('');
			setDefaultPartner([]);
		}
	}

    const handleAddition = (tag) => {
        console.log(tag)
        if (selected.length > 5) {
            Common.toast.error('Only 5 tags are allowed');
            Common.toast.dismiss();
            return false;
        }

        if (tag.text.length > 20) {
            Common.toast.error('Character in tags should not be greater than 20');
            Common.toast.dismiss();
            return false;
        }

        if (selected.length < 5 && tag.text.length <= 20)
            setSelected([...selected, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = selected.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setSelected(newTags);
    };
    const handleTagClick = (index) => {
        console.log('The tag at index ' + index + ' was clicked');
    };
    const handleDocument = (e) => {
        const fileTypes = ['application/pdf', 'image/jpeg', 'image/jpg','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/png', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (e.target.files[0] !== undefined) {
            if (!fileTypes.includes(e.target.files[0].type)) {
                Common.toast.error('File format not supported');
                e.target.value = null;
                return false;
            } else {
                if (e.target.files[0].size > (5 * 1048576)) {
                    Common.toast.error('File size should be less than 5MB');
                    e.target.value = null;
                    return false;
                } else {
                    setDocu(e.target.files[0]);
                }
            }
        }
        Common.toast.dismiss();
    };
    const resetToggle = (isVisible) =>{
        if (isVisible === true)
            setToggleFileInput(false);
        else
            setToggleFileInput(true);

    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        const formData = new FormData();
        let tagsArr = [];
        formData.append("file", doc);
        formData.append("title", document_title);
        formData.append("description", document_description);
        formData.append("partner_id", partner_id);
        for(let i = 0;i < selected.length;i++) {
            tagsArr.push(selected[i].text);
        }
        formData.append("tags", tagsArr);
        if (state === null) {
            formData.append("action_type", 'add');
        } else {
            formData.append("action_type", state.type); // edit
        }

        if  (state !== null) {
            setDocumentId(state.rowId);
            formData.append("doc_id", documentId);
        }
        Common.axios.post(props.url+'partner-policy/add-document-manager', formData, {
            mode: 'cors',
            headers: { 'Content-Type':  'multipart/form-data' }
        }).then(function (response) {
            Common.showLoader(false);
            if(response.data.success){
                navigate("/partner-policy");
                return false;
            }
        Common.toast.dismiss();
        Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    return(
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Layout site_logo={props.site_logo} label="partner-policy" site={props.site_name}/>
                    <div className="layout-page">
                        <NavBar site={props.site_name} url={props.url}/>
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h4 className="fw-bold py-3 mb-3">Add Policy documents</h4>
                                <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            <form id="formPartner" autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                                                <div className="row">
													<div className="col-md-6">
                                                        <label htmlFor="title" className="form-label">Document Title<span className="text-danger">*</span></label>
                                                        <input autoFocus type="text" className="form-control" id="title" name="title" placeholder="Max 100 chars" maxLength="100" value={document_title} onChange={(e) => setDocumentTitle(e.target.value)} required/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="document_description" className="form-label">Description</label>
                                                        <textarea rows="2" maxLength="250" id="document_description" name="document_description" className="form-control" placeholder="Document description" aria-label="Payment Instructions" value={document_description} onChange={(e) => setDocumentDescription(e.target.value)}></textarea>
                                                    </div>
												</div>
                                                <div className="row">
                                                    {isAddPageLoaded ? <>
                                                        <div className="col-md-6">
                                                            <label htmlFor="formFile" className="form-label" >Document Upload</label>
                                                            <input type="file" className="form-control mb-2" id="formFile" name="formFile" onChange={handleDocument} />
                                                            <label><small>Valid file formats: pdf,doc,docx,png,jpeg/jpg,csv,xls/xlsx Max file size 5MB</small></label>
                                                        </div>
                                                    </> : <>{toggleFileInput ? <>
                                                        <div className="col-md-6 mt-3">
                                                            <span style={{display: 'flex'}}>
                                                                <label htmlFor="formFile" className="form-label">Document Upload</label>
                                                                <button type="button" className="btn btn-primary btn-sm mb-2 toggle" onClick={(e)=>resetToggle(toggleFileInput)}>Reset</button>
                                                            </span>
                                                            <input type="file" className="form-control" id="formFile" name="formFile" onChange={handleDocument}/>
                                                            <label><small>Valid file formats: pdf,doc,docx,png,jpeg/jpg,csv,xls/xlsx Max file size 5MB</small></label>
                                                        </div>
                                                    </> : <>
                                                        <div className="col-md-6 mt-3">
                                                            <span style={{display:"flex"}}>
                                                                <label htmlFor="formFile" className="form-label">Document Upload</label>
                                                                <button type="button" className="btn btn-primary btn-sm toggle" onClick={(e)=>resetToggle(toggleFileInput)} >Reset</button>
                                                            </span>
                                                            <label><a href={uploadedFileUrl} download={uploadedFileName}>{uploadedFileName}</a></label>
                                                        </div>
                                                    </>}</>}
                                                    
                                                    <div className="col-md-6">
                                                        <label htmlFor="frmpartner" className="form-label">Partners</label>
                                                        <Typeahead selected={defaultPartner} clearButton id="frmpartner" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPartnerSelect(e)} placeholder="Choose Partner"
                                                            options={partnerList}
                                                            renderMenuItemChildren={(option: Item,props) => (
                                                            <>
                                                                <Highlighter search={props.text}>
                                                                    {option.label}
                                                                </Highlighter>
                                                                <div>
                                                                    <small>{(option.type)?option.type[0].toUpperCase() + option.type.slice(1):''}</small>
                                                                </div>
                                                            </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-md-6">
                                                        <label htmlFor="document_description" className="form-label">Document Tags</label>
                                                            <div>

                                                                <ReactTags
                                                                    tags={selected}
                                                                    suggestions={suggestions}
                                                                    delimiters={delimiters}
                                                                    handleDelete={handleDelete}
                                                                    handleAddition={handleAddition}
                                                                    handleDrag={handleDrag}
                                                                    handleTagClick={handleTagClick}
                                                                    inputFieldPosition="top"
                                                                    autocomplete
                                                                    editable
                                                                />
                                                            </div>
                                                        <label> <small> Type and press enter to create tags.Max 5 tags each upto 20 chars</small> </label>
                                                    </div>
												</div>
												<div className="row ">
													<div className="d-flex gap-2">
														<a href="/partner-policy" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i>Back </a>
														<button role="button" type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
													</div>
												</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <Common.PartnerNeedHelp url={props.url}/>
                    </div>
                </div>
            </div>
        </>
    );
}