import React from 'react';
import * as Common from '../common/ImportUserEssentials';
import { Link  } from 'react-router-dom';

export default function PartnerLayout(props){
	const navigate = Common.useNavigate();
	React.useEffect(() => {
		const script = document.createElement('script');
			const script1 = document.createElement('script');
			script.type = "application/javascript";
			script.src = "assets/vendor/js/menu.js";
			document.body.appendChild(script);
			
			script.addEventListener('load', () => {
				script1.type = "application/javascript";
				script1.src = "assets/js/main.js";
				script1.async = true;
				document.body.appendChild(script1);
			})
			setTimeout(function(){ 
				document.body.removeChild(script);
				document.body.removeChild(script1);
			}, 1500);
	}, []);
	const handleFynOneRedirection =() => {
        navigate('/login');
    }
    return (
		<aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
			<div className="app-brand demo">
				<a href="/" className="app-brand-link">
					<span className="app-brand-logo demo">
						<img alt="FYNONE Logo" height="65" width="auto" src={props.site_logo}></img>
					</span>
				</a>
				<a href="#" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
					<i className="bx bx-chevron-left bx-sm align-middle"></i>
				</a>
			</div>
			<div className="menu-inner-shadow"></div>
			<ul className="menu-inner py-1">
				<li className="menu-header small text-uppercase">
					<span className="menu-header-text">Menu</span>
				</li>
				<li className={(props.label === "partners" || props.label === "add-partner") ? "menu-item active" : "menu-item"}>
					<a href="/partners" className="menu-link">
						<i className='menu-icon bx bx-group'></i>
						<div data-i18n="Partner Details">Partner Details</div>
					</a>
				</li>
				<li className={(props.label === "partner-marketing") ? "menu-item active" : "menu-item"}>
					<a href="/fynone-marketing" className="menu-link">
						<i className="menu-icon tf-icons bx bxs-store-alt"></i>
						<div data-i18n="FynOne Marketing Materials">FynOne Marketing Materials</div>
					</a>
				</li>
				<li className={(props.label === "partner-clients" || props.label === "partner-myclients" ) ? "menu-item active open" : "menu-item"}>
					<a href="#" className="menu-link menu-toggle">
						<i className="menu-icon tf-icons bx bx-user"></i>
						<div data-i18n="Partner Clients">Clients</div>
					</a>
					<ul className="menu-sub">
						<li className={(props.label === "partner-myclients") ? "menu-item active" : "menu-item"}>
							<Link to="/partner-myclients" className="menu-link menu-link-toggle">
								<div data-i18n="My Clients">My Clients</div>
							</Link>
						</li>
						{localStorage.getItem('fo_partnerType') === 'distributor' && <>
							<li className={(props.label === "partner-clients") ? "menu-item active" : "menu-item"}>
								<Link to="/partner-clients" className="menu-link menu-link-toggle">
									<div data-i18n="Partner Clients">Partner Clients</div>
								</Link>
							</li>
						</>}
					</ul>
				</li>
				<li className={(props.label === "partner-myclients-orders" || props.label === "partner-clients-orders" ) ? "menu-item active open" : "menu-item"}>
					<a href="#" className="menu-link menu-toggle">
						<i className='menu-icon bx bx-package'></i>
						<div data-i18n="Partner Clients">Orders and Commissions</div>
					</a>
					<ul className="menu-sub">
						<li className={(props.label === "partner-myclients-orders") ? "menu-item active" : "menu-item"}>
							<Link to="/partner-myclients-orders" className="menu-link menu-link-toggle">
								<div data-i18n="My Client Orders">My Client Orders</div>
							</Link>
						</li>
						{localStorage.getItem('fo_partnerType') === 'distributor' && <>
							<li className={(props.label === "partner-clients-orders") ? "menu-item active" : "menu-item"}>
								<Link to="/partner-clients-orders" className="menu-link menu-link-toggle">
									<div data-i18n="Partner Client Orders">Partner Client Orders</div>
								</Link>
							</li>
						</>}
					</ul>
				</li>				
				<li className={(props.label === "partner-policy") ? "menu-item active" : "menu-item"}>
					<a href="/partner-policy" className="menu-link">
						<i className="menu-icon tf-icons bx bxs-report"></i>
						<div data-i18n="Policy Documents">Policy Documents</div>
					</a>
				</li>
				<li className="mt-3 px-3 d-grid mx-auto">
					<button type="button" className="btn btn-primary" onClick={(e) => handleFynOneRedirection()}><div data-i18n="Back to FynOne"><i className='bx bx-right-arrow-alt'></i> Go to FynOne</div></button>
				</li>
			</ul>
		</aside>
	);
}