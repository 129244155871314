import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import GuestHeader from './GuestHeader';
import { AES } from 'crypto-js';
import axios from "axios";
import '../styles/page-auth.css';

export default function ForgotPassword(props){
    const [email, setEmail]       = useState('');
    const [errorMessage,setError] = useState('');
    const [successMsg,setSucces]  = useState('');
	useEffect(() => {
		document.title = 'Forgot Password | '+props.site_name;
	}, [props.site_name]);
    const handleSubmit =(e) => {
        e.preventDefault();
        axios({
            method: 'post',
            url:props.url+'forgot/submit',
            data: {
				email: AES.encrypt(email, props.site_key).toString()
            },
        }).then(function (response) {
			if(response.data.success){
				setSucces(response.data.message);
			} else{
				setError(response.data.message);
			}
			setTimeout(() => {
				setError('');
				setSucces('');
			}, 5000);
        }).catch(error => console.log(error));
    }
    return(
        <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="card">
              <div className="card-body">
              <GuestHeader site_logo={props.site_logo} site_name={props.site_name} />
              <h4 className="mb-2">Forgot Password? 🔒</h4>
              <p className="mb-4">Enter your email and we'll send you instructions to reset your password</p>
      
                <form id="formAuthentication" className="mb-3" autoComplete="off" onSubmit={handleSubmit}>
                {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">{errorMessage}</div>)}
                {successMsg && (<div className="alert alert-success alert-dismissible" role="alert">{successMsg}</div>)}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="email" className="form-control" id="email" name="email" placeholder="Enter your email" value={email} required  onChange={(e) => setEmail(e.target.value)} autoFocus/>
                  </div>
                  <button className="btn btn-primary d-grid w-100">Send Reset Link</button>
                </form>
                <div className="text-center">
                <Link to="/login" className="d-flex align-items-center justify-content-center">
                  <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                  Back to login
                </Link>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}