import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import PureCounter from "@srexi/purecounterjs";
import TagManager from 'react-gtm-module';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Landing(props){
	const navigate = useNavigate();
    useEffect(() => {
		if( localStorage.getItem('fo_loggedin') ){
			window.location.href = props.link + "mart-orders";
			return false;
		}
		document.title = 'FynMart - Merchant Signup';
		AOS.init();
		new PureCounter();
		GLightbox({
			selector: '.glightbox'
		});
		const hostname = window.location.hostname;
		if( hostname === 'www.fynone.com' ){
			const tagManagerArgs = {
				gtmId: 'G-RF6E5H5MTZ'
			}

			TagManager.initialize(tagManagerArgs);
		}
		const script = document.createElement('script');

        script.src = 'landingmv/js/main.js';
        script.async = true;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
	}, []);
	return (
		<React.Fragment>
			<header id="header" className="header d-flex align-items-center sticky-top">
				<div className="container position-relative d-flex align-items-center justify-content-between">
					<a href="/mart" className="logo d-flex align-items-center me-auto me-xl-0">
						<img alt="FYNMART Merchant Logo" height="65" width="auto" src={props.site_logo}></img>
					</a>
					<nav id="navmenu" className="navmenu">
						<ul>
							<li><a href="#hero" className="active">Home</a></li>
							<li><a href="#about">About</a></li>
							<li><a href="#menu">Mart Types</a></li>
							<li><a href="#stats-new">Download App</a></li>
							<li><a href="#seller">Merchant Benefits</a></li>
							<li><a href="#footer">Contact</a></li>
						</ul>
						<i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
					</nav>
					<a className="btn-getstarted" href="/login">Login/Sign Up</a>
				</div>
			</header>
			<main className="main">
				<section id="hero" className="hero section">
					<div className="container">
						<div className="row gy-4 justify-content-center justify-content-lg-between">
							<div className="col-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center">
								<h1 data-aos="fade-up">Your Personal<br/>Online Shop</h1>
								<p data-aos="fade-up" data-aos-delay="100">Invite customers to your Online store to increase repeat purchases. Give loyalty rewards & announce deals/offers</p>
								<div className="d-flex" data-aos="fade-up" data-aos-delay="200">
									<a href="/login" className="btn-get-started">Login/Sign Up</a>
									{/*<a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox btn-watch-video d-flex align-items-center"><i class="bi bi-play-circle"></i><span>Watch Video</span></a>*/}
								</div>
							</div>
							<div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
								<img src="landingmv/img/hero-img.gif" className="img-fluid animated" alt=""/>
							</div>
						</div>
					</div>
				</section>
				<section id="stats" className="stats section">
					<img src="landingmv/img/stats-bg.png" alt="" data-aos="fade-in"/>
					<div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
						<div className="row gy-4">
							<div className="col-lg-3 col-md-6">
								<div className="stats-item text-center w-100 h-100">
									<span data-purecounter-start="0" data-purecounter-end="40" data-purecounter-duration="1" className="purecounter"></span>
									<p>Categories</p>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="stats-item text-center w-100 h-100">
									<span data-purecounter-start="0" data-purecounter-end="10000" data-purecounter-duration="1" className="purecounter"></span>
									<p>Items</p>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="stats-item text-center w-100 h-100">
									<span data-purecounter-start="0" data-purecounter-end="19000" data-purecounter-duration="1" className="purecounter"></span>
									<p>Pincodes</p>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="stats-item text-center w-100 h-100">
									<span data-purecounter-start="0" data-purecounter-end="4000" data-purecounter-duration="1" className="purecounter"></span>
									<p>Cities & Towns</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="about" className="why-us section">
					<div className="container">
						<div className="row gy-4">
							<div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
								<div className="why-box">
									<h3>Why Sell on FynMart</h3>
									<p>
										Its your own personal online store. Why sell on e-commerce platforms and other marketplaces when you can have your own store, connect with your loyal customer base, put out deals & offers and grow repeat sales.
									</p>
									<div className="text-center">
										<a href="#" className="more-btn"><span>Learn More</span> <i className="bi bi-chevron-right"></i></a>
									</div>
								</div>
							</div>
							<div className="col-lg-8 d-flex align-items-stretch">
								<div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
									<div className="col-xl-4">
										<div className="icon-box d-flex flex-column justify-content-center align-items-center">
											<i className="bi bi-clipboard-data"></i>
											<h4>Merchant Only</h4>
											<p>Only you can invite your customers through a QR code or link share. This allows you to personalize offerings & services to your customers</p>
										</div>
									</div>
									<div className="col-xl-4" data-aos="fade-up" data-aos-delay="300">
										<div className="icon-box d-flex flex-column justify-content-center align-items-center">
											<i className="bi bi-gem"></i>
											<h4>Rewards</h4>
											<p>Setup discounts, offers, deals & rewards and notify your customers anytime anywhere. Why wait for a Big Sale day when everyday can be Big!</p>
										</div>
									</div>
									<div className="col-xl-4" data-aos="fade-up" data-aos-delay="400">
										<div className="icon-box d-flex flex-column justify-content-center align-items-center">
											<i className="bi bi-inboxes"></i>
											<h4>Zero Commission</h4>
											<p>FynMart is a Zero Commission model and you can start for free. No setup costs, No AMC, No minimum commitments. Platform fees at just Rs 5 per order</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="menu" className="menu section">
					<div className="container section-title" data-aos="fade-up">
						<h2>Mart Types</h2>
						<p><span>across </span> <span className="description-title">Categories</span> such as </p>
					</div>
					<div className="container">
						<ul className="nav nav-tabs d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
							<li className="nav-item">
								<button className="nav-link active show" data-bs-toggle="tab" data-bs-target="#fnb">
									<h4>Food & Beverage (FnB)</h4>
								</button>
							</li>
							<li className="nav-item">
								<button className="nav-link" data-bs-toggle="tab" data-bs-target="#retail">
									<h4>Retail Store</h4>
								</button>
							</li>
							<li className="nav-item">
								<button className="nav-link" data-bs-toggle="tab" data-bs-target="#pharmacy">
									<h4>Pharmacy</h4>
								</button>
							</li>
							<li className="nav-item">
								<button className="nav-link" data-bs-toggle="tab" data-bs-target="#lifestyle">
									<h4>Lifestyle & Fashion</h4>
								</button>
							</li>
							<li className="nav-item">
								<button className="nav-link" data-bs-toggle="tab" data-bs-target="#salon">
									<h4>Spa's & Salons</h4>
								</button>
							</li>
						</ul>
						<div className="tab-content" data-aos="fade-up" data-aos-delay="200">
							<div className="tab-pane fade active show" id="fnb">
								<div className="tab-header text-center">
									<h3>Food & Beverage (FnB)</h3>
								</div>
								<div className="row gy-5">
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Hotel.png" className="glightbox"><img src="landingmv/img/category/Hotel.png" className="menu-img img-fluid" alt=""/></a>
										<h4>QSR</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Dine-ins.png" className="glightbox"><img src="landingmv/img/category/Dine-ins.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Dine-ins</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Cloud Kitchen.png" className="glightbox"><img src="landingmv/img/category/Cloud Kitchen.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Cloud Kitchen</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Catering Services.png" className="glightbox"><img src="landingmv/img/category/Catering Services.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Catering Services</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/bakery.png" className="glightbox"><img src="landingmv/img/category/bakery.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Bakery</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Pubs Bars & Breweries.png" className="glightbox"><img src="landingmv/img/category/Pubs Bars & Breweries.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Pubs Bars & Breweries</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Home Chef.png" className="glightbox"><img src="landingmv/img/category/Home Chef.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Home Chef</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Food Trucks.png" className="glightbox"><img src="landingmv/img/category/Food Trucks.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Food Trucks</h4>
									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="retail">
								<div className="tab-header text-center">
									<h3>Retail Store</h3>
								</div>
								<div className="row gy-5">
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Convenience store.png" className="glightbox"><img src="landingmv/img/category/Convenience store.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Convenience store</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Electronics & appliances.png" className="glightbox"><img src="landingmv/img/category/Electronics & appliances.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Electronics & appliances</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Office supplies.png" className="glightbox"><img src="landingmv/img/category/Office supplies.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Office supplies</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Grocery.png" className="glightbox"><img src="landingmv/img/category/Grocery.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Grocery</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Sporting goods.png" className="glightbox"><img src="landingmv/img/category/Sporting goods.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Sporting goods</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Printing & Stationery.png" className="glightbox"><img src="landingmv/img/category/Printing & Stationery.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Printing & Stationery</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Books music & entertainment.png" className="glightbox"><img src="landingmv/img/category/Books music & entertainment.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Books music & entertainment</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Pet supplies.png" className="glightbox"><img src="landingmv/img/category/Pet supplies.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Pet supplies</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Toys.png" className="glightbox"><img src="landingmv/img/category/Toys.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Toys</h4>
									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="pharmacy">
								<div className="tab-header text-center">
									<h3>Pharmacy</h3>
								</div>
								<div className="row gy-5">
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/drug Store-01.png" className="glightbox"><img src="landingmv/img/category/drug Store-01.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Drug Store</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Hospital.png" className="glightbox"><img src="landingmv/img/category/Hospital.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Hospital</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Speciality pharmacy.png" className="glightbox"><img src="landingmv/img/category/Speciality pharmacy.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Speciality pharmacy</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Community pharmacy.png" className="glightbox"><img src="landingmv/img/category/Community pharmacy.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Community pharmacy</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Veterinary.png" className="glightbox"><img src="landingmv/img/category/Veterinary.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Veterinary</h4>
									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="lifestyle">
								<div className="tab-header text-center">
									<h3>Lifestyle & Fashion</h3>
								</div>
								<div className="row gy-5">
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Apparel & Clothing.png" className="glightbox"><img src="landingmv/img/category/Apparel & Clothing.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Apparel & Clothing</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Beauty & Cosmetics.png" className="glightbox"><img src="landingmv/img/category/Beauty & Cosmetics.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Beauty & Cosmetics</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Fitness & Athleisure wear.png" className="glightbox"><img src="landingmv/img/category/Fitness & Athleisure wear.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Fitness & Athleisure wear</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Footwear & Accessories.png" className="glightbox"><img src="landingmv/img/category/Footwear & Accessories.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Footwear & Accessories</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Health and wellness.png" className="glightbox"><img src="landingmv/img/category/Health and wellness.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Health and Wellness</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Home decor & Furnishings.png" className="glightbox"><img src="landingmv/img/category/Home decor & Furnishings.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Home decor & Furnishings</h4>
									</div>
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Jewelry & Watches.png" className="glightbox"><img src="landingmv/img/category/Jewelry & Watches.png" className="menu-img img-fluid" alt=""/></a>
										<h4>Jewellery & Watches</h4>
									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="salon">
								<div className="tab-header text-center">
									<h3>Spa's & Salons</h3>
								</div>
								<div className="row gy-5">
									<div className="col-lg-4 menu-item">
										<a href="landingmv/img/category/Beauty salons.png" className="glightbox"><img src="landingmv/img/category/Beauty salons.png" className="menu-img img-fluid" alt=""/></a>
									<h4>Beauty salons</h4>
								</div>
								<div className="col-lg-4 menu-item">
									<a href="landingmv/img/category/Bridal salons.png" className="glightbox"><img src="landingmv/img/category/Bridal salons.png" className="menu-img img-fluid" alt=""/></a>
									<h4>Bridal salons</h4>
								</div>
								<div className="col-lg-4 menu-item">
									<a href="landingmv/img/category/hair salon.png" className="glightbox"><img src="landingmv/img/category/hair salon.png" className="menu-img img-fluid" alt=""/></a>
									<h4>Hair salon</h4>
								</div>
								<div className="col-lg-4 menu-item">
									<a href="landingmv/img/category/SPA.png" className="glightbox"><img src="landingmv/img/category/SPA.png" className="menu-img img-fluid" alt=""/></a>
									<h4>Spa</h4>
								</div>
								<div className="col-lg-4 menu-item">
									<a href="landingmv/img/category/Nail salons.png" className="glightbox"><img src="landingmv/img/category/Nail salons.png" className="menu-img img-fluid" alt=""/></a>
									<h4>Nail salons</h4>
								</div>
								<div className="col-lg-4 menu-item">
									<a href="landingmv/img/category/Barbershops.png" className="glightbox"><img src="landingmv/img/category/Barbershops.png" className="menu-img img-fluid" alt=""/></a>
									<h4>Barbershops</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="stats-new" className="stats-new section">
				<div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<h1 style={{"color":"#ffffff"}}>Download FynOne - the Business Super App, and access FynMart</h1>
						</div>
						<div className="col-lg-6 col-md-6">
							<img src="landingmv/img/google-play-badge.png" alt="FynOne Play Store"></img>
						</div>
					</div>
				</div>
			</section>
			<section id="seller" className="testimonials section">
				<div className="container section-title" data-aos="fade-up">
					<h2>Merchant Benefits</h2>
					<p>Start for <span className="description-title">Free</span>, No commitments</p>
				</div>
				<div className="container" data-aos="fade-up" data-aos-delay="100">
					<p>Step 1: Register for free on <a href={props.link} target="_blank" rel="noreferrer">FynOne</a> - the Super App for Any Business (<a href={props.link} target="_blank" rel="noreferrer">www.FynOne.com</a>)</p>
					<p>Step 2: Enrol into the Free plan that gives exclusive access to FynMart merchant module for Digital store</p>
					<p>Step 3: Start adding inventory, add photos & videos, categorize items</p>
					<p>Step 4: Invite your customers and start selling! We dont charge any commission. Platform fees at just Rs 5 per customer order</p>
					<a href="https://www.youtube.com/watch?v=3_2cZqFaE2Y" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i>&nbsp;<span>Watch Video</span></a>
				</div>
			</section>
		</main>
		<footer id="footer" className="footer">
			<div className="container">
				<div className="row gy-3">
					<div className="col-lg-3 col-md-6 d-flex">
						<i className="bi bi-geo-alt icon"></i>
						<div className="address">
							<h4>Address</h4>
							<p>#302, 3rd Floor, Spearhead Towers,</p>
							<p>Malleswaram, Bangalore</p>
							<p>India - 560055</p>
							<p></p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 d-flex">
						<i className="bi bi-telephone icon"></i>
						<div>
							<h4>Contact</h4>
							<p>
								<strong>Phone:</strong> <span>+91 96633 55662</span><br/>
								<strong>Email:</strong> <span>hello@fynamics.tech</span><br/>
							</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 d-flex">
						<i className="bi bi-clock icon"></i>
						<div>
							<h4>Working Hours</h4>
							<p>
								<strong>Mon-Fri:</strong> <span>10AM - 7PM</span><br/>
							</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 d-flex">
						<div>
							<h4>FynMart - The Community Store</h4>
							<p>
								<strong>A Product of <a href="https://www.fynamics.tech/" target="_blank" rel="noreferrer">Fynamics</a></strong>
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
	</React.Fragment>
	);
}