import React,{useState,useEffect} from 'react';
import Layout from './PartnerLayout';
import NavBar from './PartnerNavBar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import * as Common from '../common/ImportUserEssentials';

export default function AddPartner(props){
	const [verifyOTPShow, setVerifyOTPShow] = useState(false);
	const [otp, setOtp] 			   		= useState('');
	const [user_otp, setUserOtp]       		= useState('');
	const [runTimer,setRunTimer] 	   		= useState(false);
	const [seconds, setSeconds] 	   		= useState(30);
	const [name, setName]         	   		= useState('');
    const [mobile, setMobile]          		= useState('');
    const [email, setEmail]          		= useState('');
	const fromRef 							= React.useRef();
	const [nameInputRO,setNameInputRO] 		= useState(0);
	const [emailInputRO,setEmailInputRO] 	= useState(0);
	const navigate 							= Common.useNavigate();
	React.useEffect(() => {
		if(runTimer){
			const sec_interval = setInterval(() => {
				if (seconds > 0) {
				  setSeconds(seconds - 1);
				}
				if (seconds === 0) {
					clearInterval(sec_interval);
				}
			}, 1000);
			return () => clearInterval(sec_interval);
		}
	}, [seconds, runTimer]);
	useEffect(() => {
		document.title = 'Add Partner | '+props.site_name;
	}, []);
	const setOtpValue = (val) => {
		setOtp(Common.acceptOnlyNumbers(val))
	}
	const submitOTP = () => {
		if( otp.length !== 6 ){
			Common.toast.error('Invalid OTP entered.');
			return false;
		}

        if(otp == user_otp){
			setOtpValue('');
			setVerifyOTPShow(false);
			fromRef.current.requestSubmit();
			return false;
		} else {
			setOtpValue('');
			Common.toast.error('Wrong OTP entered.');
			return false;
		}
	}
	const handleOTPSubmit =(e) => {
        e.preventDefault();
		submitOTP();
    }

	const otpVerification = () => {
		setVerifyOTPShow(true);
		setRunTimer(true);
		let valotp = Math.floor(100000 + Math.random() * 900000);
		setUserOtp(valotp);
		Common.axios({
			method: 'post',
			url:props.url+'partners/sendPartnerOTP',
			data: {'mobile':Common.AES.encrypt(JSON.stringify(mobile), props.site_key).toString(),'email':Common.AES.encrypt(JSON.stringify(email), props.site_key).toString(),'otp':Common.AES.encrypt(JSON.stringify(valotp), props.site_key).toString()},
		}).then(function (response) {
			if(response.data.success){
				Common.toast.success(response.data.message);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
		return false;
	}
	const checkUserExist = (val) => {
		setMobile(val);
		if( val.length === 10 ){
			Common.axios({
				method: 'post',
				url: props.url+'partners/CheckUserExist',
				data: {mobile:Common.AES.encrypt(val,props.site_key).toString()}
			}).then(function(response){
				if( response.data.success ){
					if( response.data.data ){
						setName(response.data.data.uname);
						setEmail(response.data.data.email);
						setNameInputRO(1);
						setEmailInputRO(1);
						return false;
					}else{
						setName('');
						setEmail('');
						setNameInputRO(0);
						setEmailInputRO(0);
						return false;
					}
				}
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
		setName('');
		setEmail('');
		setNameInputRO(0);
		setEmailInputRO(0);
	}
	const CheckPartnerExist = () => {
		if( mobile === '' || mobile === undefined ){
			Common.toast.error('Mobile cannot be left blank.');
			return false;
		}
		if( name === '' || name === undefined ){
			Common.toast.error('Name cannot be left blank.');
			return false;
		}
		if( mobile.length === 10 ){
			Common.axios({
				method: 'post',
				url: props.url+'partners/CheckPartnerExist',
				data: {email:Common.AES.encrypt(email, props.site_key).toString(),mobile:Common.AES.encrypt(mobile,props.site_key).toString()}
			}).then(function(response){
				if( response.data.success ){
					otpVerification();
					return false;
				}
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
	}
	
    const formSubmit = (e)=>{
        e.preventDefault();

        let formData = new FormData(e.target);

        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = Common.AES.encrypt(value, props.site_key).toString();
		}
        Common.axios({
            method: 'post',
            url:props.url+'partners/addPartner',
            data: data,
        }).then(function (response) {
			if(response.data.success){
				Common.toast.success(response.data.message);
				setTimeout(() => {
					navigate("/partners");
				}, 1000);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
	if(otp.length === 6){
		submitOTP();
	}
    return (
        <>
		<Modal show={verifyOTPShow} size="md" aria-labelledby="otp-modal" backdrop="static" keyboard={false} centered>
			<Modal.Body>				
				<div className="row">
					<div className="text-center mb-3">
						<h6>Please enter the one time password <br/> to verify your account</h6>
						<div> <span>A code has been sent to</span> <small>******<span>{mobile !== null && mobile.slice(-4)}</span></small> </div>
						<form autoComplete="off" onSubmit={handleOTPSubmit}>
							<div className="inputs d-flex flex-row justify-content-center mt-2">
							<OtpInput value={otp} onChange={setOtpValue} numInputs={6} shouldAutoFocus={true} inputStyle = {'otpInput m-2 text-center form-control rounded'}
								inputType="tel"
								renderSeparator={<span>-</span>}
								renderInput={(props) => <input {...props}/>}
							/>
							</div>
							<div className="mt-4"> <button type="submit" className="btn btn-primary px-4 validate">Validate</button> </div>
						</form>
					</div>
					<p className="text-center">
						<span>Not your number? </span>
						<Button variant="" className="p-0 text-decoration-underline" onClick={e=>{setVerifyOTPShow(false);setSeconds(30);setRunTimer(false);setOtpValue('')}}>Back</Button>
					</p>
					<p className="text-center">
						{seconds > 0 ? (
						<>Time Remaining: {seconds < 10 ? `0${seconds}` : seconds} secs</>
						) : (
						<>Didn't receive code? <Button variant="" className="p-0 text-decoration-underline" onClick={e=>{setVerifyOTPShow(false);setSeconds(30);setRunTimer(false);setOtpValue('')}}>Close</Button> and try again.</>						
						)}
					</p>
				</div>
			</Modal.Body>
		</Modal>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="add-partner" {...props}/>
                <div className="layout-page">
                    <NavBar {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Partner Details /</span> Add Partner</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
											<form ref={fromRef} onSubmit={formSubmit} autoComplete="off">
												<div className="row">
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="mobile" className="form-label">Mobile<span className="text-danger">*</span></label>
                                                        <div className="input-group input-group-merge">
                                                            <span id="basic-icon-default-fullname2" className="input-group-text"><i className="bx bx-mobile"></i></span>
                                                            <input type="text" className="form-control" id="mobile" name="mobile" placeholder="Enter Mobile" maxLength="10" value={mobile} onChange={(e) => checkUserExist(Common.acceptOnlyNumbers(e.target.value))}  required inputMode="numeric"/>
                                                        </div>
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="name" className="form-label">Name<span className="text-danger">*</span></label>
                                                        <div className="input-group input-group-merge">
                                                            <span className={"input-group-text " + (nameInputRO ? 'disabled-bg':'')}><i className="bx bx-user"></i></span>
															<input type="text" className="form-control" id="name" name="name" placeholder="Enter name" defaultValue={name} onChange={(e)=>setName(e.target.value)} readOnly={(nameInputRO ? true:false)} required/>
                                                        </div>
														<div className="form-text">Name is auto fetched if user is already present in FynOne.</div>
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="name" className="form-label">Email</label>
                                                        <div className="input-group input-group-merge">
                                                            <span className={"input-group-text " + (emailInputRO ? 'disabled-bg':'')}><i className='bx bx-envelope'></i></span>
															<input type="email" className="form-control" id="email" name="email" placeholder="Enter email" defaultValue={email} onChange={(e)=>setEmail(e.target.value)} readOnly={(emailInputRO ? true:false)} />
                                                        </div>
														<div className="form-text">Email is auto fetched if user is already present in FynOne.</div>
                                                    </div>
												</div>
                                                <div className="row mt-3">
													<div className="text-center">
														<a href="/partners" role="button" className="me-3 my-1 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
														<button type='button' onClick={CheckPartnerExist} className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
														<div className="form-text">User will receive sms OTP and the same should be entered by you on clicking Save.</div>
													</div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.PartnerNeedHelp url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}