import React,{useState,useEffect} from 'react';
import * as myConstClass  from './Constant.js';
import * as Common from '../common/ImportUserEssentials';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ReactTimeAgo from 'react-time-ago';
import notiSound from '../sounds/noti-sound.mp3';
//TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(en);
export default function NotificationMartOrder(props){	
	const site_url = props.url;
	let noti_interval = null;

	let audio = new Audio(notiSound);
	const playBeep = () => {
		if(audio.paused === true){
		  audio.play();
		}else{
		  audio.pause();
		  audio.currentTime = 0;
		  audio.play();
		}
	};
	useEffect(() => {
		Common.axios({
			method: 'post',
			url:props.url+'mart/details',
			data: {'pid':Common.AES.encrypt(props.pid, myConstClass.SITE_KEY).toString()},
		}).then(function (response) {
			if( response.data.success && response.data.data !== null && response.data.data.id !== undefined ){
				noti_interval = setInterval(() => {notification(props.pid)},10000);       
				return false;
			}
		}).catch(error => console.log(error));
		return () => clearInterval(noti_interval);
	}, [site_url]);
	const notification = (pid) => {
		Common.axios({
			method: 'post',
			url:site_url+'notifications/fetch',
			data: {'category':'mart-preorder','pid':pid}
		}).then(function (response) {
			if( response.data.success && response.data.data !== null ){
				playBeep();
				Common.toast.custom((t) => (
					<div className="toast-container">
						<div className={"bs-toast toast fade show "+(response.data.data.type === 'success' ? 'bg-success':'bg-dangeer')} role="alert" aria-live="assertive" aria-atomic="true">
							<div className="toast-header">
								<i className="bx bx-bell me-2"></i>
								<div className="me-auto fw-semibold text-truncate" style={{"maxWidth":"190px"}}>{response.data.data.subject}</div>
								<button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
							</div>
							<div className="toast-body py-1">
								<div>{response.data.data.message}</div>
								<div className="text-right">
									<small><ReactTimeAgo date={new Date(response.data.data.createdon)} locale="en-US"/></small>
								</div>
							</div>
					  </div>
					</div>
				),{id:'Noti_'+new Date()+response.data.data.id,duration: Infinity,position: "top-right"});
				return false;
			}
		}).catch(error => console.log(error));
	}
}
{/**/}