import React,{useState,useEffect}  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

export default function UserListing(props){
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
	const [DTpending, setDTPending] = React.useState(true);
    const [users, setUsersList]    = useState([]);
	const [userMgmt_w,setuserMgmt_w] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [exportBtnClass,setExportBtnClass]    = React.useState('d-none');
	const [modules,setModules] = useState([]);
	const [viewDetails,setViewDetails] = React.useState();
	const [veModal, setVEModal] = useState(false);
	const [veModalType,setVEModalType] = useState('');
	const [sortOrder,setSortOrder]    = useState('');
    //const navigate = Common.useNavigate();
    useEffect(() => {
        document.title = 'User Listing| '+props.site_name;
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( Object.keys(userPerm).length > 0 ){
			// Write
			setuserMgmt_w((userPerm[5]['write'] !== undefined && userPerm[5]['write'] === '0') ? false : true);
		}
		loadDataTable(1,perPage,filterText,sortOrder);
    },[props,filterText,perPage,dt_load]);
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,sortOrder);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection));
	};
    const loadDataTable = (page,perPage,filterText,sort='') => {
		Common.axios({
            method: 'post',
            url:props.url+'user/listSub',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),'page':page,'perPage':perPage,'sort':sort,'search':filterText}
        }).then(function (response) {
			setExportBtnClass('d-none');
            if(response.data.success){
                setUsersList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				if(response.data.total > 0){
					setExportBtnClass('');
				}
            }
        }).catch(error => console.log(error));
	}
    /*const editUser =(val) => {
        navigate('/edit-user',
		{
			state: {
				id: val
			}
		});
    }*/
	const veUser =(param,val) => {
		Common.showLoader(true);
		setVEModalType(param);
		/* let subPlanType = 0;
		if (localStorage.getItem('fo_subplan') === 'Basic') {
			subPlanType = 1
		} else if (localStorage.getItem('fo_subplan') === 'Standard') {
			subPlanType = 2;
		} else {
			subPlanType = 3;
		} */
		Common.axios({
			method: 'post',
			url:props.url+'user/fetchModules',
			data: {
				'sub_plan_type': ''
			}
		}).then(function (response) {
			if(response.data.success){
				setModules(response.data.data);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
		Common.axios({
            method: 'post',
            url:props.url+'user/viewDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),'id':val}
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
				setViewDetails(response.data.data);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
        setVEModal(true);
    }
    const columns = [
    	{
            name: 'Action',
            button: true,
            cell: (row) => ( userMgmt_w === true ? <>
	            	<div className="dropdown">
						<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
							<i className="bx bx-dots-vertical-rounded"></i>
						</button>
						<div className="dropdown-menu" >
							<button onClick={(e) => veUser('view',row.id)} className="dropdown-item" ><i className="bx bx-book-reader"></i> View </button>
							{(row.role === 'Sub-User') && <>
							<button onClick={(e) => veUser('edit',row.id)} className="dropdown-item" ><i className="bx bx-edit"></i> Edit </button>
							</>}
						</div>
	              	</div>
            	</>:<></>
            ),
        },
		{
            name: 'Status',
            selector: row => row.status,
            sortable: true,
			sortField: 'status',
			cell: (row) => (
				<BootstrapSwitchButton
					checked={(row.status === '1' )}
					onlabel='Active'
					offlabel='Inactive'
					onstyle="success"
					offstyle="danger"
					size="sm"
					width={250}
					onChange={(checked: boolean) => {
						userStatus(checked,row.id);
					}}
				/>
			)
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
			sortField: 'name'
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: false,
			sortField: 'mobile'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
			sortField: 'email'
        },
		{
            name: 'Role',
            selector: row => row.role,
            sortable: true,
			sortField: 'role'
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on'
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
			sortField: 'created_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on'
        },
		{
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
			sortField: 'updated_by'
        }
    ];

	const updatePerm = (event,val) => {
		let details = viewDetails;
		
		if( details.permissions[val] === undefined ){
			details.permissions[val] = {'view':event.target.checked ? '1':'0','write':event.target.checked ? '1':'0','delete':event.target.checked ? '1':'0'};
		}

		details.permissions[val].view = event.target.checked ? '1' : '0';
		details.permissions[val].write = event.target.checked ? '1' : '0';
		details.permissions[val].delete = event.target.checked ? '1' : '0';
		setViewDetails(details);
		setDtLoad(!dt_load);
	}
	function updateDetails(){
		setVEModal(false);
		Common.axios({
			method: 'post',
			url: props.url+'user/updateDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),'type':'permissions',id:viewDetails.details.id,permissions:viewDetails.permissions}
		}).then(function (response) {
            if(response.data.success){
				Common.toast.success(response.data.message);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const userStatus = (checked,id) => {
		Common.axios({
			method: 'post',
			url: props.url+'user/updateDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),'type':'status',status:checked,id:id}
		}).then(function (response) {
            if(response.data.success){
				Common.toast.success(response.data.message);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const handleExportCSV = (e)=>{
		e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'user/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText},
            // responseType: 'blob',
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message
				return false;
			}
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="user-listing" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
					<NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">User Management /</span> User Listing</h4>
							<div className="row">
								<div className="col-md-6 col-12 listing-tabs mb-1 text-start">
									<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
									{userMgmt_w && (<>
										<Common.Link to="/add-user" className="btn btn-sm btn-primary"> <i className="bx bx-plus"></i>User</Common.Link>
									</>)}
								</div>
								<div className="col-md-6 col-12 text-center text-md-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Name, Mobile, Email')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
                            <div className="row">
                                <div className="col-md-12">
									<DTE.DataTable title={"Users ("+totalDataCnt+")"} className="rdt_AdjDrp" columns={columns} data={users} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal show={veModal} onHide={()=>{setVEModal(false);setViewDetails()}} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title className="text-capitalize">{veModalType} Details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Common.Row>
					<Common.Col><strong>Name : </strong> {viewDetails !== undefined ? viewDetails.details.uname : ''}</Common.Col>
					<Common.Col><strong>Mobile : </strong>{viewDetails !== undefined ? viewDetails.details.mobile : ''} </Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col><strong>Email : </strong>{viewDetails !== undefined ? viewDetails.details.email : ''} </Common.Col>
					<Common.Col><strong>Role : </strong>{viewDetails !== undefined ? viewDetails.details.role : ''} </Common.Col>
				</Common.Row>
				<Common.Row className="mt-2 mb-2">
					<h5 className="text-decoration-underline">Permissions</h5>
						<Common.Col className="ms-sm-5">
						{modules.map((val,index) => (
							<div key={"tr_"+index} className="row mt-1 align-middle justify-content-center">
								{(localStorage.getItem('fo_subplan') === 'Basic' && (val.name === 'Loans & Investments' || val.name === 'Assets Management' || val.name === 'Accounting' || val.name === 'Tax Compliance') ? <>
									<div className="col-8">{val.name}</div>
									<div className="col-4 form-check form-switch">
										<input className="form-check-input" type="checkbox" role="switch" name={"permission_"+val.id+"_v"} checked={((viewDetails !== undefined && viewDetails.details.role === "Co-Owner") || (viewDetails !== undefined && viewDetails.permissions[val.id] !== undefined && viewDetails.permissions[val.id].view === "1")) ? true : false} disabled/>
									</div>
								</> : <>
									{(localStorage.getItem('fo_subplan') === 'Standard' && (val.name === 'Tax Compliance') ? <>
									<div className="col-8">{val.name}</div>
										<div className="col-4 form-check form-switch">
											<input className="form-check-input" type="checkbox" role="switch" name={"permission_"+val.id+"_v"} checked={((viewDetails !== undefined && viewDetails.details.role === "Co-Owner") || (viewDetails !== undefined && viewDetails.permissions[val.id] !== undefined && viewDetails.permissions[val.id].view === "1")) ? true : false} disabled/>
										</div>
									</>:<>
										<div className="col-8">{val.name}</div>
										<div className="col-4 form-check form-switch">
											{(veModalType === 'view') ?
											<input className="form-check-input" type="checkbox" role="switch" name={"permission_"+val.id+"_v"} checked={((viewDetails !== undefined && viewDetails.details.role === "Co-Owner") || (viewDetails !== undefined && viewDetails.permissions[val.id] !== undefined && viewDetails.permissions[val.id].view === "1")) ? true : false} disabled/>
											:
											<input value="1" className="form-check-input" type="checkbox" role="switch" name={"permission_"+val.id+"_v"} checked={((viewDetails !== undefined && viewDetails.details.role === "Co-Owner") || (viewDetails !== undefined && viewDetails.permissions[val.id] !== undefined && viewDetails.permissions[val.id].view === "1"))} onChange={(e)=>{updatePerm(e,val.id)}}/>
											}
										</div>
									</>)}
								</>)}
							</div>
						))}
						</Common.Col>
				</Common.Row>
				<Common.Row>
					<Common.Col>
						<p className="smaller_font text-danger">Note : Any changes in module permissions requires the user to logout and login to reflect the change.</p>
					</Common.Col>
				</Common.Row>
				{(veModalType === 'edit') && <>
				<div className="row mt-5">
					<div className="text-center">
						<button type="button" onClick={updateDetails} className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
					</div>
				</div>
				</>}
			</Modal.Body>
		</Modal>
       </>
    );
}