import React,{useState,useEffect} from 'react';
import axios from "axios";

export default function ChildInput(props){
  
  const [pitemName,setItempName]       = useState(props.itemName);
  const [pqty,setpQty]                 = useState(props.qty);
  const [hsn,setHSN]                   = useState(props.hsn);
  const [punit,setpUnit]               = useState(props.unit);
  const [prate,setpRate]               = useState(props.rate);
  const [units,setUnits]               = useState([]);
  const [ptax,setpTax]                 = useState(props.tax);
  const [pdiscount,setpDiscount]       = useState(props.discount);
  const [ptotalval,setpTotalVal]       = useState(props.totalval);
  const [taxes,setTaxes]               = useState([]);
  const [pFlatdiscount,setpFlatDiscount]= useState(props.discount_flat);
  const [product_len,setProductLen]     = useState(props.len);

  useEffect(() => {
    //get unit
let url = props.url+'sales/getUnits';
axios({
    method: 'get',
    url:url,
}).then(function (response) {
    if(response.data.success){
      setUnits(response.data.data);
    }
}).catch(error => console.log(error));

//get tax
axios({
  method: 'get',
  url:props.url+'ajax/getTaxes',
}).then(function (response) {
  if(response.data.success){
    setTaxes(response.data.data);
  }
}).catch(error => console.log(error));

}, []);

    return <> 
          <li className="d-flex mb-4 pb-1" id={"ia_"+props.len}>
          <div className="row g-2">
            <div className="col mb-0">
              <label htmlFor="emailWithTitle" className="form-label">Item Name</label>
                <input type="text" className="form-control" id="district" name={`pitemName_${props.len}`} placeholder="" value={pitemName} onChange={(e) => setItempName(e.target.value)} />
            </div>
            <div className="col mb-0">
              <label htmlFor="emailWithTitle" className="form-label">Quantity</label>
                <input type="text" className="form-control" id="district" name={`pqty_${props.len}`} placeholder="" value={pqty} onChange={(e) => setpQty(e.target.value)}/>
            </div>
			<div className="col mb-0">
              <label htmlFor="emailWithTitle" className="form-label">HSN</label>
                <input type="text" className="form-control" id="district" name={`hsn_${props.len}`} placeholder="" value={hsn} onChange={(e) => setHSN(e.target.value)}/>
            </div>
            <div className="col mb-0">
              <label htmlFor="emailWithTitle" className="form-label" >Unit</label>
              <select id="defaultSelect" className="form-select" name={`punit_${props.len}`} value={punit} onChange={(e) => setpUnit(e.target.value)}>
              {units.map((val,key) => (
                    <option key={val.id} value={val.id} >{val.code}</option>
                ))}
              </select>
            </div>
            <div className="col mb-0">
              <label htmlFor="emailWithTitle" className="form-label">Price /Unit</label>
                <input type="text" className="form-control" id="prate" name={`prate_${props.len}`} placeholder="1" value={prate} onChange={(e) => setpRate(e.target.value)} />
            </div>
            <div className="col mb-0">
              <label htmlFor="emailWithTitle" className="form-label">Discount%</label>
                <input type="text" className="form-control" id="pdiscount" name={`pdiscount_${props.len}`} placeholder="" value={pdiscount} onChange={(e) => setpDiscount(e.target.value)} />
            </div>
            <div className="col mb-0">
              <label htmlFor="emailWithTitle" className="form-label">Discount(Flat)</label>
                <input type="text" className="form-control" id="pflatdiscount" name={`pflatdiscount_${props.len}`} placeholder="" value={pFlatdiscount} onChange={(e) => setpFlatDiscount(e.target.value)} />
            </div>
            <div className="col mb-0">
                <label htmlFor="emailWithTitle" className="form-label">Tax</label>
                <select id="defaultSelect" className="form-select" name={`tax_${props.len}`} value={ptax} onChange={(e) => setpTax(e.target.value,e.target[e.target.value-1].text)}>
                    {taxes.map((val,key) => (
                    <option key={val.id} value={val.id} >{val.rate}</option>
                    ))}
                </select>
            </div>
            <div className="col mb-0">
              <label htmlFor="emailWithTitle" className="form-label">Total Value</label>
              <input type="text" className="form-control" id="totalval" name={`totalval_${props.len}`} placeholder="" value={ptotalval}  onChange={(e) => setpTotalVal(e.target.value)} readOnly/>
           <input type="hidden" name='product_len' value={product_len} onChange={(e) => setProductLen(e.target.value)} />
            </div>
			<div className="col mb-0">
				<label htmlFor="emailWithTitle" className="form-label">&nbsp;&nbsp;</label><br/>
				<button type="button" className="btn btn-sm btn-danger" onClick={(e) => e.target.closest('#ia_'+props.len).remove()}><i className='bx bx-trash'></i></button>
			</div>
          </div>
          </li>
  </>;
}