import React  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import styled from 'styled-components';
// import { MultiSelect } from "react-multi-select-component";
import Button from 'react-bootstrap/Button';
import '../styles/transaction.css';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import Multiselect from 'multiselect-react-dropdown';

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;

export default function Transactions(props){
	
	const [filterText, setFilterText] = React.useState('');
	const [DTpending, setDTPending] = React.useState(true);
	const [dt_load,setDtLoad] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [totalDataCnt,setTotalDataCount] = React.useState({'Income and Collections':'','Expense and Payments':'','Asset':'','Liability':'','Equity':''});
	const tabTypes = ['Income and Collections', 'Expense and Payments', 'Asset', 'Liability', 'Equity'];
	const [activeTab, setActiveTab] = React.useState(tabTypes[0]);
	const [transactionList, setTransactionList] = React.useState([]);
    const [exportBtnClass,setExportBtnClass] = React.useState('d-none');
	const [transactions_r,setTransactions_r] = React.useState(true);
	const [transactions_w,setTransactions_w] = React.useState(true);
	const [allocation_centre_selected, setAllocationCentreSelected] = React.useState([]);
	// const [income_type_selected, setIncomeTypeSelected] = React.useState([]);
	const [income_subtype_selected, setIncomeSubtypeSelected] = React.useState([]);
	const [allocation_centre_id, setAllocationCentreId] = React.useState([]);
	const [typeId, setTypeId] = React.useState([]);
	const [subtypeId, setSubtypeId] = React.useState([]);
	const [allocation_centre_master,setAllocationCentreMaster] = React.useState([]);
	const [income_type_master, setIncomeTypeMaster] = React.useState([]);
	const [incomeSubtypeMaster, setIncomeSubTypeMaster] = React.useState([]);
	const [allocation_centre, setAllocationCentre] = React.useState('');
	const [transactionDetails, setTransactionDetails] = React.useState([]);
	const [allocation_centre_default,setAllocationCentreDefault] = React.useState([]);

	const [incomeType, setIncomeType] = React.useState('');
	const [incomeTypeDefault,setIncomeTypeDefault] = React.useState([]);

	const [incomeSubType, setIncomeSubType] = React.useState('');
	const [incomeSubTypeDefault,setIncomeSubTypeDefault] = React.useState([]);
	const [income_subtype_master, setIncome_subtype_master] = React.useState([]);

	// const [columnType, setColumnType] = React.useState('');
	const [columnTypeModalShow,setColumnTypeModalShow] = React.useState(false);

	const [headerType, setHeaderType] = React.useState('');
	const [headerTypeModalShow,setHeaderTypeModalShow] = React.useState(false);
	const [headerAllocationCentre, setHeaderAllocationCentre] = React.useState('');
	const [headerSubType,setHeaderSubType] = React.useState(false);

	// Purchase
	
	const [pur_allocation_centre_selected, setPurAllocationCentreSelected] = React.useState([]);
	// const [expense_type_selected, setExpenseTypeSelected] = React.useState([]);
	const [expense_subtype_selected, setExpenseSubtypeSelected] = React.useState([]);
	const [expense_type_master, setExpenseTypeMaster] = React.useState([]);
	const [expenseSubtypeMaster, setExpenseSubTypeMaster] = React.useState([]);
	const [expenseType, setExpenseType] = React.useState('');
	const [expenseTypeDefault,setExpenseTypeDefault] = React.useState([]);
	const [expenseSubType, setExpenseSubType] = React.useState('');
	const [expenseSubTypeDefault,setExpenseSubTypeDefault] = React.useState([]);
	const [expense_subtype_master, setExpense_subtype_master] = React.useState([]);
	const [purColumnTypeModalShow,setPurColumnTypeModalShow] = React.useState(false);
	const [pur_allocation_centre_default,setPurAllocationCentreDefault] = React.useState([]);
	const [pur_allocation_centre, setPurAllocationCentre] = React.useState('');
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [stateClear, setStateClear] = React.useState({ toggledClearRows: false });

	const [purHeaderType, setPurHeaderType] = React.useState('');
	const [purHeaderTypeModalShow,setPurHeaderTypeModalShow] = React.useState(false);
	const [purHeaderAllocationCentre, setPurHeaderAllocationCentre] = React.useState('');
	const [purHeaderSubType,setPurHeaderSubType] = React.useState(false);
	const [sortOrder, setSortOrder] = React.useState('');

	const navigate = Common.useNavigate();
	
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
    const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
	let state  = Common.useLocation();

    React.useEffect(() => {
        document.title = 'Transactions Listing | '+props.site_name;
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Read permission
			setTransactions_r((userPerm[12]['view'] !== undefined && userPerm[12]['view'] === '0') ? false : true);
		}
		if( Object.keys(userPerm).length > 0 ){
			setTransactions_w((userPerm[12]['write'] !== undefined && userPerm[12]['write'] === '0') ? false : true);
		}
		if (localStorage.getItem('fo_subplan') === 'Basic') {
			navigate("/pricing-plan");
		}
		
		//get allocation centre
		getAllocationCentreData();
		// Income Types Master
		getIncomeTypeData();

		// Expense Types Master
		getExpenseTypeData();

		loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,allocation_centre_id,typeId,subtypeId);

	}, [props,dt_load,filterText]);
	if( !transactions_r ){
		navigate("/not-authorized");
	}
	const getAllocationCentreData = () => {
		//get allocation centre
		Common.axios({
			method: 'get',
			url:props.url+'transactions/getAllocationCentre/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			console.log(response.data.data);
			if(response.data.success){
				setAllocationCentreMaster(response.data.data);
			}
		}).catch(error => console.log(error));
	}
	const getIncomeTypeData = (type='all') => {
		Common.axios({
			method: 'get',
			url: props.url+'transactions/fetch_income_types/'+type,
		}).then(function (response) {
			if( response.data.success ){
				// setIncomeTypeMaster(response.data.data);
				setIncomeTypeMaster(response.data.data);
			}
		}).catch(error => console.log(error));
	}
	const getExpenseTypeData = (type='all') => {
		Common.axios({
			method: 'get',
			url: props.url+'transactions/fetch_expense_types/'+type,
		}).then(function (response) {
			if( response.data.success ){
				// setExpenseTypeMaster(response.data.data);
				setExpenseTypeMaster(response.data.data);
			}
		}).catch(error => console.log(error));
	}
	const loadDataTable = (page,perPage,filterText,dates,sort='',active_tab=activeTab,allocationCentreId,incomeTypeId,incomeSubtypeid) => {
		// Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'transactions/listing/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'dates':dates,'search':filterText,'active_tab':active_tab,'allocationCentreId':allocationCentreId,'typeId':incomeTypeId,'subtypeid':incomeSubtypeid}
        }).then(function (response) {
			setExportBtnClass('d-none');
			// Common.showLoader(false);
            if(response.data.success){
				setTransactionList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(prevState => ({
				...prevState,
					[active_tab]: response.data.total
				}));
				if(response.data.data.length > 0){
					setExportBtnClass('d-block');
				}
				setDTPending(false);
            }
        }).catch(error => console.log(error));
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,dates,sortOrder,activeTab,allocation_centre_id,typeId,subtypeId);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText,dates,sortOrder,activeTab,allocation_centre_id,typeId,subtypeId);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,dates,btoa(column.sortField+'####'+sortDirection),activeTab,allocation_centre_id,typeId,subtypeId);
	};
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
    const saleColumns = [
		{
			name: 'Action',
			button: true,
			cell: (row) => (
				<div className="dropdown">
				<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
				<i className="bx bx-dots-vertical-rounded"></i>
				</button>
				<div className="dropdown-menu" >
					<button className="dropdown-item" onClick={(e) => columnTypeModal(row)}><i className='bx bxs-edit'></i> Edit</button>
				</div>
			</div>
			)
		},
    	{
            name: 'Document Number',
            sortable: true,
			sortField: 'inv_no',
			cell: (row) => (
				row.inv_no
			)
        },
        {
            name: 'Document Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				row.inv_date
			)
        },
		{
            name: 'Customer Name',
            selector: row => row.customer_name,
            sortable: false,
			sortField: 'customer_name',
			cell: (row) => (
				row.customer_name
			)
        },
		{
            name: 'Taxable Amount',
            selector: row => row.taxable_amount,
            sortable: true,
			sortField: 'taxable_amount',
			cell: (row) => (
				row.taxable_amount
			)
        },
		{
            name: 'Tax Amount',
            selector: row => row.total_tax,
            sortable: true,
			sortField: 'total_tax',
			cell: (row) => (
				row.total_tax
			)
        },
		{
            name: 'Total Amount',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt',
			cell: (row) => (
				row.total_amt
			)
        },
		{
            name: 'Allocation Center',
            selector: row => row.allocation_centre,
            sortable: false,
			sortField: 'allocation_centre',
			cell: (row) => (
				row.allocation_centre
			)
        },
		{
            name: 'Income type',
            selector: row => row.income_type,
            sortable: false,
			sortField: 'income_type',
			cell: (row) => (
				row.income_type
			)
        },
		{
            name: 'Income sub type',
            selector: row => row.income_subtype,
            sortable: false,
			sortField: 'income_subtype',
			cell: (row) => (
				row.income_subtype
			)
        },
		{
            name: 'Type',
            selector: row => row.type,
            sortable: false,
			sortField: 'type',
			cell: (row) => (
				row.type
			)
        }

    ];
	const purchaseColumns = [
		{
			name: 'Action',
			button: true,
			cell: (row) => (
				<div className="dropdown">
				<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
				<i className="bx bx-dots-vertical-rounded"></i>
				</button>
				<div className="dropdown-menu" >
					<button className="dropdown-item" onClick={(e) => purchaseColumnTypeModal(row)}><i className='bx bxs-edit'></i> Edit</button>
				</div>
			</div>
			)
		},
    	{
            name: 'Document Number',
            sortable: true,
			sortField: 'p.doc_no',
			cell: (row) => (
				row.inv_no
			)
        },
        {
            name: 'Document Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'p.doc_date',
			cell: (row) => (
				row.inv_date
			)
        },
		{
            name: 'Seller Name',
            selector: row => row.seller_name,
            sortable: false,
			sortField: 'seller_name',
			cell: (row) => (
				row.seller_name
			)
        },
		{
            name: 'Taxable Amount',
            selector: row => row.taxable_amount,
            sortable: true,
			sortField: 'p.total_taxable_amt',
			cell: (row) => (
				row.taxable_amount
			)
        },
		{
            name: 'Tax Amount',
            selector: row => row.total_tax,
            sortable: true,
			sortField: 'total_tax',
			cell: (row) => (
				row.total_tax
			)
        },
		{
            name: 'Total Amount',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt',
			cell: (row) => (
				row.total_amt
			)
        },
		{
            name: 'Allocation Center',
            selector: row => row.allocation_centre,
            sortable: false,
			sortField: 'allocation_centre',
			cell: (row) => (
				row.allocation_centre
			)
        },
		{
            name: 'Expenses type',
            selector: row => row.expenses_type,
            sortable: false,
			sortField: 'expenses_type',
			cell: (row) => (
				row.expenses_type
			)
        },
		{
            name: 'Expenses sub type',
            selector: row => row.expenses_subtype,
            sortable: false,
			sortField: 'expenses_subtype',
			cell: (row) => (
				row.expenses_subtype
			)
        },
		{
            name: 'Type',
            selector: row => row.type,
            sortable: false,
			sortField: 'type',
			cell: (row) => (
				row.type
			)
        }

    ];
	const assetColumns = [
    	{
            name: 'Receipient name',
            sortable: false,
			sortField: 'loan_recipient',
			cell: (row) => (
				row.loan_recipient
			)
        },
        {
            name: 'Loan amount',
            selector: row => row.loan_amt,
            sortable: true,
			sortField: 'loan_amt',
			cell: (row) => (
				row.loan_amt
			)
        },
		{
            name: 'Date of loan',
            selector: row => row.loan_given_date,
            sortable: true,
			sortField: 'loan_given_date',
			cell: (row) => (
				row.loan_given_date
			)
        },
		{
            name: 'Total Loan period',
            selector: row => row.total_loan_period,
            sortable: true,
			sortField: 'total_loan_period',
			cell: (row) => (
				row.total_loan_period
			)
        },
		{
            name: 'Rate of ineteret',
            selector: row => row.rate_of_interest,
            sortable: true,
			sortField: 'rate_of_interest',
			cell: (row) => (
				row.rate_of_interest
			)
        },
		{
            name: 'Total loan amount',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt',
			cell: (row) => (
				row.total_amt
			)
        }
    ];
	const liabilityColumns = [
    	{
            name: 'Provider name',
            sortable: false,
			sortField: 'loan_provider',
			cell: (row) => (
				row.loan_provider
			)
        },
        {
            name: 'Loan amount',
            selector: row => row.loan_amt,
            sortable: true,
			sortField: 'loan_amt',
			cell: (row) => (
				row.loan_amt
			)
        },
		{
            name: 'Date of loan',
            selector: row => row.loan_taken_date,
            sortable: true,
			sortField: 'loan_taken_date',
			cell: (row) => (
				row.loan_taken_date
			)
        },
		{
            name: 'Total Loan period',
            selector: row => row.total_loan_period,
            sortable: true,
			sortField: 'total_loan_period',
			cell: (row) => (
				row.total_loan_period
			)
        },
		{
            name: 'Rate of ineteret',
            selector: row => row.rate_of_interest,
            sortable: true,
			sortField: 'rate_of_interest',
			cell: (row) => (
				row.rate_of_interest
			)
        },
		{
            name: 'Total loan amount',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt',
			cell: (row) => (
				row.total_amt
			)
        }
    ];
	const equityColumns = [
    	{
            name: 'Shareholder name',
            sortable: false,
			sortField: 'shareholder',
			cell: (row) => (
				row.shareholder
			)
        },
        {
            name: 'Investment date',
            selector: row => row.investment_date,
            sortable: true,
			sortField: 'investment_date',
			cell: (row) => (
				row.investment_date
			)
        },
		{
            name: 'Investment amount',
            selector: row => row.investment_amt,
            sortable: true,
			sortField: 'investment_amt',
			cell: (row) => (
				row.investment_amt
			)
        },
		{
            name: 'Premiun amount',
            selector: row => row.premium_amt,
            sortable: true,
			sortField: 'premium_amt',
			cell: (row) => (
				row.premium_amt
			)
        }
    ];
	const setDatePicker = (start, end) => {
		setDates({ start, end });
		let dates_range = {
			start: start,
			end: end,
		}
		loadDataTable(1,perPage,filterText,dates_range,sortOrder,activeTab,allocation_centre_id,typeId,subtypeId);
	}
	const columnTypeModal = (details) => {
		if(details.type === 'Collection'){
			Common.toast.error('This Record Cannot be edited.');
			return false;
		}
		setAllocationCentreDefault([]);
		setIncomeTypeDefault([]);
		setIncomeSubTypeDefault([]);
		getIncomeTypeData(details.type);
		if(details.acid !== '0'){
			setAllocationCentre(details.allocation_centre);
			setAllocationCentreDefault((details.acid !== '0') ? [{'value':details.acid,'label':details.allocation_centre,'digits':''}] : []);
		}
		if(details.income_type_id !=='0'){
			setIncomeType(details.income_type_id);
			setIncomeTypeDefault((details.income_type_id !=='0')?[{'value':details.income_type_id,'label':details.income_type,'digits':''}]:[]);
		}
		if(details.income_type_id !== '0' && details.income_sub_type_id !=='0'){
			let ids = [];
			if(details.income_type_id !== '0'){
				ids.push(details.income_type_id);
			}
			setIncomeSubType(details.income_sub_type_id);
			if(ids.length > 0){
				setIncomeSubTypeDefault((details.income_sub_type_id !=='0')?[{'value':details.income_sub_type_id,'label':details.income_subtype,'digits':''}]:[]);
				getIncomesubTypeMaster(ids,2);
			}else{
				setIncome_subtype_master([]);
				setIncomeSubTypeDefault([]);
			}
		}
        setTransactionDetails(details);
        setColumnTypeModalShow(true);
    }
		
	const setAllocationCentreSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setAllocationCentre(value);
		if (value === '') {
			setAllocationCentreDefault([]);
		} else {
			setAllocationCentreDefault(e)
		}
	}
	const setIncomeTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let id = (e[0]) ? e[0].value : '';
		setIncomeType(value);
		if (value === '') {
			setIncomeTypeDefault([]);
		} else {
			setIncomeTypeDefault(e)
		}
		setIncome_subtype_master([]);
		setIncomeSubTypeDefault([]);
		let ids = [];
		ids.push(id);
		getIncomesubTypeMaster(ids,2);
	}
	const setIncomeSubTypeSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setIncomeSubType(value);
		if (value === '') {
			setIncomeSubTypeDefault([]);
		} else {
			setIncomeSubTypeDefault(e)
		}
	}
	const handleColumnType = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'transactions/sale_filter_update',
			data: {'pid': localStorage.getItem('fo_profileid'),'id':transactionDetails.id,'type':transactionDetails.type,'allocation_centre':allocation_centre,'incomeType':incomeType,'incomeSubType':incomeSubType},
        }).then(function (response) {
			if(response.data.success){
                setColumnTypeModalShow(false);
				setAllocationCentre('');
				setIncomeType('');
				setIncomeSubType('');
				setAllocationCentreDefault([]);
				setIncomeTypeDefault([]);
				setIncomeSubTypeDefault([]);
				getAllocationCentreData();
				getIncomeTypeData();
				loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,allocation_centre_id,typeId,subtypeId);
			}
        }).catch(error => console.log(error));
    }

	const headerTypeModal = (type) => {
		setHeaderType(type);
		if(type === 2){
			getIncomesubTypeMaster(typeId,2);
		}
        setHeaderTypeModalShow(true);
    }
	const setHeaderAllocationCentreSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setHeaderAllocationCentre(value);
	}
	const setHeaderIncomeSubTypeSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setHeaderSubType(value);
	}
	const handleHeaderType = (e)=>{
        e.preventDefault();
		let type_id = headerSubType;
		let ids = subtypeId;
		if(headerType === 1){
			type_id = headerAllocationCentre;
			ids = allocation_centre_id;
		}
        Common.axios({
            method: 'post',
            url:props.url+'transactions/sale_main_filter_update',
			data: {'pid': localStorage.getItem('fo_profileid'),'type_id':type_id,'header_type':headerType,'search':filterText,'activeTab':activeTab,'dates':dates,'ids':ids,'typeId':typeId},
        }).then(function (response) {
			if(response.data.success){
                setHeaderTypeModalShow(false);
				setHeaderAllocationCentre('');
				setHeaderSubType('');
				window.location.reload();
			}
        }).catch(error => console.log(error));
    }
	const handleExportCSV = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'transactions/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'dates':dates,'search':filterText,'active_tab':activeTab,'allocation_centre_id':allocation_centre_id,'typeId':typeId,'subtypeId':subtypeId},
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
			}
        }).catch(error => console.log(error));
    }
	const [showSalesDiv,setShowSalesDiv] 		 = React.useState(true);
    const [showPurchasesDiv,setShowPurchasesDiv] = React.useState(false);
    const [showAssetDiv,setShowAssetDiv] 		 = React.useState(false);
    const [showLiabilityDiv,setShowLiabilityDiv] = React.useState(false);
    const [showEquityDiv,setShowEquityDiv] 		 = React.useState(false);
	function TabGroup() {
		const handleTab = bankChecked => {
			setAllocationCentreSelected([]);
			setPurAllocationCentreSelected([]);
			setIncomeSubtypeSelected([]);
			setExpenseSubtypeSelected([]);
			setAllocationCentreId([]);
			setSubtypeId([]);
			setTypeId([]);
			setExportBtnClass('d-none');
			setActiveTab(bankChecked);
			state.state = null
			setSortOrder('')
			loadDataTable(1,perPage,filterText,dates,'',bankChecked,[],[],[]);
			if(bankChecked === "Income and Collections"){
				setShowSalesDiv(true);
				setShowPurchasesDiv(false);
				setShowAssetDiv(false);
				setShowLiabilityDiv(false);
				setShowEquityDiv(false);
			}else if(bankChecked === "Expense and Payments"){
				setShowPurchasesDiv(true);
				setShowSalesDiv(false);
				setShowAssetDiv(false);
				setShowLiabilityDiv(false);
				setShowEquityDiv(false);
			}else if(bankChecked === "Asset"){
				setShowPurchasesDiv(false);
				setShowSalesDiv(false);
				setShowAssetDiv(true);
				setShowLiabilityDiv(false);
				setShowEquityDiv(false);
			}else if(bankChecked === "Liability"){
				setShowPurchasesDiv(false);
				setShowSalesDiv(false);
				setShowAssetDiv(false);
				setShowLiabilityDiv(true);
				setShowEquityDiv(false);
			}else{
				setShowPurchasesDiv(false);
				setShowSalesDiv(false);
				setShowAssetDiv(false);
				setShowLiabilityDiv(false);
				setShowEquityDiv(true);
			}
		};
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{tabTypes.map(type => (
				<Tab
					key={type}
					active={activeTab === type}
					onClick={() => handleTab(type)}
				>
					{(totalDataCnt[type] !== '') ? type + ' ('+totalDataCnt[type]+')' : type}
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
	const getAllocationCentreSelected = (e) => {
		let ids = [];
		e.map((val,index) => (
			ids.push(val.value)
		));
		setAllocationCentreId(ids);
		setAllocationCentreSelected(e);
		loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,ids,typeId,subtypeId);
	}
	const getIncomeTypeSelected = (e) => {
		let ids = [];
		e.map((val,index) => (
			ids.push(val.value)
		));
		setTypeId(ids);
		// setIncomeTypeSelected(e);
		loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,allocation_centre_id,ids,subtypeId);
		if(ids.length > 0){
			getIncomesubTypeMaster(ids,1);
		}else{
			setIncomeSubTypeMaster([]);
		}
	}
	const getIncomeSubtypeSelected = (e) => {
		let ids = [];
		e.map((val,index) => (
			ids.push(val.value)
		))
		setSubtypeId(ids);
		setIncomeSubtypeSelected(e);
		loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,allocation_centre_id,typeId,ids);
	}
	const getIncomesubTypeMaster = (ids,type) => {
		// Income Sub Types Master
		Common.axios({
			method: 'post',
			url: props.url+'transactions/fetch_income_subtypes',
			data: {
				'pid': localStorage.getItem('fo_profileid'),
				'income_type_master_id':ids
			}
		}).then(function (response) {
			if( response.data.success ){
				if(type === 1){
					// setIncomeSubTypeMaster(response.data.data);
					setIncomeSubTypeMaster(response.data.data);
				}else{
					setIncome_subtype_master(response.data.data);
				}
			}
		}).catch(error => console.log(error));
	}
	// Purchase

	const getPurAllocationCentreSelected = (e) => {
		let ids = [];
		e.map((val,index) => (
			ids.push(val.value)
		));
		setAllocationCentreId(ids);
		setPurAllocationCentreSelected(e);
		loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,ids,typeId,subtypeId);
	}
	const getExpenseTypeSelected = (e) => {
		let ids = [];
		e.map((val,index) => (
			ids.push(val.value)
		));
		setTypeId(ids);
		// setExpenseTypeSelected(e);
		loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,allocation_centre_id,ids,subtypeId);
		getExpenseSubTypeMaster(ids,1);
	}
	const getExpenseSubtypeSelected = (e) => {
		let ids = [];
		e.map((val,index) => (
			ids.push(val.value)
		));
		setSubtypeId(ids);
		setExpenseSubtypeSelected(e);
		loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,allocation_centre_id,typeId,ids);
	}
	const getExpenseSubTypeMaster = (ids,type) => {
		// Expense Sub Types Master
		Common.axios({
			method: 'post',
			url: props.url+'transactions/fetch_expense_subtypes',
			data: {
				'pid': localStorage.getItem('fo_profileid'),
				'expense_type_master_id':ids
			}
		}).then(function (response) {
			if( response.data.success ){
				if(type === 1){
					// setExpenseSubTypeMaster(response.data.data);
					setExpenseSubTypeMaster(response.data.data);
				}else{
					setExpense_subtype_master(response.data.data);
				}
			}
		}).catch(error => console.log(error));
	}

	const purchaseColumnTypeModal = (details) => {
		if(details.type === 'Payable'){
			Common.toast.error('This Record Cannot be edited.');
			return false;
		}
		setPurAllocationCentreDefault([]);
		setExpenseTypeDefault([]);
		setExpenseSubTypeDefault([]);
		getExpenseTypeData(details.type);
		if(details.acid !== '0'){
			setPurAllocationCentre(details.allocation_centre);
			setPurAllocationCentreDefault((details.acid !== '0') ? [{'value':details.acid,'label':details.allocation_centre,'digits':''}] : []);
		}
		if(details.expense_type_id !=='0'){
			setExpenseType(details.expense_type_id);
			setExpenseTypeDefault((details.expense_type_id !=='0')?[{'value':details.expense_type_id,'label':details.expenses_type,'digits':''}]:[]);
		}
		if(details.expense_type_id !=='0' ){
			let ids = [];
			if(details.expense_type_id !== '0'){
				ids.push(details.expense_type_id);
			}
			setExpenseSubType(details.expense_sub_type_id);
			if(ids.length > 0){
				setExpenseSubTypeDefault((details.expense_sub_type_id !=='0')?[{'value':details.expense_sub_type_id,'label':details.expenses_subtype,'digits':''}]:[]);
				getExpenseSubTypeMaster(ids,2);
			}else{
				setIncome_subtype_master([]);
				setIncomeSubTypeDefault([]);
			}
		}
        setTransactionDetails(details);
        setPurColumnTypeModalShow(true);
    }
	const handlePurchaseColumnType = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'transactions/purchase_filter_update',
			data: {'pid': localStorage.getItem('fo_profileid'),'id':transactionDetails.id,'type':transactionDetails.type,'allocation_centre':pur_allocation_centre,'expenseType':expenseType,'expenseSubType':expenseSubType},
        }).then(function (response) {
			if(response.data.success){
                setPurColumnTypeModalShow(false);
				setPurAllocationCentre('');
				setExpenseType('');
				setExpenseSubType('');
				setPurAllocationCentreDefault([]);
				setExpenseTypeDefault([]);
				setExpenseSubTypeDefault([]);
				getAllocationCentreData();
				getExpenseTypeData();
				loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,allocation_centre_id,typeId,subtypeId);
			}
        }).catch(error => console.log(error));
    }
	const setPurAllocationCentreSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setPurAllocationCentre(value);
		if (value === '') {
			setPurAllocationCentreDefault([]);
		} else {
			setPurAllocationCentreDefault(e)
		}
	}
	const setExpenseTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let id = (e[0]) ? e[0].value : '';
		setExpenseType(value);
		if (value === '') {
			setExpenseTypeDefault([]);
		} else {
			setExpenseTypeDefault(e)
		}
		setExpense_subtype_master([]);
		setExpenseSubTypeDefault([]);
		let ids = [];
		ids.push(id);
		getExpenseSubTypeMaster(ids,2);
	}
	const setExpenseSubTypeSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setExpenseSubType(value);
		if (value === '') {
			setExpenseSubTypeDefault([]);
		} else {
			setExpenseSubTypeDefault(e)
		}
	}
	const purHeaderTypeModal = (type) => {
		setPurHeaderType(type);		
		getExpenseSubTypeMaster(typeId,2);
        setPurHeaderTypeModalShow(true);
    }
	const setPurHeaderAllocationCentreSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setPurHeaderAllocationCentre(value);
	}
	const setPurHeaderExpenseSubTypeSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setPurHeaderSubType(value);
	}
	const handlePurHeaderType = (e)=>{
        e.preventDefault();
		let type_id = purHeaderSubType;
		let ids = subtypeId;
		if(purHeaderType === 1){
			type_id = purHeaderAllocationCentre;
			ids = allocation_centre_id;
		}
        Common.axios({
            method: 'post',
            url:props.url+'transactions/purchase_main_filter_update',
			data: {'type_id':type_id,'header_type':purHeaderType,'pid':localStorage.getItem('fo_profileid'),'search':filterText,'activeTab':activeTab,'dates':dates,'ids':ids,'typeId':typeId},
        }).then(function (response) {
			if(response.data.success){
                setPurHeaderTypeModalShow(false);
				setPurHeaderAllocationCentre('');
				setPurHeaderSubType('');
				window.location.reload();
			}
        }).catch(error => console.log(error));
    }
	const handleChange = (state) => {
		let datas = state.selectedRows;
		let res = [];
		datas.map((val,index) => (
			res[index] = {'id':val.id,'type':val.type}
		))
		setSelectedRows(res);
	};
	
	const handleChartOfAccount = () => {
		Common.showLoader(true);
		let url = props.url+'transactions/chart_of_account';
		Common.axios({
			method: 'POST',
			url:url,
			data:{data:selectedRows,'pid':localStorage.getItem('fo_profileid')},
		}).then(function (response) {
			Common.showLoader(false);
			Common.toast.dismiss();
			if(response.data.success){
				// loadDataTable(1,perPage,filterText,dates);
				loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,allocation_centre_id,typeId,subtypeId);
				Common.toast.success(response.data.message, {duration: 6000});
				handleClearRows();
			}
			else{
				Common.toast.error(response.data.message);
			}
		}).catch(error => {console.log(error);Common.showLoader(false);});
	}
	const contextActions = React.useMemo(() => {
		return (
			<div>
				<Button variant="info" size="sm" key="Post to Chart of Accounts" onClick={handleChartOfAccount}>
					Post to Chart of Accounts
				</Button>
			</div>
		);
	}, [selectedRows]);
	const handleClearRows = () => {
		setStateClear({ toggledClearRows: !stateClear.toggledClearRows})
	}
    return (
		<>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="transactions" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Accounting /</span> Unposted Transactions</h4>
							<div className="row ">
								<div className="col-sm-12">
									<div className="row pb-3">
										<div className="col-md-10">
											<TabGroup/>
										</div>
									</div>
								</div>
								<div id="sdt_cont" className="col-sm-12">
									{showSalesDiv && (<>
										{ transactions_w && (<>
											<div className="row">
												<div className="col-md-3 col-12 listing-tabs mb-1">
													<div className="btn-group btn-group-sm" role="group">
														<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
															<span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
															<i className="bx bx-download d-block d-sm-none"></i>
														</button>
													</div>
												</div>
												<div className="col-md-5 col-12 text-center mb-1">
													<small>Unposted Transaction</small>
													<DateRangePicker
														initialSettings={DTE.DatePickerPredefined(moment,start,end)}
														onCallback={setDatePicker}>
														<div className="input-group input-group-sm">
															<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
															<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
																<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
															</div>
														</div>
													</DateRangePicker>
													<small>Date range based on Document Date</small>
												</div>
												<div className="col-md-4 col-12 text-center text-md-end mb-1">
													<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Document Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
													<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
												</div>
											</div>
											<div className="row">
												<div className="col-md-3">
													<Multiselect
														displayValue="label"
														onKeyPressFn={function noRefCheck(){}}
														onRemove={(e) => getAllocationCentreSelected(e)}
														onSearch={function noRefCheck(){}}
														onSelect={(e) => getAllocationCentreSelected(e)}
														options={allocation_centre_master}
														placeholder="Select Allocation Centre"
														showArrow
														showCheckbox
													/>
												</div>
												<div className="col-md-1">
													{allocation_centre_id.length === 1 && <>
														<span role="button" onClick={(e)=>headerTypeModal(1)}><i className='bx bx-edit-alt fs-4'></i></span>
													</>}
												</div>
												<div className="col-md-3">
													<Multiselect
														displayValue="label"
														groupBy="group_name"
														onKeyPressFn={function noRefCheck(){}}
														onRemove={(e) => getIncomeTypeSelected(e)}
														onSearch={function noRefCheck(){}}
														onSelect={(e) => getIncomeTypeSelected(e)}
														options={income_type_master}
														placeholder="Select Income Type"
														showArrow
														showCheckbox
													/>
												</div>
												<div className="col-md-1">
												</div>
												{typeId.length === 1 && typeId[0] !== '0' && <>
												<div className="col-md-3">
													<Multiselect
														displayValue="label"
														groupBy="group_name"
														onKeyPressFn={function noRefCheck(){}}
														onRemove={(e) => getIncomeSubtypeSelected(e)}
														onSearch={function noRefCheck(){}}
														onSelect={(e) => getIncomeSubtypeSelected(e)}
														options={incomeSubtypeMaster}
														placeholder="Select Income Subtype"
														showArrow
														showCheckbox
													/>
												</div>
												<div className="col-md-1">
													{subtypeId.length === 1 && <>
														<span role="button" onClick={(e)=>headerTypeModal(2)}><i className='bx bx-edit-alt fs-4'></i></span>
													</>}
												</div>
												</>}
											</div>
										</>)}
										<DTE.DataTable title="Income and Collections" className="transaction_dataTables rdt_AdjDrp" selectableRows selectableRowsHighlight selectableRowsVisibleOnly onSelectedRowsChange={handleChange} columns={saleColumns} data={transactionList} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} contextActions={contextActions} clearSelectedRows={stateClear.toggledClearRows}/>
									</>)}
									{showPurchasesDiv && (<>
										{ transactions_w && (<>
											<div className="row">
												<div className="col-md-3 col-12 listing-tabs mb-1">
													<div className="btn-group btn-group-sm" role="group">
														<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
															<span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
															<i className="bx bx-download d-block d-sm-none"></i>
														</button>
													</div>
												</div>
												<div className="col-md-5 col-12 text-center mb-1">
													<small>Unposted Transaction</small>
													<DateRangePicker
														initialSettings={DTE.DatePickerPredefined(moment,start,end)}
														onCallback={setDatePicker}>
														<div className="input-group input-group-sm">
															<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
															<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
																<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
															</div>
														</div>
													</DateRangePicker>
													<small>Date range based on Document Date</small>
												</div>
												<div className="col-md-4 col-12 text-center text-md-end mb-1">
													<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Document Number')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
													<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
												</div>
											</div>
											<div className="row">
												<div className="col-md-3">
													<Multiselect
														displayValue="label"
														onKeyPressFn={function noRefCheck(){}}
														onRemove={(e) => getPurAllocationCentreSelected(e)}
														onSearch={function noRefCheck(){}}
														onSelect={(e) => getPurAllocationCentreSelected(e)}
														options={allocation_centre_master}
														placeholder="Select Allocation Centre"
														showArrow
														showCheckbox
													/>
												</div>
												<div className="col-md-1">
													{allocation_centre_id.length === 1 && <>
														<span role="button" onClick={(e)=>purHeaderTypeModal(1)}><i className='bx bx-edit-alt fs-4'></i></span>
													</>}
												</div>
												<div className="col-md-3">
													<Multiselect
														displayValue="label"
														groupBy="group_name"
														onKeyPressFn={function noRefCheck(){}}
														onRemove={(e) => getExpenseTypeSelected(e)}
														onSearch={function noRefCheck(){}}
														onSelect={(e) => getExpenseTypeSelected(e)}
														options={expense_type_master}
														placeholder="Select Expense Type"
														showArrow
														showCheckbox
													/>
												</div>
												<div className="col-md-1">
												</div>
												{typeId.length === 1 && typeId[0] !== '0' && <>
												<div className="col-md-3">
													<Multiselect
														displayValue="label"
														groupBy="group_name"
														onKeyPressFn={function noRefCheck(){}}
														onRemove={(e) => getExpenseSubtypeSelected(e)}
														onSearch={function noRefCheck(){}}
														onSelect={(e) => getExpenseSubtypeSelected(e)}
														options={expenseSubtypeMaster}
														placeholder="Select Expense Subtype"
														showArrow
														showCheckbox
													/>
												</div>
												<div className="col-md-1">
													{subtypeId.length === 1 && <>
														<span role="button" onClick={(e)=>purHeaderTypeModal(2)}><i className='bx bx-edit-alt fs-4'></i></span>
													</>}
												</div>
												</>}
											</div>
										
										</>)}
										<DTE.DataTable title="Expense and Payments" selectableRows selectableRowsHighlight selectableRowsVisibleOnly onSelectedRowsChange={handleChange} className="transaction_dataTables rdt_AdjDrp" columns={purchaseColumns} data={transactionList} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} contextActions={contextActions} clearSelectedRows={stateClear.toggledClearRows}/>
									</>)}
									{showAssetDiv && (<>
										{ transactions_w && (<>
											<div className="row">
												<div className="col-md-3 col-12 listing-tabs mb-1">
													<div className="btn-group btn-group-sm" role="group">
														<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
															<span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
															<i className="bx bx-download d-block d-sm-none"></i>
														</button>
													</div>
												</div>
												<div className="col-md-5 col-12 text-center mb-1">
												</div>
												<div className="col-md-4 col-12 text-center text-md-end mb-1">
													<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Receipent Name')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
													<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
												</div>
											</div>
										</>)}
										<DTE.DataTable title="Asset" selectableRows selectableRowsHighlight selectableRowsVisibleOnly onSelectedRowsChange={handleChange} className="transaction_dataTables rdt_AdjDrp" columns={assetColumns} data={transactionList} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} contextActions={contextActions} clearSelectedRows={stateClear.toggledClearRows}/>
									</>)}
									{showLiabilityDiv && (<>
										{ transactions_w && (<>
											<div className="row">
												<div className="col-md-3 col-12 listing-tabs mb-1">
													<div className="btn-group btn-group-sm" role="group">
														<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
															<span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
															<i className="bx bx-download d-block d-sm-none"></i>
														</button>
													</div>
												</div>
												<div className="col-md-5 col-12 text-center mb-1">
												</div>
												<div className="col-md-4 col-12 text-center text-md-end mb-1">
													<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Provider Name')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
													<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
												</div>
											</div>
										</>)}
										<DTE.DataTable title="Liability" selectableRows selectableRowsHighlight selectableRowsVisibleOnly onSelectedRowsChange={handleChange} className="transaction_dataTables rdt_AdjDrp" columns={liabilityColumns} data={transactionList} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} contextActions={contextActions} clearSelectedRows={stateClear.toggledClearRows}/>
									</>)}
									{showEquityDiv && (<>
										{ transactions_w && (<>
											<div className="row">
												<div className="col-md-3 col-12 listing-tabs mb-1">
													<div className="btn-group btn-group-sm" role="group">
														<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
															<span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
															<i className="bx bx-download d-block d-sm-none"></i>
														</button>
													</div>
												</div>
												<div className="col-md-5 col-12 text-center mb-1">
												</div>
												<div className="col-md-4 col-12 text-center text-md-end mb-1">
													<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Shareholder name')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
													<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
												</div>
											</div>
										</>)}
										<DTE.DataTable title="Equity" selectableRows selectableRowsHighlight selectableRowsVisibleOnly onSelectedRowsChange={handleChange} className="transaction_dataTables rdt_AdjDrp" columns={equityColumns} data={transactionList} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} contextActions={contextActions} clearSelectedRows={stateClear.toggledClearRows}/>
									</>)}
								</div>
							</div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal show={columnTypeModalShow} size={'md'} onHide={()=>setColumnTypeModalShow(false)} aria-labelledby="column-type-modal" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="column-type-modal">Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
					<form id="formColumnType" autoComplete="off" className='formAddPayment'>
						<div className="row">
							<div className='col-sm-12'>
								<div className="row mt-3">
									<label className="col-4 col-form-label" htmlFor="frmColumnType">Allocation Centre</label>
									<div className="col-8">
										<Typeahead selected={allocation_centre_default} clearButton allowNew newSelectionPrefix="Add: " id="frmAllocationcentre" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setAllocationCentreSelect(e)} placeholder="Choose or Create Allocation Center"
											options={allocation_centre_master}
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.label}
												</Highlighter>
											)}
										/>
									</div>
									<label className="col-4 col-form-label mt-3" htmlFor="frmColumnType">Income Type</label>
									<div className="col-8 mt-3">
										<Typeahead selected={incomeTypeDefault} clearButton id="frmIncomeType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setIncomeTypeSelect(e)} placeholder="Choose or Create Income type"
											options={income_type_master}
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.label}
												</Highlighter>
											)}
										/>
									</div>
									<label className="col-4 col-form-label mt-3" htmlFor="frmColumnType">Income Subtype</label>
									<div className="col-8 mt-3">
										<Typeahead selected={incomeSubTypeDefault} clearButton allowNew newSelectionPrefix="Add: " id="frmIncomeSubType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setIncomeSubTypeSelect(e)} placeholder="Choose or Create Income Sub type"
											options={income_subtype_master}
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.label}
												</Highlighter>
											)}
										/>
									</div>
								</div>
								<div className="text-center mt-3">
									<button type='button' onClick={handleColumnType}  className="btn btn-sm btn-primary ms-1" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
								</div>
							</div>
						</div>
					</form>
                </div>
            </Modal.Body>
        </Modal>
		<Modal show={headerTypeModalShow} size={'md'} onHide={()=>setHeaderTypeModalShow(false)} aria-labelledby="header-type-modal" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="header-type-modal">Update {(headerType === 1)?'Allocation Centre '+(allocation_centre_selected.length>0?allocation_centre_selected[0].label:''):'Income Subtype '+(income_subtype_selected.length>0?income_subtype_selected[0].label:'')} To</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
					<form id="formHeaderType" autoComplete="off" className='formAddPayment'>
						<div className="row">
							<div className='col-sm-12'>
								<div className="row mt-3">
									<label className="col-4 col-form-label" htmlFor="formHeaderType">{(headerType === 1)?'Allocation Centre':'Income Subtype'}</label>
									<div className="col-8">
									{headerType === 1 && <>
										<Typeahead clearButton allowNew newSelectionPrefix="Add: " id="frmAllocationcentre" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setHeaderAllocationCentreSelect(e)} placeholder="Choose or Create Allocation Centre"
											options={allocation_centre_master}
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.label}
												</Highlighter>
											)}
										/>
									</>}
									{headerType === 2 && <>
										<Typeahead clearButton id="frmIncomeSubType" allowNew newSelectionPrefix="Add: " labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setHeaderIncomeSubTypeSelect(e)} placeholder="Choose or Create Income Subtype"
											options={income_subtype_master}
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.label}
												</Highlighter>
											)}
										/>
									</>}
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 mt-2">
										<p className="smaller_font text-danger">Note: This Edit will update All Historical data</p>
									</div>
								</div>
								<div className="text-center mt-3">
									<button type='button' onClick={handleHeaderType}  className="btn btn-sm btn-primary ms-1" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
								</div>
							</div>
						</div>
					</form>
                </div>
            </Modal.Body>
        </Modal>
		<Modal show={purColumnTypeModalShow} size={'md'} onHide={()=>setPurColumnTypeModalShow(false)} aria-labelledby="column-type-modal" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="column-type-modal">Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
					<form id="formColumnType" autoComplete="off" className='formAddPayment'>
						<div className="row">
							<div className='col-sm-12'>
								<div className="row mt-3">
									<label className="col-4 col-form-label mt-3" htmlFor="frmColumnType">Allocation Centre </label>
									<div className="col-8 mt-3">
										<Typeahead selected={pur_allocation_centre_default} clearButton allowNew newSelectionPrefix="Add: " id="frmAllocationcentre" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPurAllocationCentreSelect(e)} placeholder="Choose or Create Allocation Center"
											options={allocation_centre_master}
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.label}
												</Highlighter>
											)}
										/>
									</div>
									<label className="col-4 col-form-label mt-3" htmlFor="frmColumnType">Expense Type </label>
									<div className="col-8 mt-3">
										<Typeahead selected={expenseTypeDefault} clearButton id="frmExpenseType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setExpenseTypeSelect(e)} placeholder="Choose Expense Type"
											options={expense_type_master}
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.label}
												</Highlighter>
											)}
										/>
									</div>
									<label className="col-4 col-form-label mt-3" htmlFor="frmColumnType">Expense Sub Type </label>
									<div className="col-8 mt-3">
										<Typeahead selected={expenseSubTypeDefault} clearButton allowNew newSelectionPrefix="Add: " id="frmExpenseSubType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setExpenseSubTypeSelect(e)} placeholder="Choose or Create Expense Sub type"
											options={expense_subtype_master}
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.label}
												</Highlighter>
											)}
										/>
									</div>
								</div>
								<div className="text-center mt-3">
									<button type='button' onClick={handlePurchaseColumnType}  className="btn btn-sm btn-primary ms-1" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
								</div>
							</div>
						</div>
					</form>
                </div>
            </Modal.Body>
        </Modal>
		<Modal show={purHeaderTypeModalShow} size={'md'} onHide={()=>setPurHeaderTypeModalShow(false)} aria-labelledby="header-type-modal" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
				<Modal.Title id="header-type-modal">Update {(purHeaderType === 1)?'Allocation Centre '+(pur_allocation_centre_selected.length>0?pur_allocation_centre_selected[0].label:''):'Income Subtype '+(expense_subtype_selected.length>0?expense_subtype_selected[0].label:'')} To</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
					<form id="formHeaderType" autoComplete="off" className='formAddPayment'>
						<div className="row">
							<div className='col-sm-12'>
								<div className="row mt-3">
									<label className="col-4 col-form-label" htmlFor="formHeaderType">{(purHeaderType === 1)?'Allocation Centre':'Expense Subtype'}</label>
									<div className="col-8">
									{purHeaderType === 1 && <>
										<Typeahead clearButton id="frmAllocationcentre" allowNew newSelectionPrefix="Add: " labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPurHeaderAllocationCentreSelect(e)} placeholder="Choose or Create Allocation Centre"
											options={allocation_centre_master}
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.label}
												</Highlighter>
											)}
										/>
									</>}
									{purHeaderType === 2 && <>
										<Typeahead clearButton id="frmExpenseSubType" allowNew newSelectionPrefix="Add: " labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPurHeaderExpenseSubTypeSelect(e)} placeholder="Choose or Create Expense Subtype"
											options={expense_subtype_master}
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.label}
												</Highlighter>
											)}
										/>
									</>}
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 mt-2">
										<p className="smaller_font text-danger">Note: This Edit will update All Historical data</p>
									</div>
								</div>
								<div className="text-center mt-3">
									<button type='button' onClick={handlePurHeaderType}  className="btn btn-sm btn-primary ms-1" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
								</div>
							</div>
						</div>
					</form>
                </div>
            </Modal.Body>
        </Modal>
		</>
    );
}