import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import * as Common from '../common/ImportUserEssentials';

export default function InvoiceRemarks(props){
	// User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	
	const [declaration, setDeclaration] = useState('');
	const [tnc, setTnc]                 = useState('');
	const [licence, setLicence]         = useState('');
    	
	const [invoiceRemarks_w,setinvoiceRemarks_w] = React.useState(true);
	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Add Invoice Terms | '+props.site_name;
		if( userPerm && Object.keys(userPerm).length > 0 ){
			setinvoiceRemarks_w((userPerm[1]['write'] !== undefined && userPerm[1]['write'] === '0') ? false : true);
		}
        Common.axios({
            method: 'post',
            url: props.url+'user/profileDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
        }).then(function (response) {
            console.log(response);
            if(response.data.success){
                setDeclaration(response.data.data.declaration);
                setTnc(response.data.data.tnc);
                setLicence(response.data.data.licence);
				return false;
            }
			Common.toast.error(response.data.message);
			setTimeout(() => {
				navigate("/profiles");
			}, 2000);
        }).catch(error => console.log(error));
	}, []);
	if( !invoiceRemarks_w ){
		navigate("/not-authorized");
	}
    const handleSubmit = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'user/profile_update',
            data: {
                id:localStorage.getItem('fo_profileid'),declaration:declaration,tnc:tnc,licence:licence
            },
        }).then(function (response) {
			if(response.data.success){
                Common.toast.success(response.data.message);
				return false;
			}
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }

    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="invoice-remarks" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
						<h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Account Management /</span> Invoice Terms</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            <form id="formBankAccount" autoComplete="off" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="business-declaration">Declaration</label>
                                                        <textarea id="business-declaration" name="declaration" className="form-control" placeholder="Declaration" aria-label="Declaration" rows="4" aria-describedby="Declaration" value={declaration} onChange={(e) => setDeclaration(e.target.value)}></textarea>
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="business-tnc">Terms and Conditions</label>
                                                        <textarea id="business-tnc" name="tnc" className="form-control" placeholder="Terms and Conditions" aria-label="Terms and Conditions" rows="4" aria-describedby="Terms and Conditions" value={tnc} onChange={(e) => setTnc(e.target.value)}></textarea>
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="business-licence">Additional Licence</label>
                                                        <textarea id="business-licence" name="licence" className="form-control" placeholder="Additional Licence" aria-label="Additional Licence" rows="4" aria-describedby="Additional Licence" value={licence} onChange={(e) => setLicence(e.target.value)}></textarea>
                                                    </div>
												</div>
												<div className="row ">
													<div className="d-flex gap-2">
														<button type="submit" className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
													</div>
												</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}