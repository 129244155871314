import React  from "react";
import Layout from './PartnerLayout';
import NavBar from './PartnerNavBar';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';

export default function PartnerClientsOrders(props){
	const [DTpending, setDTPending] = React.useState(true);
	const [dt_load,setDtLoad] = React.useState(true);
	const [sortPartner,setSortPartner] = React.useState('');
	const [filterText, setFilterText] = React.useState('');
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [partnerClient, setPartnerClient]    = React.useState([]);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [activeProfile, setActiveProfile] = React.useState(0);
	const [expiredProfile, setExpiredProfile] = React.useState(0);
    React.useEffect(() => {
        document.title = 'Partner Clients | '+props.site_name;
        loadDataTable(1,perPage,filterText,sortPartner);
	}, [props,perPage,dt_load,filterText]);

	const loadDataTable = (page,perPage,filterText,sort='') => {
		Common.axios({
            method: 'post',
            url:props.url+'partner-client-order/listingPartnerClient/',
			data: {'page':page,'perPage':perPage,'sort':sort,'search':filterText,'account_type':localStorage.getItem('fo_partnerType')}
        }).then(function (response) {
            if(response.data.success){
				setPartnerClient(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,sortPartner);
	};
	const handlePerRowsChange = async (newPerPage) => {
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortPartner(btoa(column.sortField+'####'+sortDirection));
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection));
	};
	const columns = [        
        {
            name: 'Partner account name',
            selector: row => row.account_name,
            sortable: true,
			sortField: 'account_name'
        },
        {
            name: 'Order ID',
            selector: row => row.order_id,
            sortable: true,
			sortField: 'order_id'
        },
        {
            name: 'Order Date',
            selector: row => row.order_date,
            sortable: true,
			sortField: 'order_date'
        },
        {
            name: 'Net Amount (₹)',
            selector: row => row.net_amount,
            sortable: true,
			sortField: 'net_amount'
        },
        {
            name: 'Partner commission %',
            selector: row => row.dir_my_commission,
            sortable: true,
			sortField: 'dir_my_commission'
        },
        {
            name: 'Partner commission Amt (₹)',
            selector: row => row.dir_my_commission_net,
            sortable: true,
			sortField: 'dir_my_commission_net'
        },
        {
            name: 'My commission %',
            selector: row => row.par_my_commission,
            sortable: false,
            sortField: 'par_my_commission',
        },
        {
            name: 'My commission Amt (₹)',
            selector: row => row.par_my_commission_amt,
            sortable: true,
            sortField: 'par_my_commission_amt'
        },
	];
    return (
	<>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="partner-clients-orders" {...props}/>
                <div className="layout-page">
                    <NavBar {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3">Partner Client Orders</h4>
							<div className="row">
                                <div className="col-md-8 col-12 mb-1"></div>
								<div className="col-md-4 col-12 text-center text-md-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Client account name, Order ID')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
                            <div className="row">
                                <div className="col-md-12">
                                    <DTE.DataTable title={"Partner Client Orders ("+totalDataCnt+")"} className="dataTables_wrapper rdt_AdjDrp" columns={columns} data={partnerClient} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} persistTableHead/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.PartnerNeedHelp url={props.url}/>
                </div>
            </div>
        </div>
	</>
    );
}