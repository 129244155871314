import React  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import styled from 'styled-components';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import Modal from 'react-bootstrap/Modal';

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;

export default function JournalLedger(props){
	
	const [filterText, setFilterText] = React.useState('');
	const [DTpending, setDTPending] = React.useState(true);
	const [dt_load,setDtLoad] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [totalDataCnt,setTotalDataCount] = React.useState({'Journal':'','Ledger':''});
	const tabTypes = ['Journal','Ledger'];
	const [activeTab, setActiveTab] = React.useState(tabTypes[0]);
	const [journalLedgerList, setJournalLedgerList] = React.useState([]);
    const [exportBtnClass,setExportBtnClass] = React.useState('d-none');
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	const [transactions_r,setTransactions_r] = React.useState(true);
	const [totalCredit, setTotalCredit] = React.useState(0);
	const [totalDebit, setTotalDebit] = React.useState(0);
	const [accountMasterList, setAccountMasterList] = React.useState([]);
	const [accountMaster, setAccountMaster] = React.useState('All');
	const [defaultAccountMaster, setDefaultAccountMaster] = React.useState([{'value': 'All', 'label': 'All','digits':''}]);
	const [transactions_w,setTransactions_w] = React.useState(true);
	const [accountLedgerModelShow,setAccountLedgerModelShow] = React.useState(false);
	const [accountLedgerModelTitle, setAccountLedgerModelTitle] = React.useState('');
	const [accountLedger, setAccountLedger] = React.useState([]);
	const [totalLedgerCredit, setTotalLedgerCredit] = React.useState(0);
	const [totalLedgerDebit, setTotalLedgerDebit] = React.useState(0);
	const [totalLedgerBalance, setTotalLedgerBalance] = React.useState(0);
	const [accountID, setAccountID] = React.useState('');
	const [sortOrder, setSortOrder] = React.useState('');
	

	const navigate = Common.useNavigate();
	
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
    const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
	let state  = Common.useLocation();

    React.useEffect(() => {
        document.title = 'Transactions Listing | '+props.site_name;
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Read permission
			setTransactions_r((userPerm[12]['view'] !== undefined && userPerm[12]['view'] === '0') ? false : true);
		}
		if( Object.keys(userPerm).length > 0 ){
			setTransactions_w((userPerm[12]['write'] !== undefined && userPerm[12]['write'] === '0') ? false : true);
		}
		Common.axios({
			method: 'get',
			url:props.url+'journal_ledger/get_account_master/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setAccountMaster('All');
				setAccountMasterList([{value:"All", label:"All"},...response.data.data]);
			}
		}).catch(error => console.log(error));

		if (localStorage.getItem('fo_subplan') === 'Basic') {
			navigate("/pricing-plan");
		}
		loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab);

	}, [props,dt_load,filterText]);
	if( !transactions_r ){
		navigate("/not-authorized");
	}
	const loadDataTable = (page,perPage,filterText,dates,sort='',active_tab=activeTab,account_master=accountMaster) => {
		// Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'journal_ledger/listing/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'dates':dates,'search':filterText,'active_tab':active_tab,'account_master':account_master}
        }).then(function (response) {
			setExportBtnClass('d-none');
            if(response.data.success){
				setJournalLedgerList(response.data.data);
				setTotalRows(response.data.total);
				setTotalCredit(Number(response.data.credit).toFixed(2));
				setTotalDebit(Number(response.data.debit).toFixed(2));
				setTotalDataCount(prevState => ({
				...prevState,
					[active_tab]: response.data.total
				}));
				if(response.data.data.length > 0){
					setExportBtnClass('');
				}
				setDTPending(false);
            }
        }).catch(error => console.log(error));
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,dates,sortOrder,activeTab);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText,dates,sortOrder,activeTab);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,dates,btoa(column.sortField+'####'+sortDirection),activeTab);
	};
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
    const journalColumns = [
        {
            name: 'Transaction Date',
            selector: row => row.doc_date,
            sortable: true,
			sortField: 'doc_date',
			cell: (row) => (
				row.doc_date
			)
        },
		{
            name: 'Account head',
            selector: row => row.account_head,
            sortable: false,
			sortField: 'account_head',
			cell: (row) => (
				row.account_head
			)
        },
		{
            name: 'Sub type',
            selector: row => row.sub_account,
            sortable: false,
			sortField: 'sub_account',
			cell: (row) => (
				row.sub_account
			)
        },
		{
            name: 'Debit (₹)',
            selector: row => row.debit,
            sortable: false,
			sortField: 'debit',
			cell: (row) => (
				row.debit
			)
        },
		{
            name: 'Credit (₹)',
            selector: row => row.credit,
            sortable: false,
			sortField: 'credit',
			cell: (row) => (
				row.credit
			)
        }
    ];
	const ledgerColumns = [
    	{
            name: 'Account Head',
            sortable: false,
			sortField: 'account_head',
			cell: (row) => (
				<span role="button" className="text-primary" onClick={() => accountLedgerModel(row.account_id,row.account_head)}>{row.account_head}</span>
			)
        },
		{
            name: 'Account Category',
            selector: row => row.account_master,
            sortable: false,
			sortField: 'account_master',
			cell: (row) => (
				row.account_master
			)
        },
		{
            name: 'Debit (₹)',
            selector: row => row.debit,
            sortable: true,
			sortField: 'debit',
			cell: (row) => (
				Number(row.debit).toFixed(2)
			)
        },
		{
            name: 'Credit (₹)',
            selector: row => row.credit,
            sortable: true,
			sortField: 'credit',
			cell: (row) => (
				Number(row.credit).toFixed(2)
			)
        },
		{
            name: 'Balance (₹)',
            // selector: row => row.total,
            sortable: true,
			sortField: 'total',
			cell: (row) => (
				Math.abs((Number(row.debit) - Number(row.credit))).toFixed(2)
			)
        }
    ];
	const setDatePicker = (start, end) => {
		setDates({ start, end });
		let dates_range = {
			start: start,
			end: end,
		}
		loadDataTable(1,perPage,filterText,dates_range,sortOrder,activeTab);
	}
	const handleExportCSV = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'journal_ledger/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'dates':dates,'search':filterText,'active_tab':activeTab,'account_master':accountMaster},
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
			}
        }).catch(error => console.log(error));
    }
	const [showJournalDiv,setShowJournalDiv] = React.useState(true);
    const [showLedgerDiv,setShowLedgerDiv] = React.useState(false);
	function TabGroup() {
		const handleTab = tabChecked => {
			setExportBtnClass('d-none');
			setActiveTab(tabChecked);
			state.state = null
			setSortOrder('')
			loadDataTable(1,perPage,filterText,dates,'',tabChecked);
			if(tabChecked === "Journal"){
				setShowJournalDiv(true);
				setShowLedgerDiv(false);
			}
			else{
				setShowLedgerDiv(true);
				setShowJournalDiv(false);
			}
		};
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{tabTypes.map(type => (
				<Tab
					key={type}
					active={activeTab === type}
					onClick={() => handleTab(type)}
				>
					{(totalDataCnt[type] !== '' && type === 'Journal') ? type + ' ('+totalDataCnt[type]+')' : type}
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
	const setAccountMasterSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		let label = (e[0]) ? e[0].label : '';
		if(value){
			setAccountMaster(value);
			setDefaultAccountMaster([{'value':value,'label':label,'digits':''}]);
			loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,value);
		}else{
			setAccountMaster('');
			setDefaultAccountMaster([]);
			loadDataTable(1,perPage,filterText,dates,sortOrder,activeTab,value);
		}
	}
	const accountLedgerModel = (account_id,account_head) => {
        let url = props.url+'journal_ledger/get_account_ledger';
        Common.axios({
            method: 'post',
            url: url,
            data:{aid:account_id,pid:localStorage.getItem('fo_profileid'),date:dates}
        }).then(function (response) {
            if(response.data.success){
                setAccountLedger(response.data.data);
				setAccountID(account_id);
                setAccountLedgerModelShow(true);
                setAccountLedgerModelTitle(account_head);
				setTotalLedgerCredit(Number(response.data.credit).toFixed(2));
				setTotalLedgerDebit(Number(response.data.debit).toFixed(2));
				setTotalLedgerBalance(Number(response.data.balance).toFixed(2));
            }
        }).catch(error => console.log(error));
    }
	const handleLedgerExportCSV = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'journal_ledger/download_ledger_csv',
			data: {aid:accountID,pid:localStorage.getItem('fo_profileid'),date:dates},
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
			}
        }).catch(error => console.log(error));
    }
    return (
		<>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="journal-ledger" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Accounting /</span> Chart of Account</h4>
							<div className="row ">
								<div className="col-sm-12">
									<div className="row pb-3">
										<div className="col-md-8">
											<TabGroup/>
										</div>
									</div>
								</div>
								<div id="sdt_cont" className="col-sm-12">
									{showJournalDiv && (<>
										{ transactions_w && (<>
											<div className="row">
												<div className="col-md-3 col-12 listing-tabs mb-1">
													<div className="btn-group btn-group-sm" role="group">
														<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
															<span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
															<i className="bx bx-download d-block d-sm-none"></i>
														</button>
													</div>
												</div>
												<div className="col-md-5 col-12 text-center mb-1">
													<DateRangePicker
														initialSettings={DTE.DatePickerPredefined(moment,start,end)}
														onCallback={setDatePicker}>
														<div className="input-group input-group-sm">
															<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
															<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
																<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
															</div>
														</div>
													</DateRangePicker>
													<small>Date range based on Transaction Date</small>
												</div>
												<div className="col-md-4 col-12 text-center text-md-end mb-1">
													<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Account Head')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
													<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
												</div>
											</div>
										</>)}
										<DTE.DataTable className="rdt_AdjDrp" columns={journalColumns} data={journalLedgerList} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
                                        <div className={"row "+exportBtnClass}>
                                            <div className="col-md-5 col-12 mt-2 px-2">
											</div>
                                            <div className="col-md-3 col-4 mt-2 px-2">
												<b>Total Debit: {totalDebit}</b>
											</div>
											<div className="col-md-3 col-4 mt-2 px-2">
												<b>Total Credit: {totalCredit}</b>
											</div>
										</div>
									</>)}
									{showLedgerDiv && (<>
										{ transactions_w && (<>
											<div className="row">
												<div className="col-md-2 col-12 listing-tabs mb-1">
													<div className="btn-group btn-group-sm" role="group">
														<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}>
															<span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
															<i className="bx bx-download d-block d-sm-none"></i>
														</button>
													</div>
												</div>
												<div className="col-md-3 col-12 text-center mb-1">
													<DateRangePicker
														initialSettings={DTE.DatePickerPredefined(moment,start,end)}
														onCallback={setDatePicker}>
														<div className="input-group input-group-sm">
															<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
															<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
																<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
															</div>
														</div>
													</DateRangePicker>
													<small>Date range based on Transaction Date</small>
												</div>
												<div className="col-md-3 col-12 text-center mb-1">
													<Typeahead selected={defaultAccountMaster} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setAccountMasterSelect(e)} placeholder="Choose Account Category"
													options={accountMasterList}
													renderMenuItemChildren={(option: Item,props) => (
														<Highlighter search={props.text}>
															{option.label}
														</Highlighter>
														)}
													/>
												</div>
												<div className="col-md-4 col-12 text-center text-md-end mb-1">
													<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Account head')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
													<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
												</div>
											</div>
										</>)}
										<DTE.DataTable className="rdt_AdjDrp" columns={ledgerColumns} data={journalLedgerList} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
										<div className={"row "+exportBtnClass}>
                                            <div className="col-md-5 col-12 mt-2 px-2">
											</div>
                                            <div className="col-md-3 col-4 mt-2 px-2">
												<b>Total Debit: {totalDebit}</b>
											</div>
											<div className="col-md-3 col-4 mt-2 px-2">
												<b>Total Credit: {totalCredit}</b>
											</div>
										</div>
									</>)}
								</div>
							</div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal show={accountLedgerModelShow} size={'lg'} onHide={()=>setAccountLedgerModelShow(false)} aria-labelledby="accountLedger-modal" backdrop="static" keyboard={false}>
			<Modal.Header closeButton className="text-center">
				<Modal.Title id="accountLedger-modal" className="w-100">Ledger - {accountLedgerModelTitle} Account</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					{accountLedger !== undefined && accountLedger.length === 0 && <div className="text-center py-1">No Ledger Data</div>}
					{accountLedger !== undefined && accountLedger.length > 0 && <>
					<div className="row">
						<div className="col-md-12 col-12 listing-tabs mb-1">
							<div className="btn-group btn-group-sm" role="group">
								<button className={"btn btn-sm btn-info me-2"} onClick={handleLedgerExportCSV}>
									<span className="d-none d-sm-block"><i className="bx bx-download"></i>Export CSV</span>
									<i className="bx bx-download d-block d-sm-none"></i>
								</button>
							</div>
						</div>
					</div>
					<div className="table-responsive text-nowrap">
						<table className="table table-sm table-bordered">
							<thead className="table-light">
								<tr>
									<th>Date</th>
									<th>Particulars</th>
									<th>Debit (₹)</th>
									<th>Credit (₹)</th>
								</tr>
							</thead>
							<tbody className="table-border-bottom-0">
								{accountLedger.map((val,index) => (
									<tr key={'ded1'+index}>
										<td>{val.doc_date}</td>
										<td>{val.sub_account}</td>
										<td className="text-right">{(val.debit !== '0')?val.debit:''}</td>
										<td className="text-right">{(val.credit !== '0')?val.credit:''}</td>
									</tr>
								))}
								<tr>
									<td></td>
									<td><b>Total</b></td>
									<td className="text-right"><b>{(totalLedgerDebit > 0)?totalLedgerDebit:''}</b></td>
									<td className="text-right"><b>{(totalLedgerCredit > 0)?totalLedgerCredit:''}</b></td>
								</tr>
								<tr>
									<td></td>
									<td></td>
									<td className="text-right"><b>{totalLedgerDebit > totalLedgerCredit?'Balance(₹): '+totalLedgerBalance:''}</b></td>
									<td className="text-right"><b>{totalLedgerCredit > totalLedgerDebit?'Balance(₹): '+totalLedgerBalance:''}</b></td>
								</tr>
							</tbody>
						</table>
					</div>
					</>}
				</div>
			</Modal.Body>
		</Modal>
		</>
    );
}