import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Select from "react-select";

import * as Common from '../common/ImportUserEssentials';
import { Typeahead,AsyncTypeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import paymentModes from '../masters/PaymentModes.js';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as DTE from '../common/ImportDataTableEssentials';

export default function AddPayable(props){
	const [state_master, setStateMaster]                = useState([]);
    const [sid, setSid]                                 = useState('');
	const [update_seller,setUpdateSeller]                   = useState(0);
	const [seller_name, setSellerName]                      = useState([]);
	const [seller_phone, setSellerPhone]                    = useState('');
	const [seller_phone_disabled, setSellerPhoneDisabled]   = useState(false);
	const [sellerAddiDiv,setSellerAddiDiv]                  = useState(false);
	const [seller_gstin,setSellerGstin]                     = useState('');
	const [seller_legal_name,setSellerLegalName]            = useState('');
	const [seller_trade_name,setSellerTradeName]            = useState('');
	const [seller_pincode, setSellerPincode]                = useState('');
	const [seller_addr,setSellerAddr]                       = useState('');
	const [seller_district,setSellerDistrict]               = useState('');
	const [seller_state,setSellerState]                     = useState([]);
	//const [seller_email, setSellerEmail] 					= useState('');
    // const [saveBtnDisabled,setSaveBtnDisabled]          = useState(false);
	const [isSellerLoading, setIsSellerLoading]             = useState(false);
	const [sellers, setSellers]                         = useState([]);
	const [received,setReceived]                        = useState('');
	const [payment_date,setPaymentDate]                 = useState(new Date());
	const [payment_mode,setPaymentMode]                 = useState('UPI/QR');
	const [payment_remarks,setPaymentRemarks]           = useState('');
	const [trans_ref,setTransRef]                       = useState('');

	const [bankList, setBankList] 						= useState([]);
	const [bankAccount,setBankAccount] 					= useState(0);
    const [bank_cash_account,setBankCashAccount] 		= useState(1);
	const [defaultPayee, setDefaultPayee] 				= useState([]);

    const filterBy                                      = () => true;
    const [filterText, setFilterText] = React.useState('');
	const [customers_list_modal, setCustomersListModal] = useState([]);
	const [totalRows, setTotalRows] = React.useState(0);
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [DTpending, setDTPending] = React.useState(true);
	const [custListModalShow, setCustListModalShow] = useState(false);

	const paginationComponentOptions = {
		noRowsPerPage: true
	};

	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Add Payable | '+props.site_name;
		// State Master
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_state_master',
		}).then(function (response) {
			if( response.data.success ){
				setStateMaster(response.data.data);
			}
		}).catch(error => console.log(error));

		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				setDefaultPayee(response.data.defaultPayee);
				if (response.data.defaultPayee.length > 0) {
					setBankAccount(response.data.defaultPayee[0].value);
				}
			}
		}).catch(error => console.log(error));

	}, []);

    const sellerSearch = (query: string) => {
		setIsSellerLoading(true);

		fetch(props.url+`vendor/search/`+localStorage.getItem('fo_profileid')+`/${query}`)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setSellers(items);
			setIsSellerLoading(false);
		});
	};

    const setSellerDetails = (e) => {
		if(e[0] === undefined) {
			setSellerName([]);
			setSellerPhone('');
			setSellerPhoneDisabled(false);
			setSid('');
			setSellerGstin('');
			setSellerLegalName('');
			setSellerTradeName('');
			setSellerPincode('');
			setSellerAddr('');
			setSellerDistrict('');
			setSellerState([]);
			//setSellerEmail('');
			return;
		}
		setSellerName(e);
		let phone = (e[0].mobile) ? e[0].mobile : '';
		setSellerPhone(phone);
		setSid(e[0].id.startsWith('new') ? '':e[0].id);
		setSellerGstin((e[0]) ? e[0].gstin : '');
		setSellerLegalName((e[0]) ? e[0].legal_name : '');
		setSellerTradeName((e[0]) ? e[0].trade_name : '');
		setSellerPincode((e[0]) ? e[0].pincode : '');
		setSellerAddr((e[0]) ? e[0].addr : '');
		setSellerDistrict((e[0]) ? e[0].district : '');
		setSellerState((e[0]) ? e[0].state : '');
		setSellerPhoneDisabled(true);
	}
    const showSellerAdditional = () => {
		setSellerAddiDiv( (sellerAddiDiv) ? false : true );
	}
	const setPaymentModeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setPaymentMode(value);
		if(value === 'Cash'){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/'+value,
			}).then(function (response) {
				if(response.data.success){
					if(response.data.data !== ''){
						setBankCashAccount(1);
						setDefaultPayee(response.data.data);
						setBankAccount(response.data.data[0].value);
						setBankList(response.data.data);
					}else{
						setBankCashAccount(0);
						setDefaultPayee([]);
						setBankAccount(0);
					}
				}
			}).catch(error => console.log(error));
		}else{
			setBankCashAccount(1);
			setDefaultPayee([]);
			setListOfBanks();
		}
	}
	const setListOfBanks = () => {
		Common.axios({
			method: 'get',
			url:props.url+'bank-accounts/search/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setBankList(response.data.items);
				// setDefaultPayee(response.data.defaultPayee);
				// if (response.data.defaultPayee.length > 0) {
				// 	setBankAccount(response.data.defaultPayee[0].value);
				// }
			}
		}).catch(error => console.log(error));
	}
	const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setBankAccount(value);
		// setDefaultPayee('');
		if (value === '') {
			setDefaultPayee([]);
		} else {
			bankList.forEach((bank,i) => {
				if (bank.value === value) {
					setDefaultPayee([bank]);
				}
			});
		}
	}
    const populatePinData = (pincode) => {
		setSellerPincode(pincode);
		if( pincode.length=== 6 ){
			Common.axios({
				method: 'post',
				url:props.url+'ajax/getDataByPin',
				data: {pincode:pincode},
			}).then(function (response) {
				if(response.data.success){
					setSellerState(response.data.stateid);
					setSellerDistrict(response.data.district);
					return false;
				}
				Common.toast.dismiss();
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
	}
    const handlePayable = (e) => {
		e.preventDefault();
		Common.showLoader(true);
		// payment_mode !== 'Cash' &&
		if( bankAccount === undefined || bankAccount === 0 || bankAccount === '' ){
			Common.showLoader(false);
			Common.toast.error('Bank Account is required.');
			return false;
		}
		if( payment_mode === '' ){
			Common.showLoader(false);
			Common.toast.error('Payment Mode is required');
			return false;
		}

        // setSaveBtnDisabled(true);
		let formData = new FormData(e.target);
		let pid = localStorage.getItem('fo_profileid');
		formData.append('pid', pid);
		formData.append('sid', sid);
		formData.append('seller_name', seller_name[0].party_name);
		formData.append('update_seller', update_seller);
        formData.append('payment_mode', payment_mode);
        formData.append('bankAccount', bankAccount);
		let data = {};
		// convert the key/value pairs
		for (var [key, value] of formData.entries()) {
		   data[key] = value;
		}

		if( data.seller_name === '' ){
			Common.showLoader(false);
			Common.toast.dismiss();
			Common.toast.error('Seller name cannot be blank.');
            // setSaveBtnDisabled(false);
			return false;
		}

		Common.axios({
			method: 'post',
			url: props.url+'payable/add',
			data: data
		}).then(function (response) {
			if(response.data.success){
				Common.showLoader(false);
				navigate("/payable-listing",
				{
					state: {
						tab_type: 2,
					}
				});
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const customerListModal = (param) => {
		if( param === 'show' ){
			setCustListModalShow(true);
			loadCustTableModal(1,filterText);
		}
		if( param === 'hide' ){
			setCustListModalShow(false );
			setCustomersListModal([]);
		}
	}
	const handlePageChange = page => {
		loadCustTableModal(page,filterText);
	};
	const handleSort = (column, sortDirection) => {
		loadCustTableModal(1,filterText,btoa(column.sortField+'####'+sortDirection));
	};
	const loadCustTableModal = (page,filterText,sort='') => {
		Common.axios({
			method: 'post',
			url: props.url+'vendor/listingInVendor/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'sort':sort,'search':filterText}
		}).then(function (response) {
			if(response.data.success){
				setCustomersListModal(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);

				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const columns = [
        {
            name: 'Party Name',
            selector: row => row.party_name,
            sortable: true,
			sortField: 'party_name',
			cell: (row) => (
				<Button variant="" className="text-break p-0 text-decoration-underline" onClick={e=>{setSellerDetails([row]);setCustListModalShow(false);}}>{row.party_name.substring(0,15)}</Button>
			)
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
			sortField: 'mobile'
        },
        {
            name: 'Gstin',
            selector: row => row.gstin,
            sortable: true,
			sortField: 'gstin'
        },
        /*{
            name: 'Email',
            selector: row => row.email,
            sortable: true,
			sortField: 'email'
        }*/
    ];


    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="add-payable" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Expenses & Payments /</span> Add Payable</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            <form id="formAddPayable" onSubmit={handlePayable} autoComplete="off" className='formAddPayable'>
                                            <div className="row" id="collectionAddDiv">
													<div className="col-12">
														<div className="accordion" id="salesAccordion">
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingTwo">
																	<button type="button" className="accordion-button" data-bs-target="#accordionSeller" aria-expanded="false" aria-controls="accordionSeller" disabled>
                                                                    Seller Details
																	</button>
																</h2>
																<div id="accordionSeller" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<Button className="text-end" variant="" size="sm" onClick={()=>customerListModal('show')}><Common.OverlayTrigger overlay={Common.popoverContent('Seller List','Click to search seller by Name, GSTIN, Phone')}><i className='bx bxs-right-top-arrow-circle'></i></Common.OverlayTrigger></Button>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<AsyncTypeahead selected={seller_name} clearButton allowNew newSelectionPrefix="Add: " filterBy={filterBy} id="sale-customer" className="floatingTypeahead" isLoading={isSellerLoading} labelKey="party_name" minLength={3} onSearch={sellerSearch} options={sellers} placeholder="Search (min 3 chars)" onChange={(e) => setSellerDetails(e)}
																						renderMenuItemChildren={(option: Item,props) => (
																							<div>
																								<Highlighter search={props.text}>
																									{option.party_name}
																								</Highlighter>
																								<div>
																									{(option.gstin !== 'URP' && option.gstin !== '') && <><small>Gstin: {option.gstin}</small>, </>}
																									<small>Mobile: {option.mobile}</small>
																								</div>
																							</div>
																						)}
																					/>
																					<label htmlFor="frmSaleCName">Seller Name <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_phone" type="text" maxLength="10" className="form-control" id="frmSaleCPhone" placeholder="Enter Phone" value={seller_phone} onChange={(e) => setSellerPhone(Common.acceptOnlyNumbers(e.target.value))} disabled={seller_phone_disabled}/>
																					{seller_phone_disabled && <span id="sellerPhoneEdit"
																						onClick = {function(e){
																							setSellerPhoneDisabled(false);
																							setUpdateSeller(1);
																						}}><Common.OverlayTrigger overlay={Common.popoverContent('Click to Edit Phone','On submit, phone number will be updated for the seller.')}><i className="bx bx-edit"></i></Common.OverlayTrigger></span>}
																					<label htmlFor="frmSaleCPhone">Phone</label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col">
																				<div className="divider">
																					<div className="divider-text"><i className={'bx bx-chevrons-'+ (sellerAddiDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showSellerAdditional}>{sellerAddiDiv ? 'Hide':'Show'} additional Fields</Common.Link><i className={'bx bx-chevrons-'+ (sellerAddiDiv ? "up":"down")+' bx-fade-up'}></i></div>
																				</div>
																			</div>
																		</div>
																		{sellerAddiDiv && <>
																		<div className="row">
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_gstin" type="text" className="form-control" id="frmSaleCGstin" placeholder="Enter Gstin" value={seller_gstin} maxLength="15" onChange={(e) => setSellerGstin(Common.acceptOnlyCharsNumbers(e.target.value))}/>
																					<label htmlFor="frmSaleCGstin">Gstin</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_legal_name" type="text" className="form-control" id="frmSaleCPhone" placeholder="Enter Legal Name" value={seller_legal_name} maxLength="100" onChange={(e) => setSellerLegalName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmSaleCPhone">Legal Name</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_trade_name" type="text" className="form-control" id="frmSaleCPhone" placeholder="Enter Trade Name" value={seller_trade_name} maxLength="100" onChange={(e) => setSellerTradeName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmSaleCPhone">Trade Name</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_pincode" type="text" className="form-control" id="frmSaleCPincode" placeholder="Enter Pin Code" value={seller_pincode} maxLength="6" onChange={(e) => populatePinData(Common.acceptOnlyNumbers(e.target.value))}/>
																					<label htmlFor="frmSaleCPincode">Pin Code</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_addr" type="text" className="form-control" id="frmSaleCAddr" placeholder="Enter Address" value={seller_addr} maxLength="100" onChange={(e) => setSellerAddr(Common.acceptOnlyCharsNumbers(e.target.value))}/>
																					<label htmlFor="frmSaleCAddr">Address</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_district" type="text" className="form-control" id="frmSaleCDist" placeholder="Enter District" value={seller_district} maxLength="100" onChange={(e) => setSellerDistrict(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmSaleCDist">District</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Select classNamePrefix="react-select" className="form-control" id="frmSaleCState" placeholder="Select State" name='seller_state' value={state_master[Common.stateIndex(state_master,seller_state)]} options={state_master}/>
																					<label htmlFor="frmSaleCState">State</label>
																				</div>
																			</div>
																			{/*<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_email" type="text" className="form-control" id="frmPOCEmail" placeholder="Enter Seller Email" value={seller_email} maxLength="100" onChange={(e) => setSellerEmail(e.target.value)}/>
																					<label htmlFor="frmPOCEmail">Email</label>
																				</div>
																			</div>*/}
																		</div>
																		</>}
																	</div>
																</div>
															</div>
														</div>
													</div>
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-6 mt-2" id="summaryDivR">
                                                        <div className="card">
                                                            <div className="row">
                                                                <label className="col-4 col-form-label" htmlFor="frmSaleTotal">Amount <span className="text-danger">*</span></label>
                                                                <div className="col-8">
                                                                    <div className="input-group input-group-merge">
                                                                        <span className="input-group-text"><i className='bx bx-rupee'></i></span>
                                                                        <input type="text" className="form-control" id="frmSaleTotal" name="received_amount" placeholder="0.00" value={received} onChange={(e) => setReceived(Common.acceptOnlyDecimal(e.target.value))} autoComplete="off" required/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <label className="col-4 col-form-label" htmlFor="frmSalePayDate">Payment Date</label>
                                                                <div className="col-8">
                                                                    <DatePicker data-testid="frmSalePayDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setPaymentDate} name="payment_date" value={payment_date} />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <label className="col-4 col-form-label" htmlFor="frmSalePayMode">Payment Mode</label>
                                                                <div className="col-8">
                                                                    <Typeahead clearButton defaultSelected={paymentModes.slice(1, 2)} id="frmSalePayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
                                                                        options={paymentModes}
                                                                        renderMenuItemChildren={(option: Item) => (
                                                                            <div>
                                                                                {option.label}
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
															<div className="row mt-3">
																<label className="col-4 col-form-label" htmlFor="frmBankAccount">Bank Account <span className="text-danger">*</span></label>
																<div className="col-8">
																	<Typeahead selected={defaultPayee} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
																		options={bankList}
																		renderMenuItemChildren={(option: Item,props) => (
																			<Highlighter search={props.text}>
																				{option.label}
																			</Highlighter>
																		)}
																	/>
																	<small>To add additional bank account, Go to Account management - <a href='/bank-accounts'>Bank accounts</a></small>
																</div>
															</div>
                                                            <div className="row mt-3">
                                                                <label className="col-4 col-form-label" htmlFor="frmSalePayRemarks">Payment Remarks</label>
                                                                <div className="col-8">
                                                                    <input type="text" className="form-control" id="frmSalePayRemarks" placeholder="Payment Remarks" name="payment_remarks" value={payment_remarks} onChange={(e) => setPaymentRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <label className="col-4 col-form-label" htmlFor="frmSaleTransRef">Transaction Ref. No.</label>
                                                                <div className="col-8">
                                                                    <input type="text" maxLength="100" className="form-control" id="frmSaleTransRef" placeholder="Transaction Ref." name="trans_ref" value={trans_ref} onChange={(e) => setTransRef(e.target.value)} autoComplete="off"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3"></div>
												</div>
                                                <br></br>
                                                <div className="row">
                                                    <div className="col-sm-7 text-right">
                                                        <a href="/payable-listing" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                        &nbsp;&nbsp;
														{bank_cash_account === 1 && (<>
															<button type='submit' className="btn btn-sm btn-primary"><i className='bx bx-save' ></i> Save</button>
														</>)}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        <Modal show={custListModalShow} size="lg" onHide={()=>{setCustListModalShow(false);setFilterText('')}} aria-labelledby="customer-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="text-end">
					<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Party Name, Mobile, Gstin, Email')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
					<DTE.FilterComponent placeholder="Search" onFilter={e => {setFilterText(e.target.value);loadCustTableModal(1,e.target.value);}} onClear={e=>{setFilterText('');loadCustTableModal(1,'');}} filterText={filterText} />
					</div>
				</div>
				<div className="row">
					{customers_list_modal !== undefined && customers_list_modal.length === 0 && <div className="text-center py-1">No Seller</div>}
					{customers_list_modal !== undefined && customers_list_modal.length > 0 && <>
					<div className="table-responsive text-nowrap">
						<DTE.DataTable className="rdt_AdjDrp" title={"Seller ("+totalDataCnt+")"} columns={columns} data={customers_list_modal} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} paginationComponentOptions={paginationComponentOptions} onChangePage={handlePageChange} onSort={handleSort} sortServer/>
					</div>
					</>}
				</div>
			</Modal.Body>
		</Modal>
        </>
    );
}