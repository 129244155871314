import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import NotificationMartOrder from '../common/NotificationMartOrder.js';
import NotificationUploadFileError from '../common/NotificationUploadFileError.js';

export default function NavBar(props){
	const [profiles,setProfiles] = useState([]);
	const [udetails,setUdetails] = useState([]);
	const [profile_name,setProfileName] = useState([]);
	const [subscriptionPlan,setSubscriptionPlan] = useState('');
	const [subscriptionValidity,setSubscriptionValidity] = useState('');
	const [profileMasterId, setProfileMasterId] = useState('');
	const [profileMasterName, setProfileMasterName] = useState('');
	const [viewModal, setViewModal] = React.useState(false);
	const [whatsNew, setWhatsNew] = useState([]);
	const [whatsNewModal,setWhatsNewModal] = React.useState(false);
    const handleWhatsNewViewClose = () => setWhatsNewModal(false);
	const handleViewClose = () => setViewModal(false);
	const [firstTimeLogin,setFirstTimeLogin] = React.useState(false);
	const [youtubeModal,setYoutubeModal] = React.useState(false);
	// const [freeFinalModal,setFreeFinalModal] = React.useState(false);
	const [subscriptionModal,setsubscriptionModal] = React.useState(false);
	const [subscriptionFinalModal,setsubscriptionFinalModal] = React.useState(false);
	const [planPeriod, setPlanPeriod] = React.useState('');
	const [trialDays, setTrialDays] = React.useState(0);
	const handleYoutubeViewClose = () => setYoutubeModal(false);

	const navigate     = Common.useNavigate();
	const site_url = props.url;
	useEffect(() => {
		if( !localStorage.getItem('fo_loggedin') ){
			Common.destroySession()
			navigate("/login");
		} else {
			Common.axios({
				method: 'get',
				url:site_url+'user/userInfo',
			}).then(function (response) {
				if( response.data.success ){
					setUdetails(response.data.user);
					
					if( response.data.profiles.length === 0 ){
						navigate("/add-profile");
						return false;
					}
					
					setProfiles(response.data.profiles);
					setProfileName(response.data.current_profile.name);
					let date = response.data.current_profile.sub_validity.split(" ")[0];
					let month = '';
					if (date.split('-')[1] === '01'){
						month = 'Jan';
					} else if (date.split('-')[1] === '02') {
						month = 'Feb';
					} else if (date.split('-')[1] === '03') {
						month = 'Mar';
					} else if (date.split('-')[1] === '04') {
						month = 'April';
					} else if (date.split('-')[1] === '05') {
						month = 'May';
					} else if (date.split('-')[1] === '06') {
						month = 'June';
					} else if (date.split('-')[1] === '07') {
						month = 'July';
					} else if (date.split('-')[1] === '08') {
						month = 'Aug';
					} else if (date.split('-')[1] === '09') {
						month = 'Sept';
					} else if (date.split('-')[1] === '10') {
						month = 'Oct';
					} else if (date.split('-')[1] === '11') {
						month = 'Nov';
					} else if (date.split('-')[1] === '12') {
						month = 'Dec';
					}
					let formattedDate = date.split('-')[2] + ' ' + month + ' '+ date.split('-')[0];

					if (response.data.current_profile.days !== null) {
						let days = parseInt(response.data.current_profile.days);
						setTrialDays(days);
					}
					setPlanPeriod(response.data.current_profile.plan_type)
					if (response.data.current_profile.days <= 0 && response.data.current_profile.sub_plan !== '') {
						setSubscriptionValidity('Plan Expired');
					}

					if (response.data.current_profile.days !== null) {
						if (parseInt(response.data.current_profile.days) >= -7 && parseInt(response.data.current_profile.days) <= 0) { // 7 days extended validity
							if (localStorage.getItem('fo_subModal') === null) {
								setsubscriptionModal(true)
								localStorage.setItem('fo_subModal', 1);
								return false;
							} else {
								setsubscriptionModal(false);
							}
						} else if (parseInt(response.data.current_profile.days) < -7 || parseInt(response.data.current_profile.days) === null) { // after extended validity expired
							if (localStorage.getItem('fo_subFinalModal') === null) {
								setsubscriptionFinalModal(true)
								localStorage.setItem('fo_subFinalModal', 1)
								return false;
							} else {
								setsubscriptionFinalModal(false);
							}
						} else {
							if (response.data.current_profile.sub_plan === 'Free') {
								setSubscriptionValidity('Life Time');
							}else{
								setSubscriptionValidity(formattedDate);
							}
						}
					}
					
					setSubscriptionPlan(response.data.current_profile.sub_plan);
					setProfileMasterId(response.data.current_profile.profile_master_id);
					//getMasterProfileName(response.data.current_profile.profile_master_id);
					setProfileMasterName(response.data.current_profile.master_profile_name);
					if( response.data.current_profile.name === '' ){
						navigate("/add-profile");
						return false;
					}
						localStorage.setItem('fo_profileid',response.data.current_profile.pid);
						localStorage.setItem('fo_subplan',response.data.current_profile.sub_plan);
						localStorage.setItem('fo_subvalidity',response.data.current_profile.sub_validity);
						localStorage.setItem('fo_plantype',response.data.current_profile.plan_type);
						localStorage.setItem('fo_collaboration',response.data.current_profile.is_sub_tab_enabled);
						localStorage.setItem('fo_userrole',response.data.current_profile.role);
						// localStorage.setItem('fo_mart_url','0');
						if(response.data.implemented_by){
							localStorage.setItem('fo_implemented_by_name',response.data.implemented_by.name);
							localStorage.setItem('fo_implemented_by_logo',response.data.implemented_by.logo);
						}

						if (localStorage.getItem('fo_subplan') === '' ) {
							navigate("/pricing-plan");
							return false;
						}
						if (localStorage.getItem('fo_subplan') === 'Free' && localStorage.getItem('fo_mart_url') === "0") {
							navigate('/mart');
							return false;
						}
						if( !localStorage.getItem('fo_userperm') ){
							profilePermission(response.data.current_profile.pid);
						}
						if (response.data.user.is_first_login === '1') {
							checkFirstTimeLogin()
							if (firstTimeLogin === true) {
								setYoutubeModal(true);
								updateFirstTimeLogin();
							}
						}
						let windowURLParts = window.location.href.split('/')[3]
						if (windowURLParts === 'profiles') {
							navigate('/profiles');
							return false;
						} else if (windowURLParts === 'profile') {
							navigate('/profile');
							return false;
						} else if (windowURLParts === 'whats-new') {
							navigate('/whats-new');
							return false;
						}
						if ((localStorage.getItem('fo_subplan') === 'Free' && localStorage.getItem('fo_mart_url') === "1") ) {
							navigate("/pricing-plan");
							return false;
						}

						if (parseInt(response.data.current_profile.days) < -7) {
							navigate("/pricing-plan");
						}
					return false;
				}
				if( response.data.action !== undefined && response.data.action === 'session'){
					logout();
					return false;
				}
				Common.toast.dismiss();
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}

		var msTillEndOfDay = moment().endOf('day').add(1, 'seconds').diff(moment(), 'milliseconds');
		setTimeout( () => {
			localStorage.removeItem('fo_subModal')
		}, msTillEndOfDay);
		
		getWhatsNewDetails();
	}, [site_url,firstTimeLogin]);
	const logout = () => {
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'logout',
		}).then(function (response) {
			if( response.data.success ){
				Common.showLoader(false);
				Common.destroySession();
				navigate("/login");
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	};
	const changeProfile = (obj,subplan) => {
		Common.showLoader(true);
		localStorage.removeItem('fo_subModal')
		localStorage.removeItem('fo_subFinalModal')
		obj = obj.replace('pid_','');
		Common.axios({
			method: 'post',
			url:props.url+'user/update',
			data: {current_profile:obj},
		}).then(function (response) {
			Common.showLoader(false);
			if( response.data.success ){
				localStorage.setItem('fo_profileid',obj);
				if(subplan === 'Free'){
					localStorage.setItem('fo_mart_url','0');
				}
				profilePermission(obj,1);
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const checkFirstTimeLogin = () => {
		Common.axios({
			method: 'post',
			url:props.url+'user/checkFirstTimeLogin',
			data: {
				uid: localStorage.getItem('fo_loggedin'),
			},
		}).then(function (response) {
			if( response.data.success ){
				if (response.data.is_first_login === '1') {
					setFirstTimeLogin(true)
					
				}
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const updateFirstTimeLogin = () => {
		Common.axios({
			method: 'post',
			url:props.url+'user/updateFirstTimeLogin',
			data: {
				uid: localStorage.getItem('fo_loggedin'),
			},
		}).then(function (response) {
			if( response.data.success ){
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}


	/*profiles.forEach((profile,index) => {
		if( udetails.current_profile > 0 && profile.id === udetails.current_profile ){
			profile_name = profile.name;
			if( !localStorage.getItem('fo_profileid') || (localStorage.getItem('fo_profileid') === '0') ){
				localStorage.setItem('fo_profileid',profile.id);
			}
			if( !localStorage.getItem('fo_userperm') ){
				profilePermission(profile.id);
			}
		} else if( profile.current_profile !== '' ){
			profile_name = profile.name;
			if( !localStorage.getItem('fo_profileid') || (localStorage.getItem('fo_profileid') === '0') ){
				localStorage.setItem('fo_profileid',profile.id);
			}
			if( !localStorage.getItem('fo_userperm') ){
				profilePermission(profile.id);
			}
		}
	})*/
	const showSubscriptionDetails = (e) => {
		setViewModal(true);
	}

	// Load profile permission
	function profilePermission(pid,reload=0){
		Common.axios({
			method: 'post',
			url: props.url+'user/profilePermission',
			data: {pid:pid},
		}).then(function (response) {
			if( response.data.success ){
				localStorage.setItem('fo_userperm',JSON.stringify(response.data.data));
				if( reload ){
					window.location.reload(false);
				}
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	/*function getMasterProfileName(profile_master_id) {
		Common.axios({
			method: 'post',
			url:site_url+'user/getMasterProfileDetails/',
			data: {
				master_profile_id:profile_master_id
			}
		}).then(function (response) {
			if( response.data.success == '1'){
				if (response.data.master_profile.length !== 0) {
					setProfileMasterName(response.data.master_profile[0].name);
				}
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}*/
	function getWhatsNewDetails() {
		Common.axios({
			method: 'post',
			url:props.url+'user/whatsNew/',
			data: {
				uid: localStorage.getItem('fo_loggedin'),
				after_login: '1'
			}
		}).then(function (response) {
			if( response.data.success === 1){
				if (response.data.whatsnew.length > 0) {
					setWhatsNew(response.data.whatsnew);
					setWhatsNewModal(true);
				} else {
					setWhatsNewModal(false);
				}
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const modalStyle={
        display: 'block !important'
    };

	const handleClick = () => {
		setYoutubeModal(false);
	}
	const handleRedirection = () => {
		let label = window.location.href.split('/')[3]
		console.log(label)
		if (label ==='pricing-plan') {
			window.location.reload();
		} else {
			navigate("/pricing-plan");
		}
			
	}
    return (
		<>
		<NotificationMartOrder url={props.url} pid={localStorage.getItem('fo_profileid')}/>
		<NotificationUploadFileError url={props.url} pid={localStorage.getItem('fo_profileid')}/>
        <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
              <button className="nav-item nav-link px-0 me-xl-4 btn btn-link">
                <i className="bx bx-menu bx-sm"></i>
              </button>
            </div>
            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
				<div className='d-grid me-2'>
					{profileMasterId === '0' ? 
					<>
						<div className="text-truncate fw-bold">{profile_name}</div>
						{/* <small className='label-text mobile-text-size'> <a href='/pricing-plan'>{subscriptionPlan}</a> | {subscriptionValidity === 'Plan Expired' ?  <a href='/pricing-plan' style={{'color': 'red'}}>{subscriptionValidity}</a>  : <><a href='/pricing-plan'>{subscriptionValidity}</a></>} | {trialDays >= 0 ? <><a href='/pricing-plan'>{trialDays +' days Free Trial'}</a></> : <></>}</small> */}
						<small> <a href='/pricing-plan'>{subscriptionPlan}</a> | {subscriptionValidity === 'Plan Expired' ?  <a href='/pricing-plan' style={{'color': 'red'}}>{subscriptionValidity}</a>  : <>{planPeriod === null && trialDays !== 0 ? <><a href='/pricing-plan'>{trialDays +' days remains'}</a></> : <><a href='/pricing-plan'>{subscriptionValidity}</a></>} </>} </small>
					</>:
					<>
						<div className="text-truncate fw-bold"><Button variant="link" className="p-0" size="sm" onClick={(e) => showSubscriptionDetails(e)}><b>{profile_name}</b></Button> </div>
						<small> <a href='/pricing-plan'>{subscriptionPlan}</a> | {subscriptionValidity === 'Plan Expired' ?  <a href='/pricing-plan' style={{'color': 'red'}}>{subscriptionValidity}</a>  : <>{planPeriod === null && trialDays !== 0 ? <><a href='/pricing-plan'>{trialDays +' days remains'}</a></> : <><a href='/pricing-plan'>{subscriptionValidity}</a></>} </>} </small>
					</>}
				</div>
				<div className='ms-auto'>
					<div className="btn-group dropstart me-2">
						<button className="btn btn-success px-2" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="navbar-dropdown">
							<i className="bx bx-sm bxs-user-detail"></i>
						</button>
						<ul className="dropdown-menu">
							{profiles.map((profile,index) => {
								if( profile.id === localStorage.getItem('fo_profileid') ){
									return (
										<li key={"pid_"+index}><button disabled className="dropdown-item active" id={"pid_"+profile.id}>{profile.name}</button></li>
									)
								} else {
									return (
										<li key={"pid_"+index}><button type="button" onClick={e=>changeProfile(e.target.id,profile.sub_plan)} className="dropdown-item" id={"pid_"+profile.id}>{profile.name}</button></li>
									)
								}
							})}
						</ul>
					</div>
				</div>
              <ul className="navbar-nav flex-row align-items-center">				
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  <button className="btn btn-link nav-link dropdown-toggle hide-arrow" href="#" data-bs-toggle="dropdown">
                    <div className="avatar avatar-online">
						<p data-letters={Common.userInitial(udetails.uname)}></p>
                    </div>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <div className="dropdown-item">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-2">
                            <div className="avatar">
                              <p data-letters={Common.userInitial(udetails.uname)}></p>
							  {/*<img alt="Default User" src="assets/img/default-user-img.jpg"  className="w-px-40 h-auto rounded-circle" />*/}
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-semibold d-block">{udetails.uname}</span>
                            <small className="text-muted">{udetails.role}</small>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/profile">
                        <i className="bx bx-user me-2"></i>
                        <span className="align-middle">My Details</span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/profiles">
						<i className='bx bxs-user-account me-2'></i>
                        <span className="align-middle">All Profiles</span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/whats-new">
                        <i className='bx bxs-megaphone me-2'></i>
                        <span className="align-middle">What's New</span>
                      </a>
                    </li>
                    <li>
                      <a rel="noreferrer" className="dropdown-item" target='_blank' href="https://play.google.com/store/apps/details?id=com.fynamics.fynone">
					  	<i className='bx bxl-play-store'></i>
                        <span className="align-middle"> Download App</span>
                      </a>
                    </li>
                    
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <button className="dropdown-item" onClickCapture={logout}>
                        <i className="bx bx-power-off me-2"></i>
                        <span className="align-middle">Log Out</span>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
        </nav>
		<Modal {...props} show={viewModal} onHide={handleViewClose} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>Profile Details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Common.Row>
					<Common.Col>This is the Sub Profile of  <strong>{profileMasterName}</strong></Common.Col>
				</Common.Row>
			</Modal.Body>
		</Modal>
		<Modal {...props} show={whatsNewModal} onHide={handleWhatsNewViewClose} size="lg" aria-labelledby="whatsnew-modal" backdrop="static" keyboard={false} style={modalStyle}>
			<Modal.Header closeButton>
				<Modal.Title>Whats New</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{height: '70vh',overflowY: 'auto'}}>
				{whatsNew.map((data, i) => {
					return (
						<div key={'whatsnew_'+i} id={'whatsnew_'+i} className="row mb-4">
							<div className="col-md-3">
								<div className="date-arrow" style={{marginLeft: "0"}}>
									<div className="date-block"><br/>
										&nbsp;<div><b>{data.created_on.split(" ")[0].split("-")[2]}</b></div>
										<div><b><small>
											{data.created_on.split(" ")[0].split("-")[1] === '01' ? "Jan" : ""}
											{data.created_on.split(" ")[0].split("-")[1] === '02' ? "Feb" : ""}
											{data.created_on.split(" ")[0].split("-")[1] === '03' ? "March" : ""}
											{data.created_on.split(" ")[0].split("-")[1] === '04' ? "April" : ""}
											{data.created_on.split(" ")[0].split("-")[1] === '05' ? "May" : ""}
											{data.created_on.split(" ")[0].split("-")[1] === '06' ? "June" : ""}
											{data.created_on.split(" ")[0].split("-")[1] === '07' ? "July" : ""}
											{data.created_on.split(" ")[0].split("-")[1] === '08' ? "Aug" : ""}
											{data.created_on.split(" ")[0].split("-")[1] === '09' ? "Sept" : ""}
											{data.created_on.split(" ")[0].split("-")[1] === '10' ? "Oct" : ""}
											{data.created_on.split(" ")[0].split("-")[1] === '11' ? "Nov" : ""}
											{data.created_on.split(" ")[0].split("-")[1] === '12' ? "Dec" : ""}
										</small></b></div>
										<div><b><small>{data.created_on.split(" ")[0].split("-")[0]}</small></b></div>
									</div>
								</div>
							</div>
							<div className="col-md-9">
								<div className="card plate-card" >
									<div className="card-body">
										<div className="d-flex align-items-center" style={{'flexDirection':'column'}}>
											<p dangerouslySetInnerHTML={{ __html: (data.title) }} />
                                            <p dangerouslySetInnerHTML={{ __html: (data.description) }} />
										</div>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</Modal.Body>
			<Modal.Footer>
				<a className='btn btn-primary' href='/whats-new'>View more</a>
			</Modal.Footer>
		</Modal>
		<Modal centered show={youtubeModal} onHide={handleYoutubeViewClose} size="md" aria-labelledby="whatsnew-modal" backdrop="static" keyboard={false} style={modalStyle}>
			<Modal.Header>
				<Modal.Title >Welcome to FynOne</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			Hey There!! Welcome to FynOne - the Billing & Accounting app for Bharat<br/><br/>
			FynOne is feature loaded with deep focus on Compliance, Collaboration & Simplicity. To have a quick walkthrough of the features, visit the video link below that's hosted on Youtube. 
			</Modal.Body>
			<Modal.Footer>
				<a className='btn btn-primary' href='https://youtu.be/uFOhnS8QuEA' target='_blank' rel="noreferrer">Watch</a>
				<button type='button' className='btn btn-outline-secondary' onClick={handleClick}>Skip</button>
			</Modal.Footer>
		</Modal>
		{/* <Modal centered show={freeFinalModal} size="md" aria-labelledby="whatsnew-modal" backdrop="static" keyboard={false} style={modalStyle}>
			<Modal.Header>
				<Modal.Title>Your subscription expired</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			Your subscription to our Free plan has expired. Pl upgrade to a paid plan.<br/><br/>
			</Modal.Body>
			<Modal.Footer>
				<button type='button' className='btn btn-primary btn-sm' onClick={handleRedirection}>Pricing Plan</button>
			</Modal.Footer>
		</Modal> */}
		<Modal centered show={subscriptionModal} onHide={() => setsubscriptionModal(false)} size="md" aria-labelledby="whatsnew-modal" backdrop="static" keyboard={false} style={modalStyle}>
			<Modal.Header closeButton>
				<Modal.Title >Your subscription expired</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			Your subscrription has expired. Please renew or upgrade to a higher plan to avoid service disruption.<br/><br/>
			</Modal.Body>
			{/* <Modal.Footer>
				<button type='button' className='btn btn-primary btn-sm' onClick={handleRedirection}>Pricing Plan</button>
			</Modal.Footer> */}
		</Modal>
		<Modal centered show={subscriptionFinalModal} onHide={() => setsubscriptionFinalModal(false)} size="md" aria-labelledby="whatsnew-modal" backdrop="static" keyboard={false} style={modalStyle}>
			<Modal.Header>
				<Modal.Title >Your renewal is overdue</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			Your subscription expired long back and renewal is overdue. Pl renew or upgrade your subscription to continue.<br/><br/>
			</Modal.Body>
			<Modal.Footer>
				<button type='button' className='btn btn-primary btn-sm' onClick={handleRedirection}>Pricing Plan</button>
			</Modal.Footer>
		</Modal>
		</>
    );
}