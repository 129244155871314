import React, { useState,useEffect,useRef } from 'react'
import GuestHeader from './GuestHeader';
import * as Common from '../common/ImportGuestEssentials';
import ReCAPTCHA from "react-google-recaptcha";
import '../styles/page-auth.css';

export default function SignUp(props){
    const [email, setEmail]       = useState('');
    const [password, setPassword] = useState('');
    const [name, setName]     = useState('');
    const [mobile, setMobile]     = useState('');
    const [clicked, setClicked]   = useState('');
    const [errorMessage,setError] = useState('');
	const [showPass,setShowPass]             = useState(false);
    const [referralCode,setReferralCode] = useState('');
	const [showReferralCode,setShowReferralCode] = useState(false);
    const [referralId,setReferralId] = useState('');
    const [referralMessage,setReferralMessage] = useState('');
    const [referralMessageClass,setReferralMessageClass] = useState('');
    const navigate = Common.useNavigate();
	const captchaRef = useRef(null);
	const [displayEmail,setDisplayEmail] = useState(false);
	const { state } = Common.useLocation();
	
	useEffect(() => {
		document.title = 'SignUp | ' + props.site_name;
		if( Common.secureLocalStorage.getItem('fm_loggedin') ){
			navigate("/post-login");
		}
		if(state !== null){
			setMobile(state.mobileNo);
			setEmail(state.emailId);
			if(state.emailId){
				setShowPass(true);
			}
		}
	}, [props,navigate]);
	const setAddiFields =(val)=>{
		setEmail(val);
		if( val !== '' ){
			setShowPass(true);
			return false;
		}
		setShowPass(false);
		return false;
    }
    const handleClick=(e)=>{
        e.preventDefault();
        if(document.getElementById('password').getAttribute('type')==='text'){
			document.getElementById('password').setAttribute('type', 'password');
			setClicked('bx bx-hide');
        }else if(document.getElementById('password').getAttribute('type')==='password'){
			document.getElementById('password').setAttribute('type', 'text');
			setClicked('bx bx-show');
        }
     }
	 const handleReferralCode=(e)=>{
        e.preventDefault();
		setShowReferralCode((showReferralCode) ? false : true);
		setReferralCode('');
		setReferralMessageClass('');
		setReferralMessage('');
		setReferralId('');
     }
	 const handleClearClick=(e)=>{
        e.preventDefault();
		setReferralCode('');
		setReferralMessageClass('');
		setReferralMessage('');
		setReferralId('');
     }
	 const getReferralCode = (val) => {
		setReferralCode(val);
		setReferralId('');
		setReferralMessageClass('');
		setReferralMessage('');
		if( val.length >= 5 ){
			Common.axios({
				method: 'get',
				url: props.url+'register/getReferralCode/'+val,
			}).then(function (response) {
				if(response.data.success){
					setReferralId(response.data.ref_id);
					setReferralMessageClass('referral_text');
					setReferralMessage(response.data.message);
					return false;
				}else{
					setTimeout(() => {
						setReferralCode('');
					}, 2000);
					setReferralMessageClass('text-danger');
					setReferralMessage(response.data.message);
				}
			}).catch(error => console.log(error));
		}
	 }
	 const handleMouseEvent = (val) => {
		if( val && val.length < 5 ){
			setTimeout(() => {
				setReferralCode('');
			}, 2000);
			setReferralMessageClass('text-danger');
			setReferralMessage('Invalid Referral Code.');
		}
	 }
     const handleSubmit =(e) => {
        e.preventDefault();
		const token = captchaRef.current.getValue();
		let formData = new FormData(e.target);
		formData.append('referralId', referralId);
		let data = {};
        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = Common.AES.encrypt(value, props.site_key).toString();
		}
		data['token'] = token;
        Common.axios({
            method: 'post',
            url:props.url+'register/submit',
            data: data,
        }).then(function (response) {
			if(response.data.success){
				navigate('/verify-mobile',
				{
					state: {
						uid: response.data.id,
						type: 'signup',
						last4: mobile.slice(-4)
					}
				});
				return false;
			}
			setError(response.data.message);
			setTimeout(() => {
				setError('');
			}, 5000);
			captchaRef.current.reset();
        }).catch(error => console.log(error));
    }
	const displayEmailField = () => {
		setDisplayEmail((displayEmail) ? false : true)
	}
    return(
        <div className="container-xxl">
			<div className="authentication-wrapper authentication-basic container-p-y">
				<div className="authentication-inner">
					<div className="card">
						<div className="card-body">
							<GuestHeader site_logo={props.site_logo} site_name={props.site_name} />
							<h4 className="mb-2">Welcome to  {props.site_name}!</h4>
							<p className="mb-4">Simplifying Financial Management</p>
							<form id="formAuthentication" className="mb-3" autoComplete="off" onSubmit={handleSubmit}>
								{errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
								{errorMessage}</div>)}
								<div className="mb-3">
									<label htmlFor="name" className="form-label">Name<span className="text-danger">*</span></label>
									<input type="text" className="form-control" id="name" name="name" value={name} placeholder="Enter name" required onChange={(e) => setName(Common.acceptOnlyChars(e.target.value))} maxLength="50" autoFocus />
								</div>
								<div className="mb-3">
									<label htmlFor="mobile" className="form-label">Mobile<span className="text-danger">*</span></label>
									<input type="text" className="form-control" id="mobile" name="mobile" value={mobile} placeholder="Enter mobile" maxLength="10" required onChange={(e) => setMobile(Common.acceptOnlyNumbers(e.target.value))} />
								</div>
								<div className="mb-3">
									<button type='button' className='text-primary border-0 bg-white p-0 cursor-pointer' onClick={displayEmailField}>Add Email</button>
								</div>
								{displayEmail && (<>
									<div className="mb-3">
										<label htmlFor="email" className="form-label">Email</label>
										<input type="email" className="form-control" id="email" name="email" placeholder="Enter your email (optional)" value={email} onChange={(e) => setAddiFields(e.target.value)} />
									</div>
								</>)}
								{showPass && (<>
								<div className="mb-3 form-password-toggle">
									<label className="form-label" htmlFor="password">Password</label>
									<div className="input-group input-group-merge">
										<input type="password" id="password"  className="form-control" name="password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
										<span className="input-group-text cursor-pointer"><i className={clicked || 'bx bx-hide' } onClick={handleClick} ></i></span>
									</div>
								</div>
								</>)}
								<div className="mb-3">
									<button className='text-primary border-0 bg-white p-0 cursor-pointer' onClick={handleReferralCode}>Have a Referral Code</button>
								</div>
								{showReferralCode && (<>
								<div className="mb-3">
									<label className="form-label" htmlFor="referral_code">Referral Code</label>
									<div className="input-group input-group-merge">
										<input type="text" id="referral_code"  className="form-control" name="referral_code" placeholder="Referral Code" maxLength="5" value={referralCode} onChange={(e) => getReferralCode(Common.acceptOnlyCharsNumbersNoSpace(e.target.value.toUpperCase()))} onMouseLeave={(e) =>handleMouseEvent(Common.acceptOnlyCharsNumbersNoSpace(e.target.value.toUpperCase()))}/>
										<span className="input-group-text cursor-pointer"><i className={referralCode?'bx bx-x clear_button':'clear_button' } onClick={handleClearClick} ></i></span>
									</div>
									<span className={referralMessageClass+' smaller_font'}>{ referralMessage }</span>
								</div>
								</>)}
								<div className="my-2">
									<ReCAPTCHA className="g-recaptcha" ref={captchaRef} sitekey={props.recaptcha_key} />
								</div>
								<div className="mb-3">
									<div className="form-check">
										<input className="form-check-input" type="checkbox" id="terms-conditions" name="terms" required/>
										<label className="form-check-label" htmlFor="terms-conditions">
											I agree to
											<a href="https://www.fynamicsgst.in/about/privacy/" target="_blank" rel="noreferrer"> privacy policy & terms</a>
										</label>
									</div>
								</div>
								<button className="btn btn-primary d-grid w-100">Sign up</button>
							</form>
							<p className="text-center">
								<span>Already have an account? </span>
								<Common.Link to="/login">
									<span>Login instead</span>
								</Common.Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
    );
}