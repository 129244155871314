import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import * as Common from '../common/ImportUserEssentials';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';

export default function AddAsset(props){
	const [asset_type_master,setAssetTypeMaster]     = useState([]);
	const [asset_name_master,setAssetNameMaster]     = useState([]);
	const [asset_type,setAssetType]                  = useState('');
	const [asset_name,setAssetName]                  = useState('');
	const [assetNameDisable, setAssetNameDisable]    = useState(true);
	const [asset_no,setAssetNo]                      = useState('');
	
	const [asset_provider_name,setAssetProviderName] = useState('');
	const [purchase_value,setPurchaseValue]          = useState('');
	const [purchase_date,setPurchaseDate]            = useState('');
	const [useful_life,setUsefulLife]                = useState('');
    
	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Add Asset | '+props.site_name;
        if (localStorage.getItem('fo_subplan') === 'Basic') {
			navigate("/pricing-plan");
		}

		// get Asset type master
		Common.axios({
			method: 'post',
				url: props.url+'assets/fetch_asset_type',
				data: {
					'pid': localStorage.getItem('fo_profileid')
				}
		}).then(function (response) {
			if( response.data.success ){
				setAssetTypeMaster(response.data.data);
			}
		}).catch(error => console.log(error));

        // Fetch Asset No
        const getAssetNo = () => {
            Common.axios({
                method: 'post',
                url:props.url+'assets/getAssetNo',
                data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
            }).then(function (response) {
                if(response.data.success){
                    setAssetNo(response.data.data);
                    return false;
                }
                Common.toast.error(response.data.message);
            }).catch(error => console.log(error))
		}
        getAssetNo();
        const asset_no_interval = setInterval(() => {getAssetNo()},10000);
        

        return () => clearInterval(asset_no_interval);

	}, []);
    const setAssetTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setAssetType(value);
        setAssetNameDisable((value)?false:true);
        Common.axios({
			method: 'post',
				url: props.url+'assets/fetch_asset_name',
				data: {
					'pid': localStorage.getItem('fo_profileid'),
					'asset_type_master_id':value
				}
		}).then(function (response) {
			if( response.data.success ){
				setAssetNameMaster(response.data.data);
			}
		}).catch(error => console.log(error));
	}
    const setAssetNameSelect = (e) => {
		let value = (e[0]) ? e[0].label : '';
		setAssetName(value);
	}
    const handleAsset =(e) => {
		e.preventDefault();
        Common.showLoader(true);
		let formData = new FormData(e.target);
		let pid = localStorage.getItem('fo_profileid');
		formData.append('pid', pid);
		formData.append('asset_type', asset_type);
		formData.append('asset_name', asset_name);
		let data = {};
		// convert the key/value pairs
		for (var [key, value] of formData.entries()) {
		   data[key] = value;
		}

		Common.axios({
			method: 'post',
			url: props.url+'assets/add',
			data: data
		}).then(function (response) {
            Common.showLoader(false);
			if(response.data.success){
				navigate("/assets-listing");
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
  
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="add-asset" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Assets Management /</span> Add Asset</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            <form id="formAddCollection" onSubmit={handleAsset} autoComplete="off" className='formAddCollection'>
                                                <div className="row" id="collectionAddDiv">
													<div className="col-12">
                                                        <div className="row">
                                                            <div className="col-md-6 col-sm-6 mb-3">
                                                                <div className="form-floating">
                                                                    <Typeahead clearButton id="frmAssetType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setAssetTypeSelect(e)} options={asset_type_master} placeholder="Choose Asset Type"
                                                                            renderMenuItemChildren={(option: Item,props) => (
                                                                                <>
                                                                                <Highlighter search={props.text}>
                                                                                    {option.label}
                                                                                </Highlighter>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    <label htmlFor="frmAssetType">Asset Type <span className="text-danger">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-sm-6 mb-3">
                                                                <div className="form-floating">
                                                                    <Typeahead disabled={assetNameDisable} clearButton allowNew newSelectionPrefix="Add: "  id="frmAssetName" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setAssetNameSelect(e)} options={asset_name_master} placeholder="Choose Asset Name"
                                                                    renderMenuItemChildren={(option: Item,props) => (
                                                                        <>
                                                                        <Highlighter search={props.text}>
                                                                            {option.label}
                                                                        </Highlighter>
                                                                        <div>
                                                                            <small>{option.description}</small>
                                                                        </div>
                                                                        </>
                                                                    )}
                                                                    />
                                                                    <label htmlFor="frmAssetName">Asset Name</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-sm-6 mb-3">
                                                                <div className="form-floating">
                                                                    <input name="asset_no" type="text" maxLength="10" className="form-control" id="frmAssetNo" placeholder="Enter Asset Id" value={asset_no} readOnly/>
																	<label htmlFor="frmAssetNo">Asset Id</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-sm-6 mb-3">
                                                                <div className="form-floating">
                                                                    <input name="asset_provider_name" type="text" className="form-control" id="frmAssetProviderName" placeholder="Enter Asset Provider Name" onChange={(e) => { setAssetProviderName(Common.acceptOnlyCharsNumbers(e.target.value)); }}  value={asset_provider_name} />
                                                                    <label htmlFor="frmAssetProviderName">Asset Provider Name</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-sm-6 mb-3">
                                                                <div className="form-floating">
                                                                    <input type="text" name="purchase_value" className="form-control" id="frmPurchaseValue" placeholder="0.00" value={purchase_value} onChange={(e) => { setPurchaseValue(Common.acceptOnlyDecimal(e.target.value)); }} required/>
																	<label htmlFor="frmPurchaseValue">Purchase value <span className="text-danger">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-sm-6 mb-3">
                                                                <div className="form-floating">
                                                                    <DatePicker name="purchase_date" data-testid="frmPurchaseDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setPurchaseDate} value={purchase_date} />
                                                                    <label htmlFor="frmPurchaseDate">Purchase date</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-sm-6 mb-3">
                                                                <div className="form-floating">
                                                                    <input name="useful_life" type="text" maxLength="12" className="form-control" id="frmUsefulLife" placeholder="Enter Useful life of asset" onChange={(e) => { setUsefulLife(Common.acceptOnlyDecimal(e.target.value));}}  value={useful_life} />
																    <label htmlFor="frmUsefulLife">Useful life of asset (in years)</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12 text-center">
                                                                <a href="/assets-listing" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
                                                                &nbsp;&nbsp;
                                                                <button type='submit' className="btn btn-sm btn-primary"><i className='bx bx-save' ></i> Save</button>
                                                            </div>
                                                        </div>
													</div>
												</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}