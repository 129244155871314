import React  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import styled from 'styled-components';

import 'bootstrap-daterangepicker/daterangepicker.css';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Typeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;

export default function Investments(props){
	
	const [filterText, setFilterText]                = React.useState('');
	const [DTpending, setDTPending]                  = React.useState(true);
	const [dt_load,setDtLoad]                        = React.useState(true);
	const [totalRows, setTotalRows]                  = React.useState(0);
	const [perPage, setPerPage]                      = React.useState(10);
	const [totalDataCnt,setTotalDataCount]           = React.useState({'Equity investments received':''});
	const [totInvAmt,setTotInvAmt]                   = React.useState({'Equity investments received':'-'});
    const [totShare,setTotShare]                     = React.useState({'Equity investments received':'-'});
	const tabTypes                                   = ['Equity investments received'];
	const [activeTab, setActiveTab]                  = React.useState(tabTypes[0]);
	const [investmentsList, setInvestmentsList]      = React.useState([]);
    // const [exportBtnClass,setExportBtnClass]         = React.useState('d-none');
	const [showInvestmentsDiv,setShowInvestmentsDiv] = React.useState(true);
	const [loanInvestment_r,setLoanInvestment_r]     = React.useState(true);
	const [loanInvestment_w,setLoanInvestment_w]     = React.useState(true);
    const [activeCancel, setActiveCancel]            = React.useState(false);
	const [invtId,setInvtId]                         = React.useState('');
	const [cancelDivShow,setCancelDivShow]           = React.useState(false);
	const [cancelReason,setCancelReason]             = React.useState('');

    
	const [transferDivShow,setTransferDivShow]       = React.useState(false);
	const [toShareholder,setToShareholder]           = React.useState([]);
	const [totalShare,setTotalShare] 		     	 = React.useState('');
	const [fromShareholderId,setFromShareholderId]   = React.useState('');
	const [toShareholderId,setToShareholderId]       = React.useState('');
	const [number_of_share,setNumberOfShares] 	     = React.useState('');
	const [updateFromFolio,setupdateFromFolio]       = React.useState('');
	const [updateToFolio,setupdateToFolio] 		     = React.useState('');
	const [fromSholderName,setfromSholderName] 		 = React.useState('');
    const [sortOrder,setSortOrder]                   = React.useState('');
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));

	const navigate = Common.useNavigate();
	
	let state  = Common.useLocation();

    React.useEffect(() => {
        document.title = 'Investments Listing | '+props.site_name;
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Read permission
			setLoanInvestment_r((userPerm[8]['view'] !== undefined && userPerm[8]['view'] === '0') ? false : true);
		}
		if( Object.keys(userPerm).length > 0 ){
			setLoanInvestment_w((userPerm[8]['write'] !== undefined && userPerm[8]['write'] === '0') ? false : true);
		}
        if (localStorage.getItem('fo_subplan') === 'Basic') {
			navigate("/pricing-plan");
		}
		loadDataTable(1,perPage,filterText,sortOrder,activeTab,activeCancel);
        
	}, [props,dt_load,filterText,state]);
	if( !loanInvestment_r ){
		navigate("/not-authorized");
	}
	const loadDataTable = (page,perPage,filterText,sort='',active_tab=activeTab,activeCancel) => {
        let active_cancel = (activeCancel)?'C':'A';
		Common.axios({
            method: 'post',
            url:props.url+'investments/listing/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText,'active_tab':active_tab,'active_cancel':active_cancel}
        }).then(function (response) {
			// setExportBtnClass('d-none');
            if(response.data.success){
				setInvestmentsList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(prevState => ({
				...prevState,
					[active_tab]: response.data.total
				}));
                setTotShare(prevState => ({
                ...prevState,
                    [activeTab]: response.data.tabInfo.total_share
                }));
                setTotInvAmt(prevState => ({
                ...prevState,
                    [activeTab]: response.data.tabInfo.amount
                }));
				// if(response.data.data.length > 0){
				// 	setExportBtnClass('');
				// }
				setDTPending(false);
            }
        }).catch(error => console.log(error));
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,sortOrder,activeTab,activeCancel);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText,sortOrder,activeTab,activeCancel);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
        setSortOrder(btoa(column.sortField+'####'+sortDirection));
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection),activeTab,activeCancel);
	};
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
    const columns = [
        {
            name: 'Shareholder name',
            sortable: true,
			sortField: 'shareholder',
			cell: (row) => (
				row.shareholder
			)
        },
        {
            name: 'Demat account',
            selector: row => row.demat_account,
            sortable: false,
			sortField: 'demat_account',
			cell: (row) => (
				row.demat_account
			)
        },
		{
            name: 'Face value',
            selector: row => row.face_value,
            sortable: true,
			sortField: 'face_value',
			cell: (row) => (
				row.face_value
			)
        },
		{
            name: 'No. of shares',
            selector: row => row.no_of_shares,
            sortable: true,
			sortField: 'no_of_shares',
			cell: (row) => (
				row.no_of_shares
			)
        },
		{
            name: 'Price',
            selector: row => row.price,
            sortable: true,
			sortField: 'price',
			cell: (row) => (
				row.price
			)
        },
		{
            name: 'Investment amount',
            selector: row => row.investment_amt,
            sortable: true,
			sortField: 'investment_amt',
			cell: (row) => (
				row.investment_amt
			)
        },
		{
            name: 'Premium amount',
            selector: row => row.premium_amt,
            sortable: true,
			sortField: 'premium_amt',
			cell: (row) => (
				row.premium_amt
			)
        },
		{
            name: 'Investment date',
            selector: row => row.investment_date,
            sortable: true,
			sortField: 'investment_date',
			cell: (row) => (
				row.investment_date
			)
        },
		{
            name: 'Folio numbers',
            selector: row => row.folio_numbers,
            sortable: true,
			sortField: 'folio_numbers',
			cell: (row) => (
				row.folio_numbers
			)
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
			sortField: 'created_by',
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on',
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
			sortField: 'updated_by',
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on',
        },

    ];
    if( activeCancel ) {
		columns.push(
            {
                name: 'Cancel Reason',
                selector: row => row.cancel_reason,
                sortable: true,
                sortField: 'cancel_reason'
            }
        );
	}
    if(!activeCancel){
        columns.unshift(
            {
                name: 'Action',
                button: true,
                cell: (row) => ( loanInvestment_w === true ? <>
                    <div className="dropdown">
                        <button type="button" className="btn me-2 p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu" >
                        <button className="dropdown-item" onClick={(e) => handleTransferShareModel(row.id,row.shareholder_id,row.no_of_shares,row.shareholder)}><i className='bx bxs-share' ></i> Transfer share</button>
                            <button className="dropdown-item" onClick={(e) => handleCanelModel(row.id)}><i className='bx bx-message-square-x'></i> Cancel Investments</button>
                        </div>
                      </div>
                  </> :<></>
                )
            }
        );
    }
	const handleCanelModel = (val) => {
        setInvtId(val);

        setCancelDivShow(true);
        setCancelReason('');
    }
    const handleCancelInvestment = () => {
        Common.showLoader(true);
        let url = props.url+'investments/update';
        Common.axios({
            method: 'post',
            url: url,
            data: {'invt_id':invtId,'cancel_reason':cancelReason,'status':'C'}
        }).then(function (response) {
            Common.showLoader(false);
            if(response.data.success){
                Common.toast.dismiss();
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
                    setCancelDivShow(false);
                    loadDataTable(1,perPage,filterText,sortOrder,activeTab,activeCancel);
                    return false;
                }else{
                    Common.toast.error(response.data.message);
                }
                return false;
            }
            const err_html = () => (
                Common.parse(response.data.message)
            );
            Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    const handleTransferShareModel = (val,shareholder_id,total_share,from_shareholder_name) => {
        setInvtId(val);
        setFromShareholderId(shareholder_id);
		handleTransferShareholderList(shareholder_id);
        setTotalShare(total_share);
        setfromSholderName(from_shareholder_name);
        setToShareholderId('');
        setNumberOfShares('');
        setupdateFromFolio('');
        setupdateToFolio('');
        setTransferDivShow(true);
    }
    const handleTransferShareholderList = (shId)=>{
		if(shId){
			Common.axios({
                method: 'post',
                    url: props.url+'investments/fetch_shareholder',
                    data: {
                        'pid': localStorage.getItem('fo_profileid'),
                        'shId' : shId
                    }
            }).then(function (response) {
                if( response.data.success ){
                    setToShareholder(response.data.data);
                }
            }).catch(error => console.log(error));
		}
    }
    const handleTransferShare = () => {
        if( toShareholderId === ''){
			Common.toast.dismiss();
			Common.toast.error('To Shareholder is required.');
			return false;
		}
        if( number_of_share === ''){
			Common.toast.dismiss();
			Common.toast.error('Number of Share is required.');
			return false;
		}
        if(Number(number_of_share) > Number(totalShare)){
            Common.toast.dismiss();
			Common.toast.error('Number of Share Lesser than or equal to Total Share.');
			return false;
        }
        Common.showLoader(true);
        let url = props.url+'investments/transfer_share';
        Common.axios({
            method: 'post',
            url: url,
            data: {'from_shareholder_id':fromShareholderId,'to_shareholder_id':toShareholderId,'number_of_share':number_of_share,'update_from_folio':updateFromFolio,'update_to_folio':updateToFolio,'totalShare':totalShare,'invtId':invtId,'pid': localStorage.getItem('fo_profileid')}
        }).then(function (response) {
            Common.showLoader(false);
            if(response.data.success){
                Common.toast.dismiss();
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
                    setTransferDivShow(false);
                    loadDataTable(1,perPage,filterText,sortOrder,activeTab,activeCancel);
                    return false;
                }else{
                    Common.toast.error(response.data.message);
                }
                return false;
            }
            const err_html = () => (
                Common.parse(response.data.message)
            );
            Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
    const setToShareholderSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setToShareholderId(value);
	}
	const handleExportCSV = (e)=>{
        e.preventDefault();        
        let active_cancel = (activeCancel)?'C':'A';
        Common.axios({
            method: 'post',
            url:props.url+'investments/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText,'active_tab':activeTab,'active_cancel':active_cancel},
        }).then(function (response) {
			if(response.data.success){
                window.location.href = response.data.message;
			}
        }).catch(error => console.log(error));
    }
	function TabGroup() {
		const handleTab = bankChecked => {
			// setExportBtnClass('d-none');
			setActiveTab(bankChecked);
            setActiveCancel(false);
			state.state = null
			loadDataTable(1,perPage,filterText,sortOrder,bankChecked,activeCancel);
			if(bankChecked === "Equity investments received"){
				setShowInvestmentsDiv(true);
			}
			else{
				setShowInvestmentsDiv(false);
			}
		};
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{tabTypes.map(type => (
				<Tab
					key={type}
					active={activeTab === type}
					onClick={() => handleTab(type)}
				>
					{(totalDataCnt[type] !== '') ? type + ' ('+totalDataCnt[type]+')' : type}
                    <div className="tabAdditionalInfo">
                        {(type === 'Equity investments received')?'Tot. Share.#'+ totShare[type] + '|':''} Tot. Amt.(₹) {Common.numToWords(totInvAmt[type])}
                    </div>
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
    const handleactiveCancel = nextChecked => {
        setActiveCancel(nextChecked);
        loadDataTable(1,perPage,filterText,sortOrder,activeTab,nextChecked);
    };
    return (
		<>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="investments-listing" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Loans & Investments /</span> Investments</h4>
							<div className="row ">
								<div className="col-sm-12">
									<div className="row pb-3">
										<div className="col-md-8 col-12">
											<TabGroup/>
										</div>
									</div>
								</div>
								<div id="sdt_cont" className="col-sm-12">
									{showInvestmentsDiv && (<>
										{ loanInvestment_w && (<>
											<div className="row">
												<div className="col-md-4 col-8 listing-tabs mb-1">
													<button className={"btn btn-sm btn-info me-2 "} onClick={handleExportCSV}>
														<i className="bx bx-download"></i><span className="hidden-phone">Export CSV</span>
													</button>
													<Common.Link to="/add-investments" className="btn btn-primary btn-sm">
														<i className="bx bx-plus"></i><span className="hidden-phone">Add Investment</span>
													</Common.Link>
												</div>
                                                <div className="col-md-3 col-4 text-right text-md-center mb-1 collected-filters">
                                                    <label>
                                                        <BootstrapSwitchButton
                                                            onChange={handleactiveCancel}
                                                            checked={activeCancel}
                                                            onlabel='Cancelled'
                                                            onstyle='danger'
                                                            offlabel='Active'
                                                            offstyle='success'
                                                            width='100'
                                                            size='sm'
                                                        />
                                                    </label>
                                                </div>
												<div className="col-md-5 col-12 text-end mb-1">
													<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Shareholder name')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
													<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
												</div>
											</div>
										
										</>)}
										<DTE.DataTable className="dataTables_wrapper rdt_AdjDrp" columns={columns} data={investmentsList} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</>)}
								</div>
							</div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal size='md' show={cancelDivShow} onHide={()=>setCancelDivShow(false)} aria-labelledby="Invt-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Invt-Cancel-modal">Cancel Investment</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addInvtCancelFrm" autoComplete="off">
					<div className="row">
                        <div className="col-sm-12">
                            <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                            <div className="input-group input-group-merge">
                                <textarea rows="2" id="cancel-reason" name="cancel_reason" className="form-control" placeholder="Cancel Reason" aria-label="CancelReason" aria-describedby="Reason" value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} required></textarea>
                            </div>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-1">
							<button type='button' className="btn btn-sm btn-danger" onClick={(e) => handleCancelInvestment()}><i className='bx bx-message-square-x' ></i>&nbsp;Cancel</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
        <Modal size='md' show={transferDivShow} onHide={()=>setTransferDivShow(false)} aria-labelledby="Transfer-Share-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Transfer-Share-modal">Transfer Share From ({fromSholderName})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addTransferShareFrm" autoComplete="off">
                    <div className="row mb-3">
                        <label className="col-4 col-form-label">Number of Share</label>
                        <div className="col-8">
                            <p className="mt-1">{ totalShare }</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-4 col-form-label" htmlFor="frmToShareholderName">To Shareholder <span className="text-danger">*</span></label>
                        <div className="col-8">
                            <Typeahead clearButton id="frmToShareholderName" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setToShareholderSelect(e)} 
                                options={toShareholder} placeholder="Choose To Shareholder"
                                renderMenuItemChildren={(option: Item,props) => (
                                    <Highlighter search={props.text}>
                                        {option.label}
                                    </Highlighter>
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-4 col-form-label" htmlFor="frmNumberOfShares">Number of shares <span className="text-danger">*</span></label>
                        <div className="col-8">
                            <input type="text" name="number_of_share" className="form-control" id="frmNumberOfShares" placeholder="0.000" value={number_of_share} onChange={(e) => { setNumberOfShares(Common.acceptOnlyDecimal(e.target.value)); }} required/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-4 col-form-label" htmlFor="frmFromFolioNumbers">Folio for From shareholder</label>
                        <div className="col-8">
                            <input name="from_folio_numbers" type="text"  className="form-control" id="frmFromFolioNumbers" placeholder="Enter From Folio Numbers" onChange={(e) => { setupdateFromFolio(e.target.value); }} value={updateFromFolio} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-4 col-form-label" htmlFor="frmToFolioNumbers">Folio for To shareholder</label>
                        <div className="col-8">
                            <input name="to_folio_numbers" type="text"  className="form-control" id="frmToFolioNumbers" placeholder="Enter To Folio Numbers" onChange={(e) => { setupdateToFolio(e.target.value); }} value={updateToFolio} />
                        </div>
                    </div>
					<div className="row">
						<div className="col-sm-12 text-center mt-1">
							<button type='button' className="btn btn-sm btn-success" onClick={(e) => handleTransferShare()}><i className='bx bx-save'></i>&nbsp;Submit</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
		</>
    );
}