import React,{useState,useEffect,useRef} from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import * as Common from '../common/ImportUserEssentials';
import '../styles/page-misc.css';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import Button from 'react-bootstrap/Button';
import PureCounter from "@srexi/purecounterjs";
import TagManager from 'react-gtm-module'; 
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import ReCAPTCHA from "react-google-recaptcha";

export default function Icsi(props){
    const [mrn_number, setMRNNumber] 		  = useState('');
	const [otp, setOtp] 					  = useState('');
	const [user_otp, setUserOtp] 			  = useState('');
	const [docDate, setDocDate] 			  = useState('');
	const [respMobile, setRespMobile]         = useState('');
	const [mobile, setMobile]                 = useState('');
	const [applicantName, setApplicantName]   = useState('');
	const [verifyOTPShow, setVerifyOTPShow] = useState(false);
	const [seconds, setSeconds] = useState(30);
	const [runTimer,setRunTimer] = useState(false);
	const captchaRef = useRef(null);
    useEffect(() => {
		document.title = 'FYN ONE - Billing & Accounting app for Bharat | Shipping & Delivery Policy';
		if(runTimer){
			const sec_interval = setInterval(() => {
				if (seconds > 0) {
				  setSeconds(seconds - 1);
				}
				if (seconds === 0) {
					clearInterval(sec_interval);
				}
			}, 1000);
			return () => clearInterval(sec_interval);
		}
		new PureCounter();
		const hostname = window.location.hostname;
		if( hostname === 'www.fynone.com' ){
			const tagManagerArgs = {
				gtmId: 'G-RF6E5H5MTZ'
			}
	 
			TagManager.initialize(tagManagerArgs);
		}
		const script = document.createElement('script');

        script.src = 'landing/js/main.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
	}, [seconds, runTimer]);

	const setOtpValue = (val) => {
		setOtp(Common.acceptOnlyNumbers(val))
	}
	const submitOTP = () => {
		if( otp.length !== 6 ){
			Common.toast.error('Invalid OTP entered.');
			return false;
		}
        if(otp == user_otp){
			setOtpValue('');
			setVerifyOTPShow(false);
			
			storeUser();
			return false;
		} else {
			setOtpValue('');
			Common.toast.error('Wrong OTP entered.');
			return false;
		}
	}
	const handleOTPSubmit =(e) => {
        e.preventDefault();
		submitOTP();
    }

	const otpVerification = () => {
		setVerifyOTPShow(true);
		setRunTimer(true);
		return false;
	}
	const handleSubmit =(e) => {
        e.preventDefault();
		const token = captchaRef.current.getValue();
		let formData = new FormData(e.target);
		let valotp = Math.floor(100000 + Math.random() * 900000);
		setUserOtp(valotp);
		formData.append('otp', valotp); 
		formData.append('dob', docDate); 
		let data = {};
        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = Common.AES.encrypt(value, props.site_key).toString();
		}
		data['token'] = token;
        Common.axios({
			method: 'post',
            url:props.url+'icsi/register',
            data: data,
        }).then(function (response) {
			if(response.data.success === 1){
				setRespMobile(response.data.resp_mobile)
				setApplicantName(response.data.applicant_name)
				otpVerification();
				Common.toast.success(response.data.message_otp)
			}else if (response.data.message === 'Invalid recaptcha' || response.data.message === 'Recaptcha required') {
				Common.toast.error(response.data.message)
				return false;
			} else {
				let msg = response.data.message;
				confirmAlert({
					title: 'Registration Failed',
					message: msg,
					buttons: [{
						label: 'Try Again',
						onClick: () => {
							window.location.reload()
						}
					}]
				});
			}
			captchaRef.current.reset();
        }).catch(error => console.log(error));
    }
	const storeUser = () => {
		Common.axios({
			method: 'post',
			url:props.url+'icsi/storeUser',
			data: {'name':Common.AES.encrypt(applicantName, props.site_key).toString(),'mobile':Common.AES.encrypt(respMobile, props.site_key).toString()},
		}).then(function (response) {
			if(response.data.success === 1){
				if (response.data.message === 'Successfully Registered') {
					if (response.data.message_two !== '') {
						confirmAlert({
							title: 'Success',
							message: response.data.message + response.data.message_two,
							buttons: [{
								label: 'Login to FynOne',
								onClick: () => {
									window.location.href = '/login'
								}
							}]
						});
					} else {
						confirmAlert({
							title: 'Success',
							message: response.data.message,
							buttons: [{
								label: 'Login to FynOne',
								onClick: () => {
									window.location.href = '/login'
								}
							}]
						});
					}
					
				} else if (response.data.message === 'No data found on ICSI portal. Pl recheck the form details and retry') {
					// Common.toast.error(response.data.message);
					confirmAlert({
						title: 'Registration Failed',
						message: response.data.message,
						buttons: [{
							label: 'Try Again',
							onClick: () => {
								window.location.reload()
							}
						}]
					});
				} else if (response.data.message === 'Membership number is already registered with us. Please login or enter a new one.') {
					let msg = '';
					msg = 'Membership number is already registered with us. Please login or enter a new one.';
					confirmAlert({
						title: 'Registration Failed',
						message: msg,
						buttons: [{
							label: 'Try Again',
							onClick: () => {
								window.location.reload()
							}
						}]
					});
				}
			} else {

				Common.toast.error(response.data.message)
			}
			setOtpValue('');
		}).catch(error => console.log(error));
	}
	if(otp.length === 6){
		submitOTP();
	}
	return (
		<React.Fragment>
			<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Dosis:300,400,500,,600,700,700i|Lato:300,300i,400,400i,700,700i" rel="stylesheet" />
			{/* <div className="container-xxl container-p-y"> */}
				<header id="header" className="fixed-top">
					<div className="container d-flex align-items-center justify-content-between">

						<a href="/" className="logo"><img alt="FYNONE Logo" height="65" width="auto" src={props.site_logo}></img></a>
						<nav id="navbar" className="navbar">
							<ul>
								<li><a className="nav-link scrollto active" href="#hero" style={{'overflowAnchor': 'none'}}>Home</a></li>
								<li><a className="nav-link scrollto" href="#about" style={{'overflowAnchor': 'none'}}>Features</a></li>
								<li><a className="nav-link scrollto" href="#services" style={{'overflowAnchor': 'none'}}>Modules</a></li>
								<li><a className="nav-link scrollto " href="#counts" style={{'overflowAnchor': 'none'}}>About</a></li>
								<li><a className="nav-link scrollto" href="#gallery" style={{'overflowAnchor': 'none'}}>Mobile App</a></li>
								<li><a className="nav-link scrollto" href="#contact" style={{'overflowAnchor': 'none'}}>Contact</a></li>
							</ul>
							<i className="bi bi-list mobile-nav-toggle"></i>
						</nav>
					</div>
				</header>
				<section id="hero" className="d-flex">
					<div className="container">
						<div className="row pt-4">
							<div className="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-1 d-flex flex-column justify-content-center">
								<h1>The modern Billing & Accounting app for Company Secretaries</h1>
								<h2>Simple, Collaborative & Compliance driven</h2>
							</div>
							<div className="col-lg-2 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center"></div>
							<div className="col-lg-4 order-3 order-lg-2 hero-img">
								<div className="card">
									<div className="card-body">
									<p className="ribbon">
										<span className="text">
											<strong className="bold" style={{'fontSize': '20px','margin':'0'}}>FREE - 3 Years</strong><br/>
											<span className="bold" style={{'fontSize':'14px','margin':'0','padding':'0'}}>PRO Subscription</span>
										</span>
									</p>
										<form id="formAuthentication" className="mb-3" autoComplete="off" onSubmit={handleSubmit}>
											{/* {errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
											{errorMessage}</div>)} */}
											{/* <div className="mb-3">
												<div className="input-group input-group-merge">
													<span className="input-group-text"><i className='bx bx-buildings'></i></span>
													<input type="text" className="form-control" id="memberFirmName" name="member_firm_name" placeholder="* Member Name" onChange={(e) => setMemberFirmName(Common.acceptOnlyChars(e.target.value))} value={ memberFirmName } maxLength="50" autoFocus required/>
												</div>
											</div> */}
											<div className="mb-3">
												<div className="input-group input-group-merge">
													<span className="input-group-text"><i className='bx bx-hash' ></i></span>
													<input type="text" className="form-control" id="mrn_number" name="mrn_number" placeholder="* Membership Number" onChange={(e) => setMRNNumber(Common.acceptOnlyCharsNumbersNoSpace(e.target.value))} value={ mrn_number } required/>
												</div>
											</div>
											<div className="mb-3">
												<div className="input-group input-group-merge">
													<span className="input-group-text"><i className='bx bx-calendar'></i></span>
													{/* <input type="text" className="form-control" id="mobile" name="mobile" placeholder="* Registered Mobile Number" maxLength="10" onChange={(e) => setMobile(Common.acceptOnlyNumbers(e.target.value))} value={mobile} required/> */}
												<DatePicker name="docDate" calendarPlacement="top" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-yy" className="form-control" onChange={setDocDate} value={docDate} />
												</div>
												<small style={{'fontSize': '75%'}}>Date of Birth</small>
											</div>
                                            <div className="mb-3">
												<div className="input-group input-group-merge">
													<span className="input-group-text"><i className='bx bx-phone'></i></span>
													<input type="text" className="form-control" id="mobile" name="mobile" placeholder="* Registered Mobile Number" maxLength="10" onChange={(e) => setMobile(Common.acceptOnlyNumbers(e.target.value))} value={mobile} required/>
												</div>
											</div>
											<div className="mb-3 px-2 bg-warning rounded"><small className="text-white" style={{'font-size': '13px'}}>Offer available only to members of ICSI in practice.</small></div>
											<div className="my-2">
												<ReCAPTCHA className="g-recaptcha" ref={captchaRef} sitekey={props.recaptcha_key} />
											</div>
											<div className="mb-3">
												<div className="form-check">
													<input className="form-check-input" type="checkbox" id="terms-conditions" name="terms" required/>
													<label className="form-check-label" htmlFor="terms-conditions">
														I agree to
														<a href="https://www.fynone.com/tnc" target="_blank" rel="noreferrer"> privacy policy & terms</a>
													</label>
												</div>
											</div>
											<button className="btn btn-primary d-grid w-100" type="submit">Create Account</button>
										</form>
										{/* <small style={{'textAlign': 'center','color': 'grey'}}>Details will be validated with the ICAI server</small> */}
									</div>
								</div>
								{/* <img src="/icai/img/hero-img.png" className="img-fluid" alt="" /> */}
							</div>
						</div>
					</div>
				</section>

				<main id="main">
					<section id="about" className="about">
						<div className="container">
							<div className="row">
								<div className="col-xl-5 col-lg-6 d-flex justify-content-center video-box align-items-stretch position-relative">
									{/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox play-btn mb-4"></a> */}
								</div>

								<div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
									<h3>FynOne by Fynamics, an approved GST Suvidha Provider (GSP)</h3>
									<p>Founded in 2018, Fynamics is a leading GST product company serving over 2500+ clients and generated over 80 million e-invoices. USPs of FynOne are:</p>

									<div className="icon-box">
										<div className="icon"><i className="bx bx-fingerprint"></i></div>
										<h4 className="title">Simple</h4>
										<p className="description">Next gen UI with AI embedded, automated ledger creation, auto suggestions and notifications</p>
									</div>

									<div className="icon-box">
										<div className="icon"><i className="bx bx-gift"></i></div>
										<h4 className="title">Collaborative</h4>
										<p className="description">Unlimited business profiles, unlimited users. Extend access easily to your team members with module wise & client wise permissions</p>
									</div>

									<div className="icon-box">
										<div className="icon"><i className="bx bx-atom"></i></div>
										<h4 className="title">Compliance driven</h4>
										<p className="description">Realtime taxpayer GSTIN auto fills & validations, 1 click einvoice & eway bill generation, easy GST returns filing with 2B reconciliation</p>
									</div>
								</div>
							</div>

						</div>
					</section>


					<section id="services" className="services section-bg">
						<div className="container">

							<div className="section-title">
								<h2>What you get</h2>
								<p>As part of the benefits for Members in Practise under Institute of Company Secretaries of India, members get exclusive 3 years FREE PRO subscription and 60% discount thereon that includes</p>
							</div>

							<div className="row">
								<div className="col-lg-4 col-md-6">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-money" style={{'color': '#ff689b'}}></i></div>
										<h4 className="title"><p>Unlimited Profiles</p></h4>
										<p className="description">Multiple clients across multiple GSTINs. No restriction on number of clients that can be added</p>
									</div>
								</div>
								<div className="col-lg-4 col-md-6">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-calendar" style={{'color': '#e9bf06'}}></i></div>
										<h4 className="title"><p>Billing & E-invoicing</p></h4>
										<p className="description">Create bills (via form or excel upload) with smart auto fills and generate e-invoices in 1 click</p>
									</div>
								</div>

								<div className="col-lg-4 col-md-6" data-wow-delay="0.1s">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-store" style={{'color': '#3fcdc7'}}></i></div>
										<h4 className="title"><p>Inventory & Eway bill</p></h4>
										<p className="description">Track stock movement, review item level P&L, generate & manage gatepass & Eway bills</p>
									</div>
								</div>
								<div className="col-lg-4 col-md-6" data-wow-delay="0.1s">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-credit-card" style={{'color':'#41cf2e'}}></i></div>
										<h4 className="title"><p>Expense Management</p></h4>
										<p className="description">Add purchase invoice details, track advance payments, perform vendor level KYC</p>
									</div>
								</div>

								<div className="col-lg-4 col-md-6" data-wow-delay="0.2s">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-globe" style={{'color': '#d6ff22'}}></i></div>
										<h4 className="title"><p>GST Compliance</p></h4>
										<p className="description">Seamlessly file GST returns of R1, R6, ITC04, 3B etc and perform 2B reconciliation for max ITC</p>
									</div>
								</div>
								<div className="col-lg-4 col-md-6" data-wow-delay="0.2s">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-time-five" style={{'color': '#4680ff'}}></i></div>
										<h4 className="title"><p>Unlimited Users</p></h4>
										<p className="description">Create sub users with client wise and module wise permission based access & OTP authentication</p>
									</div>
								</div>
							</div>

						</div>
					</section>

					<section id="counts" className="counts">
						<div className="container">

							<div className="text-center title">
								<h3>About Fynamics</h3>
								<p>A leading E-invoicing & a full stack GST solutions company</p>
							</div>

							<div className="row counters position-relative">

								<div className="col-lg-3 col-6 text-center">
									<span data-purecounter-start="0" data-purecounter-end="2500" data-purecounter-duration="1" className="purecounter"></span>
									<p>Clients</p>
								</div>

								<div className="col-lg-3 col-6 text-center">
									<span data-purecounter-start="0" data-purecounter-end="3" data-purecounter-duration="1" className="purecounter"></span>
									<p>Countries</p>
								</div>

								<div className="col-lg-3 col-6 text-center">
									<span data-purecounter-start="0" data-purecounter-end="80" data-purecounter-duration="1" className="purecounter"></span>
									<p>E-invoices (in Mn)</p>
								</div>

								<div className="col-lg-3 col-6 text-center">
									<span data-purecounter-start="0" data-purecounter-end="6" data-purecounter-duration="1" className="purecounter"></span>
									<p>Yrs of Service</p>
								</div>

							</div>

						</div>
					</section>

					<section id="clients" className="clients">
						<div className="container">

							<div className="row no-gutters clients-wrap clearfix wow fadeInUp">

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client1.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client2.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client3.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client4.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client5.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client6.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client7.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client8.png" className="img-fluid" alt="" />
									</div>
								</div>

							</div>

						</div>
					</section>

					<section id="testimonials" className="testimonials">
						<div className="container position-relative" data-aos="fade-up">
							<div className="row justify-content-center">
								<div className="testimonial-item">
									<h3>Data Security at its Core</h3>
									<p>Infosec team & senior management with decades of hands-on experience and holding professional certifications like CISA, CISSP, CISM, ITIL etc. All our licenses have been awarded only after a thorough review and rigorous scrutiny of our systems and applications by the regulator.</p>
									<img src="icai/img/security.png" alt="" height="100" />
								</div>
							</div>

						</div>
					</section>


					<section id="gallery" className="gallery">
						<div className="container">

							<div className="section-title">
								<h2>Mobile App</h2>
								<p>FynOne Mobile App allows you to access the data anywhere, anytime and on the go. Its free and is auto synced with everything that happens on the web. Download the app from the <a href="https://play.google.com/store/apps/details?id=com.fynamics.fynone" rel="noreferrer" target="_blank">Playstore</a> today</p>
							</div>

							<div className="row no-gutters">

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery1.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery1.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery2.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery2.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery3.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery3.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery4.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery4.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery5.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery5.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery6.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery6.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery7.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery7.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery8.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery8.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

							</div>

						</div>
					</section>

					<section id="contact" className="contact">
						<div className="container">

							<div className="section-title">
								<h2>Contact</h2>
								<p>Email, Call, WhatsApp, Message or feel free to even come over to our Office! Our coordinates are as below:</p>
							</div>
						</div>

						<div className="container">

							<div className="row mt-5">

								<div className="col-lg-4">
									<div className="info">
										<div className="address">
											<i className="ri-map-pin-line"></i>
											<h4>Location:</h4>
											<p>#302, 3rd Floor, Margosa Plaza
												<br />Malleswaram, Bangalore, India - 560055
											</p>
										</div>

										<div className="email">
											<i className="ri-mail-line"></i>
											<h4>Email:</h4>
											<p>support@fynone.com</p>
										</div>

										<div className="phone">
											<i className="ri-phone-line"></i>
											<h4>Call:</h4>
											<p>+91 96633 55662</p>
										</div>

									</div>

								</div>

								<div className="col-lg-8 mt-5 mt-lg-0">

									<iframe title="FynOne Map" src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3887.451328850033!2d77.56653527390588!3d13.006905914105582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x3bae173cde1e370d%3A0x81ec7113090c296c!2sFynamics%20Techno%20Solutions%20Pvt.%20Ltd.%2C%20No.%20202%2C%20II%20Floor%2C%20Spearhead%20Towers%2C%20%2350%2C%2056%2C%20Margosa%20Rd%2C%20Malleshwaram%2C%20Bengaluru%2C%20Karnataka%20560055!3m2!1d13.0069007!2d77.5691102!5e0!3m2!1sen!2sin!4v1685015828328!5m2!1sen!2sin" style={{'border':'0', 'width': '100%', 'height': '350px'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

								</div>

							</div>

						</div>
					</section>

				</main>

				<footer id="footer">
					<div className="footer-top">
						<div className="container">
							<div className="row">

								<div className="col-lg-3 col-md-6 footer-contact">
									<h3>FYN ONE</h3>
									<h4>The Billing & Accounting app for Bharat</h4><br />
									<p>
										FYN ONE is part of the FinTech suite at Fynamics
									</p>
								</div>

								<div className="col-lg-3 col-md-6 footer-links">

								</div>

								<div className="col-lg-3 col-md-6 footer-links">
									<h4>Useful Links</h4>
									<ul>
										<li><a className="bx bx-chevron-right" href="#hero" style={{'overflowAnchor': 'none'}}>Home</a></li>
										<li><a className="bx bx-chevron-right" href="#about" style={{'overflowAnchor': 'none'}}>Features</a></li>
										<li><a className="bx bx-chevron-right" href="#services" style={{'overflowAnchor': 'none'}}>Modules</a></li>
										<li><a className="bx bx-chevron-right" href="#counts" style={{'overflowAnchor': 'none'}}>About</a></li>
										<li><a className="bx bx-chevron-right" href="#gallery" style={{'overflowAnchor': 'none'}}>Mobile App</a></li>
										<li><a className="bx bx-chevron-right" href="#contact" style={{'overflowAnchor': 'none'}}>Contact</a></li>
									</ul>
								</div>

								<div className="col-lg-3 col-md-6 footer-links">
									<h4>Stay Connected</h4>
									<p>Follow us for the latest updates</p>
									<div className="social-links mt-3">
										<a href="https://twitter.com/Fynone_official" rel="noreferrer" target="_blank" className="twitter"><i className="bx bxl-twitter"></i></a>
										<a href="https://www.facebook.com/profile.php?id=100094403341519" rel="noreferrer" target="_blank" className="facebook"><i className="bx bxl-facebook"></i></a>
										<a href="https://instagram.com/fynone_official?igshid=NGExMmI2YTkyZg==" rel="noreferrer" target="_blank" className="instagram"><i className="bx bxl-instagram"></i></a>
										<a href="https://www.youtube.com/channel/UCcgwyw_fdBIFBDArgbnEJjw" rel="noreferrer" target="_blank" className="youtube"><i className="bx bxl-youtube"></i></a>
										<a href="https://www.linkedin.com/in/fyn-one-14660a281/" rel="noreferrer" target="_blank" className="linkedin"><i className="bx bxl-linkedin"></i></a>
									</div>
								</div>

							</div>
						</div>
					</div>
				</footer>
			{/* </div> */}
			<Modal show={verifyOTPShow} size="md" aria-labelledby="otp-modal" backdrop="static" keyboard={false} centered>
				<Modal.Body>
					<div className="row">
						<div className="text-center mb-3">
							<h6>Enter OTP sent to registered mobile number and email id <br/></h6>
							<div> <span>A code has been sent to</span> <small>******<span>{respMobile !== null && respMobile.slice(-4)}</span></small> </div>
							<form autoComplete="off" onSubmit={handleOTPSubmit}>
								<div className="inputs d-flex flex-row justify-content-center mt-2">
								<OtpInput value={otp} onChange={setOtpValue} numInputs={6} shouldAutoFocus={true} inputStyle = {'otpInput m-2 text-center form-control rounded'}
									inputType="tel"
									renderSeparator={<span>-</span>}
									renderInput={(props) => <input {...props}/>}
								/>
								</div>
								<div className="mt-4"> <button type="submit" className="btn btn-primary px-4 validate">Validate</button> </div>
							</form>
						</div>
						<p className="text-center">
							<span>Not your number? </span>
							<Button variant="" className="p-0 text-decoration-underline" onClick={e=>{setVerifyOTPShow(false);setSeconds(30);setRunTimer(false);setOtpValue('')}}>Back</Button>
						</p>
						<p className="text-center">
							{seconds > 0 ? (
							<>Time Remaining: {seconds < 10 ? `0${seconds}` : seconds} secs</>
							) : (
							<>Didn't receive code? <Button variant="" className="p-0 text-decoration-underline" onClick={e=>{setVerifyOTPShow(false);setSeconds(30);setRunTimer(false);setOtpValue('')}}>Close</Button> and try again.</>						
							)}
						</p>
					</div>
				</Modal.Body>
			</Modal>			
		</React.Fragment>
	);
}