import React,{useState}  from "react";
import Layout from './Layout';
import Button from 'react-bootstrap/Button';
import NavBar from './NavBar';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import * as DTE from '../common/ImportDataTableEssentials';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TagManager from 'react-gtm-module';

export default function SalesListing(props){
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
	const [DTpending, setDTPending] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
    const [poContracts, setPoContracts] = useState([]);
	const [editModal,setEditModal] = useState(false);
	const [po_id,setPoId] = useState();
	const [po_min_end_date,setPoMinEndDate] = useState(new Date());
	const [po_end_date,setPoEndDate] = useState('');
	const [exportBtnClass,setExportBtnClass]    = React.useState('d-none');
	const handleModalClose = () => {setEditModal(false);setPoEndDate('')}
	const [uploadPaidModal, setUploadPaidModal] = useState(false);
	const [itemDocument,setItemDocument]        = useState('');
	const [sortOrder,setSortOrder]        = useState('');
	
	// User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	const [incomeColl_w,setincomeColl_w] = React.useState(true);
	
    React.useEffect(() => {
        document.title = 'PO or Contract Listing | '+props.site_name;
		if( Object.keys(userPerm).length > 0 ){
			setincomeColl_w((userPerm[2]['write'] !== undefined && userPerm[2]['write'] === '0') ? false : true);
		}
		const hostname = window.location.hostname;
		if( hostname === 'mart.fynone.com' ){
			const tagManagerArgs = {
				gtmId: 'G-RF6E5H5MTZ'
			}

			TagManager.initialize(tagManagerArgs);
		}
		loadDataTable(1,perPage,filterText);
    }, [props,dt_load,filterText]);
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,sortOrder);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText,sortOrder);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
        setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection));
	};
    const loadDataTable = (page,perPage,filterText,sort='') => {
		Common.axios({
            method: 'post',
            url:props.url+'po-contract/listing/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText}
        }).then(function (response) {
			setExportBtnClass('d-none');
            if(response.data.success){
				setPoContracts(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				if(response.data.total > 0){
					setExportBtnClass('');
				}
            }
        }).catch(error => console.log(error));
	}
    const columns = [
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
			sortField: 'status',
			cell: (row) => (
				<BootstrapSwitchButton
					checked={(row.status === '1' )}
					disabled={(row.status === '0')}
					onlabel='Active'
					offlabel='Inactive'
					onstyle="success"
					offstyle="danger"
					size="sm"
					width={100}
					onChange={(checked: boolean) => {
						poStatus(checked,row.id);
					}}
				/>
			)
        },
        {
            name: 'Customer Name',
            selector: row => row.customer_name,
            sortable: true,
			sortField: 'customer_name'
        },
        {
            name: 'PO No.',
            selector: row => row.po_no,
            sortable: true,
			sortField: 'po_no'
        },
        {
            name: 'PO date',
            selector: row => row.po_date,
            sortable: true,
			sortField: 'po_date',
			cell: (row) => (
				row.po_date !== '0000-00-00' ? Common.displayDate(row.po_date) : ''
			)
        },
        {
            name: 'PO end date',
            selector: row => row.po_end_date,
            sortable: true,
			sortField: 'po_end_date',
			cell: (row) => (
				<button style={{"display":"contents"}} className="btn btn-link" onClick={(e)=>editEndDate(row.id,row.po_end_date)}>{row.po_end_date !== '0000-00-00' ? Common.displayDate(row.po_end_date) : ''} <i className='bx bx-pencil'></i></button>
			)
        },
        {
            name: 'PO amount (₹)',
            selector: row => row.po_amt,
            sortable: true,
			sortField: 'po_amt'
        },
		{
            name: 'Balance (₹)',
            selector: row => row.balance,
            sortable: true,
			sortField: 'balance'
        },
        {
            name: 'Download Attachment',
            selector: row => row.file_path,
            sortable: false,
			sortField: 'file_path',
			cell: (row) => (
				row.file_path !== null ? <><button type="button"  style={{"width": "19rem"}} onClick={(e) => handleDocDownload(row.file_path,row.id)} className="btn btn-sm btn-primary">Download</button> <i className='bx bx-trash' onClick={(e) => handleDocDelete(row.file_path,row.id)}></i></> : <button type="button" onClick={(e) => showUploadDocModal(true, row.id)} className="btn btn-sm btn-info" >Upload</button>
			)
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
            sortField: 'created_by',
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
            sortField: 'p.created_on',
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: false,
            sortField: 'updated_by',
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
            sortField: 'p.updated_on',
        }
	];
	const handleDocDelete = (filepath, id) => {
        confirmAlert({
            title: 'Delete Document',
            message: 'Are you sure you want to delete?',
            buttons: [{
                    label: 'Yes',
                    onClick: () => {
                        Common.showLoader(true);
                        Common.axios({
                            method: 'post',
                            url:props.url+'po-contract/del_document/',
                            data: {
                                filepath:filepath,
                                id:id
                            }
                        }).then(function (response) {
                            Common.showLoader(false);
                            if(response.data.success){
								loadDataTable(1,perPage,filterText,sortOrder);
                                Common.toast.success(response.data.message);
                            } 
                            Common.toast.dismiss();
                        }).catch(error => console.log(error));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log('no')
                    }
                }
            ]
        });
    }
	const showUploadDocModal = (flag, id) => {
        setPoId(id)
        if (flag === false)
            setUploadPaidModal(false);
        else
            setUploadPaidModal(true);
    }
	const handleDocument = (e) => {
        const fileTypes = ['application/pdf', 'image/jpeg', 'image/jpg','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/png', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (e.target.files[0] !== undefined) {
            if (!fileTypes.includes(e.target.files[0].type)) {
                Common.toast.error('File format not supported');
                e.target.value = null;
                return false;
            } else {
                if (e.target.files[0].size > (5 * 1048576)) {
                    Common.toast.error('File size should be less than 5MB');
                    e.target.value = null;
                    return false;
                } else {
                    setItemDocument(e.target.files[0]);
                }
            }
        }
        Common.toast.dismiss();
    };
	const uploadDocument = (e) => {
        let fileData = new FormData();
        fileData.append('files', itemDocument);
        fileData.append('pid', localStorage.getItem('fo_profileid'));
        fileData.append('po_id', po_id);
        Common.axios.post(props.url+'po-contract/upload_file', fileData, {
            mode: 'cors',
            headers: { 'Content-Type':  'multipart/form-data' }
        }).then(function (response) {
            setUploadPaidModal(false);
			loadDataTable(1,perPage,filterText,sortOrder);         
        }).catch(error => console.log(error));
    }
	const handleDocDownload = (filePath) => {
        Common.showLoader(true);
        Common.axios({
            method: 'get',
            url:props.url+'po-contract/download/'+encodeURIComponent(btoa(filePath)) + '/' + localStorage.getItem('fo_profileid'),
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				Common.toast.dismiss();
                const url = props.url+response.data.url;
                console.log(url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.data.file_name);
                document.body.appendChild(link);
                link.click();
                return false;
			} 
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    };
	const poStatus = (checked,id) => {
		Common.axios({
			method: 'post',
			url: props.url+'po-contract/po_update',
			data: {status:checked,id:id}
		}).then(function (response) {
            if(response.data.success){
            	loadDataTable(1,perPage,filterText,sortOrder);
				Common.toast.success(response.data.message);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const editEndDate = (id,date) => {
		setEditModal(true);
		setPoId(id);
		setPoMinEndDate(new Date(date).setDate(new Date(date).getDate() + 1))		
	}
	const editEndDateSubmit = () => {
		if( po_end_date === '' ){
			Common.toast.error('PO End date is invalid.');
			return false;
		}
		Common.axios({
			method: 'post',
			url: props.url+'po-contract/po_update',
			data: {po_end_date:po_end_date,id:po_id}
		}).then(function (response) {
            if(response.data.success){
				handleModalClose();
				Common.toast.success(response.data.message);
				setTimeout(()=>{setDtLoad( (dt_load) ? false : true)},500);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const handleExportCSV = (e)=>{
		e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'po-contract/download_csv',
			data: {'pid':localStorage.getItem('fo_profileid'),'search':filterText},
            // responseType: 'blob',
        }).then(function (response) {
			if(response.data.success){
				window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `POContract-${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click(); */
			} else{
				Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
	}
    return (
    <>
		<Modal {...props} size="sm" show={editModal} onHide={handleModalClose}>
			<Modal.Header closeButton>
				<Modal.Title>Edit End Date</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<DatePicker name="po_end_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" minDate={new Date(po_min_end_date)} className="form-control" onChange={setPoEndDate} value={po_end_date} />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={editEndDateSubmit}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="po-contract" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Income & Collections /</span> PO or Contract Listing 
                                <span className="dropdown">
                                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
										<i className='text-danger bx bxl-youtube bx-md'></i>
                                    </button>
                                    <div className="dropdown-menu" >
                                        <a href="https://youtu.be/T4D9mCBEa8Q" className="dropdown-item" target="_blank" rel="noreferrer"><span>Add customer PO</span></a>
                                    </div>
                                </span>
                            </h4>
							<div className="row">
								<div className="col-md-6 col-12 listing-tabs mb-1 text-start">
									<button className={"btn btn-sm btn-info me-2 "+exportBtnClass} onClick={handleExportCSV}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
									{incomeColl_w && (<>
										<Common.Link to="/add-po-contract" className="btn btn-sm btn-primary"> <i className="bx bx-plus"></i>PO/Contract</Common.Link>
									</>)}
								</div>
								<div className="col-md-6 col-12 text-center text-md-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Customer Name, PO No.')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
                            <div className="row">
                                <div className="col-sm-12">									
									<DTE.DataTable title={"PO/Contract ("+totalDataCnt+")"} className="rdt_AdjDrp" columns={columns} data={poContracts} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} persistTableHead/>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal show={uploadPaidModal} size='sm' onHide={()=>showUploadDocModal(false, po_id)} aria-labelledby="Eway-Bill-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Select File to upload</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Default file input example</label>
                            <input className="form-control" type="file" name="formFile" id="formFile" onChange={handleDocument} />
                        </div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={(e) => uploadDocument(e)}>
				Upload
			</Button>
			<Button variant="danger" onClick={()=>showUploadDocModal(false,po_id)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
    </>
    );
}