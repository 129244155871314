import React  from "react";
import NavBar from './NavBar';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {useParams} from "react-router-dom";
import * as Common from '../common/ImportUserEssentials';
import Carousel from 'react-bootstrap/Carousel';
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import Offcanvas from 'react-bootstrap/Offcanvas';
import GLightbox from 'glightbox';
import '../styles/mart-orders-add.css';
import beepSound from '../sounds/scanner-beep.mp3';
import {Html5Qrcode} from "html5-qrcode";
import { AsyncTypeahead,Highlighter } from 'react-bootstrap-typeahead';
export default function Approve(props){
	const { hash } = useParams();
	const [cust_details, setCustDetails] = React.useState({'id':0,'name':'','mobile':'','gstin':''});
	const [storeDetails,setStoreDetails] = React.useState({'id':0,'pid':0,'pos':0,'pstatus':0});
	const [categories,setCategories] = React.useState([]);
	const [dummy_categories,setDummyCategories] = React.useState([]);
	//const [martMode,setMartMode] = React.useState('normal')
	const [showCart, setShowCart] = React.useState(false);
	const [cartItems,setCartItems] = React.useState([]);
	const [cartAnimation,setCartAnimation] = React.useState('');
	const [reCalcCart,setReCalcCart] = React.useState(true);
	const [items,setItems] = React.useState([]);
	const [showCat, setShowCat] = React.useState(false);
	const [cartSubTotal, setCartSubTotal] = React.useState(0);
	const [cartDiscount, setCartDiscount] = React.useState(0);
	const [cartGst, setCartGst] = React.useState(0);
	const [cartTotal, setCartTotal] = React.useState(0);
	const [defaultPayee,setDefaultPayee] = React.useState(0);
	const [searchText,setSearchText] = React.useState('');
	
	const [isItemLoading, setIsItemLoading] = React.useState(false);
	const [itemsCart,setItemsCart] = React.useState([]);
	
	//Cart
	const [martCartMode,setMartCartMode] = React.useState('normal');
	
	// Bypass client-side filtering by returning `true`. Results are already
	const filterBy = () => true;
	const navigate = Common.useNavigate();
	//Barcode scanner
	let audio = new Audio(beepSound);
	const playBeep = () => {
		if(audio.paused === true){
		  audio.play();
		}else{
		  audio.pause();
		  audio.currentTime = 0;
		  audio.play();
		}
	};
	React.useEffect(() => {
		GLightbox({
			selector: '[data-glightbox]',
			touchNavigation: false,
			loop: false,
			autoplayVideos: true
		});
	},[items]);
    React.useEffect(() => {
		document.title = 'Store | '+props.site_name
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'martuser/detailsStore',
			data: {hash:hash}
		}).then(function (response) {
			Common.showLoader(false);
			if( response.data.success ){
				if( response.data.data.pstatus === "0" ){
					Common.toast.dismiss();
					Common.toast.error("Merchant isn't online. Contact merchant directly.");
					navigate("/home");
					return false;
				}
				document.title = response.data.data.name+' | '+props.site_name
				setStoreDetails(response.data.data);
				loadItemCategories(response.data.data.id);
				Common.axios({
					method: 'post',
					url:props.url+'martuser/detailsCustomer',
					data: {martid:response.data.data.id}
				}).then(function (cresponse) {
					if( cresponse.data.success ){
						setCustDetails(cresponse.data.data);
						return false;
					}
					Common.toast.error(cresponse.data.message);
				}).catch(error => console.log(error));
				Common.axios({
					method: 'get',
					url:props.url+'bank-accounts/defaultPayee/'+response.data.data.pid,
				}).then(function (bresponse) {
					if(bresponse.data.success){
						setDefaultPayee(bresponse.data.data.value);
					}
				}).catch(error => console.log(error));
				return false;
			}
			Common.toast.error(response.data.message);
			navigate('/home');
		}).catch(error => console.log(error));
	}, [props]);
	const reloadItems = () => {
		if( categories.length > 0 ){
			let temp = [];
			categories.forEach((cat) => {
				temp.push(cat.id);
			});
			loadItems(temp);
		}
	}
	React.useEffect(() => {
		if( searchText === '' || searchText.length >= 3 ){
			reloadItems();
		}
	},[searchText]);
	React.useEffect(() => {
		reloadItems();
	}, [categories]);
	React.useEffect(() => {
		let cart_sub = 0, cart_dis = 0, cart_gst = 0, cart_total = 0;
		if( cartItems.length > 0 ){
			cartItems.forEach(item => {
				cart_sub += item.qty * (item.sale_price-item.discount);
				cart_dis += item.qty * (item.discount);
				cart_gst += item.qty * item.gst_amt;
				cart_total += item.qty * item.sale_total_price;
			});
		}
		setCartSubTotal(cart_sub);
		setCartDiscount(cart_dis);
		setCartGst(cart_gst);
		setCartTotal(cart_total);
	}, [cartItems,reCalcCart]);
	/*React.useEffect(() => {
		const html5QrCode = new Html5Qrcode("barReader");
		const startScanner = async () => {
			await html5QrCode.start(
				{ facingMode: 'environment' },
				{
					fps: 10,
					qrbox: { width: 300, height: 200 }
					//qrbox: qrboxFunction
				},
				(decodedText, decodedResult) => {
					if(decodedText !== ''){
						playBeep();
						stopScanner();
						addItemBarcode(decodedText);
					}
				},
				(errorMessage) => {
					// parse error, ignore it.
				}
			).catch((err) => {
				Common.toast.error(err);
				setMartMode('normal');
			});
		};
		const addItemBarcode = (barcode) => {
			Common.showLoader(true);
			Common.axios({
				method: 'post',
				url:props.url+'martorders/itemFromBarcode',
				data: {martid:storeDetails.id,barcode:barcode}
			}).then(function (response) {
				Common.showLoader(false);
				if(response.data.success){
					const index = cartItems.findIndex(item => item.iid === response.data.data.iid);
					if( index >= 0 ){
						let newQty = parseFloat(cartItems[index].qty) + 1;
						updateCart(index,newQty,1);
						Common.toast.success(response.data.message);
						return false;
					}
					addToCart(response.data.data,1);
					Common.toast.success(response.data.message);
					return false;
				}
				startScanner();
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
		const stopScanner = async () => {
			if (html5QrCode.isScanning) {
				await html5QrCode.stop();
				html5QrCode.clear();
			}
		};
		if( martMode === 'barcode' ){
			startScanner();
		}
		return () => {
			stopScanner();
		};
	}, [martMode,refBar]);*/
	React.useEffect(() => {
		if( document.getElementById('barCartReader') !== null ){
			const html5QrCode = new Html5Qrcode("barCartReader");
			const startScanner = async () => {
				await html5QrCode.start(
					{ facingMode: 'environment' },
					{
						fps: 10,
						qrbox: { width: 250, height: 180 }
						//qrbox: qrboxFunction
					},
					(decodedText, decodedResult) => {
						if(decodedText !== ''){
							playBeep();
							setMartCartMode('normal');
							addItemBarcode(decodedText);
						}
					},
					(errorMessage) => {
						// parse error, ignore it.
					}
				).catch((err) => {
					Common.toast.error(err);
					setMartCartMode('normal');
				});
			};
			const addItemBarcode = (barcode) => {
				Common.showLoader(true);
				Common.axios({
					method: 'post',
					url:props.url+'martorders/itemFromBarcode',
					data: {martid:storeDetails.id,barcode:barcode}
				}).then(function (response) {
					Common.showLoader(false);
					setMartCartMode('barcode');
					if(response.data.success){
						addToCart(response.data.data,1);
						Common.toast.success(response.data.message);
						return false;
					}
					Common.toast.error(response.data.message);
				}).catch(error => console.log(error));
			}
			const stopScanner = async () => {
				if (html5QrCode.isScanning) {
					await html5QrCode.stop();
					html5QrCode.clear();
				}
			};
			if( martCartMode === 'barcode' ){
				startScanner();
			}
			return () => {
				stopScanner();
			};
		}
	}, [martCartMode]);
	const itemsSearch = (query: string) => {
		setIsItemLoading(true);

		fetch(props.url+`martorders/itemSearch/`+storeDetails.id+'/'+query)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setItemsCart(items);
			setIsItemLoading(false);
		});
	};
	const loadItemCategories = (mid) => {
		Common.axios({
            method: 'post',
            url:props.url+'martorders/getCategories',
			data: {martid:mid}
        }).then(function (response) {
            if(response.data.success){
				setCategories(response.data.data);
				setDummyCategories(response.data.data);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const loadItems = (catids) => {
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'martorders/itemsByCategory',
			data: {martid:storeDetails.id,'catids':catids,search:searchText}
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
				setItems(response.data.data);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const presentInCart = (iid) => {
		return cartItems.some(item => iid === item.iid);
	}
	const removeFromCart = (cart) => {
		const index = cartItems.findIndex(item => item.iid === cart.iid);
		if (index !== -1) {
			const newArray = [...cartItems.slice(0, index), ...cartItems.slice(index + 1)];
			setCartItems(newArray);
			setReCalcCart(!reCalcCart);
			cartAnimate();
			if( martCartMode === 'barcode' ){
				setMartCartMode('normal');
				setTimeout(() => {
					setMartCartMode('barcode');
				}, 1000);
			}
		}
	}
	const cntItemsInCart = (iid) => {
		const index = cartItems.findIndex(item => item.iid === iid);
		return cartItems[index]['qty'];
	}
	const decrementCart = (item) => {
		const index = cartItems.findIndex(cart => cart.iid === item.iid);
		let newQty = parseFloat(cartItems[index]['qty']) - 1;
		if( newQty < 1 ){
			removeFromCart(item);
			return false;
		}
		updateCart(index,newQty);
	}
	const incrementCart = (item) => {
		const index = cartItems.findIndex(cart => cart.iid === item.iid);
		let newQty = parseFloat(cartItems[index]['qty']) + 1;
		updateCart(index,newQty);
	}
	const cartAnimate = () => {
		setCartAnimation('bx-tada');
		setTimeout(() => {
			setCartAnimation('');
		}, 1000);
	}
	const updateCart = (index,newQty) => {
		newQty = parseFloat(newQty) > 0 ? newQty.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : newQty;
		let temp_cart = cartItems;
		temp_cart[index]['qty'] = newQty;
		setCartItems(temp_cart);
		setReCalcCart(!reCalcCart);
		cartAnimate();
	}
	const addToCart = (item) => {
		if( presentInCart(item.iid) ){
			incrementCart(item);
			return false;
		}
		item['qty'] = 1;
		setCartItems([...cartItems,item]);
		cartAnimate();
	}

	const placeOrder = () => {
		let data = {};
		data['pid'] = storeDetails.pid;
		data['martid'] = storeDetails.id;
		data['cid'] = cust_details.id;
		data['update_cust'] = 0;
		data['pos'] = JSON.stringify({'value':storeDetails.pos})
		data['doc_date'] = new Date();
		data['total_taxable_amt'] = cartSubTotal;
		data['total_doc_discount'] = cartDiscount;
		data['round_off'] = 0;
		data['other_charges'] = 0;
		data['total_amt'] = cartTotal;
		data['doc_type'] = 'INV';
		data['supply_type'] = (cust_details.gstin !== '' && cust_details.gstin.length === 15) ? 'B2B':'B2C';
		data['etin'] = '';
		data['doc_status'] = 'P';
		data['rev_chrg'] = 'N';
		data['igst_intra'] = 'N';
		data['income_type'] = '1';
		data['income_subtype'] = '';
		data['payee'] = defaultPayee;
		data['items'] = [];
		let errors = [];
		cartItems.forEach(item => {
			let temp = {};
			if( item['service'] === '0' && Number(item['qty']) < Number(item['min_qty']) ){
				errors.push(item['name']+' : Minimum order qty. is '+Common.displayUnit(item['min_qty']));
				return false;
			}
			temp['id'] = item['id'];
			temp['name'] = item['name'];
			temp['item_qty'] = item['qty'];
			temp['unit'] = item['uqcid'];
			temp['hsn'] = item['hsn'];
			temp['price'] = item['sale_price'];
			temp['item_tot_price'] = item['qty']*item['sale_total_price'];
			temp['item_discount'] = item['discount'];
			temp['gst_rt'] = item['gst_rt'];
			temp['gst_rate_id'] = item['gst_rate_id'];
			temp['igst_amt'] = 0;
			temp['cgst_amt'] = temp['sgst_amt'] = (Number(item['qty']*item['gst_amt'])/2).toFixed(2);
			data['items'].push(temp);
		});
		data['items'] = JSON.stringify(data['items']);
		data['allocation_centre'] = '';
		data['poid'] = 0;
		data['qid'] = 0;

		if( errors.length > 0 ){
			Common.showLoader(false);
			let err_list = '';
			errors.forEach((val, index) => {
				err_list += '<li>'+val+'</li>';
			})
			const err_html = () => (
				<ul><strong>List of Errors:</strong>{Common.parse(err_list)}</ul>
			);
			Common.toast.error(err_html);
			return false;
		}

		Common.axios({
			method: 'post',
			url: props.url+'sale_orders/add',
			data: data
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				Common.axios({
					method: 'post',
					url: props.url+'notifications/add',
					data: {'pid':storeDetails.pid,subject:'New PreOrder received',message:'#'+response.data.invno+' from '+cust_details.name+' ('+cust_details.mobile+')',type:'success',category:'mart-preorder'}
				}).then(function(response){
					navigate("/orders");
				});
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	return (
	<div id="mm_orderadd">
		<Offcanvas className="w-25" placement={'start'} show={showCat} onHide={()=>setShowCat(false)}>
			<Offcanvas.Body className="text-center text-break">
				<div key={"catMLinkall"} className="catDivBtn py-2">
					<button onClick={()=>{setShowCat(false);loadItemCategories(storeDetails.id)}} className="btn btn-lg rounded-pill btn-link p-0">
						<img className="img-thumbnail rounded-pill" src={window.location.origin+"/assets/img/allcat.png"} alt={"Items All"}/>
					</button>
					<small>All</small>
				</div>
				{categories.map((cat, i) => {
					return (
					<div key={"catMLink"+i} className="catDivBtn py-2">
						<button onClick={()=>{setShowCat(false);loadItems([cat.id]);setDummyCategories([cat])}} className="btn btn-lg rounded-pill btn-link p-0">
							{(cat.image.name !== '') ?
							<img className="img-thumbnail rounded-pill border-1" src={cat.image.name} alt={"Items "+i}/>
							:
							<img className="img-thumbnail rounded-pill border-1" src={window.location.origin+"/assets/img/no-preview.png"} alt={"Items "+i}/>
							}
						</button>
						<small>{cat.name}</small>
					</div>
					);
				})}
			</Offcanvas.Body>
		</Offcanvas>
		<Offcanvas id="user_cart" placement={'end'} show={showCart} onHide={()=>{setShowCart(false);setMartCartMode('normal')}}>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title className="row row-cols-auto w-100 align-items-center">
					<div className="col-4 col-lg-3">My Cart</div>
					<div className="col-5 col-lg-7 p-0">
						<AsyncTypeahead selected={[]} filterBy={filterBy} id="item-cart" isLoading={isItemLoading} labelKey="name" minLength={3} onSearch={itemsSearch} options={itemsCart} placeholder="Search (min 3 chars)" onChange={(e) => addToCart(e[0])}
							renderMenuItemChildren={(option: Item,props) => (
								<div className="smaller_font">
									<Highlighter search={props.text}>
										{option.name}
									</Highlighter>
									<div>
										<small className="text-success text-nowrap">Price: {Common.displayPrice(option.sale_price)}</small>
										{option.service === '0' && option.stock_movement === '1' && <>
										, <small>Available: {option.current_stock === '0.00' ? 'Out of Stock' : Number(option.current_stock)}</small>
										</>}
									</div>
								</div>
							)}
						/>
					</div>
					<div className="col-2 col-lg-2">
						<Common.OverlayTrigger placement={'bottom'} overlay={Common.tooltipContent('Scan Barcodes')}>
							<Button onClick={()=>setMartCartMode( (martCartMode==='barcode') ? 'normal':'barcode')} variant="outer-primary" className={"p-0 "+(martCartMode==='barcode' ? "text-success" : "") }><i className={"bx bx-lg bx-barcode-reader"+(martCartMode==='barcode' ? " bx-burst":"")}></i></Button>
						</Common.OverlayTrigger>
					</div>
				</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className={"row pb-2 text-center "+ (martCartMode === 'normal' ? "d-none":"")}>
					<div className={"mx-auto "+(martCartMode === 'normal' ? "d-none":"")} style={{'width':'320px','height':'auto'}}>
						<h5>Scan Barcodes</h5>
						<div id="barCartReader" className="d-block"></div>
					</div>
				</div>
				{cartItems.length === 0 ? <><div className="text-center"><small>You have not added anything to cart.</small></div></>
				:
				<div className="table-responsive">
					<table id="cartTable" className="small_font table table-sm table-hover table-borderless align-middle">
						<thead>
							<tr><th style={{'width':'10%'}}></th><th style={{'width':'60%'}}>Item</th><th style={{'width':'20%'}} className="text-end">Qty</th><th style={{'width':'10%'}} className="text-end"></th></tr>
						</thead>
						<tbody>
						{cartItems.map((item,index) => {
							return (
								<tr key={"iCart"+index}>
									<td>
									{item.media.length > 0 ? <>									
										{ (item.media[0].type === 'image/png' || item.media[0].type === 'image/jpeg') ?
                                            <img className="border rounded" src={item.media[0].name} width="50" height="auto" alt={"Items Cart"+index}/>
										:
											<video className="w-100" controls>
												<source src={item.media[0].name} type={item.media[0].type}/>
												Your browser does not support the video tag.
											</video>
										}
										</>
                                    : <img className="" src={window.location.origin+"/assets/img/no-media.png"} height="auto" width="50" alt={"Items Cart"+index}/>
									}
									</td>
									<td>
										{item.name}<br/>
										<span className="smaller_font text-success text-nowrap">{Common.displayPrice(item.sale_price-item.discount)}</span>
										{ item.discount > 0 && <><br/><span className="smaller_font text-decoration-line-through text-muted">{Common.displayPrice(item.sale_price)}</span></>}
									</td>
									<td>
										<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Change Quantity')}>
										<input onChange={(e)=>updateCart(index,Common.acceptOnlyDecimal(e.target.value))} type="text" className="text-right form-control form-control-sm" name="qty" value={item.qty} autoComplete="off"/>
										</Common.OverlayTrigger>
									</td>
									<td>
										<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Remove from Cart')}>
											<Button onClick={()=>removeFromCart(item)} size="sm" variant="link"><i className='bx bx-trash'></i></Button>
										</Common.OverlayTrigger>
									</td>
								</tr>
							)
						})}
						</tbody>
					</table>
				</div>
				}
				<hr/>
				<div className="list-group small_font">
					<div className="list-group-item list-group-item-action text-nowrap">Subtotal: <span className="float-end">{Common.displayPrice(cartSubTotal)}</span></div>
					<div className="list-group-item list-group-item-action text-nowrap">GST: <span className="float-end">{Common.displayPrice(cartGst)}</span></div>
					<div className="list-group-item list-group-item-action text-nowrap active">Total: <span className="float-end">{Common.displayPrice(cartTotal)}</span></div>
				</div>
				{cartTotal > 0 &&
				<div className="d-grid gap-2 pt-4">
                    <Button onClick={()=>placeOrder()} variant="success" type="button">Place Order <i className='bx bx-right-arrow-alt bx-fade-right'></i></Button>
				</div>
				}
			</Offcanvas.Body>
		</Offcanvas>
		<div className="layout-wrapper layout-content-navbar">
            <div className="layout-container martBg">
                <div className="layout-page">
                    <NavBar storeDetails={storeDetails} {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
								<h4 className="fw-bold pt-3 martTxtColor">
									<a className="btn btn-sm btn-primary me-1" href="/home"><i className='bx bxs-chevron-left'></i></a>
									<span className="align-middle">
										<span className="text-muted martTxtColor fw-light">All Stores /</span>
										Add Order
									</span>
								</h4>
							</div>
							<div className="row align-items-center">
								<div className="offset-md-4 col-md-4">
									<div className="input-group input-group-merge">
										<span className="input-group-text"><i className="bi bi-search"></i></span>
										<input value={searchText} type="text" className="form-control" aria-label="Search Item" placeholder="Search (min 3 chars)" onChange={(e)=>setSearchText(e.target.value)}/>
										<span className="input-group-text cursor_pointer" onClick={()=>setSearchText('')}><i className="bi bi-x-lg"></i></span>
									</div>
								</div>
								{/*<div className="col-md-3">
									<div className="text-center">
										<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Scan Barcodes')}>
											<Button onClick={()=>setMartMode( (martMode==='barcode') ? 'normal':'barcode')} variant="outer-primary" className={(martMode==='barcode') ? "text-success" : "text-white" }><i className={"bx bx-lg bx-barcode-reader"+(martMode==='barcode' ? " bx-burst":"")}></i></Button>
										</Common.OverlayTrigger>
									</div>
								</div>*/}
								<div className="col-md-4">
									<div className="float-end hidden-phone">
										<Button onClick={()=>setShowCart(true)} variant="success" className="py-0 d-flex align-items-center"><i className={"bi bx-md align-text-bottom "+(cartItems.length === 0 ? "bi-cart ":"bi-cart-fill ") + cartAnimation}></i>
											<div className="ms-1 text-end align-text-bottom">
												{cartItems.length === 0 && 'My Cart'}
												{cartItems.length > 0 && <>{cartItems.length} items<br/> {Common.displayPrice(cartTotal)}</>}
											</div>
										</Button>
									</div>
								</div>
							</div>
							<div className="row pb-5">
								{/*<div className={"py-5 col-12 text-center "+ (martMode === 'normal' ? "d-none":"")}>
									<div className={"mx-auto "+(martMode === 'normal' ? "d-none":"")} style={{'width':'320px','height':'auto'}}>
										<h5 className="martTxtColor">Scan Barcodes</h5>
										<div id="barReader" className="d-block"></div>
									</div>
								</div>*/}
								<div className={"card mt-5 col-md-1 text-center hidden-phone " + {/*(martMode === 'barcode' ? "d-none":"")*/}}>
									<div key={"catLinkall"} className="catDivBtn py-2">
										<button onClick={()=>loadItemCategories(storeDetails.id)} className="btn btn-lg rounded-pill btn-link">
											<img className="img-thumbnail rounded-pill" src={window.location.origin+"/assets/img/allcat.png"} alt={"Items All"}/>
										</button>
										<small>All</small>
									</div>
									{categories.map((cat, i) => {
										if( cat.id === "0" && items[cat.id] !== undefined && items[cat.id].length === 0 ) { return ''; }
										return (
										<div key={"catLink"+i} className="catDivBtn py-2">
											<button onClick={()=>{loadItems([cat.id]);setDummyCategories([cat])}} className="btn btn-lg rounded-pill btn-link">
												{(cat.image.name !== '') ?
												<img className="img-thumbnail rounded-pill border-1" src={cat.image.name} alt={"Items "+i}/>
												:
												<img className="img-thumbnail rounded-pill border-1" src={window.location.origin+"/assets/img/no-preview.png"} alt={"Items "+i}/>
												}
											</button>
											<small>{cat.name}</small>
										</div>
										);
									})}
								</div>
								<div className={"col-md-11 "+{/*(martMode === 'barcode' ? "d-none":"")*/}}>
									{dummy_categories.map((cat, cati) => {
										if( (items[cat.id] !== undefined && items[cat.id].length === 0) && dummy_categories.length > 1 ){ return ''; }
										return (
											<div className="row" key={"cat_"+cati}>
												<div className="col-12">
													<div className="divider text-start mb-1"><div className="divider-text fs-5 text-white text-capitalize fw-bold">{cat.name} ({items[cat.id] !== undefined && items[cat.id].length})</div></div>
												</div>
												{items[cat.id] === undefined && <><div className="mb-3"><ShimmerSimpleGallery card={true} row={1} col={4} imageHeight={200} caption={true} /></div></>}
												{items[cat.id] !== undefined && items[cat.id].length === 0 && <><div className="mb-3 text-white text-center">There are no items in this category</div></>}
												{items[cat.id] !== undefined && items[cat.id].map( (item,index) =>{
													return (
													<div key={"item_"+index} className="col-6 col-md-4 col-xl-3 card-group">
														<div className="card mb-3">
															<div className="itemMedia p-0 card-body">
																{item.discount > 0 && <>
																	<Button variant="primary" size="sm" className="dispDiscount" disabled>{item.discount/item.sale_price*100}% OFF</Button>
																</>
																}
																{item.media.length > 0 ?
																<Carousel variant="dark" controls={(item.media.length > 1) ? true : false} indicators={(item.media.length > 1) ? true : false}>
																{item.media.map((media,medi) => {
																	return(
																		<Carousel.Item key={"media_"+medi}>
																			{ (media.type === 'image/png' || media.type === 'image/jpeg') ?
																				<a href={media.name} data-type="image" data-width="100%" data-glightbox={"gallery"+cati+index} data-gallery={"gallery"+cati+index}>
																					<img data-length={item.media.length} className="card-img-top" src={media.name} height="200" alt={"Items "+index+medi}/>
																				</a>
																			:
																				<a href={media.name} data-type="video" data-width="100%" data-glightbox={"gallery"+cati+index} data-gallery={"gallery"+cati+index}>
																				<video className="w-100" controls>
																					<source src={media.name} type={media.type}/>
																					Your browser does not support the video tag.
																				</video>
																				</a>
																			}
																		</Carousel.Item>
																	)
																})}
																</Carousel>
																: <img className="card-img-top" src={window.location.origin+"/assets/img/no-media.png"} height="200" alt={"Items "+index}/>
																}
															</div>
															<div className="card-footer px-3 py-2">
																<div className="card-title">{item.name}</div>
																<div className="card-text mb-1">
																	<div className="row d-flex align-items-center">
																		<div className="col-sm-5 col-md-12 col-lg-5">
																			<div className="small_font text-center text-sm-start text-md-center text-lg-start">
																				<div>{Common.displayPrice(item.sale_total_price)}</div>
																				{item.discount>0 && <div><small className="text-decoration-line-through text-muted">{Common.displayPrice((Number(item.sale_price) + (Number(item.gst_amt)/(Number(item.sale_price)-Number(item.discount))).toFixed(2)*Number(item.sale_price)))}</small></div>}
																			</div>
																		</div>
																		<div className="col-sm-7 col-md-12 col-lg-7 mt-2 mt-sm-0 text-center text-sm-end text-md-center text-lg-end">
																			{item.service === '0' &&
																				<div className={"x_small_font text-primary "+(presentInCart(item.iid) ? "text-end" : "text-lg-end text-md-center text-sm-end text-center")}>Min: {Common.displayPercent(item.min_qty)} {item.item_unit}</div>
																			}
																			{presentInCart(item.iid) ? <div className="row">
																			<ButtonGroup size="sm" aria-label="Cart Button">
																			  <Button onClick={()=>decrementCart(item)} variant="success"><i className='bx bx-minus'></i></Button>
																			  <Button variant="success" className="fw-bold btnIncVal disabled">{cntItemsInCart(item.iid)}</Button>
																			  <Button onClick={()=>incrementCart(item)} variant="success"><i className='bx bx-plus'></i></Button>
																			</ButtonGroup>
																			</div> : <Button onClick={()=>addToCart(item)} size="sm" variant="outline-success">Add</Button>
																			}
																		</div>
																	</div>
																</div>
																{/*<p className="card-text">
																	<small className="text-muted">Last purchased {Math.floor(Math.random() * (10 - 1 + 1)) + 1} mins ago</small>
																</p>*/}
															</div>
														</div>
													</div>
													)
												})}
											</div>
										)
									})}
								</div>
							</div>
						</div>
                    </div>
					<div className="fixed-bottom d-block d-md-none">
						<div className="d-grid mx-auto">
							<ButtonGroup aria-label="Cart Button">
								<Button onClick={()=>setShowCat(true)} variant="primary" className=""><i className='bx bx-md bx-list-ul'></i>Categories</Button>
								<Button onClick={()=>setShowCart(true)} variant="success" className="py-0 d-flex align-items-center justify-content-center"><i className={"bi bx-md align-text-bottom "+(cartItems.length === 0 ? "bi-cart ":"bi-cart-fill ") + cartAnimation}></i>
									<div className="ms-1 text-end align-text-bottom">
										{cartItems.length === 0 && 'My Cart'}
										{cartItems.length > 0 && <>{cartItems.length} items<br/> {Common.displayPrice(cartTotal)}</>}
									</div>
								</Button>
							</ButtonGroup>
						</div>
					</div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
	</div>
	);
}