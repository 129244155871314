import React,{useState}  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
// import 'react-date-picker/dist/DatePicker.css';
import Button from 'react-bootstrap/Button';
import * as Common from '../common/ImportUserEssentials';
import Modal from 'react-bootstrap/Modal';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import * as DPE from '../common/ImportDatePickerEssentials';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default function Gst(props) {
    // const [taxCompliance_w, setTaxCompliance_w] =  useState(true);
    // const [gstStatus, setGstStatus] = useState('0');
    const [showProModal, setShowProModal] = useState(false);
    const [dates, setDates] = useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
    const { start, end } = dates;
    const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
    const setDatePicker = (start, end) => {
        setDates({ start, end });
    }
    const [editModal,setEditModal] = useState(false);
    const [editPurchaseModal,setEditPurchaseModal] = useState(false);
    const handleModalClose = () => {setEditModal(false);}
    const handlePurchaseModalClose = () => {setEditPurchaseModal(false);}
    const navigate = Common.useNavigate();
    React.useEffect(() => {
        document.title = 'GST | '+props.site_name;
        // let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
        // if( userPerm && Object.keys(userPerm).length > 0 ){
        //     setTaxCompliance_w((userPerm[1]['write'] !== undefined && userPerm[1]['write'] === '0') ? false : true);
        // }
        if (localStorage.getItem('fo_subplan') === 'Basic' || localStorage.getItem('fo_subplan') === 'Standard') {
			navigate("/pricing-plan");
		}
        Common.axios({
            method: 'post',
            url:props.url+'gst/getGstStatus',
            data: {
                'pid':localStorage.getItem('fo_profileid')
            }
        }).then(function (response) {
            if(response.data.success){
                // setGstStatus(response.data.data.gst_status);
                if (response.data.data.gst_status === '1') {
                    setShowProModal(false);
                    return false;
                } else {
                    if (localStorage.getItem('fo_subplan') === 'PRO') {
                        setShowProModal(true);
                        return false;
                    }

                }
                return false;
            }
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}, [props]);
    const popUpIncomeDataRange = () => {
        setEditModal(true);
    }
    const popUpPurchaseDataRange = () => {
        setEditPurchaseModal(true);
    }
    const subscriptionRedirect =() => {
        navigate('/pricing-plan');
    }
    
    const handleIncomeCSVExport = (e) => {
        e.preventDefault();
        Common.axios({
            method: 'post',
            url: props.url+'gst/downloadIncomeCollectionRecords/',
            data: {
            pid:localStorage.getItem('fo_profileid'),
            dates:dates
        },
        // responseType: 'blob'
        }).then(function (response) {
            if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Income-Report-${Date.now()}.csv`);
                document.body.appendChild(link); */
                setEditModal(false);
                // link.click();
            } else{
                Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
    }

    const handleExpenseCSVExport = (e) => {
        e.preventDefault();
        Common.axios({
            method: 'post',
            url: props.url+'gst/downloadPurchaseExpensesRecords/',
            data: {
            pid:localStorage.getItem('fo_profileid'),
            dates:dates
        },
        // responseType: 'blob',
        }).then(function (response) {
            if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Expenses-Report-${Date.now()}.csv`);
                document.body.appendChild(link); */
                window.location.href = response.data.message;
                setEditPurchaseModal(false);
                // link.click();
            } else{
                Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
    }
    
    return (
        <>
            <div className="layout-wrapper layout-menu-fixed layout-content-navbar">
                <div className="layout-container">
                <Layout site_logo={props.site_logo} label="tax_compliance" site={props.site_name}/>
                    <div className="layout-page">
                        <NavBar site={props.site_name} url={props.url}/>
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Tax Compliance /</span> GST</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="nav-align-top mb-4">
                                            <ul className="nav nav-tabs nav-fill" role="tablist">
                                                <li className="nav-item">
                                                    <button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="true">
                                                        GST returns
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-profile" aria-controls="navs-justified-profile" aria-selected="false">
                                                        GSTR 2B
                                                    </button>
                                                </li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane fade show active" id="navs-justified-home" role="tabpanel">
                                                    <h3 className="display-6">GSTR1</h3>
                                                    <ol className="mb-4 p-2">
                                                        <li style={{'padding':'0.25rem'}}><button type="button" className="btn btn-primary btn-sm" onClick={popUpIncomeDataRange}>Download</button> the sales register with document date within above selected date range</li>
                                                        <li style={{'padding':'0.25rem'}}><u><a href="https://www.fynamicsgst.in/fynone/" rel="noreferrer" target="_blank">Click Here</a></u> to navigate to Fynamics GST compliance module</li>
                                                        <li style={{'padding':'0.25rem'}}>Upload the file. Fynamics GST automatically segregates data into relevant GSTN buckets and across individual GSTINs</li>
                                                        <li style={{'padding':'0.25rem'}}>Access reports and dashboard for MIS review and file returns</li>
                                                    </ol>
                                                    <h3 className="display-6 mt-2">GSTR 3B, 6, ITC04 etc</h3>
                                                    <ol className="mb-4 p-2">
                                                        <li style={{'padding':'0.25rem'}}><a href="https://www.fynamicsgst.in/fynone/" rel="noreferrer" target="_blank">Click Here</a> to navigate to Fynamics GST compliance module and perform the activities directly</li>
                                                    </ol>
                                                    <hr/>
                                                    <p className="p-lead text-center">Fynamics GST, FYN Gateway and FYN ONE are all products of Fynamics - an approved GST Suvidha Provider.</p>
                                                </div>
                                                <div className="tab-pane fade" id="navs-justified-profile" role="tabpanel">
                                                    <h3 className="display-6 mt-2">GSTR 2B Reconciliation</h3>
                                                    <ol className="mb-4 p-2">
                                                        <li style={{'padding':'0.25rem'}}><button type="button" className="btn btn-primary btn-sm" onClick={popUpPurchaseDataRange}>Download</button> the purchase register with document date within above selected date range</li>
                                                        <li style={{'padding':'0.25rem'}}><u><a href="https://www.fynamicsgst.in/fynone/" rel="noreferrer" target="_blank">Click Here</a></u> to navigate to Fynamics GST compliance module</li>
                                                        <li style={{'padding':'0.25rem'}}>Upload the file. Fynamics GST automatically identifies duplicate invocies and amendment transactions</li>
                                                        <li style={{'padding':'0.25rem'}}>Perform reconciliation with GSTR2B pulled from GSTN automatically via APIs</li>
                                                        <li style={{'padding':'0.25rem'}}>Fynamics reconciles the data using AI engines and segregates them into Exact Match, Partial Match and No Match</li>
                                                        <li style={{'padding':'0.25rem'}}>Take relevant actions on ITC claims to impact 3B suggestion</li>
                                                    </ol>
                                                    <hr/>
                                                    <p className="p-lead text-center">Fynamics GST, FYN Gateway and FYN ONE are all products of Fynamics - an approved GST Suvidha Provider.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Common.FYNTicket url={props.url}/>
                    </div>
                </div>
            </div>
            {/* income */}
            <Modal {...props} size="sm" show={editModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Choose Date Range For Sale Records</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <DateRangePicker initialSettings={DPE.DatePickerPredefined(moment,start,end)} onCallback={setDatePicker}>
                <div className="input-group">
                    <span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
                    <div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
                        <i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
                    </div>
                </div>
            </DateRangePicker>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleIncomeCSVExport}>
                    Download
                </Button>
            </Modal.Footer>
        </Modal>
        {/* purchase */}
        <Modal {...props} size="sm" show={editPurchaseModal} onHide={handlePurchaseModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Choose Date Range For Purchase Records</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <DateRangePicker initialSettings={DPE.DatePickerPredefined(moment,start,end)} onCallback={setDatePicker}>
                <div className="input-group">
                    <span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
                    <div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
                        <i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
                    </div>
                </div>
            </DateRangePicker>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleExpenseCSVExport}>
                    Download
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal {...props} size="sm" show={showProModal}  aria-labelledby="gst-compliance-modal" backdrop="static" keyboard={false} centered>
            <Modal.Header>
                <div className="col-6 modal-mobile-view">
                    <Modal.Title>Renewal/Upgrade</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
            
                <b >GST compliance module can be enabled under PRO plan . Please <button className='border-0 bg-white p-0 text-primary cursor-pointer' onClick={(e)=>subscriptionRedirect()}>Click Here</button> to enable</b>
            </Modal.Body>
        </Modal>
        </>
    );
}
