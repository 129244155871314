import React from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import { usePapaParse } from 'react-papaparse';
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
export default function AddSaleFile(props){
	// Upload file
	const { readString } = usePapaParse();
	const [template_list,setTemplateList] = React.useState([]);
	const [dl_file,setDownloadFile] = React.useState({'value':'','label':'','path':'','col_count':0});
	const [ul_file, setUploadFile] = React.useState();
	const inputUlFile = React.useRef(null);
	const selectDlFile = React.useRef(null);
	
	//const [ul_cron,setUlCron] = React.useState();

	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
	const [DTpending, setDTPending] = React.useState(true);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
    const [crons, setCronList]    = React.useState([]);
	
	const [viewCronModal, setViewCronModal] = React.useState(false);
	const [cron_details,setCronDetails] = React.useState();
	const [incomeColl_w,setincomeColl_w] = React.useState(true);
	const navigate = Common.useNavigate();
	React.useEffect(() => {
		document.title = 'Add Sale File | '+props.site_name;
		if (localStorage.getItem('fo_subplan') !== 'Enterprise') {
			navigate("/pricing-plan");
		}
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Write permission
			setincomeColl_w((userPerm[2]['write'] !== undefined && userPerm[2]['write'] === '0') ? false : true);
		}
		
		// Template Master
		Common.axios({
			method: 'get',
			url: props.url+'templates/fetch_list/sales/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if( response.data.success ){
				setTemplateList(response.data.data);
			}
		}).catch(error => console.log(error));
		loadDataTable(1,perPage,filterText);
    },[props,filterText,dt_load]);
	if( !incomeColl_w ){
		navigate("/not-authorized");
	}
	function refreshCrons(){
		setDtLoad(!dt_load);
	}
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		loadDataTable(page,newPerPage,filterText);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection));
	};
    const loadDataTable = (page,perPage,filterText,sort=btoa('createdon####desc')) => {
		Common.axios({
			method: 'post',
			url:props.url+'cron/listing/',
			data: {'type':'sales','pid':localStorage.getItem('fo_profileid'),'page':page,'perPage':perPage,'sort':sort,'search':filterText}
		}).then(function (response) {
			if(response.data.success){
				setCronList(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error))
	}
	function showCronDetails(id){
		setViewCronModal(true);
		Common.axios({
			method: 'post',
			url:props.url+'cron/details/',
			data: {'pid':localStorage.getItem('fo_profileid'),'id':id}
		}).then(function (response) {
			if(response.data.success){
				setCronDetails(response.data.data);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error))
	}
	function ulFileAction(event) {
		if(event.target.files.length > 0 ){
			const reader = new FileReader();
			reader.onload = () => {
				const csvData = reader.result;
				const parsedData = readString(csvData);
				const numberOfColumns = parsedData.data[0].length;

				if( numberOfColumns !== parseInt(dl_file.col_count) ){
					Common.toast.error('Uploaded file doesn\'t match the template');
					event.target.value = null;
					return false;
				}
				setUploadFile(event.target.files[0])
				return false;
			};
			reader.readAsText(event.target.files[0]);
		}
	}
	function submitUlFile(event) {
		event.preventDefault();
		Common.showLoader(true);
		const formData = new FormData();
		formData.append('templateid',dl_file.value);
		formData.append('file', ul_file);
		formData.append('filename', ul_file.name);
		formData.append('pid', localStorage.getItem('fo_profileid'));
		Common.axios({
			mode: 'cors',
            url: props.url+'sale/upload_file',
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				selectDlFile.current.clearValue();
				inputUlFile.current.value = null;
				Common.toast.success(response.data.message);
				setDownloadFile({'value':'','label':'','path':'','col_count':0});
				setUploadFile();
				loadDataTable(1,perPage,filterText);
				return false;
			}
			Common.toast.error(response.data.message);
		});
	}
	const columns = [
        {
            name: 'File Name',
            selector: row => row.filename,
            sortable: true,
			sortField: 'filename',
			cell: (row) => (
				<a href={row.filepath}>{row.filename}</a>
			)
        },
        {
            name: 'Template Name',
            selector: row => row.template,
            sortable: true,
			sortField: 'template'
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
			sortField: 'status',
			cell: (row) => (
				( row.finishedon !== '0000-00-00 00:00:00' ) ? 'Completed' : 'Pending'
			)
        },
        {
            name: 'Created By',
            selector: row => row.creator,
            sortable: true,
            sortField: 'creator',
        },
        {
            name: 'Created On',
            selector: row => Common.displayDateTime(row.createdon),
            sortable: true,
            sortField: 'createdon',
        },
        {
            name: 'Details',
			center: true,
            selector: row => row.startedon,
            sortable: false,
            cell: (row) => (
				<button onClick={(e) => showCronDetails(row.id)} className="btn btn-link"><i className='bx bx-book-reader'></i> View </button>
			)
        }
    ];
    return(
	<>
		<div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="add-sale-file" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Income & Collections /</span> Add Sale File
								<span className="dropdown">
									<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
									<i className='text-danger bx bxl-youtube bx-md'></i>
									</button>
									<div className="dropdown-menu" >
										<a href="https://youtu.be/MQm7uec5Nec" className="dropdown-item" target="_blank" rel="noreferrer"><span>Bulk upload invoices</span></a>
									</div>
								</span>
							</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <div className="card-body">
											<div className="row my-1">
												<div className="col-sm-6 border-sm-end mb-2">
													<h6><span className="badge bg-primary">Step 1</span> Download Template</h6>
													<Select ref={selectDlFile} placeholder="Select Template" name='templateid' onChange={setDownloadFile} options={template_list}/>
													{dl_file.value !== '' && 
													<div className="m-3">
														<div className="card">
															<div className="card-header">
																Selected Template : {dl_file.label}
															</div>
															<ul className="list-group list-group-flush">
																<li className="list-group-item">File Name : {dl_file.path.split('/').pop()}</li>
																<li className="list-group-item text-center"><a className="btn btn-sm btn-primary" href={dl_file.path}>Download</a></li>
															</ul>
														</div>
													</div>
													}
												</div>
												<div className="col-sm-6 mb-2">
													<h6><span className="badge bg-primary">Step 2</span> Upload</h6>
													<div className="mb-3">
														<input className="form-control" ref={inputUlFile} type="file" onChange={ulFileAction} disabled={dl_file.col_count === 0}/>
													</div>
													{dl_file.value !== '' && ul_file !== undefined && 
													<div className="m-3">
														<div className="text-center">
															<button className="btn btn-sm btn-primary" onClick={submitUlFile}>Upload</button>
														</div>
													</div>
													}
												</div>
											</div>
											{/*ul_cron && Object.keys(ul_cron).length > 0 &&
											<div className="row justify-content-md-center">
												<div className="col-sm-6">
													<Alert key="saleulalert" variant="warning">
														File processing pending (details below):
														<ul>
															<li>Name : <a href={ul_cron.filepath}>{ul_cron.filename}</a></li>
															<li>Uploaded On : {Common.displayDateTime(ul_cron.createdon)}</li>
														</ul>
													</Alert>
												</div>
											</div>*/}
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-3 mb-1 text-start">
									<button className={"btn btn-sm btn-info me-2"} onClick={refreshCrons}><i className="bx bx-refresh"></i> <span className="hidden-phone">Refresh</span></button>
								</div>
								<div className="col-9 text-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','File Name, Template Name, Created By')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
							<div className="row">
                                <div className="col-md-12">
                                    <DTE.DataTable defaultSortFieldId={5} defaultSortAsc={false} className="rdt_AdjDrp" title={"File Uploads ("+totalDataCnt+")"} columns={columns} data={crons} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} persistTableHead/>
                                </div>
                            </div>
							<a href="/sales-listing" role="button" className="my-3 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>							
						</div>
					</div>
				</div>
			</div>
		</div>
		<Modal size={"lg"} show={viewCronModal} onHide={()=>setViewCronModal(false)} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>Cron Details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Common.Row>
					<Common.Col md="6"><strong>File Name : </strong><a href={cron_details && cron_details.filepath}>{cron_details && cron_details.filename}</a></Common.Col>
					<Common.Col md="6"><strong>Uploaded On : </strong>{cron_details && Common.displayDateTime(cron_details.createdon)} </Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col md="6" className="text-break"><strong>Processing Time (HH:mm:ss) : </strong>{cron_details && cron_details.diff}</Common.Col>
					<Common.Col md="6" className="text-break"><strong>Total Records : </strong>{cron_details && cron_details.total_rows}</Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col md="6" className="text-break"><strong>Status: </strong>{cron_details && cron_details.finishedon !== '0000-00-00 00:00:00' ? 'Completed' : 'Pending'}</Common.Col>
				</Common.Row>
			</Modal.Body>
		</Modal>
	</>
	);
}