import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';
export {DataTable};

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;	
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 32px;
	width: 32px;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: top;
`;
export const FilterComponent = ({ placeholder,filterText, onFilter, onClear }) => (
	<>
		<TextField
			id="search"
			type="text"
			placeholder={placeholder}
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
			autoComplete="off"
		/>
		<ClearButton type="button" onClick={onClear}>
			X
		</ClearButton>
	</>
);
export const DatePickerPredefined = (moment,start,end) => ({
	"autoApply": true,
	startDate: start.toDate(),
	endDate: end.toDate(),
	/*"maxSpan": {
        "days": 30
    },*/
	ranges: {
		Today: [moment().toDate(), moment().toDate()],
		Yesterday: [
			moment().subtract(1, 'days').toDate(),
			moment().subtract(1, 'days').toDate(),
		],
		'Last 7 Days': [
			moment().subtract(6, 'days').toDate(),
			moment().toDate(),
		],
		'Last 30 Days': [
			moment().subtract(29, 'days').toDate(),
			moment().toDate(),
		],
		'This Month': [
			moment().startOf('month').toDate(),
			moment().endOf('month').toDate(),
		],
		'Last Month': [
			moment().subtract(1, 'month').startOf('month').toDate(),
			moment().subtract(1, 'month').endOf('month').toDate(),
		],
		'Current Financial Year': [
			moment().startOf('year').add(3, 'months').toDate(),
			moment().endOf('year').subtract(9, 'months').add(1, 'years').toDate(),
		],
		'Last One Year': [
			moment().subtract(1, 'years').toDate(),
			moment().toDate(),
		]
	}
});