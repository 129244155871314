import React,{useState,useEffect} from 'react';
import AccounHeader from './AccountHeader';
import OtpInput from 'react-otp-input';
import * as Common from '../common/ImportGuestEssentials';

export default function Login(props){
	const [otp, setOtp] = useState('');
	const [errorMessage,setError] = useState('');
	const navigate = Common.useNavigate();
	const { state } = Common.useLocation();
	const [seconds, setSeconds] = useState(30);

	useEffect(() => {
		document.title = 'Verify Mobile | '+props.site_name;
		if( state === null ){
			navigate('/');
		}
		if( localStorage.getItem('fo_loggedin') ){
			navigate("/post-login");
		}
		const sec_interval = setInterval(() => {
			if (seconds > 0) {
			  setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				clearInterval(sec_interval);
			}
		}, 1000);
		return () => clearInterval(sec_interval);
	}, [state,props,seconds,navigate]);
	
	const setOtpValue = (val) => {
		setOtp(Common.acceptOnlyNumbers(val))
	}
	const submitOTP = () => {
		if( otp.length !== 6 ){
			setError('Invalid OTP entered.');
			return false;
		}
        Common.axios({
            method: 'post',
            url:props.url+'login/mobile_otp',
            data: {
				otp: Common.AES.encrypt(otp.toString(), props.site_key).toString(),uid:Common.AES.encrypt(state.uid.toString(), props.site_key).toString(),type:Common.AES.encrypt(state.type, props.site_key).toString()
			},
        }).then(function (response) {
			if(response.data.success){
				localStorage.setItem('fo_loggedin', state.uid);
				localStorage.setItem('fo_profileid',response.data.profileid);
				if( response.data.profileid === '0' ){
					navigate('/add-profile');
					return;
				}
				navigate('/post-login');
				return;
			}
			setError(response.data.message);
			setOtpValue('');
			setTimeout(() => {
				setError('');
			}, 5000);
        }).catch(error => console.log(error));
	}
	const handleOTPSubmit =(e) => {
        e.preventDefault();
		submitOTP();
    }
	if(otp.length === 6){
		submitOTP();
	}
    return (
        <div className="container-xxl">
			<div className="authentication-wrapper authentication-basic container-p-y">
				<div className="authentication-inner">
					<div className="card">
						<div className="card-body">
							<AccounHeader site_logo={props.site_logo} site_name={props.site_name} />
							<div className="text-center mb-3">
								<h6>Please enter the one time password <br/> to verify your account</h6>
								<div> <span>A code has been sent to</span> <small>******<span>{state !== null && state.last4}</span></small> </div>
								{errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
								{errorMessage}</div>)}
								<form autoComplete="off" onSubmit={handleOTPSubmit}>
									<div className="inputs d-flex flex-row justify-content-center mt-2">
									<OtpInput value={otp} onChange={setOtpValue} numInputs={6} shouldAutoFocus={true} inputStyle = {'otpInput m-2 text-center form-control rounded'}
										inputType="tel"
										renderSeparator={<span>-</span>}
										renderInput={(props) => <input {...props}/>}
									/>
									</div>
									<div className="mt-4"> <button type="submit" className="btn btn-primary px-4 validate">Validate</button> </div>
								</form>
							</div>
							<p className="text-center">
								<span>Not your number? </span>
								<Common.Link to="/login">
								<span>Back</span>
								</Common.Link>
							</p>
							<p className="text-center">
								{seconds > 0 ? (
								<>Time Remaining: {seconds < 10 ? `0${seconds}` : seconds} secs</>
								) : (
								<>Didn't receive code? <Common.Link to="/login">Go back</Common.Link> and try again.</>
								)}
							</p>
						</div>
					</div>
				</div>
			</div>
        </div>
    );
}
