import * as myConstClass  from './common/Constant.js';
import NoInternetConnection from './common/NoInternetConnection'
import Landing from './components/Landing';
import Icai from './components/Icai';
import Icsi from './components/Icsi';
import Privacy from './components/Privacy';
import Tnc from './components/Tnc';
import Shipping from './components/Shipping';
import Cancellation from './components/Cancellation';
import DataPurging from './components/DataPurging';
import Login from './components/Login';
import SignUp from './components/SignUp';
import VerifyMobile from './components/VerifyMobile';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import PostLogin from './components/PostLogin';
import AddProfile from './components/AddProfile';
import EditProfile from './components/EditProfile';
import Profile from './components/Profile';
import AddPoContract from './components/AddPoContract';
import PoContract from './components/PoContract';
import Sales from './components/Sales';
import AddCustomer from './components/AddCustomer';
import AddItem from './components/AddItem';
import AddItemFile from './components/AddItemFile';
import EditItem from './components/EditItem';
import SalesListing from './components/SalesListing';
import ProfileList from './components/ProfileList.js';
import BankAccounts from './components/BankAccounts.js';
import AddBankAccount from './components/AddBankAccount.js';
import InvoiceRemarks from './components/InvoiceRemarks.js';
import ItemListing from './components/ItemListing.js';
import AddExpense from './components/AddExpense';
import ExpenseListing from './components/ExpenseListing';
import CustomerList from './components/CustomerListing';
import SellerList from './components/SellerListing';
import AddSeller from './components/AddSeller';
import EditSale from './components/EditSale';
import CustomerEdit from './components/CustomerEdit';
import AddUser from './components/AddUser';
import UserGroups from './components/UserGroups';
import UserListing from './components/UserListing';
/*import EditUser from './components/EditUser';*/
import PageNotFound from './components/PageNotFound';
import CollectionListing from './components/CollectionListing.js';
import AddCollection from './components/AddCollection';
import EditCollection from './components/EditCollection';
import EditCustomerCollection from './components/EditCustomerCollection';
import PayableListing from './components/PayableListing.js';
import AddPayable from './components/AddPayable';
import EditPayable from './components/EditPayable';
import EditSellerPayable from './components/EditSellerPayable';
import EditSeller from './components/EditSeller';
import Subscription from './components/Subscription';
import NotAuthorized from './components/NotAuthorized';
import DispatchStock from './components/DispatchStock';
import SaleSettings from './components/SaleSettings';
import Quotation from './components/Quotation';
import AddQuotation from './components/AddQuotation';
import AddSaleOrder from './components/AddSaleOrder';
import AddSaleFile from './components/AddSaleFile';
import ConvertSale from './components/ConvertSale';
import ConvertSaleOrder from './components/ConvertSaleOrder';
import CopySale from './components/CopySale';
import DocumentManager from './components/DocumentManager';
import AddDocumentManager from './components/AddDocumentManager';
import DataSecurity from './components/DataSecurity';
import Dashboard from './components/Dashboard';
import ExpenseDashboard from './components/ExpenseDashboard';
import Gst from './components/Gst';
import EditBankAccount from './components/EditBankAccount';
import OtherIncome from './components/OtherIncome';
import OtherExpense from './components/OtherExpense';
import AddBankTransfer from './components/AddBankTransfer';
import PoContractListing from './components/PoContractListing';
import AddPo from './components/AddPo';
import LoanListing from './components/LoanListing';
import AddLoan from './components/AddLoan';
import AddLoanGiven from './components/AddLoanGiven';
import AssetsManagementListing from './components/AssetsManagementListing';
import AddAsset from './components/AddAsset';
import FinancialSummary from './components/FinancialSummary';
import ProfitLossStatement from './components/ProfitLossStatement';
import Investments from './components/Investments';
import AddInvestments from './components/AddInvestments';
import Transactions from './components/Transactions';
import JournalLedger from './components/JournalLedger';
import MartLanding from './components/MartLanding';
import MartInventory from './components/MartInventory';
import MartAdd from './components/MartAdd';
import MartItemPhotos from './components/MartItemPhotos';
import MartCategories from './components/MartCategories';
import MartOrders from './components/MartOrders';
import MartOrdersAdd from './components/MartOrdersAdd';
import MartInvPrint from './components/MartInvPrint';
import AddDeliveryChallan from './components/AddDeliveryChallan';
import MartPayment from './components/MartPayment';
import MartSettings from './components/MartSettings';
import PartnerDetails from './components/PartnerDetails';
import FynoneMarketing from './components/FynoneMarketing';
import AddFynoneMarketing from './components/AddFynoneMarketing';
import PartnerPolicy from './components/PartnerPolicy';
import AddPartnerPolicy from './components/AddPartnerPolicy';
import PartnerMyClients from './components/PartnerMyClients';
import PartnerClients from './components/PartnerClients.js';
import LandingPartner from './components/LandingPartner';
import AddPartner from './components/AddPartner';
import PartnerClientsOrders from './components/PartnerClientsOrders';
import PartnerMyClientsOrders from './components/PartnerMyClientsOrders';

import React, { Component } from "react";
//import  { Navigate } from 'react-router-dom';
import {BrowserRouter  as Router,Routes,Route  } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import WhatsNew from './components/WhatsNew';
/*import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas,far);
*/

/*function shareLS()
{
	const sharedData = { uid: localStorage.getItem('fo_loggedin')};
	var win = document.getElementById('ifr').contentWindow;
	win.postMessage(sharedData, "http://mart.localhost:3000");
}*/
class App extends Component { 
  render() {
    return (
        <div className="App">
			<NoInternetConnection>
            <Router>
              <Routes>
                <Route path="/register" exact  element={<SignUp recaptcha_key={myConstClass.RECAPTCHA_KEY} site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/verify-mobile" exact  element={<VerifyMobile site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/forgot_password" exact  element={<ForgotPassword site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/reset/:hash"  element={<ResetPassword site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/post-login"   element={<PostLogin url={myConstClass.BASE_URL} />} />
                <Route path="/add-profile"   element={<AddProfile site_logo = {myConstClass.SITE_LOGO} mart_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/edit-profile"   element={<EditProfile site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/profile" element={<Profile referrer="fynone" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
		        <Route path="/login" exact element={<Login recaptcha_key={myConstClass.RECAPTCHA_KEY} site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/add-sale" exact  element={<Sales site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/copy-sale" exact  element={<CopySale site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/edit-draft" exact  element={<CopySale site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/quotation" exact  element={<Quotation site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/add-quotation" exact  element={<AddQuotation site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/add-sale-orders" exact  element={<AddSaleOrder site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/add-sale-file" exact  element={<AddSaleFile site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/convert-sale" exact  element={<ConvertSale site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/convert-sale-order" exact  element={<ConvertSaleOrder site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/new-customer" exact  element={<AddCustomer referrer="fynone" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/sale-settings" exact element={<SaleSettings site_key = {myConstClass.SITE_KEY} fynone_logo = {myConstClass.FYNONE_LOGO} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/add-po-contract" exact element={<AddPoContract site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/po-contract" exact element={<PoContract site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/sales-listing" exact element={<SalesListing site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/add-item" exact  element={<AddItem referrer="fynone" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/add-item-file" exact  element={<AddItemFile site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/edit-item" exact  element={<EditItem referrer="fynone" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/item-listing" exact element={<ItemListing site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/dispatch-stock" exact element={<DispatchStock site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/add-expense" exact element={<AddExpense site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/expense-draft" exact element={<AddExpense site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/expense-listing" exact element={<ExpenseListing site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/po-listing" exact element={<PoContractListing site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/add-po" exact element={<AddPo site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/profiles" exact  element={<ProfileList site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/bank-accounts" exact  element={<BankAccounts site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/add-bank-account" exact  element={<AddBankAccount site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/edit-bank-account" exact  element={<EditBankAccount site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/add-bank-transfer" exact  element={<AddBankTransfer site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/invoice-remarks" exact  element={<InvoiceRemarks site_key = {myConstClass.SITE_KEY}  site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/customer-listing" exact  element={<CustomerList referrer="fynone" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/seller-listing" exact  element={<SellerList site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/add-seller" exact  element={<AddSeller site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/edit-seller" exact  element={<EditSeller site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/edit-sale/:id"   element={<EditSale site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/edit-customer" exact element={<CustomerEdit referrer="fynone" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/add-user" exact  element={<AddUser site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/user-groups" exact  element={<UserGroups site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/user-listing" exact  element={<UserListing site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/collection-listing" exact  element={<CollectionListing referrer="fynone" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/add-collection" exact  element={<AddCollection referrer="fynone" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="edit-collection" exact  element={<EditCollection referrer="fynone" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="edit-customer-collection" exact  element={<EditCustomerCollection referrer="fynone" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/other-income" exact  element={<OtherIncome site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/other-expense" exact  element={<OtherExpense site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
                <Route path="/payable-listing" exact  element={<PayableListing site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/add-payable" exact  element={<AddPayable site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/add-payable" exact  element={<AddPayable site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/document-manager" exact  element={<DocumentManager site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/add-document-manager" exact  element={<AddDocumentManager site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="data-security" exact  element={<DataSecurity site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="whats-new" exact  element={<WhatsNew referrer="fynone" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="edit-payable" exact  element={<EditPayable site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="edit-seller-payable" exact  element={<EditSellerPayable site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/" exact  element={<Landing site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/chartered-accountants" exact  element={<Icai recaptcha_key={myConstClass.RECAPTCHA_KEY} site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
        <Route path="/company-secretaries" exact  element={<Icsi recaptcha_key={myConstClass.RECAPTCHA_KEY} site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
        <Route path="/landing-partner" exact  element={<LandingPartner recaptcha_key={myConstClass.RECAPTCHA_KEY} site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/privacy" exact  element={<Privacy site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/tnc" exact  element={<Tnc site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/shipping" exact  element={<Shipping site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/cancellation" exact  element={<Cancellation site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/datapurging" exact  element={<DataPurging site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/pricing-plan" element={<Subscription site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} pricing_logo = {myConstClass.PRICING_LOGO} url={myConstClass.BASE_URL} />} />
				<Route path="/dashboard" element={<Dashboard referrer="fynone" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/expense-dashboard" element={<ExpenseDashboard site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/gst" element={<Gst site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/not-authorized" element={<NotAuthorized site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/loan-listing" exact  element={<LoanListing site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/add-loan" exact  element={<AddLoan site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/add-loan-given" exact  element={<AddLoanGiven site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/assets-listing" exact  element={<AssetsManagementListing site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />				
				<Route path="/add-asset" exact  element={<AddAsset site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="financial-summary" exact  element={<FinancialSummary site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="profit-loss-statement" exact  element={<ProfitLossStatement site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/investments-listing" exact  element={<Investments site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />        
				<Route path="/add-investments" exact  element={<AddInvestments site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart" exact  element={<MartLanding site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL} link={myConstClass.BASE_LINK}/>} />
				<Route path="/mart-inventory" exact  element={<MartInventory site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-add-inventory" exact  element={<AddItem referrer="fynmart" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-edit-inventory" exact  element={<EditItem referrer="fynmart" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-add" exact  element={<MartAdd param="add" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL} martlink={myConstClass.MART_LINK}/>} />
				<Route path="/mart-settings" exact  element={<MartAdd param="settings" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL} martlink={myConstClass.MART_LINK}/>} />
				<Route path="/settings" exact  element={<MartSettings param="settings" fynone_logo = {myConstClass.FYNONE_LOGO} site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL} martlink={myConstClass.MART_LINK}/>} />
				<Route path="/mart-item-photos" exact  element={<MartItemPhotos site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-categories" exact  element={<MartCategories site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-add-order" exact  element={<MartOrdersAdd site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-orders" exact  element={<MartOrders site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-payment" exact  element={<MartPayment site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-dashboard" exact  element={<Dashboard referrer="fynmart" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-inv-print/:inv" exact  element={<MartInvPrint site_key = {myConstClass.SITE_KEY} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-collection-listing" exact  element={<CollectionListing referrer="fynmart" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-collection-add" exact  element={<AddCollection referrer="fynmart" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-collection-edit" exact  element={<EditCollection referrer="fynmart" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-collection-customer-edit" exact  element={<EditCustomerCollection referrer="fynmart" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-customer-listing" exact  element={<CustomerList referrer="fynmart" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-customer-add" exact  element={<AddCustomer referrer="fynmart" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/mart-customer-edit" exact  element={<CustomerEdit referrer="fynmart" site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_MERCHANT_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.BASE_URL}/>} />

				<Route path="/transactions" exact  element={<Transactions site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/journal-ledger" exact  element={<JournalLedger site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/add-dc" exact  element={<AddDeliveryChallan site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/edit-dc" exact  element={<AddDeliveryChallan site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/partners" exact  element={<PartnerDetails site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="partner-whats-new" exact  element={<WhatsNew referrer="partnerportal" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/partner-profile" element={<Profile referrer="partnerportal" site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
				<Route path="/fynone-marketing" exact  element={<FynoneMarketing site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/add-fynone-marketing" exact  element={<AddFynoneMarketing site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/partner-policy" exact  element={<PartnerPolicy site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/add-partner-policy" exact  element={<AddPartnerPolicy site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/partner-myclients" exact  element={<PartnerMyClients site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/partner-clients" exact  element={<PartnerClients site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/add-partner" exact  element={<AddPartner site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/partner-myclients-orders" exact  element={<PartnerMyClientsOrders site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				<Route path="/partner-clients-orders" exact  element={<PartnerClientsOrders site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL}/>} />
				
				/* This line should be at last */
				<Route path="*" element={<PageNotFound site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />}/>				
              </Routes>
            </Router>
			<Toaster />
			{/*<iframe sandbox="allow-scripts allow-same-origin" style={{width:0,height:0}} onLoad={shareLS} src="http://mart.localhost:3000/localstorage" id="ifr"></iframe>*/}
			</NoInternetConnection>
        </div>
    );
  }
}

export default App;