import { Link } from "react-router-dom";
import AccounHeader from './AccountHeader';
import '../styles/page-misc.css';

export default function NotAuthorized(props) {
	return (
		<div className="container-xxl container-p-y bg-white">
		<AccounHeader site_logo={props.site_logo} site_name={props.site_name} />
		  <div className="misc-wrapper">
			<h2 className="mb-2 mx-2">Not Authorized</h2>
			<Link to="/login" className="btn btn-primary">Back to home</Link>
			<div className="mt-3">
			  <img
				src={window.location.origin+"/assets/img/page-not-authorized.jpg"}
				alt="page-misc-error-light"
				width="500"
				className="img-fluid"
			  />
			</div>
		  </div>
		</div>
	)
}