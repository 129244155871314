import React,{useState, useRef }  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import * as Common from '../common/ImportUserEssentials';

import * as DTE from '../common/ImportDataTableEssentials';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default function ProfitLossStatement(props) {
    // P&L Statement
    // A.Revenue
    const [saleGoodsAmt, setSaleGoodsAmt]                       = useState('0.00');
    const [saleRentAmt, setSaleRentAmt]                         = useState('0.00');
    const [saleFeeIncomeAmt, setSaleFeeIncomeAmt]               = useState('0.00');
    const [saleFinancialIncomeAmt, setSaleFinancialIncomeAmt]   = useState('0.00');
    const [saleMiscellaneousAmt, setSaleMiscellaneousAmt]       = useState('0.00');
    const [saleTotalGrossAmt, setSaleTotalGrossAmt]             = useState('0.00');
    const [saleTotalGstAmt, setSaleTotalGstAmt]                 = useState('0.00');
    const [saleTotalNetAmt, setSaleTotalNetAmt]                 = useState('0.00');
    const [saleUncategorizedIncome, setSaleUncategorizedIncome] = useState('0.00');

    // B.Cost of Goods Sold
    const [purchaseGoodsAmt, setPurchaseGoodsAmt]                     = useState('0.00');
    const [purchaseTechnologyCostsAmt, setPurchaseTechnologyCostsAmt] = useState('0.00');
    const [purchaseFactoryExpensesAmt, setPurchaseFactoryExpensesAmt] = useState('0.00');
    const [purchaseSalaryAmt, setPurchaseSalaryAmt]                   = useState('0.00');
    const [totalCOGSAmt, setTotalCOGSAmt]                             = useState('0.00');

    // C.Gross Profit
    const [totalGrossProfit, setTotalGrossProfit]                     = useState('0.00');
    const [totalGrossMargin, setTotalGrossMargin]                     = useState('0.00');

    // D.Operating Expenditure
    const [purchaseRentalAmt, setPurchaseRentalAmt]                               = useState('0.00');
    const [purchaseMarketingAmt, setMarketingAmt]                                 = useState('0.00');
    const [purchaseProfessionalServicesAmt, setPurchaseProfessionalServicesAmt]   = useState('0.00');
    const [purchaseAdministrativeExpenditureAmt, setAdministrativeExpenditureAmt] = useState('0.00');
    const [purchaseUncategorizedExpense, setPurchaseUncategorizedExpense]         = useState('0.00');
    const [totalExpenditure, setTotalExpenditure]                                 = useState('0.00');

    // E. EBITDA
    const [EBITDA, setEBITDA]                                                     = useState('0.00');
    const [ebitdaPrecentage, setEbitdaPrecentage]                                 = useState('0.00');

    // F. Other Non Operating Expenses
    const [purchaseFinanceCostsAmt, setPurchaseFinanceCostsAmt]                   = useState('0.00');
    const [taxesDutiesCostsAmt, setTaxesDutiesCostsAmt]                           = useState('0.00');
    const [purchaseMiscellaneousAmt, setMiscellaneousAmt]                         = useState('0.00');
    const [purchaseDepreciationAmt, setPurchaseDepreciationAmt]                   = useState('0.00');
    const [totalNonExpenses, setTotalNonExpenses]                                 = useState('0.00');
    
    // G. Profit/Loss
    const [profitLossAmt, setProfitLossAmt]                                       = useState('0.00');
    const [netMarginAmt, setNetMarginAmt]                                         = useState('0.00');

    const [YTDSummary, setYTDSummary] = useState('0');

    // YTD Statement
    // A.Revenue
    const [YTDsaleGoodsAmt, setYTDSaleGoodsAmt]                       = useState('0.00');
    const [YTDsaleRentAmt, setYTDSaleRentAmt]                         = useState('0.00');
    const [YTDsaleFeeIncomeAmt, setYTDSaleFeeIncomeAmt]               = useState('0.00');
    const [YTDsaleFinancialIncomeAmt, setYTDSaleFinancialIncomeAmt]   = useState('0.00');
    const [YTDsaleMiscellaneousAmt, setYTDSaleMiscellaneousAmt]       = useState('0.00');
    const [YTDsaleTotalGrossAmt, setYTDSaleTotalGrossAmt]             = useState('0.00');
    const [YTDsaleTotalGstAmt, setYTDSaleTotalGstAmt]                 = useState('0.00');
    const [YTDsaleTotalNetAmt, setYTDSaleTotalNetAmt]                 = useState('0.00');
    const [YTDsaleUncategorizedIncome, setYTDSaleUncategorizedIncome] = useState('0.00');

    // B.Cost of Goods Sold
    const [YTDpurchaseGoodsAmt, setYTDPurchaseGoodsAmt]                     = useState('0.00');
    const [YTDpurchaseTechnologyCostsAmt, setYTDPurchaseTechnologyCostsAmt] = useState('0.00');
    const [YTDpurchaseFactoryExpensesAmt, setYTDPurchaseFactoryExpensesAmt] = useState('0.00');
    const [YTDpurchaseSalaryAmt, setYTDPurchaseSalaryAmt]                   = useState('0.00');
    const [YTDtotalCOGSAmt, setYTDTotalCOGSAmt]                             = useState('0.00');

    // C.Gross Profit
    const [YTDtotalGrossProfit, setYTDTotalGrossProfit]                     = useState('0.00');
    const [YTDtotalGrossMargin, setYTDTotalGrossMargin]                     = useState('0.00');

    // D.Operating Expenditure
    const [YTDpurchaseRentalAmt, setYTDPurchaseRentalAmt]                               = useState('0.00');
    const [YTDpurchaseMarketingAmt, setYTDMarketingAmt]                                 = useState('0.00');
    const [YTDpurchaseProfessionalServicesAmt, setYTDPurchaseProfessionalServicesAmt]   = useState('0.00');
    const [YTDpurchaseAdministrativeExpenditureAmt, setYTDAdministrativeExpenditureAmt] = useState('0.00');
    const [YTDpurchaseUncategorizedExpense, setYTDPurchaseUncategorizedExpense]         = useState('0.00');
    const [YTDtotalExpenditure, setYTDTotalExpenditure]                                 = useState('0.00');

    // E. EBITDA
    const [YTDEBITDA, setYTDEBITDA]                                                     = useState('0.00');
    const [YTDebitdaPrecentage, setYTDEbitdaPrecentage]                                 = useState('0.00');

    // F. Other Non Operating Expenses
    const [YTDpurchaseFinanceCostsAmt, setYTDPurchaseFinanceCostsAmt]                   = useState('0.00');
    const [YTDtaxesDutiesCostsAmt, setYTDTaxesDutiesCostsAmt]                           = useState('0.00');
    const [YTDpurchaseMiscellaneousAmt, setYTDMiscellaneousAmt]                         = useState('0.00');
    const [YTDpurchaseDepreciationAmt, setYTDPurchaseDepreciationAmt]                   = useState('0.00');
    const [YTDtotalNonExpenses, setYTDTotalNonExpenses]                                 = useState('0.00');
    
    // G. Profit/Loss
    const [YTDprofitLossAmt, setYTDProfitLossAmt]                                       = useState('0.00');
    const [YTDnetMarginAmt, setYTDNetMarginAmt]                                         = useState('0.00');

    const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
    const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
    const tableRef = useRef(null);
    React.useEffect(() => {
        document.title = 'Financial Metrics | '+props.site_name;
        Common.axios({
            method: 'post',
            url:props.url+'financial-metrics/profit-lose-statement/',
            data: {'pid':Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),'dates':dates,'YTDSummary':YTDSummary},
        }).then(function (response) {
			if(response.data.success){
                setSaleGoodsAmt(response.data.data.saleData.sale_goods);
                setSaleRentAmt(response.data.data.saleData.sale_rent);
                setSaleFeeIncomeAmt(response.data.data.saleData.fee_income);
                setSaleFinancialIncomeAmt(response.data.data.otherIncomeData.financial_income);
                setSaleMiscellaneousAmt(response.data.data.otherIncomeData.miscellaneous);
                setSaleUncategorizedIncome(response.data.data.saleData.uncategorized_income);
                let gross_amt = Number(response.data.data.saleData.sale_goods) + Number(response.data.data.saleData.sale_rent) + Number(response.data.data.saleData.fee_income) + Number(response.data.data.otherIncomeData.financial_income) + Number(response.data.data.otherIncomeData.miscellaneous) + Number(response.data.data.saleData.uncategorized_income);
                let gst_amt = Number(response.data.data.saleData.gst_amt) + Number(response.data.data.otherIncomeData.gst_amt);
                let total_net_amt = gross_amt - gst_amt;
                setSaleTotalGrossAmt(gross_amt);
                setSaleTotalGstAmt(gst_amt);
                setSaleTotalNetAmt(total_net_amt);

                setPurchaseGoodsAmt(response.data.data.expenseData.purchase_goods);
                setPurchaseTechnologyCostsAmt(response.data.data.expenseData.technology_costs);
                setPurchaseFactoryExpensesAmt(response.data.data.expenseData.factory_expenses);
                setPurchaseSalaryAmt(response.data.data.otherExpenseData.salary);
                let total_cogs_amt = Number(response.data.data.expenseData.purchase_goods) + Number(response.data.data.expenseData.technology_costs) + Number(response.data.data.expenseData.factory_expenses) + Number(response.data.data.otherExpenseData.salary);
                let gross_profit = total_net_amt - total_cogs_amt;
                let gross_margin = total_net_amt > 0 ? ((gross_profit/total_net_amt) * 100):'-';
                setTotalCOGSAmt(total_cogs_amt);
                setTotalGrossProfit(gross_profit);
                setTotalGrossMargin(gross_margin);

                setPurchaseRentalAmt(response.data.data.expenseData.rental);
                setMarketingAmt(response.data.data.expenseData.marketing);
                setPurchaseProfessionalServicesAmt(response.data.data.expenseData.professional_services);
                setAdministrativeExpenditureAmt(Number(response.data.data.expenseData.admin_expenditure) + Number(response.data.data.otherExpenseData.admin_expenditure));
                setPurchaseUncategorizedExpense(response.data.data.expenseData.uncategorized_expenses);
                let total_expenditure = Number(response.data.data.expenseData.rental) + Number(response.data.data.expenseData.marketing) + Number(response.data.data.expenseData.professional_services) + Number(response.data.data.expenseData.admin_expenditure) + Number(response.data.data.otherExpenseData.admin_expenditure) + Number(response.data.data.expenseData.uncategorized_expenses);
                setTotalExpenditure(total_expenditure);
                let ebitda = gross_profit - total_expenditure;
                let ebitda_pre = total_net_amt > 0 ? ((ebitda/total_net_amt) * 100):'-';
                setEBITDA(ebitda);
                setEbitdaPrecentage(ebitda_pre);

                setPurchaseFinanceCostsAmt(response.data.data.otherExpenseData.finance_costs);
                setTaxesDutiesCostsAmt(response.data.data.otherExpenseData.taxes_duties);
                setMiscellaneousAmt(Number(response.data.data.expenseData.miscellaneous) + Number(response.data.data.otherExpenseData.miscellaneous));
                setPurchaseDepreciationAmt(response.data.data.depreciationData.deprecetiation);
                let total_non_expenses = Number(response.data.data.otherExpenseData.finance_costs) + Number(response.data.data.expenseData.miscellaneous) + Number(response.data.data.otherExpenseData.miscellaneous) + Number(response.data.data.depreciationData.deprecetiation) + Number(response.data.data.otherExpenseData.taxes_duties);
                setTotalNonExpenses(total_non_expenses);
                let profit_loss = ebitda - total_non_expenses;
                setProfitLossAmt(profit_loss);
                let profit_loss_margin = total_net_amt > 0 ? ((profit_loss / total_net_amt) * 100):'-';
                setNetMarginAmt(profit_loss_margin);

                if(YTDSummary === '0'){
                    setYTDSaleGoodsAmt(response.data.data.YTDsaleData.sale_goods);
                    setYTDSaleRentAmt(response.data.data.YTDsaleData.sale_rent);
                    setYTDSaleFeeIncomeAmt(response.data.data.YTDsaleData.fee_income);
                    setYTDSaleFinancialIncomeAmt(response.data.data.YTDotherIncomeData.financial_income);
                    setYTDSaleMiscellaneousAmt(response.data.data.YTDotherIncomeData.miscellaneous);
                    setYTDSaleUncategorizedIncome(response.data.data.YTDsaleData.uncategorized_income);
                    let gross_amt = Number(response.data.data.YTDsaleData.sale_goods) + Number(response.data.data.YTDsaleData.sale_rent) + Number(response.data.data.YTDsaleData.fee_income) + Number(response.data.data.YTDotherIncomeData.financial_income) + Number(response.data.data.YTDotherIncomeData.miscellaneous) + Number(response.data.data.YTDsaleData.uncategorized_income);
                    let gst_amt = Number(response.data.data.YTDsaleData.gst_amt) + Number(response.data.data.YTDotherIncomeData.gst_amt);
                    let total_net_amt = gross_amt - gst_amt;
                    setYTDSaleTotalGrossAmt(gross_amt);
                    setYTDSaleTotalGstAmt(gst_amt);
                    setYTDSaleTotalNetAmt(total_net_amt);

                    setYTDPurchaseGoodsAmt(response.data.data.YTDexpenseData.purchase_goods);
                    setYTDPurchaseTechnologyCostsAmt(response.data.data.YTDexpenseData.technology_costs);
                    setYTDPurchaseFactoryExpensesAmt(response.data.data.YTDexpenseData.factory_expenses);
                    setYTDPurchaseSalaryAmt(response.data.data.YTDotherExpenseData.salary);
                    let total_cogs_amt = Number(response.data.data.YTDexpenseData.purchase_goods) + Number(response.data.data.YTDexpenseData.technology_costs) + Number(response.data.data.YTDexpenseData.factory_expenses) + Number(response.data.data.YTDotherExpenseData.salary);
                    let gross_profit = total_net_amt - total_cogs_amt;
                    let gross_margin = total_net_amt > 0?((gross_profit/total_net_amt) * 100):'-';
                    setYTDTotalCOGSAmt(total_cogs_amt);
                    setYTDTotalGrossProfit(gross_profit);
                    setYTDTotalGrossMargin(gross_margin);

                    setYTDPurchaseRentalAmt(response.data.data.YTDexpenseData.rental);
                    setYTDMarketingAmt(response.data.data.YTDexpenseData.marketing);
                    setYTDPurchaseProfessionalServicesAmt(response.data.data.YTDexpenseData.professional_services);
                    setYTDAdministrativeExpenditureAmt(Number(response.data.data.YTDexpenseData.admin_expenditure) + Number(response.data.data.YTDotherExpenseData.admin_expenditure));
                    setYTDPurchaseUncategorizedExpense(response.data.data.YTDexpenseData.uncategorized_expenses);
                    let total_expenditure = Number(response.data.data.YTDexpenseData.rental) + Number(response.data.data.YTDexpenseData.marketing) + Number(response.data.data.YTDexpenseData.professional_services) + Number(response.data.data.YTDexpenseData.admin_expenditure) + Number(response.data.data.YTDotherExpenseData.admin_expenditure) + Number(response.data.data.YTDexpenseData.uncategorized_expenses);
                    setYTDTotalExpenditure(total_expenditure);
                    let ebitda = gross_profit - total_expenditure;
                    let ebitda_pre = total_net_amt > 0 ? ((ebitda/total_net_amt) * 100):'-';
                    setYTDEBITDA(ebitda);
                    setYTDEbitdaPrecentage(ebitda_pre);

                    setYTDPurchaseFinanceCostsAmt(response.data.data.YTDotherExpenseData.finance_costs);
                    setYTDTaxesDutiesCostsAmt(response.data.data.YTDotherExpenseData.taxes_duties);
                    setYTDMiscellaneousAmt(Number(response.data.data.YTDexpenseData.miscellaneous) + Number(response.data.data.YTDotherExpenseData.miscellaneous));
                    setYTDPurchaseDepreciationAmt(response.data.data.YTDdepreciationData.deprecetiation);
                    let total_non_expenses = Number(response.data.data.YTDotherExpenseData.finance_costs) + Number(response.data.data.YTDexpenseData.miscellaneous) + Number(response.data.data.YTDotherExpenseData.miscellaneous) + Number(response.data.data.YTDdepreciationData.deprecetiation) + Number(response.data.data.YTDotherExpenseData.taxes_duties);
                    setYTDTotalNonExpenses(total_non_expenses);
                    let profit_loss = ebitda - total_non_expenses;
                    setYTDProfitLossAmt(profit_loss);
                    let profit_loss_margin = total_net_amt > 0 ? ((profit_loss / total_net_amt) * 100):'-';
                    setYTDNetMarginAmt(profit_loss_margin);
                }
				return false;
			}
        }).catch(error => console.log(error));
	}, [dates]);
    const setDatePicker = (start, end) => {
        setDates({ start, end });
        setYTDSummary('1');
    }
    const copyBtnClickCallback = function(e) {
        const code = tableRef.current.innerHTML;
        navigator.clipboard.writeText(code);
        Common.toast.success('Copied');
        e.target.focus();
    };
    return(
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Layout site_logo={props.site_logo} label="profit-loss-statement" site={props.site_name}/>
                    <div className="layout-page">
                        <NavBar site={props.site_name} url={props.url}/>
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Financial Metrics /</span> P&L Statement</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-12 col-lg-12 col-12 text-center">
                                                        <h5>P&L Statement</h5>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    {/* <div className="col-md-4"></div> */}
                                                    <div className="col-md-4 offset-md-4">
                                                        <DateRangePicker
                                                        initialSettings={DTE.DatePickerPredefined(moment,start,end)}
                                                        onCallback={setDatePicker}>
                                                            <div className="input-group input-group-sm">
                                                                <span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
                                                                <div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
                                                                    <i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
                                                                </div>
                                                            </div>
                                                        </DateRangePicker>
                                                        <small className="text-center" style={{'marginLeft': '3rem'}}>Date range based on Transaction date</small>
                                                    </div>
                                                    <div className="col-md-2 text-center text-md-start">
                                                        <button id="copy" onClick={copyBtnClickCallback} className="btn btn-sm btn-info me-2"><i className='bx bx-copy'></i> <span className="hidden-phone">Copy Data</span></button>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 col-lg-12 col-12 mb-3">
                                                        <div className="table-responsive mb-3">
                                                            <div ref={tableRef} className="table">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan={2} className="fw-bold" style={{'textAlign': 'center'}}>Particulars </th>
                                                                            <th colSpan={2} className="fw-bold" style={{'textAlign': 'center'}}>{label}</th>
                                                                            <th colSpan={2} className="fw-bold" style={{'textAlign': 'center'}}>YTD Statement</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td colSpan={6} className="fw-bold">A.Revenue</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={6}></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Sale of Goods or Service</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(saleGoodsAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDsaleGoodsAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Sale or rent of assets</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(saleRentAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDsaleRentAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Fee based Income</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(saleFeeIncomeAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDsaleFeeIncomeAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Financial Income</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(saleFinancialIncomeAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDsaleFinancialIncomeAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Miscellaneous</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(saleMiscellaneousAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDsaleMiscellaneousAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Uncategorized Income</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(saleUncategorizedIncome).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDsaleUncategorizedIncome).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={6}></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Total Gross Revenue</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(saleTotalGrossAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDsaleTotalGrossAmt).toFixed(2)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Less GST</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(saleTotalGstAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDsaleTotalGstAmt).toFixed(2)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Total Net Revenue</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(saleTotalNetAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDsaleTotalNetAmt).toFixed(2)}</td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={6} className="fw-bold">B. Cost of Goods</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Purchase of Goods</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseGoodsAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseGoodsAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Salary</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseSalaryAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseSalaryAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Technology Costs</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseTechnologyCostsAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseTechnologyCostsAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Factory Expenses</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseFactoryExpensesAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseFactoryExpensesAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={6}></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Total COGS</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(totalCOGSAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDtotalCOGSAmt).toFixed(2)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={6}></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={6} className="fw-bold">C. Gross Profit</td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Gross Profit</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(totalGrossProfit).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDtotalGrossProfit).toFixed(2)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Gross Margin (in %)</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>{(totalGrossMargin !== '-')?Number(totalGrossMargin).toFixed(2):totalGrossMargin}%</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>{(YTDtotalGrossMargin !== '-')?Number(YTDtotalGrossMargin).toFixed(2):YTDtotalGrossMargin}%</td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={6} className="fw-bold">D. Operating Expenditure</td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Rental</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseRentalAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseRentalAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Marketing</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseMarketingAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseMarketingAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Professional Services</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseProfessionalServicesAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseProfessionalServicesAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Administrative expenditure</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseAdministrativeExpenditureAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseAdministrativeExpenditureAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} >Uncategorized Expense</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseUncategorizedExpense).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseUncategorizedExpense).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Total Operating Expenditure</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(totalExpenditure).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDtotalExpenditure).toFixed(2)}</td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={6} className="fw-bold">E. EBITDA/Operating Profit</td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={2}>EBITDA</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(EBITDA).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDEBITDA).toFixed(2)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>EBITDA (in %)</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>{(ebitdaPrecentage !== '-')?Number(ebitdaPrecentage).toFixed(2):ebitdaPrecentage}%</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>{(YTDebitdaPrecentage !== '-')?Number(YTDebitdaPrecentage).toFixed(2):YTDebitdaPrecentage}%</td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={6} className="fw-bold">F. Other Non Operating Expenses</td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Finance Costs</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseFinanceCostsAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseFinanceCostsAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Duties and Taxes</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(taxesDutiesCostsAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDtaxesDutiesCostsAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Depreciation</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseDepreciationAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseDepreciationAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Miscellaneous</td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(purchaseMiscellaneousAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDpurchaseMiscellaneousAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Total Non operating expenses</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(totalNonExpenses).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDtotalNonExpenses).toFixed(2)}</td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={6} className="fw-bold">G. Profit/Loss</td>
                                                                        </tr>
                                                                        <tr><td colSpan={6}></td></tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Profit/Loss</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(profitLossAmt).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>₹ {Number(YTDprofitLossAmt).toFixed(2)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>Net Margin (in %)</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>{(netMarginAmt !== '-')?Number(netMarginAmt).toFixed(2):netMarginAmt}%</td>
                                                                            <td></td>
                                                                            <td className="text-nowrap" style={{'textAlign': 'right'}}>{(YTDnetMarginAmt !== '-')?Number(YTDnetMarginAmt).toFixed(2):YTDnetMarginAmt}%</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Common.FYNTicket url={props.url}/>
                    </div>
                </div>
            </div>
        </>
    );
}