import React from 'react';

export default function ItemCustomFields(props){
    const [customItemFieldName, setCustomItemFieldName]   = React.useState(props.datas?props.datas.item_field_name:'');
    const [customItemFieldId, setCustomItemFieldId]   = React.useState(props.datas?props.datas.id:'');
    // const [customItemFieldValue, setCustomItemFieldValue] = React.useState(props.datas?props.datas.field_value:'');
    
    return  (<>
        <div className="row" key={"iak_"+props.len} id={"ia_"+props.len}>
            <div className="mb-3 col-md-5">
                <div className="input-group ">
                    <input type="text" className="form-control custom_fields mt-2" id={`custom_item_field_name_${props.len}`} name={`custom_item_field_name_${props.len}`}  value={customItemFieldName} onChange={(e) => setCustomItemFieldName(e.target.value)} placeholder='Custom Field Name' />
                    <input type="hidden" id={`custom_item_field_id_${props.len}`} name={`custom_item_field_id_${props.len}`}  value={customItemFieldId} onChange={(e) => setCustomItemFieldId(e.target.value)}/>
                </div>
            </div>
            {/* <div className="mb-3 col-md-5">
                <div className="input-group input-group-merge">
                    <input type="text" className="form-control mt-2" id={`custom_item_field_value_${props.len}`} name={`custom_item_field_value_${props.len}`}  value={customItemFieldValue} onChange={(e) => setCustomItemFieldValue(e.target.value)} placeholder='Custom Field Value' />
                </div>
            </div> */}
            <div className="mb-3 col-md-2 mt-2 text-center">
                <button type="button" className="btn btn-danger" onClick={(e) => e.target.closest('#ia_'+props.len).remove()}><i className='bx bx-trash'></i></button>
            </div>
        </div>
    </>);
}