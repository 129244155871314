import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import Select from "react-select";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import * as Common from '../common/ImportUserEssentials';

export default function AddUser(props){
	const [newFlag,setNewFlag] = useState(1);
	const [verifyOTPShow, setVerifyOTPShow] = useState(false);
	const [otp, setOtp] = useState('');
	const [user_otp, setUserOtp] = useState('');
	const [runTimer,setRunTimer] = useState(false);
	const [seconds, setSeconds] = useState(30);
	const [name, setName]         = useState('');
	const [role, setRole]         = useState('');
    const [mobile, setMobile]       = useState('');
    const [profile_name, setProfileName]       = useState('');
	const [modules,setModules] = useState([]);
	const [proceed,setProceed]             = useState(false);
    const [showDiv,setShowDiv]             = useState(false);
    const [showDiv2,setShowDiv2]             = useState(false);
	const [unchecked,setUnchecked] = React.useState([]);
	const [userMgmt_w,setuserMgmt_w] = React.useState(true);
	const [nameInputRO,setNameInputRO] = React.useState(1);
	const fromRef = React.useRef();
	const navigate = Common.useNavigate();
	const options = [
		{ value: "Co-Owner", label: "Co-Owner" },
		{ value: "Sub-User", label: "Sub-User" }
	];
	React.useEffect(() => {
		if(runTimer){
			const sec_interval = setInterval(() => {
				if (seconds > 0) {
				  setSeconds(seconds - 1);
				}
				if (seconds === 0) {
					clearInterval(sec_interval);
				}
			}, 1000);
			return () => clearInterval(sec_interval);
		}
	}, [seconds, runTimer]);
	useEffect(() => {
		document.title = 'Add User | '+props.site_name;
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));

		if( Object.keys(userPerm).length > 0 ){
			// Write permission
			setuserMgmt_w((userPerm[5]['write'] !== undefined && userPerm[5]['write'] === '0') ? false : true);
		}
		Common.axios({
			method: 'post',
            url:props.url+'user/profileDetails',
            data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
        }).then(function (response) {
			if(response.data.success){
                setProfileName(response.data.data.name);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}, []);
	if( !userMgmt_w ){
		navigate("/not-authorized");
	}
	const setOtpValue = (val) => {
		setOtp(Common.acceptOnlyNumbers(val))
	}
	const submitOTP = () => {
		if( otp.length !== 6 ){
			Common.toast.error('Invalid OTP entered.');
			return false;
		}

        if(otp == user_otp){
			setOtpValue('');
			setVerifyOTPShow(false);
			setNewFlag(0);
			fromRef.current.requestSubmit();
			return false;
		} else {
			setOtpValue('');
			Common.toast.error('Wrong OTP entered.');
			return false;
		}
	}
	const handleOTPSubmit =(e) => {
        e.preventDefault();
		submitOTP();
    }

	const otpVerification = () => {
		if( name === '' || name === undefined ){
			Common.toast.error('Name cannot be left blank.');
			return false;
		}
		if( role === '' || role === undefined ){
			Common.toast.error('Role cannot be left blank.');
			return false;
		}

		if( role === 'Sub-User' && unchecked.length === 6){
			Common.toast.error('Atleast permission for 1 module is required.');
			return false;
		}
		setVerifyOTPShow(true);
		setRunTimer(true);
		let valotp = Math.floor(100000 + Math.random() * 900000);
		setUserOtp(valotp);
		Common.axios({
			method: 'post',
			url:props.url+'user/sendSubOTP',
			data: {'mobile':Common.AES.encrypt(JSON.stringify(mobile), props.site_key).toString(),'otp':Common.AES.encrypt(JSON.stringify(valotp), props.site_key).toString()},
		}).then(function (response) {
			if(response.data.success){
				Common.toast.success(response.data.message);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
		return false;
	}
	const checkUserExist = (val) => {
		setMobile(val);
		setNewFlag(1);
		if( val.length === 10 ){
			Common.axios({
				method: 'post',
				url: props.url+'user/preAddProfile',
				data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString(),mobile:Common.AES.encrypt(val,props.site_key).toString()}
			}).then(function(response){
				if( response.data.success ){
					// Own mobile number check
					// if( response.data.data.id === localStorage.getItem('fo_loggedin') ){
					// 	Common.toast.error('You are already added.');
					// 	return false;
					// }
					setProceed(true);
					setName(response.data.data.uname);
					if( response.data.data.uname === undefined ){
						setNameInputRO(0);
					}
					return false;
				}
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
		setName('');
		setNameInputRO(1);
		setProceed(false);
		setShowDiv(false);
		setShowDiv2(false);
	}
	
    const formSubmit = (e)=>{
        e.preventDefault();

        let formData = new FormData(e.target);

        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			if( key === 'name' && value === '' ){
				Common.toast.error('Name cannot be left blank.');
				return false;
			}
			data[key] = Common.AES.encrypt(value, props.site_key).toString();
		}
		data['pid'] = Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString();
		if (localStorage.getItem('fo_subplan') === 'Basic') {
			data['sub_plan_type'] = 1
		} else if (localStorage.getItem('fo_subplan') === 'Standard') {
			data['sub_plan_type'] = 2;
		} else {
			data['sub_plan_type'] = 3;
		}
        Common.axios({
            method: 'post',
            url:props.url+'user/addSub',
            data: data,
        }).then(function (response) {
			if(response.data.success){
				Common.toast.success('User successfully added to this profile.');
				setTimeout(() => {
					navigate("/user-listing");
				}, 1000);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    const handleRoleChange=(e)=>{
		let val = e.value;
		setRole(val);
		let subPlanType = 0;
		if (localStorage.getItem('fo_subplan') === 'Basic') {
			subPlanType = 1
		} else if (localStorage.getItem('fo_subplan') === 'Standard') {
			subPlanType = 2;
		} else {
			subPlanType = 3;
		}
		if( val !== '' ){
			if( val === 'Co-Owner' ){
				setShowDiv(false);
				setShowDiv2(true);
				setUnchecked([]);
				return false;
			}
			Common.axios({
				method: 'post',
				url:props.url+'user/fetchModules',
				data: {
					'sub_plan_type': ''
				}
			}).then(function (response) {
				if(response.data.success){
					setModules(response.data.data);
					return false;
				}
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));

			setShowDiv(true);
			setShowDiv2(false);
			return false;
		}
		setShowDiv(false);
		return false;
    }
	const checkOtherPermissions = (event,val) => {
		/*const { value, checked } = e.target;
		console.log(`${value} is ${checked}`);
		if( !checked ){

		}*/
		if (!event.target.checked) {
			setUnchecked([...unchecked,val]);
		} else {
			var array = [...unchecked];
			var index = array.indexOf(val)
			if (index !== -1) {
				array.splice(index, 1);
				setUnchecked(array);
			}
		}
	}
	if(otp.length === 6){
		submitOTP();
	}
    return (
        <>
		<Modal show={verifyOTPShow} size="md" aria-labelledby="otp-modal" backdrop="static" keyboard={false} centered>
			<Modal.Body>				
				<div className="row">
					<div className="text-center mb-3">
						<h6>Please enter the one time password <br/> to verify your account</h6>
						<div> <span>A code has been sent to</span> <small>******<span>{mobile !== null && mobile.slice(-4)}</span></small> </div>
						<form autoComplete="off" onSubmit={handleOTPSubmit}>
							<div className="inputs d-flex flex-row justify-content-center mt-2">
							<OtpInput value={otp} onChange={setOtpValue} numInputs={6} shouldAutoFocus={true} inputStyle = {'otpInput m-2 text-center form-control rounded'}
								inputType="tel"
								renderSeparator={<span>-</span>}
								renderInput={(props) => <input {...props}/>}
							/>
							</div>
							<div className="mt-4"> <button type="submit" className="btn btn-primary px-4 validate">Validate</button> </div>
						</form>
					</div>
					<p className="text-center">
						<span>Not your number? </span>
						<Button variant="" className="p-0 text-decoration-underline" onClick={e=>{setVerifyOTPShow(false);setSeconds(30);setRunTimer(false);setOtpValue('')}}>Back</Button>
					</p>
					<p className="text-center">
						{seconds > 0 ? (
						<>Time Remaining: {seconds < 10 ? `0${seconds}` : seconds} secs</>
						) : (
						<>Didn't receive code? <Button variant="" className="p-0 text-decoration-underline" onClick={e=>{setVerifyOTPShow(false);setSeconds(30);setRunTimer(false);setOtpValue('')}}>Close</Button> and try again.</>						
						)}
					</p>
				</div>
			</Modal.Body>
		</Modal>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="add-user" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">User Management /</span> Add User to <u>{profile_name}</u></h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
											<form ref={fromRef} onSubmit={formSubmit} autoComplete="off">
												<div className="row">
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="mobile" className="form-label">Mobile<span className="text-danger">*</span></label>
                                                        <div className="input-group input-group-merge">
                                                            <span id="basic-icon-default-fullname2" className="input-group-text"><i className="bx bx-mobile"></i></span>
                                                            <input type="text" className="form-control" id="mobile" name="mobile" placeholder="Enter Mobile" maxLength="10" value={mobile} onChange={(e) => checkUserExist(Common.acceptOnlyNumbers(e.target.value))}  required inputMode="numeric"/>
                                                        </div>
                                                    </div>
													{proceed && (<>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="name" className="form-label">Name</label>
                                                        <div className="input-group input-group-merge">
                                                            <span className={"input-group-text " + (nameInputRO ? 'disabled-bg':'')}><i className="bx bx-user"></i></span>
															{nameInputRO ? 
                                                            <input type="text" className="form-control" id="name" name="name" placeholder="Auto populated using mobile number" value={name} readOnly/>
															:
                                                            <input type="text" className="form-control" id="name" name="name" placeholder="Enter name" defaultValue={name} onChange={(e)=>setName(e.target.value)} required/>
															}
                                                        </div>
														<div className="form-text">Name is auto fetched if user is already present in FynOne.</div>
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label htmlFor="role" className="form-label">Role<span className="text-danger">*</span></label>
														<Select id="role" placeholder="Select Role" name='role' options={options} onChange={handleRoleChange} required/>
													</div>
													</>)}
												</div>
												{showDiv2 && (
													<div className="text-center">
														<div className="alert alert-info" role="alert">
															<i className='bx bx-info-circle bx-tada' ></i> User will have same permissions as Owner
														</div>
													</div>
												)}
												{showDiv && (<>
												<div className={"divider"}><u>Permissions</u></div>
												<div className="table-responsive">
													<table className="table table-borderless table-striped">
														<thead>
															<tr>
																<th scope="col">Module</th>
																<th scope="col">Access</th>
															</tr>
														  </thead>
														  <tbody>
															{modules.map((val,index) => (
																<tr key={"tr_"+index} className="align-middle">
																	<td>{val.name}</td>
																	<td>
																		<div className="form-check form-switch">
																			{(localStorage.getItem('fo_subplan') === 'Basic' && (val.name === 'Loans & Investments' || val.name === 'Assets Management' || val.name === 'Accounting' || val.name === 'Tax Compliance') ? <>
																				<input value="0" className="form-check-input" type="checkbox" role="switch" name={"permission_"+val.id+"_v"} onChange={(e)=>checkOtherPermissions(e,val.id)} disabled={true} />
																			</> : <>
																				{(localStorage.getItem('fo_subplan') === 'Standard' && (val.name === 'Tax Compliance') ? <>
																				<input value="0" className="form-check-input" type="checkbox" role="switch" name={"permission_"+val.id+"_v"} onChange={(e)=>checkOtherPermissions(e,val.id)} disabled={true} />
																				</> : <>
																					<input value="1" className="form-check-input" type="checkbox" role="switch" name={"permission_"+val.id+"_v"} onChange={(e)=>checkOtherPermissions(e,val.id)} defaultChecked/>
																				</>)}
																			</>)}
																		</div>
																	</td>
																	{/*<td>
																		<div className="form-check form-switch">
																			{(unchecked.indexOf(val.id) !== -1) ?
																				<input value="1" className="form-check-input" type="checkbox" role="switch" name={"permission_"+val.id+"_w"} disabled={true} checked={false}/>
																				:
																				<input value="1" className="form-check-input" type="checkbox" role="switch" name={"permission_"+val.id+"_w"} />
																			}
																		</div>
																	</td>
																	<td>
																		<div className="form-check form-switch">
																			{(unchecked.indexOf(val.id) !== -1) ?
																				<input value="1" className="form-check-input" type="checkbox" role="switch" name={"permission_"+val.id+"_d"} disabled={true} checked={false}/>
																				:
																				<input value="1" className="form-check-input" type="checkbox" role="switch" name={"permission_"+val.id+"_d"}/>
																			}
																		</div>
																	</td>*/}
																</tr>
															))}
														  </tbody>
													</table>
												</div>
												</>)}
                                                <div className="row mt-3">
													<div className="text-center">
														<a href="/user-listing" role="button" className="me-3 my-1 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
														{proceed && (<>
														{newFlag ? 
															<>
															<button type='button' onClick={otpVerification} className="btn btn-sm btn-primary"><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
															<div className="form-text">User will receive sms OTP and the same should be entered by you on clicking Save.</div></>
															:
															<button type='submit' className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
														}
														</>)}
													</div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}