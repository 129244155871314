import React from 'react';

export default function MultiDownloadCustomFields(props){
    const [customItemFieldName, setCustomItemFieldName]   = React.useState('');
    
    return  (<>
        <div className="row" key={"iak_"+props.len} id={"ia_"+props.len}>
            <div className="mb-3 col-md-8 col-8">
                <div className="input-group ">
                    <input type="text" className="form-control custom_fields mt-2" id={`custom_item_field_name_${props.len}`} name={`custom_item_field_name_${props.len}`}  value={customItemFieldName} onChange={(e) => setCustomItemFieldName(e.target.value)} placeholder='Invoice Label' maxLength="30" required />
                </div>
            </div>
            <div className="mb-3 col-md-4 col-4 mt-2 text-center">
                <button type="button" className="btn btn-danger" onClick={(e) => e.target.closest('#ia_'+props.len).remove()}><i className='bx bx-trash'></i></button>
            </div>
        </div>
    </>);
}