import React,{useState,useEffect} from 'react';
import * as Common from '../common/ImportUserEssentials';

export default function PartnerNavBar(props){
	const [udetails,setUdetails] = useState([]);
	const [partnerType,setPartnerType] = useState('');
	useEffect(() => {
		if( !localStorage.getItem('fo_loggedin') ){
            window.location.href = '/landing-partner';
		}else{
            Common.axios({
                method: 'get',
                url: props.url+'/user/details',
            }).then(function (response) {
                if( response.data.success ){
                    setUdetails(response.data.data);
                }
            }).catch(error => console.log(error));
            if(localStorage.getItem('fo_partnerType') === 'partner'){
                Common.axios({
                    method: 'get',
                    url: props.url+'/partners/getPartnerType',
                }).then(function (response) {
                    if( response.data.success ){
                        setPartnerType(response.data.data);
                    }
                }).catch(error => console.log(error));
            }else{
                const accountType = localStorage.getItem('fo_partnerType');
                const modStr = (accountType)?accountType[0].toUpperCase() + accountType.slice(1):'';
                setPartnerType(modStr);
            }
        }
	}, []);
	const logout = () => {
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'logout',
		}).then(function (response) {
			if( response.data.success ){
				Common.showLoader(false);
				Common.destroySession();
				window.location.href = '/landing-partner';
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	};
    return (
		<>
        <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
              <button className="nav-item nav-link px-0 me-xl-4 btn btn-link">
                <i className="bx bx-menu bx-sm"></i>
              </button>
            </div>
            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
				<div className='d-grid me-2'>
                    <div className="text-truncate fw-bold">{udetails.uname}</div>
                    <small>{partnerType}</small>
				</div>
                <div className='ms-auto'>
				</div>
                <ul className="navbar-nav flex-row align-items-center">				
                    <li className="nav-item navbar-dropdown dropdown-user dropdown">
                    <a className="nav-link dropdown-toggle hide-arrow" href="#" data-bs-toggle="dropdown">
                        <div className="avatar avatar-online">
                            <p data-letters={Common.userInitial(udetails.uname)}></p>
                        </div>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                        <a className="dropdown-item" href="#">
                            <div className="d-flex">
                            <div className="flex-shrink-0 me-2">
                                <div className="avatar">
                                <p data-letters={Common.userInitial(udetails.uname)}></p>
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <span className="fw-semibold d-block">{udetails.uname}</span>
                                <small className="text-muted">{udetails.role}</small>
                            </div>
                            </div>
                        </a>
                        </li>
                        <li>
                        <div className="dropdown-divider"></div>
                        </li>
                        <li>
                        <a className="dropdown-item" href="/partner-profile">
                            <i className="bx bx-user me-2"></i>
                            <span className="align-middle">My Details</span>
                        </a>
                        </li>
                        <li>
                        <a className="dropdown-item" href="/partner-whats-new">
                            <i className='bx bxs-megaphone me-2'></i>
                            <span className="align-middle">What's New</span>
                        </a>
                        </li>
                        <li>
                        <a rel="noreferrer" className="dropdown-item" target='_blank' href="https://play.google.com/store/apps/details?id=com.fynamics.fynone">
                            <i className='bx bxl-play-store'></i>
                            <span className="align-middle"> Download App</span>
                        </a>
                        </li>
                        
                        <li>
                        <div className="dropdown-divider"></div>
                        </li>
                        <li>
                        <a className="dropdown-item" href="#" onClickCapture={logout}>
                            <i className="bx bx-power-off me-2"></i>
                            <span className="align-middle">Log Out</span>
                        </a>
                        </li>
                    </ul>
                    </li>
                </ul>
            </div>
        </nav>
		</>
    );
}