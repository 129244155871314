const doc_types = [
	{
		label: 'Plan A',
		value: '2500.00'
	},
	{
		label: 'Plan B',
		value: '10000.00'
	},
	{
		label: 'Plan C',
		value: '50000.00'
	}
];
export default doc_types;