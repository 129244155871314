import React,{useState}  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import 'react-date-picker/dist/DatePicker.css';
import * as Common from '../common/ImportUserEssentials';

export default function DataSecurity(props) {
    const [securityText, setSecurityText]      = useState('');
    React.useEffect(() => {
        document.title = 'Data Security | '+props.site_name;
        Common.axios({
            method: 'get',
            url:props.url+'bank-accounts/data-security/',
            // data: {pid:localStorage.getItem('fo_profileid')},
        }).then(function (response) {
			if(response.data.success){
                setSecurityText(response.data.data[0].text);
				return false;
			}
        }).catch(error => console.log(error));
	}, []);
    return(
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Layout site_logo={props.site_logo} label="data-security" site={props.site_name}/>
                    <div className="layout-page">
                        <NavBar site={props.site_name} url={props.url}/>
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Account Management /</span> Data Security</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="mb-3 col-md-12">
                                                        {/* <p>{securityText}</p> */}
                                                        <p dangerouslySetInnerHTML={{ __html: (securityText) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Common.FYNTicket url={props.url}/>
                    </div>
                </div>
            </div>
        </>
    );
}