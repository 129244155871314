import { Link } from "react-router-dom";
import GuestHeader from './GuestHeader';
import '../styles/page-misc.css';

export default function NotFound(props) {
	return (
		<div className="container-xxl container-p-y">
		<GuestHeader site_logo={props.site_logo} site_name={props.site_name} />
		  <div className="misc-wrapper">
			<h2 className="mb-2 mx-2">Page Not Found :(</h2>
			<p className="mb-4 mx-2">Oops! 😖 The requested URL was not found on this server.</p>
			<Link to="/" className="btn btn-primary">Back to home</Link>
			<div className="mt-3">
			  <img
				src={window.location.origin+"/assets/img/page-misc-error-light.png"}
				alt="page-misc-error-light"
				width="500"
				className="img-fluid"
				data-app-dark-img="page-misc-error-dark.png"
				data-app-light-img="page-misc-error-light.png"
			  />
			</div>
		  </div>
		</div>
	)
}