const supply_types = [
  {
    label: 'Business to Customer (B2C)',
    value: 'B2C'
  },
  {
    label: 'Business to Business (B2B)',
    value: 'B2B'
  },
  {
    label: 'SEZ with payment (SEZWP)',
    value: 'SEZWP'
  },
  {
    label: 'SEZ without payment (SEZWOP)',
    value: 'SEZWOP'
  },
  {
    label: 'Export with payment (EXPWP)',
    value: 'EXPWP'
  },
  {
    label: 'Export without payment (EXPWOP)',
    value: 'EXPWOP'
  },
  {
    label: 'Deemed Export (DEXP)',
    value: 'DEXP'
  }
];
export default supply_types;