const invoice_types = [
	{
		label: 'R - Regular B2B Invoices',
		value: 'R'
	},
	{
		label: 'DE – Deemed Exports',
		value: 'DE'
	},
	{
		label: 'SEWP – SEZ Exports with payment',
		value: 'SEWP'
	},
	{
		label: 'SEWOP – SEZ exports without payment',
		value: 'SEWOP'
	}
];
export default invoice_types;