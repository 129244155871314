import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import Select from "react-select";
import * as Common from '../common/ImportUserEssentials';

export default function EditSeller(props){
	// User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	
    const [party_name, setPartyName]    = useState('');
	const [phone, setPhone]    = useState('');
	const [gstin, setGstin]    = useState('');
	const [legal_name, setLegalName]    = useState('');
	const [trade_name, setTradeName]    = useState('');
	const [pincode, setPinCode]         = useState('');
	const [stateid,setState]            = useState('');
    const [district,setDistrict]        = useState('');
    const [addr,setAddr]                = useState('');
    const [seller_id,setSellerID]       = useState('');
	const [state_master,setStateMaster]    = useState('');
	
    const { state }                     = Common.useLocation();
	const [expensePay_w,setexpensePay_w] = React.useState(true);
	const navigate = Common.useNavigate();
	useEffect(() => {
		document.title = 'Edit Seller | '+props.site_name;
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Write permission
			setexpensePay_w((userPerm[3]['write'] !== undefined && userPerm[3]['write'] === '0') ? false : true);
		}
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_state_master',
		}).then(function (response) {
			if( response.data.success ){
				setStateMaster(response.data.data);
			}
		}).catch(error => console.log(error));
        Common.axios({
            method: 'post',
            url:props.url+'vendor/getDetails',
            data:{se_id:state.se_id,pid:localStorage.getItem('fo_profileid')}
        }).then(function (response) {
            if(response.data.success){
                setSellerID(response.data.data.id);
                setPartyName(response.data.data.party_name);
                setGstin(response.data.data.gstin);
                setPhone(response.data.data.mobile);
                setLegalName(response.data.data.legal_name);
                setTradeName(response.data.data.trade_name);
                setPinCode(response.data.data.pincode);
                setState(response.data.data.state);
                setDistrict(response.data.data.district);
                setAddr(response.data.data.addr);
            }
        }).catch(error => console.log(error));
	}, []);
	if( !expensePay_w ){
		navigate("/not-authorized");
	}
    const handleCustomer = (e)=>{
        e.preventDefault();
        if(party_name===""){
            alert("Party Name is Required.");
            return false;
        }
        let url = props.url+'vendor/edit';
        Common.axios({
            method: 'post',
            url:url,
            data: {
                party_name:party_name,legal_name:legal_name,trade_name:trade_name,pincode:pincode,state:stateid,district:district,addr:addr,pid:localStorage.getItem('fo_profileid'),seller_id:seller_id
            },
        }).then(function (response) {
			if(response.data.success){
                navigate("/seller-listing");
            }
        }).catch(error => console.log(error));
    }

    const getPincodeDetails =(val) => {
        let pincode= Common.acceptOnlyNumbers(val);
		setPinCode(pincode);
		if( pincode.length === 6 ){
			Common.axios({
				method: 'post',
				url:props.url+'ajax/getDataByPin',
				data: {pincode:pincode},
			}).then(function (response) {
				if(response.data.success){
					setState(response.data.stateid);
					setDistrict(response.data.district);
				}
			}).catch(error => console.log(error));
		}
    }

    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="add-seller" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Expenses & Payments /</span> Edit Seller</h4>
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="card mb-4">
                                        <div className="card-body">
                                            <form id="editSellerFrm" autoComplete="off">
                                                <div className="row">
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="party_name" className="form-label">Customer Name<span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" id="party_name" name="party_name" placeholder="Customer Name" maxLength="100" value={party_name} onChange={(e) => setPartyName(Common.acceptOnlyChars(e.target.value))} required/>
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label htmlFor="mobile" className="form-label">Phone No</label>
                                                        <input type="text" className="form-control" id="mobile" name="mobile" value={phone} readOnly />
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="gstin" className="form-label">Gstin</label>
                                                        <input type="text" className="form-control" id="gstin" name="gstin" placeholder="Gstin" maxLength="15" value={gstin} readOnly />
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="legal_name" className="form-label">Legal Name</label>
                                                        <input type="text" className="form-control" id="legal_name" name="legal_name" placeholder="Legal Name" maxLength="100" value={legal_name} onChange={(e) => setLegalName(e.target.value)}/>
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="trade_name" className="form-label">Trade Name</label>
                                                        <input type="text" className="form-control" id="trade_name" name="trade_name" placeholder="Trade Name" maxLength="100" value={trade_name} onChange={(e) => setTradeName(e.target.value)}/>
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="pincode" className="form-label">Pin Code</label>
                                                        <input type="text" className="form-control" id="pincode" name="pincode" placeholder="Pin Code" maxLength="6" value={pincode} onChange={(e) => getPincodeDetails(e.target.value)} inputMode="numeric" />
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="state" className="form-label">State</label>
														<Select id="state" placeholder="Select State" name='state' value={state_master[Common.stateIndex(state_master,stateid)]} options={state_master} onChange={(e) => setState(e.value)}/>
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="district" className="form-label">District</label>
                                                        <input type="text" className="form-control" id="district" name="district" placeholder="District" value={district} onChange={(e) => setDistrict(e.target.value)}/>
                                                    </div>
													<div className="mb-3 col-md-6">
                                                        <label htmlFor="addr" className="form-label">Address</label>
                                                        <textarea id="addr" name="addr" className="form-control" placeholder="Address" aria-label="Address" aria-describedby="basic-icon-default-message2"  value={addr} onChange={(e) => setAddr(e.target.value)} ></textarea>
                                                    </div>
												</div>
                                                <div className="row ">
													<div className="d-flex gap-2">
														<a href="/customer-listing" role="button" className="me-lg-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> <span className="hidden-phone">Back</span></a>
														<button className="btn btn-sm btn-primary" onClick={handleCustomer}><span className="tf-icons bx bx-save"></span>&nbsp;<span className="hidden-phone">Save</span></button>
													</div>
												</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        </>
    );
}